import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Props
 *
 */
type Props = {
  children: ReactNode;
};

/**
 *
 * Component
 *
 */
const BatchIcon: FC<Props> = memo(({ children }) => (
  <BatchIconS>{children}</BatchIconS>
));
export default BatchIcon;

/**
 *
 * Styles
 *
 */
const BatchIconS = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  border-radius: 50%;
  background: linear-gradient(#ff17e4, #5e05c6);
  line-height: 50%;
`;
