import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Components
import NewsLayout from 'components/templates/NewsLayout';
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderPart from 'components/atoms/loader/LoaderPart';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Hooks
import { useNewsUsersIndex } from 'hooks/pages/private/useNewsUsersIndex';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { newsUsersIndexBreadCrumbItems } from 'utils/breadCrumbs';
import { NEWS, NEWS_USERS_KIND } from 'utils/const/news';
import { LANG } from 'utils/const/lang';

const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN,
  VIETNAM, THAILAND,
} = LANG;
const { LIST_VIEW_LIMIT } = NEWS;
const { LOT, RANK_UP, CAMPAIGN } = NEWS_USERS_KIND;

/**
 *
 * Component
 *
 */
const NewsUsersIndex: FC = memo(() => {
  const lang = useRecoilValue(langState);

  const { t } = useTranslation();
  const {
    isLoading,
    newsUsers,
    newsUsersPagination,
    getApiNewsUsersSelect,
    isEmpty,
    errorMessage,
    goToNewsUsersShowPage,
    goToNewsUsersSkipPage,
  } = useNewsUsersIndex();

  return (
    <>
      <Helmet>
        <title>{t('title.userNewsIndex')}</title>
        <meta name="description" content={t('description.userNewsIndex')} />
      </Helmet>
      <NewsLayout breadCrumbItems={newsUsersIndexBreadCrumbItems}>
        {/* 抽選演出スキップ */}
        {newsUsers[0]?.id !== 0 && (
          <SkipWrapS>
            <SkipButtonS onClick={(e) => goToNewsUsersSkipPage(e)}>
              ALL REWARD RESERVE
            </SkipButtonS>
          </SkipWrapS>
        )}
        <UlistS>
          {/* ローディングアニメーション */}
          {isLoading && <LoaderPart />}
          {newsUsers[0]?.id !== 0
            && newsUsers.map((news) => (
              <ListS key={uuidv4()}>
                <BoxS>
                  <IconBrockS>
                    <RoundIcon status={news.status}>●</RoundIcon>
                  </IconBrockS>
                  <NewsBlockS>
                    <NewsWrapS onClick={(e) => goToNewsUsersShowPage(e, news.id)}>
                      <TitleS>
                        {lang === JAPANESE
                          ? news.title
                          : lang === SIMPLE_CHINESE
                            ? news.zh_cn_title
                            : lang === TRADITIONAL_CHINESE
                              ? news.zh_tw_title
                              : lang === KOREAN
                                ? news.ko_title
                                : lang === VIETNAM
                                  ? news.vi_title
                                  : lang === THAILAND
                                    ? news.th_title
                                    : news.en_title}
                      </TitleS>

                      {/* Lot */}
                      {news.kind === LOT && (
                        <TextS>
                          {lang === JAPANESE
                            ? news.description
                            : lang === SIMPLE_CHINESE
                              ? news.zh_cn_description
                              : lang === TRADITIONAL_CHINESE
                                ? news.zh_tw_description
                                : lang === KOREAN
                                  ? news.ko_description
                                  : lang === VIETNAM
                                    ? news.vi_description
                                    : lang === THAILAND
                                      ? news.th_description
                                      : news.en_description}
                        </TextS>
                      )}

                      {/* Rank up */}
                      {news.kind === RANK_UP && (
                        <TextS>
                          {t('お客様のランクがアップされました。')}
                        </TextS>
                      )}

                      {/* Campaign */}
                      {news.kind === CAMPAIGN && (
                        <TextS>{t('キャンペーン')}</TextS>
                      )}
                    </NewsWrapS>
                  </NewsBlockS>
                  <AllowButtonS onClick={(e) => goToNewsUsersShowPage(e, news.id)}>
                    <AllowS />
                  </AllowButtonS>
                </BoxS>
              </ListS>
            ))}
          {/* 0件取得時メッセージ */}
          {isEmpty && (
            <MessageWrapS>
              <p>{t('お知らせはありません。')}</p>
            </MessageWrapS>
          )}

          {/* エラーメッセージ */}
          {errorMessage && (
            <MessageWrapS>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </MessageWrapS>
          )}
        </UlistS>

        {/* さらに表示するボタン */}
        {newsUsersPagination?.total > LIST_VIEW_LIMIT && (
          <RoundButtonWrapS>
            {isLoading ? (
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {!newsUsersPagination.last
                  ? t('さらに表示する')
                  : t('表示を閉じる')}
              </RoundButton>
            ) : (
              <RoundButton
                onClick={getApiNewsUsersSelect}
                background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                borderColor="rgba(0,135,167,1)"
              >
                {!newsUsersPagination.last
                  ? t('さらに表示する')
                  : t('表示を閉じる')}
              </RoundButton>
            )}
          </RoundButtonWrapS>
        )}
      </NewsLayout>
    </>
  );
});
export default NewsUsersIndex;

/**
 *
 * Styles
 *
 */
const UlistS = styled.ul`
  width: 100%;
  min-height: 300px;
  position: relative;
`;

const ListS = styled.li`
  background: rgba(0, 0, 0, 0.2);

  :nth-child(even) {
    background: rgba(0, 0, 0, 0.4);
  }
`;

const BoxS = styled.div`
  color: #ffffff;

  // flex
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconBrockS = styled.div`
  width: 15%;
  text-align: center;

  @media (min-width: 600px) {
    width: 10%;
  }
`;

const RoundIcon = styled.p<{ status: number }>`
  font-size: 2rem;
  color: #87cefa;
  display: ${({ status }) => (status === 0 ? 'block' : 'none')};

  @media (min-width: 1025px) {
    font-size: 2.5rem;
  }
`;

const NewsBlockS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  padding: 3rem 0;

  @media (min-width: 600px) {
    width: 80%;
  }

  @media (min-width: 1025px) {
    padding: 5rem 0;
  }
`;

const NewsWrapS = styled.button``;

const TitleS = styled.p`
  margin-bottom: 1.3rem;
  font-size: min(6vw, 1.6rem);
  line-height: 1.5;
  word-break: break-all;
  // flex
  display: flex;
  font-weight: bold;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
    font-size: 1.7rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
`;

const TextS = styled.p`
  font-size: min(6vw, 1.4rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-width: 70vw;

  @media (min-width: 600px) {
    font-size: 1.5rem;
    width: 800px;
  }

  @media (min-width: 1025px) {
    font-size: 1.7rem;
  }
`;

const AllowButtonS = styled.button`
  width: 20%;
  cursor:;

  @media (min-width: 600px) {
    width: 10%;
  }
`;

const AllowS = styled.div`
  margin: 0 auto;
  border-top: solid 2px;
  border-right: solid 2px;
  width: 1.4rem;
  height: 1.4rem;
  transform: rotate(45deg);

  @media (min-width: 600px) {
    width: 1.7rem;
    height: 1.7rem;
  }

  @media (min-width: 1025px) {
    width: 2rem;
    height: 2rem;
  }
`;

const SkipWrapS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.2rem;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;

  @media (min-width: 1025px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
`;

const SkipButtonS = styled.button`
  width: 200px;
  display: flex;
  justify-content: center;
  background-color: #ff0000;
  border-radius: 8px;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 1rem 2rem;

  &:hover {
    opacity: 0.7;
  }
`;

const MessageWrapS = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-size: min(6vw, 1.2rem);

  @media (min-width: 600px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.7rem;
  }
`;

const RoundButtonWrapS = styled.div`
  width: 50%;
  margin-top: 5rem;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 30%;
    font-size: 1.7rem;
  }

  @media (min-width: 1025px) {
    margin-top: 7rem;
    width: 25%;
    font-size: 2rem;
  }
`;
