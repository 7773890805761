import { viSeo } from 'utils/lang/seo/viSeo';
/**
 * ベトナム語
 */
export const vi = {
  // メタ情報
  ...viSeo,
  // ページ
  トップ: 'Đầu trang',
  新規会員登録: 'Đăng ký',
  入力内容の確認: 'Xác nhận',
  メール送信完了: 'Gửi email',
  メール認証: 'Đã xác minh địa chỉ email',
  ログイン: 'Đăng nhập',
  パスワードのリセット: 'Đặt lại mật khẩu',
  パスワードの再設定: 'Đặt lại mật khẩu của bạn',
  マイページ: 'Trang của tôi',
  アカウントの停止: 'Tạm ngưng tài khoản',
  メールアドレスの変更: 'Thay đổi địa chỉ email',
  'ポイント出金・交換': 'Rút tiền và trao đổi điểm',
  KYC書類アップロード: 'Tải lên hình ảnh tài liệu KYC',
  "Day Lott'sとは": "Day Lott's là gì ?",
  仕組み: 'Hệ thống',
  よくある質問: 'Hỏi Đáp',
  ランキング: 'RankingBảng xếp hạng',
  サポート: 'Hỗ trợ',
  お知らせ: 'Tin tức',
  言語: 'Ngôn ngữ',
  'NEWSはありません。': 'Không có tin tức.',
  '高額獲得者はいません。': 'Không có người thắng lớn.',
  '業界最高水準である、当選確率1/8も用意されているオンラインゲームです。還元率は75%!! 1ゲーム5Pからゲーム参加可能。5Pから10,000Pの当選も毎日開催されています。':
    'Đây là một trò chơi trực tuyến với tỷ lệ trúng thưởng là 1/8, đây là mức cao nhất trong ngành. Tỷ lệ trả thưởng là 75%!! Bạn có thể tham gia trò chơi từ 5P mỗi ván chơi. Tiền trúng thưởng từ 5P đến 10.000P cũng được tổ chức mỗi ngày.',
  "1day,1million,1richをテーマにDay Lott'sは運営をしています。毎日世界中のどこかでミリオネアがDay Lott'sから誕生しています。我々のサービスは、1日1,000,000Pの当選を目指しています。":
    "Day Lott's hoạt động theo chủ đề 1 ngày, 1 triệu, 1 người giàu. Mỗi ngày, ở đâu đó trên thế giới, các triệu phú được sinh ra từ Day Lott's. Dịch vụ của chúng tôi đặt mục tiêu trúng thưởng 1.000.000P mỗi ngày.",
  "Day Lott'sでは、入金、出金の際に仮想通貨を利用することができます。仮想通貨の利用により、ボーダーレスに、そしてスピードのある入出金が可能となっております。":
    "Tại Day Lott's, bạn có thể sử dụng tiền điện tử để nạp và rút tiền. Bằng cách sử dụng tiền điện tử, bạn có thể nạp cũng như rút tiền không biên giới và nhanh chóng.",
  'メールアドレスを登録フォームに入力し、会員登録を行います。':
    'Nhập địa chỉ email của bạn vào biểu mẫu đăng ký để đăng ký làm thành viên.',
  会員登録: 'Đăng ký',
  '入金・チャージ': 'Nạp tiền',
  '仮想通貨、振込など様々な方法でポイントをチャージすることができます。仮想通貨、振込など様々な方法でポイントをチャージすることができます。':
    'Bạn có thể nạp điểm bằng nhiều phương thức khác nhau như tiền điện tử, chuyển khoản.',
  チケット購入: 'Mua vé',
  'ゲームに参加するためにチケットを、ポイントで購入します。':
    'Mua vé bằng điểm để tham gia trò chơi.',
  当選の確定: 'Xác nhận trúng thưởng',
  'ゲームにエントリーをし、ゲームが開始されたら、すぐに当選の確定が分かります。当選確定の演出もお楽しみください！':
    'Ngay sau khi bạn tham gia trò chơi và trò chơi bắt đầu, bạn sẽ biết rằng mình đã trúng thưởng. Hãy tận hưởng màn trình diễn xác nhận trúng thưởng!',
  '当選が確定したら、即座にマイページに当選ポイントが反映されます。また、そのポイントを換金して出金することができます。':
    'Ngay sau khi xác nhận người trúng thưởng, điểm trúng thưởng sẽ được thể hiện trên Trang của Tôi. Bạn cũng có thể đổi số điểm đó để rút tiền.',
  // フッター
  利用規約: 'Điều khoản Sử dụng',
  お問い合わせ: 'Liên hệ với Chúng tôi',
  利用規約に同意し登録する: 'Hãy đồng ý với <0>Điều khoản Sử dụng</0> để đăng ký tài khoản.',
  '※ユーザー名は後から変更をすることはできません。': '*Tên người dùng không thể thay đổi sau này.',
  '※パスワードは大文字、小文字、数字を組み合わせて8文字以内で入力してください。': '*Mật khẩu phải là sự kết hợp của chữ hoa, chữ thường và số, không quá 8 ký tự.',
  プライバシーポリシー: 'Chính sách bảo mật',
  責任あるギャンブル: 'Cờ bạc có trách nhiệm ',
  "DayLott'sとこのウェブサイトは、Seven Eyes Ent. Company（会社番号227777/076/077および登録住所Sheetal Marg, Baluwater Kathmandu, Nepal）によって運営されています。 Seven Eyes Ent. Companyはネパール政府より発行された、Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010に基づき付与されたサブライセンスを通して正式な許可を保持しています。":
    "Day Lott's và website này được điều hành bởi Công ty Giải trí Seven Eyes (Seven Eyes Ent. Company). (Mã số công ty 227777/076/077 và địa chỉ đăng ký Sheetal Marg, Baluwater Kathmandu, Nepal). Công ty Giải trí Seven Eyes có giấy phép chính thức thông qua giấy phép phụ được cấp theo Cá cược thể thao, poker, bingo, trò chơi xổ số trực tuyến, Giấy phép số 109 ngày 29 tháng 7 năm 2010 do Chính phủ Nepal cấp.",
  プレイヤー保護について: 'Giới thiệu về Bảo vệ người chơi',
  責任のあるギャンブルポリシー: 'Chính sách cờ bạc có trách nhiệm',
  '1 .未成年者の保護': '1 .Bảo vệ trẻ vị thành niên',
  '2 .プレイのルール': '2 .Quy tắc chơi',
  '3 .結託': '3 .Thông đồng',
  '4 .カスタマーサービスと顧客からの苦情':
    '4 .Dịch vụ khách hàng và Khiếu nại của khách hàng',
  '4.1.カスタマーサービス': '4.1.Dịch vụ khách hàng',
  '4.2.苦情の提出': '4.2.Nộp đơn khiếu nại',
  "4.3.DayLott'sとの書面による連絡に含める必要がある情報":
    "4.3.Thông tin Bạn Phải Bao gồm trong Giao tiếp Bằng Văn bản của Bạn với DayLott's",
  '1 .ギャンブルの自己評価': '1 .Tự đánh giá cờ bạc',
  '2 .サポート組織': '2 .Tổ chức hỗ trợ',
  '3 .コントロールの維持': '3 .Duy trì kiểm soát',
  '3.1.自己排除': '3.1.Tự loại trừ',
  "インターネットはモバイル機器や世界中の家庭から簡単にアクセスできるため、責任のあるオンラインギャンブルは親の管理と監督に大きく依存しています。インターネット上での子供の安全を確保するため、DayLott'sは未成年者が不適切なオンライン資料にアクセスするのを防止するフィルタリングソフトウェアを使用するようにお客様に推奨します。DayLott'sでは、保護者に対して次のヒントを提供しています。":
    "Bởi vì Internet có thể dễ dàng truy cập từ các thiết bị di động và gia đình trên khắp thế giới, cờ bạc trực tuyến có trách nhiệm phụ thuộc rất nhiều vào sự kiểm soát và giám sát của cha mẹ. Để đảm bảo sự an toàn của trẻ em trên Internet, DayLott's khuyến khích khách hàng của mình sử dụng phần mềm lọc để ngăn trẻ vị thành niên truy cập tài liệu trực tuyến không phù hợp. DayLott's đưa ra những lời khuyên sau đây cho các bậc cha mẹ.",
  '自分のメールアドレスを安全に保つ。':
    'Giữ địa chỉ email và mật khẩu của bạn an toàn.',
  "自分のDayLott'sアカウントにログインしたときにコンピューターを無人で放置しない。":
    'Đừng để máy tính của bạn không được giám sát khi bạn đăng nhập vào tài khoản DayLott của mình.',
  '18歳未満の人がいかなるギャンブル活動への参加も許可しない。':
    'Không cho phép bất kỳ ai dưới 18 tuổi tham gia vào bất kỳ hoạt động cờ bạc nào.',
  '自分のクレジット/デビットカードおよびその他の金融口座の詳細を、子供の手の届かないところに保管する。':
    'Giữ thẻ tín dụng / thẻ ghi nợ và các chi tiết tài khoản tài chính khác ngoài tầm với của trẻ em.',
  'パスワードをコンピューターに保存しない。パスワードを書き留め、未成年者から離れた安全な場所に保管する。':
    'Không lưu trữ mật khẩu trên máy tính của bạn. Ghi lại mật khẩu và giữ chúng ở nơi an toàn tránh xa trẻ vị thành niên.',
  "共有コンピューターからDayLott'sにアクセスする場合、特にコンピューターを子供と共有する場合は、「記憶する」機能を使用しない。":
    'Khi truy cập DayLott\'s từ máy tính dùng chung, đặc biệt nếu máy tính dùng chung với trẻ em. Không sử dụng chức năng "ghi nhớ" khi truy cập DayLott\'s từ máy tính dùng chung, đặc biệt nếu máy tính được dùng chung với trẻ em.',
  '未成年のギャンブルによって引き起こされる違法性と潜在的な損害について、自分の子供たちに教育する。':
    'Giáo dục con cái của bạn về tính bất hợp pháp và thiệt hại tiềm ẩn do cờ bạc ở tuổi vị thành niên.',
  '未成年者によるすべての電子機器ならびにモバイルデバイスの使用には特に注意する。':
    'Đặc biệt cẩn thận về việc trẻ vị thành niên sử dụng tất cả các thiết bị điện tử và di động.',
  '自分の子供がギャンブルサイトを含む不適切な題材にアクセスするのを防ぐために、フィルタリングソフトウェアを活用する。':
    'Sử dụng phần mềm lọc để ngăn con bạn truy cập tài liệu không phù hợp, bao gồm cả các trang web cờ bạc.',
  "未成年者のギャンブルは違法であるだけでなく、監督されていない子供が負った早期のギャンブル依存と実質的なギャンブルでの借金につながる可能性があります。したがって、自分の携帯電話、タブレット、ラップトップまたはコンピューターを法定年齢に満たない友人または家族と共有してオンラインギャンブルに参加する場合、DayLott'sは以下のいずれかのサービスを使用して、ウェブサイトへのアクセスを制限することを推奨します。":
    "Đánh bạc của trẻ vị thành niên không chỉ là bất hợp pháp mà còn có thể dẫn đến nghiện cờ bạc sớm và các khoản nợ cờ bạc đáng kể do trẻ em không được giám sát. Do đó, nếu bạn chia sẻ điện thoại di động, máy tính bảng, máy tính xách tay hoặc máy tính của mình với bạn bè hoặc thành viên gia đình chưa đủ tuổi hợp pháp để tham gia cờ bạc trực tuyến, DayLott's khuyên bạn nên hạn chế quyền truy cập của họ vào trang web bằng cách sử dụng một trong các dịch vụ sau.",
  'Netnanny.com - 不適切なウェブコンテンツから子供を守るフィルタリングソフトウェア。':
    'Netnanny.com - Phần mềm lọc bảo vệ trẻ em khỏi nội dung web không phù hợp.',
  'Cybersitter.com - 保護者がブロックするサイトを自身で追加することができるフィルタリングソフトウェア。':
    'Cybersitter.com - Phần mềm lọc cho phép cha mẹ thêm các trang web của riêng họ để chặn.',
  'betterinternetforkids.eu - EUが共同出資し、インターネットフィルタソフトウェアに関する情報を提供し、無料のテスト版を提供するウェブサイト。':
    'betterinternetforkids.eu - Một trang web do EU đồng tài trợ cung cấp thông tin về phần mềm lọc internet và cung cấp các phiên bản thử nghiệm miễn phí.',
  その他の重要なヒント: 'Các mẹo quan trọng khác',
  'ギャンブルをする決定は自分の個人的な選択であることを確認してください。次の場合は、ギャンブルをすることはおすすめしません。':
    'Hãy chắc chắn rằng quyết định đánh bạc của bạn là sự lựa chọn cá nhân của riêng bạn. Cờ bạc không được khuyến khích trong các trường hợp sau đây.',
  '毎日の責務を妨げる場合。': 'Nó cản trở các trách nhiệm hàng ngày của bạn.',
  '依存症の治療をしている、あるいは回復している場合。':
    'Bạn đang điều trị hoặc phục hồi sau cơn nghiện.',
  'アルコールやその他の物質の影響を受けている場合。':
    'Bạn đang bị ảnh hưởng của rượu hoặc các chất khác.',
  '以前の損失を取り戻そうとしている場合。あるいは賞金で借金を回収することが主な目的となっている場合。':
    'Nếu bạn đang cố gắng khôi phục lại sự mất mát trước đây của mình. Hoặc khi mục tiêu chính là đòi nợ bằng tiền thắng cược của bạn.',
  "DayLott'sでゲームを始める前に、ゲームのルールを知ることをおすすめします。":
    "Trước khi bắt đầu một trò chơi trong DayLott's, bạn nên biết các quy tắc của trò chơi.",
  '顧客が他の顧客と結託していると思われる場合や、不正行為を行った場合は、直ちにカスタマーサービスにご連絡ください。':
    'Nếu bạn nghi ngờ rằng một khách hàng đang thông đồng với một khách hàng khác hoặc thực hiện bất kỳ hành vi gian lận nào, vui lòng liên hệ với bộ phận Dịch vụ Khách hàng ngay lập tức.',
  'お客様はメールでカスタマーサービスにいつでも連絡することができます。':
    'Khách hàng có thể liên hệ với bộ phận chăm sóc khách hàng bất cứ lúc nào qua email.',
  "いかなる場合でも問題が発生してから3か月以内に、顧客の苦情/申し立てを提出する必要があります。お客様の苦情/申し立てが正確な部署に向けられ、調査されていることを確実にするために、以下の手段を介して書面による連絡をDayLott'sに提出しなければなりません。":
    "Trong mọi trường hợp, khiếu nại / cáo buộc của khách hàng phải được gửi trong vòng ba tháng kể từ khi sự cố xảy ra. Để đảm bảo rằng khiếu nại / cáo buộc của khách hàng được chuyển đến đúng bộ phận và được điều tra, thông báo bằng văn bản phải được gửi cho DayLott's thông qua các phương tiện sau.",
  電子メール: 'Email',
  "お客様のプライバシーを保護するために、お客様とDayLott's間のすべての電子メールによる通信は、DayLott'sで保持されているお客様の顧客アカウントに登録したメールアドレスを使用して実行する必要があります。そうでない場合、当社の対応が遅れることがあります。DayLott'sとの(苦情を含む) あらゆる書面による連絡には、以下の情報を含める必要があります。":
    "Để bảo vệ quyền riêng tư của bạn, tất cả các liên lạc qua email giữa bạn và DayLott's phải được thực hiện bằng địa chỉ email đã đăng ký trong tài khoản khách hàng của bạn được duy trì với DayLott's. Nếu không, phản hồi của chúng tôi có thể bị chậm trễ. Bất kỳ liên lạc bằng văn bản nào với DayLott's (bao gồm cả các khiếu nại) phải bao gồm các thông tin sau.",
  'お客様のニックネーム/電話番号': 'Biệt hiệu / số điện thoại của bạn',
  お客様の顧客アカウントに登録済みの氏名:
    'Tên của bạn xuất hiện trong tài khoản khách hàng của bạn',
  '苦情/申し立ての詳細': 'Chi tiết về khiếu nại / cáo buộc',
  '苦情/申し立てに関連する特定の日付と時刻 (該当する場合)':
    'Ngày và giờ cụ thể liên quan đến khiếu nại / cáo buộc (nếu có)',
  "DayLott'sでは、お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。":
    "Tại DayLott's, chúng tôi cam kết mang đến cho bạn trải nghiệm cờ bạc thú vị nhất có thể, nhưng chúng tôi cũng nhận ra rằng cờ bạc có thể gây ra vấn đề cho một số người. Để đảm bảo rằng bạn có thể tiếp tục tận hưởng trò chơi an toàn và có thể quản lý được, chúng tôi ủng hộ việc đánh bạc có trách nhiệm và đã áp dụng một số biện pháp để hỗ trợ bạn.",
  'ギャンブルによりお客様自身 (またはその他の人) の人生が支配されている可能性があると懸念する場合、次の質問で判明することがあるかもしれません。':
    'Nếu bạn lo ngại rằng cờ bạc có thể đang kiểm soát cuộc sống của bạn (hoặc của người khác), những câu hỏi sau có thể giúp bạn tìm hiểu.',
  'つまらない日常や不幸な人生を忘れるためにギャンブルをしますか？':
    'Bạn có đánh bạc để quên đi cuộc sống tẻ nhạt hay bất hạnh của mình không?',
  'ギャンブルをしていてお金が足りなくなったら、喪失感を味わい絶望を感じ、できるだけ早く再びギャンブルをする必要がありますか？':
    'Nếu bạn đang đánh bạc và hết tiền, bạn có cảm thấy mất mát và tuyệt vọng và cần phải đánh bạc lại càng sớm càng tốt?',
  '最後の1円がなくなるまでギャンブルをしますか？それが、たとえば家への交通費や生活費だとしてもですか？':
    'Bạn có đánh bạc cho đến khi đồng xu cuối cùng của bạn không còn nữa không? Bạn sẽ đánh bạc cho đến khi đồng xu cuối cùng của bạn biến mất, ngay cả khi đó là chi phí vận chuyển về nhà hay chi phí sinh hoạt?',
  'ギャンブルに費やした金額や時間の嘘をついてごまかしたことがありますか？':
    'Bạn đã bao giờ nói dối và gian lận về số tiền hoặc thời gian bạn đã dành cho cờ bạc?',
  'ギャンブルが原因で家族、友人、趣味への興味を失ったことがありますか？':
    'Bạn đã bao giờ mất hứng thú với gia đình, bạn bè hoặc sở thích của mình vì cờ bạc?',
  '負けた後、できるだけ早くもう一度挑戦して損失を勝たなければならないと感じますか？':
    'Sau khi thua, bạn có cảm thấy bắt buộc phải thử lại càng sớm càng tốt để giải quyết các thua lỗ của mình không?',
  '議論、不満、失望によりギャンブルをしたくなりますか？':
    'Những tranh luận, những thất vọng hay thất vọng có khiến bạn muốn đánh bạc không?',
  'ギャンブルをすることが原因で落ち込む、あるいは自分自身に害を及ぼすと感じることがありますか？':
    'Bạn có cảm thấy chán nản hoặc làm hại bản thân vì cờ bạc không?',
  'これらの質問に "はい" という答えが多いほど、深刻なギャンブルの問題を抱えている可能性が高くなります。':
    'Bạn càng đưa ra nhiều câu trả lời "có" cho những câu hỏi này, bạn càng có nhiều khả năng mắc phải một vấn đề cờ bạc nghiêm trọng.',
  'この問題について相談するには、カスタマーサービスに連絡するか、無料で独立したアドバイスを提供する数々のサポート組織があります。ご相談希望の方は、自国のサポート組織・機関に問い合わせをして下さい。':
    'Để thảo luận về vấn đề này, bạn có thể liên hệ với bộ phận dịch vụ khách hàng hoặc một trong nhiều tổ chức hỗ trợ cung cấp lời khuyên miễn phí, độc lập. Vui lòng liên hệ với tổ chức hỗ trợ ở quốc gia của bạn nếu bạn muốn thảo luận về vấn đề của mình.',
  'お客様あるいは知り合いの方がギャンブルの問題を抱えている場合、認定された組織からの支援を検討することをおすすめします。':
    'Nếu bạn hoặc ai đó bạn biết có vấn đề về cờ bạc, bạn có thể muốn xem xét nhận sự trợ giúp từ một tổ chức được công nhận.',
  "DayLott'sでは、お客様にギャンブルアカウントの管理に積極的に関与することを推奨しています。そのために、当社はさまざまな責任のあるゲーミング機能を提供しています。":
    "DayLott's khuyến khích khách hàng của chúng tôi đóng vai trò tích cực trong việc quản lý tài khoản cờ bạc của họ.",
  'もしお客様がギャンブルに対し問題が生じたと思われる場合には、カスタマーサービスへ連絡をし、アカウントへ自己排除期間を適用するように要請してください。':
    'Nếu bạn tin rằng bạn có vấn đề với cờ bạc, vui lòng liên hệ với Dịch vụ khách hàng và yêu cầu áp dụng thời gian tự loại trừ cho tài khoản của bạn.',
  以下の自己排除期間がご利用可能です:
    'Có các khoảng thời gian tự loại trừ sau đây',
  '1週間': '1 tuần',
  '2週間': '2 tuần',
  '1ヶ月': '1 tháng',
  '3ヶ月': '3 tháng',
  '6ヶ月': '6 tháng',
  "できる限り速やかに自己排除を適用するように努めますが、このプロセスにはある程度の作業時間が必要となりますのでご理解いただきますようお願いいたします。自己排除期間は実装されてお客様へ連絡されるまで、開始したものとはみなされませんのでご注意ください。もしその後も引き続きサービスにアクセス可能な場合には、責任をもってすぐ当社までご連絡ください。自己排除に入った時点でのDayLott'sの未確定ベットは、決済されます。お客様のベットが勝ちだった場合、勝ち金は該当する口座へ送金されます。自己排除期間が終了したあと、お客様のアカウントは自動的に再開されますのでご注意下さい。":
    "Chúng tôi sẽ cố gắng áp dụng quy trình tự loại trừ sớm nhất có thể, nhưng xin hiểu rằng quá trình này có thể mất một khoảng thời gian. Xin lưu ý rằng giai đoạn tự loại trừ sẽ không được coi là đã bắt đầu cho đến khi bạn được thông báo. Nếu bạn tiếp tục truy cập vào dịch vụ sau thời gian đó, bạn có trách nhiệm liên hệ với chúng tôi ngay lập tức. Mọi cược đặt chưa giải quyết trên DayLott's tại thời điểm tự loại trừ sẽ được giải quyết. Nếu cược thắng, tiền thắng cược của bạn sẽ được chuyển vào tài khoản có liên quan. rằng tài khoản của bạn sẽ tự động được mở lại sau khi hết thời gian tự loại trừ.",
  "DayLott'sは開かれる可能性のある新しい重複アカウントを検出し、閉鎖するためにできる限りのことを常に行っております。自己排除が適用されると、いかなるマーケティング資料も受け取ることはなくなります。":
    "DayLott's sẽ luôn làm mọi thứ có thể để phát hiện và đóng bất kỳ tài khoản trùng lặp mới nào có thể được mở. Sau khi áp dụng tính năng tự loại trừ, bạn sẽ không còn nhận được bất kỳ tài liệu tiếp thị nào nữa.",
  // フォーム
  メールアドレス: 'Địa chỉ email',
  ユーザー名: 'Tên người dùng',
  パスワード: 'Mật khẩu',
  'パスワード(確認用)': 'Mật khẩu xác nhận',
  新しいパスワード: 'Mật khẩu mới',
  '新しいパスワード(確認用)': 'Xác nhận mật khẩu mới',
  ファイルを選択: 'Chọn tệp',
  'ドラッグ＆ドロップするかファイルを選択してください':
    'Kéo và thả hoặc chọn một tệp',
  // ボタン
  修正する: 'Sửa chữa',
  送信する: 'Gửi',
  再送する: 'Gửi lại',
  確認する: 'Xác nhận',
  登録する: 'Đăng ký',
  入金する: 'Nạp tiền',
  出金する: 'Rút tiền',
  受け取る: 'Nhận',
  アップロード: 'Tải lên',
  削除: 'Xóa',
  停止: 'Dừng lại',
  選択: 'Lựa chọn',
  送信: 'Nộp',
  戻る: 'Quay lại',
  設定する: 'Thiết lập',
  // トッページ
  さらに表示する: 'Đọc thêm.',
  // 新規会員登録ページ
  '新規会員登録(タイトル)': 'Tạo tài khoản',
  // 新規会員登録確認ページ
  '内容に誤りがないか、よくご確認ください。':
    'Xin vui lòng đảm bảo rằng thông tin đã nhập là chính xác.',
  // 新規会員登録完了ページ
  メールの送信が完了しました: 'Đã gửi email.',
  '以下のメールアドレスに確認メールを送りました。メール記載のURLから登録を完了してください。':
    'Một email đã được gửi đến địa chỉ email sau đây. Xin vui lòng hoàn thành quá trình đăng ký từ URL có trong email.',
  TOPに戻る: 'Lên đầu trang',
  // メール認証
  '認証に成功しました。': 'Quá trình xác thực đã thành công.',
  ログインページへ: 'Đến trang đăng nhập',
  // ログイン
  'Bunny Paradise Worldのアカウントでもログインできますが、初回はパスワードのリセットが必要です。': 'Bạn cũng có thể đăng nhập bằng tài khoản Bunny Paradise World của mình, nhưng cần phải thiết lập lại mật khẩu lần đầu.',
  'ログインができない、パスワードを忘れた方はこちら':
    'Nếu bạn không thể đăng nhập hoặc quên mật khẩu của mình, hãy nhấp vào đây.',
  "DayLott'sのリンクをホーム画面に追加すると次回以降簡単にアクセスができます。": 'Thêm liên kết DayLott vào màn hình chính của bạn để dễ dàng truy cập vào lần sau.',
  iPhoneの場合: 'For iPhone',
  '1. ブラウザ(Safari)でWEBページを開いた状態で、下部の「共有アイコン」をタップ。': '1. mở một trang web trong trình duyệt của bạn (Safari) và nhấn vào "biểu tượng chia sẻ" ở dưới cùng.',
  '2. 共有メニューに表示される「ホーム画面に追加」をタップ。': '2. Nhấn vào "Thêm vào Màn hình chính" trên menu Chia sẻ.',
  '3. 名称などをご確認いただき、画面右上の「追加」をタップ。': '3. Nhấn vào "Thêm" ở góc trên bên phải của màn hình sau khi xác nhận tên, v.v.',
  '4. ホーム画面にアイコンが追加されたら完了です。': '4. Khi biểu tượng được thêm vào Màn hình chính, nghĩa là bạn đã hoàn tất.',
  確認して閉じる: 'Xác nhận và đóng',
  // パスワードリセット
  '登録済みのメールアドレスにパスワード再設定用のURLを送信します。':
    'Url để đặt lại mật khẩu sẽ được gửi đến địa chỉ email đã đăng ký.',
  パスワードのリセットを申請する: 'Yêu cầu đặt lại mật khẩu',
  '再送しました。': 'Gửi lại thành công.',
  // パスワードの再設定
  新しいパスワードを設定する: 'Đặt mật khẩu mới',
  '新しいパスワードを入力した後、「設定する」を押してください。':
    'Sau khi nhập mật khẩu mới, nhấn "Đặt".',
  // パスワードの再設定(完了)
  パスワードの変更が完了しました: 'Thay đổi mật khẩu đã hoàn tất.',
  // マイページ
  'ポイント購入履歴はありません。': 'Không có lịch sử mua điểm.',
  初回登録ボーナス: 'Phần thưởng đăng ký ban đầu',
  入金ボーナス: 'Tiền thưởng khi gửi tiền',
  紹介ボーナス: 'Tiền thưởng giới thiệu',
  クレジット購入: 'Mua bằng tín dụng',
  ビットコイン購入: 'Mua bằng Bitcoin',
  振り込み購入: 'Mua bằng chuyển khoản tiền mặt',
  テザー購入: 'Mua bởi Tether',
  イーサリアム購入: 'Mua bằng Ethereum',
  'ポイント出金・交換履歴はありません。':
    'Không có lịch sử rút / trao đổi điểm.',
  'べット履歴はありません。': 'Không có lịch sử đặt cược.',
  '当選履歴はありません。': 'Không có lịch sử trúng thưởng.',
  '〇回ゲームをプレイすると出金を行うことができます。': 'Bạn có thể rút tiền sau khi chơi trò chơi ◯◯ lần.',
  所持ポイント: 'Số điểm sở hữu',
  生年月日: 'Ngày sinh',
  '※ポイントの出金・交換をする際にはKYC認証が必須となります。': '※Cần xác thực KYC khi rút hoặc đổi điểm.',
  未設定: 'Not set',
  登録情報: 'Thông tin\nĐăng ký',
  KYC提出: 'KYC',
  'ポイントデポジット出金・交換': 'Rút/Đổi\nđiểm đã nạp',
  プレイ履歴: 'Lịch sử',
  編集: 'Chỉnh sửa',
  ログアウト: 'Đăng xuất',
  KYC確認中: 'Đang kiểm tra KYC',
  KYC却下: 'KYC bị từ chối',
  書類のタイプを選択する: 'Chọn loại giấy tờ',
  パスポート: 'Hộ chiếu',
  免許証: 'Giấy phép lái xe',
  // アカウントの停止(確認)
  'アカウント情報、所持チケット、所持ポイント等が失われます。確認してから「停止」ボタンを押してください。':
    'Thông tin tài khoản, vé, điểm, v.v., sẽ bị xóa. Xin vui lòng xác nhận rồi nhấp vào nút "Đình chỉ".',
  '※この操作は取り消しができません。': '※Không thể hủy thao tác này.',
  // アカウントの停止(完了)
  アカウント停止完了: 'Đình chỉ vĩnh viễn',
  'アカウントの停止が完了しました。ご利用いただきありがとうございました。':
    'Chúng tôi tạm ngưng tài khoản của bạn vĩnh viễn. cảm ơn bạn đã sử dụng.',
  // メールアドレスの変更
  '入力したメールアドレスにメールアドレス再設定用のURLを送信します。':
    'Url để đặt lại địa chỉ email sẽ được gửi đến địa chỉ email bạn đã nhập.',
  // KYC書類アップロード(フォーム)
  パスポートのアップロード: 'Tải lên (Hộ chiếu)',
  免許証のアップロード: 'Tải lên (Giấy phép lái xe)',
  年: 'Năm',
  月: 'Tháng',
  日: 'Ngày',
  パスポートの写真をアップロード: 'Tải lên ảnh hộ chiếu.',
  '免許証 ( 表 ) の写真をアップロード':
    'Tải lên ảnh mặt trước giấy phép lái xe.',
  '免許証 ( 裏 ) の写真をアップロード':
    'Tải lên ảnh mặt sau giấy phép lái xe.',
  '1つ前に戻る': 'Quay lại',
  '画像をアップロードしてください。': 'Xin vui lòng tải lên hình ảnh.',
  // KYC書類アップロード(確認)
  提出内容の確認: 'Xác nhận',
  送信内容の確認: 'Xác nhận gửi nội dung',
  '以下の内容で送信します。よろしければ送信を押してください。':
    'Nếu thông tin bạn cung cấp là chính xác, xin vui lòng nhấp vào nút "Gửi".',
  '免許証 ( 表 )': 'Giấy phép lái xe (Mặt trước)',
  '免許証 ( 裏 )': 'Giấy phép lái xe (Mặt sau)',
  // KYC書類アップロード(完了)
  本人確認書類送信完了: 'Đã hoàn thành việc gửi giấy tờ xác minh danh tính',
  '審査結果はメールにてお知らせいたします。今しばらくお待ちください。':
    'Chúng tôi sẽ thông báo kết quả kiểm tra qua email. Xin vui lòng đợi trong giây lát.',
  マイページへ戻る: 'Quay lại trang của tôi',
  // ポイント購入
  ポイント購入: 'Mua điểm',
  ポイント: 'điểm',
  ドル: 'đô la',
  円: 'yên',
  レートを確認する: 'Kiểm tra tỷ giá',
  ボーナスポイント: 'Điểm thưởng',
  'ボーナスポイントは購入ポイント獲得時に付与されます。': 'Điểm thưởng được trao khi bạn kiếm được điểm mua.',
  購入方法を選択: 'Chọn phương thức thanh toán',
  仮想通貨: 'Nạp tiền bằng tiền điện tử',
  クレジット: 'Thẻ tín dụng',
  現金振込: 'Chuyển tiền mặt',
  'ページを更新して再度お試しください。':
    'Hãy làm mới trang và thử lại.',
  'データを取得できませんでした。': 'Lấy dữ liệu không thành công.',
  '最低購入ポイント数は10ポイントです。':
    'Số điểm tối thiểu để mua là 10 điểm.',
  'お名前(必須)': 'Tên của bạn (bắt buộc)',
  '姓(カナ)': 'Họ (Kana)',
  '名(カナ)': 'Tên (Kana)',
  '全角カタカナで入力してください。': 'Vui lòng nhập katakana có chiều rộng đầy đủ.',
  '口座名義は入力必須です。': 'Chủ tài khoản phải được nhập.',
  // ポイント購入（仮想通貨）
  'レート表記はページにアクセスした時点のものです。入金時の数値とは異なる場合がございます。':
    'Ký hiệu tỷ lệ là thời điểm trang được truy cập. Nó có thể khác với giá trị tại thời điểm gửi tiền.',
  仮想通貨で入金: 'Nạp tiền bằng tiền điện tử',
  送金アドレス: 'Địa chỉ email chuyển tiền',
  アドレスを再取得: 'Tìm nạp lại địa chỉ email',
  'コピーしました。': 'Đã sao chép.',
  'コピー失敗しました。': 'Sao chép không thành công.',
  // ポイント購入（クレジット）
  クレジットカードで入金: 'Nạp tiền bằng thẻ tín dụng',
  有効期限: 'Ngày hết hạn',
  クレジットカード番号: 'Số thẻ',
  カード名義人: 'Tên',
  支払い回数: 'Số lượng thanh toán',
  一括払い: 'Thanh toán một lần',
  '3回払い': 'Thanh toán ba lần',
  '6回払い': 'Thanh toán sáu lần',
  セキュリティコード: 'Mã bảo mật',
  '以上の内容でよろしければ「入金する」ボタンをクリックしてください。':
    'Nếu bạn hài lòng với các nội dung trên, xin vui lòng nhấp vào nút "Nạp tiền".',
  'お支払い手続きが完了いたします。':
    'Thủ tục thanh toán sẽ hoàn tất.',
  // ポイント購入（現金）
  現金で入金: 'Nạp tiền bằng tiền mặt',
  '支払額を以下の口座にお振込ください。':
    'Vui lòng chuyển số tiền thanh toán vào tài khoản sau.',
  依頼人名: 'Tên khách hàng',
  銀行名: 'Tên Ngân hàng',
  支店名: 'Tên chi nhánh',
  店番号: 'Mã số cửa hàng',
  口座科目: 'Loại tài khoản',
  口座番号: 'Số tài khoản',
  口座名義: 'Chủ tài khoản',
  // ポイント購入完了（共通）
  入金情報送信完了: 'Đã hoàn tất quá trình truyền thông tin giao dịch thanh toán',
  '確認ができ次第メールにてお知らせいたします。今しばらくお待ちください。':
    'Sau khi xác nhận, chúng tôi sẽ thông báo cho bạn qua email. Xin vui lòng đợi trong giây lát.',
  // ポイント出金・交換(トップ)
  '出金・交換ポイント数': 'Rút/Đổi điểm',
  '出金・交換をするポイント数を入力してください。': 'Xin vui lòng nhập số điểm để rút / đổi.',
  ポイント残高: 'Số dư điểm',
  '出金・交換方法を選択': 'Chọn phương thức rút tiền / trao đổi',
  '半角数字のみ入力してください。': 'Vui lòng chỉ nhập các số có nửa độ rộng.',
  '50ポイントから出金が可能です。': 'Có thể rút tiền từ 50 điểm.',
  '入力ポイントが所持ポイントを超えています。': 'Điểm đầu vào vượt quá điểm sở hữu.',
  '送金先アドレスを入力してください。': 'Vui lòng nhập địa chỉ chuyển tiền của bạn.',
  // ポイント出金・交換(仮想通貨)
  送金手続き: 'Quy trình chuyển tiền',
  送金内容の確認: 'Xác nhận thông tin chuyển tiền',
  送金額: 'Số tiền chuyển',
  送金先アドレス: 'Địa chỉ chuyển tiền',
  '上記内容に間違いがないことを確認して送金をしてください。送金確定後の返金や操作の取り消しはできません。': 'Đảm bảo rằng các thông tin trên là chính xác trước khi rút/đổi. Không thể hoàn lại tiền cũng như không thể hủy bỏ các thao tác sau khi việc chuyển tiền được xác nhận.',
  // ポイント出金・交換(受付完了)
  '出金・交換を受け付けました。': 'Giao dịch rút/đổi đã được chấp nhận.',
  '取引に問題があった場合はメールにてご連絡を致します。またご不明な点がありましたらサポートまでお問い合わせください。': 'Chúng tôi sẽ liên hệ qua email nếu giao dịch của bạn xảy ra bất kỳ vấn đề gì. Nếu bạn có bất kỳ câu hỏi nào, xin vui lòng liên hệ với bộ phận hỗ trợ.',
  // 履歴
  べット履歴: 'Lịch sử đặt cược',
  当選履歴: 'Lịch sử trúng thưởng',
  ポイント購入履歴: 'Lịch sử mua điểm',
  'ポイント出金・交換履歴': 'Lịch sử rút / đổi điểm',
  もっと見る: 'Đọc thêm',
  // DayLott'sとは
  "DayLott'sでは、毎日ミリオネアを排出する、世界初のゲームです。少額から大口まで、多彩なテーブルを用意することで、より多くの方にゲームを楽しんでもらうことができます。":
    "DayLott's là trò chơi đầu tiên trên thế giới sản sinh ra các triệu phú mỗi ngày. Chúng tôi cung cấp nhiều loại bàn chơi, từ nhỏ đến lớn để nhiều người có thể thưởng thức trò chơi hơn.",
  '業界最高水準の75%！！世界で１番ユーザーが獲得できるゲームになるようにこだわって作りました。そしてその割合は常に一定です。ゲームにエントリーをした全てのユーザーに平等に獲得するチャンスがあります。':
    '75% dẫn đầu ngành !! Chúng tôi đã biến nó thành nỗi ám ảnh của mình khi biến nó thành trò chơi sẽ có nhiều người dùng nhất trên thế giới. Và tỷ lệ phần trăm luôn không đổi. Mọi người dùng tham gia trò chơi đều có cơ hội chiến thắng ngang nhau.',
  'ビットコイン、テザー、イーサリアムでゲームを選ぶポイントを購入することができます。':
    'Bạn có thể mua điểm để chơi trò chơi bằng Bitcoin, Tether hoặc Ethereum.',
  '会員登録からエントリーまでたった3分で完了します。スマートフォンがあればいつでもどこでも気軽にゲームにエントリーをすることができます。':
    'Chỉ mất 3 phút từ khi đăng ký đến khi nhập cảnh. Chỉ cần có điện thoại thông minh là bạn có thể dễ dàng vào game mọi lúc mọi nơi.',
  // 仕組み
  '世界初、必ず購入した誰かが高額当選、ミリオネアを誕生させる。':
    ' Đầu tiên trên thế giới, ai đó luôn mua trò chơi sẽ giành được một số tiền lớn và trở thành Triệu phú vào ngày sinh nhật của mình.',
  '世界最高水準還元率が驚異の75%を誇るオンラインゲームです。':
    'Tỷ lệ hoàn vốn cao nhất thế giới là 75% đáng kinh ngạc đối với các trò chơi trực tuyến.',
  'エントリーするベットポイント、当選ポイントに応じたボックスが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステム。エントリーしたボックスのチケット購入数が満たされると即座に抽選が始まり、当選が確定します。世界一簡単な、世界一高額当選のできる世界一スピード感のあるオンラインゲームです。':
    "Các loại trò chơi Day Lott's được chuẩn bị dựa trên số điểm đặt cược mà người chơi tham gia và số điểm trúng thưởng. Quy tắc rất đơn giản: bạn chỉ cần mua một vé để tham gia. Ngay sau khi số lượng vé trong loại trò chơi Day Lott's bạn tham gia được bán hết, kỳ xổ số sẽ bắt đầu và xác nhận người trúng thưởng. Do đó, đây là trò chơi trực tuyến dễ nhất và nhanh nhất trên thế giới, nơi bạn có thể giành được những giải thưởng lớn nhất trên thế giới.",
  '対応端末 iOS 14.8以降 Android 12.0以降':
    'Thiết bị được hỗ trợ iOS 14.8 trở lên và Android 12.0 trở lên',
  'チケットを購入し、抽選ページにアクセス':
    'Mua vé và truy cập trang xổ số.',
  '抽選演出開始され、当選演出時にご自身で登録したユーザー名が枠内に止まりましたら当選確定':
    'Quy trình xổ số sẽ bắt đầu. Trong quá trình xổ số, nếu tên người dùng đã đăng ký của bạn nằm trong khung, bạn sẽ được xác nhận là người trúng thưởng.',
  '当選確定後、当選ポイントが反映され出金が可能':
    'Sau khi xác nhận người trúng thưởng, số điểm trúng thưởng sẽ được hiển thị và bạn có thể rút về nếu muốn.',
  ご購入された誰かが必ず高額当選:
    'Sẽ luôn có người mua vé giành được giải thưởng lớn.',
  "ボックス毎に設定された高額当選ポイントが、ご購入された誰かに当選します。Day Lott'sではボックスに設定されている当選ポイントは必ずユーザーに反映されます。":
    'Số điểm trúng thưởng lớn trong mỗi loại trò chơi Day Lott\'s sẽ do người mua vé trong loại trò chơi đó giành được. Trong Day Lott\'s, số điểm trúng thưởng trong mỗi loại trò chơi luôn được trao người trúng thưởng.',
  '安心・安全・透明性のある当選': 'Xổ số an toàn, bảo mật và minh bạch',
  "Day Lott'sでは、透明性のある運営を心がけています。全てのユーザーが当選したユーザーを確認することができます。また、個人情報の保護、入出金におけるセキュリティの強化をしているため、安全にプレイをして頂くことができます。":
    "Tại Day Lott's, chúng tôi luôn nỗ lực vì sự minh bạch. Tất cả người dùng có thể kiểm tra người dùng trúng thưởng. Ngoài ra, chúng tôi đã tăng cường bảo vệ thông tin cá nhân cũng như bảo mật việc rút và nạp. Bạn có thể chơi một cách an toàn.",
  バリエーションのある当選ポイント設定: 'Các thiết lập điểm trúng thưởng khác nhau',
  'ユーザーのニーズを満たしたプレイボックスを多く用意しています。小さなべットエントリーで高額当選ポイントを獲得することも可能です。最低5ポイントからエントリーが可能となっており、最大100ミリオンポイントの当選も実現します。':
    'Chúng tôi có nhiều loại trò chơi khác nhau đáp ứng nhu cầu của người dùng. Bạn cũng có thể trúng thưởng số điểm lớn với số tiền đặt cược nhỏ. Bạn có thể tham gia với số điểm tối thiểu là 5 điểm và trúng thưởng tối đa 100 triệu điểm.',
  // よくある質問
  入金: 'Nạp',
  出金: 'Rút',
  'よくある質問はありません。': 'No faq.',
  // ランキング
  一日: 'Hàng ngày',
  週間: 'Hàng tuần',
  月間: 'Hàng tháng',
  全期間: 'Tất cả',
  'ランキングはありません。': 'Không có bảng xếp hạng.',
  // サポート
  '当ページのよくある質問をまずはご覧ください。':
    'Xin vui lòng xem mục Hỏi Đáp trên trang này.',
  メールでのお問い合わせ: 'Gửi email cho bộ phận hỗ trợ',
  'までお問い合わせください。': 'Xin vui lòng liên hệ.',
  お問合せ時間: 'Thời gian hỗ trợ',
  '毎日対応させて頂いております。': 'Chúng tôi trả lời các câu hỏi của người dùng mỗi ngày.',
  '※お問い合わせの回答に数日頂く事がございます。3営業日以内には回答させて頂きます。':
    '*Có thể mất vài ngày để trả lời câu hỏi cụ thể của bạn. Chúng tôi sẽ trả lời trong vòng 3 ngày làm việc',
  // PLAYBOX
  テーブル選択: 'Lựa chọn bảng',
  予約状況: 'Trạng thái đặt chỗ',
  口: 'Vé',
  ゼウス: 'thần Zeus',
  キング: 'Nhà vua',
  ダイヤモンド: 'Kim cương',
  ゴールド: 'Vàng',
  シルバー: 'Bạc',
  ブロンズ: 'Đồng',
  アンバー: 'Hổ phách',
  口数選択: 'Chọn số lượng vé',
  購入する: 'Mua',
  キャンセル: 'Hủy',
  ダイヤモンド獲得確率: 'Tỷ lệ giành được kim cương',
  ゴールド獲得確率: 'Tỷ lệ giành được vàng',
  シルバー獲得確率: 'Tỷ lệ thắng bạc',
  ブロンズ獲得確率: 'Tỷ lệ giành HCĐ',
  獲得確率: 'Tỷ lệ trúng thưởng',
  獲得: 'Chiến thắng',
  確率: 'tỷ lệ',
  口購入: 'đơn vị mua',
  '所持ポイントが不足しています。': 'Bạn không có đủ điểm.',
  '購入するにはログインが必要です。': 'Bạn cần đăng nhập để mua hàng.',
  '1度に購入できるのは2000口までです。': 'Bạn có thể mua tối đa 4.000 đơn vị cùng một lúc.',
  // チケット購入確認
  購入内容のご確認: 'Xác nhận Thông tin Mua',
  '下記の内容で間違いないかご確認ください。':
    'Vui lòng kiểm tra những điều sau để đảm bảo không có sai sót.',
  '「購入する」ボタンをクリックすると購入が確定します。':
    'Nhấp vào nút "Mua" để hoàn tất việc mua hàng.',
  参加ボックス: 'Hộp tham gia',
  購入ポイント数: 'Điểm mua hàng',
  消費ポイント数: 'Điểm tiêu thụ',
  所持有償ポイント数: 'Sở hữu điểm được trả',
  所持無償ポイント数: 'Sở hữu điểm miễn phí',
  残り有償ポイント数: 'Điểm thanh toán còn lại',
  残り無償ポイント数: 'Điểm miễn phí còn lại',
  // チケット購入完了
  チケット番号: 'Số vé',
  番: 'Số ',
  '参加中のPLAY BOX': 'Đang tham gia PLAY BOX',
  抽選画面へ: 'Đến trang xổ số',
  // お知らせ一覧
  '購入済みのチケットの抽選が開始されました。矢印をタップすると結果を見ることができます。':
    'Cuộc xổ số đã bắt đầu. Bạn có thể xem kết quả bằng cách nhấn vào mũi tên.',
  'お客様のランクがアップされました。': 'Thứ hạng của bạn đã được cải thiện.',
  キャンペーン: 'Chiến dịch.',
  表示を閉じる: 'Đóng chế độ xem',
  // お知らせ
  'お知らせはありません。': 'Không có tin tức.',
  抽選の結果を見る: 'Kết quả xổ số.',
  抽選会場へ行く: 'đi xổ số',
  マイページで確認: 'Xác nhận trên Trang của Tôi',
  // お問い合わせ
  お問い合わせフォーム: 'Liên hệ với Chúng tôi',
  'お客様からのご質問をお問い合わせフォームにて受け付けております。':
    'Chúng tôi tiếp nhận các câu hỏi của khách hàng từ biểu mẫu liên hệ.',
  '下記項目をご記入の上、「確認する」を押してください。':
    'Xin vui lòng điền vào các mục sau đây rồi nhấn "Xác nhận". ',
  氏名: 'Họ và tên',
  お問い合わせ内容: 'Nội dung câu hỏi',
  // お問い合わせ確認
  お問い合わせ内容の確認: 'Xác nhận nội dung',
  // お問い合わせ受付完了
  'お問い合わせを受け付けました。': 'Chúng tôi đã nhận được câu hỏi của bạn.',
  'お問い合わせありがとうございます。': 'Xin cảm ơn bạn đã liên hệ.',
  'お問い合わせには3営業日以内に回答させて頂きます。':
    'Chúng tôi sẽ trả lời câu hỏi của bạn trong vòng 3 ngày làm việc.',
  // 抽選結果
  'このページは動画が自動再生されます。よろしければ"OK"を押してください。動画を再生せずポイントを受け取る場合は"受け取る"を押してください。':
    'Video sẽ tự động phát trên trang này. Vui lòng nhấn "OK" nếu bạn muốn. Để nhận điểm mà không cần phát video, hãy nhấp vào "Nhận".',
  Waiting: 'Đang chờ đợi',
  購入日時: 'Ngày mua',
  当選ポイント: 'Số điểm trúng thưởng',
  // ポイント受け取り
  獲得ポイント受け取り: 'Nhận điểm kiếm được',
  動画を再生せずに獲得した全てのポイントを受け取る: 'Nhận tất cả điểm kiếm được mà không cần phát video.',
  'ポイントを受け取りました。': 'Điểm nhận được.',
  // 共通エラー
  通信エラー: 'Lỗi kết nối.',
  サーバーエラー: 'Lỗi máy chủ',
  // バリエーションエラー
  'は入力必須です。': ' là thông tin bắt buộc.',
  'メールアドレスは正しい形式で入力してください。':
    'Xin vui lòng nhập địa chỉ Email của bạn theo đúng định dạng.',
  'ユーザー名は半角英数字のみで入力してください。':
    'Vui lòng nhập Tên người dùng chỉ sử dụng các ký tự chữ và số một byte.',
  'パスワードは大文字小文字を含む半角英数字を組み合わせて入力してください。':
    'Vui lòng nhập mật khẩu bằng sự kết hợp của các ký tự chữ và số một byte bao gồm cả chữ hoa và chữ thường.',
  'パスワードが一致しません。': 'Các mật khẩu không trùng khớp.',
  '画像は.jpgか.pngをアップロードしてください。':
    'Tải lên ảnh .jpg hoặc .png.',
  '金額を入れてください。': 'Vui lòng nhập số tiền.',
  '日本円での合計金額(手数料込)': 'Tổng số tiền (bao gồm phí) bằng yên Nhật',
  同意: 'Agree',
};
