import { useState } from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType } from 'types/api';

/**
 *
 * Types
 *
 */
type LocationState = {
  referrer: string;
  email: string;
};

/**
 *
 * メール送信完了ページ用ロジック
 *
 */
export const useEmailSend = (): {
  email: string;
  successMessage: string;
  errorMessage: string;
  referrer: string;
  referrerPettern: RegExp;
  redirect: () => void;
  goBack: () => void;
  isLoading: boolean;
  postApiResendEmail: () => void;
  cleanUpMethod: () => void;
} => {
  // i18-next
  const { t } = useTranslation();
  // locationオブジェクト
  const location = useLocation<LocationState>();
  // location.state代替オブジェクト
  const alternativeLocationState: LocationState = {
    referrer: '',
    email: '',
  };
  // 登録フォームからの入力内容
  const { referrer, email } = location.state ?? alternativeLocationState;
  // 前ページのパターン
  const referrerPettern = /^(\/register\/confirm|\/password_reset|\/email_change)$/;

  // historyオブジェクト
  const history = useHistory();
  // リダイレクト
  const redirect = () => {
    history.replace('/');
  };
  // 前のページに戻る
  const goBack = () => {
    switch (referrer) {
      case '/register/confirm':
        history.push('/register');
        break;
      case '/password_reset':
        history.push('/password_reset');
        break;
      case '/email_change':
        history.push('/email_change');
        break;
      default:
        history.push('/');
    }
  };

  // サクセスメッセージ
  const [successMessage, setSuccessMessage] = useState<string>('');
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');
  // エラー用カスタムフック
  const { errorHandler } = useError();

  // ローディングフラグ
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // axiosキャンセルトークン
  const axiosSource = axiosCancelToken.source();
  // 再送信リクエスト
  const postApiResendEmail = () => {
    if (!isLoading) {
      setSuccessMessage('');
      setErrorMessage('');
      setIsLoading(true);

      let apiPath: string;
      switch (referrer) {
        case '/register/confirm':
          apiPath = '/api/resend_verified';
          break;
        case '/password_reset':
          apiPath = '/api/forget_password';
          break;
        case '/email_change':
          apiPath = '/api/users/change_email';
          break;
        default:
          setIsLoading(false);

          return;
      }

      /**
       *
       * axios POST /api/resend_verified
       * axios POST /api/forget_password
       * axios POST /api/users/change_email
       *
       */
      axiosClient
        .post<boolean>(apiPath, {
          email,
          cancelToken: axiosSource.token,
        })
        .then(({ data }) => {
          if (data) {
            setSuccessMessage(t('再送しました。'));
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    setIsLoading(false);
    setSuccessMessage('');
    setErrorMessage('');
    // APIリクエストキャンセル
    axiosSource.cancel();
  };

  return {
    email,
    successMessage,
    errorMessage,
    referrer,
    referrerPettern,
    redirect,
    goBack,
    isLoading,
    postApiResendEmail,
    cleanUpMethod,
  };
};
