// External library
import { useTranslation } from 'react-i18next';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { VALIDATE } from 'utils/const/validate';
import { LANG } from 'utils/const/lang';

export const useValidate = (): {
  emailValidate: (value: string) => string;
  nameValidate: (value: string) => string;
  passwordValidate: (value: string) => string;
  confirmPasswordValidate: (value: string, confirmValue: string) => string;
  imageValidate: (file: File) => string;
  birthdayValidate: (year: string, month: string, day: string) => string;
  inquiryNameValidate: (value: string) => string;
  inquiryBodyValidate: (value: string) => string;
  cardNumberValidate: (value: string, length: number) => string;
  cardNameValidate: (value: string) => string;
  expiryValidate: (value: string) => string;
  cvcValidate: (value: string, length: number) => string;
} => {
  // Recoil 言語設定
  const lang = useRecoilValue(langState);
  // i18next
  const { t } = useTranslation();
  const {
    JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
  } = LANG;

  // 未入力
  const required = (value: string, field: string): string => {
    if (value === undefined || value === null || value === '') {
      return `${field}${t('は入力必須です。')}`;
    }

    return '';
  };

  // 最小数
  const min = (value: string, field: string, minNumber: number): string => {
    if (value.length < minNumber) {
      switch (lang) {
        case JAPANESE:
          return `${field}は${minNumber}文字以上で入力してください。`;
        case SIMPLE_CHINESE:
          return `${field}请输入至少${minNumber}个字符。`;
        case TRADITIONAL_CHINESE:
          return `${field}請輸入${minNumber}文字以上。`;
        case KOREAN:
          return `${field} 항목은 ${minNumber}자 이상으로 입력해 주세요`;
        case VIETNAM:
          return `${field} sử dụng ít nhất ${minNumber}nhân vật`;
        case THAILAND:
          return `${field} ใช้อย่างน้อย ${minNumber}ตัวอักษร`;
        default:
          return `Please enter ${field} using at least "${minNumber}" characters.`;
      }
    }

    return '';
  };

  // 最大数
  const max = (value: string, field: string, maxNumber: number): string => {
    if (value.length > maxNumber) {
      switch (lang) {
        case JAPANESE:
          return `${field}は${maxNumber}文字以内で入力してください。`;
        case SIMPLE_CHINESE:
          return `${field}请输入最多${maxNumber}个字符。`;
        case TRADITIONAL_CHINESE:
          return `${field}請輸入${maxNumber}文字以下。`;
        case KOREAN:
          return `${field} 항목은 ${maxNumber}자 이하로 입력해 주세요`;
        case VIETNAM:
          return `${field} sử dụng bên trong ${maxNumber}nhân vật`;
        case THAILAND:
          return `${field} ใช้ที่ภายใน ${maxNumber}ตัวอักษร`;
        default:
          return `Please enter ${field} using at within "${maxNumber}" characters.`;
      }
    }

    return '';
  };

  // アドレス形式
  const email = (value: string): string => {
    const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (!regex.test(value)) {
      return t('メールアドレスは正しい形式で入力してください。');
    }

    return '';
  };

  // ユーザーネーム(大文字を含む半角英数字のみ&空白禁止)
  const userName = (value: string): string => {
    const regex = /^[0-9a-zA-Z!#$%()*+-./:;=?@^_`{|}]+$/g;

    if (!regex.test(value)) {
      return t(
        'ユーザー名は半角英数字記号のみで入力してください。',
      );
    }

    return '';
  };

  // パスワードルール(大文字を含む半角英数字のみ&空白記号禁止)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const password = (value: string): string => {
    const regex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[0-9A-Za-z]+$/;

    if (!regex.test(value)) {
      return t(
        'パスワードは大文字小文字を含む半角英数字を組み合わせて入力してください。',
      );
    }

    return '';
  };

  // パスワード一致
  const confirm = (value: string, confirmValue: string): string => {
    if (value !== confirmValue) {
      return t('パスワードが一致しません。');
    }

    return '';
  };

  // 画像ファイル拡張子
  const extensions = (file: File) => {
    const allowExtensions = '.(jpeg|jpg|JPEG|JPG|png|PNG)$';
    if (!RegExp(allowExtensions).exec(file.name)) {
      return t('画像は.jpgか.pngをアップロードしてください。');
    }

    return '';
  };

  // クレジットカード: カード番号 / 有効期限 / セキュリティコード 形式
  const number = (value: string, field: string, length: number): string => {
    const regex = /^[0-9]+$/;

    if (!regex.test(value) || value.length !== length) {
      switch (lang) {
        case JAPANESE:
          return `${field}は正しい形式で入力してください。`;
        case SIMPLE_CHINESE:
          return `请输入正确格式的${field}。`;
        case TRADITIONAL_CHINESE:
          return `${field}請用正確形式輸入`;
        case KOREAN:
          return `${field} 항목은 올바른 형식으로 입력해 주세요.`;
        case VIETNAM:
          return `${field} theo đúng định dạng.`;
        case THAILAND:
          return `${field} ในรูปแบบที่ถูกต้อง`;
        default:
          return `Please enter ${field} in the correct format.`;
      }
    }

    return '';
  };

  // クレジットカード: カード名義人
  const cardName = (value: string, field: string): string => {
    const regex = /^[a-zA-Z\x20]*$/;

    if (!regex.test(value)) {
      switch (lang) {
        case JAPANESE:
          return `${field}は半角英字で入力してください。`;
        case SIMPLE_CHINESE:
          return `请输入半角数字的${field}。`;
        case TRADITIONAL_CHINESE:
          return `${field}請用半角數字輸入`;
        case KOREAN:
          return `${field} 항목은 숫자로 입력해 주세요.`;
        case VIETNAM:
          return `${field} bằng các ký tự tiếng Anh nửa chiều rộng.`;
        case THAILAND:
          return `${field} ด้วยตัวอักษรภาษาอังกฤษแบบครึ่งความกว้าง`;
        default:
          return `Please enter ${field} in half-width English characters.`;
      }
    }

    return '';
  };

  // メールアドレス
  const emailValidate = (value: string): string => {
    const field = t('メールアドレス');

    const result = required(value, field)
      || email(value)
      || max(value, field, VALIDATE.EMAIL_MAX);

    return result;
  };

  // ユーザー名
  const nameValidate = (value: string): string => {
    const field = t('ユーザー名');

    const result = required(value, field)
      || min(value, field, VALIDATE.NAME_MIN)
      || max(value, field, VALIDATE.NAME_MAX)
      || userName(value);

    return result;
  };

  // パスワード
  const passwordValidate = (value: string): string => {
    const field = t('パスワード');

    const result = required(value, field);
    // || min(value, field, VALIDATE.PASSWORD_MIN)
    // || max(value, field, VALIDATE.PASSWORD_MAX);

    return result;
  };

  // パスワード確認用
  const confirmPasswordValidate = (
    value: string,
    confirmValue: string,
  ): string => {
    const field = t('パスワード(確認用)');

    const result = required(confirmValue, field)
    // || password(value)
    // || min(confirmValue, field, VALIDATE.PASSWORD_MIN)
    // || max(confirmValue, field, VALIDATE.PASSWORD_MAX)
      || confirm(value, confirmValue);

    return result;
  };

  // 画像ファイル
  const imageValidate = (file: File): string => {
    const result = extensions(file);

    return result;
  };

  // 生年月日
  const birthdayValidate = (
    year: string,
    month: string,
    day: string,
  ): string => {
    let result = '';

    result = required(year, t('年'));
    if (result) {
      return result;
    }
    result = required(month, t('月'));
    if (result) {
      return result;
    }
    result = required(day, t('日'));
    if (result) {
      return result;
    }

    return result;
  };

  // お問合せ氏名
  const inquiryNameValidate = (value: string): string => {
    const field = t('氏名');

    const result = required(value, field) || max(value, field, VALIDATE.INQUIRY_NAME_MAX);

    return result;
  };

  // お問合せ内容
  const inquiryBodyValidate = (value: string): string => {
    const field = t('お問い合わせ内容');

    const result = required(value, field) || max(value, field, VALIDATE.INQUIRY_MAX);

    return result;
  };

  // カード番号
  const cardNumberValidate = (value: string, length: number): string => {
    const field = t('クレジットカード番号');

    const result = required(value, field) || number(value, field, length);

    return result;
  };

  // カード名義人
  const cardNameValidate = (value: string): string => {
    const field = t('カード名義人');

    const result = required(value, field) || cardName(value, field);

    return result;
  };

  // 有効期限
  const expiryValidate = (value: string): string => {
    const field = t('有効期限');

    const result = required(value, field) || number(value, field, 4);

    return result;
  };

  // カード番号
  const cvcValidate = (value: string, length: number): string => {
    const field = t('セキュリティコード');

    const result = required(value, field) || number(value, field, length);

    return result;
  };

  return {
    emailValidate,
    nameValidate,
    passwordValidate,
    confirmPasswordValidate,
    imageValidate,
    birthdayValidate,
    inquiryNameValidate,
    inquiryBodyValidate,
    cardNumberValidate,
    cardNameValidate,
    expiryValidate,
    cvcValidate,
  };
};
