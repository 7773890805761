import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Utils
import { devices } from 'utils/const/devices';

/**
 *
 * Types
 *
 */
type Props = {
  zIndex?: number;
};

/**
 *
 * Component
 *
 */
const LoaderPart: FC<Props> = memo(({ zIndex = 999 }) => (
  <LoaderPartS zIndex={zIndex}>
    <CycleS />
  </LoaderPartS>
));
export default LoaderPart;

/**
 *
 * Styles
 *
 */
const {
  tabletPortrait, tabletLandscape, pc,
} = devices;

export const LoaderPartS = styled.div<{ zIndex: number }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
  background-color: rgba(0, 0, 0, 0.4);
  // flex
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CycleS = styled.div`
  border-radius: 50%;
  width: clamp(30px, 15vw, 60px);
  height: clamp(30px, 15vw, 60px);
  font-size: 1rem;
  position: relative;
  text-indent: -9999em;
  border-top: 1rem solid rgba(255, 255, 255, 0.6);
  border-right: 1rem solid rgba(255, 255, 255, 0.6);
  border-bottom: 1rem solid rgba(255, 255, 255, 0.6);
  border-left: 1rem solid #ffffff;
  transform: translateZ(0);
  // animation
  @keyframes loading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  animation: loading 1.1s infinite linear;

  @media ${tabletPortrait} {
    width: clamp(30px, 10vw, 60px);
    height: clamp(30px, 10vw, 60px);
  }

  @media ${tabletLandscape}, ${pc} {
    width: 10rem;
    height: 10rem;
  }

  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
`;
