// External library
import { useLocation } from 'react-router-dom';

/**
 *
 * お問い合わせ送信完了用ロジック
 *
 */
export const useInquiryFinish = (): {
  referrer: string;
} => {
  // locationオブジェクト
  const location = useLocation<{
    referrer: string;
  }>();
  // location.state代替オブジェクト
  const alternativeLocationState = {
    referrer: '',
  };
  // 前のページのパスを取得
  const { referrer } = location.state ?? alternativeLocationState;

  return {
    referrer,
  };
};
