import { useState } from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';

/**
 *
 * Types
 *
 */
type LocationState = {
  referrer: string;
  email: string;
  name: string;
  password: string;
  confirmPassword: string;
};

/**
 *
 * 新規会員登録確認ページ用ロジック
 *
 */
export const useRegisterConfirm = (): {
  isLoading: boolean;
  referrer: string;
  email: string;
  name: string;
  hiddenPassword: string;
  errorMessage: string;
  redirectToRegister: () => void;
  toBackRegister: () => void;
  postApiRegister: () => Promise<void>;
  cleanUpMethod: () => void;
} => {
  // Recoil言語状態
  const lang = useRecoilValue(langState);
  // historyオブジェクト
  const history = useHistory();
  // 登録フォームへリダイレクト
  const redirectToRegister = () => {
    history.replace('/register');
  };
  // 登録フォームへ遷移
  const toBackRegister = () => {
    history.push('/register');
  };

  // locationオブジェクト
  const location = useLocation<LocationState>();
  // location.state代替オブジェクト
  const alternativeLocationState: LocationState = {
    referrer: '',
    email: '',
    name: '',
    password: '',
    confirmPassword: '',
  };
  // 登録フォームからの入力内容
  const {
    referrer, email, name, password, confirmPassword,
  } = location.state ?? alternativeLocationState;

  // ローディングフラグ
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // エラー用カスタムフック
  const { errorHandler } = useError();
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Google reCAPTCHA v3
  const { executeRecaptcha } = useGoogleReCaptcha();

  // axiosキャンセルトークン
  const axiosSource = axiosCancelToken.source();
  // 新規会員登録リクエスト
  const postApiRegister = async () => {
    if (!isLoading) {
      setIsLoading(true);

      if (!executeRecaptcha) {
        setIsLoading(false);

        return;
      }
      // reCAPTCHAトークンを発行
      const recaptcha_token = await executeRecaptcha('regist');

      const utmId = localStorage.getItem('utmId');

      // axios POST /api/register
      axiosClient
        .post<boolean>('/api/register', {
          email,
          name,
          password,
          password_confirmation: confirmPassword,
          lang,
          utm_id: utmId ?? '',
          recaptcha_token,
          cancelToken: axiosSource.token,
        })
        .then(({ data }) => {
          if (data) {
            localStorage.removeItem('utmId');
            // 完了ページに遷移
            history.push({
              pathname: '/email_send',
              state: {
                referrer: '/register/confirm',
                email,
              },
            });
          }
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) setErrorMessage(errorHandler(error.response));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // 表示用秘匿パスワード
  const hiddenPassword = '*'.repeat(password.length);

  // クリーンアップ処理
  const cleanUpMethod = () => {
    setIsLoading(false);
    setErrorMessage('');
    axiosSource.cancel();
  };

  return {
    isLoading,
    referrer,
    email,
    name,
    hiddenPassword,
    errorMessage,
    redirectToRegister,
    toBackRegister,
    postApiRegister,
    cleanUpMethod,
  };
};
