import { FC } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { v4 as uuidv4 } from 'uuid';
// Components
import FormLayout from 'components/templates/FormLayout';
import NeonBorderTitle from 'components/molecules/title/NeonBorderTitle';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import RoundButton from 'components/atoms/button/RoundButton';
// Hooks
import { useWithdrawalCc } from 'hooks/pages/private/useWithdrawalCc';
// Utils
import { withdrawalCcBreadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';

const WithdrawalCc: FC = () => {
  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    itemList,
    onSubmit,
    goToWithdrawalTopPage,
  } = useWithdrawalCc();

  return (
    <>
      <Helmet>
        <title>{t('title.withdrawalCc')}</title>
        <meta name="description" content={t('description.withdrawalCc')} />
      </Helmet>

      {isLoading && <LoaderWindow />}

      <FormLayout
        breadCrumbItems={withdrawalCcBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('送金手続き')}
        titleStyle="gold"
      >
        <ContainerS>
          <NeonBorderTitle
            title={t('送金内容の確認')}
          />
          <FrameAreaS>
            <FrameS>
              {itemList.map((v) => (
                <ItemListS key={uuidv4()}>
                  <ItemNameS>{t(v.name)}</ItemNameS>
                  <ItemValueS>{v.value}</ItemValueS>
                </ItemListS>
              ))}
            </FrameS>
            <ConfirmTextS>
              {t('上記内容に間違いがないことを確認して送金をしてください。送金確定後の返金や操作の取り消しはできません。')}
            </ConfirmTextS>
          </FrameAreaS>

          <ButtonAreaS>
            <ButtonWrapS>
              {isLoading ? (
                <RoundButton
                  background="#555555"
                  borderColor="rgba(230, 230, 230, 1)"
                >
                  {t('送信')}
                </RoundButton>
              ) : (
                <RoundButton
                  background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                  borderColor="rgba(0,135,167,1)"
                  onClick={onSubmit}
                >
                  {t('送信')}
                </RoundButton>
              )}
            </ButtonWrapS>
            <ButtonWrapS>
              <RoundButton
                background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                borderColor="rgba(219,0,177,1)"
                onClick={goToWithdrawalTopPage}
              >
                {t('戻る')}
              </RoundButton>
            </ButtonWrapS>
          </ButtonAreaS>
        </ContainerS>
      </FormLayout>
    </>
  );
};
export default WithdrawalCc;

const ContainerS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  row-gap: 3rem;

  @media (min-width: 1025px) {
    width: 90%;
    margin-top: 3rem;
    row-gap: 4rem;
  }
`;

const FrameAreaS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1025px) {
    width: 50%;
  }
`;

const FrameS = styled.ul`
  width: 100%;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
`;

const ItemListS = styled.li`
  display: flex;
  flex-direction: column;
  row-gap: 0.7rem;
  color: #ffffff;
`;

const ItemNameS = styled.div`
  font-size: 1.4rem;
`;

const ItemValueS = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
`;

const ConfirmTextS = styled.p`
  font-size: 1.4rem;
  color: #ffffff;
  line-height: 1.5;
  word-break: break-word;
`;

const ButtonAreaS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center center;
  row-gap: 2rem;
`;

const ButtonWrapS = styled.div`
  width: clamp(140px, 33vw, 170px);
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;
