import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import FormLayout from 'components/templates/FormLayout';
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { useRegisterConfirm } from 'hooks/pages/guest/useRegisterConfirm';
// Utils
import { registerConfirmBreadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const RegisterConfirm: FC = () => {
  // i18next
  const { t } = useTranslation();
  // 新規会員登録確認ページ用カスタムフック
  const {
    isLoading,
    referrer,
    email,
    name,
    hiddenPassword,
    errorMessage,
    redirectToRegister,
    toBackRegister,
    postApiRegister,
    cleanUpMethod,
  } = useRegisterConfirm();

  useEffect(() => {
    // 登録フォーム以外からアクセスした場合TOPへリダイレクト
    if (referrer !== '/register') {
      redirectToRegister();
    }

    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', null);
    // ブラウザバックor進むをしたら会員登録ページにリダイレクト
    window.addEventListener('popstate', toBackRegister, false);

    return () => {
      cleanUpMethod();
      window.removeEventListener('popstate', toBackRegister, false);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.registerConfirmation')}</title>
        <meta
          name="description"
          content={t('description.registerConfirmation')}
        />
      </Helmet>

      {/* ローディングアニメーション */}
      {isLoading && <LoaderWindow />}

      <FormLayout
        breadCrumbItems={registerConfirmBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('新規会員登録(タイトル)')}
        titleStyle="gold"
      >
        <TextWrapS>
          <TextS>{t('内容に誤りがないか、よくご確認ください。')}</TextS>
        </TextWrapS>

        {/* エラーメッセージ */}
        {errorMessage && (
          <ErrorMessageWrapS>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </ErrorMessageWrapS>
        )}

        <ContainerS>
          <UListS>
            <ListS>
              <FieldS>{t('メールアドレス')}</FieldS>
              <ValueS>{email}</ValueS>
            </ListS>
            <ListS>
              <FieldS>{t('ユーザー名')}</FieldS>
              <ValueS>{name}</ValueS>
            </ListS>
            <ListS>
              <FieldS>{t('パスワード')}</FieldS>
              <ValueS>{hiddenPassword}</ValueS>
            </ListS>
          </UListS>
        </ContainerS>

        <ButtonGroupS>
          <RoundButtonWrapS className="button-fix">
            {isLoading ? (
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {t('修正する')}
              </RoundButton>
            ) : (
              <RoundButton
                onClick={toBackRegister}
                background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                borderColor="rgba(0,135,167,1)"
              >
                {t('修正する')}
              </RoundButton>
            )}
          </RoundButtonWrapS>
          <RoundButtonWrapS className="button-register-confirm">
            {isLoading ? (
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {t('送信する')}
              </RoundButton>
            ) : (
              <RoundButton
                onClick={postApiRegister}
                background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                borderColor="rgba(219,0,177,1)"
              >
                {t('送信する')}
              </RoundButton>
            )}
          </RoundButtonWrapS>
        </ButtonGroupS>
      </FormLayout>
    </>
  );
};
export default RegisterConfirm;

/**
 *
 * Styles
 *
 */
const TextWrapS = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
    margin-top: 2.4rem;
    margin-bottom: 3rem;
  }

  @media (min-width: 1025px) {
    width: 50%;
    margin-top: 3rem;
  }
`;

const TextS = styled.p`
  color: #ffffff;
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const ErrorMessageWrapS = styled.div`
  width: 100%;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: flex-start;
  word-break: break-word;

  @media (min-width: 600px) {
    width: 60%;
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    width: 50%;
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
  }
`;

const ContainerS = styled.div`
  width: 100%;
  padding: 2rem 2rem 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  margin-bottom: 2rem;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1025px) {
    width: 50%;
    margin-bottom: 3.4rem;
  }
`;

const UListS = styled.ul`
  width: 100%;
`;

const ListS = styled.li`
  margin-bottom: 2rem;
  color: #ffffff;
  word-break: break-word;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FieldS = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
`;

const ValueS = styled.p`
  font-size: 1.6rem;
`;

const ButtonGroupS = styled.div`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 1025px) {
    flex-direction: row;
    width: 50%;
  }
`;

const RoundButtonWrapS = styled.div`
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }

  &.button-fix {
    width: 50%;
    margin-bottom: 2rem;

    @media (min-width: 600px) {
      width: 40%;
    }

    @media (min-width: 1025px) {
      width: 30%;
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }

  &.button-register-confirm {
    width: 65%;

    @media (min-width: 600px) {
      width: 50%;
    }

    @media (min-width: 1025px) {
      width: 45%;
    }
  }
`;
