import { FC, memo, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import CompletionLayout from 'components/templates/CompletionLayout';
// Custom hooks
import { useKycFinish } from 'hooks/pages/private/useKycFinish';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const KycFinish: FC = memo(() => {
  // hooks
  const { t } = useTranslation();
  const history = useHistory();
  const { referrer } = useKycFinish();

  useEffect(() => {
    if (referrer !== '/kyc/confirm') {
      history.replace('/');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.kycCompleted')}</title>
        <meta name="description" content={t('description.kycCompleted')} />
      </Helmet>
      <CompletionLayout
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title="本人確認書類送信完了"
        to="/mypage"
        linkText="マイページへ戻る"
      >
        <TextS>
          {t(
            '審査結果はメールにてお知らせいたします。今しばらくお待ちください。',
          )}
        </TextS>
      </CompletionLayout>
    </>
  );
});
export default KycFinish;

/**
 *
 * Styles
 *
 */
const TextS = styled.p`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 5rem;
  text-align: center;

  @media (min-width: 600px) {
    width: 60%;
    font-size: 1.6rem;
  }

  @media (min-width: 1025px) {
    width: 40%;
  }
`;
