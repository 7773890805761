// External library
import { useTranslation } from 'react-i18next';
// Recoil
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { langState } from 'store/atoms/langState';

/**
 *
 * Types
 *
 */
type UseClear = () => {
  clearUserState: () => void;
};

/**
 *
 * Hooks
 *
 */
export const useClear: UseClear = () => {
  // Recoil ユーザー情報更新関数
  const setUserData = useSetRecoilState(userState);
  // Recoil 言語設定更新関数
  const lang = useRecoilValue(langState);

  // Hooks
  const { i18n } = useTranslation();

  // ユーザー情報リセット
  const clearUserState = () => {
    setUserData({
      loggedIn: false,
      id: 0,
      name: '',
      email: '',
      ethAddress: '',
      point: 0,
      freePoint: 0,
      birthday: '',
      unreadNewsCount: 0,
      unreadNewsUsersCount: 0,
      unlotteryBetsCount: 0,
      kycFlg: 0,
    });

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_in');

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(lang);
  };

  return {
    clearUserState,
  };
};
