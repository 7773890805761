import { atom } from 'recoil';
import { ATOM_KEYS } from 'utils/const/recoilKeys';
import { PlayBoxId } from 'types/playBoxType';

/**
 *
 * Types
 *
 */
type playBoxShowStateType = {
  initialBoxId: PlayBoxId;
  isShow1000: boolean;
  isShow5000: boolean;
  isShow10000: boolean;
  isShow100000: boolean;
  isShow1000000: boolean;
};

export const playBoxShowState = atom<playBoxShowStateType>({
  key: ATOM_KEYS.PLAYBOX_SHOW_STATE,
  default: {
    initialBoxId: '',
    isShow1000: false,
    isShow5000: false,
    isShow10000: false,
    isShow100000: false,
    isShow1000000: false,
  },
});
