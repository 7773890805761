import { selector } from 'recoil';
import { userState } from 'store/atoms/userState';
import { SELECTOR_KEYS } from 'utils/const/recoilKeys';

export const userPointDisplayState = selector<string>({
  key: SELECTOR_KEYS.USER_POINT_DISPLAY_STATE,
  get: ({ get }) => {
    const { point, freePoint } = get(userState);
    const totalPoint = point + freePoint;

    return totalPoint.toLocaleString();
  },
});
