import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type Props = {
  children: ReactNode;
  color: string;
};

/**
 *
 * Component
 *
 */
const ColorSelect: FC<Props> = memo((props) => {
  const { children, color } = props;

  return <ColorSelectS color={color}>{children}</ColorSelectS>;
});
export default ColorSelect;

/**
 *
 * Styles
 *
 */
const ColorSelectS = styled.div<{ color: string }>`
  display: inline-flex;
  width: 100%;
  padding: 1rem 0.5rem;
  font-size: inherit;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid ${({ color }) => color};
  position: relative;

  &::after {
    height: 100%;
    content: '▼';
    padding: 1.2em 7px;
    font-size: 0.8em;
    color: ${({ color }) => color};
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid ${({ color }) => color};
    pointer-events: none;

    @media (min-width: 600px) {
      padding: 1em 7px;
    }

    @media (min-width: 1025px) {
      content: '▽';
      font-size: 0.7em;
      text-shadow: 0.1px 0.1px 0, 0.1px -0.1px 0px, -0.1px 0.1px 0,
        -0.1px -0.1px 0px, 0.1px 0px 0px, 0px 0.1px 0px, -0.1px 0px 0px,
        0px -0.1px 0px;
    }
  }
`;
