import { FC } from 'react';
// External library
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
// Components
import CompletionLayout from 'components/templates/CompletionLayout';
// Hooks
import { useWithdrawalFinish } from 'hooks/pages/private/useWithdrawalFinish';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const WithdrawalFinish: FC = () => {
  // Hooks
  const { t } = useTranslation();
  useWithdrawalFinish();

  return (
    <>
      <Helmet>
        <title>{t('title.withdrawalFinish')}</title>
        <meta name="description" content={t('description.withdrawalFinish')} />
      </Helmet>
      <CompletionLayout
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title="出金・交換を受け付けました。"
        to="/mypage"
        linkText="マイページへ戻る"
      >
        <TextS>
          {t(
            '取引に問題があった場合はメールにてご連絡を致します。またご不明な点がありましたらサポートまでお問い合わせください。',
          )}
        </TextS>
      </CompletionLayout>
    </>
  );
};
export default WithdrawalFinish;

/**
 *
 * Styles
 *
 */
const TextS = styled.p`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 5rem;
  text-align: center;

  @media (min-width: 600px) {
    width: 60%;
    font-size: 1.6rem;
    line-height: 1.6;
  }

  @media (min-width: 1025px) {
    width: 40%;
  }
`;
