import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { ATOM_KEYS } from 'utils/const/recoilKeys';

// 永続化用Recoilプラグイン
const { persistAtom } = recoilPersist();

/**
 * ゲスト時言語設定フラグ
 */
export const isChangeLangState = atom<boolean>({
  key: ATOM_KEYS.IS_CHANGE_LANG_STATE,
  default: false,
  effects_UNSTABLE: [persistAtom],
});
