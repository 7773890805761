import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import CrossIcon from 'components/atoms/icon/CrossIcon';
// Hooks
import { useUfcTransition } from 'hooks/organisms/useUfcTransition';
// Recoil
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { userPointDisplayState } from 'store/selectors/userPointDisplayState';
// Images
import logo from 'assets/images/header/logo.svg';

/**
 *
 * Types
 *
 */
type Props = {
  isMenuShow: boolean;
  toggleMenuShow: (state: boolean) => void;
  children: ReactNode;
};

/**
 *
 * Component
 *
 */
const GlobalModal: FC<Props> = memo((props) => {
  const { isMenuShow, toggleMenuShow, children } = props;

  // i18next
  const { t } = useTranslation();

  // Recoil ユーザー情報
  const { loggedIn, name } = useRecoilValue(userState);
  const point = useRecoilValue(userPointDisplayState);

  const {
    transitToUfc,
  } = useUfcTransition();

  return (
    <GlobalModalS className={isMenuShow ? 'show' : ''}>
      <HeaderS>
        <LogoS>
          <LogoImageS src={logo} alt="" />
        </LogoS>
        <CloseButtonS onClick={() => toggleMenuShow(false)}>
          <CrossIcon />
        </CloseButtonS>
      </HeaderS>
      <BodyS>
        <BodyHeaderS>
          {loggedIn ? (
            <UserS>
              <UserNameS>
                <UserNameTextS>{name}</UserNameTextS>
              </UserNameS>
              <UserPointS>
                <UserPointUnitS>{t('所持ポイント')}</UserPointUnitS>
                <UserPointTextS>{`${point} P`}</UserPointTextS>
              </UserPointS>
            </UserS>
          ) : (
            <SignButtonWrapS>
              <Link className="link-signin" to="/login">
                <SignInButtonS
                  onClick={() => toggleMenuShow(false)}
                  type="button"
                >
                  {t('ログイン')}
                </SignInButtonS>
              </Link>
              <SignUpButtonS
                className="link-signup"
                onClick={() => transitToUfc('register')}
                type="button"
              >
                {t('新規会員登録')}
              </SignUpButtonS>
            </SignButtonWrapS>
          )}
        </BodyHeaderS>
        <BodyContentsS>{children}</BodyContentsS>
      </BodyS>
    </GlobalModalS>
  );
});
export default GlobalModal;

/**
 *
 * Styles
 *
 */
const GlobalModalS = styled.div`
  height: 100%;
  width: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #000000;
  white-space: nowrap;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
  // position
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  // animation
  transition: 0.2s;

  @media (min-width: 1025px) {
    display: none;
  }

  &.show {
    width: 100%;
  }
`;

const HeaderS = styled.div`
  min-height: 5rem;
  height: 5rem;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 600px) {
    margin: 0.5rem auto 0;
  }
`;

const LogoS = styled.div`
  height: 80%;

  @media (min-width: 600px) {
    height: 100%;
  }
`;

const LogoImageS = styled.img`
  height: 100%;
`;

const CloseButtonS = styled.button`
  width: 3rem;
  height: 3rem;
`;

const BodyS = styled.div`
  height: 100%;
  width: 90%;
  margin: 1rem auto 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BodyHeaderS = styled.div`
  width: 100%;
  border-bottom: 1px solid #ffffff;
`;

const SignButtonWrapS = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link-signin,
  .link-signup {
    width: 100%;
  }
`;

const SignButtonS = styled.button`
  width: 100%;
  font-size: 1.4rem;
  padding: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }
`;

const SignInButtonS = styled(SignButtonS)`
  color: #fff040;
`;

const SignUpButtonS = styled(SignButtonS)`
  color: #ffffff;
`;

const UserS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #ffffff;
`;

const UserNameS = styled.div`
  width: 50%;
  padding-bottom: 1rem;
`;

const UserNameTextS = styled.p`
  font-size: 1.6rem;
`;

const UserPointS = styled.div`
  width: 50%;
  padding-bottom: 1rem;
`;

const UserPointUnitS = styled.p`
  text-align: end;
  margin-bottom: 0.5rem;
`;

const UserPointTextS = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  text-align: end;
`;

const BodyContentsS = styled.div`
  width: 100%;
  height: 100%;
`;
