import { FC } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';

const PrivateRoute: FC<RouteProps> = ({ path, component }) => {
  const user = useRecoilValue(userState);

  return user.loggedIn ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to="/login" />
  );
};

export default PrivateRoute;
