import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import SuccessMessage from 'components/atoms/text/SuccessMessage';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { useEmailSend } from 'hooks/pages/common/useEmailSend';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const EmailSend: FC = () => {
  // i18-next
  const { t } = useTranslation();
  // 新規会員登録完了ページ用カスタムフック
  const {
    isLoading,
    successMessage,
    errorMessage,
    email,
    referrer,
    referrerPettern,
    redirect,
    goBack,
    postApiResendEmail,
    cleanUpMethod,
  } = useEmailSend();

  useEffect(() => {
    // 特定ページ以外からのアクセスはトップページへリダイレクト
    if (!referrerPettern.test(referrer)) {
      redirect();
    }

    // ブラウザバックしたら指定のページにリダイレクト
    window.addEventListener('popstate', goBack, false);

    return () => {
      cleanUpMethod();
      window.removeEventListener('popstate', goBack, false);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.emailSend')}</title>
        <meta name="description" content={t('description.emailSend')} />
      </Helmet>
      <EmailSendS>
        <ContainerS>
          <TitleS>
            <TitleTextS>{t('メールの送信が完了しました')}</TitleTextS>
          </TitleS>

          {/* ローディングアニメーション */}
          {isLoading && <LoaderWindow />}

          {/* 再送成功メッセージ */}
          {successMessage && (
            <MessageWrapS>
              <SuccessMessage>{successMessage}</SuccessMessage>
            </MessageWrapS>
          )}

          {/* 再送エラーメッセージ */}
          {errorMessage && (
            <MessageWrapS>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </MessageWrapS>
          )}

          <ContentS>
            <ContentTextS>
              {t(
                '以下のメールアドレスに確認メールを送りました。メール記載のURLから登録を完了してください。',
              )}
            </ContentTextS>
          </ContentS>

          <EmailS>
            <EmailTextS>{email}</EmailTextS>
          </EmailS>

          <ButtonS>
            <RoundButtonWrapS>
              {isLoading ? (
                <RoundButton
                  background="#555555"
                  borderColor="rgba(230, 230, 230, 1)"
                >
                  {t('再送する')}
                </RoundButton>
              ) : (
                <RoundButton
                  onClick={postApiResendEmail}
                  background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                  borderColor="rgba(219,0,177,1)"
                >
                  {t('再送する')}
                </RoundButton>
              )}
            </RoundButtonWrapS>
          </ButtonS>

          <TopLinkS>
            <Link className="link-top" to="/">
              {t('TOPに戻る')}
            </Link>
          </TopLinkS>
        </ContainerS>
      </EmailSendS>
    </>
  );
};
export default EmailSend;

/**
 *
 * Styles
 *
 */
const EmailSendS = styled.main`
  background-image: url(${backGroundSp});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (min-width: 1025px) {
    background-image: image-set(
      url(${backGroundPc}) 1x,
      url(${backGroundPc2x}) 2x
    );
    background-position: top;
  }
`;

const ContainerS = styled.div`
  height: 100%;
  width: 90%;
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 15rem;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    padding-top: 9rem;
  }

  @media (min-width: 1025px) {
    width: 100%;
    max-width: 100rem;
    padding-top: 7rem;
    padding-bottom: 10rem;
  }
`;

const ItemS = styled.div`
  width: 100%;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1025px) {
    width: 50%;
  }
`;

const TitleS = styled(ItemS)`
  margin-bottom: 2rem;
`;

const TitleTextS = styled.p`
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 600;

  @media (min-width: 600px) {
    font-size: 2rem;
  }

  @media (min-width: 1025px) {
    font-size: 2.4rem;
  }
`;

const ContentS = styled(ItemS)`
  margin-bottom: 1.4rem;
`;

const ContentTextS = styled.p`
  font-size: 1.4rem;
  line-height: 1.6;
  color: #ffffff;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }
`;

const EmailS = styled(ItemS)`
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 4rem;

  @media (min-width: 1025px) {
    padding: 1.2rem;
  }
`;

const EmailTextS = styled.p`
  font-size: 1.6rem;
  color: #ffffff;
  word-break: break-word;

  @media (min-width: 1025px) {
    font-size: 2rem;
  }
`;

const MessageWrapS = styled(ItemS)`
  font-size: 1.6rem;
  margin-bottom: 2rem;

  @media (min-width: 1025px) {
    font-size: 1.8rem;
  }
`;

const ButtonS = styled(ItemS)`
  margin-bottom: 3rem;
`;

const RoundButtonWrapS = styled.div`
  width: 65%;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const TopLinkS = styled(ItemS)`
  .link-top {
    font-size: 1.4rem;
    font-weight: 200;
    color: #fff040;
    text-decoration: underline;

    @media (min-width: 1025px) {
      font-size: 1.6rem;
    }
  }
`;
