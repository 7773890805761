import { useState } from 'react';
// External library
import { AxiosError } from 'axios';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType, RankingType } from 'types/api';

// ランキングページ用ロジック
type Kind = 'daily' | 'weekly' | 'monthly' | 'all';
export const useRanking = (): {
  isLoading: boolean;
  isTabActive: Kind;
  ranking: RankingType[];
  errorMessage: string;
  isEmpty: boolean;
  getApiRanking: (kind: Kind) => void;
  setIsTabActive: React.Dispatch<React.SetStateAction<Kind>>;
  // クリーンアップ関数
  cleanUpMethod: () => void;
} => {
  // ローディング状態
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // エラー用カスタムフック
  const { errorHandler } = useError();
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');
  // 0件フラグ
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  // タブメニュー
  const [isTabActive, setIsTabActive] = useState<Kind>('daily');

  // ランキングデータ
  const initialRanking = {
    user_id: 0,
    rank: 0,
    name: '',
    point: '',
    count: 0,
    ymd: '',
  };
  const [ranking, setRanking] = useState<RankingType[]>([
    {
      ...initialRanking,
    },
  ]);

  // ランキングデータリクエスト
  const axiosRankingSource = axiosCancelToken.source();
  const getApiRanking = (kind: Kind) => {
    if (!isLoading) {
      setRanking([{ ...initialRanking }]);
      setIsLoading(true);
      setIsTabActive(kind);
      setIsEmpty(false);
      setErrorMessage('');

      // axios GET /api/ranking/(kind)
      axiosClient
        .get<RankingType[]>(`/api/ranking/${kind}`, {
          cancelToken: axiosRankingSource.token,
        })
        .then(({ data }) => {
          if (data.length !== 0) {
            setRanking([...data]);
          } else {
            setIsEmpty(true);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    // APIリクエストキャンセル
    axiosRankingSource.cancel();
    // データ
    setRanking([{ ...initialRanking }]);
    setErrorMessage('');
    setIsEmpty(false);
  };

  return {
    isLoading,
    // タブメニュー
    isTabActive,
    ranking,
    errorMessage,
    isEmpty,
    getApiRanking,
    setIsTabActive,
    // クリーンアップ関数
    cleanUpMethod,
  };
};
