import { FC, memo, ComponentPropsWithoutRef } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type ButtonCssProps = {
  background: string;
  borderColor: string;
};

type Props = ComponentPropsWithoutRef<'button'> & ButtonCssProps;

/**
 *
 * Component
 *
 */
const RoundButton: FC<Props> = memo((props) => {
  const { children } = props;

  return (
    <RoundButtonS
      {...props}
      type={props.type ?? 'button'}
    >
      {children}
    </RoundButtonS>
  );
});
export default RoundButton;

/**
 *
 * Styles
 *
 */
const RoundButtonS = styled.button<ButtonCssProps>`
  width: 100%;
  position: relative;
  padding: 0.8rem 2rem;
  border-radius: 9999px;
  background: ${({ background }) => background};
  border-color: ${({ borderColor }) => borderColor};
  border-width: 2px;
  border-style: solid;
  color: #ffffff;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }
`;
