import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'react-i18next';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Custom hooks
import { useFaq } from 'hooks/pages/common/useFaq';
// Components
import SupportLayout from 'components/templates/SupportLayout';
import LoaderPart from 'components/atoms/loader/LoaderPart';
import Question from 'components/organisms/faq/Question';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Util
import { LANG } from 'utils/const/lang';
import { ROWS } from 'utils/const/questionCategoriesRows';
// Images
import supportBarTopSp from 'assets/images/neonItem/bar-tiplist-top_sp.png';
import supportBarBottomSp from 'assets/images/neonItem/bar-tiplist-bottom_sp.png';

const { SP, TAB, PC } = ROWS;
const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN,
  VIETNAM, THAILAND,
} = LANG;

/**
 *
 * Component
 *
 */
const Faq: FC = () => {
  // Recoil言語設定
  const lang = useRecoilValue(langState);

  // Hooks
  const { t } = useTranslation();
  const path = useLocation().pathname.slice(1);
  const {
    dataList,
    isLoading,
    emptyMessage,
    errorMessage,
    getApiFaqSelect,
    cleanUpMethod,
  } = useFaq();

  useEffect(() => {
    // FAQ情報取得
    getApiFaqSelect();

    return () => {
      cleanUpMethod();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.faq')}</title>
        <meta name="description" content={t('description.faq')} />
      </Helmet>
      <SupportLayout path={path}>
        <ContentS>
          {/* ローディングアニメーション */}
          {isLoading && <LoaderPart />}
          {/* カテゴリーボタン */}
          <CategoryS length={dataList.length}>
            {dataList.length > 0
              && dataList.map((data) => (
                <CategoryLinkWrapS key={uuidv4()}>
                  <AnchorLink href={`#${data.id}`}>
                    <CategoryTextWrapS>
                      <TextS>
                        {lang === JAPANESE
                          ? data.name
                          : lang === SIMPLE_CHINESE
                            ? data.zh_cn_name
                            : lang === TRADITIONAL_CHINESE
                              ? data.zh_tw_name
                              : lang === KOREAN
                                ? data.ko_name
                                : lang === VIETNAM
                                  ? data.vi_name
                                  : lang === THAILAND
                                    ? data.th_name
                                    : data.en_name}
                      </TextS>
                    </CategoryTextWrapS>
                  </AnchorLink>
                </CategoryLinkWrapS>
              ))}
          </CategoryS>

          {/* 質問一覧 */}
          <UlistS>
            {dataList.length > 0
              && dataList.map((data) => (
                <CategoryListS key={uuidv4()}>
                  <CategoryHeadS id={String(data.id)}>
                    <CategoryHeadTextS>
                      {lang === JAPANESE
                        ? data.name
                        : lang === SIMPLE_CHINESE
                          ? data.zh_cn_name
                          : lang === TRADITIONAL_CHINESE
                            ? data.zh_tw_name
                            : lang === KOREAN
                              ? data.ko_name
                              : lang === VIETNAM
                                ? data.vi_name
                                : lang === THAILAND
                                  ? data.th_name
                                  : data.en_name}
                    </CategoryHeadTextS>
                    <BorderS />
                  </CategoryHeadS>
                  <QuestionUlistS>
                    {data.questions.map((question) => (
                      <ListS key={uuidv4()}>
                        <SupportBarS>
                          <SupportBarImageS
                            src={supportBarTopSp}
                            alt=""
                            loading="lazy"
                          />
                        </SupportBarS>
                        <Question faq={question} />
                        <SupportBarS>
                          <SupportBarImageS
                            src={supportBarBottomSp}
                            alt=""
                            loading="lazy"
                          />
                        </SupportBarS>
                      </ListS>
                    ))}
                  </QuestionUlistS>
                </CategoryListS>
              ))}
          </UlistS>

          {/* 0件取得メッセージ */}
          {emptyMessage && (
            <MessageS>
              <p>{emptyMessage}</p>
            </MessageS>
          )}

          {/* エラーメッセージ */}
          {errorMessage && (
            <MessageS>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </MessageS>
          )}
        </ContentS>
      </SupportLayout>
    </>
  );
};

export default Faq;

/**
 *
 * Styles
 *
 */
const ContentS = styled.div`
  position: relative;
  margin-bottom: 10rem;
  min-height: 305px;
  width: 100%;
  color: #ffffff;

  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    min-height: 420px;
  }

  @media (min-width: 1025px) {
    max-width: 100rem;
    margin-bottom: 20rem;
  }
`;

const CategoryS = styled.div<{ length: number }>`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 3rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: ${({ length }) => `repeat(calc(${length}/${SP}), 1fr)`};
  grid-gap: 0.5rem;

  @media (min-width: 600px) {
    width: 95%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: ${({ length }) => `repeat(calc(${length}/${TAB}), 1fr)`};
    grid-gap: 1rem;
  }

  @media (min-width: 1025px) {
    margin-top: 3rem;
    margin-bottom: 5rem;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: ${({ length }) => `repeat(calc(${length}/${PC}), 1fr)`};
  }
`;

const CategoryLinkWrapS = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);

  :hover {
    color: #87cefa;

    @media (min-width: 600px) {
      border: 1px solid rgba(135, 206, 250, 0.7);
    }
  }
`;

const CategoryTextWrapS = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
`;

const UlistS = styled.ul`
  width: 100%;
`;

const CategoryListS = styled.li`
  margin-bottom: 2.6rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    margin-bottom: 10rem;
  }

  &:last-child {
    margin-bottom: 0rem;
  }
`;

const CategoryHeadS = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 600px) {
    width: 95%;
  }

  @media (min-width: 1025px) {
    margin-bottom: 2rem;
  }
`;

const CategoryHeadTextS = styled.p`
  font-size: 1.6rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  line-height: 1.6;

  @media (min-width: 600px) {
    font-size: 1.8rem;
    margin-bottom: 1.8rem;
  }

  @media (min-width: 1025px) {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
`;

const BorderS = styled.hr``;

const QuestionUlistS = styled.ul`
  width: 100%;
`;

const ListS = styled.li`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1025px) {
    margin-bottom: 3rem;
  }

  &:last-child {
    margin-bottom: 0rem;
  }
`;

const SupportBarS = styled.div`
  width: 100%;
  display: block;

  @media (min-width: 600px) {
    width: 90%;
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

const SupportBarImageS = styled.img`
  width: 100%;
`;

const TextS = styled.p`
  font-size: 1.4rem;
  white-space: pre-line;
  line-height: 1.7;

  @media (min-width: 600px) {
    font-size: 1.3rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const MessageS = styled.div`
  margin-top: 5rem;
  width: 100%;
  position: absolute;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #ffffff;
  font-size: 1.3rem;

  @media (min-width: 600px) {
    margin-top: 10rem;
    font-size: 1.7rem;
  }

  @media (min-width: 1025px) {
    font-size: 2rem;
  }
`;
