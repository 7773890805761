export type BreadCrumbItems = {
  path: string;
  name: string;
};

export const topBreadCrumbItems: BreadCrumbItems[] = [
  {
    path: '/',
    name: 'トップ',
  },
];

/**
 *
 * 新規会員登録
 *
 */
export const registerBreadCrumbItems: BreadCrumbItems[] = [
  ...topBreadCrumbItems,
  {
    path: '/register',
    name: '新規会員登録',
  },
];

/**
 *
 * 新規会員登録(確認)
 *
 */
export const registerConfirmBreadCrumbItems: BreadCrumbItems[] = [
  ...registerBreadCrumbItems,
  {
    path: '/register',
    name: '入力内容の確認',
  },
];

/**
 *
 * ログイン
 *
 */
export const loginBreadCrumbItems: BreadCrumbItems[] = [
  ...topBreadCrumbItems,
  {
    path: '/login',
    name: 'ログイン',
  },
];

/**
 *
 * パスワードリセット
 *
 */
export const passwordResetBreadCrumbItems: BreadCrumbItems[] = [
  {
    path: '/password_reset',
    name: 'パスワードのリセット',
  },
];

/**
 *
 * パスワード再設定
 *
 */
export const passwordResetFormBreadCrumbItems: BreadCrumbItems[] = [
  ...topBreadCrumbItems,
  {
    path: '/password_reset',
    name: 'パスワードの再設定',
  },
];

/**
 *
 * パスワード再設定完了
 *
 */
export const passwordResetFinishBreadCrumbItems: BreadCrumbItems[] = [
  ...passwordResetFormBreadCrumbItems,
  {
    path: '/password_reset',
    name: 'パスワード再設定完了',
  },
];

/**
 *
 * マイページ
 *
 */
export const myPageBreadCrumbItems: BreadCrumbItems[] = [
  ...topBreadCrumbItems,
  {
    path: '/mypage',
    name: 'マイページ',
  },
];

/**
 *
 * アカウントの停止(確認)
 *
 */
export const actDleConfirmBreadCrumbItems: BreadCrumbItems[] = [
  ...myPageBreadCrumbItems,
  {
    path: '/act/dle/confirm',
    name: 'アカウントの停止',
  },
];

/**
 *
 * メールアドレスの変更
 *
 */
export const emailChangeBreadCumbItems: BreadCrumbItems[] = [
  ...myPageBreadCrumbItems,
  {
    path: '/email_change',
    name: 'メールアドレスの変更',
  },
];

/**
 *
 * ポイント購入
 *
 */
export const pointPurchaseBreadCrumbItems: BreadCrumbItems[] = [
  ...myPageBreadCrumbItems,
  {
    path: '/point_purchase',
    name: 'ポイント購入',
  },
];

/**
 *
 * ポイント購入（仮想通貨）
 *
 */
export const pointPurchaseCcBreadCrumbItems: BreadCrumbItems[] = [
  ...pointPurchaseBreadCrumbItems,
  {
    path: '/point_purchase/cc',
    name: '仮想通貨で入金',
  },
];

/**
 *
 * ポイント購入（クレジット）
 *
 */
export const pointPurchaseCreditBreadCrumbItems: BreadCrumbItems[] = [
  ...pointPurchaseBreadCrumbItems,
  {
    path: '/point_purchase/credit',
    name: 'クレジットカードで入金',
  },
];

/**
 *
 * ポイント購入（現金振込）
 *
 */
export const pointPurchaseCashBreadCrumbItems: BreadCrumbItems[] = [
  ...pointPurchaseBreadCrumbItems,
  {
    path: '/point_purchase/cash',
    name: '現金で入金',
  },
];

/**
 *
 * 出金ページ(トップ)
 *
 */
export const withdrawalTopBreadCrumbItems: BreadCrumbItems[] = [
  ...myPageBreadCrumbItems,
  {
    path: '/withdrawal',
    name: 'ポイント出金・交換',
  },
];

/**
 *
 * 出金ページ(仮想通貨)
 *
 */
export const withdrawalCcBreadCrumbItems: BreadCrumbItems[] = [
  ...withdrawalTopBreadCrumbItems,
  {
    path: '/withdrawal/cc',
    name: '送金手続き',
  },
];

/**
 *
 * KYC書類アップロード
 *
 */
export const kycFileUploadBreadCrumbItems: BreadCrumbItems[] = [
  ...myPageBreadCrumbItems,
  {
    path: '/kyc/upload',
    name: 'KYC書類アップロード',
  },
];

/**
 *
 * ユーザーお知らせ一覧
 *
 */
export const newsUsersIndexBreadCrumbItems: BreadCrumbItems[] = [
  ...topBreadCrumbItems,
  {
    path: '/news_users/index',
    name: 'お知らせ',
  },
];

/**
 *
 * ユーザーお知らせ獲得ポイント受け取り
 *
 */
export const newsUsersSkipBreadCrumpItems: BreadCrumbItems[] = [
  ...newsUsersIndexBreadCrumbItems,
  {
    path: '/news_users/skip',
    name: '獲得ポイント受け取り',
  },
];

/**
 *
 * お問合せ
 *
 */
export const inquiryBreadCrumbItems: BreadCrumbItems[] = [
  ...topBreadCrumbItems,
  {
    path: '/inquiry',
    name: 'お問い合わせフォーム',
  },
];

/**
 *
 * お問合せ内容確認
 *
 */
export const inquiryConfirmBreadCrumbItems: BreadCrumbItems[] = [
  ...inquiryBreadCrumbItems,
  {
    path: '/inquiry',
    name: 'お問い合わせ内容の確認',
  },
];
