import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Hooks
import { useAuth } from 'hooks/versatile/useAuth';
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType } from 'types/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

/**
 *
 * Types
 *
 */
type UseNewsUsersSkip = () => {
  isLoading: boolean;
  goBack: () => void;
  onLotCheck: () => void;
};

/**
 *
 * Hooks
 *
 */
export const useNewsUsersSkip: UseNewsUsersSkip = () => {
  const history = useHistory();
  const goBack = () => {
    history.push('/news_users/index');
  };

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { getApiUsersMe } = useAuth();
  const lotCheckSource = axiosCancelToken.source();
  const { displayErrorMessageInToast } = useError();
  const onLotCheck = () => {
    setIsLoading(true);

    axiosClient
      .post<boolean | ApiErrorType>(
        '/api/lot/allcheck',
        {
          cancelToken: lotCheckSource.token,
        },
      )
      .then(({ data }) => {
        if (data) {
          getApiUsersMe();
          toast.success(t('ポイントを受け取りました。'));

          setTimeout(() => {
            history.push({
              pathname: '/mypage',
              state: {
                initialMenu: 'history',
              },
            });
          }, 500);
        }
      })
      .catch(displayErrorMessageInToast)
      .finally(() => { setIsLoading(false); });
  };

  const cleanup = () => {
    setIsLoading(false);
    lotCheckSource.cancel();
  };

  useEffect(() => cleanup, []);

  return {
    isLoading,
    goBack,
    onLotCheck,
  };
};
