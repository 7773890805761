// eslint-disable-next-line
import { useState, useRef, RefObject } from 'react';
// External library
import dayjs from 'dayjs';
import { AxiosError } from 'axios';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { langState } from 'store/atoms/langState';
import { playBoxListState } from 'store/atoms/playBoxListState';
import { playBoxShowState } from 'store/atoms/playBoxShowState';
// Types
import {
  ApiErrorType,
  NewsType,
  NewsBigWinnersType,
  LotSelectType,
  PaginationType,
} from 'types/api';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';

/**
 *
 * トップページ用カスタムフック
 *
 */
type Pagination = {
  nextPageUrl: string;
  last: boolean;
};
type NewsSerive = {
  type: 'service';
  data: {
    id: number;
    date: string;
    title: string;
    enTitle: string;
    zhCnTitle: string;
    zhTwTitle: string;
    koTitle: string;
    viTitle: string;
    thTitle: string;
  }[];
};
type NewsBigWinners = {
  type: 'bigwinners';
  data: {
    date: string;
    userName: string;
    point: string;
  }[];
};
export const useTop = (): {
  // メインバナー
  playingVideo: boolean;
  onPlayingVideo: () => void;
  // NEWS
  newsService: NewsSerive;
  newsPagination: Pagination;
  newsIsLoading: boolean;
  isEmptyNews: boolean;
  newsErrorMessage: string;
  getApiNewsSelect: () => void;
  // 高額獲得者
  newsBigWinners: NewsBigWinners;
  newsBigWinnersPagination: Pagination;
  newsBigWinnersIsLoading: boolean;
  isEmptyWinnersNews: boolean;
  newsBigWinnersErrorMessage: string;
  getApiNewsBigWinnersSelect: () => void;
  // PLAYBOX
  boxRef: RefObject<HTMLInputElement>;
  isBoxLoading: boolean;
  getApiLotSelect: () => void;
  boxEmptyMessage: string;
  boxErrorMessage: string;
  scrollToBox: (ref: RefObject<HTMLInputElement>, isScroll: boolean) => void;
  // クリーンアップ関数
  cleanUpMethod: () => void;
} => {
  // Recoil 言語設定
  const lang = useRecoilValue(langState);

  // メインバナー(動画)
  const [playingVideo, setPlayingVideo] = useState<boolean>(false);
  const onPlayingVideo = () => {
    setPlayingVideo(true);
  };

  // ページネーション
  const initialPagination = {
    nextPageUrl: '',
    last: false,
  };

  // エラー用カスタムフック
  const { errorHandler } = useError();

  // NEWS用ステート
  const [newsIsLoading, setNewsIsLoading] = useState(false);
  const [isEmptyNews, setIsEmptyNews] = useState<boolean>(false);
  const [newsErrorMessage, setNewsErrorMessage] = useState<string>('');
  const [newsService, setNewsService] = useState<NewsSerive>({
    type: 'service',
    data: [],
  });
  const [newsPagination, setNewsPagination] = useState<Pagination>({
    ...initialPagination,
  });

  // NEWS取得リクエスト
  const axiosNewsSource = axiosCancelToken.source();
  const getApiNewsSelect = () => {
    if (!newsIsLoading) {
      setNewsIsLoading(true);
      setIsEmptyNews(false);

      // axios GET /api/news/select
      axiosClient
        .get<{
          pagination: PaginationType;
          news: NewsType[];
        }>(`/api/news/select${newsPagination.nextPageUrl ?? ''}`, {
          cancelToken: axiosNewsSource.token,
        })
        .then(({ data }) => {
          const { news, pagination } = data;

          if (news.length !== 0) {
            // NEWS取得
            const getNews = news.map((item) => ({
              id: item.id,
              date: dayjs(item.start_date).format('YYYY/MM/DD'),
              title: item.title,
              enTitle: item.en_title,
              zhCnTitle: item.zh_cn_title,
              zhTwTitle: item.zh_tw_title,
              koTitle: item.ko_title,
              viTitle: item.vi_title,
              thTitle: item.th_title,
            }));

            // NEWS更新
            if (pagination.current_page > 1) {
              setNewsService({
                type: 'service',
                data: [...newsService.data, ...getNews],
              });
            } else {
              setNewsService({
                type: 'service',
                data: [...getNews],
              });
            }

            // ページネーション更新
            setNewsPagination({
              nextPageUrl: pagination.next_page_url,
              last: pagination.last,
            });
            // データが空の場合
          } else {
            setIsEmptyNews(true);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setNewsErrorMessage(result);
          }
          setNewsService({
            type: 'service',
            data: [],
          });
        })
        .finally(() => {
          setNewsIsLoading(false);
        });
    }
  };

  // 高額獲得者用ステート
  const [newsBigWinnersIsLoading, setNewsBigWinnersIsLoading] = useState(false);
  const [isEmptyWinnersNews, setIsEmptyWinnersNews] = useState<boolean>(false);
  const [newsBigWinnersErrorMessage, setNewsBigWinnersErrorMessage] = useState<string>('');
  const [newsBigWinners, setNewsBigWinners] = useState<NewsBigWinners>({
    type: 'bigwinners',
    data: [],
  });
  const [newsBigWinnersPagination, setNewsBigWinnersPagination] = useState<Pagination>({
    ...initialPagination,
  });

  // 高額獲得者情報リクエスト
  const axiosNewsBigWinnersSource = axiosCancelToken.source();
  const getApiNewsBigWinnersSelect = () => {
    if (!newsBigWinnersIsLoading) {
      setNewsBigWinnersIsLoading(true);
      setIsEmptyWinnersNews(false);

      // axios GET /api/news_big_winners/select
      axiosClient
        .get<{
          pagination: PaginationType;
          news_big_winners: NewsBigWinnersType[];
        }>(
          `/api/news_big_winners/select${
            newsBigWinnersPagination.nextPageUrl ?? ''
          }`,
          {
            cancelToken: axiosNewsBigWinnersSource.token,
          },
        )
        .then(({ data }) => {
          const { news_big_winners, pagination } = data;

          if (news_big_winners.length !== 0) {
            // 高額獲得者情報取得
            const getNewsBigWinners = news_big_winners.map((item) => ({
              date: dayjs(item.created_at).format('YYYY/MM/DD'),
              userName: item.users?.name ? item.users.name : '',
              point: `${item.point.toLocaleString()}P`,
            }));

            // 高額獲得者情報更新
            if (pagination.current_page > 1) {
              setNewsBigWinners({
                type: 'bigwinners',
                data: [...newsBigWinners.data, ...getNewsBigWinners],
              });
            } else {
              setNewsBigWinners({
                type: 'bigwinners',
                data: [...getNewsBigWinners],
              });
            }

            // ページネーション更新
            setNewsBigWinnersPagination({
              nextPageUrl: pagination.next_page_url,
              last: pagination.last,
            });
            // データが空の場合
          } else {
            setIsEmptyWinnersNews(true);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setNewsBigWinnersErrorMessage(result);
          }
          setNewsBigWinners({
            type: 'bigwinners',
            data: [],
          });
        })
        .finally(() => {
          setNewsBigWinnersIsLoading(false);
        });
    }
  };

  // PLAYBOX用ステート
  const [isBoxLoading, setIsBoxLoading] = useState<boolean>(false);
  const [boxEmptyMessage, setBoxEmptyMessage] = useState<string>('');
  const [boxErrorMessage, setBoxErrorMessage] = useState<string>('');

  // スクロール用Ref
  const boxRef = useRef<HTMLInputElement>(null);

  // Recoil
  // PLAYBOX情報更新関数
  const setPlayBoxList = useSetRecoilState(playBoxListState);
  // PLAYBOX開閉情報更新関数
  const setPlayBoxShow = useSetRecoilState(playBoxShowState);

  // PLAYBOXデータリクエスト
  const axiosLotSelectSource = axiosCancelToken.source();
  const getApiLotSelect = () => {
    if (!isBoxLoading) {
      setIsBoxLoading(true);

      axiosClient
        .get<LotSelectType>('/api/lot/select', {
          cancelToken: axiosLotSelectSource.token,
        })
        .then(({ data }) => {
          if (data) {
            setPlayBoxList(data);
          } else {
            const message = lang === 'ja' ? 'PLAYBOXはありません。' : 'No play box.';
            setBoxEmptyMessage(message);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setBoxErrorMessage(result);
          }
        })
        .finally(() => {
          setIsBoxLoading(false);
        });
    }
  };

  // ボックス選択時のスクロール;
  /**
   * ref: RefObject
   * isScroll: scroll flg
   */
  const scrollToBox = (ref: RefObject<HTMLInputElement>, isScroll: boolean) => {
    if (isScroll) {
      if (ref?.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    // APIリクエストキャンセル
    axiosNewsSource.cancel();
    axiosNewsBigWinnersSource.cancel();
    setPlayingVideo(false);
    // データ
    setNewsService({ type: 'service', data: [] });
    setNewsBigWinners({ type: 'bigwinners', data: [] });
    // ページネーション
    setNewsPagination({ ...initialPagination });
    setNewsBigWinnersPagination({ ...initialPagination });
    // ローディング
    setNewsIsLoading(false);
    setNewsBigWinnersIsLoading(false);
    // エラーメッセージ
    setIsEmptyNews(false);
    setIsEmptyWinnersNews(false);
    setNewsErrorMessage('');
    setNewsBigWinnersErrorMessage('');
    // PLAYBOX
    setPlayBoxShow({
      initialBoxId: '',
      isShow1000: false,
      isShow5000: false,
      isShow10000: false,
      isShow100000: false,
      isShow1000000: false,
    });
  };

  return {
    // メインバナー
    playingVideo,
    onPlayingVideo,
    // NEWS
    newsService,
    newsPagination,
    newsIsLoading,
    isEmptyNews,
    newsErrorMessage,
    getApiNewsSelect,
    // 高額獲得者
    newsBigWinners,
    newsBigWinnersPagination,
    newsBigWinnersIsLoading,
    isEmptyWinnersNews,
    newsBigWinnersErrorMessage,
    getApiNewsBigWinnersSelect,
    // PLAYBOX
    boxRef,
    isBoxLoading,
    getApiLotSelect,
    boxEmptyMessage,
    boxErrorMessage,
    scrollToBox,
    // クリーンアップ関数
    cleanUpMethod,
  };
};
