const prefix = 'DayLott\'s - ';

export const koSeo = {
  title: {
    // Global pages
    top: `${prefix}톱`,
    emailSend: `${prefix}메일 전송 완료`,
    emailVerified: `${prefix}메일 인증`,
    whats: `${prefix}Day Lott's란?`,
    system: `${prefix}시스템`,
    faq: `${prefix}자주 하는 질문`,
    ranking: `${prefix}랭킹`,
    support: `${prefix}지원`,
    responsibleGambling: `${prefix}Responsible Gambling Polic`,
    newsShow: `${prefix}알아 채다`,
    contact: `${prefix}문의하기`,
    contactConfirmation: `${prefix}연락처 확인`,
    contactCompleted: `${prefix}문의 완료`,
    passwordReset: `${prefix}비밀번호 재설정`,
    passwordResetForm: `${prefix}비밀번호 재설정 양식`,
    passwordResetCompleted: `${prefix}비밀번호 재설정 완료`,
    // Guest pages
    register: `${prefix}신규 회원 등록`,
    registerConfirmation: `${prefix}신규 회원 등록`,
    login: `${prefix}로그인`,
    accountDeleteCompleted: `${prefix}계정 정지 완료`,
    // Private pages
    mypage: `${prefix}나의 페이지`,
    accountDeleteConfirmation: `${prefix}계정 정지`,
    kycUpload: `${prefix}KYC 업로드`,
    kycConfirmation: `${prefix}KYC 확인`,
    kycCompleted: `${prefix}KYC 완료`,
    emailChange: `${prefix}이메일 주소 변경`,
    pointPurchase: `${prefix}포인트 구매`,
    pointPurchaseCc: `${prefix}가상통화로 입금`,
    pointPurchaseCredit: `${prefix}신용카드로 입금`,
    pointPurchaseCash: `${prefix}현금으로 입금`,
    pointPurchaseCompleted: `${prefix}입금 정보 전송 완료`,
    userNewsIndex: `${prefix}공지사항 목록`,
    userNewsShow: `${prefix}알아 채다`,
    userNewsSkip: `${prefix}적립 포인트를 받다`,
    lottery: `${prefix}추첨`,
    ticketPurchaseConfirmation: `${prefix}구매 내용 확인`,
    ticketPurchaseCompleted: `${prefix}티켓 구매 완료`,
    withdrawalTop: `${prefix}포인트 보증금 출금・교환`,
    withdrawalCc: `${prefix}송금 절차`,
    withdrawalFinish: `${prefix}출금・교환을 접수했습니다.`,
  },
  description: {
    // Global pages
    top: 'DayLott\'s는 매일 밀리오네아를 배출하는 세계 최초의 온라인 복권 게임입니다.',
    emailSend: `${prefix}메일 전송 완료 페이지.`,
    emailVerified: `${prefix}메일 인증 페이지.`,
    whats: 'DayLott\'s는 매일 밀리오네아를 배출하는 세계 최초의 온라인 복권 게임입니다.',
    system: '당첨 확률이 경이의 75%를 자랑하는 온라인 복권 게임입니다. 참가할 베팅 금액, 당첨 금액에 따른 테이블이 준비되어 있습니다. 규칙은 티켓을 구입하고 입장하는 단순한 시스템입니다.',
    faq: '이 페이지는 DayLott\'s에 대한 FAQ를 요약한 페이지입니다.',
    ranking: '이곳은 DayLott\'s 당첨자 순위 페이지입니다.',
    support: '여기는 DayLott\'s FAQ, 이메일 문의, 문의 시간 등의 지원 페이지입니다.',
    responsibleGambling: 'We are committed to providing you with the most enjoyable gambling experience possible, but we also recognize that gambling can cause problems for some people. To ensure that you can continue to enjoy safe and manageable play, we support responsible gambling and have put in place a number of measures to assist you.',
    newsShow: `${prefix}공지 페이지`,
    contact: '이곳은 DayLott\'s의 문의 페이지입니다. 문의 양식을 사용하여 고객의 연락을 받습니다.',
    contactConfirmation: `${prefix}문의 내용 확인 페이지.`,
    contactCompleted: `${prefix}문의 완료 페이지.`,
    passwordReset: `${prefix}비밀번호 재설정 페이지.`,
    passwordResetForm: `${prefix}비밀번호 재설정 양식.`,
    passwordResetCompleted: `${prefix}비밀번호 재설정 완료 페이지.`,
    // Guest pages
    register: 'DayLott\'s에 회원 가입하여 포인트를 충전! 복권 티켓을 구입하여 게임에 참여하세요!',
    registerConfirmation: `${prefix}입력 내용 확인.`,
    login: 'DayLott\'s에 등록한 이메일 주소와 비밀번호를 입력하여 로그인하세요. 처음 이용하는 경우는 회원 등록을 실시해 주세요.',
    accountDeleteCompleted: `${prefix}계정 정지 완료 페이지입니다.`,
    // Private pages
    mypage: 'DayLott\'s 사용자를 위한 마이페이지입니다.',
    accountDeleteConfirmation: `${prefix}계정 정지 페이지.`,
    kycUpload: `${prefix}KYC 업로드 페이지입니다.`,
    kycConfirmation: `${prefix}KYC 확인 페이지입니다.`,
    kycCompleted: `${prefix}KYC 완료 페이지.`,
    emailChange: `${prefix}이메일 주소 변경 페이지.`,
    pointPurchase: `${prefix}포인트 구매 페이지.`,
    pointPurchaseCc: `${prefix}암호화폐 입금 페이지입니다.`,
    pointPurchaseCredit: `${prefix}신용 입금 페이지입니다.`,
    pointPurchaseCash: `${prefix}현금 입금 페이지입니다.`,
    pointPurchaseCompleted: `${prefix}입금 정보가 전송되었습니다.`,
    userNewsIndex: `${prefix}공지사항 페이지입니다.`,
    userNewsShow: `${prefix}공지 페이지.`,
    userNewsSkip: `${prefix}적립 포인트 받기 페이지`,
    lottery: `${prefix}복권 페이지입니다.`,
    ticketPurchaseConfirmation: `${prefix}티켓 구매 확인 페이지.`,
    ticketPurchaseCompleted: `${prefix}티켓 구매 완료 페이지입니다.`,
    withdrawalTop: `${prefix}포인트 보증금 출금・교환 페이지.`,
    withdrawalCc: `${prefix}송금 절차 페이지.`,
    withdrawalFinish: `${prefix}출금・교환을 접수했습니다.`,
  },
};
