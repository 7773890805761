import { FC, useEffect, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
// Components
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderPart from 'components/atoms/loader/LoaderPart';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Custom hooks
import { useTicketPurchaseFinish } from 'hooks/pages/private/useTicketPurchaseFinish';
import { useRedirect } from 'hooks/versatile/useRedirect';
// Utils
import { PLAYBOX_TYPE } from 'utils/const/playBox';
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';
// Images
import backGroundSp from 'assets/images/backGround/ticket_purchase_sp.jpg';
import backGroundPc from 'assets/images/backGround/ticket_purchase_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/ticket_purchase_pc@2x.jpg';
import dayLotts1000Icon from 'assets/images/top/playBox/daylotts1000_icon.svg';
import dayLotts5000Icon from 'assets/images/top/playBox/daylotts5000_icon.svg';
import dayLotts10000Icon from 'assets/images/top/playBox/daylotts10000_icon.svg';
import dayLotts100000Icon from 'assets/images/top/playBox/daylotts100000_icon.svg';

const {
  ENGLISH, JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN,
  VIETNAM, THAILAND,
} = LANG;

/**
 *
 * Component
 *
 */
const TicketPurchaseFinish: FC = memo(() => {
  // Recoil言語設定
  const lang = useRecoilValue(langState);
  // BOX定数
  const {
    BOX_1000, BOX_5000, BOX_10000, BOX_100000,
  } = PLAYBOX_TYPE;

  // Hooks
  const { t } = useTranslation();
  const { redirectToTopPage } = useRedirect();
  const {
    referrer,
    boxTitle,
    ticketNumbers,
    isLoading,
    errorMessage,
    unlotteryBoxes,
    getApiLotUnlottery,
    cleanUpMethod,
  } = useTicketPurchaseFinish();

  useEffect(() => {
    // 確認画面以外からのアクセス or チケット情報が無い場合=>TOPへリダイレクト
    if (referrer !== '/ticket_purchase/confirm' || ticketNumbers[0] === 0) {
      redirectToTopPage();
    }

    // 未抽選ボックス情報取得
    getApiLotUnlottery();

    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', null);
    // ブラウザバックor進むをしたらトップページにリダイレクト
    window.addEventListener('popstate', redirectToTopPage, false);

    return () => {
      cleanUpMethod();
      window.removeEventListener('popstate', redirectToTopPage, false);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.ticketPurchaseCompleted')}</title>
        <meta name="description" content={t('description.ticketPurchaseCompleted')} />
      </Helmet>
      <FinishS>
        <ContainerS>
          {/* コンテンツ */}
          <FinishWrapS>
            <FinishTextS>
              <TitleTextS>
                {lang === JAPANESE
                  ? `${boxTitle} のチケット購入が完了しました！`
                  : lang === SIMPLE_CHINESE
                    ? `您已成功购买了 ${boxTitle} 的票！`
                    : lang === TRADITIONAL_CHINESE
                      ? `${boxTitle} 的票券購買已完成！`
                      : lang === KOREAN
                        ? `${boxTitle} 의 티켓 구매가 완료되었습니다!`
                        : lang === VIETNAM
                          ? `Đã hoàn tất mua vé ${boxTitle}!`
                          : lang === THAILAND
                            ? `การซื้อตั๋ว ${boxTitle}  เสร็จเรียบร้อยแล้ว`
                            : `Completed purchase of ${boxTitle} ticket!`}
              </TitleTextS>
            </FinishTextS>
            <TicketInfoS>
              <TicketWrapS>
                <TicketHeadS>
                  <TextS>{t('チケット番号')}</TextS>
                </TicketHeadS>
                <TicketNumberWrapS>
                  <TicketNumberS numberLength={ticketNumbers.length}>
                    {ticketNumbers[0] !== 0
                      && ticketNumbers.map((number) => (
                        <NumberS key={uuidv4()}>
                          {lang === ENGLISH || lang === VIETNAM || lang === THAILAND ? (
                            <TextS>
                              {t('番')}
                              {number.toLocaleString()}
                            </TextS>
                          ) : (
                            <TextS>
                              {number.toLocaleString()}
                              {t('番')}
                            </TextS>
                          )}
                        </NumberS>
                      ))}
                  </TicketNumberS>
                </TicketNumberWrapS>
              </TicketWrapS>
            </TicketInfoS>
          </FinishWrapS>

          <BoxInfoS>
            <InfoS>
              <InfoHeadS>
                <InfoHeadTextS>{t('参加中のPLAY BOX')}</InfoHeadTextS>
              </InfoHeadS>

              {/** ローディング */}
              {isLoading && <LoaderPart />}
              <BoxContentS>
                {/** エラーメッセージ */}
                {errorMessage && (
                  <MessageWrapS>
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                  </MessageWrapS>
                )}
                {unlotteryBoxes.length !== 0 && (
                  <GridBoxS>
                    {unlotteryBoxes.map((item) => (
                      <BoxS key={uuidv4()} boxesLength={unlotteryBoxes.length}>
                        <InBoxS>
                          <LogoWrapS>
                            {item.lot_boxes_type === BOX_1000 && (
                              <LogoS src={dayLotts1000Icon} alt="" />
                            )}
                            {item.lot_boxes_type === BOX_5000 && (
                              <LogoS src={dayLotts5000Icon} alt="" />
                            )}
                            {item.lot_boxes_type === BOX_10000 && (
                              <LogoS src={dayLotts10000Icon} alt="" />
                            )}
                            {item.lot_boxes_type === BOX_100000 && (
                              <LogoS src={dayLotts100000Icon} alt="" />
                            )}
                          </LogoWrapS>
                          <ButtonWrapS>
                            <Link
                              to={`/lottery/?id=${item.lot_tables_id.toString()}`}
                            >
                              <RoundButton
                                background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                                borderColor="rgba(219,0,177,1)"
                              >
                                {t('抽選画面へ')}
                              </RoundButton>
                            </Link>
                          </ButtonWrapS>
                        </InBoxS>
                      </BoxS>
                    ))}
                  </GridBoxS>
                )}
              </BoxContentS>
            </InfoS>
          </BoxInfoS>

          <ToTopLinkS href="/">{t('TOPに戻る')}</ToTopLinkS>
        </ContainerS>
      </FinishS>
    </>
  );
});

export default TicketPurchaseFinish;

/**
 *
 * Styles
 *
 */
const FinishS = styled.main`
 background: url(${backGroundSp});
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 background-attachment: fixed;

 @media ${devices.tabletLandscapeAndPc} {
   background: image-set(url(${backGroundPc}) 1x, url(${backGroundPc2x}) 2x);
   background-position: top;
 }
`;

const ContainerS = styled.div`
 width: 90%;
 min-height: 90vh;
 margin: 0 auto;
 padding-top: 10rem;
 padding-bottom: 5rem;
 display: flex;
 flex-direction: column;
 align-items: center;
 color: #ffffff;

 @media (min-width: 600px) {
   max-width: 60rem;
 }

 @media ${devices.tabletLandscapeAndPc} {
   width: 100%;
 }
`;

const FinishWrapS = styled.div`
 width: 100%;
 display: flex;
 flex-direction: column;
 align-items: center;
`;

const FinishTextS = styled.div`
 margin-bottom: 3rem;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
`;

const TitleTextS = styled.p`
 font-size: min(5vw, 2rem);
 font-weight: bold;
 word-break: break-word;

 @media ${devices.tabletLandscapeAndPc} {
   font-size: 2.5rem;
 }
`;

const TextS = styled.p`
 font-size: min(6vw, 1.4rem);
 word-break: break-word;

 @media ${devices.tabletLandscapeAndPc} {
   font-size: 1.6rem;
 }
`;

const TicketInfoS = styled.div`
 width: 100%;
 background: rgba(0, 0, 0, 0.6);
`;

const TicketWrapS = styled.div`
 padding: 1.5rem;
 display: flex;

 @media (min-width: 600px) {
   padding: 2rem;
 }
`;

const TicketHeadS = styled.div`
 width: 40%;
 padding-top: 3rem;
 background: rgba(80, 80, 80, 0.3);
 display: flex;
 justify-content: center;

 @media (min-width: 600px) {
   width: 35%;
 }
`;

const TicketNumberWrapS = styled.div`
 width: 70%;
 padding: 3rem 0.5rem;
 background: rgba(80, 80, 80, 0.2);
 display: flex;
 flex-direction: column;
 align-items: center;

 @media ${devices.tabletLandscapeAndPc} {
   padding: 3rem 1rem;
 }
`;

const TicketNumberS = styled.div<{ numberLength: number }>`
 width: 100%;

 display: grid;
 grid-template-columns: ${({ numberLength }) => (numberLength > 1 ? 'repeat(2,1fr)' : 'repeat(1, 1fr)')};
 gap: 1rem;

 @media (min-width: 600px) {
   grid-template-columns: ${({ numberLength }) => (numberLength > 2 ? 'repeat(3,1fr)' : `repeat(${numberLength}, 1fr)`)};
 }
`;
const NumberS = styled.div`
 text-align: center;
`;

const BoxInfoS = styled.div`
 width: 100%;
 background: rgba(0, 0, 0, 0.6);
 margin: 5rem 0;
`;

const InfoS = styled.div`
 width: 100%;
 padding: 3rem 2rem 2rem;
 position: relative;
 display: flex;
 flex-direction: column;
 align-items: center;
`;

const InfoHeadS = styled.div`
 width: 100%;
 display: flex;
 justify-content: center;
 margin-bottom: 3rem;
`;

const InfoHeadTextS = styled.p`
 font-size: min(5vw, 1.8rem);
 word-break: break-word;

 @media (min-width: 600px) {
   font-size: 2rem;
 }
`;

const BoxContentS = styled.div`
 width: 100%;
 position: relative;
 min-height: 120px;
`;

const GridBoxS = styled.div`
 width: 100%;
 display: flex;
 flex-direction: column;

 @media (min-width: 600px) {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 2rem;
 }
`;

const BoxS = styled.div<{ boxesLength: number }>`
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-bottom: 2rem;
 background: rgba(80, 80, 80, 0.3);

 &:last-child {
   margin-bottom: 0;
 }

 @media (min-width: 600px) {
   grid-column: ${({ boxesLength }) => (boxesLength === 1 ? '2 / 3' : 'unset')};
   margin-bottom: 0;
 }
`;

const InBoxS = styled.div`
 width: 100%;
 padding: 2rem 0;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;

 @media (min-width: 600px) {
   padding: 2rem 1rem;
 }
`;

const LogoWrapS = styled.div`
 width: min(30vw, 140px);
 margin-bottom: 2rem;
 display: flex;
 justify-content: center;

 @media (min-width: 600px) {
   width: 80%;
 }
`;

const ButtonWrapS = styled.div`
 width: min(40vw, 190px);
 font-size: 1.4rem;

 @media (min-width: 600px) {
   width: 100%;
 }

 @media ${devices.tabletLandscapeAndPc} {
   font-size: 1.6rem;
 }
`;

const LogoS = styled.img`
 width: 100%;
`;

const MessageWrapS = styled.div`
 width: 100%;
 height: 100%;
 position: absolute;
 font-size: 1.4rem;
 word-break: break-word;
 display: flex;
 justify-content: center;
 align-items: center;
`;

const ToTopLinkS = styled.a`
 color: #ffff00;
 font-size: 1.4rem;
 text-decoration: underline;
 :hover {
   color: #87cefa;
 }

 @media ${devices.tabletLandscapeAndPc} {
   font-size: 1.6rem;
 }
`;
