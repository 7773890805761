import {
  useState, useRef, RefObject, useEffect,
} from 'react';
// External library
import { AxiosError } from 'axios';
// Custom hooks
import { useAuth } from 'hooks/versatile/useAuth';
import { useError } from 'hooks/versatile/useError';
import { useValidate } from 'hooks/versatile/useValidate';
// Recoil
import { userState } from 'store/atoms/userState';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType } from 'types/api';
import { useDevice } from '../../versatile/useDevice';
import { useRecoilState } from 'recoil';

const ACCESS_MATCH_COUNT = 5;

type LoginResponse = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: string;
}

/**
 *
 * ログインページ用ロジック
 *
 */
export const useLogin = (): {
  isLoading: boolean;
  errorMessage: string;
  emailRef: RefObject<HTMLInputElement>;
  passwordRef: RefObject<HTMLInputElement>;
  postApiLogin: () => void;
  isShownHomeDialog: boolean;
  onCloseHomeDialog: () => void;
} => {
  // Recoil ユーザー情報
  const [user, setUserData] = useRecoilState(userState);

  // 認証用カスタムフック
  const { getApiUsersMe } = useAuth();
  // エラー用カスタムフック
  const { errorHandler } = useError();
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');
  // ローディング状態
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Eメール
  const emailRef = useRef<HTMLInputElement>(null);
  // パスワード
  const passwordRef = useRef<HTMLInputElement>(null);
  // 端末
  const device = useDevice();

  // バリデーション用カスタムフック
  const { emailValidate, passwordValidate } = useValidate();
  // axiosキャンセルトークン
  const axiosSource = axiosCancelToken.source();
  // ログインリクエスト
  const postApiLogin = () => {
    if (!isLoading) {
      setIsLoading(true);

      // メールアドレスバリデーション
      let email = '';
      if (emailRef.current !== null) {
        const result = emailValidate(emailRef.current.value);
        if (result) {
          setErrorMessage(result);
          setIsLoading(false);

          return;
        }
        email = emailRef.current.value;
      }
      // パスワードバリデーション
      let password = '';
      if (passwordRef.current !== null) {
        const result = passwordValidate(passwordRef.current.value);
        if (result) {
          setErrorMessage(result);
          setIsLoading(false);

          return;
        }
        password = passwordRef.current.value;
      }

      setUserData({
        loggedIn: false,
        id: 0,
        name: '',
        email: '',
        ethAddress: '',
        point: 0,
        freePoint: 0,
        birthday: '',
        unreadNewsCount: 0,
        unreadNewsUsersCount: 0,
        unlotteryBetsCount: 0,
        kycFlg: 0,
      });
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('expires_in');

      // axios POST /api/login
      axiosClient
        .post<LoginResponse>('/api/login', {
          email,
          password,
          cancelToken: axiosSource.token,
        })
        .then(({ data }) => {
          if (data) {
            const accessToken = data.access_token;
            const refreshToken = data.refresh_token;
            const expiresIn = data.expires_in;
            if (accessToken) {
              localStorage.setItem('access_token', accessToken);
            }
            if (refreshToken) {
              localStorage.setItem('refresh_token', refreshToken);
            }
            if (expiresIn) {
              localStorage.setItem('expires_in', expiresIn);
            }
            if (accessToken && refreshToken) {
              console.log(accessToken);
              console.log(refreshToken);
              getApiUsersMe();
            }

            if (user.loggedIn) {
              setIsLoading(false);
            }
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }

          setIsLoading(false);
        });
    }
  };

  // ホーム画面追加誘導ダイアログ
  const [isShownHomeDialog, setIsShownHomeDialog] = useState(false);
  const onCloseHomeDialog = () => { setIsShownHomeDialog(false); };
  const toggleShownHomeDialogByAccessCount = () => {
    if (window.localStorage && device.isSmartPhone) {
      const accessCount = localStorage.getItem('dltLoginPageAccessCount');
      if (accessCount) {
        if (Number(accessCount) < ACCESS_MATCH_COUNT) {
          localStorage.setItem('dltLoginPageAccessCount', String(Number(accessCount) + 1));
        } else if (Number(accessCount) >= ACCESS_MATCH_COUNT) {
          setIsShownHomeDialog(true);
          localStorage.removeItem('dltLoginPageAccessCount');
        }
      } else {
        localStorage.setItem('dltLoginPageAccessCount', '1');
      }
    }
  };

  // クリーンアップ処理
  const cleanup = () => {
    axiosSource.cancel();
    setIsLoading(false);
    setErrorMessage('');
  };

  useEffect(() => {
    toggleShownHomeDialogByAccessCount();

    // スクロール位置リセット
    window.scrollTo({
      top: 0,
    });

    return cleanup;
  }, []);

  return {
    isLoading,
    errorMessage,
    emailRef,
    passwordRef,
    postApiLogin,
    isShownHomeDialog,
    onCloseHomeDialog,
  };
};
