import { FC } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
// Components
import FormLayout from 'components/templates/FormLayout';
import NeonBorderTitle from 'components/molecules/title/NeonBorderTitle';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ColorBorderInput from 'components/atoms/input/ColorBorderInput';
import EqualIcon from 'components/atoms/icon/EqualIcon';
import ColorSquare from 'components/atoms/frame/ColorFrame';
import RadioButton from 'components/atoms/radio/RadioButton';
import RoundButton from 'components/atoms/button/RoundButton';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Hooks
import { useWithdrawalTop } from 'hooks/pages/private/useWithdrawalTop';
// Utils
import { withdrawalTopBreadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';

/**
 *
 * Comoponent
 *
 */
const WithdrawalTop: FC = () => {
  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    pointErrorMessage,
    exitPoints,
    changeExitPoints,
    postApiRateWithdraw,
    balancePoints,
    rateJpy,
    address,
    changeAddress,
    blurAddress,
    addressErrorMessage,
    goToWithdrawalPage,
    goToMypage,
  } = useWithdrawalTop();

  const pointInputProps = {
    type: 'text',
    value: exitPoints,
    onChange: changeExitPoints,
    onBlur: postApiRateWithdraw,
  };

  const addressInputProps = {
    type: 'text',
    value: address,
    onChange: changeAddress,
    onBlur: blurAddress,
  };

  return (
    <>
      <Helmet>
        <title>{t('title.withdrawalTop')}</title>
        <meta name="description" content={t('description.withdrawalTop')} />
      </Helmet>

      {isLoading && <LoaderWindow />}

      <FormLayout
        breadCrumbItems={withdrawalTopBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('ポイント出金・交換')}
        titleStyle="gold"
      >
        <FormS>
          <BlockS>
            <NeonBorderTitle
              title={t('出金・交換ポイント数')}
              description={t('出金・交換をするポイント数を入力してください。')}
            />

            <FieldAreaS>
              {pointErrorMessage && (
                <MessageWrapS>
                  <ErrorMessage>{t(pointErrorMessage)}</ErrorMessage>
                </MessageWrapS>
              )}

              <FieldS>
                <PointAreaS>
                  <PointInputBlockS>
                    <PointInputWrapS>
                      <PurchaseTextS>
                        {t('金額を入れてください。')}
                      </PurchaseTextS>
                      <ColorBorderInput
                        color="#f502fd"
                        textAlign="end"
                        unit="$"
                        {...pointInputProps}
                      />
                    </PointInputWrapS>
                    <UnitOuterS>{t('ポイント')}</UnitOuterS>
                  </PointInputBlockS>
                  <BalanceBlockS>
                    <UnitS>{t('ポイント残高')}</UnitS>
                    <BalanceFrameS>
                      <DollarS>$</DollarS>
                      <PointS>{balancePoints}</PointS>
                    </BalanceFrameS>
                  </BalanceBlockS>
                </PointAreaS>

                <EqualAreaS>
                  <EqualWrapS>
                    <EqualIcon />
                  </EqualWrapS>
                </EqualAreaS>

                <MoneyAreaS>
                  <UsdtAreaS>
                    <HiddenNoticeTextS>
                      {t('日本円での合計金額(手数料込)')}
                    </HiddenNoticeTextS>
                    <FrameWrapS>
                      <ColorSquare color="#f502fd" justifyContent="end">
                        <MoneyS>{exitPoints}</MoneyS>
                        <UnitS>USDT</UnitS>
                      </ColorSquare>
                    </FrameWrapS>
                  </UsdtAreaS>
                  <JpyAreaS>
                    <FrameWrapS>
                      <ColorSquare color="#f502fd" justifyContent="end">
                        <MoneyS>{rateJpy}</MoneyS>
                        <UnitS>{t('円')}</UnitS>
                      </ColorSquare>
                    </FrameWrapS>
                    <NoticeTextS>
                      {t('日本円での合計金額(手数料込)')}
                    </NoticeTextS>
                  </JpyAreaS>
                </MoneyAreaS>
              </FieldS>
            </FieldAreaS>
          </BlockS>

          <BlockS>
            <NeonBorderTitle
              title={t('出金・交換方法を選択')}
            />

            <SelectAreaS>
              <RadioButton
                radioText={t('USDT')}
              >
                <LabelS
                  isChecked
                >
                  <RadioS
                    type="radio"
                    name="withdrawal"
                  />
                </LabelS>
              </RadioButton>
              <RadioButton
                radioText={t('現金振込')}
                isDisabled
              >
                <LabelS
                  isChecked={false}
                >
                  <RadioS
                    type="radio"
                    name="withdrawal"
                  />
                </LabelS>
              </RadioButton>
            </SelectAreaS>

            <AddressAreaS>
              <AddressInputWrapS>
                <AddressTitleS>
                  {t('送金先アドレス')}
                </AddressTitleS>
                <ColorBorderInput
                  color="#f502fd"
                  textAlign="start"
                  unit={null}
                  {...addressInputProps}
                />
              </AddressInputWrapS>
              {addressErrorMessage && (
                <MessageWrapS>
                  <ErrorMessage>{t(addressErrorMessage)}</ErrorMessage>
                </MessageWrapS>
              )}
            </AddressAreaS>

            <ButtonAreaS>
              <ButtonWrapS>
                {isLoading ? (
                  <RoundButton
                    background="#555555"
                    borderColor="rgba(230, 230, 230, 1)"
                  >
                    {t('選択')}
                  </RoundButton>
                ) : (
                  <RoundButton
                    background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                    borderColor="rgba(0,135,167,1)"
                    onClick={goToWithdrawalPage}
                  >
                    {t('選択')}
                  </RoundButton>
                )}
              </ButtonWrapS>
              <ButtonWrapS>
                <RoundButton
                  background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                  borderColor="rgba(219,0,177,1)"
                  onClick={goToMypage}
                >
                  {t('戻る')}
                </RoundButton>
              </ButtonWrapS>
            </ButtonAreaS>
          </BlockS>
        </FormS>
      </FormLayout>
    </>
  );
};
export default WithdrawalTop;

/**
 *
 * Styles
 *
 */
const FormS = styled.div`
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 7rem;

  @media (min-width: 1025px) {
    width: 90%;
    row-gap: 8rem;
  }
`;

const BlockS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3.7rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    row-gap: 5rem;
  }

  @media (min-width: 1025px) {
    row-gap: 6rem;
  }
`;

const FieldAreaS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center center;
  row-gap: 2.6rem;
`;

const FieldS = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center center;
  row-gap: 4rem;

  @media (min-width: 600px) {
    width: 90%;
    flex-direction: row;
  }
`;

const PointAreaS = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  @media (min-width: 600px) {
    flex: 2;
    align-items: flex-end;
  }
`;

const PointInputBlockS = styled.div`
  display: flex;
  column-gap: 1rem;

  @media (min-width: 600px) {
    width: 90%;
  }
`;

const PurchaseTextS = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 2rem;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const PointInputWrapS = styled.div`
  flex: 2;
`;

const BalanceBlockS = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;

  @media (min-width: 600px) {
    width: 90%;
  }
`;

const BalanceFrameS = styled.div`
  display: flex;
  flex: 2;
  border: 2px solid #f502fd;
  border-radius: 7px;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
`;

const FrameWrapS = styled.div`
  width: 70%;
`;

const NoticeTextS = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ffffff;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const HiddenNoticeTextS = styled(NoticeTextS)`
  color: transparent;
  visibility: hidden;
`;

const EqualAreaS = styled.div`
  display: flex;
  place-content: center center;

  @media (min-width: 600px) {
    flex: 1;
  }
`;

const EqualWrapS = styled.div`
  width: 50%;
`;

const MoneyAreaS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center center;
  row-gap: 2rem;

  @media (min-width: 600px) {
    flex: 2;
    row-gap: 3rem;
  }
`;

const UsdtAreaS = styled.div`
  width: 100%;
  display: flex;
  column-gap: 1rem;
  row-gap: 2rem;
  flex-direction: column;
`;

const JpyAreaS = styled.div`
  width: 100%;
  display: flex;
  column-gap: 1rem;
  row-gap: 2rem;
  flex-direction: column;
`;

const SelectAreaS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2.6rem;

  @media (min-width: 600px) {
    row-gap: 4rem;
  }
`;

const LabelS = styled.label<{ isChecked: boolean }>`
  cursor: pointer;
  padding-left: 2.7rem;
  position: relative;

  @media (min-width: 600px) {
    padding-left: 3rem;
  }

  @media (min-width: 1025px) {
    padding-left: 4.5rem;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }

  &::before {
    border: 2px solid #afeeee;
    height: 20px;
    width: 20px;
    left: 0px;

    @media (min-width: 600px) {
      border: 2px solid #afeeee;
      height: 22px;
      width: 22px;
    }

    @media (min-width: 1025px) {
      border: 3px solid #afeeee;
      height: 30px;
      width: 30px;
    }
  }

  &::after {
    background-color: #afeeee;
    height: 10px;
    width: 10px;
    left: 5px;
    opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};

    @media (min-width: 600px) {
      height: 12px;
      width: 12px;
      left: 5px;
    }

    @media (min-width: 1025px) {
      height: 16px;
      width: 16px;
      left: 7px;
    }
  }
`;

const RadioS = styled.input`
  position: absolute;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
`;

const TextS = styled.p`
  font-size: 1.4rem;
  color: #ffffff;
  word-break: break-all;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const DollarS = styled.span`
  text-align: start;
  font-size: 1.4rem;
  color: #ffffff;
  word-break: break-all;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  
`;

const PointS = styled.span`
  width: 100%;
  text-align: end;
  font-size: 1.4rem;
  color: #ffffff;
  word-break: break-all;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const MoneyS = styled(TextS)`
  font-size: 1.6rem;
  text-align: end;
  width: 100%;
`;

const UnitOuterS = styled(TextS)`
  display: flex;
  align-items: flex-end;
  color: #ffeb00;
  white-space: no-wrap;
  width: auto;
  flex: none;
  width: max-content;
`;

const UnitS = styled.p`
  font-size: 1.4rem;
  color: #ffeb00;
  word-break: break-all;
  margin-left: 1rem;
  white-space: no-wrap;
  width: auto;
  flex: none;
  width: max-content;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const ButtonAreaS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center center;
  row-gap: 2.4rem;
`;

const ButtonWrapS = styled.div`
  width: clamp(140px, 33vw, 170px);
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const AddressAreaS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  @media (min-width: 1025px) {
    width: 90%;
  }
`;

const AddressTitleS = styled.div`
  width: 100%;
  font-size: 1.8rem;
  color: #ffffff;
  padding-bottom: 1rem;
`;

const AddressInputWrapS = styled.div`
  width: 100%;
`;

const MessageWrapS = styled.div`
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
  word-break: break-all;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.8rem;
  }
`;
