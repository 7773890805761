import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
// External library
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
import { MINIMUM_PURCHASE_POINT, CRYPTCURRENCY, MAXIMUM_PURCHASE_CREDIT } from 'utils/const/payment';
// Types
import {
  RateSaveType,
  PostPaymentType,
  PointCampaignSelectType,
} from 'types/api';
import type { CreditPayment } from 'types/api';
import { CcLocationState, CreditLocationState } from 'types/paymentType';
// Images
import depositCashPc from 'assets/images/deposit/dlt_icon_cash@2x.png';
import depositCreditPc from 'assets/images/deposit/dlt_icon_creditcard@2x.png';
import depositBitcoinPc from 'assets/images/deposit/dlt_icon_bitcoin@2x.png';
import depositUsdtPc from 'assets/images/deposit/dlt_icon_usdt@2x.png';
import depositEthPc from 'assets/images/deposit/dlt_icon_eth@2x.png';

/**
 * Types
 */
// 仮想通貨種別
type CryptocurrencyType = typeof CRYPTCURRENCY[number];

// 購入方法
type PurchaseType = 'btc' | 'usdt' | 'eth' | 'credit' | 'jpy';
type PurchaseRadio = {
  value: PurchaseType;
  radioText: string;
  image: string;
  changePurchase: () => void;
};

/**
 *
 * ポイント購入ページ用カスタムフック
 *
 */
export const usePointPurchase = (): {
  isLoading: boolean;
  purchase: PurchaseType;
  purchaseRadioList: PurchaseRadio[];
  setPurchase: Dispatch<SetStateAction<PurchaseType>>;
  onClickPurchase: () => void;
  campaigns: PointCampaignSelectType[];
} => {
  // i18next
  const { t } = useTranslation();
  // エラー用カスタムフック
  const { displayErrorMessageInToast } = useError();

  // ステート一覧
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<CryptocurrencyType>('BTC');
  const [point, setPoint] = useState<string>(MINIMUM_PURCHASE_POINT);
  const [purchase, setPurchase] = useState<PurchaseType>('jpy');

  // 入金ボーナスポイントリクエスト
  const [campaigns, setCampaigns] = useState<PointCampaignSelectType[]>([]);
  const pointCampaignSelectSource = axiosCancelToken.source();
  const getApiPointCampaignSelect = () => {
    // axios GET /api/point
    axiosClient
      .get<PointCampaignSelectType[]>('/api/point_campaign/select', {
        cancelToken: pointCampaignSelectSource.token,
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setCampaigns([...data]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // ラジオボタン用リスト
  const purchaseRadioList: PurchaseRadio[] = [
    {
      value: 'jpy',
      radioText: t('現金振込'),
      image: depositCashPc,
      changePurchase: () => {
        setPurchase('jpy');
      },
    },
    {
      value: 'credit',
      radioText: t('クレジット'),
      image: depositCreditPc,
      changePurchase: () => setPurchase('credit'),
    },
    {
      value: 'btc',
      radioText: t('BTC'),
      image: depositBitcoinPc,
      changePurchase: () => {
        setPurchase('btc');
        setSelectedCurrency('BTC');
      },
    },
    {
      value: 'usdt',
      radioText: t('USDT'),
      image: depositUsdtPc,
      changePurchase: () => {
        setPurchase('usdt');
        setSelectedCurrency('USDT');
      },
    },
    {
      value: 'eth',
      radioText: t('ETH'),
      image: depositEthPc,
      changePurchase: () => {
        setPurchase('eth');
        setSelectedCurrency('ETH');
      },
    },
  ];

  // historyオブジェクト
  const history = useHistory();

  // API レート保存
  const rateSaveSource = axiosCancelToken.source();
  const postApiRateSave = (payment_type: PostPaymentType) => (
    axiosClient
      .post<RateSaveType>('/api/rate/save', {
        point,
        payment_type,
        cancelToken: rateSaveSource.token,
      })
  );

  // API クレジットカード決済パラメーター取得
  const creditPaymentSource = axiosCancelToken.source();
  const getApiCreditPayment = (transactionId: string, needUsd: number) => (
    axiosClient
      .get<CreditPayment>(
        `/api/credit/payment?need_usd=${needUsd}&transaction_id=${transactionId}`,
        { cancelToken: creditPaymentSource.token },
      )
  );

  // 購入ボタン押下
  const onClickPurchase = async () => {
    try {
      setIsLoading(true);

      if (purchase === 'btc' || purchase === 'usdt' || purchase === 'eth') {
        const { data } = await postApiRateSave(selectedCurrency);
        const state: CcLocationState = {
          referrer: '/point_purchase',
          point,
          needBtc: data.need_btc,
          needEth: data.need_eth,
          needUsdt: data.need_usdt,
          selectedCurrency,
        };
        history.push({
          pathname: '/point_purchase/cc',
          state,
        });
      } else if (purchase === 'credit') {
        const { data: rateSave } = await postApiRateSave('USD');
        const { data: creditPayment } = (
          await getApiCreditPayment(rateSave.transaction_id, rateSave.need_usd)
        );
        const state: CreditLocationState = {
          referrer: '/point_purchase',
          point,
          needUsd: rateSave.need_usd,
          cid: creditPayment.cid,
          mail: creditPayment.mail,
          encodedMail: '',
          prc: creditPayment.prc,
          url1: creditPayment.url1,
          url2: creditPayment.url2,
          user: creditPayment.user,
          f: creditPayment.f ?? '',
        };
        history.push({
          pathname: '/point_purchase/credit',
          state,
        });
      } else {
        history.push({
          pathname: '/point_purchase/cash',
        });
      }
    } catch (e) {
      displayErrorMessageInToast(e);
      setIsLoading(false);
    }
  };

  // クリーンアップ処理
  const cleanup = () => {
    setIsLoading(false);
    setPoint('0');
    setSelectedCurrency('BTC');
    setPurchase('jpy');
    setCampaigns([]);
    pointCampaignSelectSource.cancel();
    rateSaveSource.cancel();
  };

  useEffect(() => {
    // スクロール位置リセット
    window.scrollTo({
      top: 0,
    });

    // 入金ボーナスポイント取得API実行
    getApiPointCampaignSelect();

    return cleanup;
  }, []);

  return {
    isLoading,
    purchase,
    purchaseRadioList,
    setPurchase,
    onClickPurchase,
    campaigns,
  };
};
