import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import CommonModal from 'components/organisms/modal/CommonModal';
import VideoBox from 'components/organisms/lottery/VideoBox';
import RankingBox from 'components/organisms/lottery/RankingBox';
import WinnersBox from 'components/organisms/lottery/WinnersBox';
import EntryUserBox from 'components/organisms/lottery/EntryUserBox';
import AcquisitionPoint from 'components/organisms/lottery/AcquisitionPoint';
import RoundButton from 'components/atoms/button/RoundButton';
// Custom hooks
import { useLottery } from 'hooks/pages/private/useLottery';
import { useWindowDimensions } from 'hooks/versatile/useWindowDimensions';
// Utils
import { devices } from 'utils/const/devices';
// Images
import backGroundMainPc from 'assets/images/backGround/lottery_pc.jpg';
import backGroundMainPc2x from 'assets/images/backGround/lottery_pc@2x.jpg';
// Music
import exampleMusic from 'assets/music/example.mp3';

const PC_BREAK_POINT_WIDTH = 1024;

/**
 *
 * Component
 *
 */
const Lottery: FC = memo(() => {
  // Hooks
  const { t } = useTranslation();
  const { windowDimensions } = useWindowDimensions();
  const {
    isLoading,
    entryUsersScrollPagination,
    winnersScrollPagination,
    isShowModal,
    canWatchMovie,
    canReceivePoints,
    playBoxType,
    onClickLotteryCheck,
    getApiEntryUsers,
    getApiWinners,
    onReceive,
    goBack,
    audioRef,
  } = useLottery();

  return (
    <>
      <Helmet>
        <title>{t('title.lottery')}</title>
        <meta name="description" content={t('description.lottery')} />
      </Helmet>
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <audio
        ref={audioRef}
        preload="auto"
        src={exampleMusic}
      />
      <LotteryS>
        {/* モーダルウィンドウ */}
        {isShowModal && (
          <CommonModal backgroundColor="rgba(0, 0, 0, 0.8)">
            <ModalContainerS>
              <ModalTextS>
                {t(
                  'このページは動画が自動再生されます。よろしければ"OK"を押してください。動画を再生せずポイントを受け取る場合は"受け取る"を押してください。',
                )}
              </ModalTextS>
              <RoundButtonWrapS>
                {isLoading ? (
                  <>
                    <RoundButton
                      background="#555555"
                      borderColor="rgba(230, 230, 230, 1)"
                    >
                      OK
                    </RoundButton>
                    <RoundButton
                      background="#555555"
                      borderColor="rgba(230, 230, 230, 1)"
                    >
                      {t('受け取る')}
                    </RoundButton>
                    <RoundButton
                      background="#555555"
                      borderColor="rgba(230, 230, 230, 1)"
                    >
                      {t('戻る')}
                    </RoundButton>
                  </>
                ) : (
                  <>
                    {canWatchMovie && (
                      <RoundButton
                        onClick={onClickLotteryCheck}
                        background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                        borderColor="rgba(219,0,177,1)"
                      >
                        OK
                      </RoundButton>
                    )}
                    {!canWatchMovie && (
                      <RoundButton
                        background="#555555"
                        borderColor="rgba(230, 230, 230, 1)"
                      >
                        OK
                      </RoundButton>
                    )}
                    {canReceivePoints && (
                      <RoundButton
                        onClick={onReceive}
                        background="linear-gradient(rgba(0,5,65,1), 90%, rgba(14,152,108,0.9))"
                        borderColor="rgba(14,152,108,1)"
                      >
                        {t('受け取る')}
                      </RoundButton>
                    )}
                    {!canReceivePoints && (
                      <RoundButton
                        background="#555555"
                        borderColor="rgba(230, 230, 230, 1)"
                      >
                        {t('受け取る')}
                      </RoundButton>
                    )}
                    <RoundButton
                      onClick={goBack}
                      background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                      borderColor="rgba(0,135,167,1)"
                    >
                      {t('戻る')}
                    </RoundButton>
                  </>
                )}
              </RoundButtonWrapS>
            </ModalContainerS>
          </CommonModal>
        )}

        {/* スマホ&タブレット用レイアウト */}
        {windowDimensions.width < PC_BREAK_POINT_WIDTH ? (
          <MainContainerSpTabS>
            <VideoBoxWrapSpTabS>
              <VideoBox
                playBoxType={playBoxType}
              />
            </VideoBoxWrapSpTabS>

            <SubContainerSpTabS>
              <AcquisitionPointWrapSpTabS>
                <AcquisitionPoint />
              </AcquisitionPointWrapSpTabS>

              <EntryUserBoxWrapSpTabS>
                <EntryUserBox
                  isLoading={isLoading}
                  scrollFirstFlag={entryUsersScrollPagination?.first ?? false}
                  scrollLastFlag={entryUsersScrollPagination?.last ?? false}
                  getApiEntryUsers={getApiEntryUsers}
                />
              </EntryUserBoxWrapSpTabS>

              <WinnerBoxWrapSpTabS>
                <WinnersBox
                  isLoading={isLoading}
                  scrollLastFlag={winnersScrollPagination?.last ?? false}
                  getApiWinners={getApiWinners}
                />
              </WinnerBoxWrapSpTabS>

              <RankingBoxWrapSpTabS>
                <RankingBox isLoading={isLoading} />
              </RankingBoxWrapSpTabS>
            </SubContainerSpTabS>
          </MainContainerSpTabS>
        ) : (
          /*  PC&タブレット横向き用レイアウト */
          <MainContainerPcS>
            <TopWrapPcS>
              <VideoBoxWrapPcS>
                <VideoBox
                  playBoxType={playBoxType}
                />
              </VideoBoxWrapPcS>

              <EntryUserBoxWrapPcS>
                <EntryUserBox
                  isLoading={isLoading}
                  scrollFirstFlag={entryUsersScrollPagination?.first ?? false}
                  scrollLastFlag={entryUsersScrollPagination?.last ?? false}
                  getApiEntryUsers={getApiEntryUsers}
                />
              </EntryUserBoxWrapPcS>
            </TopWrapPcS>

            <BottomWrapPcS>
              <WinnerBoxWrapPcS>
                <WinnersBox
                  isLoading={isLoading}
                  scrollLastFlag={winnersScrollPagination?.last ?? false}
                  getApiWinners={getApiWinners}
                />
              </WinnerBoxWrapPcS>

              <RankingBoxWrapPcS>
                <RankingBox isLoading={isLoading} />
              </RankingBoxWrapPcS>

              <AcquisitionPointWrapPcS>
                <AcquisitionPoint />
              </AcquisitionPointWrapPcS>
            </BottomWrapPcS>
          </MainContainerPcS>
        )}
      </LotteryS>
    </>
  );
});
export default Lottery;

/**
 *
 * Styles
 *
 */
const {
  mobileLandscape, tabletPortrait, tabletLandscape, pc,
} = devices;

const LotteryS = styled.main`
  width: 100%;
  color: #ffffff;
  background-image: image-set(
    url(${backGroundMainPc}) 1x,
    url(${backGroundMainPc2x}) 2x
  );
  background-size: cover;
  background-position-y: top;
  background-attachment: fixed;
  background-repeat: no-repeat;
`;

const ModalContainerS = styled.div`
  width: min(85%, 300px);
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${tabletPortrait} {
    width: 50%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 500px;
  }
`;

const ModalTextS = styled.p`
  font-size: 1.6rem;
  line-height: 1.5;
  color: #ffffff;
  word-break: break-all;
  margin-bottom: 3rem;
`;

const RoundButtonWrapS = styled.div`
  width: min(100%, 250px);
  font-size: 1.4rem;
  display: grid;
  grid-gap: 2rem;

  @media ${tabletPortrait} {
    width: 50%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 40%;
    font-size: 1.6rem;
  }
`;

const MainContainerSpTabS = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 6rem;

  @media ${mobileLandscape} {
    position: relative;
    flex-direction: row;
  }
`;

const VideoBoxWrapSpTabS = styled.div`
  width: 100%;
  height: 70vw;
  display: block;

  @media ${mobileLandscape} {
    width: 50vw;
    height: 100vh;
    max-height: 428px;
    position: sticky;
    top: 0;
    left: 0;
  }

  @media ${tabletPortrait} {
    height: 60vw;
  }
`;

const SubContainerSpTabS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${mobileLandscape} {
    width: 50vw;
  }
`;

const AcquisitionPointWrapSpTabS = styled.div`
  width: 100%;
  height: 65vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${mobileLandscape} {
    height: auto;
    position: sticky;
    top: 0;
    right: 0;
    z-index: 4;
  }

  @media ${tabletPortrait} {
    height: 50vw;
  }
`;

const EntryUserBoxWrapSpTabS = styled.div`
  min-height: 350px;
  display: block;
  margin-top: 0;
  margin-bottom: 0;

  @media ${mobileLandscape} {
    height: 400px;
    margin-bottom: 70px;
    margin-top: 6vw;
  }

  @media ${tabletPortrait} {
    min-height: 500px;
  }
`;

const WinnerBoxWrapSpTabS = styled.div`
  display: block;
  min-height: 320px;
  margin-bottom: 0;

  @media ${mobileLandscape} {
    height: 350px;
    margin-bottom: 70px;
  }

  @media ${tabletPortrait} {
    min-height: 450px;
  }
`;

const RankingBoxWrapSpTabS = styled.div`
  display: block;
  min-height: 320px;

  @media ${mobileLandscape} {
    height: 350px;
  }

  @media ${tabletPortrait} {
    min-height: 450px;
  }
`;

const MainContainerPcS = styled.div`
  width: 95%;
  max-width: 1200px;
  height: 100%;
  padding: 8rem 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  row-gap: 6rem;
`;

const TopWrapPcS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2rem;
`;

const VideoBoxWrapPcS = styled.div`
  width: 50%;
  height: 400px;
  font-size: 3rem;
`;

const EntryUserBoxWrapPcS = styled.div`
  width: 50%;
  height: 400px;
`;

const BottomWrapPcS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  column-gap: 2rem;
`;

const WinnerBoxWrapPcS = styled.div`
  width: calc(100% / 3);
  height: 320px;
`;

const RankingBoxWrapPcS = styled.div`
  width: calc(100% / 3);
  height: 320px;
`;

const AcquisitionPointWrapPcS = styled.div`
  width: calc(100% / 3);
  height: auto;
  margin-top: -5rem;
`;
