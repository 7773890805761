const prefix = 'DayLott\'s - ';

export const zhTwSeo = {
  title: {
    // Global pages
    top: `${prefix}首頁`,
    emailSend: `${prefix}電子郵件傳輸完成`,
    emailVerified: `${prefix}電子郵件認證`,
    whats: `${prefix}何謂 Day Lott's`,
    system: `${prefix}系統`,
    faq: `${prefix}常見問題`,
    ranking: `${prefix}排名`,
    support: `${prefix}支援`,
    responsibleGambling: `${prefix}Responsible Gambling Polic`,
    newsShow: `${prefix}注意`,
    contact: `${prefix}聯繫我們`,
    contactConfirmation: `${prefix}確認聯繫內容`,
    contactCompleted: `${prefix}查詢完成`,
    passwordReset: `${prefix}重設密碼`,
    passwordResetForm: `${prefix}密碼重置表格`,
    passwordResetCompleted: `${prefix}密碼重置完成`,
    // Guest pages
    register: `${prefix}新會員註冊`,
    registerConfirmation: `${prefix}新會員註冊`,
    login: `${prefix}登入`,
    accountDeleteCompleted: `${prefix}帳戶暫停完成`,
    // Private pages
    mypage: `${prefix}我的頁面`,
    accountDeleteConfirmation: `${prefix}暫停帳戶`,
    kycUpload: `${prefix}KYC 上傳`,
    kycConfirmation: `${prefix}KYC 確認`,
    kycCompleted: `${prefix}KYC 完成`,
    emailChange: `${prefix}電子郵件地址的變更`,
    pointPurchase: `${prefix}購買點數`,
    pointPurchaseCc: `${prefix}以虛擬貨幣存款`,
    pointPurchaseCredit: `${prefix}以信用卡存款`,
    pointPurchaseCash: `${prefix}以現金存款`,
    pointPurchaseCompleted: `${prefix}存款資訊發送完成`,
    userNewsIndex: `${prefix}通知清單`,
    userNewsShow: `${prefix}注意`,
    userNewsSkip: `${prefix}獲得積分`,
    lottery: `${prefix}彩票`,
    ticketPurchaseConfirmation: `${prefix}購買內容的確認`,
    ticketPurchaseCompleted: `${prefix}購票完成`,
    withdrawalTop: `${prefix}點數提領 提款/兌換`,
    withdrawalCc: `${prefix}汇款手续`,
    withdrawalFinish: `${prefix}系統已經收到出款與交換的請求。`,
  },
  description: {
    // Global pages
    top: 'DayLott\'s 是世界上第一個每天都會產生百萬富翁的在線彩票遊戲。',
    emailSend: `${prefix}的郵件發送完成頁面。`,
    emailVerified: `${prefix}電子郵件認證頁面。`,
    whats: 'DayLott\'s 是世界上第一個每天都會產生百萬富翁的在線彩票遊戲。',
    system: '一款在線彩票遊戲，中獎機率高達 75%。根據要輸入的賭注金額和獲勝金額準備表格。規則是一個簡單的系統，您只需購買門票並進入。',
    faq: '這是一個總結有關 DayLott\'s 的常見問題的頁面。',
    ranking: '這是DayLott\'s 的獲勝者排名頁面。',
    support: '這是有關 DayLott\'s 的常見問題、電子郵件查詢、查詢時間等的支持頁面。',
    responsibleGambling: 'We are committed to providing you with the most enjoyable gambling experience possible, but we also recognize that gambling can cause problems for some people. To ensure that you can continue to enjoy safe and manageable play, we support responsible gambling and have put in place a number of measures to assist you.',
    newsShow: `${prefix}通知頁面。`,
    contact: '這是DayLott\'s的查詢頁面。使用我們的聯繫表格與我們的客戶取得聯繫。',
    contactConfirmation: `${prefix}的查詢確認頁面。`,
    contactCompleted: `${prefix}查詢完成頁面。`,
    passwordReset: `${prefix}密碼重置頁面。`,
    passwordResetForm: `${prefix}密碼重置表格。`,
    passwordResetCompleted: `${prefix}密碼重置完成頁面。`,
    // Guest pages
    register: '註冊成為 DayLott\'s 會員並充值！購買彩票並加入遊戲！',
    registerConfirmation: `${prefix}輸入內容的確認。`,
    login: '請輸入在 DayLott\'s 中註冊的郵箱地址和密碼登錄。如果您是第一次使用，請註冊成為會員。',
    accountDeleteCompleted: `${prefix}帳戶暫停完成頁面。`,
    // Private pages
    mypage: '這是我的DayLott\'s用戶頁面。',
    accountDeleteConfirmation: `${prefix}帳戶暫停頁面。`,
    kycUpload: `${prefix}KYC 上傳頁面。`,
    kycConfirmation: `${prefix}KYC 確認頁面。`,
    kycCompleted: `${prefix}KYC 完成頁面。`,
    emailChange: `${prefix}電子郵件地址更改頁面。`,
    pointPurchase: `${prefix}積分購買頁面。`,
    pointPurchaseCc: `${prefix}使用加密貨幣存款的頁面。`,
    pointPurchaseCredit: `${prefix}用信用卡存款的頁面。`,
    pointPurchaseCash: `${prefix}用現金存款的頁面。`,
    pointPurchaseCompleted: `${prefix}存款信息已發送。`,
    userNewsIndex: `${prefix}通知列表頁面。`,
    userNewsShow: `${prefix}通知頁面。`,
    userNewsSkip: `${prefix}獲得積分頁面。`,
    lottery: `${prefix}彩票頁面。`,
    ticketPurchaseConfirmation: `${prefix}購票確認頁面。`,
    ticketPurchaseCompleted: `${prefix}購票完成頁面。`,
    withdrawalTop: `${prefix}點數提領 提款/兌換頁面。`,
    withdrawalCc: `${prefix}汇款手续頁面。`,
    withdrawalFinish: `${prefix}系統已經收到出款與交換的請求。`,
  },
};
