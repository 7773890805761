// External library
import { useLocation } from 'react-router-dom';

/**
 *
 * Types
 *
 */
type UseAccountDeleteFinish = () => {
  referrer: string;
};

/**
 *
 * Hooks
 *
 */
export const useAccountDeleteFinish: UseAccountDeleteFinish = () => {
  // Hooks
  const location = useLocation<{ referrer: string }>();
  const { referrer } = location.state ?? { referrer: '/' };

  return {
    referrer,
  };
};
