/**
 *
 * 動画音声
 *
 */
export const VOLUME_MIN = 0;
export const VOLUME_MAX = 1;

/**
 *
 * TOP動画
 *
 */
export const TOP_VIDEO = {
  VIDEO_EN: 'https://vimeo.com/741777951', // 英語
  VIDEO_JA: 'https://vimeo.com/741778906', // 日本語
  VIDEO_ZH_CN: 'https://vimeo.com/741778625', // 中国語（簡体）
  VIDEO_ZH_TW: 'https://vimeo.com/741778365', // 中国語（繁体）
  VIDEO_KO: 'https://vimeo.com/741777031', // 韓国語
} as const;

/**
 *
 * DayLott's 1000 抽選動画
 *
 */
export const PRIZE_1000POINT_VIDEOS = {
  GOLD_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741784087/rendition/360p/file.mp4?loc=external&signature=bdd764f58ed8a5f56a11f76910c66cfe69a14290fba7bd835de8f0a445067e40',
  SILVER_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741779658/rendition/360p/file.mp4?loc=external&signature=8d632750e19f76d2a07733cd6bbd824418feb6951bc28cda2868c3f5980e0def',
  BRONZE_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741786091/rendition/360p/file.mp4?loc=external&signature=371a037cdbf8f6e1a64556f9b8f70363672834cce3afdfb6f11ad9176cef4bdc',
};

/**
 *
 * DayLott's 5000 抽選動画
 *
 */
export const PRIZE_5000POINT_VIDEOS = {
  GOLD_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741784066/rendition/360p/file.mp4?loc=external&signature=26dfbcc03e054399f770375d0fead2e031ea1ba885dc6088e25a0484b24ae5c5',
  SILVER_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741779632/rendition/360p/file.mp4?loc=external&signature=2d4997652067f95fa93ac3305bf2c4fae936a131ced0189e4c7388327740091a',
  BRONZE_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741785993/rendition/360p/file.mp4?loc=external&signature=63f0d61e86136c7455eb103d5e3b4b30af5294128fc7cf63f5d6d1d59e6102e0',
};

/**
 *
 * DayLott's 10000 抽選動画
 *
 */
export const PRIZE_10000POINT_VIDEOS = {
  DIAMOND_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741785020/rendition/360p/file.mp4?loc=external&signature=d1ba6b7e495e02aca04ec23feac3abdb1c2a62755548adbfd2a41547d7d6936f',
  GOLD_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741784123/rendition/360p/file.mp4?loc=external&signature=2ea69f233d5c820f12bdd1624078eb20f4018ee398d8a04fff48f581a1f23479',
  SILVER_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741779676/rendition/360p/file.mp4?loc=external&signature=f827b25cdaf8f08a7871da1511bfd6afbfc30526434f113f3a3e8daa53b80a20',
  BRONZE_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/741786050/rendition/360p/file.mp4?loc=external&signature=81a3895a3d25b80c79d33d05737ef88492d595cf2269dcab54beceb3b493e022',
};

/*
 *
 * DayLott's 100000 イベント動画
 *
 */
// TODO: 差し替え
export const PRIZE_100000POINT_EVENT_VIDEOS = {
  KING_VIDEO_URL: 'https://player.vimeo.com/progressive_redirect/playback/760569974/rendition/540p/file.mp4?loc=external&signature=eccbf4e2afc70260669c8e90624bf94833876fb504835ae609c0dc529e0d9f91',
};

/**
 *
 * Daylott's 100000 抽選動画(SILVER)
 *
 */
export const PRIZE_100000POINT_SILVER_VIDEO = [
  { number: 1, url: 'https://vimeo.com/705330023' },
  { number: 2, url: 'https://vimeo.com/705330057' },
  { number: 3, url: 'https://vimeo.com/705330087' },
  { number: 4, url: 'https://vimeo.com/705330125' },
  { number: 5, url: 'https://vimeo.com/705330161' },
  { number: 6, url: 'https://vimeo.com/705330208' },
  { number: 7, url: 'https://vimeo.com/705330248' },
  { number: 8, url: 'https://vimeo.com/705330291' },
  { number: 9, url: 'https://vimeo.com/705330329' },
  { number: 10, url: 'https://vimeo.com/705330369' },
  { number: 11, url: 'https://vimeo.com/705330406' },
  { number: 12, url: 'https://vimeo.com/705330454' },
  { number: 13, url: 'https://vimeo.com/705330483' },
  { number: 14, url: 'https://vimeo.com/705330516' },
  { number: 15, url: 'https://vimeo.com/705330579' },
  { number: 16, url: 'https://vimeo.com/705330626' },
  { number: 17, url: 'https://vimeo.com/705330673' },
  { number: 18, url: 'https://vimeo.com/705330715' },
  { number: 19, url: 'https://vimeo.com/705330767' },
  { number: 20, url: 'https://vimeo.com/705330818' },
  { number: 21, url: 'https://vimeo.com/705330868' },
  { number: 22, url: 'https://vimeo.com/705330922' },
  { number: 23, url: 'https://vimeo.com/705330963' },
  { number: 24, url: 'https://vimeo.com/705331008' },
  { number: 25, url: 'https://vimeo.com/705331054' },
  { number: 26, url: 'https://vimeo.com/705331110' },
  { number: 27, url: 'https://vimeo.com/705331161' },
  { number: 28, url: 'https://vimeo.com/705331204' },
  { number: 29, url: 'https://vimeo.com/705331259' },
  { number: 30, url: 'https://vimeo.com/705331313' },
  { number: 31, url: 'https://vimeo.com/705331368' },
  { number: 32, url: 'https://vimeo.com/705331416' },
  { number: 33, url: 'https://vimeo.com/705331456' },
  { number: 34, url: 'https://vimeo.com/705331499' },
  { number: 35, url: 'https://vimeo.com/705331556' },
  { number: 36, url: 'https://vimeo.com/705331618' },
  { number: 37, url: 'https://vimeo.com/705331674' },
  { number: 38, url: 'https://vimeo.com/705331744' },
  { number: 39, url: 'https://vimeo.com/705331799' },
  { number: 40, url: 'https://vimeo.com/705331860' },
  { number: 41, url: 'https://vimeo.com/705331914' },
  { number: 42, url: 'https://vimeo.com/705331960' },
  { number: 43, url: 'https://vimeo.com/705332011' },
  { number: 44, url: 'https://vimeo.com/705332081' },
  { number: 45, url: 'https://vimeo.com/705332140' },
  { number: 46, url: 'https://vimeo.com/705332184' },
  { number: 47, url: 'https://vimeo.com/705332226' },
  { number: 48, url: 'https://vimeo.com/705332275' },
  { number: 49, url: 'https://vimeo.com/705332324' },
  { number: 50, url: 'https://vimeo.com/705332372' },
  { number: 51, url: 'https://vimeo.com/705332436' },
  { number: 52, url: 'https://vimeo.com/705332511' },
  { number: 53, url: 'https://vimeo.com/705332579' },
  { number: 54, url: 'https://vimeo.com/705332617' },
  { number: 55, url: 'https://vimeo.com/705332660' },
  { number: 56, url: 'https://vimeo.com/705332705' },
  { number: 57, url: 'https://vimeo.com/705332756' },
  { number: 58, url: 'https://vimeo.com/705332803' },
  { number: 59, url: 'https://vimeo.com/705332859' },
  { number: 60, url: 'https://vimeo.com/705332951' },
  { number: 61, url: 'https://vimeo.com/705333025' },
  { number: 62, url: 'https://vimeo.com/705333079' },
  { number: 63, url: 'https://vimeo.com/705333126' },
  { number: 64, url: 'https://vimeo.com/705333187' },
  { number: 65, url: 'https://vimeo.com/705339974' },
  { number: 66, url: 'https://vimeo.com/705333444' },
  { number: 67, url: 'https://vimeo.com/705333484' },
  { number: 68, url: 'https://vimeo.com/705333521' },
  { number: 69, url: 'https://vimeo.com/705333558' },
  { number: 70, url: 'https://vimeo.com/705333588' },
  { number: 71, url: 'https://vimeo.com/705333631' },
  { number: 72, url: 'https://vimeo.com/705333670' },
  { number: 73, url: 'https://vimeo.com/705333705' },
  { number: 74, url: 'https://vimeo.com/705333740' },
  { number: 75, url: 'https://vimeo.com/705333778' },
  { number: 76, url: 'https://vimeo.com/705333808' },
  { number: 77, url: 'https://vimeo.com/705333851' },
  { number: 78, url: 'https://vimeo.com/705333897' },
  { number: 79, url: 'https://vimeo.com/705333956' },
  { number: 80, url: 'https://vimeo.com/705333998' },
  { number: 81, url: 'https://vimeo.com/705334055' },
  { number: 82, url: 'https://vimeo.com/705334092' },
  { number: 83, url: 'https://vimeo.com/705341336' },
  { number: 84, url: 'https://vimeo.com/705341376' },
  { number: 85, url: 'https://vimeo.com/705341409' },
  { number: 86, url: 'https://vimeo.com/705341461' },
  { number: 87, url: 'https://vimeo.com/705341511' },
  { number: 88, url: 'https://vimeo.com/705341547' },
  { number: 89, url: 'https://vimeo.com/705341596' },
  { number: 90, url: 'https://vimeo.com/705341632' },
  { number: 91, url: 'https://vimeo.com/705342516' },
  { number: 92, url: 'https://vimeo.com/705334529' },
  { number: 93, url: 'https://vimeo.com/705334554' },
  { number: 94, url: 'https://vimeo.com/705334582' },
  { number: 95, url: 'https://vimeo.com/705334627' },
  { number: 96, url: 'https://vimeo.com/705334666' },
  { number: 97, url: 'https://vimeo.com/705334697' },
  { number: 98, url: 'https://vimeo.com/705334738' },
  { number: 99, url: 'https://vimeo.com/705334781' },
];

/**
 *
 * DayLott's 100000 抽選動画(BRONZE)
 *
 */
export const PRIZE_100000POINT_BRONZE_VIDEO = [
  { number: 0, url: 'https://vimeo.com/704078607' },
  { number: 1, url: 'https://vimeo.com/704078692' },
  { number: 2, url: 'https://vimeo.com/704078755' },
  { number: 3, url: 'https://vimeo.com/704078805' },
  { number: 4, url: 'https://vimeo.com/704078875' },
  { number: 5, url: 'https://vimeo.com/704078955' },
  { number: 6, url: 'https://vimeo.com/704079026' },
  { number: 7, url: 'https://vimeo.com/704079092' },
  { number: 8, url: 'https://vimeo.com/704079166' },
  { number: 9, url: 'https://vimeo.com/704079237' },
];

/**
 *
 * DayLott's 100000 抽選動画(OTHER)
 *
 */
export const PRIZE_100000POINT_OTHER_VIDEO = [
  { number: 0, url: 'https://vimeo.com/704104786' },
  { number: 1, url: 'https://vimeo.com/704104865' },
  { number: 2, url: 'https://vimeo.com/704104916' },
  { number: 3, url: 'https://vimeo.com/704104979' },
  { number: 4, url: 'https://vimeo.com/704445579' },
  { number: 5, url: 'https://vimeo.com/704105054' },
  { number: 6, url: 'https://vimeo.com/704105111' },
  { number: 7, url: 'https://vimeo.com/704105177' },
  { number: 8, url: 'https://vimeo.com/704105243' },
  { number: 9, url: 'https://vimeo.com/704105382' },
];
