import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { ATOM_KEYS } from 'utils/const/recoilKeys';

/**
 *
 * Types
 *
 */
type UserStateType = {
  loggedIn: boolean;
  id: number;
  name: string;
  email: string;
  ethAddress: string;
  point: number;
  freePoint: number;
  birthday: string;
  unreadNewsCount: number;
  unreadNewsUsersCount: number;
  unlotteryBetsCount: number;
  kycFlg: number;
};

// 永続化用Recoilプラグイン
const { persistAtom } = recoilPersist();

export const userState = atom<UserStateType>({
  key: ATOM_KEYS.USER_STATE,
  default: {
    loggedIn: false,
    id: 0,
    name: '',
    email: '',
    ethAddress: '',
    point: 0,
    freePoint: 0,
    birthday: '',
    unreadNewsCount: 0,
    unreadNewsUsersCount: 0,
    unlotteryBetsCount: 0,
    kycFlg: 0,
  },
  effects_UNSTABLE: [persistAtom],
});
