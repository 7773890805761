import { FC, memo } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Component
 *
 */
const LoaderWindow: FC = memo(() => (
  <LoaderWindowS>
    <CycleS />
  </LoaderWindowS>
));
export default LoaderWindow;

/**
 *
 * Styles
 *
 */
export const LoaderWindowS = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  // flex
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CycleS = styled.div`
  border-radius: 50%;
  width: 10em;
  height: 10em;
  font-size: 1rem;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.6);
  border-right: 1.1em solid rgba(255, 255, 255, 0.6);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.6);
  border-left: 1.1em solid #ffffff;

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  animation: loading 1.1s infinite linear;
  transform: translateZ(0);

  &::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
`;
