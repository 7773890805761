import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { useRecoilValue } from 'recoil';
// Hooks
import { PlayBoxType, useVimeoVideo } from 'hooks/organisms/lottery/useVimeoVideo';
// Recoil
import { productionEndFlagState } from 'store/atoms/lottery/productionEndFlag';
// Utils
import { devices } from 'utils/const/devices';
import { PRODUCTION_END_FLAG } from 'utils/const/lottery/lotBox';
// Assets
import iconAudioOff from 'assets/images/lottery/video/icon-audio-off.svg';
import iconAudioOn from 'assets/images/lottery/video/icon-audio-on.svg';
import iconSkip from 'assets/images/lottery/video/icon-skip.svg';

const {
  mobileLandscape,
  tabletPortrait,
  tabletLandscape,
  pc,
} = devices;

/**
 *
 * Types
 *
 */
type Props = {
  playboxType: PlayBoxType;
};

/**
 *
 * Component
 *
 */
const VimeoVideo: FC<Props> = memo(({ playboxType }) => {
  // Hooks
  const {
    isPlaying,
    movieUrl,
    mute,
    onMuted,
    onUnMuted,
    isShowAudioButton,
    isShowBlackOut,
    onClickSkip,
    onMovieReady,
    onMovieEnded,
  } = useVimeoVideo(playboxType);
  // Recoil
  const productionEndFlag = useRecoilValue(productionEndFlagState);

  return (
    <>
      {isShowBlackOut && (
        <BlackOutS />
      )}
      {/* 抽選演出VimeoVideo */}
      {movieUrl !== '' && (
        <ReactPlayer
          style={{ width: '100%' }}
          url={movieUrl}
          playsinline
          playing={isPlaying}
          onContextMenu={(e: Event) => {
            e.preventDefault();
          }}
          volume={0.5}
          muted={mute}
          width="100%"
          height="100%"
          onReady={onMovieReady}
          onEnded={onMovieEnded}
        />
      )}

      {/* 音声切り替えボタン */}
      {isShowAudioButton && (
        <AudioButtonS type="button" onClick={mute ? onUnMuted : onMuted}>
          <ImageS src={mute ? iconAudioOn : iconAudioOff} alt="" />
        </AudioButtonS>
      )}

      {/* 抽選演出スキップボタン */}
      {productionEndFlag === PRODUCTION_END_FLAG.TRUE && (
        <SkipButtonS type="button" onClick={onClickSkip}>
          <ImageS src={iconSkip} alt="" />
        </SkipButtonS>
      )}
    </>
  );
});
export default VimeoVideo;

/**
 *
 * Styles
 *
 */
const SkipButtonS = styled.button`
  width: 10%;
  position: absolute;
  top: 5%;
  right: 3%;
  z-index: 4;

  @media ${mobileLandscape} {
    top: 4%;
    right: 3%;
  }

  @media ${tabletPortrait} {
    width: 7%;
    top: 3.5%;
    right: 3.5%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 8%;
    top: 4%;
    right: 3%;
  }
`;

const AudioButtonS = styled.button`
  width: 10%;
  position: absolute;
  right: 3%;
  bottom: 5%;
  z-index: 4;

  @media ${mobileLandscape} {
    right: 3%;
    bottom: 4%;
  }

  @media ${tabletPortrait} {
    width: 7%;
    right: 3.5%;
    bottom: 3.5%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 8%;
    right: 3%;
    bottom: 4%;
  }
`;

const ImageS = styled.img`
  width: 100%;
`;

const BlackOutS = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000;
  position: absolute;
`;
