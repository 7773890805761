// eslint-disable-next-line
import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
// Components
import ArrowIcon from 'components/atoms/icon/ArrowIcon';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';
// Types
import { PlayBoxType } from 'types/playBoxType';
// Images
import purchase10BgSp from 'assets/images/top/playBox/10_sp.png';
import purchase10BgPc from 'assets/images/top/playBox/10_pc.png';
import purchase10BgPc2x from 'assets/images/top/playBox/10_pc@2x.png';
import purchase50BgSp from 'assets/images/top/playBox/50_sp.png';
import purchase50BgPc from 'assets/images/top/playBox/50_pc.png';
import purchase50BgPc2x from 'assets/images/top/playBox/50_pc@2x.png';
import purchase100BgSp from 'assets/images/top/playBox/100_sp.png';
import purchase100BgPc from 'assets/images/top/playBox/100_pc.png';
import purchase100BgPc2x from 'assets/images/top/playBox/100_pc@2x.png';

/**
 *
 * Types
 *
 */
type Background = {
  backGroundSp: string;
  backGroundPc: string;
  backGroundPc2x: string;
};

type Props = {
  playBox: PlayBoxType;
  bulkIncrement: (units: number) => void;
};

// 複数口購入数初期値10口、50口、100口
const multiplyNumberList = [
  {
    number: 10,
    backGroundSp: purchase10BgSp,
    backGroundPc: purchase10BgPc,
    backGroundPc2x: purchase10BgPc2x,
    textColor: '#0A4049',
  },
  {
    number: 50,
    backGroundSp: purchase50BgSp,
    backGroundPc: purchase50BgPc,
    backGroundPc2x: purchase50BgPc2x,
    textColor: '#77034D',
  },
  {
    number: 100,
    backGroundSp: purchase100BgSp,
    backGroundPc: purchase100BgPc,
    backGroundPc2x: purchase100BgPc2x,
    textColor: '#26086D',
  },
];

const { ENGLISH } = LANG;

/**
 *
 * Component
 *
 */
// 複数購入ボタン用コンポーネント
const MultiplePurchaseButton: FC<Props> = memo((props) => {
  const { playBox, bulkIncrement } = props;
  const { t } = useTranslation();
  const lang = useRecoilValue(langState);

  return (
    <MultiplePurchaseS>
      {multiplyNumberList.map((item) => (
        <ButtonS
          type="button"
          key={uuidv4()}
          onClick={() => bulkIncrement(item.number)}
          backGroundSp={item.backGroundSp}
          backGroundPc={item.backGroundPc}
          backGroundPc2x={item.backGroundPc2x}
          disabled={
            playBox.recruitment === playBox.lot_tables[0]?.lot_bets_count
          }
        >
          {lang === ENGLISH ? (
            <ButtonContentEnS>
              <ButtonTextWrapEnS>
                <PurchaseUnitNumberEnS>
                  <PurchaseUnitWrapLeftS>
                    <PurchaseUnitEnS>Buy</PurchaseUnitEnS>
                  </PurchaseUnitWrapLeftS>
                  <NumberWrapEnS>
                    <NumberEnS>{item.number}</NumberEnS>
                  </NumberWrapEnS>
                  <PurchaseUnitWrapRightS>
                    <PurchaseUnitEnS>units</PurchaseUnitEnS>
                  </PurchaseUnitWrapRightS>
                </PurchaseUnitNumberEnS>

                <PurchaseUnitPointWrapEnS>
                  <PurchaseUnitPointEnS>
                    {`( ${(
                      playBox.minimum_bet * item.number
                    ).toLocaleString()}P )`}
                  </PurchaseUnitPointEnS>
                </PurchaseUnitPointWrapEnS>
              </ButtonTextWrapEnS>
              <WinningRateEnS>
                <RateLabelWrapS>
                  <RateLabelS>{t('獲得確率')}</RateLabelS>
                </RateLabelWrapS>
                <RateNumberWrapS>
                  <RateNumberS color={item.textColor}>
                    {` ${item.number}/${playBox.recruitment}`}
                  </RateNumberS>
                </RateNumberWrapS>
              </WinningRateEnS>
            </ButtonContentEnS>
          ) : (
            <ButtonContentS>
              <ButtonTextWrapS>
                <PurchaseUnitNumberS>
                  <NumberWrapS>
                    <NumberS>{item.number}</NumberS>
                  </NumberWrapS>
                  <UnitWrapJaS>
                    <PurchaseUnitWrapS>
                      <PurchaseUnitS>{t('口購入')}</PurchaseUnitS>
                    </PurchaseUnitWrapS>
                    <PurchaseUnitPointWrapS>
                      <PurchaseUnitPointS className="ja">
                        {`( ${(
                          playBox.minimum_bet * item.number
                        ).toLocaleString()}P )`}
                      </PurchaseUnitPointS>
                    </PurchaseUnitPointWrapS>
                  </UnitWrapJaS>
                </PurchaseUnitNumberS>
              </ButtonTextWrapS>
              <WinningRateS>
                <RateLabelWrapS>
                  <RateLabelS>{t('獲得')}</RateLabelS>
                  <RateLabelS>{t('確率')}</RateLabelS>
                </RateLabelWrapS>
                <RateNumberWrapS>
                  <RateNumberS color={item.textColor}>
                    {` ${item.number / item.number}/${playBox.recruitment / item.number}`}
                  </RateNumberS>
                </RateNumberWrapS>
              </WinningRateS>
            </ButtonContentS>
          )}

          <ArrowIconWrapS>
            <ArrowIcon rotate="-45deg" />
          </ArrowIconWrapS>
        </ButtonS>
      ))}
    </MultiplePurchaseS>
  );
});
export default MultiplePurchaseButton;

/**
 *
 * Styles
 *
 */
const {
  tabletPortrait, tabletLandscape, pc,
} = devices;

const MultiplePurchaseS = styled.div`
  width: 100%;
  border: 1px solid #ffffff;
  display: flex;
  flex-direction: column;

  @media ${tabletLandscape}, ${pc} {
    width: 790px;
    flex-direction: unset;
  }
`;

const ButtonS = styled.button<Background>`
  width: 100%;
  color: #ffffff;
  background-image: url(${({ backGroundSp }) => backGroundSp});
  background-size: cover;
  background-position: center;
  padding: 1.6rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  border-top: 1px solid #ffffff;

  &:first-child {
    border-top: none;
  }

  &:only-child {
    border-top: none;
  }

  @media (min-width: 600px) {
    padding: 3rem 2rem;
  }

  @media ${tabletLandscape}, ${pc} {
    border: 1px solid #ffffff;
    background-image: image-set(
      url(${({ backGroundPc }) => backGroundPc}) 1x,
      url(${({ backGroundPc2x }) => backGroundPc2x}) 2x
    );
    padding: 2rem 1rem;
    justify-content: center;
    background-position: inherit;
  }
`;

const TextS = styled.p`
  font-size: 1.4rem;
  word-break: break-word;
  line-height: 1.6;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const ButtonContentS = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media ${tabletLandscape}, ${pc} {
    flex-direction: column;
  }
`;

const ButtonContentEnS = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${tabletLandscape}, ${pc} {
    flex-direction: column;
  }
`;

const ButtonTextWrapS = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;

  @media (min-width: 600px) {
    max-width: 365px;
  }

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 1rem;
  }
`;

const ButtonTextWrapEnS = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @media (min-width: 600px) {
    padding-left: 2rem;
    flex-direction: unset;
    max-width: 365px;
    column-gap: 1rem;
  }

  @media ${tabletLandscape}, ${pc} {
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 1rem;
    max-width: unset;
    column-gap: unset;
    justify-content: center;
    row-gap: 0.4rem;
  }
`;

const PurchaseUnitNumberS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    width: 100%;
    flex-direction: unset;
    column-gap: 1rem;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

const PurchaseUnitNumberEnS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    display: contents;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: unset;
  }
`;

const UnitWrapJaS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: unset;
    align-items: flex-end;
    column-gap: 1rem;
  }

  @media ${tabletLandscape}, ${pc} {
    flex-direction: column;
    column-gap: unset;
    row-gap: 0.4rem;
  }
`;

const NumberWrapS = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const NumberWrapEnS = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    width: 40%;
    display: contents;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 50%;
    display: contents;
  }
`;

const NumberS = styled.p`
  font-size: 4.4rem;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  font-family: 'M PLUS 2', sans-serif;

  @media (min-width: 600px) {
    font-size: 4.7rem;
    text-align: end;
  }
`;

const NumberEnS = styled.p`
  font-size: 4.4rem;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  font-family: 'M PLUS 2', sans-serif;

  @media (min-width: 600px) {
    font-size: 5rem;
    text-align: end;
    line-height: 0.9;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 4rem;
    line-height: 1;
    margin: 0 0.4rem;
  }
`;

const PurchaseUnitWrapS = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    display: contents;
  }

  @media ${tabletLandscape}, ${pc} {
    display: flex;
    justify-content: unset;
  }
`;

const PurchaseUnitWrapLeftS = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    display: contents;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 25%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

const PurchaseUnitWrapRightS = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    width: 30%;
    display: contents;
    align-items: flex-end;
    justify-content: flex-start;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 30%;
    display: flex;
  }
`;

const PurchaseUnitS = styled(TextS)`
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  font-family: 'M PLUS 2', sans-serif;

  @media (min-width: 600px) {
    min-width: fit-content;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 3rem;
    line-height: 1.1;
    text-align: start;
  }
`;

const PurchaseUnitEnS = styled(TextS)`
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  font-family: 'M PLUS 2', sans-serif;

  @media (min-width: 600px) {
    line-height: 1.1;
    text-align: start;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2.2rem;
  }
`;

const PurchaseUnitPointWrapS = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (min-width: 600px) {
    justify-content: unset;
  }
`;

const PurchaseUnitPointWrapEnS = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    width: 20%;
    display: flex;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    justify-content: center;
  }
`;

const PurchaseUnitPointS = styled.p`
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.5;

  @media ${tabletLandscape}, ${pc} {
    line-height: 1.1;
  }
`;

const PurchaseUnitPointEnS = styled.p`
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.5;

  @media (min-width: 600px) {
    line-height: 1.1;
  }
`;

const WinningRateS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 70%;
    flex-direction: unset;
    align-items: center;
    column-gap: 1rem;
  }
`;

const WinningRateEnS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    width: 40%;
    max-width: 230px;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    flex-direction: unset;
    align-items: center;
    column-gap: 1rem;
    flex-direction: column;
    max-width: unset;
    row-gap: 0.4rem;
  }
`;

const RateLabelWrapS = styled.div`
  display: flex;

  @media ${tabletLandscape}, ${pc} {
    flex-direction: column;
  }
`;

const RateNumberWrapS = styled.div`
  width: 100%;
  background: #ffffff;

  @media ${tabletPortrait} {
    width: min(25vw, 200px);
  }

  @media ${tabletLandscape}, ${pc} {
    width: 40%;
  }
`;

const RateLabelS = styled(TextS)`
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff240;
  text-align: end;

  @media ${tabletLandscape}, ${pc} {
    line-height: 1.3;
  }
`;

const RateNumberS = styled.p<{ color: string }>`
  padding: 1rem;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  color: ${({ color }) => color};

  @media ${tabletLandscape}, ${pc} {
    padding: 1.5rem 0;
  }
`;

const ArrowIconWrapS = styled.div`
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    height: 3rem;
    width: 3rem;
  }

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;
