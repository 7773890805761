import { enSeo } from 'utils/lang/seo/enSeo';

export const en = {
  // メタ情報
  ...enSeo,
  // ページ
  トップ: 'Top',
  新規会員登録: 'Sign up',
  入力内容の確認: 'Confirmation',
  メール送信完了: 'Email send',
  メール認証: 'Email verified',
  ログイン: 'Sign in',
  パスワードのリセット: 'Reset password',
  パスワードの再設定: 'Resetting your password',
  マイページ: 'Mypage',
  アカウントの停止: 'Account suspend',
  メールアドレスの変更: 'Email address change',
  'ポイント出金・交換': 'Point withdraw and exchange',
  KYC書類アップロード: 'KYC document image upload',
  "Day Lott'sとは": "What is Day Lott's ?",
  仕組み: 'System',
  よくある質問: 'FAQ',
  ランキング: 'Ranking',
  サポート: 'Support',
  お知らせ: 'News',
  言語: 'Language',
  'NEWSはありません。': 'No news.',
  '高額獲得者はいません。': 'No big winners.',
  '業界最高水準である、当選確率1/8も用意されているオンラインゲームです。還元率は75%!! 1ゲーム5Pからゲーム参加可能。5Pから10,000Pの当選も毎日開催されています。':
    'It is an online game with a winning rate of 1/8, which is the highest level in the industry. The return rate is 75% !! You can participate in the game from 5P per game. Winning from 5P to 10,000P is also held every day.',
  "1day,1million,1richをテーマにDay Lott'sは運営をしています。毎日世界中のどこかでミリオネアがDay Lott'sから誕生しています。我々のサービスは、1日1,000,000Pの当選を目指しています。":
    "Day Lott's operates under the theme of 1day, 1million, 1rich. Every day, somewhere in the world, millionaires are born from Day Lott's. Our service aims to win 1,000,000P a day.",
  "Day Lott'sでは、入金、出金の際に仮想通貨を利用することができます。仮想通貨の利用により、ボーダーレスに、そしてスピードのある入出金が可能となっております。":
    "Day Lott's allows you to use cryptocurrency for deposits and withdrawals. By using cryptocurrency, borderless and speedy deposits and withdrawals are possible.",
  'メールアドレスを登録フォームに入力し、会員登録を行います。':
    'Enter your email address in the registration form to sign up as a member.',
  会員登録: 'Sign up',
  '入金・チャージ': 'Deposit',
  '仮想通貨、振込など様々な方法でポイントをチャージすることができます。仮想通貨、振込など様々な方法でポイントをチャージすることができます。':
    'You can deposit points by various methods such as cryptocurrency, transfer.',
  チケット購入: 'Ticket purchase',
  'ゲームに参加するためにチケットを、ポイントで購入します。':
    'Buy tickets with points and participate in the game.',
  当選の確定: 'Winning confirmation',
  'ゲームにエントリーをし、ゲームが開始されたら、すぐに当選の確定が分かります。当選確定の演出もお楽しみください！':
    'As soon as you enter the game and the game starts, you will know that you have won. Please enjoy the production of winning confirmation!',
  '当選が確定したら、即座にマイページに当選ポイントが反映されます。また、そのポイントを換金して出金することができます。':
    'As soon as the winning is confirmed, the winning points will be reflected on My Page. You can also redeem those points for withdrawal.',
  // フッター
  利用規約: 'Terms of Use',
  お問い合わせ: 'Contact Us',
  利用規約に同意し登録する: 'Agree to the <0>Terms of Use</0> and register as account.',
  '※ユーザー名は後から変更をすることはできません。': '*User names cannot be changed later.',
  '※パスワードは大文字、小文字、数字を組み合わせて8文字以内で入力してください。': '*Password must be a combination of upper and lower case letters and numbers, no more than 8 characters.',
  プライバシーポリシー: 'Privacy Policy',
  責任あるギャンブル: 'Responsible Gambling ',
  "DayLott'sとこのウェブサイトは、Seven Eyes Ent. Company（会社番号227777/076/077および登録住所Sheetal Marg, Baluwater Kathmandu, Nepal）によって運営されています。 Seven Eyes Ent. Companyはネパール政府より発行された、Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010に基づき付与されたサブライセンスを通して正式な許可を保持しています。":
    "DayLott's and this website are operated by Seven Eyes Ent. Company. (Company number 227777/076/077 and registered address Sheetal Marg, Baluwater Kathmandu, Nepal) Seven Eyes Ent. Company holds a formal license through a sub-license granted under Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010 issued by the Government of Nepal. Seven Eyes Ent.",
  プレイヤー保護について: 'About Player Protection',
  責任のあるギャンブルポリシー: 'Responsible Gambling Policy',
  '1 .未成年者の保護': '1 .Protection of minors',
  '2 .プレイのルール': '2 .Rules of Play',
  '3 .結託': '3 .Collusion',
  '4 .カスタマーサービスと顧客からの苦情':
    '4 .Customer Service and Customer Complaints',
  '4.1.カスタマーサービス': '4.1.Customer Service',
  '4.2.苦情の提出': '4.2.Submission of Complaints',
  "4.3.DayLott'sとの書面による連絡に含める必要がある情報":
    "4.3.Information You Must Include in Your Written Communication with DayLott's",
  '1 .ギャンブルの自己評価': '1 .Gambling self-assessment',
  '2 .サポート組織': '2 .Support Organizations',
  '3 .コントロールの維持': '3 .Maintaining control',
  '3.1.自己排除': '3.1.Self-Exclusion',
  "インターネットはモバイル機器や世界中の家庭から簡単にアクセスできるため、責任のあるオンラインギャンブルは親の管理と監督に大きく依存しています。インターネット上での子供の安全を確保するため、DayLott'sは未成年者が不適切なオンライン資料にアクセスするのを防止するフィルタリングソフトウェアを使用するようにお客様に推奨します。DayLott'sでは、保護者に対して次のヒントを提供しています。":
    "Because the Internet is easily accessible from mobile devices and homes around the world, responsible online gambling relies heavily on parental control and supervision. To ensure the safety of children on the Internet, DayLott's encourages its customers to use filtering software that prevents minors from accessing inappropriate online material. DayLott's offers the following tips to parents",
  '自分のメールアドレスを安全に保つ。':
    'Keep your email address and password secure.',
  "自分のDayLott'sアカウントにログインしたときにコンピューターを無人で放置しない。":
    "Do not leave your computer unattended when you log in to your DayLott's account.",
  '18歳未満の人がいかなるギャンブル活動への参加も許可しない。':
    'Do not allow anyone under the age of 18 to participate in any gambling activity.',
  '自分のクレジット/デビットカードおよびその他の金融口座の詳細を、子供の手の届かないところに保管する。':
    'Keep your credit/debit card and other financial account details out of the reach of children.',
  'パスワードをコンピューターに保存しない。パスワードを書き留め、未成年者から離れた安全な場所に保管する。':
    'Do not store passwords on your computer. Write down passwords and keep them in a safe place away from minors.',
  "共有コンピューターからDayLott'sにアクセスする場合、特にコンピューターを子供と共有する場合は、「記憶する」機能を使用しない。":
    'When accessing DayLott\'s from a shared computer, especially if the computer is shared with children. Do not use the "remember" function when accessing DayLott\'s from a shared computer, especially if the computer is shared with children.',
  '未成年のギャンブルによって引き起こされる違法性と潜在的な損害について、自分の子供たちに教育する。':
    'Educate your children about the illegality and potential damage caused by underage gambling.',
  '未成年者によるすべての電子機器ならびにモバイルデバイスの使用には特に注意する。':
    'Be especially careful about the use of all electronic and mobile devices by minors.',
  '自分の子供がギャンブルサイトを含む不適切な題材にアクセスするのを防ぐために、フィルタリングソフトウェアを活用する。':
    'Use filtering software to prevent your children from accessing inappropriate material, including gambling sites.',
  "未成年者のギャンブルは違法であるだけでなく、監督されていない子供が負った早期のギャンブル依存と実質的なギャンブルでの借金につながる可能性があります。したがって、自分の携帯電話、タブレット、ラップトップまたはコンピューターを法定年齢に満たない友人または家族と共有してオンラインギャンブルに参加する場合、DayLott'sは以下のいずれかのサービスを使用して、ウェブサイトへのアクセスを制限することを推奨します。":
    "Gambling by minors is not only illegal, but can lead to early gambling addiction and substantial gambling debts incurred by unsupervised children. Therefore, if you share your cell phone, tablet, laptop or computer with a friend or family member who is under the legal age to participate in online gambling, DayLott's recommends that you restrict their access to the website by using one of the following services.",
  'Netnanny.com - 不適切なウェブコンテンツから子供を守るフィルタリングソフトウェア。':
    'Netnanny.com - Filtering software that protects children from inappropriate web content.',
  'Cybersitter.com - 保護者がブロックするサイトを自身で追加することができるフィルタリングソフトウェア。':
    'Cybersitter.com - Filtering software that allows parents to add their own sites to block.',
  'betterinternetforkids.eu - EUが共同出資し、インターネットフィルタソフトウェアに関する情報を提供し、無料のテスト版を提供するウェブサイト。':
    'betterinternetforkids.eu - A website co-sponsored by the EU that provides information about internet filter software and provides free test versions.',
  その他の重要なヒント: 'Other Important Tips',
  'ギャンブルをする決定は自分の個人的な選択であることを確認してください。次の場合は、ギャンブルをすることはおすすめしません。':
    'Make sure that your decision to gamble is your own personal choice. Gambling is not recommended in the following cases.',
  '毎日の責務を妨げる場合。': 'It interferes with your daily responsibilities.',
  '依存症の治療をしている、あるいは回復している場合。':
    'You are in treatment for or recovering from an addiction.',
  'アルコールやその他の物質の影響を受けている場合。':
    'You are under the influence of alcohol or other substances.',
  '以前の損失を取り戻そうとしている場合。あるいは賞金で借金を回収することが主な目的となっている場合。':
    'If you are trying to recover your previous loss. Or when the main goal is to collect the debt with your winnings.',
  "DayLott'sでゲームを始める前に、ゲームのルールを知ることをおすすめします。":
    "Before starting a game in DayLott's, it is recommended to know the rules of the game.",
  '顧客が他の顧客と結託していると思われる場合や、不正行為を行った場合は、直ちにカスタマーサービスにご連絡ください。':
    'If you suspect that a customer is colluding with another customer or has committed any fraudulent act, please contact Customer Service immediately.',
  'お客様はメールでカスタマーサービスにいつでも連絡することができます。':
    'Customers can contact customer service at any time via email.',
  "いかなる場合でも問題が発生してから3か月以内に、顧客の苦情/申し立てを提出する必要があります。お客様の苦情/申し立てが正確な部署に向けられ、調査されていることを確実にするために、以下の手段を介して書面による連絡をDayLott'sに提出しなければなりません。":
    "In any case, customer complaints/allegations must be submitted within three months of the problem occurring. In order to ensure that the customer's complaint/allegation is directed to the correct department and investigated, written communication must be submitted to DayLott's via the following means",
  電子メール: 'Email',
  "お客様のプライバシーを保護するために、お客様とDayLott's間のすべての電子メールによる通信は、DayLott'sで保持されているお客様の顧客アカウントに登録したメールアドレスを使用して実行する必要があります。そうでない場合、当社の対応が遅れることがあります。DayLott'sとの(苦情を含む) あらゆる書面による連絡には、以下の情報を含める必要があります。":
    "In order to protect your privacy, all email communications between you and DayLott's must be executed using the email address registered in your customer account maintained with DayLott's. Otherwise, our response may be delayed. Any written communication with DayLott's (including complaints) must include the following information.",
  'お客様のニックネーム/電話番号': 'Your nickname/phone number',
  お客様の顧客アカウントに登録済みの氏名:
    'Your name as it appears in your customer account',
  '苦情/申し立ての詳細': 'Details of the complaint/allegation',
  '苦情/申し立てに関連する特定の日付と時刻 (該当する場合)':
    'The specific date and time related to the complaint/allegation (if applicable)',
  "DayLott'sでは、お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。":
    "At DayLott's, we are committed to providing you with the most enjoyable gambling experience possible, but we also recognize that gambling can cause problems for some people. To ensure that you can continue to enjoy safe and manageable play, we support responsible gambling and have put in place a number of measures to assist you.",
  'ギャンブルによりお客様自身 (またはその他の人) の人生が支配されている可能性があると懸念する場合、次の質問で判明することがあるかもしれません。':
    "If you are concerned that gambling may be taking control of your (or others') life, the following questions may help you find out.",
  'つまらない日常や不幸な人生を忘れるためにギャンブルをしますか？':
    'Do you gamble to forget about your boring or unhappy life?',
  'ギャンブルをしていてお金が足りなくなったら、喪失感を味わい絶望を感じ、できるだけ早く再びギャンブルをする必要がありますか？':
    'If you are gambling and run out of money, do you feel lost and hopeless and need to gamble again as soon as possible?',
  '最後の1円がなくなるまでギャンブルをしますか？それが、たとえば家への交通費や生活費だとしてもですか？':
    'Do you gamble until your last penny is gone? Would you gamble until your last penny was gone, even if it was for, say, transportation home or living expenses?',
  'ギャンブルに費やした金額や時間の嘘をついてごまかしたことがありますか？':
    'Have you ever lied and cheated about the amount of money or time you spent on gambling?',
  'ギャンブルが原因で家族、友人、趣味への興味を失ったことがありますか？':
    'Have you ever lost interest in your family, friends, or hobbies because of gambling?',
  '負けた後、できるだけ早くもう一度挑戦して損失を勝たなければならないと感じますか？':
    'After losing, do you feel compelled to try again as soon as possible to win your losses?',
  '議論、不満、失望によりギャンブルをしたくなりますか？':
    'Do arguments, frustrations, or disappointments make you want to gamble?',
  'ギャンブルをすることが原因で落ち込む、あるいは自分自身に害を及ぼすと感じることがありますか？':
    'Do you feel depressed or harm yourself because of gambling?',
  'これらの質問に "はい" という答えが多いほど、深刻なギャンブルの問題を抱えている可能性が高くなります。':
    'The more "yes" answers you give to these questions, the more likely you are to have a serious gambling problem.',
  'この問題について相談するには、カスタマーサービスに連絡するか、無料で独立したアドバイスを提供する数々のサポート組織があります。ご相談希望の方は、自国のサポート組織・機関に問い合わせをして下さい。':
    'To discuss this issue, you can contact customer service or one of the many support organizations that offer free, independent advice. Please contact the support organization in your country if you would like to discuss your problem.',
  'お客様あるいは知り合いの方がギャンブルの問題を抱えている場合、認定された組織からの支援を検討することをおすすめします。':
    'If you or someone you know has a gambling problem, you may want to consider getting help from an accredited organization.',
  "DayLott'sでは、お客様にギャンブルアカウントの管理に積極的に関与することを推奨しています。そのために、当社はさまざまな責任のあるゲーミング機能を提供しています。":
    "DayLott's encourages our customers to take an active role in the management of their gambling accounts. To that end, we offer a variety of responsible gaming functions.",
  'もしお客様がギャンブルに対し問題が生じたと思われる場合には、カスタマーサービスへ連絡をし、アカウントへ自己排除期間を適用するように要請してください。':
    'If you believe that you have a problem with gambling, please contact Customer Service and request that a self-exclusion period be applied to your account.',
  以下の自己排除期間がご利用可能です:
    'The following self-exclusion periods are available',
  '1週間': '1 week',
  '2週間': '2 weeks',
  '1ヶ月': '1 month',
  '3ヶ月': '3 months',
  '6ヶ月': '6 months',
  "できる限り速やかに自己排除を適用するように努めますが、このプロセスにはある程度の作業時間が必要となりますのでご理解いただきますようお願いいたします。自己排除期間は実装されてお客様へ連絡されるまで、開始したものとはみなされませんのでご注意ください。もしその後も引き続きサービスにアクセス可能な場合には、責任をもってすぐ当社までご連絡ください。自己排除に入った時点でのDayLott'sの未確定ベットは、決済されます。お客様のベットが勝ちだった場合、勝ち金は該当する口座へ送金されます。自己排除期間が終了したあと、お客様のアカウントは自動的に再開されますのでご注意下さい。":
    "We will try to apply the self-exclusion as soon as possible, but please understand that this process may take some time. Please note that the self-exclusion period will not be considered to have started until you are notified. If you continue to have access to the service after that time, you are responsible for contacting us immediately. Any unsettled bets on DayLott's at the time of self-exclusion will be settled. If your bet is a win, your winnings will be transferred to the relevant account. Please note that your account will be automatically reopened after the self-exclusion period has expired.",
  "DayLott'sは開かれる可能性のある新しい重複アカウントを検出し、閉鎖するためにできる限りのことを常に行っております。自己排除が適用されると、いかなるマーケティング資料も受け取ることはなくなります。":
    "DayLott's will always do everything possible to detect and close any new duplicate accounts that may be opened. Once self-exclusion has been applied, you will no longer receive any marketing materials.",
  // フォーム
  メールアドレス: 'Email address',
  ユーザー名: 'User name',
  パスワード: 'Password',
  'パスワード(確認用)': 'Confirmation password',
  新しいパスワード: 'New password',
  '新しいパスワード(確認用)': 'Confirmation new password',
  ファイルを選択: 'Select files',
  'ドラッグ＆ドロップするかファイルを選択してください':
    'Drag and drop or select a file',
  // ボタン
  修正する: 'Fix',
  送信する: 'Send',
  再送する: 'Resend',
  確認する: 'Confirm',
  登録する: 'Sign up',
  入金する: 'Deposit',
  出金する: 'Withdrawal',
  受け取る: 'Receive',
  アップロード: 'Upload',
  削除: 'Delete',
  停止: 'Suspend',
  選択: 'Select',
  送信: 'Submit',
  戻る: 'Go back',
  設定する: 'Set',
  // トッページ
  さらに表示する: 'Read more.',
  // 新規会員登録ページ
  '新規会員登録(タイトル)': 'Create account',
  // 新規会員登録確認ページ
  '内容に誤りがないか、よくご確認ください。':
    'Make sure that the entered information is correct.',
  // 新規会員登録完了ページ
  メールの送信が完了しました: 'Email has been sent.',
  '以下のメールアドレスに確認メールを送りました。メール記載のURLから登録を完了してください。':
    'I sent an email to the following email address. Please complete the registration from the URL provided in the email.',
  TOPに戻る: 'To top page',
  // メール認証
  '認証に成功しました。': 'Authentication was successful.',
  ログインページへ: 'To sign in',
  // ログイン
  'Bunny Paradise Worldのアカウントでもログインできますが、初回はパスワードのリセットが必要です。': 'You can also log in with your Bunny Paradise World account, but password reset is required for the first time.',
  'ログインができない、パスワードを忘れた方はこちら':
    'If you cannot log in or have forgotten your password, click here.',
  "DayLott'sのリンクをホーム画面に追加すると次回以降簡単にアクセスができます。": "Add the DayLott's link to your home screen for easy access next time.",
  iPhoneの場合: 'For iPhone',
  '1. ブラウザ(Safari)でWEBページを開いた状態で、下部の「共有アイコン」をタップ。': '1. open a web page in your browser (Safari) and tap the "share icon" at the bottom.',
  '2. 共有メニューに表示される「ホーム画面に追加」をタップ。': '2. Tap "Add to Home Screen" on the Share menu.',
  '3. 名称などをご確認いただき、画面右上の「追加」をタップ。': '3. Tap "Add" in the upper right corner of the screen after confirming the name, etc.',
  '4. ホーム画面にアイコンが追加されたら完了です。': '4. When the icon is added to the Home screen, you are done.',
  確認して閉じる: 'Confirm and close',
  // パスワードリセット
  '登録済みのメールアドレスにパスワード再設定用のURLを送信します。':
    'The url for resetting the password will be sent to the registered e-mail address.',
  パスワードのリセットを申請する: 'Apply for password reset',
  '再送しました。': 'Successful resend.',
  // パスワードの再設定
  新しいパスワードを設定する: 'Set a new password',
  '新しいパスワードを入力した後、「設定する」を押してください。':
    'After entering the new password, press "Set".',
  // パスワードの再設定(完了)
  パスワードの変更が完了しました: 'Password change is complete.',
  // マイページ
  'ポイント購入履歴はありません。': 'No point purchase history.',
  初回登録ボーナス: 'Initial registration bonus',
  入金ボーナス: 'Deposit bonus',
  紹介ボーナス: 'Referral bonus',
  クレジット購入: 'Purchase by credit',
  ビットコイン購入: 'Purchase by Bitcoin',
  振り込み購入: 'Purchase by transfer cash',
  テザー購入: 'Purchase by Tether',
  イーサリアム購入: 'Purchase by Ethereum',
  'ポイント出金・交換履歴はありません。':
    'No point withdrawal / exchange history.',
  'べット履歴はありません。': 'No bet history.',
  '当選履歴はありません。': 'No winning history.',
  '〇回ゲームをプレイすると出金を行うことができます。': 'Withdrawals can be made after playing the game ○ times.',
  所持ポイント: 'Possession points',
  生年月日: 'Birthday',
  '※ポイントの出金・交換をする際にはKYC認証が必須となります。': '※KYC certification is required when withdrawing or exchanging points.',
  未設定: 'Not set',
  登録情報: 'Registration\ninfomation',
  KYC提出: 'KYC',
  'ポイントデポジット出金・交換': 'Point deposit\nwithdraw・exchange',
  プレイ履歴: 'History',
  編集: 'Edit',
  ログアウト: 'Sign out',
  KYC確認中: 'Checking KYC',
  KYC却下: 'KYC rejected',
  書類のタイプを選択する: 'Select document type',
  パスポート: 'Passport',
  免許証: "Driver's license",
  // アカウントの停止(確認)
  'アカウント情報、所持チケット、所持ポイント等が失われます。確認してから「停止」ボタンを押してください。':
    'Account information, tickets, points, etc., will be deleted. Please confirm and then click the "Suspend" button.',
  '※この操作は取り消しができません。': 'This operation cannot be canceled.',
  // アカウントの停止(完了)
  アカウント停止完了: 'Permanently suspend',
  'アカウントの停止が完了しました。ご利用いただきありがとうございました。':
    'We permanently suspend your account. thank you for using.',
  // メールアドレスの変更
  '入力したメールアドレスにメールアドレス再設定用のURLを送信します。':
    'The url for resetting the email address will be sent to email address you entered.',
  // KYC書類アップロード(フォーム)
  パスポートのアップロード: 'Upload ( Passport )',
  免許証のアップロード: "Upload ( Driver'slicense )",
  年: 'Year',
  月: 'Month',
  日: 'Day',
  パスポートの写真をアップロード: 'Upload your passport photo image.',
  '免許証 ( 表 ) の写真をアップロード':
    "Upload a photo of the front side of your driver's license.",
  '免許証 ( 裏 ) の写真をアップロード':
    "Upload a photo of the back side of your driver's license.",
  '1つ前に戻る': 'Go back',
  '画像をアップロードしてください。': 'Please upload the image.',
  // KYC書類アップロード(確認)
  提出内容の確認: 'Confirmation',
  送信内容の確認: 'Confirmation of send contents',
  '以下の内容で送信します。よろしければ送信を押してください。':
    'If the information you provided is correct, please click the "Send" button.',
  '免許証 ( 表 )': "Driver's license ( Front side )",
  '免許証 ( 裏 )': "Driver's license ( Back side )",
  // KYC書類アップロード(完了)
  本人確認書類送信完了: 'Completed send identification document',
  '審査結果はメールにてお知らせいたします。今しばらくお待ちください。':
    'Examination outcome is notice by email. Please wait for a while.',
  マイページへ戻る: 'Go back mypage',
  // ポイント購入
  ポイント購入: 'Point purchase',
  ポイント: 'point',
  ドル: 'dollar',
  円: 'yen',
  レートを確認する: 'Check the rate',
  ボーナスポイント: 'Bonus points',
  'ボーナスポイントは購入ポイント獲得時に付与されます。': 'Bonus points are given when you earn purchase points.',
  購入方法を選択: 'Select payment method',
  仮想通貨: 'Cryptocurrency',
  クレジット: 'Credit card',
  現金振込: 'Cash transfer',
  'ページを更新して再度お試しください。':
    'Please refresh the page and try again.',
  'データを取得できませんでした。': 'Get data failed.',
  '最低購入ポイント数は10ポイントです。':
    'The minimum number of points to purchase is 10 points.',
  'お名前(必須)': 'Your name (required)',
  '姓(カナ)': 'Last name (Kana)',
  '名(カナ)': 'First name (Kana)',
  '全角カタカナで入力してください。': 'Please enter in the full-width katakana.',
  '口座名義は入力必須です。': 'Account holder must be entered.',
  // ポイント購入（仮想通貨）
  'レート表記はページにアクセスした時点のものです。入金時の数値とは異なる場合がございます。':
    'The rate notation is as of the time the page was accessed. It may be different from the value at the time of deposit.',
  仮想通貨で入金: 'Deposit with cryptocurrency',
  送金アドレス: 'Remittance email address',
  アドレスを再取得: 'Re fetch email address',
  'コピーしました。': 'Copited.',
  'コピー失敗しました。': 'Copy failed.',
  // ポイント購入（クレジット）
  クレジットカードで入金: 'Deposit with credit card',
  有効期限: 'Expiry',
  クレジットカード番号: 'Card number',
  カード名義人: 'Name',
  支払い回数: 'Number of payments',
  一括払い: 'One-time payment',
  '3回払い': 'Three-time payment',
  '6回払い': 'Six-time payment',
  セキュリティコード: 'Security code',
  '以上の内容でよろしければ「入金する」ボタンをクリックしてください。':
    'If you are satisfied with the above contents, please click the "Deposit" button.',
  'お支払い手続きが完了いたします。':
    'The payment procedure will be completed.',
  // ポイント購入（現金）
  現金で入金: 'Deposit with cash',
  '支払額を以下の口座にお振込ください。':
    'Please transfer the payment amount to the following account.',
  依頼人名: 'Client name',
  銀行名: 'Bank name',
  支店名: 'Branch name',
  店番号: 'Store number',
  口座科目: 'Account type',
  口座番号: 'Account number',
  口座名義: 'Account holder',
  // ポイント購入完了（共通）
  入金情報送信完了: 'Payment information transmission completed',
  '確認ができ次第メールにてお知らせいたします。今しばらくお待ちください。':
    'After confirmation, we will notify you by email. Please wait for a while.',
  // ポイント出金・交換(トップ)
  '出金・交換ポイント数': 'Withdrawal/Exchange points',
  '出金・交換をするポイント数を入力してください。': 'Please enter the number of points to withdraw / exchange.',
  ポイント残高: 'Point balance',
  '出金・交換方法を選択': 'Select withdrawal / exchange method',
  '半角数字のみ入力してください。': 'Please enter only half-width numbers.',
  '50ポイントから出金が可能です。': 'Withdrawals are possible from 50 points.',
  '入力ポイントが所持ポイントを超えています。': 'The input point exceeds the possession point.',
  '送金先アドレスを入力してください。': 'Please enter your remittance address.',
  // ポイント出金・交換(仮想通貨)
  送金手続き: 'Remittance procedures',
  送金内容の確認: 'Confirmation of remittance details',
  送金額: 'Amount of remittance',
  送金先アドレス: 'Remittance address',
  '上記内容に間違いがないことを確認して送金をしてください。送金確定後の返金や操作の取り消しはできません。': 'Make sure that the above information is correct before withdrawal/exchange. Refunds cannot be made nor can operations ben canceled once the remittance is confirmed.',
  // ポイント出金・交換(受付完了)
  '出金・交換を受け付けました。': 'The withdrawal/exchange has been accepted.',
  '取引に問題があった場合はメールにてご連絡を致します。またご不明な点がありましたらサポートまでお問い合わせください。': 'We will contact you by email should any problems occur with your transaction. If you have any questions, please contact support.',
  // 履歴
  べット履歴: 'Bet history',
  当選履歴: 'Winning history',
  ポイント購入履歴: 'Point purchase history',
  'ポイント出金・交換履歴': 'Point withdraw / exchange history',
  もっと見る: 'Read more',
  // DayLott'sとは
  "DayLott'sでは、毎日ミリオネアを排出する、世界初のゲームです。少額から大口まで、多彩なテーブルを用意することで、より多くの方にゲームを楽しんでもらうことができます。":
    "DayLott's is the first game in the world that produces millionaires every day. We offer a variety of tables, from small to large, so that more people can enjoy the game.",
  '業界最高水準の75%！！世界で１番ユーザーが獲得できるゲームになるようにこだわって作りました。そしてその割合は常に一定です。ゲームにエントリーをした全てのユーザーに平等に獲得するチャンスがあります。':
    'Industry-leading 75%!! We have made it our obsession to make it the game that will get the most users in the world. And the percentage is always constant. Every user who enters the game has an equal chance to win.',
  'ビットコイン、テザー、イーサリアムでゲームを選ぶポイントを購入することができます。':
    'You can buy points to play games with Bitcoin, Tether, or Ethereum.',
  '会員登録からエントリーまでたった3分で完了します。スマートフォンがあればいつでもどこでも気軽にゲームにエントリーをすることができます。':
    'It only takes 3 minutes from registration to entry. If you have a smartphone, you can easily enter the game anytime, anywhere.',
  // 仕組み
  '世界初、必ず購入した誰かが高額当選、ミリオネアを誕生させる。':
    ' World’s first, someone who always buys the game will win a large sum of money and make a Millionaire on his birthday.',
  '世界最高水準還元率が驚異の75%を誇るオンラインゲームです。':
    "The world's highest return rate is an astounding 75% for online games.",
  'エントリーするベットポイント、当選ポイントに応じたボックスが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステム。エントリーしたボックスのチケット購入数が満たされると即座に抽選が始まり、当選が確定します。世界一簡単な、世界一高額当選のできる世界一スピード感のあるオンラインゲームです。':
    'Boxes are prepared based on bet points to be entered and the winning points. The rules are simple: you just buy a ticket and enter. As soon as the number of tickets purchased in the box that you entered are fully sold, the lottery will begin and the winner confirmed. This is therefore the easiest and fastest online game in the world where you can win the highest prizes in the world. ',
  '対応端末 iOS 14.8以降 Android 12.0以降':
    'Supported devices iOS 14.8 or later and Android 12.0 or later',
  'チケットを購入し、抽選ページにアクセス':
    'Purchase a ticket and access the lottery page.',
  '抽選演出開始され、当選演出時にご自身で登録したユーザー名が枠内に止まりましたら当選確定':
    'Lottery processing will begin. If your registered user name from the time of processing remains within the frame, your win will be confirmed.',
  '当選確定後、当選ポイントが反映され出金が可能':
    'After a win is confirmed, points won shall be displayed and may be withdrawn if desired.',
  ご購入された誰かが必ず高額当選:
    'Someone who buys it will definitely win a big prize.',
  "ボックス毎に設定された高額当選ポイントが、ご購入された誰かに当選します。Day Lott'sではボックスに設定されている当選ポイントは必ずユーザーに反映されます。":
    'High priced winning points in each box will be won by someone who purchases the box. In Day Lott\'s, the winning points set in the box are always attributed to the winning user.',
  '安心・安全・透明性のある当選': 'Safe, secure, and transparent election',
  "Day Lott'sでは、透明性のある運営を心がけています。全てのユーザーが当選したユーザーを確認することができます。また、個人情報の保護、入出金におけるセキュリティの強化をしているため、安全にプレイをして頂くことができます。":
    "At Day Lott's, we always strive for transparency. All users can check winning users. Additionally, we have strengthened personal information protections and the security of deposits and withdrawals. You can play safely.",
  バリエーションのある当選ポイント設定: 'Setting winning points with variations',
  'ユーザーのニーズを満たしたプレイボックスを多く用意しています。小さなべットエントリーで高額当選ポイントを獲得することも可能です。最低5ポイントからエントリーが可能となっており、最大100ミリオンポイントの当選も実現します。':
    'We have many different playboxes that meet user needs. It is also possible to earn high winning points with small bet entries. You can enter a minimum of 5 points and win up to 100 million points. ',
  // よくある質問
  入金: 'Deposit',
  出金: 'Withdraw',
  'よくある質問はありません。': 'No faq.',
  // ランキング
  一日: 'Daily',
  週間: 'Weekly',
  月間: 'Monthly',
  全期間: 'All',
  'ランキングはありません。': 'No ranking.',
  // サポート
  '当ページのよくある質問をまずはご覧ください。':
    'Please see the FAQ on this page.',
  メールでのお問い合わせ: 'Email support',
  'までお問い合わせください。': 'Please contact.',
  お問合せ時間: 'Support time',
  '毎日対応させて頂いております。': 'We answer user inquiries every day.',
  '※お問い合わせの回答に数日頂く事がございます。3営業日以内には回答させて頂きます。':
    '*It may take several days to answer your specific question. We will respond within 3 working days.',
  // PLAYBOX
  テーブル選択: 'Table selection',
  予約状況: 'Reservation status',
  口: 'Units',
  キング: 'King',
  ゼウス: 'Zeus',
  ダイヤモンド: 'Diamond',
  ゴールド: 'Gold',
  シルバー: 'Silver',
  ブロンズ: 'Bronze',
  アンバー: 'Amber',
  口数選択: 'Select number of units',
  購入する: 'Purchase',
  キャンセル: 'Cancel',
  ダイヤモンド獲得確率: 'Winning rate of diamond',
  ゴールド獲得確率: 'Winning rate of gold',
  シルバー獲得確率: 'Winning rate of silver',
  ブロンズ獲得確率: 'Winning rate of bronze',
  獲得確率: 'Winning rate',
  口購入: 'units buy',
  '所持ポイントが不足しています。': 'Not enough points you have.',
  '購入するにはログインが必要です。': 'You need to sign in to make a purchase.',
  '1度に購入できるのは2000口までです。': 'You can purchase up to 4,000 units at a time.',
  // チケット購入確認
  番: 'No.',
  '下記の内容で間違いないかご確認ください。':
    'Please check the following to make sure there are no mistakes.',
  '「購入する」ボタンをクリックすると購入が確定します。':
    'Click the "Purchase" button to complete the purchase.',
  参加ボックス: 'Join box',
  購入ポイント数: 'Purchase points',
  消費ポイント数: 'Consumption points',
  所持有償ポイント数: 'Possession paid points',
  所持無償ポイント数: 'Possession free points',
  残り有償ポイント数: 'Remaining paid points',
  残り無償ポイント数: 'Remaining free points',
  // チケット購入完了
  チケット番号: 'Ticket number',
  '参加中のPLAY BOX': 'Participating PLAY BOX',
  抽選画面へ: 'To the lottery',
  // お知らせ一覧
  '購入済みのチケットの抽選が開始されました。矢印をタップすると結果を見ることができます。':
    'The lottery has started. You can see the result by tapping arrow.',
  'お客様のランクがアップされました。': 'Your rank has improved.',
  キャンペーン: 'Campaign.',
  表示を閉じる: 'Close view',
  // お知らせ
  'お知らせはありません。': 'No news.',
  抽選の結果を見る: 'Result of the lottery.',
  抽選会場へ行く: 'Go to the lottery page',
  マイページで確認: 'Confirm on My Page',
  // お問い合わせ
  お問い合わせフォーム: 'Contact Us',
  'お客様からのご質問をお問い合わせフォームにて受け付けております。':
    'We accept contact from customers using the contact form.',
  '下記項目をご記入の上、「確認する」を押してください。':
    'Please fill in the following items and press "Confirm".',
  氏名: 'Full name',
  お問い合わせ内容: 'Content of inquiry',
  // お問い合わせ確認
  お問い合わせ内容の確認: 'Confirmation of contents',
  // お問い合わせ受付完了
  'お問い合わせを受け付けました。': 'We have received your inquiry.',
  'お問い合わせありがとうございます。': 'Thank you for contacting me.',
  'お問い合わせには3営業日以内に回答させて頂きます。':
    'We will respond to your inquiry within 3 business days.',
  // 抽選結果
  'このページは動画が自動再生されます。よろしければ"OK"を押してください。動画を再生せずポイントを受け取る場合は"受け取る"を押してください。':
    'The video will play automatically on this page. Please press "OK" if you like. To receive points without playing the video, click "Receive".',
  Waiting: 'Waiting',
  購入日時: 'Purchase date',
  当選ポイント: 'Earned point',
  // ポイント受け取り
  獲得ポイント受け取り: 'Receive points earned',
  動画を再生せずに獲得した全てのポイントを受け取る: 'Receive all points earned without playing the video.',
  'ポイントを受け取りました。': 'Points received.',
  // 共通エラー
  通信エラー: 'Connection error.',
  サーバーエラー: 'Server error',
  // バリエーションエラー
  'は入力必須です。': ' is required.',
  'メールアドレスは正しい形式で入力してください。':
    'Please enter your Email address in the correct format.',
  'ユーザー名は半角英数字のみで入力してください。':
    'Please enter the User name using only single-byte alphanumerical characters.',
  'パスワードは大文字小文字を含む半角英数字を組み合わせて入力してください。':
    'Please enter the password in a combination of single-byte alphanumerical characters including uppercase and lowercase letters.',
  'パスワードが一致しません。': 'Passwords do not match.',
  '画像は.jpgか.pngをアップロードしてください。':
    'Upload .jpg or .png for the image.',
  '金額を入れてください。': 'Please enter the amount.',
  '日本円での合計金額(手数料込)': 'Total amount (including fees) in Japanese yen',
  同意: 'Agree',
};
