/**
 *
 * APIレスポンスのステータスコード一覧
 *
 */
export const STATUS_CODE = {
  CONNECTION_REFUSED: 0, // 通信エラー
  NORMAL: 200, // 正常
  LOGIN_CHECK_ERROR: 400, // 未ログイン
  VALIDATION_ERROR: 402, // バリデーションエラー
  NOT_FOUND: 404, // Not found
  SERVER_ERROR: 500, // サーバーエラー
};
