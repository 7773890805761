/**
 * 1:初回登録ボーナス
 * 2:入金ボーナス
 * 3:紹介ボーナス
 * 4:クレカ購入
 * 5:ビットコイン購入
 * 6:現金振り込み
 * 7:テザー
 * 8:イーサリアム
 */
export const GRANT = {
  REGISTRATION_BONUS: 1,
  PAYMENT_BONUS: 2,
  INTRODUCTION_BONUS: 3,
  CREDIT_PAYMENT: 4,
  BTC_PAYMENT: 5,
  PAYMENT_TRANSFER: 6,
  USDT_PAYMENT: 7,
  ETH_PAYMENT: 8,
} as const;
