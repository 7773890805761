import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
import { v4 as uuidv4 } from 'uuid';
// Components
import SupportLayout from 'components/templates/SupportLayout';
// Utils
import { LANG } from 'utils/const/lang';
// Text SP images
import head01JaSp from 'assets/images/whats/headJa/head01_ja_sp.png';
import head02JaSp from 'assets/images/whats/headJa/head02_ja_sp.png';
import head03JaSp from 'assets/images/whats/headJa/head03_ja_sp.png';
import head04JaSp from 'assets/images/whats/headJa/head04_ja_sp.png';
import head01EnSp from 'assets/images/whats/headEn/head01_en_sp.png';
import head02EnSp from 'assets/images/whats/headEn/head02_en_sp.png';
import head03EnSp from 'assets/images/whats/headEn/head03_en_sp.png';
import head04EnSp from 'assets/images/whats/headEn/head04_en_sp.png';
import head01ZhCnSp from 'assets/images/whats/headZhCn/head01_zh_cn_sp.png';
import head02ZhCnSp from 'assets/images/whats/headZhCn/head02_zh_cn_sp.png';
import head03ZhCnSp from 'assets/images/whats/headZhCn/head03_zh_cn_sp.png';
import head04ZhCnSp from 'assets/images/whats/headZhCn/head04_zh_cn_sp.png';
import head01ZhTwSp from 'assets/images/whats/headZhTw/head01_zh_tw_sp.png';
import head02ZhTwSp from 'assets/images/whats/headZhTw/head02_zh_tw_sp.png';
import head03ZhTwSp from 'assets/images/whats/headZhTw/head03_zh_tw_sp.png';
import head04ZhTwSp from 'assets/images/whats/headZhTw/head04_zh_tw_sp.png';
import head01KoSp from 'assets/images/whats/headKo/head01_ko_sp.png';
import head02KoSp from 'assets/images/whats/headKo/head02_ko_sp.png';
import head03KoSp from 'assets/images/whats/headKo/head03_ko_sp.png';
import head04KoSp from 'assets/images/whats/headKo/head04_ko_sp.png';
import head01ViSp from 'assets/images/whats/headVi/head01_vi_sp.png';
import head02ViSp from 'assets/images/whats/headVi/head02_vi_sp.png';
import head03ViSp from 'assets/images/whats/headVi/head03_vi_sp.png';
import head04ViSp from 'assets/images/whats/headVi/head04_vi_sp.png';
import head01ThSp from 'assets/images/whats/headTh/head01_th_sp.png';
import head02ThSp from 'assets/images/whats/headTh/head02_th_sp.png';
import head03ThSp from 'assets/images/whats/headTh/head03_th_sp.png';
import head04ThSp from 'assets/images/whats/headTh/head04_th_sp.png';
// Text PC images
import head01JaPc from 'assets/images/whats/headJa/head01_ja_pc.png';
import head02JaPc from 'assets/images/whats/headJa/head02_ja_pc.png';
import head03JaPc from 'assets/images/whats/headJa/head03_ja_pc.png';
import head04JaPc from 'assets/images/whats/headJa/head04_ja_pc.png';
import head01EnPc from 'assets/images/whats/headEn/head01_en_pc.png';
import head02EnPc from 'assets/images/whats/headEn/head02_en_pc.png';
import head03EnPc from 'assets/images/whats/headEn/head03_en_pc.png';
import head04EnPc from 'assets/images/whats/headEn/head04_en_pc.png';
import head01ZhCnPc from 'assets/images/whats/headZhCn/head01_zh_cn_pc.png';
import head02ZhCnPc from 'assets/images/whats/headZhCn/head02_zh_cn_pc.png';
import head03ZhCnPc from 'assets/images/whats/headZhCn/head03_zh_cn_pc.png';
import head04ZhCnPc from 'assets/images/whats/headZhCn/head04_zh_cn_pc.png';
import head01ZhTwPc from 'assets/images/whats/headZhTw/head01_zh_tw_pc.png';
import head02ZhTwPc from 'assets/images/whats/headZhTw/head02_zh_tw_pc.png';
import head03ZhTwPc from 'assets/images/whats/headZhTw/head03_zh_tw_pc.png';
import head04ZhTwPc from 'assets/images/whats/headZhTw/head04_zh_tw_pc.png';
import head01KoPc from 'assets/images/whats/headKo/head01_ko_pc.png';
import head02KoPc from 'assets/images/whats/headKo/head02_ko_pc.png';
import head03KoPc from 'assets/images/whats/headKo/head03_ko_pc.png';
import head04KoPc from 'assets/images/whats/headKo/head04_ko_pc.png';
import head01ViPc from 'assets/images/whats/headVi/head01_vi_pc.png';
import head02ViPc from 'assets/images/whats/headVi/head02_vi_pc.png';
import head03ViPc from 'assets/images/whats/headVi/head03_vi_pc.png';
import head04ViPc from 'assets/images/whats/headVi/head04_vi_pc.png';
import head01ThPc from 'assets/images/whats/headTh/head01_th_pc.png';
import head02ThPc from 'assets/images/whats/headTh/head02_th_pc.png';
import head03ThPc from 'assets/images/whats/headTh/head03_th_pc.png';
import head04ThPc from 'assets/images/whats/headTh/head04_th_pc.png';
// Text PC@2x images
import head01JaPc2x from 'assets/images/whats/headJa/head01_ja_pc@2x.png';
import head02JaPc2x from 'assets/images/whats/headJa/head02_ja_pc@2x.png';
import head03JaPc2x from 'assets/images/whats/headJa/head03_ja_pc@2x.png';
import head04JaPc2x from 'assets/images/whats/headJa/head04_ja_pc@2x.png';
import head01EnPc2x from 'assets/images/whats/headEn/head01_en_pc@2x.png';
import head02EnPc2x from 'assets/images/whats/headEn/head02_en_pc@2x.png';
import head03EnPc2x from 'assets/images/whats/headEn/head03_en_pc@2x.png';
import head04EnPc2x from 'assets/images/whats/headEn/head04_en_pc@2x.png';
import head01ZhCnPc2x from 'assets/images/whats/headZhCn/head01_zh_cn_pc@2x.png';
import head02ZhCnPc2x from 'assets/images/whats/headZhCn/head02_zh_cn_pc@2x.png';
import head03ZhCnPc2x from 'assets/images/whats/headZhCn/head03_zh_cn_pc@2x.png';
import head04ZhCnPc2x from 'assets/images/whats/headZhCn/head04_zh_cn_pc@2x.png';
import head01ZhTwPc2x from 'assets/images/whats/headZhTw/head01_zh_tw_pc@2x.png';
import head02ZhTwPc2x from 'assets/images/whats/headZhTw/head02_zh_tw_pc@2x.png';
import head03ZhTwPc2x from 'assets/images/whats/headZhTw/head03_zh_tw_pc@2x.png';
import head04ZhTwPc2x from 'assets/images/whats/headZhTw/head04_zh_tw_pc@2x.png';
import head01KoPc2x from 'assets/images/whats/headKo/head01_ko_pc@2x.png';
import head02KoPc2x from 'assets/images/whats/headKo/head02_ko_pc@2x.png';
import head03KoPc2x from 'assets/images/whats/headKo/head03_ko_pc@2x.png';
import head04KoPc2x from 'assets/images/whats/headKo/head04_ko_pc@2x.png';
import head01ViPc2x from 'assets/images/whats/headVi/head01_vi_pc@2x.png';
import head02ViPc2x from 'assets/images/whats/headVi/head02_vi_pc@2x.png';
import head03ViPc2x from 'assets/images/whats/headVi/head03_vi_pc@2x.png';
import head04ViPc2x from 'assets/images/whats/headVi/head04_vi_pc@2x.png';
import head01ThPc2x from 'assets/images/whats/headTh/head01_th_pc@2x.png';
import head02ThPc2x from 'assets/images/whats/headTh/head02_th_pc@2x.png';
import head03ThPc2x from 'assets/images/whats/headTh/head03_th_pc@2x.png';
import head04ThPc2x from 'assets/images/whats/headTh/head04_th_pc@2x.png';
// Photo images
import photo01Sp from 'assets/images/whats/photo/photo01_sp.jpg';
import photo02Sp from 'assets/images/whats/photo/photo02_sp.jpg';
import photo03Sp from 'assets/images/whats/photo/photo03_sp.jpg';
import photo04Sp from 'assets/images/whats/photo/photo04_sp.jpg';
import photo01Pc from 'assets/images/whats/photo/photo01_pc.jpg';
import photo02Pc from 'assets/images/whats/photo/photo02_pc.jpg';
import photo03Pc from 'assets/images/whats/photo/photo03_pc.jpg';
import photo04Pc from 'assets/images/whats/photo/photo04_pc.jpg';
import photo01Pc2x from 'assets/images/whats/photo/photo01_pc@2x.jpg';
import photo02Pc2x from 'assets/images/whats/photo/photo02_pc@2x.jpg';
import photo03Pc2x from 'assets/images/whats/photo/photo03_pc@2x.jpg';
import photo04Pc2x from 'assets/images/whats/photo/photo04_pc@2x.jpg';

const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

/**
 *
 * Component
 *
 */
const Whats: FC = memo(() => {
  // current page path
  const path = useLocation().pathname.slice(1);

  // i18next
  const { t } = useTranslation();

  // Recoil 言語設定
  const lang = useRecoilValue(langState);

  // コンテンツ
  const contents = [
    {
      styleType: 'right',
      photoSp: photo01Sp,
      photoPc: photo01Pc,
      photoPc2x: photo01Pc2x,
      heads:
        lang === JAPANESE
          ? { headSp: head01JaSp, headPc: head01JaPc, headPc2x: head01JaPc2x }
          : lang === SIMPLE_CHINESE
            ? {
              headSp: head01ZhCnSp,
              headPc: head01ZhCnPc,
              headPc2x: head01ZhCnPc2x,
            }
            : lang === TRADITIONAL_CHINESE
              ? {
                headSp: head01ZhTwSp,
                headPc: head01ZhTwPc,
                headPc2x: head01ZhTwPc2x,
              }
              : lang === KOREAN
                ? { headSp: head01KoSp, headPc: head01KoPc, headPc2x: head01KoPc2x }
                : lang === VIETNAM
                  ? { headSp: head01ViSp, headPc: head01ViPc, headPc2x: head01ViPc2x }
                  : lang === THAILAND
                    ? { headSp: head01ThSp, headPc: head01ThPc, headPc2x: head01ThPc2x }
                    : { headSp: head01EnSp, headPc: head01EnPc, headPc2x: head01EnPc2x },
      textSp:
        "DayLott'sでは、毎日ミリオネアを排出する、世界初のゲームです。少額から大口まで、多彩なテーブルを用意することで、より多くの方にゲームを楽しんでもらうことができます。",
    },
    {
      styleType: 'left',
      photoSp: photo02Sp,
      photoPc: photo02Pc,
      photoPc2x: photo02Pc2x,
      heads:
        lang === JAPANESE
          ? { headSp: head02JaSp, headPc: head02JaPc, headPc2x: head02JaPc2x }
          : lang === SIMPLE_CHINESE
            ? {
              headSp: head02ZhCnSp,
              headPc: head02ZhCnPc,
              headPc2x: head02ZhCnPc2x,
            }
            : lang === TRADITIONAL_CHINESE
              ? {
                headSp: head02ZhTwSp,
                headPc: head02ZhTwPc,
                headPc2x: head02ZhTwPc2x,
              }
              : lang === KOREAN
                ? { headSp: head02KoSp, headPc: head02KoPc, headPc2x: head02KoPc2x }
                : lang === VIETNAM
                  ? { headSp: head02ViSp, headPc: head02ViPc, headPc2x: head02ViPc2x }
                  : lang === THAILAND
                    ? { headSp: head02ThSp, headPc: head02ThPc, headPc2x: head02ThPc2x }
                    : { headSp: head02EnSp, headPc: head02EnPc, headPc2x: head02EnPc2x },
      textSp:
        '業界最高水準の75%！！世界で１番ユーザーが獲得できるゲームになるようにこだわって作りました。そしてその割合は常に一定です。ゲームにエントリーをした全てのユーザーに平等に獲得するチャンスがあります。',
    },
    {
      styleType: 'right',
      photoSp: photo03Sp,
      photoPc: photo03Pc,
      photoPc2x: photo03Pc2x,
      heads:
        lang === JAPANESE
          ? { headSp: head03JaSp, headPc: head03JaPc, headPc2x: head03JaPc2x }
          : lang === SIMPLE_CHINESE
            ? {
              headSp: head03ZhCnSp,
              headPc: head03ZhCnPc,
              headPc2x: head03ZhCnPc2x,
            }
            : lang === TRADITIONAL_CHINESE
              ? {
                headSp: head03ZhTwSp,
                headPc: head03ZhTwPc,
                headPc2x: head03ZhTwPc2x,
              }
              : lang === KOREAN
                ? { headSp: head03KoSp, headPc: head03KoPc, headPc2x: head03KoPc2x }
                : lang === VIETNAM
                  ? { headSp: head03ViSp, headPc: head03ViPc, headPc2x: head03ViPc2x }
                  : lang === THAILAND
                    ? { headSp: head03ThSp, headPc: head03ThPc, headPc2x: head03ThPc2x }
                    : { headSp: head03EnSp, headPc: head03EnPc, headPc2x: head03EnPc2x },
      textSp:
        'ビットコイン、テザー、イーサリアムでゲームを選ぶポイントを購入することができます。',
    },
    {
      styleType: 'left',
      photoSp: photo04Sp,
      photoPc: photo04Pc,
      photoPc2x: photo04Pc2x,
      heads:
        lang === JAPANESE
          ? { headSp: head04JaSp, headPc: head04JaPc, headPc2x: head04JaPc2x }
          : lang === SIMPLE_CHINESE
            ? {
              headSp: head04ZhCnSp,
              headPc: head04ZhCnPc,
              headPc2x: head04ZhCnPc2x,
            }
            : lang === TRADITIONAL_CHINESE
              ? {
                headSp: head04ZhTwSp,
                headPc: head04ZhTwPc,
                headPc2x: head04ZhTwPc2x,
              }
              : lang === KOREAN
                ? { headSp: head04KoSp, headPc: head04KoPc, headPc2x: head04KoPc2x }
                : lang === VIETNAM
                  ? { headSp: head04ViSp, headPc: head04ViPc, headPc2x: head04ViPc2x }
                  : lang === THAILAND
                    ? { headSp: head04ThSp, headPc: head04ThPc, headPc2x: head04ThPc2x }
                    : { headSp: head04EnSp, headPc: head04EnPc, headPc2x: head04EnPc2x },
      textSp:
        '会員登録からエントリーまでたった3分で完了します。スマートフォンがあればいつでもどこでも気軽にゲームにエントリーをすることができます。',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('title.whats')}</title>
        <meta name="description" content={t('description.whats')} />
      </Helmet>
      <SupportLayout path={path}>
        {/* 概要 */}
        <ContainerS>
          <UListS>
            {contents.map((content) => (
              <ListS key={uuidv4()} className={content.styleType}>
                <HeadImageWrapS className={content.styleType}>
                  <HeadPictureS className={content.styleType}>
                    <source
                      srcSet={`${content.heads?.headPc} 1x, ${content.heads?.headPc2x} 2x`}
                      media="(min-width: 600px)"
                    />
                    <HeadImageS
                      src={content.heads?.headSp}
                      alt=""
                      loading="lazy"
                    />
                  </HeadPictureS>
                </HeadImageWrapS>
                <ContentS className={content.styleType}>
                  <PhotoPictureS className={content.styleType}>
                    <source
                      srcSet={`${content.photoPc} 1x, ${content.photoPc2x} 2x`}
                      media="(min-width: 600px)"
                    />
                    <PhotoImageS src={content.photoSp} alt="" loading="lazy" />
                  </PhotoPictureS>
                  <TextWrapSpS className={content.styleType}>
                    <TextSpS>{t(content.textSp)}</TextSpS>
                  </TextWrapSpS>
                </ContentS>
              </ListS>
            ))}
          </UListS>
        </ContainerS>
      </SupportLayout>
    </>
  );
});
export default Whats;

/**
 *
 * Styles
 *
 */
const ContainerS = styled.div`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;

  @media (min-width: 600px) {
    width: 90%;
    padding: 6rem 0;
  }

  @media (min-width: 1025px) {
    width: 100%;
    max-width: 100rem;
    padding: 8rem 0;
  }
`;

const UListS = styled.ul`
  width: 100%;
`;

const ListS = styled.li`
  width: 100%;
  margin-bottom: 3rem;
  // flex
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    align-items: center;
    margin-bottom: 5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.right {
    @media (min-width: 600px) {
      flex-direction: row;
    }
  }

  &.left {
    @media (min-width: 600px) {
      flex-direction: row-reverse;
    }
  }
`;

const HeadImageWrapS = styled.div`
  width: 100%;
  z-index: 2;

  @media (min-width: 1025px) {
    width: 90%;
  }

  &.right {
    @media (min-width: 600px) {
      margin-right: -10%;
    }
  }

  &.left {
    @media (min-width: 600px) {
      margin-left: -10%;
    }
  }
`;

const HeadPictureS = styled.picture`
  width: 100%;
  // flex
  display: flex;

  &.right {
    justify-content: flex-start;

    @media (min-width: 600px) {
      justify-content: initial;
    }
  }

  &.left {
    justify-content: flex-end;

    @media (min-width: 600px) {
      justify-content: initial;
    }
  }
`;

const HeadImageS = styled.img`
  width: min(85%, 360px);

  @media (min-width: 600px) {
    width: 100%;
  }
`;

const ContentS = styled.div`
  width: 100%;
  margin-top: -10%;
  z-index: 1;
  // flex
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    margin-top: 0;
  }

  &.right {
    align-items: flex-end;

    @media (min-width: 600px) {
      margin-left: -10%;
    }
  }

  &.left {
    align-items: flex-start;

    @media (min-width: 600px) {
      margin-right: -10%;
    }
  }
`;

const PhotoPictureS = styled.picture`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;

  &.right {
    align-items: flex-end;
  }

  &.left {
    align-items: flex-start;
  }
`;

const PhotoImageS = styled.img`
  width: 85%;

  @media (min-width: 600px) {
    width: 100%;
  }
`;

const TextWrapSpS = styled.div`
  width: 85%;
  padding-top: 1rem;

  @media (min-width: 600px) {
    display: none;
  }

  &.right {
    padding-right: 1rem;
  }

  &.left {
    padding-left: 1rem;
  }
`;

const TextSpS = styled.p`
  color: #ffffff;
  font-size: 1.4rem;
  line-height: 1.9;
`;
