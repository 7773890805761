import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Components
import NeonShadowBorder from 'components/atoms/border/NeonShadowBorder';

/**
 *
 * Types
 *
 */
type Props = {
  title: string;
  description?: string;
};

/**
 *
 * Components
 *
 */
const NeonBorderTitle: FC<Props> = memo(({ title, description }) => (
  <TitleS>
    <TitleTextS>{title}</TitleTextS>
    <BorderWrapS>
      <NeonShadowBorder />
    </BorderWrapS>
    {description && <DescriptionS>{description}</DescriptionS>}
  </TitleS>
));
export default NeonBorderTitle;

/**
 *
 * Styles
 *
 */
const TitleS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
`;

const TitleTextS = styled.p`
  font-size: 1.7rem;
  color: #ffffff;
  padding-left: 0.5rem;

  @media (min-width: 600px) {
    font-size: 2rem;
  }
`;

const BorderWrapS = styled.div`
  width: 100%;
`;

const DescriptionS = styled.div`
  color: #ffffff;
  font-size: 1.4rem;
  line-height: 1.5;
  word-break: break-word;
  padding-left: 0.5rem;

  @media (min-width: 600px) {
    font-size: 1.6rem;
    width: 90%;
  }
`;
