import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Components
import LoaderPart from 'components/atoms/loader/LoaderPart';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
import { rankingState } from 'store/atoms/lottery/rankingState';
// Utils
import { LANG } from 'utils/const/lang';
import { devices } from 'utils/const/devices';
// Images
import barNeonBottomPc from 'assets/images/neonItem/bar-bottom_pc.png';
import titleEnSp from 'assets/images/lottery/ranking/title_en_sp.png';
import titleEnPc from 'assets/images/lottery/ranking/title_en_pc.png';
import titleEnPc2x from 'assets/images/lottery/ranking/title_en_pc@2x.png';
import titleZhCnSp from 'assets/images/lottery/ranking/title_zh_cn_sp.png';
import titleZhCnPc from 'assets/images/lottery/ranking/title_zh_cn_pc.png';
import titleZhCnPc2x from 'assets/images/lottery/ranking/title_zh_cn_pc@2x.png';
import titleZhTwSp from 'assets/images/lottery/ranking/title_zh_tw_sp.png';
import titleZhTwPc from 'assets/images/lottery/ranking/title_zh_tw_pc.png';
import titleZhTwPc2x from 'assets/images/lottery/ranking/title_zh_tw_pc@2x.png';
import titleKoSp from 'assets/images/lottery/ranking/title_ko_sp.png';
import titleKoPc from 'assets/images/lottery/ranking/title_ko_pc.png';
import titleKoPc2x from 'assets/images/lottery/ranking/title_ko_pc@2x.png';
import titleViSp from 'assets/images/lottery/ranking/title_vi_sp.png';
import titleViPc from 'assets/images/lottery/ranking/title_vi_pc.png';
import titleViPc2x from 'assets/images/lottery/ranking/title_vi_pc@2x.png';
import titleThSp from 'assets/images/lottery/ranking/title_th_sp.png';
import titleThPc from 'assets/images/lottery/ranking/title_th_pc.png';
import titleThPc2x from 'assets/images/lottery/ranking/title_th_pc@2x.png';
import iconRank1Sp from 'assets/images/ranking/1st_sp.png';
import iconRank1Pc from 'assets/images/ranking/1st_pc.png';
import iconRank1Pc2x from 'assets/images/ranking/1st_pc@2x.png';
import iconRank2Sp from 'assets/images/ranking/2nd_sp.png';
import iconRank2Pc from 'assets/images/ranking/2nd_pc.png';
import iconRank2Pc2x from 'assets/images/ranking/2nd_pc@2x.png';
import iconRank3Sp from 'assets/images/ranking/3rd_sp.png';
import iconRank3Pc from 'assets/images/ranking/3rd_pc.png';
import iconRank3Pc2x from 'assets/images/ranking/3rd_pc@2x.png';

const {
  mobileLandscape,
  tabletPortrait,
  tabletLandscape,
  pc,
} = devices;

const {
  SIMPLE_CHINESE,
  TRADITIONAL_CHINESE,
  KOREAN,
  VIETNAM,
  THAILAND,
} = LANG;

/**
 *
 * Types
 *
 */
type Props = {
  isLoading: boolean;
};

/**
 *
 * Component
 *
 */
const RankingBox: FC<Props> = memo((props) => {
  const { isLoading } = props;

  // Recoil 言語設定
  const lang = useRecoilValue(langState);
  // Recoil ランキング情報
  const ranking = useRecoilValue(rankingState);

  return (
    <RankingS>
      <TitleS>
        <source
          srcSet={
            (lang === SIMPLE_CHINESE)
              ? `${titleZhCnPc} 1x, ${titleZhCnPc2x} 2x`
              : (lang === TRADITIONAL_CHINESE)
                ? `${titleZhTwPc} 1x, ${titleZhTwPc2x} 2x`
                : (lang === KOREAN)
                  ? `${titleKoPc} 1x, ${titleKoPc2x} 2x`
                  : (lang === VIETNAM)
                    ? `${titleViPc} 1x, ${titleViPc2x} 2x`
                    : (lang === THAILAND)
                      ? `${titleThPc} 1x, ${titleThPc2x} 2x`
                      : `${titleEnPc} 1x, ${titleEnPc2x} 2x`
          }
          media="(min-width: 600px)"
        />
        <ImageS
          src={
            (lang === SIMPLE_CHINESE)
              ? titleZhCnSp
              : (lang === TRADITIONAL_CHINESE)
                ? titleZhTwSp
                : (lang === KOREAN)
                  ? titleKoSp
                  : (lang === VIETNAM)
                    ? titleViSp
                    : (lang === THAILAND)
                      ? titleThSp
                      : titleEnSp
          }
          alt=""
        />
      </TitleS>

      {isLoading && <LoaderPart zIndex={1} />}

      {(ranking.length > 0) && (
        <RankingUListS>
          {ranking.filter((r) => r.isShow).map(({ rank, name, isShow }) => (
            <RankingListS key={uuidv4()}>
              <RankS>
                {/* 1位表示 */}
                {rank === 1 && (
                  <RankPictureS>
                    <source
                      srcSet={`${iconRank1Pc} 1x, ${iconRank1Pc2x} 2x`}
                      media="(min-width: 600px)"
                    />
                    <ImageS src={iconRank1Sp} alt="" />
                  </RankPictureS>
                )}
                {/* 2位表示 */}
                {rank === 2 && (
                  <RankPictureS>
                    <source
                      srcSet={`${iconRank2Pc} 1x, ${iconRank2Pc2x} 2x`}
                      media="(min-width: 600px)"
                    />
                    <ImageS src={iconRank2Sp} alt="" />
                  </RankPictureS>
                )}
                {/* 3位表示 */}
                {rank === 3 && (
                  <RankPictureS>
                    <source
                      srcSet={`${iconRank3Pc} 1x, ${iconRank3Pc2x} 2x`}
                      media="(min-width: 600px)"
                    />
                    <ImageS src={iconRank3Sp} alt="" />
                  </RankPictureS>
                )}
                {/* 4位以下表示 */}
                {rank >= 4 && <RankNumberS>{rank}</RankNumberS>}
              </RankS>
              <RankingNameS>{isShow && name}</RankingNameS>
            </RankingListS>
          ))}
        </RankingUListS>
      )}

      <NeonBarPcS>
        <ImageS src={barNeonBottomPc} alt="" />
      </NeonBarPcS>
    </RankingS>
  );
});
export default RankingBox;

/**
 *
 * Styles
 *
 */
const RankingS = styled.div`
 height: 100%;
 width: 100%;
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 position: relative;
 padding-bottom: 20vw;
 background-image: linear-gradient(#0d0055, #050010 60%);

 @media ${mobileLandscape} {
   padding-bottom: 0;
 }

 @media ${tabletPortrait} {
   padding-bottom: 16vw;
 }

 @media ${tabletLandscape}, ${pc} {
   padding-bottom: 0;
   font-size: 3rem;
 }
`;

const RankingUListS = styled.ul`
 height: 70vw;
 width: 100%;
 overflow: scroll;
 // grid
 display: grid;
 grid-auto-rows: 1fr;
 grid-gap: 0.5rem;

 @media ${mobileLandscape} {
   height: 250px;
 }

 @media ${tabletPortrait} {
   height: 400px;
   width: 95%;
   margin: 0 auto;
 }

 @media ${tabletLandscape}, ${pc} {
   height: 300px;
   width: 100%;
 }
`;

const RankingListS = styled.li`
 padding: 1rem;
 background-color: rgba(255, 255, 255, 0.1);
 // flex
 display: flex;
 align-items: center;
`;

const RankS = styled.div`
 width: 3rem;
 // flex
 display: flex;
 justify-content: center;
 align-items: center;

 @media ${tabletPortrait} {
   width: 3rem;
 }
`;

const RankPictureS = styled.picture`
 width: 100%;
`;

const RankNumberS = styled.p`
 font-size: 1.4rem;

 @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
   font-size: 1.6rem;
 }
`;

const RankingNameS = styled.p`
 margin-left: 1rem;
 font-size: 1.4rem;
 word-break: break-all;

 @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
   font-size: 1.6rem;
 }
`;

const TitleS = styled.picture`
 width: 100%;
 margin-bottom: 1rem;
 display: inline-block;
 margin-top: calc(-27vw / 2);
 z-index: 2;

 @media ${mobileLandscape} {
   margin-top: calc(-12vw / 2);
 }

 @media ${tabletPortrait} {
   margin-top: calc(-22vw / 2);
 }

 @media ${tabletLandscape}, ${pc} {
   margin-top: -10%;
 }
`;

const NeonBarPcS = styled.div`
 display: none;

 @media ${mobileLandscape}, ${tabletLandscape}, ${pc} {
   width: 100%;
   display: flex;
 }
`;

const ImageS = styled.img`
 width: 100%;
`;
