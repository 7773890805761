import {
  FC, ReactNode, memo, useEffect,
} from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Utils
import { devices } from 'utils/const/devices';
// Images
import mainBgPc from 'assets/images/backGround/main_pc.jpg';
import mainBgPc2x from 'assets/images/backGround/main_pc@2x.jpg';
import mainBgSp from 'assets/images/backGround/main_sp.jpg';
import titleBgPc from 'assets/images/backGround/under_midashi_pc.jpg';
import titleBgPc2x from 'assets/images/backGround/under_midashi_pc@2x.jpg';
import titleBgSp from 'assets/images/backGround/under_midashi_sp.jpg';
import neonBarImageSp from 'assets/images/neonItem/bar-main_sp.png';

/**
 *
 * Types
 *
 */
type Props = {
  children: ReactNode;
  path: string;
};

/**
 *
 * Component
 *
 */
const SupportLayout: FC<Props> = memo((props) => {
  // i18next
  const { t } = useTranslation();

  const { children, path } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <MainS>
      {/* ページタイトル */}
      <TitleS>
        {/* DayLottsとは */}
        {path === 'whats' && (
          <TitleTextWrapS>
            <TitleTextS>{t("Day Lott'sとは")}</TitleTextS>
          </TitleTextWrapS>
        )}

        {/* 仕組み */}
        {path === 'how' && (
          <TitleTextWrapS>
            <TitleTextS>{t('仕組み')}</TitleTextS>
          </TitleTextWrapS>
        )}

        {/* よくある質問 */}
        {path === 'faq' && (
          <TitleTextWrapS>
            <TitleTextS>{t('よくある質問')}</TitleTextS>
          </TitleTextWrapS>
        )}

        {/* サポート */}
        {path === 'support' && (
          <TitleTextWrapS>
            <TitleTextS>{t('サポート')}</TitleTextS>
          </TitleTextWrapS>
        )}
      </TitleS>
      <ContainerS>
        <NeonBarImageSpS src={neonBarImageSp} alt="" loading="lazy" />
        {/* コンテンツ */}
        {children}
      </ContainerS>
    </MainS>
  );
});
export default SupportLayout;

/**
 *
 * Styles
 *
 */
const { tabletPortrait, tabletLandscape, pc } = devices;

const MainS = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: url(${mainBgSp});
  background-size: cover;
  background-position: top;
  background-attachment: fixed;

  @media ${tabletLandscape}, ${pc} {
    background: image-set(url(${mainBgPc}) 1x, url(${mainBgPc2x}) 2x);
    background-size: cover;
    background-position: center;
  }
`;

const TitleS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${titleBgSp});
  background-size: cover;
  background-position: center;

  @media ${tabletLandscape}, ${pc} {
    background: image-set(url(${titleBgPc}) 1x, url(${titleBgPc2x}) 2x);
    background-position: center;
    background-size: cover;
  }
`;

const TitleTextWrapS = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;

  @media ${tabletPortrait} {
    min-height: 230px;
  }
`;

const TitleTextS = styled.p`
  color: transparent;
  font-size: min(8vw, 3rem);
  font-weight: bold;
  word-break: break-word;

  background: linear-gradient(
    0deg,
    #ffe99b,
    #a8861f,
    #ffe99b,
    #ffffff,
    #ffe99b
  );
  background-clip: text;
  -webkit-background-clip: text;

  @media ${tabletPortrait} {
    font-size: 4rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 4.5rem;
  }
`;

const ContainerS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NeonBarImageSpS = styled.img`
  width: 100%;
  height: 2rem;
  position: relative;

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;
