import { FC, useEffect, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import NewsLayout from 'components/templates/NewsLayout';
import NewsBox from 'components/organisms/news/NewsBox';
import LoaderPart from 'components/atoms/loader/LoaderPart';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { topBreadCrumbItems } from 'utils/breadCrumbs';
import { LANG } from 'utils/const/lang';
import { devices } from 'utils/const/devices';
// Custom hooks
import { useNewsShow } from 'hooks/pages/common/useNewsShow';

const { tabletLandscape, pc } = devices;

const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN,
  VIETNAM, THAILAND,
} = LANG;

/**
 *
 * Component
 *
 */
const NewsShow: FC = memo(() => {
  // Recoil 言語設定
  const lang = useRecoilValue(langState);

  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    news,
    canWatchLottery,
    getApiNewsFind,
    emptyMessage,
    errorMessage,
    // クリーンアップ関数
    cleanUpMethod,
  } = useNewsShow();

  useEffect(() => {
    // お知らせ情報リクエスト
    getApiNewsFind();

    // 遷移後にページTOPにスクロール
    window.scrollTo({
      top: 0,
    });

    return () => {
      cleanUpMethod();
    };
  }, []);
  const title = lang === JAPANESE
    ? news.title
    : lang === SIMPLE_CHINESE
      ? news.zh_cn_title
      : lang === TRADITIONAL_CHINESE
        ? news.zh_tw_title
        : lang === KOREAN
          ? news.ko_title
          : lang === VIETNAM
            ? news.vi_title
            : lang === THAILAND
              ? news.th_title
              : news.en_title;

  return (
    <>
      <Helmet>
        <title>{news.title ? `DayLott's - ${news.title}` : t('title.userNewsShow')}</title>
        <meta name="description" content={news.description ? news.description : t('description.userNewsShow')} />
      </Helmet>
      <NewsLayout
        breadCrumbItems={[
          ...topBreadCrumbItems,
          {
            path: `/news/show/${news.id}`,
            name: news.id !== 0 ? title : emptyMessage,
          },
        ]}
      >
        {/* コンテンツ */}
        <ContainerS>
          {isLoading ? (
            <LoaderPart />
          ) : (
            <NewsBox
              title={title}
              description={
                lang === JAPANESE
                  ? news.description
                  : lang === SIMPLE_CHINESE
                    ? news.zh_cn_description
                    : lang === TRADITIONAL_CHINESE
                      ? news.zh_tw_description
                      : lang === KOREAN
                        ? news.ko_description
                        : lang === VIETNAM
                          ? news.vi_description
                          : lang === THAILAND
                            ? news.th_description
                            : news.en_description
              }
              emptyMessage={emptyMessage}
              errorMessage={errorMessage}
              canWatchLottery={canWatchLottery}
            />
          )}
        </ContainerS>
      </NewsLayout>
    </>
  );
});
export default NewsShow;

/**
 *
 * Styles
 *
 */
const ContainerS = styled.div`
  width: 100%;
  min-height: 300px;
  position: relative;

  @media ${tabletLandscape}, ${pc} {
    min-height: 400px;
  }
`;
