import { useState } from 'react';
// External library
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
import { useClear } from 'hooks/versatile/useClear';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType } from 'types/api';

/**
 *
 * Types
 *
 */
type UseAccountDeleteConfirm = () => {
  isLoading: boolean;
  errorMessage: string;
  cleanUpMethod: () => void;
  postApiUsersDelete: () => void;
};

/**
 *
 * Hooks
 *
 */
export const useAccountDeleteConfirm: UseAccountDeleteConfirm = () => {
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Hooks
  const history = useHistory();
  const { errorHandler } = useError();
  const { clearUserState } = useClear();

  // axios POST /api/users/delete
  const source = axiosCancelToken.source();
  const postApiUsersDelete = () => {
    if (!isLoading) {
      setIsLoading(true);
      setErrorMessage('');

      axiosClient
        .post<boolean>('/api/users/delete', {
          cancelToken: source.token,
        })
        .then(({ data }) => {
          if (data) {
            clearUserState();
            history.push({
              pathname: '/act/dle/finish',
              state: {
                referrer: '/act/dle/confirm',
              },
            });
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // アンマウント時のクリーンアップ関数
  const cleanUpMethod = () => {
    source.cancel();
    setIsLoading(false);
    setErrorMessage('');
  };

  return {
    isLoading,
    errorMessage,
    cleanUpMethod,
    postApiUsersDelete,
  };
};
