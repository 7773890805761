import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
import { earnedPointsState } from 'store/atoms/lottery/earnedPointsState';
// Utils
import { LANG } from 'utils/const/lang';
import { devices } from 'utils/const/devices';
// Images
import backGroundPointSp from 'assets/images/backGround/acquisition-points_sp.jpg';
import frameJaSp from 'assets/images/lottery/acquiredPoint/frame_ja_sp.png';
import frameJaSpLandscape from 'assets/images/lottery/acquiredPoint/frame_ja_sp-landscape.png';
import frameJaPc from 'assets/images/lottery/acquiredPoint/frame_ja_pc.png';
import frameJaPc2x from 'assets/images/lottery/acquiredPoint/frame_ja_pc@2x.png';
import frameEnSp from 'assets/images/lottery/acquiredPoint/frame_en_sp.png';
import frameEnSpLandscape from 'assets/images/lottery/acquiredPoint/frame_en_sp-landscape.png';
import frameEnPc from 'assets/images/lottery/acquiredPoint/frame_en_pc.png';
import frameEnPc2x from 'assets/images/lottery/acquiredPoint/frame_en_pc@2x.png';
import frameZhCnSp from 'assets/images/lottery/acquiredPoint/frame_zh_cn_sp.png';
import frameZhCnSpLandscape from 'assets/images/lottery/acquiredPoint/frame_zh_cn_sp-landscape.png';
import frameZhCnPc from 'assets/images/lottery/acquiredPoint/frame_zh_cn_pc.png';
import frameZhCnPc2x from 'assets/images/lottery/acquiredPoint/frame_zh_cn_pc@2x.png';
import frameZhTwSp from 'assets/images/lottery/acquiredPoint/frame_zh_tw_sp.png';
import frameZhTwSpLandscape from 'assets/images/lottery/acquiredPoint/frame_zh_tw_sp-landscape.png';
import frameZhTwPc from 'assets/images/lottery/acquiredPoint/frame_zh_tw_pc.png';
import frameZhTwPc2x from 'assets/images/lottery/acquiredPoint/frame_zh_tw_pc@2x.png';
import frameKoSp from 'assets/images/lottery/acquiredPoint/frame_ko_sp.png';
import frameKoLandscape from 'assets/images/lottery/acquiredPoint/frame_ko_sp-landscape.png';
import frameKoPc from 'assets/images/lottery/acquiredPoint/frame_ko_pc.png';
import frameKoPc2x from 'assets/images/lottery/acquiredPoint/frame_ko_pc@2x.png';
import frameViSp from 'assets/images/lottery/acquiredPoint/frame_vi_sp.png';
import frameViLandscape from 'assets/images/lottery/acquiredPoint/frame_vi_sp-landscape.png';
import frameViPc from 'assets/images/lottery/acquiredPoint/frame_vi_pc.png';
import frameViPc2x from 'assets/images/lottery/acquiredPoint/frame_vi_pc@2x.png';
import frameThSp from 'assets/images/lottery/acquiredPoint/frame_th_sp.png';
import frameThLandscape from 'assets/images/lottery/acquiredPoint/frame_th_sp-landscape.png';
import frameThPc from 'assets/images/lottery/acquiredPoint/frame_th_pc.png';
import frameThPc2x from 'assets/images/lottery/acquiredPoint/frame_th_pc@2x.png';

const {
  JAPANESE,
  SIMPLE_CHINESE,
  TRADITIONAL_CHINESE,
  KOREAN,
  VIETNAM,
  THAILAND,
} = LANG;

const {
  mobileLandscape, tabletPortrait, tabletLandscape, pc,
} = devices;

/**
 *
 * Component
 *
 */
const AcquisitionPoint: FC = memo(() => {
  // Recoil
  const lang = useRecoilValue(langState);
  const point = useRecoilValue(earnedPointsState);

  return (
    <AcquisitionPointS>
      <FrameS>
        <source
          srcSet={
            (lang === JAPANESE)
              ? frameJaSpLandscape
              : (lang === SIMPLE_CHINESE)
                ? frameZhCnSpLandscape
                : (lang === TRADITIONAL_CHINESE)
                  ? frameZhTwSpLandscape
                  : (lang === KOREAN)
                    ? frameKoLandscape
                    : (lang === VIETNAM)
                      ? frameViLandscape
                      : (lang === THAILAND)
                        ? frameThLandscape
                        : frameEnSpLandscape
          }
          media={mobileLandscape}
        />
        <source
          srcSet={
            (lang === JAPANESE)
              ? `${frameJaPc} 1x, ${frameJaPc2x} 2x`
              : (lang === SIMPLE_CHINESE)
                ? `${frameZhCnPc} 1x, ${frameZhCnPc2x} 2x`
                : (lang === TRADITIONAL_CHINESE)
                  ? `${frameZhTwPc} 1x, ${frameZhTwPc2x} 2x`
                  : (lang === KOREAN)
                    ? `${frameKoPc} 1x, ${frameKoPc2x} 2x`
                    : (lang === VIETNAM)
                      ? `${frameViPc} 1x, ${frameViPc2x} 2x`
                      : (lang === THAILAND)
                        ? `${frameThPc} 1x, ${frameThPc2x} 2x`
                        : `${frameEnPc} 1x, ${frameEnPc2x} 2x`
          }
          media="(min-width: 600px)"
        />
        <ImageS
          src={
            (lang === JAPANESE)
              ? frameJaSp
              : (lang === SIMPLE_CHINESE)
                ? frameZhCnSp
                : (lang === TRADITIONAL_CHINESE)
                  ? frameZhTwSp
                  : (lang === KOREAN)
                    ? frameKoSp
                    : (lang === VIETNAM)
                      ? frameViSp
                      : (lang === THAILAND)
                        ? frameThSp
                        : frameEnSp
          }
          alt=""
        />
        <TextS>{point}</TextS>
      </FrameS>
    </AcquisitionPointS>
  );
});
export default AcquisitionPoint;

/**
 *
 * Styles
 *
 */
const AcquisitionPointS = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${backGroundPointSp});
  background-size: cover;
  background-position-x: center;

  @media ${mobileLandscape}, ${tabletLandscape}, ${pc} {
    background: unset;
  }
`;

const FrameS = styled.picture`
  display: block;
  width: 100%;
  position: relative;

  @media ${tabletPortrait} {
    width: 70%;
    margin: 0 auto;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
  }
`;

const ImageS = styled.img`
  width: 100%;
`;

const TextS = styled.p`
  font-size: 5vw;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: end;
  color: #ff0000;
  position: absolute;
  top: calc(50% - 2vw);
  right: 26%;
  width: 37%;
  overflow-x: scroll;
  overflow-y: hidden;
  
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display: none;
  }

  @media ${mobileLandscape} {
    font-size: 3.4vw;
    top: calc(50% - 2vw);
    right: 14%;
    width: 55%;
  }

  @media ${tabletPortrait} {
    font-size: 4vw;
    top: calc(50% - 1.6vw);
    right: 28%;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2rem;
    width: 35%;
    top: calc(50% - 0.8rem);
    right: 28%;
  }
`;
