import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// Utils
import { devices } from 'utils/const/devices';
// Images
import mainBgPc from 'assets/images/backGround/main_pc.jpg';
import mainBgPc2x from 'assets/images/backGround/main_pc@2x.jpg';
import mainBgSp from 'assets/images/backGround/main_sp.jpg';

const { tabletPortrait, tabletLandscape, pc } = devices;

/**
 *
 * Component
 *
 */
const NotFound: FC = memo(() => (
  <>
    <Helmet>
      <title>{'DayLott\'s - 404 ERROR - Page not found.'}</title>
      <meta name="description" content="404 ERROR - Page not found." />
    </Helmet>
    <NotFoundS>
      <ContainerS>
        <TitleS>404 ERROR</TitleS>
        <DescriptionS>Page not found.</DescriptionS>
        <TopLinkS>
          <Link to="/" className="link-top">TOP</Link>
        </TopLinkS>
      </ContainerS>
    </NotFoundS>
  </>
));
export default NotFound;

/**
 *
 * Styles
 *
 */
const NotFoundS = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: url(${mainBgSp});
  background-size: cover;
  background-position: top;
  background-attachment: fixed;

  @media ${tabletLandscape}, ${pc} {
    background: image-set(url(${mainBgPc}) 1x, url(${mainBgPc2x}) 2x);
    background-size: cover;
    background-position: top;
  }
`;

const ContainerS = styled.div`
  width: 90%;
  min-height: 500px;
  padding: 7rem 0 5rem;

  @media ${tabletPortrait} {
    padding: 8rem 0;
    min-height: 500px;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    max-width: 90rem;
    min-height: 500px;
    padding: 8rem 0;
  }
`;

const TitleS = styled.h1`
  color: #ffffff;
  font-size: clamp(4rem, 12vw, 6rem);
  font-weight: 600;
  margin-bottom: 0.5rem;

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 0.8rem;
    font-size: 6rem;
  }
`;

const DescriptionS = styled.p`
  color: #ffffff;
  font-size: clamp(2rem, 6vw, 4rem);
  font-weight: 300;
  margin-bottom: 6rem;

  @media ${tabletPortrait} {
    margin-bottom: 6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 3.5rem;
    margin-bottom: 10rem;
  }
`;

const TopLinkS = styled.p`
  width: 100%;
  font-size: clamp(1.2rem, 4vw , 2rem);
  color: #fff040;
  text-decoration: underline;
  display: flex;
  justify-content: center;

  @media ${tabletPortrait} {
    font-size: clamp(1.4rem, 3vw, 3rem);
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2rem;
  }

  .link-top {
    &:hover {
      opacity: 0.5;
    }
  }
`;
