// eslint-disable-next-line
import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import PrizeText from 'components/atoms/text/PrizeText';
// Utils
import { PLAYBOX_TYPE } from 'utils/const/playBox';
import { devices } from 'utils/const/devices';
// Types
import { PlayBoxType } from 'types/playBoxType';
// Images
import kingIcon from 'assets/images/top/playBox/icon-king.png';
import diamondIcon from 'assets/images/top/playBox/icon-diamond.png';
import goldIcon from 'assets/images/top/playBox/icon-gold.png';
import silverIcon from 'assets/images/top/playBox/icon-silver.png';
import bronzeIcon from 'assets/images/top/playBox/icon-bronze.png';
import leafRightSp from 'assets/images/top/playBox/leaf_right_sp.png';
import leafRightPc from 'assets/images/top/playBox/leaf_right_pc.png';
import leafRightPc2x from 'assets/images/top/playBox/leaf_right_pc@2x.png';
import leafLeftSp from 'assets/images/top/playBox/leaf_left_sp.png';
import leafLeftPc from 'assets/images/top/playBox/leaf_left_pc.png';
import leafLeftPc2x from 'assets/images/top/playBox/leaf_left_pc@2x.png';

/**
 *
 * Types
 *
 */
type Props = {
  playBox: PlayBoxType;
};

/**
 *
 * Component
 *
 */
const { BOX_100000, BOX_10000 } = PLAYBOX_TYPE;

const TablePrize: FC<Props> = memo((props) => {
  const { playBox } = props;
  // i18next
  const { t } = useTranslation();

  return (
    <TablePrizeS>
      <LeafPictureS>
        <source srcSet={`${leafLeftPc} 1x, ${leafLeftPc2x} 2x`} media="(min-width: 600px)" />
        <LeafImageS src={leafLeftSp} alt="" />
      </LeafPictureS>

      <PrizeListS>
        {/* 一等 */}
        {playBox.lot_tables[0]?.number_of_winners_1st !== 0 && (
          <PrizeTitleS>
            <PrizeTitleImageWrapS>
              <PrizeTitleImageS
                src={playBox.type === BOX_100000
                  ? kingIcon
                  : playBox.type === BOX_10000 ? diamondIcon : goldIcon}
                alt=""
              />
            </PrizeTitleImageWrapS>
            <PrizeTitleTextS>
              <PrizeText>
                {playBox.type === BOX_100000
                  ? t('キング')
                  : playBox.type === BOX_10000 ? t('ダイヤモンド') : t('ゴールド')}
                {' : '}
                <PrizePointS>
                  {playBox.lot_tables[0]?.winning_point_1st.toLocaleString()}
                  P
                </PrizePointS>
                /
                {playBox.lot_tables[0]?.number_of_winners_1st}
                <PrizeUnitS>{t('口')}</PrizeUnitS>
              </PrizeText>
            </PrizeTitleTextS>
          </PrizeTitleS>
        )}

        {/* 二等 */}
        {playBox.lot_tables[0]?.number_of_winners_2nd !== 0 && (
          <PrizeTitleS>
            <PrizeTitleImageWrapS>
              <PrizeTitleImageS
                src={playBox.type === BOX_100000 || playBox.type === BOX_10000
                  ? goldIcon
                  : silverIcon}
                alt=""
              />
            </PrizeTitleImageWrapS>
            <PrizeTitleTextS>
              <PrizeText>
                {playBox.type === BOX_100000 || playBox.type === BOX_10000
                  ? t('ゴールド')
                  : t('シルバー')}
                {' : '}
                <PrizePointS>
                  {playBox.lot_tables[0]?.winning_point_2nd.toLocaleString()}
                  P
                </PrizePointS>
                /
                {playBox.lot_tables[0]?.number_of_winners_2nd}
                <PrizeUnitS>{t('口')}</PrizeUnitS>
              </PrizeText>
            </PrizeTitleTextS>
          </PrizeTitleS>
        )}

        {/* 三等 */}
        {playBox.lot_tables[0]?.number_of_winners_3rd !== 0 && (
          <PrizeTitleS>
            <PrizeTitleImageWrapS>
              <PrizeTitleImageS
                src={playBox.type === BOX_100000 || playBox.type === BOX_10000
                  ? silverIcon
                  : bronzeIcon}
                alt=""
              />
            </PrizeTitleImageWrapS>
            <PrizeTitleTextS>
              <PrizeText>
                {playBox.type === BOX_100000 || playBox.type === BOX_10000
                  ? t('シルバー')
                  : t('ブロンズ')}
                {' : '}
                <PrizePointS>
                  {playBox.lot_tables[0]?.winning_point_3rd.toLocaleString()}
                  P
                </PrizePointS>
                /
                {playBox.lot_tables[0]?.number_of_winners_3rd}
                <PrizeUnitS>{t('口')}</PrizeUnitS>
              </PrizeText>
            </PrizeTitleTextS>
          </PrizeTitleS>
        )}

        {/* 四等 */}
        {playBox.lot_tables[0]?.number_of_winners_4th !== 0 && (
          <PrizeTitleS>
            <PrizeTitleImageWrapS>
              <PrizeTitleImageS src={bronzeIcon} alt="" />
            </PrizeTitleImageWrapS>
            <PrizeTitleTextS>
              <PrizeText>
                {t('ブロンズ')}
                {' : '}
                <PrizePointS>
                  {playBox.lot_tables[0]?.winning_point_4th.toLocaleString()}
                  P
                </PrizePointS>
                /
                {playBox.lot_tables[0]?.number_of_winners_4th}
                <PrizeUnitS>{t('口')}</PrizeUnitS>
              </PrizeText>
            </PrizeTitleTextS>
          </PrizeTitleS>
        )}

        {/* 五等 */}
        {playBox.lot_tables[0]?.number_of_winners_5th !== 0 && (
          <PrizeTitleS>
            <PrizeTitleImageWrapS>
              {/* <PrizeTitleImageS src={bronzeIcon} alt="" /> */}
            </PrizeTitleImageWrapS>
            <PrizeTitleTextS>
              <PrizeText>
                {t('アンバー')}
                {' : '}
                <PrizePointS>
                  {playBox.lot_tables[0]?.winning_point_5th.toLocaleString()}
                  P
                </PrizePointS>
                /
                {playBox.lot_tables[0]?.number_of_winners_5th}
                <PrizeUnitS>{t('口')}</PrizeUnitS>
              </PrizeText>
            </PrizeTitleTextS>
          </PrizeTitleS>
        )}
      </PrizeListS>

      <LeafPictureS>
        <source srcSet={`${leafRightPc} 1x, ${leafRightPc2x} 2x`} media="(min-width: 600px)" />
        <LeafImageS src={leafRightSp} alt="" />
      </LeafPictureS>
    </TablePrizeS>
  );
});
export default TablePrize;

/**
 *
 * Styles
 *
 */
const { tabletPortrait, tabletLandscape, pc } = devices;

const TablePrizeS = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;

  @media ${tabletLandscape}, ${pc} {
    column-gap: 2rem;
  }
`;

const LeafPictureS = styled.picture`
  height: 100px;
`;

const LeafImageS = styled.img`
  height: 100%;
`;

const PrizeListS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

const PrizeTitleS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    margin-bottom: 2rem;
  }
`;

const PrizeTitleImageWrapS = styled.div`
  height: 2rem;
  margin-right: 1rem;
`;

const PrizeTitleImageS = styled.img`
  height: 100%;
`;

const PrizeTitleTextS = styled.div`
  font-size: 3.4vw;
  font-weight: bold;
  word-break: break-word;
  line-height: 1.5;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const PrizePointS = styled.span`
  color: #fff240;
`;

const PrizeUnitS = styled.span`
  font-size: 2vw;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.2rem;
  }
`;
