// eslint-disable-next-line
import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Hooks
import { useUfcTransition } from 'hooks/organisms/useUfcTransition';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Components
import RoundButton from 'components/atoms/button/RoundButton';
// Utill
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';
// Images
import boxPc from 'assets/images/neonItem/box_pc.png';
import boxPc2x from 'assets/images/neonItem/box_pc@2x.png';
import barTopSp from 'assets/images/neonItem/bar-tiplist-top_sp.png';
import barBottomSp from 'assets/images/neonItem/bar-tiplist-bottom_sp.png';
// sp
import Head1EnSp from 'assets/images/top/whats/en/txt1_sp.png';
import Head2EnSp from 'assets/images/top/whats/en/txt2_sp.png';
import Head3EnSp from 'assets/images/top/whats/en/txt3_sp.png';
import Head1JaSp from 'assets/images/top/whats/ja/txt1_sp.png';
import Head2JaSp from 'assets/images/top/whats/ja/txt2_sp.png';
import Head3JaSp from 'assets/images/top/whats/ja/txt3_sp.png';
import Head1ZhCnSp from 'assets/images/top/whats/zhCn/txt1_sp.png';
import Head2ZhCnSp from 'assets/images/top/whats/zhCn/txt2_sp.png';
import Head3ZhCnSp from 'assets/images/top/whats/zhCn/txt3_sp.png';
import Head1ZhTwSp from 'assets/images/top/whats/zhTw/txt1_sp.png';
import Head2ZhTwSp from 'assets/images/top/whats/zhTw/txt2_sp.png';
import Head3ZhTwSp from 'assets/images/top/whats/zhTw/txt3_sp.png';
import Head1KoSp from 'assets/images/top/whats/ko/txt1_sp.png';
import Head2KoSp from 'assets/images/top/whats/ko/txt2_sp.png';
import Head3KoSp from 'assets/images/top/whats/ko/txt3_sp.png';
import Head1ViSp from 'assets/images/top/whats/vi/txt1_sp.png';
import Head2ViSp from 'assets/images/top/whats/vi/txt2_sp.png';
import Head3ViSp from 'assets/images/top/whats/vi/txt3_sp.png';
import Head1ThSp from 'assets/images/top/whats/th/txt1_sp.png';
import Head2ThSp from 'assets/images/top/whats/th/txt2_sp.png';
import Head3ThSp from 'assets/images/top/whats/th/txt3_sp.png';
// pc
import Head1EnPc from 'assets/images/top/whats/en/txt1_pc.png';
import Head2EnPc from 'assets/images/top/whats/en/txt2_pc.png';
import Head3EnPc from 'assets/images/top/whats/en/txt3_pc.png';
import Head1JaPc from 'assets/images/top/whats/ja/txt1_pc.png';
import Head2JaPc from 'assets/images/top/whats/ja/txt2_pc.png';
import Head3JaPc from 'assets/images/top/whats/ja/txt3_pc.png';
import Head1ZhCnPc from 'assets/images/top/whats/zhCn/txt1_pc.png';
import Head2ZhCnPc from 'assets/images/top/whats/zhCn/txt2_pc.png';
import Head3ZhCnPc from 'assets/images/top/whats/zhCn/txt3_pc.png';
import Head1ZhTwPc from 'assets/images/top/whats/zhTw/txt1_pc.png';
import Head2ZhTwPc from 'assets/images/top/whats/zhTw/txt2_pc.png';
import Head3ZhTwPc from 'assets/images/top/whats/zhTw/txt3_pc.png';
import Head1KoPc from 'assets/images/top/whats/ko/txt1_pc.png';
import Head2KoPc from 'assets/images/top/whats/ko/txt2_pc.png';
import Head3KoPc from 'assets/images/top/whats/ko/txt3_pc.png';
import Head1ViPc from 'assets/images/top/whats/vi/txt1_pc.png';
import Head2ViPc from 'assets/images/top/whats/vi/txt2_pc.png';
import Head3ViPc from 'assets/images/top/whats/vi/txt3_pc.png';
import Head1ThPc from 'assets/images/top/whats/th/txt1_pc.png';
import Head2ThPc from 'assets/images/top/whats/th/txt2_pc.png';
import Head3ThPc from 'assets/images/top/whats/th/txt3_pc.png';
// pc2x
import Head1EnPc2x from 'assets/images/top/whats/en/txt1_pc@2x.png';
import Head2EnPc2x from 'assets/images/top/whats/en/txt2_pc@2x.png';
import Head3EnPc2x from 'assets/images/top/whats/en/txt3_pc@2x.png';
import Head1JaPc2x from 'assets/images/top/whats/ja/txt1_pc@2x.png';
import Head2JaPc2x from 'assets/images/top/whats/ja/txt2_pc@2x.png';
import Head3JaPc2x from 'assets/images/top/whats/ja/txt3_pc@2x.png';
import Head1ZhCnPc2x from 'assets/images/top/whats/zhCn/txt1_pc@2x.png';
import Head2ZhCnPc2x from 'assets/images/top/whats/zhCn/txt2_pc@2x.png';
import Head3ZhCnPc2x from 'assets/images/top/whats/zhCn/txt3_pc@2x.png';
import Head1ZhTwPc2x from 'assets/images/top/whats/zhTw/txt1_pc@2x.png';
import Head2ZhTwPc2x from 'assets/images/top/whats/zhTw/txt2_pc@2x.png';
import Head3ZhTwPc2x from 'assets/images/top/whats/zhTw/txt3_pc@2x.png';
import Head1KoPc2x from 'assets/images/top/whats/ko/txt1_pc@2x.png';
import Head2KoPc2x from 'assets/images/top/whats/ko/txt2_pc@2x.png';
import Head3KoPc2x from 'assets/images/top/whats/ko/txt3_pc@2x.png';
import Head1ViPc2x from 'assets/images/top/whats/vi/txt1_pc@2x.png';
import Head2ViPc2x from 'assets/images/top/whats/vi/txt2_pc@2x.png';
import Head3ViPc2x from 'assets/images/top/whats/vi/txt3_pc@2x.png';
import Head1ThPc2x from 'assets/images/top/whats/th/txt1_pc@2x.png';
import Head2ThPc2x from 'assets/images/top/whats/th/txt2_pc@2x.png';
import Head3ThPc2x from 'assets/images/top/whats/th/txt3_pc@2x.png';

/**
 *
 * Component
 *
 */
const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

// サービス概要用コンポーネント
const Whats: FC = memo(() => {
  // i18next
  const { t } = useTranslation();
  // Recoil 言語設定
  const lang = useRecoilValue(langState);

  const {
    transitToUfc,
  } = useUfcTransition();

  return (
    <WhatsS>
      <BlockS>
        <BarS>
          <BarImageS src={barTopSp} alt="" loading="lazy" />
        </BarS>
        <BoxS>
          <TextBlockS>
            <HeadPictureFirstS className={lang}>
              <source
                srcSet={
                  lang === JAPANESE
                    ? `${Head1JaPc} 1x, ${Head1JaPc2x} 2x`
                    : lang === SIMPLE_CHINESE
                      ? `${Head1ZhCnPc} 1x, ${Head1ZhCnPc2x} 2x`
                      : lang === TRADITIONAL_CHINESE
                        ? `${Head1ZhTwPc} 1x, ${Head1ZhTwPc2x} 2x`
                        : lang === KOREAN
                          ? `${Head1KoPc} 1x, ${Head1KoPc2x} 2x`
                          : lang === VIETNAM
                            ? `${Head1ViPc} 1x, ${Head1ViPc2x} 2x`
                            : lang === THAILAND
                              ? `${Head1ThPc} 1x, ${Head1ThPc2x} 2x`
                              : `${Head1EnPc} 1x, ${Head1EnPc2x} 2x`
                }
                media="(min-width: 1025px)"
              />
              <HeadImageS
                src={
                  lang === JAPANESE
                    ? Head1JaSp
                    : lang === SIMPLE_CHINESE
                      ? Head1ZhCnSp
                      : lang === TRADITIONAL_CHINESE
                        ? Head1ZhTwSp
                        : lang === KOREAN
                          ? Head1KoSp
                          : lang === VIETNAM
                            ? Head1ViSp
                            : lang === THAILAND
                              ? Head1ThSp
                              : Head1EnSp
                }
                alt=""
              />
            </HeadPictureFirstS>
            <TextS>
              {t(
                '業界最高水準である、当選確率1/8も用意されているオンラインゲームです。還元率は75%!! 1ゲーム5Pからゲーム参加可能。5Pから10,000Pの当選も毎日開催されています。',
              )}
            </TextS>
          </TextBlockS>
        </BoxS>
        <BarS>
          <BarImageS src={barBottomSp} alt="" loading="lazy" />
        </BarS>
      </BlockS>

      <BlockS>
        <BarS>
          <BarImageS src={barTopSp} alt="" loading="lazy" />
        </BarS>
        <BoxS>
          <TextBlockS>
            <HeadPictureS>
              <source
                srcSet={
                  lang === JAPANESE
                    ? `${Head2JaPc} 1x, ${Head2JaPc2x} 2x`
                    : lang === SIMPLE_CHINESE
                      ? `${Head2ZhCnPc} 1x, ${Head2ZhCnPc2x} 2x`
                      : lang === TRADITIONAL_CHINESE
                        ? `${Head2ZhTwPc} 1x, ${Head2ZhTwPc2x} 2x`
                        : lang === KOREAN
                          ? `${Head2KoPc} 1x, ${Head2KoPc2x} 2x`
                          : lang === VIETNAM
                            ? `${Head2ViPc} 1x, ${Head2ViPc2x} 2x`
                            : lang === THAILAND
                              ? `${Head2ThPc} 1x, ${Head2ThPc2x} 2x`
                              : `${Head2EnPc} 1x, ${Head2EnPc2x} 2x`
                }
                media="(min-width: 1025px)"
              />
              <HeadImageS
                src={
                  lang === JAPANESE
                    ? Head2JaSp
                    : lang === SIMPLE_CHINESE
                      ? Head2ZhCnSp
                      : lang === TRADITIONAL_CHINESE
                        ? Head2ZhTwSp
                        : lang === KOREAN
                          ? Head2KoSp
                          : lang === VIETNAM
                            ? Head2ViSp
                            : lang === THAILAND
                              ? Head2ThSp
                              : Head2EnSp
                }
                alt=""
              />
            </HeadPictureS>
            <TextS>
              {t(
                "1day,1million,1richをテーマにDay Lott'sは運営をしています。毎日世界中のどこかでミリオネアがDay Lott'sから誕生しています。我々のサービスは、1日1,000,000Pの当選を目指しています。",
              )}
            </TextS>
          </TextBlockS>
        </BoxS>
        <BarS>
          <BarImageS src={barBottomSp} alt="" loading="lazy" />
        </BarS>
      </BlockS>

      <BlockS>
        <BarS>
          <BarImageS src={barTopSp} alt="" loading="lazy" />
        </BarS>
        <BoxS>
          <TextBlockS>
            <HeadPictureThirdS className={lang}>
              <source
                srcSet={
                  lang === JAPANESE
                    ? `${Head3JaPc} 1x, ${Head3JaPc2x} 2x`
                    : lang === SIMPLE_CHINESE
                      ? `${Head3ZhCnPc} 1x, ${Head3ZhCnPc2x} 2x`
                      : lang === TRADITIONAL_CHINESE
                        ? `${Head3ZhTwPc} 1x, ${Head3ZhTwPc2x} 2x`
                        : lang === KOREAN
                          ? `${Head3KoPc} 1x, ${Head3KoPc2x} 2x`
                          : lang === VIETNAM
                            ? `${Head3ViPc} 1x, ${Head3ViPc2x} 2x`
                            : lang === THAILAND
                              ? `${Head3ThPc} 1x, ${Head3ThPc2x} 2x`
                              : `${Head3EnPc} 1x, ${Head3EnPc2x} 2x`
                }
                media="(min-width: 1025px)"
              />
              <HeadImageS
                src={
                  lang === JAPANESE
                    ? Head3JaSp
                    : lang === SIMPLE_CHINESE
                      ? Head3ZhCnSp
                      : lang === TRADITIONAL_CHINESE
                        ? Head3ZhTwSp
                        : lang === KOREAN
                          ? Head3KoSp
                          : lang === VIETNAM
                            ? Head3ViSp
                            : lang === THAILAND
                              ? Head3ThSp
                              : Head3EnSp
                }
                alt=""
              />
            </HeadPictureThirdS>
            <TextS>
              {t(
                "Day Lott'sでは、入金、出金の際に仮想通貨を利用することができます。仮想通貨の利用により、ボーダーレスに、そしてスピードのある入出金が可能となっております。",
              )}
            </TextS>
            <ButtonS className="link" onClick={() => transitToUfc('deposit')}>
              <RoundButton
                background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                borderColor="rgba(219,0,177,1)"
              >
                {t('入金する')}
              </RoundButton>
            </ButtonS>
          </TextBlockS>
        </BoxS>
        <BarS>
          <BarImageS src={barBottomSp} alt="" loading="lazy" />
        </BarS>
      </BlockS>
    </WhatsS>
  );
});
export default Whats;

/**
 *
 * Styles
 *
 */
const { tabletLandscape, pc } = devices;

const WhatsS = styled.div`
  width: 100%;
  padding: 3rem 0;

  // flex
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    width: 95%;
    flex-direction: unset;
    justify-content: center;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    max-width: 100rem;
    padding: 7rem 0;
  }
`;

const BlockS = styled.div`
  margin-bottom: 2rem;

  // flex
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: unset;
    width: calc(100% / 3);
    margin-bottom: 0;
    margin-right: 2rem;

    &:last-child {
      margin: 0;
    }
  }

  &:last-child {
    margin: 0;
  }
`;

const BoxS = styled.div`
  width: 100%;
  padding: 3rem 2rem;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    background: transparent;
    padding: 5rem 3rem;
    border-image: url(${boxPc}) 5 5 5 5 fill stretch;

    @media (min-resolution: 2dppx) {
      border-image: url(${boxPc2x}) 5 5 5 5 fill stretch;
    }
  }
`;

const TextBlockS = styled.div`
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadPictureS = styled.picture`
  width: 60%;
  display: flex;
  align-items: center;

  @media (min-width: 600px) {
    width: 100%;
    height: 120px;
  }
`;

const HeadPictureFirstS = styled(HeadPictureS)`
  @media ${tabletLandscape}, ${pc} {
    &.ja,
    &.zh-CN,
    &.zh-TW,
    &.ko,
    &.vi,
    &.th {
      width: 70%;
    }
  }
`;

const HeadPictureThirdS = styled(HeadPictureS)`
  @media ${tabletLandscape}, ${pc} {
    &.en {
      width: 80%;
    }
    &.zh-TW {
      width: 70%;
    }
  }
`;

const HeadImageS = styled.img`
  width: 100%;
`;

const TextS = styled.p`
  color: #ffff;
  font-size: 1.4rem;
  word-break: break-word;
  text-align: start;
  line-height: 1.6;
  margin-top: 1rem;

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const BarS = styled.div`
  width: 100%;
  display: block;

  @media (min-width: 600px) {
    display: none;
  }
`;

const BarImageS = styled.img`
  width: 100%;
`;

const ButtonS = styled.div`
  width: 200px;
  font-size: 1.4rem;
  margin-top: 3rem;
  word-break: break-word;

  @media (min-width: 600px) {
    width: 100%;
    font-size: 1.6rem;
    margin-top: 5rem;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 220px;
    font-size: 1.8rem;
  }
`;
