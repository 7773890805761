import { useState } from 'react';
// External library
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType } from 'types/api';

/**
 *
 * メール認証ページ用カスタムフック
 *
 */
export const useEmailVerified = (): {
  isLoading: boolean;
  successMessage: string;
  errorMessage: string;
  postApiVerified: () => void;
  cleanUpMethod: () => void;
} => {
  // i18-next
  const { t } = useTranslation();

  // ローディングフラグ
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // サクセスメッセージ
  const [successMessage, setSuccessMessage] = useState('');

  // エラー用カスタムフック
  const { errorHandler } = useError();
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // 認証トークン取得
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('t');

  // axiosキャンセルトークン
  const axiosSource = axiosCancelToken.source();
  // メール認証リクエスト
  const postApiVerified = () => {
    if (!isLoading) {
      setIsLoading(true);

      // axios POST /api/verified
      axiosClient
        .post<boolean>('/api/verified', {
          email_token: token,
          cancelToken: axiosSource.token,
        })
        .then(({ data }) => {
          if (data) {
            setSuccessMessage(t('認証に成功しました。'));
            setIsLoading(false);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }

          setIsLoading(false);
        });
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    setIsLoading(false);
    setSuccessMessage('');
    setErrorMessage('');
    axiosSource.cancel();
  };

  return {
    isLoading,
    successMessage,
    errorMessage,
    postApiVerified,
    cleanUpMethod,
  };
};
