// External library
import { useTranslation } from 'react-i18next';
// Recoil
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
// Utils
import { KYC_FLG } from 'utils/const/kyc';

// Types
type User = {
  label: string;
  checked: boolean;
  edit: boolean;
  editLink: string;
  data: string;
};

// 登録情報ページ用カスタムフック
export const useRegisterInformation = (): {
  name: string;
  email: string;
  user: User[];
  kycFlg: number;
} => {
  // userState
  const {
    name, email, birthday, kycFlg,
  } = useRecoilValue(userState);
  const { APPROVAL, PROCESSING, REJECTED } = KYC_FLG;

  const getKycStatus = (): string => {
    switch (kycFlg) {
      case APPROVAL:
        return birthday;
      case PROCESSING:
        return 'KYC確認中';
      case REJECTED:
        return 'KYC却下';
      default:
        return '未設定';
    }
  };

  const { t } = useTranslation();
  // 登録情報
  const user: User[] = [
    {
      label: 'メールアドレス',
      checked: true,
      edit: true,
      editLink: '/email_change',
      data: email,
    },
    {
      label: 'パスワード',
      checked: true,
      edit: true,
      editLink: '/password_reset#mypage',
      data: '*'.repeat(8),
    },
    {
      label: '生年月日',
      checked: kycFlg === APPROVAL,
      edit: false,
      editLink: '',
      data: t(getKycStatus()),
    },
  ];

  return {
    name,
    email,
    user,
    kycFlg,
  };
};
