import { useState, useRef, RefObject } from 'react';
// External library
import { useHistory } from 'react-router-dom';
// Custom hooks
import { useValidate } from 'hooks/versatile/useValidate';

/**
 *
 * お問合せページ用ロジック
 *
 */
export const useInquiry = (): {
  errorMessage: string;
  emailRef: RefObject<HTMLInputElement>;
  nameRef: RefObject<HTMLInputElement>;
  bodyRef: RefObject<HTMLTextAreaElement>;
  pushToInquiryConfirm: () => void;
} => {
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // ユーザー名
  const nameRef = useRef<HTMLInputElement>(null);
  // Eメール
  const emailRef = useRef<HTMLInputElement>(null);
  // お問合せ内容
  const bodyRef = useRef<HTMLTextAreaElement>(null);

  // historyオブジェクト
  const history = useHistory();
  // バリデーション用カスタムフック
  const { emailValidate, inquiryNameValidate, inquiryBodyValidate } = useValidate();
  // 確認画面に遷移
  const pushToInquiryConfirm = () => {
    // 氏名バリデーション
    let name = '';
    if (nameRef.current !== null) {
      const result = inquiryNameValidate(nameRef.current.value);
      if (result) {
        setErrorMessage(result);

        return;
      }

      name = nameRef.current.value;
    }
    // メールアドレスバリデーション
    let email = '';
    if (emailRef.current !== null) {
      const result = emailValidate(emailRef.current.value);
      if (result) {
        setErrorMessage(result);

        return;
      }

      email = emailRef.current.value;
    }
    // 問い合わせ内容バリデーション
    let body = '';
    if (bodyRef.current !== null) {
      const result = inquiryBodyValidate(bodyRef.current.value);
      if (result) {
        setErrorMessage(result);

        return;
      }

      body = bodyRef.current.value;
    }

    history.push({
      pathname: '/inquiry/confirm',
      state: {
        referrer: '/inquiry',
        name,
        email,
        body,
      },
    });
  };

  return {
    errorMessage,
    nameRef,
    emailRef,
    bodyRef,
    pushToInquiryConfirm,
  };
};
