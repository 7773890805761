import { FC, memo } from 'react';
// External library
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import NeonShadowBorder from 'components/atoms/border/NeonShadowBorder';
// Images
import passportIcon from 'assets/images/mypage/passport.svg';
import licenseIcon from 'assets/images/mypage/license.svg';
// Utils
import { devices } from 'utils/const/devices';

const { tabletPortrait, tabletLandscape, pc } = devices;

/**
 *
 * Component
 *
 */
const Kyc: FC = memo(() => {
  // i18next
  const { t } = useTranslation();

  return (
    <KycS>
      <TitleWrapS>
        <TitleS>{t('生年月日')}</TitleS>
        <TextS>{t('※ポイントの出金・交換をする際にはKYC認証が必須となります。')}</TextS>
      </TitleWrapS>

      <BorderSpS />
      <BorderPcS>
        <NeonShadowBorder />
      </BorderPcS>

      <ContentS>
        <TextS>{t('書類のタイプを選択する')}</TextS>
      </ContentS>

      <ContainerS>
        <Link to="/kyc/upload/passport">
          <PassportS>
            <ItemTextS>{t('パスポート')}</ItemTextS>
            <PassportIconWrapS>
              <IconS src={passportIcon} alt="" loading="lazy" />
            </PassportIconWrapS>
          </PassportS>
        </Link>

        <Link to="/kyc/upload/license">
          <LicenseS>
            <ItemTextS>{t('免許証')}</ItemTextS>
            <LicenseIconWrapS>
              <IconS src={licenseIcon} alt="" loading="lazy" />
            </LicenseIconWrapS>
          </LicenseS>
        </Link>
      </ContainerS>
    </KycS>
  );
});
export default Kyc;

/**
 *
 * Styles
 *
 */
const KycS = styled.div`
  width: 100%;
`;

const TitleWrapS = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 1rem;

  @media ${tabletLandscape}, ${pc} {
    flex-direction: row;
    align-items: center;
    column-gap: 1.2rem;
    margin-bottom: 1.6rem;
  }
`;

const TitleS = styled.p`
  font-size: 1.6rem;
  margin-left: 0.4rem;

  @media ${tabletLandscape}, ${pc} {
    font-size: 2.4rem;
    margin-left: 0;
  }
`;

const TextS = styled.p`
  font-size: 1.2rem;

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const BorderSpS = styled.hr`
  width: 100%;
  margin-bottom: 1rem;

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;

const BorderPcS = styled.div`
  display: none;

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    display: block;
    margin-bottom: 4rem;
  }
`;

const ContentS = styled.div`
  margin-bottom: 2rem;

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 2.4rem;
  }
`;

const ContainerS = styled.div`
  width: 100%;
  // flex
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  gap: 2rem;

  @media ${tabletPortrait} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${tabletLandscape}, ${pc} {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    margin: 0 auto;
    gap: 10rem;
  }
`;

const BoxS = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.4rem;
  background-color: rgba(0, 0, 0, 0.6);
  border: 2px solid #ffffff;
  cursor: pointer;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media ${tabletPortrait} {
    justify-content: space-around;
  }

  @media ${tabletLandscape}, ${pc} {
    padding: 3rem;
    justify-content: center;
    gap: 2rem;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const PassportS = styled(BoxS)`
  color: #f502fd;
  border-color: #f502fd;
`;

const LicenseS = styled(BoxS)`
  color: #0088fc;
  border-color: #0088fc;
`;

const ItemTextS = styled.p`
  font-size: 1.4rem;

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const PassportIconWrapS = styled.div`
  width: min(25%, 10rem);

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    width: 35%;
  }
`;

const LicenseIconWrapS = styled.div`
  width: min(30%, 14rem);

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    width: 45%;
  }
`;

const IconS = styled.img`
  width: 100%;
`;
