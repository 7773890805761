import { FC } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import BreadCrumbs from 'components/atoms/breadCrumbs/BreadCrumbs';
import NormalInput from 'components/atoms/input/NormalInput';
import RoundButton from 'components/atoms/button/RoundButton';
import GoogleRecaptcha from 'components/atoms/text/GoogleRecaptcha';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
import HomeModal from 'components/organisms/login/HomeModal';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { loginBreadCrumbItems } from 'utils/breadCrumbs';
import { LANG } from 'utils/const/lang';
// Custom hooks
import { useLogin } from 'hooks/pages/guest/useLogin';
import { useUfcTransition } from 'hooks/organisms/useUfcTransition';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';
import titleJaSp from 'assets/images/login/title_ja_sp.png';
import titleJaPc from 'assets/images/login/title_ja_pc.png';
import titleJaPc2x from 'assets/images/login/title_ja_pc@2x.png';
import titleEnSp from 'assets/images/login/title_en_sp.png';
import titleEnPc from 'assets/images/login/title_en_pc.png';
import titleEnPc2x from 'assets/images/login/title_en_pc@2x.png';
import titleKoSp from 'assets/images/login/title_ko_sp.png';
import titleKoPc from 'assets/images/login/title_ko_pc.png';
import titleKoPc2x from 'assets/images/login/title_ko_pc@2x.png';
import titleZhCnSp from 'assets/images/login/title_zh_cn_sp.png';
import titleZhCnPc from 'assets/images/login/title_zh_cn_pc.png';
import titleZhCnPc2x from 'assets/images/login/title_zh_cn_pc@2x.png';
import titleZhTwSp from 'assets/images/login/title_zh_tw_sp.png';
import titleZhTwPc from 'assets/images/login/title_zh_tw_pc.png';
import titleZhTwPc2x from 'assets/images/login/title_zh_tw_pc@2x.png';
import titleViSp from 'assets/images/login/title_vi_sp.png';
import titleViPc from 'assets/images/login/title_vi_pc.png';
import titleViPc2x from 'assets/images/login/title_vi_pc@2x.png';
import titleThSp from 'assets/images/login/title_th_sp.png';
import titleThPc from 'assets/images/login/title_th_pc.png';
import titleThPc2x from 'assets/images/login/title_th_pc@2x.png';

const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

/**
 *
 * Component
 *
 */
const Login: FC = () => {
  // i18-next
  const { t } = useTranslation();
  // Recoil 言語設定
  const lang = useRecoilValue(langState);
  // ログインページ用カスタムフック
  const {
    isLoading,
    errorMessage,
    emailRef,
    passwordRef,
    postApiLogin,
    isShownHomeDialog,
    onCloseHomeDialog,
  } = useLogin();

  const {
    transitToUfc,
  } = useUfcTransition();

  return (
    <>
      <Helmet>
        <title>{t('title.login')}</title>
        <meta name="description" content={t('description.login')} />
      </Helmet>
      <LoginS>
        <ContainerS>
          {/* パンくずリスト(PC用) */}
          <BreadCrumbsWrapS>
            <BreadCrumbs crumbItems={loginBreadCrumbItems} />
          </BreadCrumbsWrapS>

          <FrameS>
            {/* ページタイトル */}
            <TitleS>
              <TitlePictureS>
                <source
                  srcSet={
                    lang === JAPANESE
                      ? `${titleJaPc} 1x, ${titleJaPc2x} 2x`
                      : lang === SIMPLE_CHINESE
                        ? `${titleZhCnPc} 1x, ${titleZhCnPc2x} 2x`
                        : lang === TRADITIONAL_CHINESE
                          ? `${titleZhTwPc} 1x, ${titleZhTwPc2x} 2x`
                          : lang === KOREAN
                            ? `${titleKoPc} 1x, ${titleKoPc2x} 2x`
                            : lang === VIETNAM
                              ? `${titleViPc} 1x, ${titleViPc2x} 2x`
                              : lang === THAILAND
                                ? `${titleThPc} 1x, ${titleThPc2x} 2x`
                                : `${titleEnPc} 1x, ${titleEnPc2x} 2x`
                  }
                  media="(min-width: 1025px)"
                />
                <TitleImageS
                  src={
                    lang === JAPANESE
                      ? titleJaSp
                      : lang === SIMPLE_CHINESE
                        ? titleZhCnSp
                        : lang === TRADITIONAL_CHINESE
                          ? titleZhTwSp
                          : lang === KOREAN
                            ? titleKoSp
                            : lang === VIETNAM
                              ? titleViSp
                              : lang === THAILAND
                                ? titleThSp
                                : titleEnSp
                  }
                  alt=""
                  height="100px"
                />
              </TitlePictureS>
            </TitleS>

            {/* エラーメッセージ */}
            {errorMessage && (
              <ErrorMessageWrapS>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </ErrorMessageWrapS>
            )}

            {/* ローディングアニメーション */}
            {isLoading && <LoaderWindow />}

            {/* ログインフォーム */}
            <FormS>
              {/* Bunny Paradise World */}
              {false && (
                <BunnyParadiseResetLinkS
                  onClick={() => transitToUfc('resetpw')}
                >
                  <NoticeText
                    className="link-password-reset"
                  >
                    {t('Bunny Paradise Worldのアカウントでもログインできますが、初回はパスワードのリセットが必要です。')}
                  </NoticeText>
                </BunnyParadiseResetLinkS>
              )}

              <InputWrapS>
                <NormalInput
                  ref={emailRef}
                  name="email"
                  type="text"
                  placeholder={t('メールアドレス')}
                />
              </InputWrapS>
              <InputWrapS>
                <NormalInput
                  ref={passwordRef}
                  name="password"
                  type="password"
                  placeholder={t('パスワード')}
                />
              </InputWrapS>

              {/* Google reCAPTCHA */}
              <GoogleRecaptchaWrapS>
                <GoogleRecaptcha />
              </GoogleRecaptchaWrapS>

              <RoundButtonWrapS>
                {isLoading ? (
                  <RoundButton
                    background="#555555"
                    borderColor="rgba(230, 230, 230, 1)"
                  >
                    {t('ログイン')}
                  </RoundButton>
                ) : (
                  <RoundButton
                    onClick={postApiLogin}
                    background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                    borderColor="rgba(0,135,167,1)"
                  >
                    {t('ログイン')}
                  </RoundButton>
                )}
              </RoundButtonWrapS>

              {/* パスワードを忘れた方 */}
              <PasswordResetLinkS
                onClick={() => transitToUfc('resetpw')}
              >
                <NoticeText
                  className="link-password-reset"
                >
                  {t('ログインができない、パスワードを忘れた方はこちら')}
                </NoticeText>
              </PasswordResetLinkS>
            </FormS>
          </FrameS>
        </ContainerS>
      </LoginS>

      {isShownHomeDialog && (
        <HomeModal onClose={onCloseHomeDialog} />
      )}
    </>
  );
};
export default Login;

/**
 *
 * Styles
 *
 */
const LoginS = styled.main`
  // background
  background-image: url(${backGroundSp});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (min-width: 1025px) {
    background-image: image-set(
      url(${backGroundPc}) 1x,
      url(${backGroundPc2x}) 2x
    );
    background-position: top;
  }
`;

const ContainerS = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 10rem;
  padding-bottom: 5rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 600px) {
    padding-top: 9rem;
  }

  @media (min-width: 1025px) {
    width: 100%;
    max-width: 100rem;
    padding-top: 2rem;
    padding-bottom: 10rem;
  }
`;

const BreadCrumbsWrapS = styled.div`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    width: 100%;
    margin-bottom: 4rem;
  }
`;

const FrameS = styled.div`
  width: 100%;
  padding: 0 1rem 2rem;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
  background-color: rgba(112, 140, 224, 0.2);
  box-shadow: 4px 4px 5px rgba(112, 140, 224, 0.3),
    -4px -4px 5px rgba(112, 140, 224, 0.3),
    -4px 4px 5px rgba(112, 140, 224, 0.3), 4px -4px 5px rgba(112, 140, 224, 0.3);
  border: 1px solid rgba(56, 109, 255, 0.7);
  border-radius: 4px;

  @media (min-width: 600px) {
    width: 90%;
    padding: 3rem 0 5rem;
  }

  @media (min-width: 1025px) {
    width: 60%;
    padding: 3rem 0 5rem;
  }
`;

const TitleS = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitlePictureS = styled.picture`
  width: min(65%, 180px);
  margin-top: max(-15%, -46px);

  @media (min-width: 600px) {
    width: 30%;
    margin-top: 0;
  }

  @media (min-width: 1025px) {
    width: 40%;
  }
`;

const TitleImageS = styled.img`
  width: 100%;
`;

const ErrorMessageWrapS = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const FormS = styled.div`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    width: 70%;
  }
`;

const InputWrapS = styled.div`
  width: 100%;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
  }
`;

const GoogleRecaptchaWrapS = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  font-size: 1rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 3rem;
  }
`;

const RoundButtonWrapS = styled.div`
  width: 70%;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1025px) {
    margin-bottom: 3rem;
    font-size: 1.6rem;
  }
`;

const BunnyParadiseResetLinkS = styled.div`
  margin-bottom: 1.2rem;
  .link-password-reset {
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-weight: 200;
    color: #fff040;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const PasswordResetLinkS = styled.div`
  .link-password-reset {
    font-size: 1.2rem;
    font-weight: 200;
    color: #fff040;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const NoticeText = styled.a`

`;
