// Utils
import { LANG } from 'utils/const/lang';
import { TOP_VIDEO } from 'utils/const/video';
// Types
import { LangType } from 'types/lang';
import { TopVideoUrlType } from 'types/video';

type UseTopVideo = () => {
  getVideoUrl: (lang: LangType) => TopVideoUrlType;
};

/**
 * TOP動画URL取得用カスタムフック
 */
export const useTopVideo: UseTopVideo = () => {
  const {
    JAPANESE, ENGLISH, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN,
  } = LANG;

  const getVideoUrl = (lang: LangType) => {
    switch (lang) {
      case ENGLISH:
        return TOP_VIDEO.VIDEO_EN;
      case JAPANESE:
        return TOP_VIDEO.VIDEO_JA;
      case SIMPLE_CHINESE:
        return TOP_VIDEO.VIDEO_ZH_CN;
      case TRADITIONAL_CHINESE:
        return TOP_VIDEO.VIDEO_ZH_TW;
      case KOREAN:
        return TOP_VIDEO.VIDEO_KO;
      default:
        return TOP_VIDEO.VIDEO_EN;
    }
  };

  return {
    getVideoUrl,
  };
};
