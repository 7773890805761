// eslint-disable-next-line
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// Custom hooks
import { useRedirect } from 'hooks/versatile/useRedirect';
// Utils
import { axiosCancelToken } from 'utils/axios';
// Types
import type { CachLocationState } from 'types/paymentType';
import {
  PointCampaignSelectType,
} from 'types/api';
import { MAXIMUM_PURCHASE_CREDIT, MINIMUM_PURCHASE_POINT } from 'utils/const/payment';

/**
 *
 * Types
 *
 */
type UsePointPurchaseCashAccount = () => {
  referrer: string;
  point: string;
  needJpy: number;
  needUsd: number;
  referenceNumber: string;
  lastName: string;
  firstName: string;
  bankName: string;
  branchName: string;
  branchCode: string;
  accountType: string;
  accountNumber: string;
  accountName: string;
  copyReferenceNumber: () => void;
  copyAccountNumber: () => void;
  goBack: () => void;
  isLoading: boolean;
  rateCc: number;
  campaigns: PointCampaignSelectType[];
};

/**
 *
 * ポイント購入（現金口座）ページ用カスタムフック
 *
 */
export const usePointPurchaseCashAccount: UsePointPurchaseCashAccount = () => {
  // locationオブジェクト
  const location = useLocation<CachLocationState | undefined>();
  // location.state代替オブジェクト
  const alternativeLocationState: CachLocationState = {
    referrer: '',
    point: '',
    needJpy: 0,
    needUsd: 0,
    commission: '0',
    referenceNumber: '',
    lastName: '',
    firstName: '',
    bankName: '',
    branchName: '',
    branchCode: '',
    accountType: '',
    accountNumber: '',
    accountName: '',
  };
  // ポイント購入ページからの取得内容
  const {
    referrer,
    needJpy,
    needUsd,
    referenceNumber,
    lastName,
    firstName,
    bankName,
    branchName,
    branchCode,
    accountType,
    accountNumber,
    accountName,
  } = location.state ?? alternativeLocationState;

  // ステート一覧
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rateCc, setRateCc] = useState<number>(0);
  const [point, setPoint] = useState<string>(MINIMUM_PURCHASE_POINT);

  // 現金購入ページへ遷移
  const history = useHistory();

  const goBack = useCallback(() => {
    history.push('/point_purchase/cash');
  }, []);
  // i18next
  const { t } = useTranslation();

  const copyReferenceNumber = () => {
    navigator.clipboard
      /* eslint-disable no-irregular-whitespace, @typescript-eslint/restrict-template-expressions */
      .writeText(`${referenceNumber}　${lastName}　${firstName}`)
      .then(() => {
        toast.success(t('コピーしました。'));
      })
      .catch(() => {
        toast.error(t('コピー失敗しました。'));
      });
  };
  const copyAccountNumber = () => {
    navigator.clipboard
      .writeText(accountNumber)
      .then(() => {
        toast.success(t('コピーしました。'));
      })
      .catch(() => {
        toast.error(t('コピー失敗しました。'));
      });
  };

  // 入金ボーナスポイントリクエスト
  const [campaigns, setCampaigns] = useState<PointCampaignSelectType[]>([]);
  const pointCampaignSelectSource = axiosCancelToken.source();

  // クリーンアップ処理
  const cleanup = () => {
    setIsLoading(false);
    setPoint('0');
    setRateCc(0);
    setCampaigns([]);
    pointCampaignSelectSource.cancel();
  };

  const { redirectToTopPage } = useRedirect();

  useEffect(() => {
    // (ポイント購入ページ・確認ページ以外からのアクセス) or ポイント情報がない=>TOPへリダイレクト
    if (referrer !== '/point_purchase/cash' || point === '') {
      redirectToTopPage();
    }

    // スクロール位置リセット
    window.scrollTo({
      top: 0,
    });

    return cleanup;
  }, []);

  return {
    referrer,
    point,
    needJpy,
    needUsd,
    referenceNumber,
    lastName,
    firstName,
    bankName,
    branchName,
    branchCode,
    accountType,
    accountNumber,
    accountName,
    copyReferenceNumber,
    copyAccountNumber,
    goBack,
    isLoading,
    rateCc,
    campaigns,
  };
};
