import { koSeo } from 'utils/lang/seo/koSeo';

/**
 * 韓国語
 */
export const ko = {
  // メタ情報
  ...koSeo,
  // ページ
  トップ: '톱',
  新規会員登録: '신규 회원 등록',
  入力内容の確認: '입력 내용 확인',
  メール認証: '이메일 인증',
  ログイン: '로그인',
  パスワードのリセット: '비밀번호 재설정',
  パスワードの再設定: '비밀번호 재설정',
  マイページ: '마이페이지',
  アカウントの停止: '계정 정지',
  メールアドレスの変更: '이메일 주소 변경',
  'ポイント出金・交換': '포인트 출금・교환',
  KYC書類アップロード: 'KYC 서류 업로드',
  "Day Lott'sとは": "Day Lott's란?",
  仕組み: '시스템',
  よくある質問: '자주 하는 질문',
  ランキング: '랭킹',
  サポート: '지원',
  お知らせ: '공지사항',
  言語: '언어',
  'NEWSはありません。': '알림은 없습니다.',
  '高額獲得者はいません。': '고액 획득자 없음.',
  '業界最高水準である、当選確率1/8も用意されているオンラインゲームです。還元率は75%!! 1ゲーム5Pからゲーム参加可能。5Pから10,000Pの当選も毎日開催されています。':
    '업계 최고 수준인 당첨 확률 1/8도 준비되어 있는 온라인 게임입니다. 환원율은 75%!!',
  "1day,1million,1richをテーマにDay Lott'sは運営をしています。毎日世界中のどこかでミリオネアがDay Lott'sから誕生しています。我々のサービスは、1日1,000,000Pの当選を目指しています。":
    "Day Lott's는 1day, 1million, 1rich를 테마로 운영하고 있습니다. Day Lott's에서는 매일 전 세계 어디선가 백만장자가 탄생하고 있습니다. 우리의 서비스는 하루 1,000,000P 당첨을 목표로 하고 있습니다.",
  "Day Lott'sでは、入金、出金の際に仮想通貨を利用することができます。仮想通貨の利用により、ボーダーレスに、そしてスピードのある入出金が可能となっております。":
    "Day Lott's에서는 입금, 출금 시에 가상통화를 이용할 수 있습니다. 가상통화 이용을 통해 장벽 없이 신속한 입출금이 가능합니다.",
  'メールアドレスを登録フォームに入力し、会員登録を行います。':
    '이메일 주소를 등록 양식에 입력하여 회원 등록을 진행합니다.',
  会員登録: '회원 등록',
  '入金・チャージ': '입금・충전',
  '仮想通貨、振込など様々な方法でポイントをチャージすることができます。仮想通貨、振込など様々な方法でポイントをチャージすることができます。':
    '신용카드, 가상통화, 송금 등 다양한 방법으로 포인트를 충전할 수 있습니다.',
  チケット購入: '티켓 구매',
  'ゲームに参加するためにチケットを、ポイントで購入します。':
    '게임에 참가하기 위해 포인트로 티켓을 구매합니다.',
  当選の確定: '당첨 확정',
  'ゲームにエントリーをし、ゲームが開始されたら、すぐに当選の確定が分かります。当選確定の演出もお楽しみください！':
    '게임에 참가하고 게임이 시작되면 바로 당첨 확정 여부를 알 수 있습니다. 당첨 확정 연출도 기대해 주세요!',
  '当選が確定したら、即座にマイページに当選ポイントが反映されます。また、そのポイントを換金して出金することができます。':
    '당첨이 확정되면 즉시 마이페이지에 당첨 포인트가 반영됩니다. 또한, 해당 포인트를 환금해서 출금할 수 있습니다.',
  // フッター
  利用規約: '이용약관',
  お問い合わせ: '문의하기',
  利用規約に同意し登録する: '<0>이용약관</0>에 동의하고 등록하기',
  '※ユーザー名は後から変更をすることはできません。': '※사용자 이름은 나중에 변경할 수 없습니다.',
  '※パスワードは大文字、小文字、数字を組み合わせて8文字以内で入力してください。': '※비밀번호는 영문 대소문자를 조합하여 8자 이내로 입력하셔야 합니다.',
  プライバシーポリシー: '개인정보보호방침',
  責任あるギャンブル: '책임 도박',
  "DayLott'sとこのウェブサイトは、Seven Eyes Ent. Company（会社番号227777/076/077および登録住所Sheetal Marg, Baluwater Kathmandu, Nepal）によって運営されています。 Seven Eyes Ent. Companyはネパール政府より発行された、Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010に基づき付与されたサブライセンスを通して正式な許可を保持しています。":
    "DayLott's and this website are operated by Seven Eyes Ent. Company. (Company number 227777/076/077 and registered address Sheetal Marg, Baluwater Kathmandu, Nepal) Seven Eyes Ent. Company holds a formal license through a sub-license granted under Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010 issued by the Government of Nepal. Seven Eyes Ent.",
  プレイヤー保護について: 'About Player Protection',
  責任のあるギャンブルポリシー: 'Responsible Gambling Policy',
  '1 .未成年者の保護': '1 .Protection of minors',
  '2 .プレイのルール': '2 .Rules of Play',
  '3 .結託': '3 .Collusion',
  '4 .カスタマーサービスと顧客からの苦情':
    '4 .Customer Service and Customer Complaints',
  '4.1.カスタマーサービス': '4.1.Customer Service',
  '4.2.苦情の提出': '4.2.Submission of Complaints',
  "4.3.DayLott'sとの書面による連絡に含める必要がある情報":
    "4.3.Information You Must Include in Your Written Communication with DayLott's",
  '1 .ギャンブルの自己評価': '1 .Gambling self-assessment',
  '2 .サポート組織': '2 .Support Organizations',
  '3 .コントロールの維持': '3 .Maintaining control',
  '3.1.自己排除': '3.1.Self-Exclusion',
  "インターネットはモバイル機器や世界中の家庭から簡単にアクセスできるため、責任のあるオンラインギャンブルは親の管理と監督に大きく依存しています。インターネット上での子供の安全を確保するため、DayLott'sは未成年者が不適切なオンライン資料にアクセスするのを防止するフィルタリングソフトウェアを使用するようにお客様に推奨します。DayLott'sでは、保護者に対して次のヒントを提供しています。":
    "Because the Internet is easily accessible from mobile devices and homes around the world, responsible online gambling relies heavily on parental control and supervision. To ensure the safety of children on the Internet, DayLott's encourages its customers to use filtering software that prevents minors from accessing inappropriate online material. DayLott's offers the following tips to parents",
  '自分のメールアドレスを安全に保つ。':
    'Keep your email address and password secure.',
  "自分のDayLott'sアカウントにログインしたときにコンピューターを無人で放置しない。":
    "Do not leave your computer unattended when you log in to your DayLott's account.",
  '18歳未満の人がいかなるギャンブル活動への参加も許可しない。':
    'Do not allow anyone under the age of 18 to participate in any gambling activity.',
  '自分のクレジット/デビットカードおよびその他の金融口座の詳細を、子供の手の届かないところに保管する。':
    'Keep your credit/debit card and other financial account details out of the reach of children.',
  'パスワードをコンピューターに保存しない。パスワードを書き留め、未成年者から離れた安全な場所に保管する。':
    'Do not store passwords on your computer. Write down passwords and keep them in a safe place away from minors.',
  "共有コンピューターからDayLott'sにアクセスする場合、特にコンピューターを子供と共有する場合は、「記憶する」機能を使用しない。":
    'When accessing DayLott\'s from a shared computer, especially if the computer is shared with children. Do not use the "remember" function when accessing DayLott\'s from a shared computer, especially if the computer is shared with children.',
  '未成年のギャンブルによって引き起こされる違法性と潜在的な損害について、自分の子供たちに教育する。':
    'Educate your children about the illegality and potential damage caused by underage gambling.',
  '未成年者によるすべての電子機器ならびにモバイルデバイスの使用には特に注意する。':
    'Be especially careful about the use of all electronic and mobile devices by minors.',
  '自分の子供がギャンブルサイトを含む不適切な題材にアクセスするのを防ぐために、フィルタリングソフトウェアを活用する。':
    'Use filtering software to prevent your children from accessing inappropriate material, including gambling sites.',
  "未成年者のギャンブルは違法であるだけでなく、監督されていない子供が負った早期のギャンブル依存と実質的なギャンブルでの借金につながる可能性があります。したがって、自分の携帯電話、タブレット、ラップトップまたはコンピューターを法定年齢に満たない友人または家族と共有してオンラインギャンブルに参加する場合、DayLott'sは以下のいずれかのサービスを使用して、ウェブサイトへのアクセスを制限することを推奨します。":
    "Gambling by minors is not only illegal, but can lead to early gambling addiction and substantial gambling debts incurred by unsupervised children. Therefore, if you share your cell phone, tablet, laptop or computer with a friend or family member who is under the legal age to participate in online gambling, DayLott's recommends that you restrict their access to the website by using one of the following services.",
  'Netnanny.com - 不適切なウェブコンテンツから子供を守るフィルタリングソフトウェア。':
    'Netnanny.com - Filtering software that protects children from inappropriate web content.',
  'Cybersitter.com - 保護者がブロックするサイトを自身で追加することができるフィルタリングソフトウェア。':
    'Cybersitter.com - Filtering software that allows parents to add their own sites to block.',
  'betterinternetforkids.eu - EUが共同出資し、インターネットフィルタソフトウェアに関する情報を提供し、無料のテスト版を提供するウェブサイト。':
    'betterinternetforkids.eu - A website co-sponsored by the EU that provides information about internet filter software and provides free test versions.',
  その他の重要なヒント: 'Other Important Tips',
  'ギャンブルをする決定は自分の個人的な選択であることを確認してください。次の場合は、ギャンブルをすることはおすすめしません。':
    'Make sure that your decision to gamble is your own personal choice. Gambling is not recommended in the following cases.',
  '毎日の責務を妨げる場合。': 'It interferes with your daily responsibilities.',
  '依存症の治療をしている、あるいは回復している場合。':
    'You are in treatment for or recovering from an addiction.',
  'アルコールやその他の物質の影響を受けている場合。':
    'You are under the influence of alcohol or other substances.',
  '以前の損失を取り戻そうとしている場合。あるいは賞金で借金を回収することが主な目的となっている場合。':
    'If you are trying to recover your previous loss. Or when the main goal is to collect the debt with your winnings.',
  "DayLott'sでゲームを始める前に、ゲームのルールを知ることをおすすめします。":
    "Before starting a game in DayLott's, it is recommended to know the rules of the game.",
  '顧客が他の顧客と結託していると思われる場合や、不正行為を行った場合は、直ちにカスタマーサービスにご連絡ください。':
    'If you suspect that a customer is colluding with another customer or has committed any fraudulent act, please contact Customer Service immediately.',
  'お客様はメールでカスタマーサービスにいつでも連絡することができます。':
    'Customers can contact customer service at any time via email.',
  "いかなる場合でも問題が発生してから3か月以内に、顧客の苦情/申し立てを提出する必要があります。お客様の苦情/申し立てが正確な部署に向けられ、調査されていることを確実にするために、以下の手段を介して書面による連絡をDayLott'sに提出しなければなりません。":
    "In any case, customer complaints/allegations must be submitted within three months of the problem occurring. In order to ensure that the customer's complaint/allegation is directed to the correct department and investigated, written communication must be submitted to DayLott's via the following means",
  電子メール: 'Email',
  "お客様のプライバシーを保護するために、お客様とDayLott's間のすべての電子メールによる通信は、DayLott'sで保持されているお客様の顧客アカウントに登録したメールアドレスを使用して実行する必要があります。そうでない場合、当社の対応が遅れることがあります。DayLott'sとの(苦情を含む) あらゆる書面による連絡には、以下の情報を含める必要があります。":
    "In order to protect your privacy, all email communications between you and DayLott's must be executed using the email address registered in your customer account maintained with DayLott's. Otherwise, our response may be delayed. Any written communication with DayLott's (including complaints) must include the following information.",
  'お客様のニックネーム/電話番号': 'Your nickname/phone number',
  お客様の顧客アカウントに登録済みの氏名:
    'Your name as it appears in your customer account',
  '苦情/申し立ての詳細': 'Details of the complaint/allegation',
  '苦情/申し立てに関連する特定の日付と時刻 (該当する場合)':
    'The specific date and time related to the complaint/allegation (if applicable)',
  "DayLott'sでは、お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。":
    "At DayLott's, we are committed to providing you with the most enjoyable gambling experience possible, but we also recognize that gambling can cause problems for some people. To ensure that you can continue to enjoy safe and manageable play, we support responsible gambling and have put in place a number of measures to assist you.",
  'ギャンブルによりお客様自身 (またはその他の人) の人生が支配されている可能性があると懸念する場合、次の質問で判明することがあるかもしれません。':
    "If you are concerned that gambling may be taking control of your (or others') life, the following questions may help you find out.",
  'つまらない日常や不幸な人生を忘れるためにギャンブルをしますか？':
    'Do you gamble to forget about your boring or unhappy life?',
  'ギャンブルをしていてお金が足りなくなったら、喪失感を味わい絶望を感じ、できるだけ早く再びギャンブルをする必要がありますか？':
    'If you are gambling and run out of money, do you feel lost and hopeless and need to gamble again as soon as possible?',
  '最後の1円がなくなるまでギャンブルをしますか？それが、たとえば家への交通費や生活費だとしてもですか？':
    'Do you gamble until your last penny is gone? Would you gamble until your last penny was gone, even if it was for, say, transportation home or living expenses?',
  'ギャンブルに費やした金額や時間の嘘をついてごまかしたことがありますか？':
    'Have you ever lied and cheated about the amount of money or time you spent on gambling?',
  'ギャンブルが原因で家族、友人、趣味への興味を失ったことがありますか？':
    'Have you ever lost interest in your family, friends, or hobbies because of gambling?',
  '負けた後、できるだけ早くもう一度挑戦して損失を勝たなければならないと感じますか？':
    'After losing, do you feel compelled to try again as soon as possible to win your losses?',
  '議論、不満、失望によりギャンブルをしたくなりますか？':
    'Do arguments, frustrations, or disappointments make you want to gamble?',
  'ギャンブルをすることが原因で落ち込む、あるいは自分自身に害を及ぼすと感じることがありますか？':
    'Do you feel depressed or harm yourself because of gambling?',
  'これらの質問に "はい" という答えが多いほど、深刻なギャンブルの問題を抱えている可能性が高くなります。':
    'The more "yes" answers you give to these questions, the more likely you are to have a serious gambling problem.',
  'この問題について相談するには、カスタマーサービスに連絡するか、無料で独立したアドバイスを提供する数々のサポート組織があります。ご相談希望の方は、自国のサポート組織・機関に問い合わせをして下さい。':
    'To discuss this issue, you can contact customer service or one of the many support organizations that offer free, independent advice. Please contact the support organization in your country if you would like to discuss your problem.',
  'お客様あるいは知り合いの方がギャンブルの問題を抱えている場合、認定された組織からの支援を検討することをおすすめします。':
    'If you or someone you know has a gambling problem, you may want to consider getting help from an accredited organization.',
  "DayLott'sでは、お客様にギャンブルアカウントの管理に積極的に関与することを推奨しています。そのために、当社はさまざまな責任のあるゲーミング機能を提供しています。":
    "DayLott's encourages our customers to take an active role in the management of their gambling accounts. To that end, we offer a variety of responsible gaming functions.",
  'もしお客様がギャンブルに対し問題が生じたと思われる場合には、カスタマーサービスへ連絡をし、アカウントへ自己排除期間を適用するように要請してください。':
    'If you believe that you have a problem with gambling, please contact Customer Service and request that a self-exclusion period be applied to your account.',
  以下の自己排除期間がご利用可能です:
    'The following self-exclusion periods are available',
  '1週間': '1 week',
  '2週間': '2 weeks',
  '1ヶ月': '1 month',
  '3ヶ月': '3 months',
  '6ヶ月': '6 months',
  "できる限り速やかに自己排除を適用するように努めますが、このプロセスにはある程度の作業時間が必要となりますのでご理解いただきますようお願いいたします。自己排除期間は実装されてお客様へ連絡されるまで、開始したものとはみなされませんのでご注意ください。もしその後も引き続きサービスにアクセス可能な場合には、責任をもってすぐ当社までご連絡ください。自己排除に入った時点でのDayLott'sの未確定ベットは、決済されます。お客様のベットが勝ちだった場合、勝ち金は該当する口座へ送金されます。自己排除期間が終了したあと、お客様のアカウントは自動的に再開されますのでご注意下さい。":
    "We will try to apply the self-exclusion as soon as possible, but please understand that this process may take some time. Please note that the self-exclusion period will not be considered to have started until you are notified. If you continue to have access to the service after that time, you are responsible for contacting us immediately. Any unsettled bets on DayLott's at the time of self-exclusion will be settled. If your bet is a win, your winnings will be transferred to the relevant account. Please note that your account will be automatically reopened after the self-exclusion period has expired.",
  "DayLott'sは開かれる可能性のある新しい重複アカウントを検出し、閉鎖するためにできる限りのことを常に行っております。自己排除が適用されると、いかなるマーケティング資料も受け取ることはなくなります。":
    "DayLott's will always do everything possible to detect and close any new duplicate accounts that may be opened. Once self-exclusion has been applied, you will no longer receive any marketing materials.",
  // フォーム
  メールアドレス: '이메일 주소',
  ユーザー名: '사용자명',
  パスワード: '비밀번호',
  'パスワード(確認用)': '비밀번호(확인용)',
  新しいパスワード: '새로운 비밀번호',
  '新しいパスワード(確認用)': '새로운 비밀번호(확인용)',
  ファイルを選択: '파일 선택',
  'ドラッグ＆ドロップするかファイルを選択してください':
    '드래그앤드롭하거나 파일을 선택해 주세요',
  // ボタン
  修正する: '수정하기',
  送信する: '전송하기',
  再送する: '다시 보내기',
  確認する: '확인하기',
  登録する: '등록하기',
  入金する: '입금하기',
  出金する: '출금하기',
  アップロード: '업로드',
  受け取る: '받기',
  削除: '삭제',
  停止: '정지',
  選択: '고르다',
  送信: '제출하다',
  戻る: '뒤로',
  設定する: '설정',
  // トッページ
  さらに表示する: '더 보기',
  // 新規会員登録ページ
  '新規会員登録(タイトル)': '신규 회원 등록',
  // 新規会員登録確認ページ
  '内容に誤りがないか、よくご確認ください。':
    '내용에 오류가 없는지 잘 확인하십시오.',
  // 新規会員登録完了ページ
  メールの送信が完了しました: '이메일 전송이 완료되었습니다',
  '以下のメールアドレスに確認メールを送りました。メール記載のURLから登録を完了してください。':
    '아래 이메일 주소로 확인 이메일을 전송했습니다. 이메일에 기재된 URL을 통해 등록을 완료해 주십시오.',
  TOPに戻る: 'TOP으로 돌아가기',
  // メール認証
  '認証に成功しました。': '인증에 성공했습니다.',
  ログインページへ: '로그인 페이지로',
  // ログイン
  'Bunny Paradise Worldのアカウントでもログインできますが、初回はパスワードのリセットが必要です。': 'Bunny Paradise World 계정으로도 로그인할 수 있지만, 첫 번째 로그인 시에는 비밀번호 재설정이 필요합니다.',
  'ログインができない、パスワードを忘れた方はこちら':
    '로그인할 수 없거나 비밀번호를 잊은 분은 여기로',
  "DayLott'sのリンクをホーム画面に追加すると次回以降簡単にアクセスができます。": "다음에 쉽게 액세스할 수 있도록 DayLott's의 링크를 홈 화면에 추가하십시오.",
  iPhoneの場合: '아이폰용',
  '1. ブラウザ(Safari)でWEBページを開いた状態で、下部の「共有アイコン」をタップ。': '1. 브라우저(Safari)에서 웹 페이지를 열고 하단의 "공유 아이콘"을 누릅니다.',
  '2. 共有メニューに表示される「ホーム画面に追加」をタップ。': '2. 공유 메뉴에서 "홈 화면에 추가"를 누릅니다.',
  '3. 名称などをご確認いただき、画面右上の「追加」をタップ。': '3. 이름 등을 확인한 후 화면 오른쪽 상단의 "추가"를 누릅니다.',
  '4. ホーム画面にアイコンが追加されたら完了です。': '4. 홈 화면에 아이콘이 추가되면 완료된 것입니다.',
  確認して閉じる: '확인 및 닫기',
  // パスワードリセット
  '登録済みのメールアドレスにパスワード再設定用のURLを送信します。':
    '등록하신 이메일 주소로 비밀번호 재설정을 위한 URL을 전송합니다.',
  パスワードのリセットを申請する: '비밀번호 재설정 신청하기',
  '再送しました。': '다시 보냈습니다.',
  // パスワードの再設定
  新しいパスワードを設定する: '새로운 비밀번호 설정하기',
  '新しいパスワードを入力した後、「設定する」を押してください。':
    "새 비밀번호를 입력한 후 '설정'을 누릅니다.",
  // パスワードの再設定(完了)
  パスワードの変更が完了しました: '비밀번호 변경이 완료되었습니다.',
  // マイページ
  'ポイント購入履歴はありません。': '포인트 구매 기록이 없습니다.',
  初回登録ボーナス: '초기 등록 보너스',
  入金ボーナス: '보증금 보너스',
  紹介ボーナス: '추천 보너스',
  クレジット購入: '신용으로 구매',
  ビットコイン購入: '비트코인으로 구매',
  振り込み購入: '현금 이체로 구매',
  テザー購入: '테더로 구매',
  イーサリアム購入: '이더리움으로 구매',
  'ポイント出金・交換履歴はありません。': '포인트 출금・구매 기록이 없습니다.',
  'べット履歴はありません。': '베팅 기록이 없습니다.',
  '当選履歴はありません。': '당첨 기록이 없습니다.',
  所持ポイント: '보유 포인트',
  生年月日: '생년월일',
  '※ポイントの出金・交換をする際にはKYC認証が必須となります。': '※포인트 출금・교환 시에는 KYC 인증이 필수입니다.',
  未設定: '미설정',
  登録情報: '등록 정보',
  KYC提出: 'KYC 제출',
  'ポイントデポジット出金・交換': '포인트 보증금 출금・교환',
  プレイ履歴: '기록',
  編集: '편집',
  ログアウト: '로그아웃',
  KYC確認中: 'KYC 확인 중',
  KYC却下: 'KYC 기각',
  書類のタイプを選択する: '서류 유형 선택하기',
  パスポート: '여권',
  免許証: '면허증',
  // アカウントの停止(確認)
  'アカウント情報、所持チケット、所持ポイント等が失われます。確認してから「停止」ボタンを押してください。':
    '계정 정보, 보유 티켓, 보유 포인트 등은 잃게 됩니다. 확인하신 후에 "정지" 버튼을 누르시기 바랍니다.',
  '※この操作は取り消しができません。': '※이 조작은 되돌릴 수 없습니다.',
  // アカウントの停止(完了)
  アカウント停止完了: '계정 정지 완료',
  'アカウントの停止が完了しました。ご利用いただきありがとうございました。':
    '계정 정지가 완료되었습니다. 이용해 주셔서 감사합니다.',
  // メールアドレスの変更
  '入力したメールアドレスにメールアドレス再設定用のURLを送信します。':
    '입력한 이메일 주소로 이메일 주소 재설정용 URL을 전송합니다.',
  // KYC書類アップロード(フォーム)
  パスポートのアップロード: '여권 업로드',
  免許証のアップロード: '면허증 업로드',
  年: '년',
  月: '월',
  日: '일',
  パスポートの写真をアップロード: '여권 사진 업로드',
  '免許証 ( 表 ) の写真をアップロード': '면허증(앞면) 사진 업로드',
  '免許証 ( 裏 ) の写真をアップロード': '면허증(뒷면) 사진 업로드',
  '1つ前に戻る': '한 단계 전으로 돌아가기',
  '画像をアップロードしてください。': '이미지를 업로드해 주세요.',
  // KYC書類アップロード(確認)
  提出内容の確認: '제출 내용 확인',
  送信内容の確認: '전송 내용 확인',
  '以下の内容で送信します。よろしければ送信を押してください。':
    '아래 내용으로 전송합니다. 괜찮으시면 전송을 눌러 주세요.',
  '免許証 ( 表 )': '면허증(앞면)',
  '免許証 ( 裏 )': '면허증(뒷면)',
  // KYC書類アップロード(完了)
  本人確認書類送信完了: '본인 확인 서류 전송 완료',
  '審査結果はメールにてお知らせいたします。今しばらくお待ちください。':
    '심사 결과는 이메일로 알려 드립니다. 잠시만 기다려주세요.',
  マイページへ戻る: '마이페이지로 돌아가기',
  // ポイント購入
  ポイント購入: '포인트 구매',
  ポイント: '포인트',
  ドル: '달러',
  円: '엔',
  レートを確認する: '환율.확인하기',
  ボーナスポイント: '보너스 포인트',
  'ボーナスポイントは購入ポイント獲得時に付与されます。': '보너스 포인트는 구매 포인트 획득 시 부여됩니다.',
  購入方法を選択: '구매 방법 선택',
  仮想通貨: '가상통화',
  クレジット: '신용카드',
  現金振込: '현금 송금',
  'ページを更新して再度お試しください。':
    '페이지를 업데이트하고 다시 시도해 주세요.',
  'データを取得できませんでした。': '데이터를 가져오지 못했습니다.',
  '最低購入ポイント数は10ポイントです。': '최저 구매 포인트는 10포인트입니다.',
  'お名前(必須)': '귀하의 이름(필수)',
  '姓(カナ)': '성(가나)',
  '名(カナ)': '이름(가나)',
  '全角カタカナで入力してください。': '전각 가타카나로 입력하십시오.',
  '口座名義は入力必須です。': '계정 소유자를 입력해야 합니다.',
  // ポイント購入（仮想通貨）
  'レート表記はページにアクセスした時点のものです。入金時の数値とは異なる場合がございます。':
    '요율 표기는 페이지를 방문한 시점입니다. 입금시의 수치와는 다른 경우가 있습니다.',
  仮想通貨で入金: '가상통화로 입금',
  送金アドレス: '송금 주소',
  アドレスを再取得: 'アドレスを再生成',
  'コピーしました。': '복사했습니다.',
  'コピー失敗しました。': '복사가 실패했습니다.',
  // ポイント購入（クレジット）
  クレジットカードで入金: '신용카드로 입금',
  有効期限: '유효기한',
  クレジットカード番号: '신용카드 번호',
  カード名義人: '카드 명의인',
  支払い回数: '할부 횟수',
  一括払い: '일시불',
  '3回払い': '3회 할부',
  '6回払い': '6회 할부',
  セキュリティコード: '보안 코드',
  '以上の内容でよろしければ「入金する」ボタンをクリックしてください。':
    '아래 내용으로 괜찮으시면 "입금하기" 버튼을 클릭해 주세요.',
  'お支払い手続きが完了いたします。': '결제 절차가 완료됩니다.',
  // ポイント購入（現金）
  現金で入金: '현금으로 입금',
  '支払額を以下の口座にお振込ください。':
    '결제 금액을 아래 계좌로 이체해주세요.',
  依頼人名: '의뢰인명',
  銀行名: '은행명',
  支店名: '지점명',
  店番号: '가게 번호',
  口座科目: '계좌 과목',
  口座番号: '계좌 번호',
  口座名義: '계좌 명의',
  // ポイント購入完了（共通）
  入金情報送信完了: '입금 정보 전송 완료',
  '確認ができ次第メールにてお知らせいたします。今しばらくお待ちください。':
    '확인되는 대로 이메일로 알려 드립니다. 잠시 기다려 주세요.',
  // ポイント出金・交換(トップ)
  '出金・交換ポイント数': '출금/교환 포인트',
  '出金・交換をするポイント数を入力してください。': '출금·교환을 하는 포인트수를 입력해 주세요.',
  ポイント残高: '포인트 잔액',
  '出金・交換方法を選択': '출금/교환 방법 선택',
  '半角数字のみ入力してください。': '반각 숫자만 입력합니다.',
  '50ポイントから出金が可能です。': '50포인트에서 출금이 가능합니다.',
  '入力ポイントが所持ポイントを超えています。': '입력 포인트가 소지 포인트를 초과합니다.',
  '送金先アドレスを入力してください。': '송금주소를 입력해주세요.',
  // ポイント出金・交換(仮想通貨)
  送金手続き: '송금 절차',
  送金内容の確認: '송금내용 확인',
  送金額: '송금액',
  送金先アドレス: '송금처 주소',
  '上記内容に間違いがないことを確認して送金をしてください。送金確定後の返金や操作の取り消しはできません。': '상기 내용에 착오가 없음을 확인하고 출금·교환합니다. 송금 확정 후에는 환불이나 조작 취소를 할 수 없습니다.',
  // ポイント出金・交換(受付完了)
  '出金・交換を受け付けました。': '출금・교환을 접수했습니다.',
  '取引に問題があった場合はメールにてご連絡を致します。またご不明な点がありましたらサポートまでお問い合わせください。': '거래에 문제가 있는 경우, 메일로 연락 드리겠습니다. 또한 궁금하신 사항이 있으시면 서포트로 문의해 주시기 바랍니다.',
  // 履歴
  べット履歴: '베팅 기록',
  当選履歴: '당첨 기록',
  ポイント購入履歴: '포인트 구매 기록',
  'ポイント出金・交換履歴': '포인트 출금・교환 기록',
  もっと見る: '더 보기',
  // DayLott'sとは
  "DayLott'sでは、毎日ミリオネアを排出する、世界初のゲームです。少額から大口まで、多彩なテーブルを用意することで、より多くの方にゲームを楽しんでもらうことができます。":
    "Day lott's에서는 매일 백만장자를 배출하는세계 최초의 게임입니다. 소액부터 거액까지 다채로운 리스트를 준비하였기에더욱 많은 분이 게임을 즐기실 수 있습니다",
  '業界最高水準の75%！！世界で１番ユーザーが獲得できるゲームになるようにこだわって作りました。そしてその割合は常に一定です。ゲームにエントリーをした全てのユーザーに平等に獲得するチャンスがあります。':
    '업계 최고의 75%!! 우리는 그것을 세계에서 가장 많은 사용자를 확보할 수 있는 게임으로 만드는 데 집착했습니다. 그리고 백분율은 항상 일정합니다. 게임에 참여하는 모든 사용자는 동일한 승률을 가집니다.',
  'ビットコイン、テザー、イーサリアムでゲームを選ぶポイントを購入することができます。':
    '비트코인, 테더, 이더리움에서게임을 즐길 포인트를 구매하실 수 있습니다.',
  '会員登録からエントリーまでたった3分で完了します。スマートフォンがあればいつでもどこでも気軽にゲームにエントリーをすることができます。':
    '회원 가입부터 참가까지 단 3분이면 완료됩니다. 스마트폰이 있으면 언제든지, 어디서나 부담 없이게임에 참가하실 수 있습니다.',
  入金はこちら: '入金はこちら',
  // 仕組み
  '世界初、必ず購入した誰かが高額当選、ミリオネアを誕生させる。':
    '세계 최초로 구매한 사람 중에 반드시 고액으로 당첨되는 사람이 나와서 백만장자가 탄생합니다.',
  '世界最高水準還元率が驚異の75%を誇るオンラインゲームです。':
    '세계 최고 수익률은 온라인 게임의 놀라운 75%입니다.',
  'エントリーするベットポイント、当選ポイントに応じたボックスが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステム。エントリーしたボックスのチケット購入数が満たされると即座に抽選が始まり、当選が確定します。世界一簡単な、世界一高額当選のできる世界一スピード感のあるオンラインゲームです。':
    '엔트리하는 베팅 포인트, 당첨 포인트에 따른 박스가 준비되어 있습니다. 룰은 티켓을 구입해서 엔트리만 하면 되는 간단한 시스템. 엔트리한 박스의 티켓 구입 수가 충족되면 즉시 추첨이 시작되어 당첨이 확정됩니다. 세상에서 가장 간단한, 세상에서 가장 고액에 당첨 가능한, 세상에서 가장 스피디한 온라인 게임입니다.',
  '対応端末 iOS 14.8以降 Android 12.0以降':
    'Supported devices iOS 14.8 or later and Android 12.0 or later',
  'チケットを購入し、抽選ページにアクセス': '티켓을 구입하여 추첨 페이지에 접속',
  '抽選演出開始され、当選演出時にご自身で登録したユーザー名が枠内に止まりましたら当選確定':
    '추첨 연출이 시작되고, 당첨 연출 시 본인이 등록한 이용자명이 테두리 안에 멈추면 당첨 확정',
  '当選確定後、当選ポイントが反映され出金が可能':
    '당첨 확정 후 당첨 포인트가 반영되어 출금 가능',
  ご購入された誰かが必ず高額当選: '구입한 누군가가 반드시 고액에 당첨',
  "ボックス毎に設定された高額当選ポイントが、ご購入された誰かに当選します。Day Lott'sではボックスに設定されている当選ポイントは必ずユーザーに反映されます。":
    "박스마다 설정된 고액 당첨 포인트가 구입한 누군가에게 당첨됩니다. Day Lott's는 박스에 설정되어 있는 당첨 포인트가 반드시 이용자에게 반영됩니다.",
  '安心・安全・透明性のある当選': '안심・안전・투명성이 있는 당첨',
  "Day Lott'sでは、透明性のある運営を心がけています。全てのユーザーが当選したユーザーを確認することができます。また、個人情報の保護、入出金におけるセキュリティの強化をしているため、安全にプレイをして頂くことができます。":
    "Day Lott's는 투명한 운영에 유념하고 있습니다. 모든 이용자가 당첨된 이용자를 확인할 수 있습니다. 또한 개인 정보 보호, 입출금에 관한 보안을 강화하고 있기 때문에 안전하게 플레이하실 수 있습니다.",
  バリエーションのある当選ポイント設定: '다채로운 당첨 금액 설정',
  'ユーザーのニーズを満たしたプレイボックスを多く用意しています。小さなべットエントリーで高額当選ポイントを獲得することも可能です。最低5ポイントからエントリーが可能となっており、最大100ミリオンポイントの当選も実現します。':
    '이용자들의 니즈를 충족하는 플레이 박스를 여럿 준비하였습니다. 적은 베팅으로 고액 당첨 포인트를 획득할 수도 있습니다. 최저 5포인트부터 엔트리가 가능하며, 최대 100밀리언 포인트 당첨도 실현됩니다.',
  // よくある質問
  出金: '출금',
  'よくある質問はありません。': '자주 묻는 질문이 없습니다.',
  // ランキング
  一日: '1일',
  週間: '주간',
  月間: '월간',
  全期間: '전 기간',
  'ランキングはありません。': '랭킹은 없습니다.',
  // サポート
  '当ページのよくある質問をまずはご覧ください。':
    '우선 본 페이지의 자주 하는 질문을 참조해 주세요.',
  メールでのお問い合わせ: '이메일로 문의',
  'までお問い合わせください。': '(으)로 문의해 주세요.',
  お問合せ時間: '문의 시간',
  '毎日対応させて頂いております。': '매일 접수합니다.',
  '※お問い合わせの回答に数日頂く事がございます。3営業日以内には回答させて頂きます。':
    '※문의 답변에 며칠이 소요될 수 있습니다. 3영업일 이내에는 답변드립니다.',
  // PLAYBOX
  予約状況: '예약 상황',
  口: '유닛',
  ゼウス: '제우스',
  キング: '왕',
  ダイヤモンド: '다이아몬드',
  ゴールド: '골드',
  シルバー: '실버',
  ブロンズ: '브론즈',
  アンバー: '호박',
  口数選択: '유닛 수 선택',
  購入する: '구매하기',
  キャンセル: '취소',
  ダイヤモンド獲得確率: '다이아몬드 획득 확률',
  ゴールド獲得確率: '골드 획득 확률',
  獲得確率: '획득 확률',
  獲得: '획득',
  確率: '확률',
  口購入: '유닛 구매',
  '所持ポイントが不足しています。': '보유 포인트가 부족합니다.',
  '購入するにはログインが必要です。': '구매하려면 로그인이 필요합니다.',
  '1度に購入できるのは2000口までです。': '한번에 구입할 수 있는 것은 2000입까지',
  // チケット購入確認
  購入内容のご確認: '구매 내용 확인',
  '下記の内容で間違いないかご確認ください。':
    '아래 내용에 오류가 없는지 확인해 주세요.',
  '「購入する」ボタンをクリックすると購入が確定します。':
    '"구매하기" 버튼을 클릭하면 구매가 확정됩니다.',
  参加ボックス: '참가 박스',
  購入ポイント数: '구매 포인트 수',
  消費ポイント数: '소비 포인트',
  所持有償ポイント数: '소지 유료 포인트',
  所持無償ポイント数: '소지 무료 포인트',
  残り有償ポイント数: '남은 유료 포인트',
  残り無償ポイント数: '남은 무료 포인트',
  // チケット購入完了
  チケット番号: '티켓 번호',
  番: '번',
  '参加中のPLAY BOX': '참가 중인 PLAY BOX',
  抽選画面へ: '추첨 화면으로',
  // お知らせ一覧
  '購入済みのチケットの抽選が開始されました。矢印をタップすると結果を見ることができます。':
    '구매한 티켓의 추첨이 시작되었습니다. 화살를 탭해서 결과를 볼 수 있습니다.',
  'お客様のランクがアップされました。': '고객님의 랭크가 올라갔습니다.',
  キャンペーン: 'キャンペーン',
  表示を閉じる: '표시를 닫기',
  // お知らせ
  'お知らせはありません。': '공지사항이 없습니다.',
  抽選の結果を見る: '추첨 결과 보기',
  抽選会場へ行く: '추첨 회장에 간다',
  マイページで確認: '마이페이지에서 확인하기',
  // お問い合わせ
  お問い合わせフォーム: '문의하기',
  'お客様からのご質問をお問い合わせフォームにて受け付けております。':
    '고객의 질문을 문의 양식을 통해 접수하고 있습니다.',
  '下記項目をご記入の上、「確認する」を押してください。':
    '아래 항목을 확인하신 후에 "확인하기"를 눌러 주세요.',
  氏名: '성명',
  お問い合わせ内容: '문의 내용',
  // お問い合わせ確認
  お問い合わせ内容の確認: '문의 내용 확인',
  // お問い合わせ受付完了
  'お問い合わせを受け付けました。': '문의를 접수하였습니다.',
  'お問い合わせありがとうございます。': '문의해 주셔서 감사합니다.',
  'お問い合わせには3営業日以内に回答させて頂きます。':
    '문의에 대해서는 3영업일 이내에 답변해 드립니다.',
  // 抽選結果
  'このページは動画が自動再生されます。よろしければ"OK"を押してください。動画を再生せずポイントを受け取る場合は"受け取る"を押してください。':
    '이 페이지에서는 동영상이 자동으로 재생됩니다. 원한다면 "OK"를 누르십시오. 동영상을 재생하지 않고 포인트를 받으려면 "받기"를 클릭하십시오.',
  Waiting: '대기 중',
  購入日時: '구매 일시',
  当選ポイント: '당첨 포인트',
  // ポイント受け取り
  獲得ポイント受け取り: '적립 포인트 받기',
  動画を再生せずに獲得した全てのポイントを受け取る: '동영상을 재생하지 않고 적립된 모든 포인트를 받으세요.',
  'ポイントを受け取りました。': '포인트를 받았습니다.',
  // 共通エラー
  通信エラー: '통신 에러',
  サーバーエラー: '서버 에러',
  // バリエーションエラー
  'は入力必須です。': '항목은 입력 필수입니다.',
  'メールアドレスは正しい形式で入力してください。':
    '이메일 주소는 올바른 형식으로 입력해 주세요.',
  'ユーザー名は半角英数字のみで入力してください。':
    '사용자 이름은 반각 영숫자로만 입력하십시오.',
  'パスワードは大文字小文字を含む半角英数字を組み合わせて入力してください。':
    '비밀번호는 대문자와 소문자를 포함한 1바이트 영숫자 조합으로 입력하십시오.',
  'パスワードが一致しません。': '비밀번호가 일치하지 않습니다.',
  '画像は.jpgか.pngをアップロードしてください。':
    '이미지는 .jpg나 .png 파일을 업로드해 주세요.',
  '金額を入れてください。': '금액을 입력해주세요.',
  '日本円での合計金額(手数料込)': '일본 엔화로 총액(수수료 포함)',
  同意: '동의하',
};
