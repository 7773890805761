import { useCallback, useState } from 'react';
// External library
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// Hooks
import { useError } from 'hooks/versatile/useError';
import { useTopVideo } from 'hooks/versatile/useTopVideo';
// Recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from 'store/atoms/userState';
import { langState } from 'store/atoms/langState';
import { isChangeLangState } from 'store/atoms/isChangeLangState';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
import { LANG } from 'utils/const/lang';
// Types
import { LangType } from 'types/lang';
import { TopVideoUrlType } from 'types/video';
import { useClear } from 'hooks/versatile/useClear';
import { AxiosError } from 'axios';
import { ApiErrorType } from 'types/api';

/**
 *
 * Types
 *
 */
type LangOption = {
  label: string;
  value: LangType;
};

/**
 *
 * Hooks
 *
 */
const {
  JAPANESE,
  ENGLISH,
  SIMPLE_CHINESE,
  TRADITIONAL_CHINESE,
  KOREAN,
  // VIETNAM,
  // THAILAND,
} = LANG;
const langOptions: LangOption[] = [
  {
    label: 'English',
    value: ENGLISH,
  },
  {
    label: '日本語',
    value: JAPANESE,
  },
  {
    label: '中文(简体)',
    value: SIMPLE_CHINESE,
  },
  {
    label: '中文(繁體)',
    value: TRADITIONAL_CHINESE,
  },
  {
    label: '한국어',
    value: KOREAN,
  },
  // {
  //   label: 'Việt Nam',
  //   value: VIETNAM,
  // },
  // {
  //   label: 'ประเทศไทย',
  //   value: THAILAND,
  // },
];

export const useHeader = (): {
  errorMessage: string;
  isMenuShow: boolean;
  isLoading: boolean;
  lang: LangType;
  langOptions: LangOption[];
  isLangOptionsShow: boolean;
  isBoxMenuShow: boolean;
  toggleMenuShow: (state: boolean) => void;
  pushToNewsUsers: () => void;
  pushToPlayHistory:() => void;
  // ログアウト
  getApiLogout: () => void;
  postApiChangeLang: (arg: LangType) => void;
  setIsLangOptionsShow: React.Dispatch<React.SetStateAction<boolean>>;
  onClickGlobeIcon: () => void;
  onClickTicketIcon: () => void;
  closeMenu: () => void;
  getVideoUrl: (lang: LangType) => TopVideoUrlType;
  cleanUpMethod: () => void;
} => {
  // モーダル表示ステート
  const [isMenuShow, setIsMenuShow] = useState<boolean>(false);
  // モーダル表示非表示切り替え
  const toggleMenuShow = useCallback((state: boolean) => {
    setIsMenuShow(state);
  }, []);

  // Hooks
  const { i18n } = useTranslation();
  const { displayErrorMessageInToast } = useError();
  const { getVideoUrl } = useTopVideo();
  // Global state
  const user = useRecoilValue(userState);
  const [lang, setLang] = useRecoilState(langState);
  const setIsChangeLang = useSetRecoilState(isChangeLangState);
  // Local state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLangOptionsShow, setIsLangOptionsShow] = useState<boolean>(false);
  const [isBoxMenuShow, setIsBoxMenuShow] = useState<boolean>(false);

  // 言語リストの表示切り替え
  const onClickGlobeIcon = () => {
    setIsLangOptionsShow((_isShow) => !_isShow);
  };

  // ページ遷移時用のメニュー閉じる
  const closeMenu = () => {
    setIsLangOptionsShow(false);
    setIsBoxMenuShow(false);
  };

  // エラー用カスタムフック
  const { errorHandler } = useError();
  const [errorMessage, setErrorMessage] = useState<string>('');

  /**
   *
   * ログアウト
   *
   */
  const { clearUserState } = useClear();
  const logoutSource = axiosCancelToken.source();
  const getApiLogout = () => {
    if (!isLoading) {
      setIsLoading(true);

      // axios POST /api/logout
      axiosClient
        .post<boolean>('/api/logout', {
          cancenToken: logoutSource.token,
        })
        .then(({ data }) => {
          if (data) clearUserState();
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);
          if (result) setErrorMessage(result);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // axios POST /api/change_lang
  const source = axiosCancelToken.source();
  const postApiChangeLang = (isLang: LangType) => {
    if (!isLoading) {
      setIsLoading(true);

      axiosClient
        .post<boolean>('/api/change_lang', {
          lang: isLang,
          cancelToken: source.token,
        })
        .then(({ data }) => {
          if (data) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            i18n.changeLanguage(isLang);
            setLang(isLang);

            if (!user.loggedIn) setIsChangeLang(true);
          }
        })
        .catch(displayErrorMessageInToast)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // historyオブジェクト
  const history = useHistory();

  // ユーザーお知らせ一覧ページ遷移
  const pushToNewsUsers = useCallback(() => {
    history.push('/news_users/index');
    setIsMenuShow(false);
  }, []);

  // PLAYBOXメニューの表示切り替え
  const onClickTicketIcon = () => {
    setIsBoxMenuShow((_isShow) => !_isShow);
  };

  const pushToPlayHistory = useCallback(() => {
    history.push({
      pathname: '/mypage',
      state: {
        initialMenu: 'history',
      },
    });
    setIsMenuShow(false);
  }, []);

  // クリーンアップ関数
  const cleanUpMethod = () => {
    setIsLoading(false);
    source.cancel();
    setIsLangOptionsShow(false);
  };

  return {
    errorMessage,
    isMenuShow,
    isLoading,
    lang,
    langOptions,
    isLangOptionsShow,
    isBoxMenuShow,
    toggleMenuShow,
    pushToNewsUsers,
    pushToPlayHistory,
    // ログアウト
    getApiLogout,
    postApiChangeLang,
    setIsLangOptionsShow,
    onClickGlobeIcon,
    onClickTicketIcon,
    closeMenu,
    getVideoUrl,
    cleanUpMethod,
  };
};
