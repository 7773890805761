import { ChangeEvent, useState, useCallback } from 'react';
// External library
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FileRejection, DropEvent } from 'react-dropzone';
import dayjs from 'dayjs';
// Custom hooks
import { useValidate } from 'hooks/versatile/useValidate';
import { useFile } from 'hooks/versatile/useFile';
// Utils
import { BreadCrumbItems, myPageBreadCrumbItems } from 'utils/breadCrumbs';
// Types
import { PageType, FileType, UploadFile } from 'types/kyc';

/**
 *
 * Types
 *
 */
type Options = {
  label: string;
  value: string;
};

type OnChangeEvent<T> = (event: ChangeEvent<T>) => void;

type UseKycUpload = () => {
  errorMessage: string;
  breadCrumbImems: BreadCrumbItems[];
  // 生年月日
  yearOptions: Options[];
  monthOptions: Options[];
  dayOptions: Options[];
  isSelectedYear: string;
  isSelectedMonth: string;
  isSelectedDay: string;
  onChangeYear: OnChangeEvent<HTMLSelectElement>;
  onChangeMonth: OnChangeEvent<HTMLSelectElement>;
  onChangeDay: OnChangeEvent<HTMLSelectElement>;
  // ファイル
  pageType: PageType;
  passport: string;
  licenseFrontSide: string;
  licenseBackSide: string;
  onChangeFile: (
    fileType: FileType,
    event: ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
  onDropFile: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => Promise<void>;
  checkPostData: () => void;
  // ルーティング
  goToMypage: () => void;
};

/**
 *
 * KYC書類アップロード用ロジック
 *
 */
export const useKycUpload: UseKycUpload = () => {
  // 選択した書類タイプを取得
  const params = useParams<{ pageType: PageType }>();
  const { pageType } = params;

  // パンくずリスト
  const getBreadCrumbItems = (): BreadCrumbItems => {
    switch (pageType) {
      case 'passport':
        return {
          path: '/kyc/upload/passport',
          name: 'パスポートのアップロード',
        };
      case 'license':
        return {
          path: '/kyc/upload/license',
          name: '免許証のアップロード',
        };
      default:
        return {
          path: '/kyc/upload/',
          name: 'Page not found.',
        };
    }
  };
  const breadCrumbImems = [
    ...myPageBreadCrumbItems,
    { ...getBreadCrumbItems() },
  ];

  // i18next
  const { t } = useTranslation();

  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // 生年月日 選択状態
  const [isSelectedYear, setIsSelectedYear] = useState<string>('');
  const [isSelectedMonth, setIsSelectedMonth] = useState<string>('');
  const [isSelectedDay, setIsSelectedDay] = useState<string>('');

  // 「年」セレクトボックス option
  const firstYear = 1930;
  const adultAge = 19;
  const nowYear = dayjs().year() - adultAge;
  const yearValues: Options[] = [...Array(nowYear - firstYear).keys()].map(
    (index) => ({
      label: String(firstYear + index),
      value: String(firstYear + index),
    }),
  );
  const yearOptions: Options[] = [...yearValues];

  // 「月」セレクトボックス option
  const lastMonth = 12;
  const monthValues: Options[] = [...new Array(lastMonth).keys()].map(
    (index) => ({
      label: String(index + 1),
      value: String(index + 1),
    }),
  );
  const monthOptions: Options[] = [...monthValues];

  // 「日」セレクトボックス option
  const [dayOptions, setDayOptions] = useState<Options[]>([]);
  const createDayOptions = (year: string, month: string) => {
    const lastDay = dayjs(`${year}-${month}`).daysInMonth();
    const day: Options[] = [...new Array(lastDay).keys()].map((index) => ({
      label: String(index + 1),
      value: String(index + 1),
    }));

    setDayOptions([...day]);
    setIsSelectedDay('');
  };

  // 「年」選択状態更新処理
  const onChangeYear = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setIsSelectedYear(value);

    // 年と月が選択されたら日option生成
    if (value && isSelectedMonth) {
      createDayOptions(value, isSelectedMonth);
    }
  };
  // 「月」選択状態更新処理
  const onChangeMonth = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setIsSelectedMonth(value);

    // 年と月が選択されたら日option生成
    if (isSelectedYear && value) {
      createDayOptions(isSelectedYear, value);
    }
  };
  // 「日」選択状態更新処理
  const onChangeDay = (event: ChangeEvent<HTMLSelectElement>) => {
    setIsSelectedDay(event.currentTarget.value);
  };

  // アップロード画像(Base64)
  const [passport, setPassport] = useState<string>('');
  const [licenseFrontSide, setLicenseFrontSide] = useState<string>('');
  const [licenseBackSide, setLicenseBackSide] = useState<string>('');

  // 画像ファイルリサイズ
  const { resizeFile } = useFile();
  const setResizeFile = async (file: File, fileType: FileType) => {
    const dataUri = await resizeFile(file);

    switch (fileType) {
      case 'passport':
        setPassport(dataUri);
        break;
      case 'license_frontside':
        setLicenseFrontSide(dataUri);
        break;
      case 'license_backside':
        setLicenseBackSide(dataUri);
        break;
      default:
    }
  };

  // バリデーション用カスタムフック
  const { imageValidate, birthdayValidate } = useValidate();
  // 画像ファイルアップロード(クリック)
  const onChangeFile = async (
    fileType: FileType,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      // アップロードファイル読み取り
      const { files } = event.currentTarget;
      if (files && files[0]) {
        // 画像バリデーション
        const imageErrorMessage = imageValidate(files[0]);
        if (imageErrorMessage) {
          throw new Error(imageErrorMessage);
        }
        // 画像リサイズ処理
        await setResizeFile(files[0], fileType);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  };

  // ドロップ用ファイルタイプチェック
  const fileTypes = ['passport', 'license_frontside', 'license_backside'];
  const isFileType = (fileType: string): fileType is FileType => fileTypes.includes(fileType);

  // 画像ファイルアップロード(ドラッグ＆ドロップ)
  const onDropFile = useCallback(
    async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent,
    ) => {
      event.stopPropagation();

      try {
        // 拒否されたファイル
        if (fileRejections.length !== 0) {
          const fileErrorMessage = fileRejections[0]?.errors[0]?.message;
          throw new Error(fileErrorMessage);
        }

        if (event.target instanceof HTMLLabelElement) {
          // 書類タイプチェック
          const targetFileType = event.target.getAttribute('for');
          if (targetFileType !== null && isFileType(targetFileType)) {
            // アップロードファイル読み取り
            if (acceptedFiles && acceptedFiles[0]) {
              // 画像バリデーション
              const imageErrorMessage = imageValidate(acceptedFiles[0]);
              if (imageErrorMessage) {
                throw new Error(imageErrorMessage);
              }
              await setResizeFile(acceptedFiles[0], targetFileType);
            }
          }
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
      }
    },
    [],
  );

  // マイページ(KYC提出)に戻る
  const history = useHistory();
  const goToMypage = () => {
    history.push({
      pathname: '/mypage',
      state: {
        initialMenu: 'kyc',
      },
    });
  };
  // KYCアップロード(確認)に進む
  const goToKycConfirm = (birthday: string, uploadFile: UploadFile) => {
    history.push({
      pathname: '/kyc/confirm',
      state: {
        pageType,
        referrer: `/kyc/upload/${pageType}`,
        birthday,
        uploadFile,
        uploadBreadCrumbItems: breadCrumbImems,
      },
    });
  };

  // 送信内容のチェック
  const checkPostData = () => {
    try {
      // 生年月日バリデーション
      const birthdayErrorMessage = birthdayValidate(
        isSelectedYear,
        isSelectedMonth,
        isSelectedDay,
      );
      if (birthdayErrorMessage) {
        throw new Error(birthdayErrorMessage);
      }
      const birthday = dayjs(
        `${isSelectedYear}-${isSelectedMonth}-${isSelectedDay}`,
      ).format('YYYY/MM/DD');

      // ファイルバリデーション
      const imageErrorMessage = t('画像をアップロードしてください。');

      // パスポート
      if (pageType === 'passport') {
        // 未アップロード
        if (passport) {
          const uploadFile: UploadFile = [
            { dataUri: passport, name: 'passport' },
          ];
          // 確認ページに遷移
          goToKycConfirm(birthday, uploadFile);
        } else {
          throw new Error(imageErrorMessage);
        }
        // 免許証
      } else if (pageType === 'license') {
        // 未アップロード
        if (licenseFrontSide && licenseBackSide) {
          const uploadFile: UploadFile = [
            { dataUri: licenseFrontSide, name: 'license_frontside' },
            { dataUri: licenseBackSide, name: 'license_backside' },
          ];
          // 確認ページに遷移
          goToKycConfirm(birthday, uploadFile);
        } else {
          throw new Error(imageErrorMessage);
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  };

  return {
    errorMessage,
    breadCrumbImems,
    // 生年月日
    yearOptions,
    monthOptions,
    dayOptions,
    isSelectedYear,
    isSelectedMonth,
    isSelectedDay,
    onChangeYear,
    onChangeMonth,
    onChangeDay,
    // ファイル
    pageType,
    passport,
    licenseFrontSide,
    licenseBackSide,
    onChangeFile,
    onDropFile,
    checkPostData,
    // ルーティング
    goToMypage,
  };
};
