import { useState, useEffect } from 'react';
// Utils
import { ORIENTATION } from 'utils/const/devices';
// Types
import { Orientation } from 'types/device';

/**
 *
 * Type
 *
 */
type UseDevice = () => {
  isSmartPhone: boolean;
  isPc: boolean;
  orientation: Orientation,
};

/**
 *
 * Hooks
 *
 */
export const useDevice: UseDevice = () => {
  // SP判定
  const [isSmartPhone, setIsSmartPhone] = useState<boolean>(false);
  const matchSp = () => {
    if (/iPhone|Android.+Mobile/.exec(navigator.userAgent) !== null) {
      return true;
    }

    return false;
  };

  // PC判定
  const [isPc, setIsPc] = useState<boolean>(false);
  const matchPc = () => {
    // PC
    if (/(iPhone|iPad|iPod|Android)/i.exec(navigator.userAgent) === null) {
      return true;
    }

    // SP・TABLET
    return false;
  };

  // デバイスの向き
  const [orientation, setOrientation] = useState<Orientation>('portrait');
  const getOrientation = () => {
    // Safari以外
    const angleExceptSafari = window.screen?.orientation?.angle;
    if (angleExceptSafari !== undefined) {
      if (angleExceptSafari === ORIENTATION.PORTRAIT) {
        setOrientation('portrait');

        return;
      }

      setOrientation('landscape');

      return;
    }

    // Safariのみ(ただしdeprecated)
    const safariOnlyAngle = window.orientation;
    if (typeof safariOnlyAngle === 'number') {
      if (safariOnlyAngle === ORIENTATION.PORTRAIT) {
        setOrientation('portrait');

        return;
      }

      setOrientation('landscape');

      return;
    }

    setOrientation('portrait');
  };

  useEffect(() => {
    // デバイス種別判定
    setIsSmartPhone(matchSp());
    setIsPc(matchPc());
    // デバイス向き取得
    getOrientation();
    // デバイスの向きが変更されたら再取得
    window.addEventListener('orientationchange', getOrientation);

    return () => window.removeEventListener('orientationchange', getOrientation);
  }, []);

  return {
    isSmartPhone,
    isPc,
    orientation,
  };
};
