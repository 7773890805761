import {
  FC, useEffect, useLayoutEffect, useState,
} from 'react';
// External library
import { useTranslation } from 'react-i18next';
import { Switch, Route, useLocation } from 'react-router-dom';
// UI Components
import Header from 'components/organisms/layouts/header/Header';
import Footer from 'components/organisms/layouts/footer/Footer';
import FooterNavSp from 'components/organisms/layouts/footer/FooterNavSp';
import TermsModal from 'components/organisms/login/TermsModal';

// Common page components
import Top from 'pages/common/Top';
import EmailSend from 'pages/common/EmailSend';
import EmailVerified from 'pages/guest/EmailVerified';
import Whats from 'pages/common/Whats';
import How from 'pages/common/How';
import Faq from 'pages/common/Faq';
import Ranking from 'pages/common/Ranking';
import Support from 'pages/common/Support';
import Responsible from 'pages/common/Responsible';
import NewsShow from 'pages/common/NewsShow';
import PasswordReset from 'pages/common/PasswordReset';
import PasswordResetForm from 'pages/common/PasswordResetForm';
import PasswordResetFinish from 'pages/common/PasswordResetFinish';
import Inquiry from 'pages/common/Inquiry';
import InquiryConfirm from 'pages/common/InquiryConfirm';
import InquiryFinish from 'pages/common/InquiryFinish';
import NotFound from 'pages/common/NotFound';
// Guest page components
import Register from 'pages/guest/Register';
import RegisterConfirm from 'pages/guest/RegisterConfirm';
import Login from 'pages/guest/Login';
import AccountDeleteFinish from 'pages/guest/AccountDeleteFinish';
// Private page components
import MyPage from 'pages/private/MyPage';
import AccountDeleteConfirm from 'pages/private/AccountDeleteConfrim';
import KycUpload from 'pages/private/KycUpload';
import KycConfirm from 'pages/private/KycConfirm';
import KycFinish from 'pages/private/KycFinish';
import EmailChange from 'pages/common/EmailChange';
import NewsUsersIndex from 'pages/private/NewsUsersIndex';
import NewsUsersShow from 'pages/private/NewsUsersShow';
import NewsUsersSkip from 'pages/private/NewsUsersSkip';
import Lottery from 'pages/private/Lottery';
import TicketPurchaseConfirm from 'pages/private/TicketPurchaseConfirm';
import TicketPurchaseFinish from 'pages/private/TicketPurchaseFinish';
import PointPurchase from 'pages/private/PointPurchase';
import PointPurchaseCc from 'pages/private/PointPurchaseCc';
import PointPurchaseCredit from 'pages/private/PointPurchaseCredit';
import PointPurchaseCash from 'pages/private/PointPurchaseCash';
import PointPurchaseCashAccount from 'pages/private/PointPurchaseCashAccount';
import PointPurchaseFinish from 'pages/private/PointPurchaseFinish';
import WithdrawalTop from 'pages/private/WithdrawalTop';
import WithdrawalCc from 'pages/private/WithdrawalCc';
import WithdrawalFinish from 'pages/private/WithdrawalFinish';
// Route components
import PrivateRoute from 'routes/PrivateRoute';
import GuestRoute from 'routes/GuestRoute';
// Custom hooks
import { useAuth } from 'hooks/versatile/useAuth';
import { useWindowDimensions } from 'hooks/versatile/useWindowDimensions';
import { useTopVideo } from 'hooks/versatile/useTopVideo';
// import { useApiTracking } from 'hooks/versatile/api/useApiTracking';
// Recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from 'store/atoms/userState';
import { langState } from 'store/atoms/langState';
import { isChangeLangState } from 'store/atoms/isChangeLangState';
import { topVideoState } from 'store/atoms/topVideoState';
// Utils
import { fetchBrowserLang } from 'utils/i18n';

/**
 *
 * Component
 *
 */
const Router: FC = () => {
  // Hooks
  const { i18n } = useTranslation();
  const { getApiUsersMe, getApiLotUnlottery } = useAuth();
  const { windowDimensions } = useWindowDimensions();
  const { getVideoUrl } = useTopVideo();
  const location = useLocation();

  // Recoil
  const setLang = useSetRecoilState(langState);
  const setTopVideo = useSetRecoilState(topVideoState);
  const user = useRecoilValue(userState);
  const isChangeLang = useRecoilValue(isChangeLangState);
  const [isShownTermsDialog, setIsShownTermsDialog] = useState(false);

  const onCloseTermsDialog = () => {
    setIsShownTermsDialog(true);
  };

  // ブラウザの言語設定
  const browserLang = fetchBrowserLang();

  useEffect(() => {
    if (user.loggedIn) {
      // 認証チェック
      getApiUsersMe();
      // 参加中BOX取得
      getApiLotUnlottery();
    } else if (!isChangeLang) {
      // 未ログイン時かつユーザーによって言語設定がされていなければブラウザの言語設定を適応
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      i18n.changeLanguage(browserLang);
      setLang(browserLang);
    }
  }, [location]);

  useLayoutEffect(() => {
    if (!isChangeLang) {
      // 未ログイン時かつユーザーによって言語設定がされていなければブラウザの設定言語動画を表示
      setTopVideo(getVideoUrl(browserLang));
    }
  }, []);

  // 広告アフィリエイト クリック回数(Day Lotts'では不要、UFCにて実施）
  // const { getApiTrack } = useApiTracking();
  useEffect(() => {
    const utmId = new URLSearchParams(location.search).get('utm_id');
    const accessToken = new URLSearchParams(location.search).get('access_token');
    const refreshToken = new URLSearchParams(location.search).get('refresh_token');
    const expiresIn = new URLSearchParams(location.search).get('expires_in');
    if (utmId) {
      localStorage.setItem('utmId', utmId);
      // getApiTrack(`?utm_id=${utmId}`)
      //   .then(({ data }) => console.log(data))
      //   .catch((e) => console.log(e));
    }
    if (accessToken) {
      localStorage.setItem('access_token', accessToken);
    }
    if (refreshToken) {
      localStorage.setItem('refresh_token', refreshToken);
    }
    if (expiresIn) {
      localStorage.setItem('expires_in', expiresIn);
    }
    if (accessToken && refreshToken) {
      console.log(accessToken);
      console.log(refreshToken);
      getApiUsersMe();
    }
  }, []);

  return (
    <>
      <Header />
      <Switch>
        {/* トップ */}
        <Route path="/" exact component={Top} />
        {/* メール送信完了 */}
        <Route path="/email_send" sensitive component={EmailSend} />
        {/* メール認証 */}
        <Route
          path="/email_verified"
          sensitive
          component={EmailVerified}
        />
        {/* DayLottsとは */}
        <Route path="/whats" sensitive component={Whats} />
        {/* 仕組み */}
        <Route path="/how" sensitive component={How} />
        {/* よくある質問 */}
        <Route path="/faq" sensitive component={Faq} />
        {/* ランキング */}
        <Route path="/ranking" sensitive component={Ranking} />
        {/* サポート */}
        <Route path="/support" sensitive component={Support} />
        {/* 責任あるギャンブル */}
        <Route path="/responsible" sensitive component={Responsible} />
        {/* サービスお知らせ詳細 */}
        <Route path="/news/show/:id" sensitive component={NewsShow} />
        {/* お問合せ */}
        <Route path="/inquiry" exact sensitive component={Inquiry} />
        {/* お問合せ確認 */}
        <Route path="/inquiry/confirm" sensitive component={InquiryConfirm} />
        {/* お問合せ完了 */}
        <Route path="/inquiry/finish" sensitive component={InquiryFinish} />
        {/* パスワードリセット申請 */}
        <Route
          path="/password_reset"
          exact
          sensitive
          component={PasswordReset}
        />
        {/* パスワードリセットフォーム */}
        <Route
          path="/password_reset/form/:token"
          sensitive
          component={PasswordResetForm}
        />
        {/* パスワードリセット完了 */}
        <Route
          path="/password_reset/finish"
          sensitive
          component={PasswordResetFinish}
        />
        {/* 新規会員登録フォーム */}
        <GuestRoute path="/register" exact sensitive component={Register} />
        {/* 新規会員登録確認 */}
        <GuestRoute
          path="/register/confirm"
          sensitive
          component={RegisterConfirm}
        />
        {/* ログイン */}
        <GuestRoute path="/login" sensitive component={Login} />
        {/* アカウント削除(完了) */}
        <GuestRoute
          path="/act/dle/finish"
          sensitive
          component={AccountDeleteFinish}
        />
        {/* マイページ */}
        <PrivateRoute path="/mypage" sensitive component={MyPage} />
        {/* アカウント削除(確認) */}
        <PrivateRoute
          path="/act/dle/confirm"
          exact
          sensitive
          component={AccountDeleteConfirm}
        />
        {/* KYC書類アップロード */}
        <PrivateRoute
          path="/kyc/upload/:pageType"
          sensitive
          component={KycUpload}
        />
        {/* KYC書類アップロード確認 */}
        <PrivateRoute path="/kyc/confirm" sensitive component={KycConfirm} />
        {/* KYC書類アップロード完了 */}
        <PrivateRoute path="/kyc/finish" sensitive component={KycFinish} />
        {/* メールアドレス変更 */}
        <PrivateRoute path="/email_change" sensitive component={EmailChange} />
        {/* ポイント購入 */}
        <PrivateRoute
          path="/point_purchase"
          exact
          sensitive
          component={PointPurchase}
        />
        {/* ポイント購入フォーム(仮想通貨) */}
        <PrivateRoute
          path="/point_purchase/cc"
          exact
          sensitive
          component={PointPurchaseCc}
        />
        {/* ポイント購入フォーム(クレジット) */}
        <PrivateRoute
          path="/point_purchase/credit"
          sensitive
          component={PointPurchaseCredit}
        />
        {/* ポイント購入フォーム(現金) */}
        <PrivateRoute
          path="/point_purchase/cash"
          exact
          sensitive
          component={PointPurchaseCash}
        />
        {/* ポイント購入フォーム(現金講座) */}
        <PrivateRoute
          path="/point_purchase/cash/account"
          exact
          sensitive
          component={PointPurchaseCashAccount}
        />
        {/* ポイント購入完了 */}
        <PrivateRoute
          path="/point_purchase/finish"
          sensitive
          component={PointPurchaseFinish}
        />
        {/* 出金ページ(TOP) */}
        <PrivateRoute
          path="/withdrawal"
          exact
          sensitive
          component={WithdrawalTop}
        />
        {/* 出金ページ(仮想通貨) */}
        <PrivateRoute
          path="/withdrawal/cc"
          sensitive
          component={WithdrawalCc}
        />
        {/* 出金ページ(受付完了) */}
        <PrivateRoute
          path="/withdrawal/finish"
          sensitive
          component={WithdrawalFinish}
        />
        {/* ユーザーお知らせ一覧 */}
        <PrivateRoute
          path="/news_users/index"
          sensitive
          component={NewsUsersIndex}
        />
        {/* ユーザーお知らせ詳細 */}
        <PrivateRoute
          path="/news_users/show/:id"
          sensitive
          component={NewsUsersShow}
        />
        {/* 獲得ポイント受け取り */}
        <PrivateRoute
          path="/news_users/skip"
          sensitive
          component={NewsUsersSkip}
        />
        {/* 抽選結果 */}
        <PrivateRoute path="/lottery" sensitive component={Lottery} />
        {/* ロト購入確認 */}
        <PrivateRoute
          path="/ticket_purchase/confirm"
          sensitive
          component={TicketPurchaseConfirm}
        />
        {/* ロト購入完了 */}
        <PrivateRoute
          path="/ticket_purchase/finish"
          sensitive
          component={TicketPurchaseFinish}
        />
        {/* 404 */}
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      {/* タブレット以下のウィンドウサイズで表示 */}
      {windowDimensions.width < 1025 && location.pathname !== '/lottery/' && (
        <FooterNavSp />
      )}
      {(!isShownTermsDialog && location.pathname === '/') && (
        <TermsModal onAgree={onCloseTermsDialog} />
      )}
    </>
  );
};

export default Router;
