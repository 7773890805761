import { useCallback } from 'react';
// External library
import { useHistory } from 'react-router-dom';

/**
 *
 * Types
 *
 */
type UseRedirect = () => {
  redirectToTopPage: () => void;
};

/**
 *
 * Hooks
 *
 */
export const useRedirect: UseRedirect = () => {
  const history = useHistory();
  const redirectToTopPage = useCallback(() => {
    history.replace('/');
  }, []);

  return {
    redirectToTopPage,
  };
};
