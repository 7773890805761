import { useState } from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
import { STATUS_CODE } from 'utils/const/statusCode';
// Types
import { ApiErrorType } from 'types/api';

/**
 *
 * Types
 *
 */
type LocationState = {
  referrer: string;
  name: string;
  email: string;
  body: string;
};

/**
 *
 * お問合せ内容確認ページ用ロジック
 *
 */
export const useInquiryConfirm = (): {
  isLoading: boolean;
  referrer: string;
  email: string;
  name: string;
  body: string;
  errorMessage: string;
  redirectToInquiry: () => void;
  toBackInquiry: () => void;
  postApiInquirySend: () => void;
  cleanUpMethod: () => void;
} => {
  // historyオブジェクト
  const history = useHistory();
  // お問合せフォームへリダイレクト
  const redirectToInquiry = () => {
    history.replace('/inquiry');
  };
  // お問合せフォームへ遷移
  const toBackInquiry = () => {
    history.push('/inquiry');
  };

  // locationオブジェクト
  const location = useLocation<LocationState>();
  // location.state代替オブジェクト
  const alternativeLocationState: LocationState = {
    referrer: '',
    name: '',
    email: '',
    body: '',
  };
  // フォームからの入力内容
  const {
    referrer, name, email, body,
  } = location.state ?? alternativeLocationState;

  // ローディングフラグ
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // エラー用カスタムフック
  const { errorHandler } = useError();
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // axiosキャンセルトークン
  const axiosSource = axiosCancelToken.source();
  // お問合せ送信リクエスト
  const postApiInquirySend = () => {
    if (!isLoading) {
      setIsLoading(true);

      // axios POST /api/inquiry/send
      axiosClient
        .post<boolean>('/api/inquiry/send', {
          name,
          email,
          body,
          cancelToken: axiosSource.token,
        })
        .then(({ data }) => {
          if (data) {
            // 完了ページに遷移
            history.push({
              pathname: '/inquiry/finish',
              state: {
                referrer: '/inquiry/confirm',
              },
            });
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    setIsLoading(false);
    setErrorMessage('');
    axiosSource.cancel();
  };

  return {
    isLoading,
    referrer,
    name,
    email,
    body,
    errorMessage,
    redirectToInquiry,
    toBackInquiry,
    postApiInquirySend,
    cleanUpMethod,
  };
};
