import { memo } from 'react';
// External library
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Components
import RoundButton from 'components/atoms/button/RoundButton';
import NeonShadowBorder from 'components/atoms/border/NeonShadowBorder';
import DepositButton from 'components/atoms/button/DepositButton';
import WithdrawalOnButton from 'components/atoms/button/WithdrawalOnButton';
import WithdrawalOffButton from 'components/atoms/button/WithdrawalOffButton';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
import { userPointDisplayState } from 'store/selectors/userPointDisplayState';
// Hooks
import { useRegisterInformation } from 'hooks/pages/private/useRegisterInformation';
// Utils
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';
// Images
import checkOnIconSp from 'assets/images/mypage/check_on_sp.png';
import checkOnIconPc from 'assets/images/mypage/check_on_pc.png';
import checkOnIconPc2x from 'assets/images/mypage/check_on_pc@2x.png';
import checkOffIconSp from 'assets/images/mypage/check_off_sp.png';
import checkOffIconPc from 'assets/images/mypage/check_off_pc.png';
import checkOffIconPc2x from 'assets/images/mypage/check_off_pc@2x.png';

const { tabletPortrait, tabletLandscape, pc } = devices;
const {
  JAPANESE,
  ENGLISH,
  KOREAN,
  SIMPLE_CHINESE,
  TRADITIONAL_CHINESE,
  VIETNAM,
  THAILAND,
} = LANG;

/**
 *
 * Types
 *
 */
type Props = {
  kycFlag: boolean;
  ticket: number;
  goToPage: () => void;
};

/**
 *
 * Component
 *
 */
const RegisterInformation = memo(({ kycFlag, ticket, goToPage }: Props) => {
  // Hooks
  const { t } = useTranslation();
  const {
    name, email, user,
  } = useRegisterInformation();

  // Recoil
  const lang = useRecoilValue(langState);
  const point = useRecoilValue(userPointDisplayState);

  return (
    <RegisterInformationS>
      <UserInfomationS>
        <ContainerS>
          <UserS>
            <NameS>{name}</NameS>
            <GradientBorderPcS />
            <EmailS>{email}</EmailS>
          </UserS>

          <GradientBorderSpS />

          <PointS>
            <TextWrapS>
              <TextS>{t('所持ポイント')}</TextS>
            </TextWrapS>
            <PointTextWrapS>
              <PointTextS>
                {point}
                <PointUnitS>&nbsp;P</PointUnitS>
              </PointTextS>
            </PointTextWrapS>
            <NeonShadowBorderS>
              <NeonShadowBorder />
            </NeonShadowBorderS>
            {ticket > 0 && (
              <NoticeTextS>
                {lang === JAPANESE && (
                  `あと${ticket}枚チケットを購入すると出金を行うことができます。`
                )}
                {lang === ENGLISH && (
                  `${ticket} more tickets can be purchased to make withdrawals.`
                )}
                {lang === KOREAN && (
                  `${ticket}장의 티켓을 추가로 구매하여 출금할 수 있습니다.`
                )}
                {lang === SIMPLE_CHINESE && (
                  `可以再购买${ticket}张门票进行提款。`
                )}
                {lang === TRADITIONAL_CHINESE && (
                  `可以再購買${ticket}張門票進行提款。`
                )}
                {lang === VIETNAM && (
                  `Có thể mua thêm ${ticket} vé để rút tiền.`
                )}
                {lang === THAILAND && (
                  `สามารถซื้อบัตรได้อีก ${ticket} ใบสำหรับการถอน`
                )}
              </NoticeTextS>
            )}
            <ButtonGroupS>
              <Link className="link" to="/point_purchase">
                <DepositButton />
              </Link>
              {kycFlag && ticket === 0 ? (
                <ButtonWrapS onClick={goToPage}>
                  <WithdrawalOnButton />
                </ButtonWrapS>
              ) : (
                <ButtonWrapS>
                  <WithdrawalOffButton />
                </ButtonWrapS>
              )}
            </ButtonGroupS>
          </PointS>
        </ContainerS>
      </UserInfomationS>

      <UserEditS>
        {user.map((item) => (
          <EditListS key={uuidv4()}>
            <ListHeaderS>
              <LabelS>
                <LabelTextS>{t(item.label)}</LabelTextS>
                <LabelCheckS>
                  {item.checked ? (
                    <>
                      <source
                        srcSet={`${checkOnIconPc} 1x, ${checkOnIconPc2x} 2x`}
                        media="(min-width: 600px)"
                      />
                      <LabelCheckImageS srcSet={`${checkOnIconSp}`} alt="" />
                    </>
                  ) : (
                    <>
                      <source
                        srcSet={`${checkOffIconPc} 1x, ${checkOffIconPc2x} 2x`}
                        media="(min-width: 600px)"
                      />
                      <LabelCheckImageS srcSet={`${checkOffIconSp}`} alt="" />
                    </>
                  )}
                </LabelCheckS>
              </LabelS>
              {item.edit && (
                <Link className="link" to={item.editLink}>
                  <RoundButton
                    background="linear-gradient(rgba(0,71,106,1), 90%, rgba(0,150,110,0.9))"
                    borderColor="rgba(0,150,110,1)"
                  >
                    {t('編集')}
                  </RoundButton>
                </Link>
              )}
            </ListHeaderS>

            <BorderS />

            <ListBodyS>
              <ListBodyTextS>{item.data}</ListBodyTextS>
            </ListBodyS>
          </EditListS>
        ))}
      </UserEditS>
    </RegisterInformationS>
  );
});
export default RegisterInformation;

/**
 *
 * Styles
 *
 */
const RegisterInformationS = styled.div`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserInfomationS = styled.div`
  display: none;

  @media ${tabletLandscape}, ${pc} {
    display: block;
    width: 100%;
    margin-bottom: 3rem;
    padding: 0;
    background-image: unset;
  }
`;

const ContainerS = styled.div`
  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    margin: 0 auto;
    // flex
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const UserS = styled.div`
  @media ${tabletLandscape}, ${pc} {
    width: 40%;
    // flex
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const NameS = styled.p`
  @media ${tabletLandscape}, ${pc} {
    font-size: 4rem;
    word-break: break-all;
    margin-bottom: 1rem;
  }
`;

const EmailS = styled.p`
  @media ${tabletLandscape}, ${pc} {
    font-size: 2rem;
    word-break: break-all;
  }
`;

const GradientBorderS = styled.hr`
  width: 100%;
  height: 2px;
  margin-bottom: 1.6rem;
  background: linear-gradient(45deg, #ff17e4, #5e05c6);
  color: transparent;
`;

const GradientBorderSpS = styled(GradientBorderS)`
  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;

const GradientBorderPcS = styled(GradientBorderS)`
  display: none;

  @media ${tabletLandscape}, ${pc} {
    display: block;
  }
`;

const PointS = styled.div`
  @media ${tabletLandscape}, ${pc} {
    width: 55%;
    // flex
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TextWrapS = styled.div`
  @media ${tabletLandscape}, ${pc} {
    width: 90%;
    margin-bottom: 1rem;
    // flex
    display: flex;
    justify-content: start;
    align-items: center;
  }
`;

const TextS = styled.p`
  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
    word-break: break-all;
  }
`;

const PointTextWrapS = styled.div`
  @media ${tabletLandscape}, ${pc} {
    width: 90%;
    // flex
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const NeonShadowBorderS = styled.div`
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const PointTextS = styled.p`
  @media ${tabletLandscape}, ${pc} {
    font-size: 6rem;
    word-break: break-all;
  }
`;

const PointUnitS = styled.span`
  @media ${tabletLandscape}, ${pc} {
    font-size: 4rem;
  }
`;

const NoticeTextS = styled.p`
  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    font-size: 1.6rem;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
`;

const ButtonGroupS = styled.div`
  width: 100%;
  // flex
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link {
    width: 100%;
  }
`;

const ButtonWrapS = styled.button`
  width: 100%;
`;

const UserEditS = styled.ul`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletLandscape}, ${pc} {
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.7);
    // flex
    flex-direction: row;
    align-items: stretch;
    gap: 1rem;
  }
`;

const EditListS = styled.li`
  width: 100%;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${tabletLandscape}, ${pc} {
    background-color: rgba(90, 100, 255, 0.3);
    margin: 0;
    padding: 1.4rem 2rem 2rem;
  }

  .link {
    font-size: 1.2rem;

    @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
      font-size: 1.4rem;
    }
  }
`;

const ListHeaderS = styled.div`
  margin-bottom: 1rem;
  // flex
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LabelS = styled.div`
  // flex
  display: flex;
  align-items: center;
`;

const LabelTextS = styled.p`
  font-size: 1.4rem;

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const LabelCheckS = styled.picture`
  height: 25px;
  margin-left: 1rem;
`;

const LabelCheckImageS = styled.img`
  height: 100%;
`;

const BorderS = styled.hr`
  margin-bottom: 1rem;

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 2rem;
  }
`;

const ListBodyS = styled.div`
  width: 100%;
`;

const ListBodyTextS = styled.p`
  font-size: 1.6rem;
  word-break: break-all;
`;
