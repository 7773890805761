import { FC, ReactNode, memo } from 'react';
// External library
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import NeonShadowBorder from 'components/atoms/border/NeonShadowBorder';
import DepositButton from 'components/atoms/button/DepositButton';
import WithdrawalOnButton from 'components/atoms/button/WithdrawalOnButton';
import WithdrawalOffButton from 'components/atoms/button/WithdrawalOffButton';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
import { userPointDisplayState } from 'store/selectors/userPointDisplayState';
// Utils
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';

const { tabletLandscape, pc } = devices;
const {
  JAPANESE,
  ENGLISH,
  KOREAN,
  SIMPLE_CHINESE,
  TRADITIONAL_CHINESE,
  VIETNAM,
  THAILAND,
} = LANG;

/**
 *
 * Types
 *
 */
type Props = {
  kycFlag: boolean;
  ticket: number;
  goToPage: () => void;
  children: ReactNode;
};

/**
 *
 * Component
 *
 */
const Deposit: FC<Props> = memo(({
  kycFlag,
  ticket,
  goToPage,
  children,
}) => {
  // Hooks
  const { t } = useTranslation();
  // Recoil
  const point = useRecoilValue(userPointDisplayState);
  const lang = useRecoilValue(langState);

  return (
    <DepositS>
      <PointS>
        <TextWrapS>
          <TextS>{t('所持ポイント')}</TextS>
        </TextWrapS>
        <PointTextWrapS>
          <PointTextS>
            {point}
            <PointUnitS>&nbsp;P</PointUnitS>
          </PointTextS>
        </PointTextWrapS>
        <NeonShadowBorderS>
          <NeonShadowBorder />
        </NeonShadowBorderS>
        {ticket > 0 && (
          <NoticeTextS>
            {lang === JAPANESE && (
              `あと${ticket}枚チケットを購入すると出金を行うことができます。`
            )}
            {lang === ENGLISH && (
              `${ticket} more tickets can be purchased to make withdrawals.`
            )}
            {lang === KOREAN && (
              `${ticket}장의 티켓을 추가로 구매하여 출금할 수 있습니다.`
            )}
            {lang === SIMPLE_CHINESE && (
              `可以再购买${ticket}张门票进行提款。`
            )}
            {lang === TRADITIONAL_CHINESE && (
              `可以再購買${ticket}張門票進行提款。`
            )}
            {lang === VIETNAM && (
              `Có thể mua thêm ${ticket} vé để rút tiền.`
            )}
            {lang === THAILAND && (
              `สามารถซื้อบัตรได้อีก ${ticket} ใบสำหรับการถอน`
            )}
          </NoticeTextS>
        )}
        <ButtonGroupS>
          <Link className="link" to="/point_purchase">
            <DepositButton />
          </Link>
          {kycFlag && ticket === 0 ? (
            <ButtonWrapS onClick={goToPage}>
              <WithdrawalOnButton />
            </ButtonWrapS>
          ) : (
            <ButtonWrapS>
              <WithdrawalOffButton />
            </ButtonWrapS>
          )}
        </ButtonGroupS>
      </PointS>
      {/* コンテンツ */}
      {children}
    </DepositS>
  );
});
export default Deposit;

/**
 *
 * Styles
 *
 */
const DepositS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PointS = styled.div`
  display: none;

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    margin: 0 auto 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TextWrapS = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: start;
  align-items: center;
`;

const TextS = styled.p`
  font-size: 2.4rem;
  word-break: break-all;
`;

const PointTextWrapS = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NeonShadowBorderS = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

const PointTextS = styled.p`
  font-size: 8rem;
  letter-spacing: 1px;
  word-break: break-all;
`;

const PointUnitS = styled.span`
  font-size: 4rem;
`;

const NoticeTextS = styled.p`
  width: 100%;
  font-size: 2rem;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 3rem;
`;

const ButtonGroupS = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link {
    width: 100%;
  }
`;

const ButtonWrapS = styled.button`
  width: 100%;
`;
