import { useRef, useState, RefObject } from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
// Custom hooks
import { useValidate } from 'hooks/versatile/useValidate';
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
import {
  loginBreadCrumbItems,
  myPageBreadCrumbItems,
  passwordResetBreadCrumbItems,
} from 'utils/breadCrumbs';
// Types
import { ApiErrorType } from 'types/api';

/**
 *
 * パスワードリセット申請用ロジック
 *
 */
export const usePasswordReset = (): {
  errorMessage: string;
  isLoading: boolean;
  breadCrumbItems: { path: string; name: string }[];
  emailRef: RefObject<HTMLInputElement>;
  postApiForgetPassword: () => void;
  cleanUpMethod: () => void;
} => {
  // ローディングフラグ
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');
  // エラー用カスタムフック
  const { errorHandler } = useError();

  // Eメール
  const emailRef = useRef<HTMLInputElement>(null);

  // historyオブジェクト
  const history = useHistory();
  // locationオブジェクト
  const location = useLocation();
  const { hash } = location;

  // パンくずリスト
  const breadCrumbItems = hash === '#mypage'
    ? [...myPageBreadCrumbItems, ...passwordResetBreadCrumbItems]
    : [...loginBreadCrumbItems, ...passwordResetBreadCrumbItems];

  // バリデーション用カスタムフック
  const { emailValidate } = useValidate();
  // axiosキャンセルトークン
  const axiosSource = axiosCancelToken.source();
  // パスワードリセット申請リクエスト
  const postApiForgetPassword = () => {
    if (!isLoading) {
      setIsLoading(true);

      // Eメールバリデーション
      let email = '';
      if (emailRef.current !== null) {
        const result = emailValidate(emailRef.current.value);
        if (result) {
          setErrorMessage(result);
          setIsLoading(false);

          return;
        }

        email = emailRef.current.value;
      }

      // axios POST /api/forget_password
      axiosClient
        .post<boolean>('/api/forget_password', {
          email,
          cancelToken: axiosSource.token,
        })
        .then(({ data }) => {
          if (data) {
            // 完了ページに遷移
            history.push({
              pathname: '/email_send',
              state: {
                referrer: '/password_reset',
                email,
              },
            });
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // クリーンアップ関数
  const cleanUpMethod = () => {
    setErrorMessage('');
    setIsLoading(false);
    axiosSource.cancel();
  };

  return {
    errorMessage,
    isLoading,
    emailRef,
    breadCrumbItems,
    postApiForgetPassword,
    cleanUpMethod,
  };
};
