import { FC, ReactNode, memo } from 'react';
// External library
import styled from 'styled-components';
// Components
import BreadCrumbs from 'components/atoms/breadCrumbs/BreadCrumbs';

/**
 *
 * Types
 *
 */
type Props = {
  children: ReactNode;
  breadCrumbItems: {
    path: string;
    name: string;
  }[];
  backGroundSp: string;
  backGroundPc: string;
  backGroundPc2x: string;
  title: string;
  titleStyle: 'gold' | 'silver';
};
type BackGround = Pick<
  Props,
  'backGroundSp' | 'backGroundPc' | 'backGroundPc2x'
>;

/**
 *
 * Component
 *
 */
const FormLayout: FC<Props> = memo((props) => {
  const {
    children,
    breadCrumbItems,
    backGroundSp,
    backGroundPc,
    backGroundPc2x,
    title,
    titleStyle,
  } = props;

  const backGround = `linear-gradient(
    0deg,
    ${titleStyle === 'gold' ? `
        #FFE99B,
        #A8861F,
        #FFE99B,
        #FFFFFF,
        #FFE99B
      `
    : `
        #CFD4DE,
        #8390AA,
        #FCFCFD,
        #E0E3E9,
        #CCD1DC
      `
})`;

  return (
    <FormLayoutS
      backGroundSp={backGroundSp}
      backGroundPc={backGroundPc}
      backGroundPc2x={backGroundPc2x}
    >
      <ContainerS>
        {/* パンくずリスト(PC用) */}
        <BreadCrumbsWrapS>
          <BreadCrumbs crumbItems={breadCrumbItems} />
        </BreadCrumbsWrapS>

        {/* ページタイトル */}
        <TitleS>
          <SquareS backGround={backGround} />
          <TextS backGround={backGround}>{title}</TextS>
          <SquareS backGround={backGround} />
        </TitleS>

        {/* コンテンツ */}
        {children}
      </ContainerS>
    </FormLayoutS>
  );
});
export default FormLayout;

/**
 *
 * Styles
 *
 */
const FormLayoutS = styled.main<BackGround>`
  // background
  background-image: url(${({ backGroundSp }) => backGroundSp});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (min-width: 1025px) {
    background-image: image-set(
      url(${({ backGroundPc }) => backGroundPc}) 1x,
      url(${({ backGroundPc2x }) => backGroundPc2x}) 2x
    );
    background-position: top;
  }
`;

const ContainerS = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @media (min-width: 600px) {
    padding-top: 12rem;
  }

  @media (min-width: 1025px) {
    width: 100%;
    max-width: 100rem;
    padding-top: 2rem;
    padding-bottom: 10rem;
  }
`;

const BreadCrumbsWrapS = styled.div`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    width: 100%;
    margin-bottom: 6rem;
  }
`;

const TitleS = styled.div`
  // flex
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SquareS = styled.div<{ backGround: string }>`
  width: min(5.4vw, 2.4rem);
  height: min(5.4vw, 2.4rem);
  transform: skew(-40deg, 0deg);
  background: ${({ backGround }) => backGround};

  @media (min-width: 600px) {
    width: 1.8rem;
    height: 1.8rem;
  }

  @media (min-width: 1025px) {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

const TextS = styled.p<{ backGround: string }>`
  margin: 0 1.2rem;
  font-size: min(5vw, 2.4rem);
  font-weight: bold;
  word-break: break-all;
  color: transparent;
  background: ${({ backGround }) => backGround};
  background-clip: text;
  -webkit-background-clip: text;

  @media (min-width: 600px) {
    margin: 0 1.4rem;
    font-size: 2.4rem;
  }

  @media (min-width: 1025px) {
    font-size: 2.6rem;
    margin: 0 1.6rem;
  }
`;
