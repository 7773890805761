import { FC, memo } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Component
 *
 */
const EqualIcon: FC = memo(() => (
  <EqualIconS>
    <EqualS>
      <ShadowS />
      <BorderS />
    </EqualS>
    <EqualS>
      <ShadowS />
      <BorderS />
    </EqualS>
  </EqualIconS>
));
export default EqualIcon;

/**
 *
 * Styles
 *
 */
const EqualIconS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EqualS = styled.div`
  width: 25%;
  min-width: 20px;
  position: relative;

  display: flex;
  align-items: center;

  &:last-child {
    margin-top: 2rem;
  }

  @media (min-width: 600px) {
    width: 15%;
  }
`;

const ShadowS = styled.div`
  height: 10px;
  width: 100%;
  position: absolute;
  top: -2.5px;
  left: 0;
  z-index: 0.8;
  background: linear-gradient(45deg, #f502fd, #f500ff);
  border-radius: 9999px;
  filter: blur(3px);
`;

const BorderS = styled.div`
  width: 99%;
  position: absolute;
  top: 1px;
  left: 0.5%;
  z-index: 0.9;
  background-color: #ffffff;
  border: 1.5px solid #ffffff;
  border-radius: 9999px;
`;
