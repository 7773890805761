import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { ATOM_KEYS } from 'utils/const/recoilKeys';
import { LANG } from 'utils/const/lang';
import { LangType } from 'types/lang';

// 永続化用Recoilプラグイン
const { persistAtom } = recoilPersist();

export const langState = atom<LangType>({
  key: ATOM_KEYS.LANG_STATE,
  default: LANG.JAPANESE,
  effects_UNSTABLE: [persistAtom],
});
