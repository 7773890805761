import { FC, memo } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Props
 *
 */
type Props = {
  rotate: string;
};

/**
 *
 * Component
 *
 */
const ArrowIcon: FC<Props> = memo((props) => {
  const { rotate } = props;

  return <ArrowIconS rotate={rotate} />;
});
export default ArrowIcon;

/**
 *
 * Styles
 *
 */
const ArrowIconS = styled.div<{ rotate: string }>`
  display: inline-block;
  width: 50%;
  height: 50%;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  transform: rotate(${({ rotate }) => rotate});
`;
