import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Image
import PlayBoxBgSp from 'assets/images/top/footerMenu/menu_playbox_sp.png';
// Types
import { PlayBoxId } from 'types/playBoxType';
// Images
import dayLotts1000Icon from 'assets/images/top/playBox/daylotts1000_icon.svg';
import dayLotts5000Icon from 'assets/images/top/playBox/daylotts5000_icon.svg';
import dayLotts10000Icon from 'assets/images/top/playBox/daylotts10000_icon.svg';

/**
 *
 * Types
 *
 */
type BoxInfo = {
  playBoxId: PlayBoxId;
  imageUrl: string;
};
type Props = {
  pushToBox: (playBoxId: PlayBoxId) => void;
};

/**
 *
 * Component
 *
 */
// PLAYBOX用id属性、画像
const playBoxList: BoxInfo[] = [
  {
    playBoxId: 'dayLotts1000',
    imageUrl: dayLotts1000Icon,
  },
  {
    playBoxId: 'dayLotts5000',
    imageUrl: dayLotts5000Icon,
  },
  {
    playBoxId: 'dayLotts10000',
    imageUrl: dayLotts10000Icon,
  },
];

const FooterMenuSp: FC<Props> = memo((props) => {
  // ボックス遷移用関数
  const { pushToBox } = props;

  return (
    <HeaderMenuSpS>
      <NavS>
        <TitleS>PLAY BOX</TitleS>
        <BoxBlockS>
          <BoxGridS>
            {playBoxList.map((box) => (
              <BoxS key={uuidv4()}>
                <BoxButtonS
                  type="button"
                  onClick={() => pushToBox(box.playBoxId)}
                >
                  <BoxImageWrapS>
                    <BoxImageS src={box.imageUrl} alt="" />
                  </BoxImageWrapS>
                </BoxButtonS>
              </BoxS>
            ))}
          </BoxGridS>
        </BoxBlockS>
      </NavS>
    </HeaderMenuSpS>
  );
});
export default FooterMenuSp;

/**
 *
 * Styles
 *
 */
const HeaderMenuSpS = styled.div`
  width: 100%;
`;

const NavS = styled.nav`
  width: 100%;
`;

const TitleS = styled.p`
  width: 100%;
  font-size: 2.5rem;
  text-align: center;
  padding: 2rem;
  background: linear-gradient(45deg, #ff17e4, #5e05c6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const BoxBlockS = styled.div`
  width: 100%;
  position: relative;
`;

const BoxGridS = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
`;

const BoxS = styled.div`
  width: 45vw;

  @media (min-width: 600px) {
    width: 28vw;
  }
`;

const BoxButtonS = styled.button`
  height: 45vw;
  display: block;
  align-items: center;
  justify-content: center;

  border-image: url(${PlayBoxBgSp}) 10 10 10 10 fill stretch;
  border-width: 5px;
  border-style: solid;

  @media (min-width: 600px) {
    height: 28vw;
  }
`;

const BoxImageWrapS = styled.div`
  width: 100%;
  padding: 1.5rem 1rem;
`;

const BoxImageS = styled.img`
  width: 100%;
`;
