import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { LANG } from 'utils/const/lang';
// Images
import pointOnButtonJa from 'assets/images/mypage/ja/button_point-on.svg';
import pointOnButtonEn from 'assets/images/mypage/en/button_point-on.svg';
import pointOnButtonZhCn from 'assets/images/mypage/zhCn/button_point-on.svg';
import pointOnButtonZhTw from 'assets/images/mypage/zhTw/button_point-on.svg';
import pointOnButtonKo from 'assets/images/mypage/ko/button_point-on.svg';
import pointOnButtonVi from 'assets/images/mypage/vi/button_point-on.svg';
import pointOnButtonTh from 'assets/images/mypage/th/button_point-on.svg';

/**
 *
 * component
 *
 */
const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

const WithdrawalOnButton: FC = memo(() => {
  const lang = useRecoilValue(langState);

  return (
    <WithdrawalOnButtonS
      src={
        lang === JAPANESE
          ? pointOnButtonJa
          : lang === SIMPLE_CHINESE
            ? pointOnButtonZhCn
            : lang === TRADITIONAL_CHINESE
              ? pointOnButtonZhTw
              : lang === KOREAN
                ? pointOnButtonKo
                : lang === VIETNAM
                  ? pointOnButtonVi
                  : lang === THAILAND
                    ? pointOnButtonTh
                    : pointOnButtonEn
      }
      alt=""
    />
  );
});
export default WithdrawalOnButton;

/**
 *
 * Styles
 *
 */
const WithdrawalOnButtonS = styled.img`
  width: 100%;
`;
