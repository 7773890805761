import { FC, useEffect, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Components
import BreadCrumbs from 'components/atoms/breadCrumbs/BreadCrumbs';
import NeonShadowBorder from 'components/atoms/border/NeonShadowBorder';
import ColorSelect from 'components/atoms/select/ColorSelect';
import FileFrameLabel from 'components/atoms/label/FileFrameLabel';
import FileTextLabel from 'components/atoms/label/FileTextLabel';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { useKycUpload } from 'hooks/pages/private/useKycUpload';
import { useRedirect } from 'hooks/versatile/useRedirect';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { LANG } from 'utils/const/lang';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';
import buttonGoBackJa from 'assets/images/kycupload/button_goback_ja.svg';
import buttonGoBackEn from 'assets/images/kycupload/button_goback_en.svg';
import buttonGoBackZhCn from 'assets/images/kycupload/button_goback_zh_cn.svg';
import buttonGoBackZhTw from 'assets/images/kycupload/button_goback_zh_tw.svg';
import buttonGoBackKo from 'assets/images/kycupload/button_goback_ko.svg';
import buttonGoBackVi from 'assets/images/kycupload/button_goback_vi.svg';
import buttonGoBackTh from 'assets/images/kycupload/button_goback_th.svg';
import buttonUploadJa from 'assets/images/kycupload/button_upload_ja.svg';
import buttonUploadEn from 'assets/images/kycupload/button_upload_en.svg';
import buttonUploadZhCn from 'assets/images/kycupload/button_upload_zh_cn.svg';
import buttonUploadZhTw from 'assets/images/kycupload/button_upload_zh_tw.svg';
import buttonUploadKo from 'assets/images/kycupload/button_upload_ko.svg';
import buttonUploadVi from 'assets/images/kycupload/button_upload_vi.svg';
import buttonUploadTh from 'assets/images/kycupload/button_upload_th.svg';

const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

/**
 *
 * Component
 *
 */
const KycUpload: FC = memo(() => {
  // KYC書類アップロード用カスタムフック
  const {
    errorMessage,
    breadCrumbImems,
    // 生年月日
    yearOptions,
    monthOptions,
    dayOptions,
    isSelectedYear,
    isSelectedMonth,
    isSelectedDay,
    onChangeYear,
    onChangeMonth,
    onChangeDay,
    // ファイル
    pageType,
    passport,
    licenseFrontSide,
    licenseBackSide,
    onChangeFile,
    onDropFile,
    checkPostData,
    // ルーティング
    goToMypage,
  } = useKycUpload();

  // Hooks
  const { t } = useTranslation();
  const { getRootProps } = useDropzone({ onDrop: onDropFile });
  const { redirectToTopPage } = useRedirect();
  // Recoil 言語設定
  const lang = useRecoilValue(langState);

  useEffect(() => {
    // パスポートor免許証以外のパスはTOPへ強制リダイレクト
    if (pageType !== 'passport' && pageType !== 'license') {
      redirectToTopPage();
    }

    // スクロール位置リセット
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.kycUpload')}</title>
        <meta name="description" content={t('description.kycUpload')} />
      </Helmet>
      <KycUploadS>
        <ContainerS>
          {/* パンくずリスト(PC用) */}
          <BreadCrumbsWrapS>
            <BreadCrumbs crumbItems={breadCrumbImems} />
          </BreadCrumbsWrapS>

          <FormS>
            <TitleS>{t('生年月日')}</TitleS>
            <BorderSpS />
            <BorderPcS>
              <NeonShadowBorder />
            </BorderPcS>

            {/* 生年月日(セレクトボックス) */}
            <BirthDayS>
              <SelectWrapS>
                <ColorSelect color="#F502FD">
                  <SelectS
                    name="year"
                    value={isSelectedYear}
                    onChange={onChangeYear}
                  >
                    <OptionS value="">{t('年')}</OptionS>
                    {yearOptions.length > 0
                      && yearOptions.map((option) => (
                        <OptionS value={option.value} key={uuidv4()}>
                          {option.label}
                        </OptionS>
                      ))}
                  </SelectS>
                </ColorSelect>
              </SelectWrapS>

              <SlashS>/</SlashS>

              <SelectWrapS>
                <ColorSelect color="#F502FD">
                  <SelectS
                    name="month"
                    value={isSelectedMonth}
                    onChange={onChangeMonth}
                  >
                    <OptionS value="">{t('月')}</OptionS>
                    {monthOptions.length > 0
                      && monthOptions.map((option) => (
                        <OptionS value={option.value} key={uuidv4()}>
                          {option.label}
                        </OptionS>
                      ))}
                  </SelectS>
                </ColorSelect>
              </SelectWrapS>

              <SlashS>/</SlashS>

              <SelectWrapS>
                <ColorSelect color="#F502FD">
                  <SelectS
                    name="day"
                    value={isSelectedDay}
                    onChange={onChangeDay}
                  >
                    <OptionS value="">{t('日')}</OptionS>
                    {dayOptions.length > 0
                      && dayOptions.map((option) => (
                        <OptionS value={option.value} key={uuidv4()}>
                          {option.label}
                        </OptionS>
                      ))}
                  </SelectS>
                </ColorSelect>
              </SelectWrapS>
            </BirthDayS>

            {/* パスポート */}
            {pageType === 'passport' && (
              <UploadS>
                <LegendS>{t('パスポートの写真をアップロード')}</LegendS>

                <FileFrameLabelWrapS>
                  <FileFrameLabel
                    src={passport}
                    text={t(
                      'ドラッグ＆ドロップするかファイルを選択してください',
                    )}
                  >
                    <FileLabelS htmlFor="passport" {...getRootProps()} />
                  </FileFrameLabel>
                </FileFrameLabelWrapS>

                <FileTextLabelWrapS>
                  <FileTextLabel
                    name={t('ファイルを選択')}
                    htmlFor="passport"
                  />
                </FileTextLabelWrapS>

                <FileS
                  id="passport"
                  name="passport"
                  accept="image/png, image/jpeg"
                  onChange={(event) => onChangeFile('passport', event)}
                />
              </UploadS>
            )}

            {/* 免許証 */}
            {pageType === 'license' && (
              <>
                {/* 表 */}
                <UploadS>
                  <LegendS>{t('免許証 ( 表 ) の写真をアップロード')}</LegendS>

                  <FileFrameLabelWrapS>
                    <FileFrameLabel
                      src={licenseFrontSide}
                      text={t(
                        'ドラッグ＆ドロップするかファイルを選択してください',
                      )}
                    >
                      <FileLabelS
                        htmlFor="license_frontside"
                        {...getRootProps()}
                      />
                    </FileFrameLabel>
                  </FileFrameLabelWrapS>

                  <FileTextLabelWrapS>
                    <FileTextLabel
                      name={t('ファイルを選択')}
                      htmlFor="license_frontside"
                    />
                  </FileTextLabelWrapS>

                  <FileS
                    id="license_frontside"
                    name="license_frontside"
                    accept="image/png, image/jpeg"
                    onChange={(event) => onChangeFile('license_frontside', event)}
                  />
                </UploadS>

                {/* 裏 */}
                <UploadS>
                  <LegendS>{t('免許証 ( 裏 ) の写真をアップロード')}</LegendS>

                  <FileFrameLabelWrapS>
                    <FileFrameLabel
                      src={licenseBackSide}
                      text={t(
                        'ドラッグ＆ドロップするかファイルを選択してください',
                      )}
                    >
                      <FileLabelS
                        htmlFor="license_backside"
                        {...getRootProps()}
                      />
                    </FileFrameLabel>
                  </FileFrameLabelWrapS>

                  <FileTextLabelWrapS>
                    <FileTextLabel
                      name={t('ファイルを選択')}
                      htmlFor="license_backside"
                    />
                  </FileTextLabelWrapS>

                  <FileS
                    id="license_backside"
                    name="license_backside"
                    accept="image/png, image/jpeg"
                    onChange={(event) => onChangeFile('license_backside', event)}
                  />
                </UploadS>
              </>
            )}

            {/* エラーメッセージ */}
            {errorMessage && (
              <ErrorMessageWrapS>
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </ErrorMessageWrapS>
            )}

            {/* ボタン系 */}
            <ButtonGroupS>
              <GoBackButtonSpS type="button" onClick={goToMypage}>
                {t('1つ前に戻る')}
              </GoBackButtonSpS>
              <GoBackButtonPcS type="button" onClick={goToMypage}>
                <ImageS
                  src={
                    lang === JAPANESE
                      ? buttonGoBackJa
                      : lang === SIMPLE_CHINESE
                        ? buttonGoBackZhCn
                        : lang === TRADITIONAL_CHINESE
                          ? buttonGoBackZhTw
                          : lang === KOREAN
                            ? buttonGoBackKo
                            : lang === VIETNAM
                              ? buttonGoBackVi
                              : lang === THAILAND
                                ? buttonGoBackTh
                                : buttonGoBackEn
                  }
                />
              </GoBackButtonPcS>
              <UploadButtonS type="button" onClick={checkPostData}>
                <ImageS
                  src={
                    lang === JAPANESE
                      ? buttonUploadJa
                      : lang === SIMPLE_CHINESE
                        ? buttonUploadZhCn
                        : lang === TRADITIONAL_CHINESE
                          ? buttonUploadZhTw
                          : lang === KOREAN
                            ? buttonUploadKo
                            : lang === VIETNAM
                              ? buttonUploadVi
                              : lang === THAILAND
                                ? buttonUploadTh
                                : buttonUploadEn
                  }
                />
              </UploadButtonS>
            </ButtonGroupS>
          </FormS>
        </ContainerS>
      </KycUploadS>
    </>
  );
});
export default KycUpload;

/**
 *
 * Styles
 *
 */
const KycUploadS = styled.main`
  color: #ffffff;
  // background
  background-image: url(${backGroundSp});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y: top;

  @media (min-width: 600px) {
    background-position-y: center;
  }

  @media (min-width: 1025px) {
    background-image: image-set(
      url(${backGroundPc}) 1x,
      url(${backGroundPc2x}) 2x
    );
    background-position-y: top;
  }
`;

const ContainerS = styled.div`
  width: 90%;
  min-height: 90vh;
  margin: 0 auto;
  padding-top: 7rem;
  padding-bottom: 5rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;

  @media (min-width: 600px) {
    width: 60%;
    padding-top: 10rem;
  }

  @media (min-width: 1025px) {
    width: 100%;
    max-width: 100rem;
    padding-top: 2rem;
    padding-bottom: 10rem;
  }
`;

const BreadCrumbsWrapS = styled.div`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    width: 100%;
    margin-bottom: 6rem;
  }
`;

const FormS = styled.div`
  width: 100%;

  @media (min-width: 1025px) {
    width: 70%;
    margin: 0 auto;
  }
`;

const TitleS = styled.p`
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  font-weight: bold;

  @media (min-width: 600px) {
    margin-bottom: 1.4rem;
    font-size: 2rem;
  }

  @media (min-width: 1025px) {
    font-size: 2.4rem;
    padding-left: 1rem;
  }
`;

const BorderSpS = styled.hr`
  width: 100%;
  margin-bottom: 2.4rem;

  @media (min-width: 600px) {
    margin-bottom: 3rem;
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

const BorderPcS = styled.div`
  display: none;

  @media (min-width: 1025px) {
    width: 100%;
    display: block;
    margin-bottom: 5rem;
  }
`;

const BirthDayS = styled.fieldset`
  width: 100%;
  margin-bottom: 2.8rem;
  // flex
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 600px) {
    margin-bottom: 3rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 5rem;
  }
`;

const SelectWrapS = styled.div`
  width: 100%;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }

  @media (min-width: 1025px) {
    font-size: 2rem;
  }
`;

const SelectS = styled.select`
  height: 100%;
  width: 100%;
`;

const OptionS = styled.option`
  color: #000000;
`;

const SlashS = styled.span`
  margin: 0 0.5rem;
  font-size: 2rem;

  @media (min-width: 600px) {
    margin: 0 1rem;
  }

  @media (min-width: 1025px) {
    margin: 0 2rem;
    font-size: 2.4rem;
  }
`;

const UploadS = styled.fieldset`
  width: 100%;

  margin-bottom: 3rem;
`;

const LegendS = styled.legend`
  width: 100%;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    margin-bottom: 1.4rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
`;

const FileS = styled.input.attrs({ type: 'file' })`
  display: none;
`;

const FileLabelS = styled.label`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const FileFrameLabelWrapS = styled.div`
  min-height: 100px;
  width: 100%;
  display: grid;

  @media (min-width: 600px) {
    min-height: 200px;
  }

  @media (min-width: 1025px) {
    min-height: 250px;
  }
`;

const FileTextLabelWrapS = styled.div`
  width: 100%;
  margin-top: 1.6rem;
  font-size: 1.4rem;
`;

const ImageS = styled.img`
  width: 100%;
`;

const ButtonGroupS = styled.div`
  width: 100%;
  position: relative;
  // flex
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 1025px) {
    margin-top: 2rem;
    flex-direction: row;
    justify-content: center;
  }
`;

const GoBackButtonSpS = styled.button`
  font-size: 1.4rem;
  color: #fff040;
  text-decoration: underline;
  margin-top: 3rem;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const GoBackButtonPcS = styled.button`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    width: 170px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:hover {
    @media (min-width: 1025px) {
      opacity: 0.7;
    }
  }
`;

const UploadButtonS = styled.button`
  width: 60%;

  @media (min-width: 600px) {
    width: 40%;
  }

  @media (min-width: 1025px) {
    width: 230px;
  }

  &:hover {
    @media (min-width: 1025px) {
      opacity: 0.7;
    }
  }
`;

const ErrorMessageWrapS = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    font-size: 1.6rem;
    margin-bottom: 3rem;
  }
`;
