import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import FormLayout from 'components/templates/FormLayout';
import RoundButton from 'components/atoms/button/RoundButton';
import ColorSquare from 'components/atoms/frame/ColorFrame';
import EqualIcon from 'components/atoms/icon/EqualIcon';
import ColorBorderInput from 'components/atoms/input/ColorBorderInput';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { usePointPurchaseCash } from 'hooks/pages/private/usePointPurchaseCash';
// Recoil
// Utils
import { pointPurchaseCashBreadCrumbItems } from 'utils/breadCrumbs';
import { devices } from 'utils/const/devices';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';

const { tabletPortrait, tabletLandscape, pc } = devices;

/**
 *
 * Component
 *
 */
const PointPurchaseCash: FC = memo(() => {
  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    pointInputProps,
    goBack,
    rateJpy,
    firstNameInputProps,
    lastNameInputProps,
    onClickPurchase,
    lessPointErrorMessage,
    accountHolderErrorMessage,
  } = usePointPurchaseCash();

  return (
    <>
      <Helmet>
        <title>{t('title.pointPurchaseCash')}</title>
        <meta name="description" content={t('description.pointPurchaseCash')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={pointPurchaseCashBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('現金で入金')}
        titleStyle="gold"
      >
        {/* ローディングアニメーション */}
        {isLoading && <LoaderWindow />}
        <FormS>
          <BlockS>
            {/* 変換レート表示部分 */}
            <PointRateBlockS>
              <PurchasePointBlockS>
                <PurchaseTextS>
                  {t('金額を入れてください。')}
                </PurchaseTextS>
                <PointBlockS>
                  <InputSquareBlockS>
                    {/* ユーザー入力要素 */}
                    <ColorBorderInput
                      color="#f502fd"
                      textAlign="end"
                      unit="$"
                      {...pointInputProps}
                    />
                  </InputSquareBlockS>
                  <PointUnitBlockS>
                    <UnitS>{t('ポイント')}</UnitS>
                  </PointUnitBlockS>
                </PointBlockS>
                <HiddenPurchaseTextS>
                  {t('金額を入れてください。')}
                </HiddenPurchaseTextS>
              </PurchasePointBlockS>

              <EqualBlockS>
                <EqualIcon />
              </EqualBlockS>

              <ConvertBlockS>
                {/* 円 */}
                <JpyBlockS>
                  <HiddenNoticeTextS>
                    {t('日本円での合計金額(手数料込)')}
                  </HiddenNoticeTextS>
                  <JpyPriceWrapS>
                    <SquareBlockS>
                      <ColorSquare color="#f502fd" justifyContent="end">
                        <JpyRateS>{rateJpy.toLocaleString()}</JpyRateS>
                        <UnitS>{t('円')}</UnitS>
                      </ColorSquare>
                    </SquareBlockS>
                  </JpyPriceWrapS>
                  <NoticeTextS>
                    {t('日本円での合計金額(手数料込)')}
                  </NoticeTextS>
                </JpyBlockS>
              </ConvertBlockS>
            </PointRateBlockS>
            {lessPointErrorMessage !== '' && (
              <MessageWrapS>
                <ErrorMessage>{t(lessPointErrorMessage)}</ErrorMessage>
              </MessageWrapS>
            )}
          </BlockS>

          <AccountHolderAreaS>
            <AccountHolderTitleS>
              {t('お名前(必須)')}
            </AccountHolderTitleS>
            <AccountHolderS>
              <AccountHolderFieldS>
                <AccountHolderLabelS>{t('姓(カナ)')}</AccountHolderLabelS>
                <AccountHolderInputWrapS>
                  <ColorBorderInput
                    color="#f502fd"
                    textAlign="start"
                    unit={null}
                    {...lastNameInputProps}
                  />
                </AccountHolderInputWrapS>
              </AccountHolderFieldS>
              <AccountHolderFieldS>
                <AccountHolderLabelS>{t('名(カナ)')}</AccountHolderLabelS>
                <AccountHolderInputWrapS>
                  <ColorBorderInput
                    color="#f502fd"
                    textAlign="start"
                    unit={null}
                    {...firstNameInputProps}
                  />
                </AccountHolderInputWrapS>
              </AccountHolderFieldS>
            </AccountHolderS>
            {accountHolderErrorMessage !== '' && (
              <MessageWrapS>
                <ErrorMessage>{t(accountHolderErrorMessage)}</ErrorMessage>
              </MessageWrapS>
            )}
          </AccountHolderAreaS>

          <RoundButtonWrapS>
            {isLoading ? (
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {t('入金する')}
              </RoundButton>
            ) : (
              <RoundButton
                background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                borderColor="rgba(219,0,177,1)"
                onClick={onClickPurchase}
              >
                {t('入金する')}
              </RoundButton>
            )}
          </RoundButtonWrapS>

          <RoundButtonWrapS>
            {isLoading ? (
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {t('1つ前に戻る')}
              </RoundButton>
            ) : (
              <RoundButton
                onClick={goBack}
                background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                borderColor="rgba(0,135,167,1)"
              >
                {t('1つ前に戻る')}
              </RoundButton>
            )}
          </RoundButtonWrapS>
        </FormS>
      </FormLayout>
    </>
  );
});
export default PointPurchaseCash;

/**
 *
 * Styles
 *
 */
const FormS = styled.div`
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6rem;

  @media (min-width: 1025px) {
    width: 90%;
    margin-top: 7rem;
  }
`;

const BlockS = styled.div`
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PointRateBlockS = styled.div`
  display: grid;
  row-gap: 2rem;

  @media (min-width: 600px) {
    row-gap: 2rem;

    grid-template-columns: 2fr 1fr 2fr;
    row-gap: unset;
  }
`;

const PurchasePointBlockS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row: 1/2;
  grid-column: 1/6;
  row-gap: 2rem;

  @media (min-width: 600px) {
    grid-row: unset;
    grid-column: unset;
  }
`;

const PurchaseTextS = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ffffff;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const HiddenPurchaseTextS = styled(PurchaseTextS)`
  color: transparent;
  visibility: hidden;
`;

const PointBlockS = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

const JpyBlockS = styled(PointBlockS)`
  flex-direction: column;
  row-gap: 2rem;
  align-items: flex-start;
  align-self: start;
`;

const JpyPriceWrapS = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  column-gap: 1.2rem;
`;

const SquareBlockS = styled.div`
  width: 73%;
  font-size: 1.6rem;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 1025px) {
    width: 75%;
    font-size: 1.8rem;
  }
`;

const InputSquareBlockS = styled.div`
  font-size: 1.6rem;

  @media ${tabletPortrait} {
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const RateS = styled.p`
  width: 100%;
  text-align: end;
  color: #ffffff;
  word-break: break-all;
`;

const JpyRateS = styled(RateS)`
  color: #ffeb00;
`;

const NoticeTextS = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ffffff;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const HiddenNoticeTextS = styled(NoticeTextS)`
  color: transparent;
  visibility: hidden;
`;

const PointUnitBlockS = styled.div`
  width: 25%;

  @media (min-width: 600px) {
    width: 35%;
  }

  @media (min-width: 1025px) {
    width: 27%;
  }
`;

const UnitS = styled.p`
  font-size: 1.4rem;
  color: #ffeb00;
  word-break: break-all;
  margin-left: 1rem;
  white-space: no-wrap;
  flex: none;
  width: max-content;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const EqualBlockS = styled.div`
  grid-row: 2/3;
  grid-column: 1/2;
  display: flex;
  margin-right: 2.5rem;

  @media (min-width: 600px) {
    grid-row: unset;
    grid-column: unset;
    margin-right: 0;
  }
`;

const ConvertBlockS = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 3rem;
  grid-row: 2/3;
  grid-column: 2/6;
  align-items: center;

  @media (min-width: 600px) {
    grid-row: unset;
    grid-column: unset;
  }
`;

const RoundButtonWrapS = styled.div`
  width: 200px;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 220px;
    font-size: 1.6rem;
  }
`;

const AccountHolderAreaS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center center;
  margin-bottom: 4rem;
`;

const AccountHolderTitleS = styled.p`
  width: 100%;
  font-size: 1.8rem;
  color: #ffffff;
  margin-bottom: 3rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ffffff;
`;

const AccountHolderS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;

  @media (min-width: 600px) {
    width: 95%;
    flex-direction: row;
    column-gap: 4rem;
  }
`;

const AccountHolderFieldS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const AccountHolderLabelS = styled.label`
  font-size: 1.6rem;
  color: #ffffff;
`;

const AccountHolderInputWrapS = styled.div`
  width: 100%;
`;

const MessageWrapS = styled.div`
  font-size: 1.6rem;
  text-align: center;
  margin-top: 2rem;

  @media (min-width: 600px) {
    font-size: 1.8rem;
  }
`;
