import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
// Components
import LoaderPart from 'components/atoms/loader/LoaderPart';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { LANG } from 'utils/const/lang';
// Images
import newsTitle from 'assets/images/top/news/title-news.png';
import newsTitle2x from 'assets/images/top/news/title-news@2x.png';
import winnersTitleJa from 'assets/images/top/news/title-winners_ja.png';
import winnersTitleJa2x from 'assets/images/top/news/title-winners_ja@2x.png';
import winnersTitleEn from 'assets/images/top/news/title-winners_en.png';
import winnersTitleEn2x from 'assets/images/top/news/title-winners_en@2x.png';
import winnersTitleZhCn from 'assets/images/top/news/title-winners_zh_cn.png';
import winnersTitleZhCn2x from 'assets/images/top/news/title-winners_zh_cn@2x.png';
import winnersTitleZhTw from 'assets/images/top/news/title-winners_zh_tw.png';
import winnersTitleZhTw2x from 'assets/images/top/news/title-winners_zh_tw@2x.png';
import winnersTitleKo from 'assets/images/top/news/title-winners_ko.png';
import winnersTitleKo2x from 'assets/images/top/news/title-winners_ko@2x.png';
import winnersTitleVi from 'assets/images/top/news/title-winners_vi.png';
import winnersTitleVi2x from 'assets/images/top/news/title-winners_vi@2x.png';
import winnersTitleTh from 'assets/images/top/news/title-winners_th.png';
import winnersTitleTh2x from 'assets/images/top/news/title-winners_th@2x.png';
import neonBorderTopSp from 'assets/images/neonItem/border-top_sp.png';
import neonBorderTopPc from 'assets/images/neonItem/border-top_pc.png';
import neonBorderTopPc2x from 'assets/images/neonItem/border-top_pc@2x.png';
import neonBorderBottomSp from 'assets/images/neonItem/border-bottom_sp.png';
import neonBorderBottomPc from 'assets/images/neonItem/border-bottom_pc.png';
import neonBorderBottomPc2x from 'assets/images/neonItem/border-bottom_pc@2x.png';

/**
 *
 * Component
 *
 */
type NewsService = {
  type: 'service';
  data: {
    id: number;
    date: string;
    title: string;
    enTitle: string;
    zhCnTitle: string;
    zhTwTitle: string;
    koTitle: string;
    viTitle: string;
    thTitle: string;
  }[];
};
type NewsBigWinners = {
  type: 'bigwinners';
  data: {
    date: string;
    userName: string;
    point: string;
  }[];
};
type Props = {
  isEmpty: boolean;
  isLoading: boolean;
  scrollLastFlag: boolean;
  getApiMethod: () => void;
  news: NewsService | NewsBigWinners;
  errorMessage: string;
};

const {
  ENGLISH, JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

const NewsScrollBox: FC<Props> = memo((props) => {
  // i18-next
  const { t } = useTranslation();
  // Recoil 言語設定
  const lang = useRecoilValue(langState);

  const {
    isEmpty,
    isLoading,
    scrollLastFlag,
    getApiMethod,
    errorMessage,
    news,
  } = props;

  return (
    <NewsScrollBoxS>
      <TitlePcS>
        {news.type === 'service' ? (
          // NEWS
          <TitleImagePcS src={newsTitle} srcSet={`${newsTitle2x} 2x`} alt="" />
        ) : (
          // 高額当選者
          <TitleImagePcS
            src={
              lang === JAPANESE
                ? winnersTitleJa
                : lang === SIMPLE_CHINESE
                  ? winnersTitleZhCn
                  : lang === TRADITIONAL_CHINESE
                    ? winnersTitleZhTw
                    : lang === KOREAN
                      ? winnersTitleKo
                      : lang === VIETNAM
                        ? winnersTitleVi
                        : lang === THAILAND
                          ? winnersTitleTh
                          : winnersTitleEn
            }
            srcSet={`${
              lang === JAPANESE
                ? winnersTitleJa2x
                : lang === SIMPLE_CHINESE
                  ? winnersTitleZhCn2x
                  : lang === TRADITIONAL_CHINESE
                    ? winnersTitleZhTw2x
                    : lang === KOREAN
                      ? winnersTitleKo2x
                      : lang === VIETNAM
                        ? winnersTitleVi2x
                        : lang === THAILAND
                          ? winnersTitleTh2x
                          : winnersTitleEn2x
            } 2x`}
            alt=""
          />
        )}
      </TitlePcS>

      <ContainerS>
        {/* ローディングアニメーション */}
        {isLoading && <LoaderPart />}

        <NeonBorderS>
          <source
            srcSet={`${neonBorderTopPc} 1x, ${neonBorderTopPc2x} 2x`}
            media="(min-width: 1025px)"
          />
          <NeonBorderImageS src={neonBorderTopSp} alt="" />
        </NeonBorderS>

        {news.type === 'service' ? (
          <TitleSpS>
            <TitleImageWrapSpS>
              <TitleImageSpS
                src={newsTitle}
                srcSet={`${newsTitle2x} 2x`}
                alt=""
              />
            </TitleImageWrapSpS>
          </TitleSpS>
        ) : (
          <TitleWinnerSpS>
            <TitleWinnerImageWrapSpS>
              <TitleImageSpS
                src={
                  lang === JAPANESE
                    ? winnersTitleJa
                    : lang === SIMPLE_CHINESE
                      ? winnersTitleZhCn
                      : lang === TRADITIONAL_CHINESE
                        ? winnersTitleZhTw
                        : lang === KOREAN
                          ? winnersTitleKo
                          : lang === VIETNAM
                            ? winnersTitleVi
                            : lang === THAILAND
                              ? winnersTitleTh
                              : winnersTitleEn
                }
                srcSet={`${
                  lang === JAPANESE
                    ? winnersTitleJa2x
                    : lang === SIMPLE_CHINESE
                      ? winnersTitleZhCn2x
                      : lang === TRADITIONAL_CHINESE
                        ? winnersTitleZhTw2x
                        : lang === KOREAN
                          ? winnersTitleKo2x
                          : lang === VIETNAM
                            ? winnersTitleVi2x
                            : lang === THAILAND
                              ? winnersTitleTh2x
                              : winnersTitleEn2x
                } 2x`}
                alt=""
              />
            </TitleWinnerImageWrapSpS>
          </TitleWinnerSpS>
        )}

        {news.data?.length !== 0 && (
          <BoxWrapS>
            <UlListS scrollLastFlag={scrollLastFlag}>
              {news.type === 'service'
                ? news.data.map((item) => (
                  <ListS key={uuidv4()}>
                    <Link className="newslink" to={`/news/show/${item.id}`}>
                      <RowS>
                        <DateS>{item.date}</DateS>
                        {lang === ENGLISH && (
                        <NewsTitleS>{item.enTitle}</NewsTitleS>
                        )}
                        {lang === JAPANESE && (
                        <NewsTitleS>{item.title}</NewsTitleS>
                        )}
                        {lang === SIMPLE_CHINESE && (
                        <NewsTitleS>{item.zhCnTitle}</NewsTitleS>
                        )}
                        {lang === TRADITIONAL_CHINESE && (
                        <NewsTitleS>{item.zhTwTitle}</NewsTitleS>
                        )}
                        {lang === KOREAN && (
                        <NewsTitleS>{item.koTitle}</NewsTitleS>
                        )}
                        {lang === VIETNAM && (
                        <NewsTitleS>{item.viTitle}</NewsTitleS>
                        )}
                        {lang === THAILAND && (
                        <NewsTitleS>{item.thTitle}</NewsTitleS>
                        )}
                      </RowS>
                    </Link>
                  </ListS>
                ))
                : news.data.map((item) => (
                  <ListS key={uuidv4()}>
                    <RowS>
                      <DateS>{item.date}</DateS>
                      <WinnerS>
                        <WinnerNameS>{item.userName}</WinnerNameS>
                        <WinnerPointS>{item.point}</WinnerPointS>
                      </WinnerS>
                    </RowS>
                  </ListS>
                ))}
            </UlListS>
            {!scrollLastFlag && (
              <ReadMoreButtonWrapS>
                <ReadMoreButtonS type="button" onClick={getApiMethod}>
                  {t('さらに表示する')}
                </ReadMoreButtonS>
              </ReadMoreButtonWrapS>
            )}
          </BoxWrapS>
        )}

        {isEmpty && (
          <EmptyS>
            <EmptyTextS>
              {news.type === 'service' ? t('NEWSはありません。') : t('高額獲得者はいません。')}
            </EmptyTextS>
          </EmptyS>
        )}

        {errorMessage && (
          <EmptyS>
            <EmptyTextS>{errorMessage}</EmptyTextS>
          </EmptyS>
        )}

        <NeonBorderS>
          <source
            srcSet={`${neonBorderBottomPc} 1x, ${neonBorderBottomPc2x} 2x`}
            media="(min-width: 1025px)"
          />
          <NeonBorderImageS src={neonBorderBottomSp} alt="" />
        </NeonBorderS>
      </ContainerS>
    </NewsScrollBoxS>
  );
});
export default NewsScrollBox;

/**
 *
 * Styles
 *
 */
const NewsScrollBoxS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NeonBorderS = styled.picture`
  width: 100%;
`;

const NeonBorderImageS = styled.img`
  width: 100%;
`;

const TitlePcS = styled.div`
  display: none;

  @media (min-width: 1025px) {
    width: 100%;
    height: 6rem;
    display: flex;
  }
`;

const TitleImagePcS = styled.img`
  @media (min-width: 1025px) {
    height: 100%;
  }
`;

const TitleSpS = styled.div`
  margin-top: -5%;
  width: 90%;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const TitleWinnerSpS = styled(TitleSpS)``;

const TitleImageWrapSpS = styled.div`
  height: 3.5rem;
`;

const TitleWinnerImageWrapSpS = styled(TitleImageWrapSpS)``;

const TitleImageSpS = styled.img`
  height: 100%;
`;

const ContainerS = styled.div`
  position: relative;
  width: 100%;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const BoxWrapS = styled.div`
  width: 90%;
  height: 100%;
  overflow: auto;

  @media (min-width: 1025px) {
    width: 100%;
    border: 1px solid #707070;
  }
`;

const UlListS = styled.ul<{ scrollLastFlag: boolean }>`
  border-bottom: ${({ scrollLastFlag }) => (scrollLastFlag ? '1px solid #707070' : 0)};
`;

const ListS = styled.li`
  width: 100%;
  border-bottom: 1px solid #707070;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  word-break: break-all;

  &:last-child {
    border-bottom: 0;
  }

  &:only-child {
    border-bottom: 1px solid #707070;
  }

  .newslink {
    width: 100%;
    display: inline-block;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;

    &:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;

const ReadMoreButtonWrapS = styled.div`
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  word-break: break-all;
  border-top: 1px solid #707070;

  @media (min-width: 1025px) {
    border-top: 0;
  }
`;

const RowS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem 0 1.2rem;

  @media (min-width: 1025px) {
    align-items: center;
    flex-direction: row;
    padding: 0;
  }
`;

const DateS = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  color: #ff7bfd;
  word-break: initial;
  margin-bottom: 0.5rem;

  @media (min-width: 1025px) {
    width: auto;
    margin-bottom: 0;
    padding: 1rem 2rem;
    justify-content: center;
    font-size: 1.6rem;
    color: #ffffff;
    border-right: 1px solid #707070;
  }
`;

const NewsTitleS = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;

  @media (min-width: 1025px) {
    padding: 1rem 2rem;
  }
`;

const WinnerS = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 1025px) {
    flex: 1;
    padding: 1rem 2rem;
  }
`;

const WinnerNameS = styled.div`
  width: 65%;

  @media (min-width: 1025px) {
    flex: 1;
  }
`;

const WinnerPointS = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: end;

  @media (min-width: 1025px) {
    justify-content: end;
    width: 40%;
  }
`;

const ReadMoreButtonS = styled.button`
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EmptyS = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyTextS = styled.p`
  font-size: 1.6rem;
  color: #ffffff;
`;
