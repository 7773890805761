// External library
import { useEffect } from 'react';
// External library
import { useHistory } from 'react-router-dom';

/**
 *
 * Types
 *
 */
export type LocationState = {
  referrer: string;
};

/**
 *
 * Hooks
 *
 */
export const usePointPurchaseFinish = (): void => {
  const history = useHistory();
  const goToTopPage = () => history.push('/');
  useEffect(() => {
    window.addEventListener('popstate', goToTopPage, false);

    return () => {
      window.removeEventListener('popstate', goToTopPage, false);
    };
  }, []);
};
