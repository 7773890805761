import {
  memo, FC, forwardRef, ComponentPropsWithRef,
} from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type Props = ComponentPropsWithRef<'input'>;

/**
 *
 * Component
 *
 */
const NormalInput: FC<Props> = memo(forwardRef((props, ref) => (
  <NormalInputS {...props} ref={ref} />
)));
export default NormalInput;

/**
 *
 * Styles
 *
 */
const NormalInputS = styled.input`
  width: 100%;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  font-size: inherit;
  color: #ffffff;

  &::placeholder {
    color: #dddddd;
  }
`;
