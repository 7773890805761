import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import CompletionLayout from 'components/templates/CompletionLayout';
// Custom hooks
import { useInquiryFinish } from 'hooks/pages/common/useInquiryFinish';
import { useRedirect } from 'hooks/versatile/useRedirect';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Styles
 *
 */
const TextWrapS = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
    margin-bottom: 3rem;
  }

  @media (min-width: 1025px) {
    width: 50%;
  }
`;

const TextS = styled.p`
  margin-bottom: 1rem;
  color: #ffffff;
  font-size: 1.2rem;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

/**
 *
 * Component
 *
 */
const InquiryFinish: FC = () => {
  // hooks
  const { redirectToTopPage } = useRedirect();
  const { referrer } = useInquiryFinish();
  const { t } = useTranslation();

  useEffect(() => {
    // 確認画面以外からアクセスした場合TOPへリダイレクト
    if (referrer !== '/inquiry/confirm') {
      redirectToTopPage();
    }

    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', null);
    // ブラウザバックor進むをしたらトップページにリダイレクト
    window.addEventListener('popstate', redirectToTopPage, false);

    return () => {
      window.removeEventListener('popstate', redirectToTopPage, false);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.contactCompleted')}</title>
        <meta name="description" content={t('description.contactCompleted')} />
      </Helmet>
      <CompletionLayout
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title="お問い合わせを受け付けました。"
        to="/"
        linkText="TOPに戻る"
      >
        <TextWrapS>
          <TextS>{t('お問い合わせありがとうございます。')}</TextS>
          <TextS>{t('お問い合わせには3営業日以内に回答させて頂きます。')}</TextS>
        </TextWrapS>
      </CompletionLayout>
    </>
  );
};

export default InquiryFinish;
