// eslint-disable-next-line
import { FC, memo, useRef, useEffect } from 'react';
// Hooks
import { usePlayBox1000 } from 'hooks/organisms/playBox/usePlayBox1000';
// Components
import PlayBoxWrap from 'components/organisms/top/playBox/PlayBoxWrap';
// Recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { playBoxShowState } from 'store/atoms/playBoxShowState';
// Utils
import { PLAYBOX_TYPE, PLAYBOX_ID } from 'utils/const/playBox';
// Images
import dayLottsIcon from 'assets/images/top/playBox/daylotts1000_icon.svg';

// id
const { ID_1000 } = PLAYBOX_ID;

/**
 *
 * Component
 *
 */
// PLAYBOX1000用コンポーネント
const PlayBox1000: FC = memo(() => {
  // Recoil
  // PLAYBOX開閉情報更新関数
  const setPlayBoxShow = useSetRecoilState(playBoxShowState);
  // playbox開閉状態
  const playBoxShow = useRecoilValue(playBoxShowState);

  // このコンポーネントのplaybox情報
  const { initialBoxId, isShow1000 } = playBoxShow;
  // PLAYBOX用カスタムフック(個別)
  const { scrollToBox, onClickShow } = usePlayBox1000();

  const boxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // モーダルメニューからの遷移
    if (initialBoxId === ID_1000) {
      setPlayBoxShow({
        initialBoxId: playBoxShow.initialBoxId,
        isShow1000: true,
        isShow5000: false,
        isShow10000: false,
        isShow100000: false,
        isShow1000000: false,
      });

      window.setTimeout(() => {
        // スクロール
        scrollToBox(boxRef);
      }, 500);
    }
  }, [initialBoxId]);

  return (
    <PlayBoxWrap
      type={PLAYBOX_TYPE.BOX_1000}
      id={ID_1000}
      isShow={isShow1000}
      icon={dayLottsIcon}
      _ref={boxRef}
      onClickMethod={onClickShow}
    />
  );
});

export default PlayBox1000;
