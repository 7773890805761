import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import FormLayout from 'components/templates/FormLayout';
import NormalInput from 'components/atoms/input/NormalInput';
import RoundButton from 'components/atoms/button/RoundButton';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { useInquiry } from 'hooks/pages/common/useInquiry';
// Utils
import { inquiryBreadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const Inquiry: FC = () => {
  // i18next
  const { t } = useTranslation();

  // 新規会員登録用カスタムフック
  const {
    errorMessage, nameRef, emailRef, bodyRef, pushToInquiryConfirm,
  } = useInquiry();

  useEffect(() => {
    // スクロール位置リセット
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.contact')}</title>
        <meta name="description" content={t('description.contact')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={inquiryBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('お問い合わせフォーム')}
        titleStyle="silver"
      >
        {/* お問合せフォーム */}
        <FormS>
          <TextWrapS>
            <TextS>
              {t(
                'お客様からのご質問をお問い合わせフォームにて受け付けております。',
              )}
              {t('下記項目をご記入の上、「確認する」を押してください。')}
            </TextS>
          </TextWrapS>

          {/* エラーメッセージ */}
          {errorMessage && (
            <ErrorMessageWrapS>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </ErrorMessageWrapS>
          )}

          <InputAreaS>
            <InputWrapS>
              <NormalInput
                ref={nameRef}
                name="name"
                type="text"
                placeholder={t('氏名')}
              />
            </InputWrapS>
            <InputWrapS>
              <NormalInput
                ref={emailRef}
                name="email"
                type="text"
                placeholder={t('メールアドレス')}
              />
            </InputWrapS>
            <InputWrapS>
              <TextareaS
                ref={bodyRef}
                name="body"
                placeholder={t('お問い合わせ内容')}
              />
            </InputWrapS>
          </InputAreaS>

          <RoundButtonWrapS>
            <RoundButton
              onClick={pushToInquiryConfirm}
              background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
              borderColor="rgba(219,0,177,1)"
            >
              {t('確認する')}
            </RoundButton>
          </RoundButtonWrapS>
        </FormS>
      </FormLayout>
    </>
  );
};
export default Inquiry;

/**
 *
 * Styles
 *
 */
const FormS = styled.div`
  width: 100%;
  margin-top: 2rem;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
    margin-top: 2.4rem;
  }

  @media (min-width: 1025px) {
    width: 50%;
    margin-top: 3rem;
  }
`;

const TextWrapS = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    margin-bottom: 3rem;
  }
`;

const TextS = styled.p`
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 1.7;
  word-break: break-word;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const ErrorMessageWrapS = styled.div`
  width: 100%;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
  }
`;

const InputAreaS = styled.div`
  width: 100%;
`;

const InputWrapS = styled.div`
  width: 100%;
  font-size: 1.4rem;
  margin-bottom: 1.8rem;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const TextareaS = styled.textarea`
  width: 100%;
  height: 200px;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  font-size: inherit;
  color: #ffffff;

  &::placeholder {
    color: #dddddd;
  }
`;

const RoundButtonWrapS = styled.div`
  width: 50%;
  margin-top: 2rem;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 30%;
    margin-top: 3rem;
  }

  @media (min-width: 1025px) {
    width: 40%;
    font-size: 1.6rem;
  }
`;
