export const EVENT_ENABLED = false;

export const EVENT_NEWS = {
  ID: 999999,
  KIND: 1,
  USER_ID: 1,
  LOT_BOX_ID: 1,
  LOT_TABLE_ID: 1,
  LOTTERY_FLG: 1,
  STATUS: 1,
  TITLE: 'Day Lott\'s 100000の抽選が開始されました！',
  DESCRIPTION: '購入済みチケットの抽選が開始されました。\n下の「抽選の結果を見る」から結果を見ることができます。',
  EN_TITLE: 'The lottery for purchased tickets has started!\nYou can see the result from "Result of the lottery"',
  EN_DESCRIPTION: 'Day Lott\'s 100000 lottery has started!',
  KO_TITLE: 'Day Lott\'s 100000의 추첨이 시작되었습니다!',
  KO_DESCRIPTION: '구입하신 티켓의 추첨이 시작되었습니다.\n아래의 "추첨 결과 보기"에서 결과를 보실 수 있습니다.',
  ZH_CN_TITLE: 'Day Lott\'s 100000的抽选已经开始啦！',
  ZH_CN_DESCRIPTION: '您购买的门票的抽选已开始。抽选结果可通过\n下的 “查看抽选结果” 查看。',
  ZH_TW_TITLE: 'Day Lott\'s 100000的抽選開始了！',
  ZH_TW_DESCRIPTION: '購買完成之票券已開始抽選。\n下的「觀看抽選結果」內可觀看是否有抽到。',
  VI_TITLE: 'The lottery for purchased tickets has started!\nYou can see the result from "Result of the lottery"',
  VI_DESCRIPTION: 'Day Lott\'s 100000 lottery has started!',
  TH_TITLE: 'The lottery for purchased tickets has started!\nYou can see the result from "Result of the lottery"',
  TH_DESCRIPTION: 'Day Lott\'s 100000 lottery has started!',
  LINK: `${process.env.REACT_APP_WEB_URL ?? 'https://app.daylotts.net'}/lottery/?id=999999`,
} as const;

export const EVENT_USERS = [
  'kaya2008',
  'aky0310',
  'happy3588',
  'Chika358',
  'chiyo',
  'aiso3541',
  'mika221clover',
  'altn27',
  'altn27',
  'miracle99',
  'grazee',
  'to0358',
] as const;
