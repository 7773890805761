import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// Custom hooks
import { useFooterNavSp } from 'hooks/organisms/useFooterNavSp';
// Components
import GlobalModal from 'components/organisms/modal/GlobalModal';
import FooterMenuSp from 'components/organisms/layouts/footer/FooterMenuSp';
// Images
import footerMenu from 'assets/images/top/footerMenu/footermenu_sp.png';

const FooterNavSp: FC = memo(() => {
  // フッターメニュー用カスタムフック
  const { isMenuShow, toggleMenuShow, pushToBox } = useFooterNavSp();

  return (
    <>
      <GlobalModal isMenuShow={isMenuShow} toggleMenuShow={toggleMenuShow}>
        <FooterMenuSp pushToBox={pushToBox} />
      </GlobalModal>
      <FooterNavS>
        <UListS>
          <ListS>
            <ButtonWrapS>
              <BoxButtonS onClick={() => toggleMenuShow(true)}>
                PLAY BOX
              </BoxButtonS>
            </ButtonWrapS>
          </ListS>
          <ListS>
            <LinkWrapS>
              <Link to="/mypage">MY PAGE</Link>
            </LinkWrapS>
          </ListS>
        </UListS>
      </FooterNavS>
    </>
  );
});
export default FooterNavSp;

/**
 *
 * Styles
 *
 */
const FooterNavS = styled.nav`
  width: 100%;
  position: fixed;
  height: 10rem;
  bottom: 0;
  left: 0;
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;

  background: url(${footerMenu});
  background-size: cover;
  background-position: center;

  @media (min-width: 1025px) {
    display: none;
    position: static;
  }
`;

const UListS = styled.ul`
  display: flex;
  align-items: center;
  gap: 3rem;
`;

const ListS = styled.li``;

const ButtonWrapS = styled.div`
  color: #ffff;
  padding: 1rem 2rem;
  border-top: 0.1px solid #707070;
  border-bottom: 0.1px solid #707070;

  @media (min-width: 600px) {
    padding: 1.5rem 3rem;
  }
`;

const BoxButtonS = styled.button`
  font-size: 1.5rem;

  @media (min-width: 600px) {
    font-size: min(2.2vw, 1.6rem);
  }
`;

const LinkWrapS = styled.div`
  color: #ffff;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-top: 0.1px solid #707070;
  border-bottom: 0.1px solid #707070;

  @media (min-width: 600px) {
    font-size: min(2.2vw, 1.6rem);
    padding: 1.5rem 3rem;
  }
`;
