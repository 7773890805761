import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import FormLayout from 'components/templates/FormLayout';
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
// Custom hooks
import { usePointPurchaseCashAccount } from 'hooks/pages/private/usePointPurchaseCashAccount';
// Recoil
// Utils
import { pointPurchaseCashBreadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';
import copyIcon from 'assets/images/purchaseCc/icon_copy.svg';

/**
 *
 * Component
 *
 */
const PointPurchaseCashAccount: FC = memo(() => {
  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    referenceNumber,
    lastName,
    firstName,
    bankName,
    branchName,
    branchCode,
    accountType,
    accountNumber,
    accountName,
    copyReferenceNumber,
    copyAccountNumber,
    goBack,
  } = usePointPurchaseCashAccount();

  return (
    <>
      <Helmet>
        <title>{t('title.pointPurchaseCash')}</title>
        <meta name="description" content={t('description.pointPurchaseCash')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={pointPurchaseCashBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('現金で入金')}
        titleStyle="gold"
      >
        {/* ローディングアニメーション */}
        {isLoading && <LoaderWindow />}
        <FormS>
          <BlockS>
            {/* 振込口座情報部分 */}
            <BankInfoS>
              <LegendS>
                {t(
                  '支払額を以下の口座にお振込ください。',
                )}
              </LegendS>
              <BankInfoSquareS>
                <DlS>
                  <DtS><BankInfoTextS>{t('依頼人名')}</BankInfoTextS></DtS>
                  <DdS>
                    <BankInfoTextS>
                      {referenceNumber}
                      &emsp;
                      {lastName}
                      &emsp;
                      {firstName}
                    </BankInfoTextS>
                    <CopyButtonS type="button" onClick={copyReferenceNumber}>
                      <CopyIconS src={copyIcon} alt="" />
                    </CopyButtonS>
                  </DdS>
                </DlS>
                <DlS>
                  <DtS><BankInfoTextS>{t('銀行名')}</BankInfoTextS></DtS>
                  <DdS><BankInfoTextS>{bankName}</BankInfoTextS></DdS>
                </DlS>
                <DlS>
                  <DtS><BankInfoTextS>{t('支店名')}</BankInfoTextS></DtS>
                  <DdS><BankInfoTextS>{branchName}</BankInfoTextS></DdS>
                </DlS>
                <DlS>
                  <DtS><BankInfoTextS>{t('店番号')}</BankInfoTextS></DtS>
                  <DdS><BankInfoTextS>{branchCode}</BankInfoTextS></DdS>
                </DlS>
                <DlS>
                  <DtS><BankInfoTextS>{t('口座科目')}</BankInfoTextS></DtS>
                  <DdS><BankInfoTextS>{accountType}</BankInfoTextS></DdS>
                </DlS>
                <DlS>
                  <DtS><BankInfoTextS>{t('口座番号')}</BankInfoTextS></DtS>
                  <DdS>
                    <BankInfoTextS>{accountNumber}</BankInfoTextS>
                    <CopyButtonS type="button" onClick={copyAccountNumber}>
                      <CopyIconS src={copyIcon} alt="" />
                    </CopyButtonS>
                  </DdS>
                </DlS>
                <DlS>
                  <DtS><BankInfoTextS>{t('口座名義')}</BankInfoTextS></DtS>
                  <DdS><BankInfoTextS>{accountName}</BankInfoTextS></DdS>
                </DlS>
              </BankInfoSquareS>
            </BankInfoS>
          </BlockS>

          <RoundButtonWrapS>
            <RoundButton
              onClick={goBack}
              background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
              borderColor="rgba(0,135,167,1)"
            >
              {t('1つ前に戻る')}
            </RoundButton>
          </RoundButtonWrapS>
        </FormS>
      </FormLayout>
    </>
  );
});
export default PointPurchaseCashAccount;

/**
 *
 * Styles
 *
 */
const FormS = styled.div`
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6rem;

  @media (min-width: 1025px) {
    width: 90%;
    margin-top: 7rem;
  }
`;

const BlockS = styled.div`
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BankInfoS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.2rem;

  @media (min-width: 600px) {
    row-gap: 1.4rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
    row-gap: 1.6rem;
  }
`;

const LegendS = styled.legend`
  width: 100%;
  line-height: 1.5;
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const BankInfoSquareS = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  column-gap: 1rem;
  padding: 1.6rem;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #f502fd;
  row-gap: 2rem;

  @media (min-width: 600px) {
    padding: 1.8rem;
  }

  @media (min-width: 1025px) {
    padding: 2.5rem;
  }
`;

const DlS = styled.dl`
  display: flex;
  justify-content: space-between;
`;

const DtS = styled.dt`
  width: 20%;

  @media (min-width: 600px) {
    width: 12%;
  }
`;

const DdS = styled.dd`
  display: flex;
  align-items: center;
  column-gap: 1.2rem;
  width: 77%;

  @media (min-width: 600px) {
    width: 86%;
  }
`;

const BankInfoTextS = styled.p`
  font-size: 1.4rem;
  word-break: break-word;
  line-height: 1.4;
  width: auto;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.8rem;
  }
`;

const CopyButtonS = styled.button`
  width: clamp(1.2rem, 4.7vw, 2rem);
`;

const CopyIconS = styled.img`
  width: 100%;
`;

const RoundButtonWrapS = styled.div`
  width: 200px;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 220px;
    font-size: 1.6rem;
  }
`;
