import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import FormLayout from 'components/templates/FormLayout';
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { useInquiryConfirm } from 'hooks/pages/common/useInquiryConfirm';
// Utils
import { inquiryConfirmBreadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const InquiryConfirm: FC = () => {
  // i18next
  const { t } = useTranslation();
  // お問い合わせ内容確認ページ用カスタムフック
  const {
    isLoading,
    referrer,
    email,
    name,
    body,
    errorMessage,
    redirectToInquiry,
    toBackInquiry,
    postApiInquirySend,
    cleanUpMethod,
  } = useInquiryConfirm();

  useEffect(() => {
    // お問合せフォーム以外からアクセスした場合TOPへリダイレクト
    if (referrer !== '/inquiry') {
      redirectToInquiry();
    }

    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', null);
    // ブラウザバックor進むをしたらお問い合わせフォームページにリダイレクト
    window.addEventListener('popstate', toBackInquiry, false);

    return () => {
      cleanUpMethod();
      window.removeEventListener('popstate', toBackInquiry, false);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.contactConfirmation')}</title>
        <meta name="description" content={t('description.contactConfirmation')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={inquiryConfirmBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('お問い合わせ内容の確認')}
        titleStyle="silver"
      >
        <TextWrapS>
          <TextS>{t('内容に誤りがないか、よくご確認ください。')}</TextS>
        </TextWrapS>

        {/* エラーメッセージ */}
        {errorMessage && (
          <ErrorMessageWrapS>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </ErrorMessageWrapS>
        )}

        <ContainerS>
          <UListS>
            <ListS>
              <FieldS>{t('氏名')}</FieldS>
              <ValueS>{name}</ValueS>
            </ListS>
            <ListS>
              <FieldS>{t('メールアドレス')}</FieldS>
              <ValueS>{email}</ValueS>
            </ListS>
            <ListS>
              <FieldS>{t('お問い合わせ内容')}</FieldS>
              <ValueS>{body}</ValueS>
            </ListS>
          </UListS>
        </ContainerS>

        {/* ローディングアニメーション */}
        {isLoading && <LoaderWindow />}

        <ButtonGroupS>
          <RoundButtonWrapS className="button-fix">
            {isLoading ? (
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {t('修正する')}
              </RoundButton>
            ) : (
              <RoundButton
                onClick={toBackInquiry}
                background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                borderColor="rgba(0,135,167,1)"
              >
                {t('修正する')}
              </RoundButton>
            )}
          </RoundButtonWrapS>
          <RoundButtonWrapS className="button-confirm">
            {isLoading ? (
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {t('送信する')}
              </RoundButton>
            ) : (
              <RoundButton
                onClick={postApiInquirySend}
                background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                borderColor="rgba(219,0,177,1)"
              >
                {t('送信する')}
              </RoundButton>
            )}
          </RoundButtonWrapS>
        </ButtonGroupS>
      </FormLayout>
    </>
  );
};
export default InquiryConfirm;

/**
 *
 * Styles
 *
 */
const TextWrapS = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
    margin-top: 2.4rem;
    margin-bottom: 3rem;
  }

  @media (min-width: 1025px) {
    margin-top: 3rem;
    width: 50%;
  }
`;

const TextS = styled.p`
  color: #ffffff;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }
`;

const ErrorMessageWrapS = styled.div`
  width: 100%;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: flex-start;
  word-break: break-word;

  @media (min-width: 600px) {
    width: 60%;
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    width: 50%;
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
  }
`;

const ContainerS = styled.div`
  width: 100%;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  margin-bottom: 2rem;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1025px) {
    width: 50%;
    margin-bottom: 3.4rem;
  }
`;

const UListS = styled.ul`
  width: 100%;
`;

const ListS = styled.li`
  margin-bottom: 2rem;
  color: #ffffff;
  word-break: break-word;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FieldS = styled.p`
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
`;

const ValueS = styled.p`
  font-size: 1.6rem;
  white-space: pre-line;
  line-height: 1.7;
`;

const ButtonGroupS = styled.div`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 1025px) {
    flex-direction: row;
    width: 50%;
  }
`;

const RoundButtonWrapS = styled.div`
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }

  &.button-fix {
    width: 40%;
    margin-bottom: 2rem;

    @media (min-width: 600px) {
      width: 30%;
    }

    @media (min-width: 1025px) {
      width: 30%;
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }

  &.button-confirm {
    width: 55%;

    @media (min-width: 600px) {
      width: 40%;
    }

    @media (min-width: 1025px) {
      width: 45%;
    }
  }
`;
