const prefix = 'DayLott\'s - ';

export const viSeo = {
  title: {
    // Global pages
    top: `${prefix}Đầu trang`,
    emailSend: `${prefix}Đã hoàn tất quá trình truyền email`,
    emailVerified: `${prefix}xác thực email`,
    whats: `${prefix}Day Lott's là gì?`,
    system: `${prefix}Hệ thống`,
    faq: `${prefix}Hỏi Đáp`,
    ranking: `${prefix}Bảng xếp hạng`,
    support: `${prefix}Hỗ trợ`,
    responsibleGambling: `${prefix}Chính sách cờ bạc có trách nhiệm`,
    newsShow: `${prefix}Tin tức`,
    contact: `${prefix}Liên hệ với Chúng tôi`,
    contactConfirmation: `${prefix}Xác nhận nội dung liên hệ`,
    contactCompleted: `${prefix}Đã hoàn tất liên hệ`,
    passwordReset: `${prefix}Đặt lại mật khẩu`,
    passwordResetForm: `${prefix}Biểu mẫu đặt lại mật khẩu`,
    passwordResetCompleted: `${prefix}Đã hoàn tất đặt lại mật khẩu.`,
    // Guest pages
    register: `${prefix}Tạo tài khoản`,
    registerConfirmation: `${prefix}Tạo tài khoản`,
    login: `${prefix}Đăng nhập`,
    accountDeleteCompleted: `${prefix}Đã hoàn tất việc tạm ngưng tài khoản`,
    // Private pages
    mypage: `${prefix}Trang của tôi`,
    accountDeleteConfirmation: `${prefix}Tạm ngưng tài khoản`,
    kycUpload: `${prefix}Tải lên KYC`,
    kycConfirmation: `${prefix}Xác nhận KYC`,
    kycCompleted: `${prefix}KYC đã hoàn thành`,
    emailChange: `${prefix}Thay đổi địa chỉ email`,
    pointPurchase: `${prefix}Mua điểm`,
    pointPurchaseCc: `${prefix}Nạp tiền bằng tiền điện tử`,
    pointPurchaseCredit: `${prefix}Nạp tiền bằng thẻ tín dụng`,
    pointPurchaseCash: `${prefix}Nạp tiền bằng tiền mặt`,
    pointPurchaseCompleted: `${prefix}Đã hoàn tất quá trình truyền thông tin giao dịch thanh toán`,
    userNewsIndex: `${prefix}Danh sách tin tức`,
    userNewsShow: `${prefix}Tin tức`,
    userNewsSkip: `${prefix}Nhận điểm kiếm được`,
    lottery: `${prefix}Vé số`,
    ticketPurchaseConfirmation: `${prefix}Xác nhận Thông tin Mua`,
    ticketPurchaseCompleted: `${prefix}Hoàn tất mua vé`,
    withdrawalTop: `${prefix}Điểm tiền gửi rút tiền trao đổi`,
    withdrawalCc: `${prefix}Quy trình chuyển tiền`,
    withdrawalFinish: `${prefix}Giao dịch rút/đổi đã được chấp nhận.`,
  },
  description: {
    // Global pages
    top: 'DayLott\'s là trò chơi xổ số trực tuyến đầu tiên trên thế giới phát sinh ra các triệu phú mỗi ngày.',
    emailSend: `${prefix}Trang hoàn tất quá trình truyền email.`,
    emailVerified: `${prefix}Trang xác minh email.`,
    whats: 'DayLott\'s là trò chơi xổ số trực tuyến đầu tiên trên thế giới phát sinh ra các triệu phú mỗi ngày.',
    system: 'Một trò chơi xổ số trực tuyến với 75% cơ hội chiến thắng tuyệt vời. Các bảng được chuẩn bị theo số tiền đặt cược được vào và số tiền thắng. Các quy tắc là một hệ thống đơn giản, nơi bạn chỉ cần mua một vé và nhập.',
    faq: 'Đây là trang Câu hỏi thường gặp về DayLott\'s.',
    ranking: 'Đây là trang xếp hạng người chiến thắng của DayLott\'s.',
    support: 'Đây là trang hỗ trợ cho các câu hỏi thường gặp về DayLott\'s, các câu hỏi qua email, thời gian yêu cầu, v.v.',
    responsibleGambling: 'Chúng tôi cam kết mang đến cho bạn trải nghiệm cờ bạc thú vị nhất có thể, nhưng chúng tôi cũng nhận ra rằng cờ bạc có thể gây ra vấn đề cho một số người. Để đảm bảo rằng bạn có thể tiếp tục tận hưởng trò chơi an toàn và có thể quản lý được, chúng tôi ủng hộ việc đánh bạc có trách nhiệm và đã áp dụng một số biện pháp để hỗ trợ bạn.',
    newsShow: `${prefix}Trang tin tức.`,
    contact: 'Đây là trang liên hệ của DayLott\'s. Chúng tôi chấp nhận liên hệ từ khách hàng bằng cách sử dụng biểu mẫu liên hệ.',
    contactConfirmation: `${prefix}Trang xác nhận liên hệ.`,
    contactCompleted: `${prefix}Liên hệ trang hoàn thành.`,
    passwordReset: `${prefix}Trang đặt lại mật khẩu.`,
    passwordResetForm: `${prefix}Biểu mẫu đặt lại mật khẩu.`,
    passwordResetCompleted: `${prefix}Trang đã hoàn tất đặt lại mật khẩu.`,
    // Guest pages
    register: 'Đăng ký làm thành viên của DayLott\'s và tích điểm! Mua vé số và tham gia trò chơi!',
    registerConfirmation: `${prefix}Xác nhận nội dung đầu vào.`,
    login: 'Vui lòng nhập địa chỉ email và mật khẩu đã đăng ký trong DayLott\'s để đăng nhập, nếu bạn sử dụng lần đầu, vui lòng đăng ký làm thành viên.',
    accountDeleteCompleted: `${prefix}Đã hoàn thành trang tạm ngưng tài khoản.`,
    // Private pages
    mypage: 'Đây là trang của tôi dành cho người dùng của DayLott\'s.',
    accountDeleteConfirmation: `${prefix}Trang tạm ngưng tài khoản.`,
    kycUpload: `${prefix}Trang tải lên KYC.`,
    kycConfirmation: `${prefix}Trang xác nhận KYC.`,
    kycCompleted: `${prefix}KYC Trang đã hoàn thành.`,
    emailChange: `${prefix}Trang thay đổi địa chỉ email.`,
    pointPurchase: `${prefix}Trang mua điểm.`,
    pointPurchaseCc: `${prefix}Trang gửi tiền bằng tiền điện tử.`,
    pointPurchaseCredit: `${prefix}Trang gửi tiền bằng tín dụng.`,
    pointPurchaseCash: `${prefix}Trang gửi tiền bằng tiền mặt.`,
    pointPurchaseCompleted: `${prefix}Thông tin tiền gửi đã được gửi.`,
    userNewsIndex: `${prefix}Trang cho danh sách tin tức.`,
    userNewsShow: `${prefix}Trang tin tức.`,
    userNewsSkip: `${prefix}Trang nhận điểm đã kiếm được`,
    lottery: `${prefix}Trang cho xổ số.`,
    ticketPurchaseConfirmation: `${prefix}Trang xác nhận mua vé.`,
    ticketPurchaseCompleted: `${prefix}Trang hoàn tất mua vé.`,
    withdrawalTop: `${prefix}Trang trao đổi rút tiền gửi điểm.`,
    withdrawalCc: `${prefix}Trang thủ tục chuyển tiền.`,
    withdrawalFinish: `${prefix}Giao dịch rút/đổi đã được chấp nhận.`,
  },
};
