import { atom } from 'recoil';
import { ATOM_KEYS } from 'utils/const/recoilKeys';
// Types
import { Winner } from 'types/lottery/winner';

export const winnersState = atom<Winner[]>({
  key: ATOM_KEYS.WINNERS_STATE,
  default: [],
});

export const specialPointsState = atom<number>({
  key: ATOM_KEYS.SPECIAL_POINTS_STATE,
  default: 0,
});
