import { FC, memo } from 'react';
// External library
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Components
import LoaderPart from 'components/atoms/loader/LoaderPart';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
import { winnersState } from 'store/atoms/lottery/winnersState';
// Utils
import { LANG } from 'utils/const/lang';
import { devices } from 'utils/const/devices';
// Images
import barNeonBottomPc from 'assets/images/neonItem/bar-bottom_pc.png';
import titleJaSp from 'assets/images/lottery/winning/title_ja_sp.png';
import titleJaPc from 'assets/images/lottery/winning/title_ja_pc.png';
import titleJaPc2x from 'assets/images/lottery/winning/title_ja_pc@2x.png';
import titleEnSp from 'assets/images/lottery/winning/title_en_sp.png';
import titleEnPc from 'assets/images/lottery/winning/title_en_pc.png';
import titleEnPc2x from 'assets/images/lottery/winning/title_en_pc@2x.png';
import titleZhCnSp from 'assets/images/lottery/winning/title_zh_cn_sp.png';
import titleZhCnPc from 'assets/images/lottery/winning/title_zh_cn_pc.png';
import titleZhCnPc2x from 'assets/images/lottery/winning/title_zh_cn_pc@2x.png';
import titleZhTwSp from 'assets/images/lottery/winning/title_zh_tw_sp.png';
import titleZhTwPc from 'assets/images/lottery/winning/title_zh_tw_pc.png';
import titleZhTwPc2x from 'assets/images/lottery/winning/title_zh_tw_pc@2x.png';
import titleKoSp from 'assets/images/lottery/winning/title_ko_sp.png';
import titleKoPc from 'assets/images/lottery/winning/title_ko_pc.png';
import titleKoPc2x from 'assets/images/lottery/winning/title_ko_pc@2x.png';
import titleViSp from 'assets/images/lottery/winning/title_vi_sp.png';
import titleViPc from 'assets/images/lottery/winning/title_vi_pc.png';
import titleViPc2x from 'assets/images/lottery/winning/title_vi_pc@2x.png';
import titleThSp from 'assets/images/lottery/winning/title_th_sp.png';
import titleThPc from 'assets/images/lottery/winning/title_th_pc.png';
import titleThPc2x from 'assets/images/lottery/winning/title_th_pc@2x.png';

const {
  mobileLandscape,
  tabletPortrait,
  tabletLandscape,
  pc,
} = devices;

const {
  JAPANESE,
  SIMPLE_CHINESE,
  TRADITIONAL_CHINESE,
  KOREAN,
  VIETNAM,
  THAILAND,
} = LANG;

/**
 *
 * Types
 *
 */
type Props = {
  isLoading: boolean;
  scrollLastFlag: boolean;
  getApiWinners: (isNext: boolean) => void;
};

/**
 *
 * Component
 *
 */
const WinnersBox: FC<Props> = memo((props) => {
  const { isLoading, scrollLastFlag, getApiWinners } = props;

  // Hooks
  const { t } = useTranslation();

  // Recoil
  const lang = useRecoilValue(langState);
  const winners = useRecoilValue(winnersState);

  const clickNext = () => {
    getApiWinners(true);
  };

  return (
    <WinnersS>
      <TitleS>
        <source
          srcSet={
            (lang === JAPANESE)
              ? `${titleJaPc} 1x, ${titleJaPc2x} 2x`
              : (lang === SIMPLE_CHINESE)
                ? `${titleZhCnPc} 1x, ${titleZhCnPc2x} 2x`
                : (lang === TRADITIONAL_CHINESE)
                  ? `${titleZhTwPc} 1x, ${titleZhTwPc2x} 2x`
                  : (lang === KOREAN)
                    ? `${titleKoPc} 1x, ${titleKoPc2x} 2x`
                    : (lang === VIETNAM)
                      ? `${titleViPc} 1x, ${titleViPc2x} 2x`
                      : (lang === THAILAND)
                        ? `${titleThPc} 1x, ${titleThPc2x} 2x`
                        : `${titleEnPc} 1x, ${titleEnPc2x} 2x`
          }
          media="(min-width: 600px)"
        />
        <ImageS
          src={
            (lang === JAPANESE)
              ? titleJaSp
              : (lang === SIMPLE_CHINESE)
                ? titleZhCnSp
                : (lang === TRADITIONAL_CHINESE)
                  ? titleZhTwSp
                  : (lang === KOREAN)
                    ? titleKoSp
                    : (lang === VIETNAM)
                      ? titleViSp
                      : (lang === THAILAND)
                        ? titleThSp
                        : titleEnSp
          }
          alt=""
        />
      </TitleS>

      {isLoading && <LoaderPart zIndex={1} />}

      {winners.length > 0 && (
        <WinnersUListS>
          {winners.map((v) => (
            <WinnersListS key={uuidv4()} className={v.isShow ? 'visible' : 'hidden'}>
              <WinnerIconS className={v.isShow ? 'visible' : 'hidden'}>★</WinnerIconS>
              <WinnerNameS className={v.isShow ? 'visible' : 'hidden'}>{v.isShow && v.name}</WinnerNameS>
            </WinnersListS>
          ))}
        </WinnersUListS>
      )}
      {(winners.filter((v) => v.isShow).length > 0 && !scrollLastFlag) && (
        <ReadMoreButtonWrapS>
          <ReadMoreButtonS type="button" onClick={clickNext}>
            {t('さらに表示する')}
          </ReadMoreButtonS>
        </ReadMoreButtonWrapS>
      )}
      <NeonBarPcS>
        <ImageS src={barNeonBottomPc} alt="" />
      </NeonBarPcS>
    </WinnersS>
  );
});
export default WinnersBox;

/**
 *
 * Styles
 *
 */
const WinnersS = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 18vw;
  background-image: linear-gradient(#330011, #4d0046 80%);
  // flex
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${mobileLandscape} {
    padding-bottom: 0;
    margin-bottom: 6rem;
  }

  @media ${tabletPortrait} {
    padding-bottom: 16vw;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 3rem;
    padding-bottom: 0;
  }
`;

const WinnersUListS = styled.ul`
  height: 50vw;
  width: 100%;
  overflow: scroll;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: calc(100% / 5);
  grid-gap: 0.5rem;

  @media ${mobileLandscape} {
    height: 250px;
  }

  @media ${tabletPortrait} {
    height: 400px;
    width: 95%;
    margin: 0 auto;
  }

  @media ${tabletLandscape}, ${pc} {
    height: 300px;
    width: 100%;
  }
`;

const WinnersListS = styled.li`
  background-color: rgba(0, 0, 0, 0);
  padding: 1rem;
  
  
  &.visible {
    background-color: rgba(255, 255, 255, 0.1);
    // flex
    display: flex;
    align-items: center;
    }
  &.hidden {
    background-color: rgba(0, 0, 0, 0.1);
    display: none;
    align-items: center;
    }

  @media ${tabletPortrait} {
    padding: 1.2rem;
  }
`;

const WinnerIconS = styled.div`
  font-size: 1.4rem;
  color: #ffd400;
  margin-right: 0.5rem;

  &.visible {
    visibility: visible;
    }
  &.hidden {
    visibility: hidden;
    }

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const WinnerNameS = styled.p`
  font-size: 1.4rem;
  word-break: break-all;

  &.visible {
    visibility: visible;
    }
  &.hidden {
    visibility: hidden;
    }

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const TitleS = styled.picture`
  width: 100%;
  margin-bottom: 1rem;
  display: inline-block;
  margin-top: calc(-27vw / 2);
  z-index: 2;

  @media ${mobileLandscape} {
    margin-top: calc(-12vw / 2);
  }

  @media ${tabletPortrait} {
    margin-top: calc(-22vw / 2);
  }

  @media ${tabletLandscape}, ${pc} {
    margin-top: -10%;
  }
`;

const NeonBarPcS = styled.div`
  display: none;

  @media ${mobileLandscape}, ${devices.tabletLandscape}, ${pc} {
    width: 100%;
    display: flex;
  }
`;

const ImageS = styled.img`
  width: 100%;
`;

const ReadMoreButtonWrapS = styled.div`
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  word-break: break-all;
  border-top: 1px solid #707070;

  @media (min-width: 1025px) {
    border-top: 0;
  }
`;

const ReadMoreButtonS = styled.button`
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
