import { useEffect, useCallback, useState } from 'react';
// External library
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
// Hooks
import { useRedirect } from 'hooks/versatile/useRedirect';
import { LocationState as TopLocationState } from 'hooks/pages/private/useWithdrawalTop';
import { LocationState as FinishLocationState } from 'hooks/pages/private/useWithdrawalFinish';
import { axiosCancelToken, axiosClient } from 'utils/axios';
import { useError } from 'hooks/versatile/useError';

/**
 *
 * Types
 *
 */
export type LocationState = {
  referrer: string;
  exitPoints: number;
  balancePoints: number;
  usdt: number;
  address: string;
};

type ItemList = {
  name: string;
  value: string;
}[];

type UseWithdrawalCc = () => {
  isLoading: boolean;
  itemList: ItemList;
  onSubmit: () => void;
  goToWithdrawalTopPage: () => void;
};

/**
 *
 * Hooks
 *
 */
export const useWithdrawalCc: UseWithdrawalCc = () => {
  const {
    referrer,
    exitPoints,
    balancePoints,
    usdt,
    address,
  } = useLocation<LocationState>().state ?? {
    referrer: '',
    exitPoints: 0,
    balancePoints: 0,
    usdt: 0,
    address: '',
  };

  // 出金ページ以外からのアクセスはトップページへリダイレクト
  const { redirectToTopPage } = useRedirect();
  useEffect(() => {
    if (referrer !== '/withdrawal') redirectToTopPage();
    window.scrollTo({ top: 0 });
  }, []);

  const itemList: ItemList = [
    {
      name: '出金・交換ポイント数',
      value: `${exitPoints} P`,
    },
    {
      name: 'ポイント残高',
      value: `${balancePoints} P`,
    },
    {
      name: '送金額',
      value: `${usdt} USDT`,
    },
    {
      name: '送金先アドレス',
      value: address,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const reactHistory = useHistory();
  const goToWithdrawalTopPage = useCallback(() => {
    const state: TopLocationState = { referrer: '/withdrawal/cc' };
    reactHistory.push({
      pathname: '/withdrawal',
      state,
    });
  }, []);
  const goToWithdrawalFinishPage = useCallback(() => {
    const state: FinishLocationState = { referrer: '/withdrawal/cc' };
    reactHistory.push({
      pathname: '/withdrawal/finish',
      state,
    });
  }, []);

  const [errorMessage, setErrorMessage] = useState('');
  const { errorHandler } = useError();

  // 出金リクエスト
  const withdrawalRequestSource = axiosCancelToken.source();
  const postApiWithdrawRequest = () => {
    setIsLoading(true);

    axiosClient
      .post<boolean>('/api/withdraw/request/withdraw', {
        point: exitPoints,
        to_address: address,
        cancelToken: withdrawalRequestSource.token,
      })
      .then(({ data }) => {
        if (data) goToWithdrawalFinishPage();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) setErrorMessage(errorHandler(error.response));
      })
      .finally(() => setIsLoading(false));
  };

  const onSubmit = useCallback(() => {
    postApiWithdrawRequest();
  }, []);

  return {
    errorMessage,
    isLoading,
    itemList,
    onSubmit,
    goToWithdrawalTopPage,
  };
};
