import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { LANG } from 'utils/const/lang';
// Images
import pointOffButtonJa from 'assets/images/mypage/ja/button_point-off.svg';
import pointOffButtonEn from 'assets/images/mypage/en/button_point-off.svg';
import pointOffButtonZhCn from 'assets/images/mypage/zhCn/button_point-off.svg';
import pointOffButtonZhTw from 'assets/images/mypage/zhTw/button_point-off.svg';
import pointOffButtonKo from 'assets/images/mypage/ko/button_point-off.svg';
import pointOffButtonVi from 'assets/images/mypage/vi/button_point-off.svg';
import pointOffButtonTh from 'assets/images/mypage/th/button_point-off.svg';

/**
 *
 * component
 *
 */
const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

const WithdrawalOffButton: FC = memo(() => {
  const lang = useRecoilValue(langState);

  return (
    <WithdrawalOffButtonS
      src={
        lang === JAPANESE
          ? pointOffButtonJa
          : lang === SIMPLE_CHINESE
            ? pointOffButtonZhCn
            : lang === TRADITIONAL_CHINESE
              ? pointOffButtonZhTw
              : lang === KOREAN
                ? pointOffButtonKo
                : lang === VIETNAM
                  ? pointOffButtonVi
                  : lang === THAILAND
                    ? pointOffButtonTh
                    : pointOffButtonEn
      }
      alt=""
    />
  );
});
export default WithdrawalOffButton;

/**
 *
 * Styles
 *
 */
const WithdrawalOffButtonS = styled.img`
  width: 100%;
`;
