import { FC, ReactNode, memo } from 'react';
// External library
import styled from 'styled-components';
// Components
import BreadCrumbs from 'components/atoms/breadCrumbs/BreadCrumbs';
// Images
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';
import backGroundSp from 'assets/images/backGround/main_sp.jpg';

/**
 *
 * Props
 *
 */
type Props = {
  children: ReactNode;
  breadCrumbItems: {
    path: string;
    name: string;
  }[];
};

/**
 *
 * Component
 *
 */
const NewsLayout: FC<Props> = memo((props) => {
  const { children, breadCrumbItems } = props;

  return (
    <NewsLayoutS>
      <ContainerS>
        {/* パンくずリスト(PC用) */}
        <BreadCrumbsWrapS>
          <BreadCrumbs crumbItems={breadCrumbItems} />
        </BreadCrumbsWrapS>

        {/* コンテンツ */}
        {children}
      </ContainerS>
    </NewsLayoutS>
  );
});
export default NewsLayout;

/**
 *
 * Styles
 *
 */
const NewsLayoutS = styled.main`
  /* background */
  background: url(${backGroundSp});
  background-size: cover;
  background-repeat: round;
  background-attachment: fixed;

  @media (min-width: 1025px) {
    background: image-set(url(${backGroundPc}) 1x, url(${backGroundPc2x}) 2x);
    background-position: top;
  }
`;

const ContainerS = styled.div`
  width: 100%;
  min-height: 90vh;
  margin: 0 auto;
  padding-top: 6.5rem;
  padding-bottom: 5rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @media (min-width: 600px) {
    padding-top: 12rem;
  }

  @media (min-width: 1025px) {
    max-width: 100rem;
    padding-top: 2rem;
    padding-bottom: 10rem;
  }
`;

const BreadCrumbsWrapS = styled.div`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    width: 100%;
    margin-bottom: 4rem;
  }
`;
