import { FC, memo } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Component
 *
 */
const GoogleRecaptcha: FC = memo(() => (
  <GoogleRecaptchaS>
    <TextS>
      This site is protected by reCAPTCHA and the Google
      {' '}
      <LinkS href="https://policies.google.com/privacy">Privacy Policy</LinkS>
      {' '}
      and
      {' '}
      <LinkS href="https://policies.google.com/terms">Terms of Service</LinkS>
      {' '}
      apply.
    </TextS>
  </GoogleRecaptchaS>
));
export default GoogleRecaptcha;

/**
 *
 * Styles
 *
 */
const GoogleRecaptchaS = styled.div`
  width: 100%;
  padding: 0.7rem 1rem;
  background-color: #f9f9f9;
  border-radius: 2px;
`;

const TextS = styled.p`
  color: #888888;
  line-height: 1.4;
`;

const LinkS = styled.a`
  color: #4a90e2;
`;
