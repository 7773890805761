// SEO
import { HelmetProvider } from 'react-helmet-async';
// Styles
import 'destyle.css';
import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
// Route
import { BrowserRouter } from 'react-router-dom';
import Router from 'routes/Router';
// Components
import { ToastContainer } from 'react-toastify';
// Recoil
import { RecoilRoot } from 'recoil';
// Utils
import 'utils/i18n';

const App = (): JSX.Element => (
  <HelmetProvider>
    <GlobalStyle />
    <RecoilRoot>
      <BrowserRouter>
        <Router />
        <ToastContainer />
      </BrowserRouter>
    </RecoilRoot>
  </HelmetProvider>
);
export default App;

/**
 *
 * Styles
 *
 */
const GlobalStyle = createGlobalStyle`
  html {
    font-size: 62.5%;
  }

  body {
    display: flex;
    font-family: 'Noto Sans JP', sans-serif;
  }

  #root {
    display: flex;
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
  }

  main {
    width: 100%;
    flex: 1;
  }

  // 画像ドラッグ&右クリック保存無効
  img {
    pointer-events: none;
  }

  // input type="number"のスピナーボタン非表示
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance:textfield;
  }

  // Google reCAPTCHA用バッジ非表示
  .grecaptcha-badge { visibility: hidden; }

  // react-toastiry用スタイル
  .Toastify__toast-body {
    font-size: 1.4rem;
    color: #000000;

    @media (min-width: 600px) {
      font-size: 1.6rem;
    }
  }
  video::-webkit-media-controls-timeline {
    display: none;
  }
  video::-webkit-media-controls-current-time-display{
    display: none;
  }
  video::-webkit-media-controls-time-remaining-display {
    display: none;
  }
 }
`;
