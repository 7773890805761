import { atom } from 'recoil';
// Utils
import { ATOM_KEYS } from 'utils/const/recoilKeys';
import { PRODUCTION_END_FLAG } from 'utils/const/lottery/lotBox';
// Types
import { ProductionEndFlag } from 'types/lottery/productionEnfFlag';

/**
 *
 * 抽選スキップフラグ
 *
 */
export const productionEndFlagState = atom<ProductionEndFlag>({
  key: ATOM_KEYS.PRODUCTION_END_FLAG_STATE,
  default: PRODUCTION_END_FLAG.FALSE,
});
