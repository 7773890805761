// External library
import { useEffect } from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
// Hooks
import { useRedirect } from 'hooks/versatile/useRedirect';

/**
 *
 * Types
 *
 */
export type LocationState = {
  referrer: string;
};

type UseWithdrawalFinish = () => void;

/**
 *
 * Hooks
 *
 */
export const useWithdrawalFinish: UseWithdrawalFinish = () => {
  const { referrer } = useLocation<LocationState>().state ?? { referrer: '' };
  const { redirectToTopPage } = useRedirect();
  const history = useHistory();
  const goToTopPage = () => history.push('/');
  useEffect(() => {
    if (referrer !== '/withdrawal/cc') redirectToTopPage();
    window.addEventListener('popstate', goToTopPage, false);

    return () => {
      window.removeEventListener('popstate', goToTopPage, false);
    };
  }, []);
};
