import { FC, memo, useEffect } from 'react';
// External library
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Components
import GlobalModal from 'components/organisms/modal/GlobalModal';
import HeaderMenuSp from 'components/organisms/layouts/header/HeaderMenuSp';
import BatchIcon from 'components/atoms/icon/BatchIcon';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
// Custom hooks
import { useHeader } from 'hooks/organisms/useHeader';
import { useUfcTransition } from 'hooks/organisms/useUfcTransition';
// Recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from 'store/atoms/userState';
import { userPointDisplayState } from 'store/selectors/userPointDisplayState';
import { userBetsCountDisplayState } from 'store/selectors/userBetsCountDisplayState';
import { langState } from 'store/atoms/langState';
import { topVideoState } from 'store/atoms/topVideoState';
import { unlotteryBoxesState } from 'store/atoms/unlotteryBoxesState';
import { isChangeLangState } from 'store/atoms/isChangeLangState';
// Utils
import { devices } from 'utils/const/devices';
import { fetchBrowserLang } from 'utils/i18n';
// Images
import backGroundPc from 'assets/images/backGround/header_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/header_pc@2x.jpg';
import logo from 'assets/images/header/logo.svg';
import bell from 'assets/images/header/bell.svg';
import ticket from 'assets/images/header/ticket.svg';
import meatBall from 'assets/images/header/meatball.svg';
import globe from 'assets/images/header/language.svg';
import city from 'assets/images/header/back_city_button_tx.png';

/**
 *
 * Component
 *
 */
const Header: FC = memo(() => {
  // Recoil
  const { loggedIn, unreadNewsUsersCount, name } = useRecoilValue(userState);
  const point = useRecoilValue(userPointDisplayState);
  const unlotteryBetsCount = useRecoilValue(userBetsCountDisplayState);
  const lang = useRecoilValue(langState);
  const isChangeLang = useRecoilValue(isChangeLangState);
  const unlotteryBoxes = useRecoilValue(unlotteryBoxesState);
  const setTopVideo = useSetRecoilState(topVideoState);

  // ブラウザの設定言語
  const browserLang = fetchBrowserLang();

  // Hooks
  const { t } = useTranslation();
  const {
    isMenuShow,
    isLoading,
    langOptions,
    isLangOptionsShow,
    isBoxMenuShow,
    toggleMenuShow,
    pushToNewsUsers,
    pushToPlayHistory,
    getApiLogout,
    postApiChangeLang,
    setIsLangOptionsShow,
    onClickGlobeIcon,
    onClickTicketIcon,
    closeMenu,
    getVideoUrl,
    cleanUpMethod,
  } = useHeader();

  const {
    transitToUfc,
  } = useUfcTransition();

  useEffect(() => {
    // 言語設定メニュー表示ステート更新
    setIsLangOptionsShow(false);
    // recoil TOP動画更新
    const argumentLang = isChangeLang ? lang : browserLang;
    setTopVideo(getVideoUrl(argumentLang));

    return () => {
      cleanUpMethod();
    };
  }, [lang]);

  // ページ遷移時に開いているチケットや言語メニューを閉じる
  const location = useLocation();
  useEffect(closeMenu, [location]);

  return (
    <HeaderS>
      <BlurS />
      <ContainerS>
        <Link className="link-logo" to="/">
          <LogoS>
            <LogoImageS src={logo} alt="" />
          </LogoS>
        </Link>
        <ButtonGroupWrapS>
          {isLoading && <LoaderWindow />}
          <SetLangS>
            <GlobeIconWrapS onClick={onClickGlobeIcon}>
              <GlobeIconS src={globe} alt="" />
            </GlobeIconWrapS>
            <LangOptionWrapS isShow={isLangOptionsShow}>
              <TriangleS />
              <LangOptionBoxS>
                <LangGridS>
                  {langOptions.length > 0
                    && langOptions.map((item) => (
                      <LangS key={uuidv4()}>
                        <LabelS onClick={() => postApiChangeLang(item.value)}>
                          {item.label}
                        </LabelS>
                        {lang === item.value && <CheckS>✓</CheckS>}
                      </LangS>
                    ))}
                </LangGridS>
              </LangOptionBoxS>
            </LangOptionWrapS>
          </SetLangS>
          {loggedIn ? (
            <UserS>
              <Link className="link-news" to="/news_users/index">
                <NewsS>
                  <NewsImageS src={bell} alt="" />
                  {unreadNewsUsersCount && (
                    <NewsBatchIconS>
                      <BatchIcon>{unreadNewsUsersCount}</BatchIcon>
                    </NewsBatchIconS>
                  )}
                </NewsS>
              </Link>
              <TicketS>
                <TicketIconWrapS onClick={onClickTicketIcon} disabled={unlotteryBoxes.length === 0}>
                  <TicketImageS src={ticket} alt="" />
                  {!!unlotteryBetsCount && (
                  <TicketBatchIconS>
                    <BatchIcon>{unlotteryBetsCount}</BatchIcon>
                  </TicketBatchIconS>
                  )}
                </TicketIconWrapS>
                <TicketMenuWrapS isShow={isBoxMenuShow}>
                  <TicketTriangleS />
                  <TicketBoxS>
                    <BoxesS>
                      {unlotteryBoxes.length > 0
                        && unlotteryBoxes.map((item) => (
                          <BoxTitleS key={uuidv4()}>
                            <Link to={`/lottery/?id=${item.lot_tables_id.toString()}`}>
                              <LabelS>
                                {item.lot_boxes_title}
                              </LabelS>
                            </Link>
                          </BoxTitleS>
                        ))}
                    </BoxesS>
                  </TicketBoxS>
                </TicketMenuWrapS>
              </TicketS>
              <Link className="link-mypage" to="/mypage">
                <UserNameS>{name}</UserNameS>
                <UserPointS>
                  <UserPointTextS>{`${point} P`}</UserPointTextS>
                </UserPointS>
              </Link>
              <LogoutButtonWrapS>
                <LogoutButtonS type="button" onClick={() => getApiLogout()}>{t('ログアウト')}</LogoutButtonS>
              </LogoutButtonWrapS>
            </UserS>
          ) : (
            <SignButtonWrapS>
              <Link to="/login">
                <SignInButtonS type="button">{t('ログイン')}</SignInButtonS>
              </Link>
              <SignUpButtonS type="button" onClick={() => transitToUfc('register')}>{t('新規会員登録')}</SignUpButtonS>
            </SignButtonWrapS>
          )}
          <MeatBallS type="button" onClick={() => toggleMenuShow(true)}>
            <MeatBallImageS src={meatBall} alt="" />
          </MeatBallS>
        </ButtonGroupWrapS>
      </ContainerS>

      <GlobalModal isMenuShow={isMenuShow} toggleMenuShow={toggleMenuShow}>
        <HeaderMenuSp
          pushToNewsUsers={pushToNewsUsers}
          transitToUfc={transitToUfc}
          pushToPlayHistory={pushToPlayHistory}
          isLoading={isLoading}
          langOptions={langOptions}
          changeLangMethod={postApiChangeLang}
        />
      </GlobalModal>

      <HeaderMenuPcS>
        <MenuUListS>
          <MenuListS>
            <Link className="link-menu" to="/whats">
              {t("Day Lott'sとは")}
            </Link>
          </MenuListS>
          <MenuListS>
            <Link className="link-menu" to="/how">
              {t('仕組み')}
            </Link>
          </MenuListS>
          <MenuListS>
            <Link className="link-menu" to="/faq">
              {t('よくある質問')}
            </Link>
          </MenuListS>
          <MenuListS>
            <Link className="link-menu" to="/ranking">
              {t('ランキング')}
            </Link>
          </MenuListS>
          <MenuListS>
            <Link className="link-menu" to="/support">
              {t('サポート')}
            </Link>
          </MenuListS>
          <MenuListS>
            <CityButtonS className="link-menu" onClick={() => transitToUfc(null)}>
              <CityIconS src={city} alt="" />
            </CityButtonS>
          </MenuListS>
        </MenuUListS>
      </HeaderMenuPcS>
    </HeaderS>
  );
});
export default Header;

/**
 *
 * Styles
 *
 */
const { tabletPortrait, tabletLandscape, pc } = devices;

const HeaderS = styled.div`
  width: 100%;
  height: 5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  @media ${tabletLandscape}, ${pc} {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    position: initial;
    background-image: image-set(
      url(${backGroundPc}) 1x,
      url(${backGroundPc2x}) 2x
    );
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 1.6rem;
  }
`;

const BlurS = styled.div`
  width: 100%;
  height: 0.5rem;
  margin-top: -0.5rem;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 10px 20px 30px rgba(0 0 0 / 80%);

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;

const ContainerS = styled.div`
  height: 100%;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${tabletPortrait} {
    margin: 0.5rem auto 0;
  }

  @media ${tabletLandscape}, ${pc} {
    height: 8rem;
    width: 90%;
    margin: 0 auto;
    justify-content: center;
    position: relative;
    align-items: center;
  }

  .link-logo {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const LogoS = styled.div`
  height: 80%;
  padding: 0;

  @media ${tabletPortrait} {
    height: 100%;
  }

  @media ${tabletLandscape}, ${pc} {
    height: 80%;
  }
`;

const LogoImageS = styled.img`
  height: 100%;
`;

const ButtonGroupWrapS = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;

  @media ${tabletLandscape}, ${pc} {
    flex: initial;
    position: absolute;
    right: 0;
    column-gap: 2rem;
  }
`;

const MeatBallS = styled.button`
  height: 60%;
  margin-left: 6%;

  @media ${tabletPortrait} {
    height: 65%;
    margin-left: 5%;
  }

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;

const MeatBallImageS = styled.img`
  height: 100%;
`;

const SignButtonWrapS = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border: 1px solid #fff040;
  border-radius: 5px;
  overflow: hidden;
`;

const LogoutButtonWrapS = styled(SignButtonWrapS)`
  border: 1px solid #ffffff;
  grid-template-columns: repeat(1, 1fr);
`;

const SignButtonS = styled.button`
  width: 100%;
  height: 100%;
  padding: 0.7rem;
  font-size: clamp(1rem, 1.2vw, 1.2rem);
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${tabletPortrait} {
    font-size: 1.4rem;
    padding: 1rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.4rem;
    padding: 1rem 2rem;
  }
`;

const SignInButtonS = styled(SignButtonS)`
  background-color: #fff040;
  color: #000000;

  &:hover {
    background-color: #ffffff;
  }
`;

const SignUpButtonS = styled(SignButtonS)`
  background-color: #000000;
  color: #ffffff;

  &:hover {
    background-color: #ffffff;
    color: #000000;
  }
`;

const LogoutButtonS = styled(SignButtonS)`
  background-color: transparent;
  color: #ffffff;
  
  &:hover {
    background-color: #ffffff;
    color: #000000;
  }
`;

const UserS = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  .link-news,
  .link-mypage {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 2rem;
  }

  .link-mypage {
    width: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    @media ${tabletPortrait} {
      width: 70px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;

const BatchIconS = styled.div`
  position: absolute;
  height: 1.4rem;
  width: 1.4rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: #000000;

  @media ${tabletPortrait} {
    height: 1.8rem;
    width: 1.8rem;
    font-size: 1.2rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-weight: 600;
  }
`;

const NewsS = styled.div`
  height: 40%;
  position: relative;

  @media ${tabletPortrait} {
    height: 55%;
  }

  @media ${tabletLandscape}, ${pc} {
    height: 30%;
  }

  &:hover {
    opacity: 0.7;
  }
`;

const NewsImageS = styled.img`
  height: 100%;
`;

const NewsBatchIconS = styled(BatchIconS)`
  top: -20%;
  right: -50%;

  @media ${tabletPortrait} {
    right: -30%;
  }

  @media ${tabletLandscape}, ${pc} {
    right: -30%;
  }
`;

const TicketS = styled.div`
  height: 40%;
  position: relative;
  margin: 0 1rem 0 0;

  @media ${tabletPortrait} {
    height: 55%;
  }

  @media ${tabletLandscape}, ${pc} {
    height: 30%;
    margin: 0 1.2rem 0 0;
  }
`;

const TicketIconWrapS = styled.button`
  height: 100%;

  &:hover {
    opacity: 0.7;
  }
`;

const TicketImageS = styled.img`
  height: 100%;
`;

const TicketBatchIconS = styled(BatchIconS)`
  top: -20%;
  right: -25%;

  @media ${tabletPortrait} {
    right: -15%;
  }
`;

const TicketMenuWrapS = styled.div<{ isShow: boolean }>`
  height: 150px;
  width: 140px;
  position: absolute;
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;

  @media ${tabletLandscape}, ${pc} {
    width: 150px;
  }
`;

const TicketBoxS = styled.div`
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 7px;
`;

const BoxesS = styled.div`
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const BoxTitleS = styled.div`
  width: 100%;
  display: flex;
  column-gap: 0.5rem;
`;

const UserNameS = styled.p`
  width: 100%;
  margin-bottom: 0.5rem;
  color: #ffffff;
  font-size: 1.2rem;
  text-align: end;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${tabletPortrait} {
    font-size: 1.4rem;
  }
`;

const UserPointS = styled.div`
  display: flex;
  width: 100%;
  font-size: 1rem;
  color: #ffffff;

  @media ${tabletPortrait} {
    font-size: 1.4rem;
  }
`;

const UserPointTextS = styled.p`
  width: 100%;
  text-align: end;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const HeaderMenuPcS = styled.nav`
  display: none;

  @media ${tabletLandscape}, ${pc} {
    display: block;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 1.6rem;
  }
`;

const MenuUListS = styled.ul`
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;

const MenuListS = styled.li`
  color: #ffffff;
  text-align: center;

  &:hover {
    background-color: #ffffff;
  }

  .link-menu {
    width: 100%;
    padding: 2rem;
    display: inline-block;
    color: inherit;

    &:visited {
      color: inherit;
    }

    &:hover {
      color: #000000;
    }
  }
`;

const SetLangS = styled.div`
  display: none;

  @media ${tabletLandscape}, ${pc} {
    display: flex;
    justify-content: center;
  }
`;

const GlobeIconWrapS = styled.button`
  height: 100%;
  width: 25px;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`;

const GlobeIconS = styled.img`
  width: 100%;
`;

const CityButtonS = styled.button`
  width: auto;
`;

const CityIconS = styled.img`
  width: 100%;
`;

const LangOptionWrapS = styled.div<{ isShow: boolean }>`
  height: 150px;
  width: 115px;
  position: absolute;
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;

const TriangleS = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 11px solid #ffffff;
`;

const TicketTriangleS = styled(TriangleS)`
  margin-left: 1rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    margin-left: 1.5rem;
  }
`;

const LangOptionBoxS = styled.div`
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 7px;
`;

const LangGridS = styled.div`
  width: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LangS = styled.div`
  width: 100%;
  display: flex;
  column-gap: 0.5rem;
`;

const LabelS = styled.button`
  font-size: 1.4rem;
  word-break: break-all;

  &:hover {
    color: #ffa500;
  }
`;
const CheckS = styled.p`
  font-size: 1.4rem;
  color: #006400;
`;
