export const NEWS = {
  LIST_VIEW_LIMIT: 3, // 個人のお知らせ一覧表示上限
  LOTTERY_LINK_EXPIRATION_HOURS: 72,
  LOTTERY_LINK_EXPIRATION_DAYS: 14,
};

export const NEWS_USERS_KIND = {
  DEFAULT: 0,
  LOT: 1, // 抽選結果
  RANK_UP: 2, // ランクアップ
  CAMPAIGN: 3, // キャンペーン
};
