// eslint-disable-next-line
import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type Props = {
  children: ReactNode;
  radioText: string;
  onClick?: () => void;
  isDisabled?: boolean;
  width?: string;
};

/**
 *
 * Component
 *
 */
const RadioButton: FC<Props> = memo((props) => {
  const {
    children, radioText, onClick, isDisabled, width,
  } = props;

  return (
    <RadioButtonS isDisabled={isDisabled}>
      <LabelWrapS width={width}>{children}</LabelWrapS>
      <RadioTextWrapS onClick={onClick}>
        <RadioTextS>{radioText}</RadioTextS>
      </RadioTextWrapS>
    </RadioButtonS>
  );
});
export default RadioButton;

/**
 *
 * Styles
 *
 */
const RadioButtonS = styled.div<{ isDisabled: boolean | undefined }>`
  width: 100%;
  display: flex;
  align-items: center;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.7 : 1)};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

const LabelWrapS = styled.div<{ width: string | undefined }>`
  width: ${({ width }) => (width || '45%')};
  text-align: right;

  @media (min-width: 600px) {
    width: ${({ width }) => (width || '48%')};
  }
`;

const RadioTextWrapS = styled.div`
  width: 52%;
  text-align: left;
`;

const RadioTextS = styled.p`
  color: #ffff;
  font-size: 1.6rem;

  @media (min-width: 1025px) {
    font-size: 1.8rem;
  }
`;
