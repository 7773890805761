import { useState } from 'react';
// External library
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType, FaqType } from 'types/api';

// FAQページ用ロジック
export const useFaq = (): {
  dataList: FaqType[];
  isLoading: boolean;
  emptyMessage: string;
  errorMessage: string;
  getApiFaqSelect: () => void;
  cleanUpMethod: () => void;
} => {
  // i18-next
  const { t } = useTranslation();
  // エラー用カスタムフック
  const { errorHandler } = useError();

  // FAQ用ステート
  const [dataList, setDataList] = useState<FaqType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emptyMessage, setEmptyMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  // FAQデータリクエスト
  const axiosFaqSource = axiosCancelToken.source();
  const getApiFaqSelect = () => {
    if (!isLoading) {
      setIsLoading(true);

      // axios GET /api/faq/select
      axiosClient
        .get<FaqType[]>('/api/faq/select?content_id=1', {
          cancelToken: axiosFaqSource.token,
        })
        .then(({ data }) => {
          if (data.length !== 0) {
            // FAQ取得
            setDataList(data);
          } else {
            setEmptyMessage(t('よくある質問はありません。'));
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    axiosFaqSource.cancel();
    setDataList([]);
    setEmptyMessage('');
    setErrorMessage('');
  };

  return {
    dataList,
    isLoading,
    emptyMessage,
    errorMessage,
    getApiFaqSelect,
    cleanUpMethod,
  };
};
