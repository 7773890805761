import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';
// Fonts
import prizeFont from 'assets/fonts/top/playbox/851Gkktt_005_subset.woff2';

/**
 *
 * Types
 *
 */
type Props = {
  children: ReactNode;
};

/**
 *
 * Components
 *
 */
const PrizeText: FC<Props> = memo(({ children }) => (
  <PrizeTextS>
    {children}
  </PrizeTextS>
));
export default PrizeText;

/**
 *
 * Styles
 *
 */
const PrizeTextS = styled.p`
  @font-face {
    font-family: 'Prize Font';
    src: url(${prizeFont}) format('woff2');
  }

  font-family: 'Prize font';
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: #ffffff;
`;
