import { useState, useEffect, MouseEvent } from 'react';
// External library
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
// Hooks
import { useError } from 'hooks/versatile/useError';
// Recoil
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
import { EVENT_ENABLED, EVENT_NEWS } from 'utils/const/event';
// Types
import { ApiErrorType, NewsUsersType, PaginationType } from 'types/api';

/**
 *
 * ユーザーお知らせ一覧ページ用ロジック
 *
 */
type Pagination = Pick<PaginationType, 'next_page_url' | 'last' | 'total'>;
type NewsUsers = Omit<
  NewsUsersType,
  'created_at' | 'updated_at' | 'deleted_at'
>;

export const useNewsUsersIndex = (): {
  isLoading: boolean;
  newsUsers: NewsUsers[];
  newsUsersPagination: Pagination;
  getApiNewsUsersSelect: () => void;
  isEmpty: boolean;
  errorMessage: string;
  goToNewsUsersShowPage: (
    e: MouseEvent<HTMLButtonElement>,
    id: NewsUsers['id'],
  ) => void;
  goToNewsUsersSkipPage: (
    e: MouseEvent<HTMLButtonElement>,) => void;
} => {
  // 0件フラグ
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  // エラー用カスタムフック
  const { errorHandler } = useError();
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // ローディング状態
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // ユーザーお知らせ一覧
  const initialNewsUsers = {
    id: 0,
    kind: 0,
    user_id: 0,
    lot_box_id: 0,
    lot_table_id: 0,
    lottery_flg: 0,
    status: 0,
    title: '',
    description: '',
    en_title: '',
    en_description: '',
    ko_title: '',
    ko_description: '',
    zh_cn_title: '',
    zh_cn_description: '',
    zh_tw_title: '',
    zh_tw_description: '',
    vi_title: '',
    vi_description: '',
    th_title: '',
    th_description: '',
    link: '',
    lot_tables: null,
  };
  const [newsUsers, setNewsUsers] = useState<NewsUsers[]>([
    { ...initialNewsUsers },
  ]);
  // ユーザーお知らせ一覧ページネーション
  const initialPagination = {
    next_page_url: '',
    last: false,
    total: 0,
  };
  const [newsUsersPagination, setNewsUsersPagination] = useState<Pagination>(initialPagination);

  // ユーザーお知らせ一覧取得
  const axiosNewsUsersSource = axiosCancelToken.source();
  const getApiNewsUsersSelect = () => {
    if (!isLoading) {
      setIsLoading(true);
      setIsEmpty(false);
      setErrorMessage('');

      // axios GET /api/news_users/select
      axiosClient
        .get<{
          news_users: NewsUsersType[];
          pagination: PaginationType;
        }>(`/api/news_users/select${newsUsersPagination.next_page_url ?? ''}`, {
          cancelToken: axiosNewsUsersSource.token,
        })
        .then(({ data }) => {
          const { news_users, pagination } = data;

          if (EVENT_ENABLED) {
            news_users[news_users.length] = {
              id: EVENT_NEWS.ID,
              kind: EVENT_NEWS.KIND,
              user_id: EVENT_NEWS.USER_ID,
              lot_box_id: EVENT_NEWS.LOT_BOX_ID,
              lot_table_id: EVENT_NEWS.LOT_TABLE_ID,
              lottery_flg: EVENT_NEWS.LOTTERY_FLG,
              status: EVENT_NEWS.STATUS,
              title: EVENT_NEWS.TITLE,
              description: EVENT_NEWS.DESCRIPTION,
              en_title: EVENT_NEWS.EN_TITLE,
              en_description: EVENT_NEWS.EN_DESCRIPTION,
              ko_title: EVENT_NEWS.KO_TITLE,
              ko_description: EVENT_NEWS.KO_DESCRIPTION,
              zh_cn_title: EVENT_NEWS.ZH_CN_TITLE,
              zh_cn_description: EVENT_NEWS.ZH_CN_DESCRIPTION,
              zh_tw_title: EVENT_NEWS.ZH_TW_TITLE,
              zh_tw_description: EVENT_NEWS.ZH_TW_DESCRIPTION,
              vi_title: EVENT_NEWS.VI_TITLE,
              vi_description: EVENT_NEWS.VI_DESCRIPTION,
              th_title: EVENT_NEWS.TH_TITLE,
              th_description: EVENT_NEWS.TH_DESCRIPTION,
              link: EVENT_NEWS.LINK,
              lot_tables: null,
              created_at: (new Date()).toDateString(),
              updated_at: (new Date()).toDateString(),
              deleted_at: (new Date()).toDateString(),
            };
          }

          if (news_users.length !== 0) {
            const getNewsUsers = news_users.map((item) => ({
              id: item.id,
              kind: item.kind,
              user_id: item.user_id,
              lot_box_id: item.lot_box_id,
              lot_table_id: item.lot_table_id,
              lottery_flg: item.lottery_flg,
              status: item.status,
              title: item.title,
              description: item.description,
              en_title: item.en_title,
              en_description: item.en_description,
              ko_title: item.ko_title,
              ko_description: item.ko_description,
              zh_cn_title: item.zh_cn_title,
              zh_cn_description: item.zh_cn_description,
              zh_tw_title: item.zh_tw_title,
              zh_tw_description: item.zh_tw_description,
              vi_title: item.vi_title,
              vi_description: item.vi_description,
              th_title: item.th_title,
              th_description: item.th_description,
              link: item.link,
              lot_tables: item.lot_tables,
            }));

            if (pagination.current_page > 1) {
              setNewsUsers([...newsUsers, ...getNewsUsers]);
            } else {
              setNewsUsers([...getNewsUsers]);
            }

            // ページネーション更新
            setNewsUsersPagination({
              next_page_url: pagination.next_page_url,
              last: pagination.last,
              total: pagination.total,
            });
          } else {
            setIsEmpty(true);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
          setNewsUsers([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const history = useHistory();
  const goToNewsUsersShowPage = (
    e: MouseEvent<HTMLButtonElement>,
    id: NewsUsers['id'],
  ) => {
    e.stopPropagation();
    history.push(`/news_users/show/${id}`);
  };
  const goToNewsUsersSkipPage = (
    e: MouseEvent<HTMLButtonElement>,
  ) => {
    e.stopPropagation();
    history.push('/news_users/skip');
  };

  // クリーンアップ処理
  const cleanup = () => {
    // APIリクエストキャンセル
    axiosNewsUsersSource.cancel();
    // データ
    setNewsUsers([{ ...initialNewsUsers }]);
    setIsEmpty(false);
    setErrorMessage('');
  };

  const user = useRecoilValue(userState);
  useEffect(() => {
    if (user.loggedIn) {
      // ユーザーお知らせ情報取得
      getApiNewsUsersSelect();
    }

    return cleanup;
  }, []);

  return {
    isLoading,
    newsUsers,
    newsUsersPagination,
    getApiNewsUsersSelect,
    isEmpty,
    errorMessage,
    goToNewsUsersShowPage,
    goToNewsUsersSkipPage,
  };
};
