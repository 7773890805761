import { FC, ReactNode, memo } from 'react';
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type Props = {
  children: ReactNode;
};

/**
 *
 * Component
 *
 */
const ErrorMessage: FC<Props> = memo(({ children }) => (
  <ErrorMessageS>{children}</ErrorMessageS>
));
export default ErrorMessage;

/**
 *
 * Styles
 *
 */
const ErrorMessageS = styled.p`
  font-size: inherit;
  color: #ff0000;
  word-break: break-all;
`;
