import { thSeo } from 'utils/lang/seo/thSeo';

export const th = {
  // メタ情報
  ...thSeo,
  // ページ
  トップ: 'หน้าแรก',
  新規会員登録: 'สมัครสมาชิกใหม่',
  入力内容の確認: 'ยืนยันข้อมูลที่กรอก',
  メール送信完了: 'ส่งอีเมล์เรียบร้อยแล้ว',
  メール認証: 'ยืนยันอีเมล์',
  ログイン: 'ลงชื่อเข้าใช้งาน',
  パスワードのリセット: 'รีเซ็ตรหัสผ่าน',
  パスワードの再設定: 'การรีเซ็ตรหัสผ่าน',
  マイページ: 'หน้าของฉัน',
  アカウントの停止: 'ระงับบัญชี',
  メールアドレスの変更: 'เปลี่ยนที่อยู่อีเมล์',
  'ポイント出金・交換': 'คะแนนที่จะแลกหรือถอนเงิน',
  KYC書類アップロード: 'ขอทำ KYC',
  "Day Lott'sとは": "Day Lott's คืออะไร",
  仕組み: 'ลักษณะ',
  よくある質問: 'คำถามที่พบบ่อย',
  ランキング: 'การจัดอันดับ',
  サポート: 'ศูนย์ช่วยเหลือ',
  お知らせ: 'ข่าวสาร',
  言語: 'ภาษา',
  'NEWSはありません。': 'ยังไม่มีข่าวสาร',
  '高額獲得者はいません。': 'ไม่มีผู้ชนะที่ยิ่งใหญ่',
  '業界最高水準である、当選確率1/8も用意されているオンラインゲームです。還元率は75%!! 1ゲーム5Pからゲーム参加可能。5Pから10,000Pの当選も毎日開催されています。':
    'นี่คือเกมออนไลน์ที่คุณจะมีโอกาสได้รับรางวัลถึง 1 ต่อ 8 ซึ่งนับว่าสูงที่สุด โอกาสได้เงินคืนสูงถึง 75%!!1 เกม วางเดิมพันได้ตั้งแต่ 5P เปิดโอกาสให้คุณได้ลุ้นรางวัลทุกวันตั้งแต่ 5P จนถึง 10,000P',
  "1day,1million,1richをテーマにDay Lott'sは運営をしています。毎日世界中のどこかでミリオネアがDay Lott'sから誕生しています。我々のサービスは、1日1,000,000Pの当選を目指しています。":
    "Day Lott's เปิดให้บริการภายใต้ธีม 1 วัน, 1 ล้าน, 1 รวย ให้กำเนิดเศรษฐีหน้าใหม่ทั่วโลกในทุกวัน มีรางวัลแจ็กพอต 1,000,000P ต่อวัน",
  "Day Lott'sでは、入金、出金の際に仮想通貨を利用することができます。仮想通貨の利用により、ボーダーレスに、そしてスピードのある入出金が可能となっております。":
    "Day Lott's ให้คุณสามารถฝากและถอนเงินเป็นสกุลเงินคริปโตได้ ช่วยให้การฝากและถอนเงินสามารถทำได้ทุกที่และรวดเร็ว",
  'メールアドレスを登録フォームに入力し、会員登録を行います。':
    'เริ่มต้นสมัครสมาชิกด้วยการกรอกที่อยู่อีเมล์ที่แบบฟอร์มการสมัคร',
  会員登録: 'สมัครสมาชิก',
  '入金・チャージ': 'ฝากเงิน/เติมเงิน',
  '仮想通貨、振込など様々な方法でポイントをチャージすることができます。仮想通貨、振込など様々な方法でポイントをチャージすることができます。':
    'คุณสามารถเติมคะแนนได้โดยการชำระเงินด้วยสกุลเงินคริปโต และการโอนผ่านบัญชีธนาคาร',
  チケット購入: 'ซื้อตั๋ว',
  'ゲームに参加するためにチケットを、ポイントで購入します。':
    'คุณกำลังใช้คะแนนในการซื้อตั๋วเพื่อเข้าร่วมเล่นเกม',
  当選の確定: 'การจับรางวัลหาผู้โชคดี',
  'ゲームにエントリーをし、ゲームが開始されたら、すぐに当選の確定が分かります。当選確定の演出もお楽しみください！':
    'เข้าร่วมเล่นเกมแล้วมาดูกันว่าใครจะเป็นผู้โชคดีถูกรางวัล หรือคุณอาจจะเป็นผู้ถูกรางวัลเองก็ได้นะ !',
  '当選が確定したら、即座にマイページに当選ポイントが反映されます。また、そのポイントを換金して出金することができます。':
    'สำหรับผู้ที่ถูกรางวัล จะมีคะแนนที่คุณได้รางวัลปรากฏที่ "หน้าของฉัน" ในทันที อีกทั้งยังสามารถนำคะแนนไปแลกเป็นเงินได้ด้วย',
  // フッター
  利用規約: 'ข้อกำหนดการใช้งาน',
  お問い合わせ: 'ติดต่อสอบถาม',
  利用規約に同意し登録する: 'ยอมรับ <0>ข้อกำหนดการใช้งาน</0> และลงทะเบียนเป็นบัญชี',
  '※ユーザー名は後から変更をすることはできません。': '*ชื่อผู้ใช้ไม่สามารถเปลี่ยนแปลงได้ในภายหลัง',
  '※パスワードは大文字、小文字、数字を組み合わせて8文字以内で入力してください。': '*รหัสผ่านจะต้องมีตัวอักษรภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว และกรอกได้เฉพาะตัวอักษรครึ่งความกว้างเท่านั้น',
  プライバシーポリシー: 'นโยบายความเป็นส่วนตัว',
  責任あるギャンブル: 'การพนันอย่างมีความรับผิดชอบ ',
  "DayLott'sとこのウェブサイトは、Seven Eyes Ent. Company（会社番号227777/076/077および登録住所Sheetal Marg, Baluwater Kathmandu, Nepal）によって運営されています。 Seven Eyes Ent. Companyはネパール政府より発行された、Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010に基づき付与されたサブライセンスを通して正式な許可を保持しています。":
    "Day Lott's และเว็บไซต์นี้บริหารโดย Seven Eyes Ent. Company (เลขบริษัท 227777/076/077 จดทะเบียนที่ชีตาลมาร์ก บาลูวาตาร์ กรุงกาฐมาณฑุ ประเทศเนปาล)Seven Eyes Ent. Company ได้รับอนุญาตอย่างเป็นทางการตามใบอนุญาตประกอบกิจการการพนันกีฬา, โป๊กเกอร์, บิงโก และเกมสลากออนไลน์หมายเลข 109 ออกให้โดยรัฐบาลเนปาล ณ วันที่ 29 กรกฎาคม 2010",
  プレイヤー保護について: 'เกี่ยวกับการคุ้มครองผู้เล่น',
  責任のあるギャンブルポリシー: 'นโยบายการพนันอย่างมีความรับผิดชอบ',
  '1 .未成年者の保護': '1 .การคุ้มครองผู้เยาว์',
  '2 .プレイのルール': '2 .กฎการเล่น',
  '3 .結託': '3 .การสมรู้ร่วมคิด',
  '4 .カスタマーサービスと顧客からの苦情':
    '4 .การบริการลูกค้าและการร้องเรียนของลูกค้า',
  '4.1.カスタマーサービス': '4.1.บริการลูกค้า',
  '4.2.苦情の提出': '4.2.การยื่นเรื่องร้องเรียน',
  "4.3.DayLott'sとの書面による連絡に含める必要がある情報":
    "4.3.ข้อมูลที่คุณต้องรวมไว้ในการสื่อสารที่เป็นลายลักษณ์อักษรกับ DayLott's",
  '1 .ギャンブルの自己評価': '1 .การพนันประเมินตนเอง',
  '2 .サポート組織': '2 .องค์กรสนับสนุน',
  '3 .コントロールの維持': '3 .รักษาการควบคุม',
  '3.1.自己排除': '3.1.การยกเว้นตนเอง',
  "インターネットはモバイル機器や世界中の家庭から簡単にアクセスできるため、責任のあるオンラインギャンブルは親の管理と監督に大きく依存しています。インターネット上での子供の安全を確保するため、DayLott'sは未成年者が不適切なオンライン資料にアクセスするのを防止するフィルタリングソフトウェアを使用するようにお客様に推奨します。DayLott'sでは、保護者に対して次のヒントを提供しています。":
    'เนื่องจากอินเทอร์เน็ตสามารถเข้าถึงได้ง่ายจากอุปกรณ์พกพาและบ้านทั่วโลก การพนันออนไลน์ที่มีความรับผิดชอบจึงต้องอาศัยการควบคุมและการดูแลของผู้ปกครองเป็นอย่างมาก เพื่อความปลอดภัยของเด็ก ๆ บนอินเทอร์เน็ต DayLott ขอแนะนำให้ลูกค้าใช้ซอฟต์แวร์การกรองที่ป้องกันไม่ให้ผู้เยาว์เข้าถึงเนื้อหาออนไลน์ที่ไม่เหมาะสม DayLott เสนอเคล็ดลับต่อไปนี้สำหรับผู้ปกครอง',
  '自分のメールアドレスを安全に保つ。':
    'รักษาที่อยู่อีเมลและรหัสผ่านของคุณให้ปลอดภัย',
  "自分のDayLott'sアカウントにログインしたときにコンピューターを無人で放置しない。":
    'อย่าปล่อยให้คอมพิวเตอร์ของคุณไม่ต้องดูแลเมื่อคุณลงชื่อเข้าใช้บัญชี DayLott ของคุณ',
  '18歳未満の人がいかなるギャンブル活動への参加も許可しない。':
    'ไม่อนุญาตให้ผู้ที่มีอายุต่ำกว่า 18 ปีเข้าร่วมกิจกรรมการพนันใดๆ',
  '自分のクレジット/デビットカードおよびその他の金融口座の詳細を、子供の手の届かないところに保管する。':
    'เก็บบัตรเครดิต/เดบิตและรายละเอียดบัญชีการเงินอื่นๆ ให้พ้นมือเด็ก',
  'パスワードをコンピューターに保存しない。パスワードを書き留め、未成年者から離れた安全な場所に保管する。':
    'อย่าเก็บรหัสผ่านบนคอมพิวเตอร์ของคุณ จดรหัสผ่านและเก็บไว้ในที่ปลอดภัยห่างจากผู้เยาว์',
  "共有コンピューターからDayLott'sにアクセスする場合、特にコンピューターを子供と共有する場合は、「記憶する」機能を使用しない。":
    'เมื่อเข้าถึง DayLott จากคอมพิวเตอร์ที่ใช้ร่วมกัน โดยเฉพาะถ้าใช้คอมพิวเตอร์ร่วมกับเด็ก อย่าใช้ฟังก์ชัน "จำ" เมื่อเข้าถึง DayLott จากคอมพิวเตอร์ที่ใช้ร่วมกัน โดยเฉพาะอย่างยิ่งถ้าคอมพิวเตอร์นั้นใช้ร่วมกับเด็ก',
  '未成年のギャンブルによって引き起こされる違法性と潜在的な損害について、自分の子供たちに教育する。':
    'ให้ความรู้แก่บุตรหลานของคุณเกี่ยวกับความผิดกฎหมายและความเสียหายที่อาจเกิดขึ้นจากการพนันที่ยังไม่บรรลุนิติภาวะ',
  '未成年者によるすべての電子機器ならびにモバイルデバイスの使用には特に注意する。':
    'โปรดใช้ความระมัดระวังเป็นพิเศษเกี่ยวกับการใช้อุปกรณ์อิเล็กทรอนิกส์และอุปกรณ์พกพาของผู้เยาว์',
  '自分の子供がギャンブルサイトを含む不適切な題材にアクセスするのを防ぐために、フィルタリングソフトウェアを活用する。':
    'ใช้ซอฟต์แวร์กรองเพื่อป้องกันไม่ให้บุตรหลานของคุณเข้าถึงเนื้อหาที่ไม่เหมาะสม รวมถึงเว็บไซต์การพนัน',
  "未成年者のギャンブルは違法であるだけでなく、監督されていない子供が負った早期のギャンブル依存と実質的なギャンブルでの借金につながる可能性があります。したがって、自分の携帯電話、タブレット、ラップトップまたはコンピューターを法定年齢に満たない友人または家族と共有してオンラインギャンブルに参加する場合、DayLott'sは以下のいずれかのサービスを使用して、ウェブサイトへのアクセスを制限することを推奨します。":
    'การพนันโดยผู้เยาว์ไม่เพียงแต่ผิดกฎหมายเท่านั้น แต่ยังนำไปสู่การติดการพนันตั้งแต่เนิ่นๆ และหนี้สินจากการพนันจำนวนมากที่เกิดขึ้นโดยเด็กที่ไม่ได้รับการดูแล ดังนั้น หากคุณแบ่งปันโทรศัพท์มือถือ แท็บเล็ต แล็ปท็อป หรือคอมพิวเตอร์กับเพื่อนหรือสมาชิกในครอบครัวที่ยังไม่บรรลุนิติภาวะในการเข้าร่วมการพนันออนไลน์ DayLott ขอแนะนำให้คุณจำกัดการเข้าถึงเว็บไซต์โดยใช้หนึ่งในบริการต่อไปนี้',
  'Netnanny.com - 不適切なウェブコンテンツから子供を守るフィルタリングソフトウェア。':
    'Netnanny.com - ซอฟต์แวร์กรองที่ปกป้องเด็กจากเนื้อหาเว็บที่ไม่เหมาะสม',
  'Cybersitter.com - 保護者がブロックするサイトを自身で追加することができるフィルタリングソフトウェア。':
    'Cybersitter.com - ซอฟต์แวร์กรองที่อนุญาตให้ผู้ปกครองเพิ่มไซต์ของตนเองเพื่อบล็อก',
  'betterinternetforkids.eu - EUが共同出資し、インターネットフィルタソフトウェアに関する情報を提供し、無料のテスト版を提供するウェブサイト。':
    'Betterinternetforkids.eu - เว็บไซต์ที่ได้รับการสนับสนุนจากสหภาพยุโรปซึ่งให้ข้อมูลเกี่ยวกับซอฟต์แวร์กรองอินเทอร์เน็ตและให้บริการเวอร์ชันทดสอบฟรี',
  その他の重要なヒント: 'เคล็ดลับสำคัญอื่นๆ',
  'ギャンブルをする決定は自分の個人的な選択であることを確認してください。次の場合は、ギャンブルをすることはおすすめしません。':
    'ตรวจสอบให้แน่ใจว่าการตัดสินใจเล่นการพนันเป็นทางเลือกส่วนตัวของคุณ ไม่แนะนำให้เล่นการพนันในกรณีต่อไปนี้',
  '毎日の責務を妨げる場合。': 'มันรบกวนความรับผิดชอบประจำวันของคุณ',
  '依存症の治療をしている、あるいは回復している場合。':
    'คุณอยู่ในการรักษาหรือฟื้นตัวจากการเสพติด',
  'アルコールやその他の物質の影響を受けている場合。':
    'คุณอยู่ภายใต้ฤทธิ์ของแอลกอฮอล์หรือสารอื่นๆ',
  '以前の損失を取り戻そうとしている場合。あるいは賞金で借金を回収することが主な目的となっている場合。':
    'หากคุณกำลังพยายามกู้คืนการสูญเสียครั้งก่อนของคุณ หรือเมื่อเป้าหมายหลักคือการเก็บหนี้ด้วยเงินรางวัลของคุณ',
  "DayLott'sでゲームを始める前に、ゲームのルールを知ることをおすすめします。":
    'ก่อนเริ่มเกมใน DayLott ขอแนะนำให้รู้กฎของเกม',
  '顧客が他の顧客と結託していると思われる場合や、不正行為を行った場合は、直ちにカスタマーサービスにご連絡ください。':
    'หากคุณสงสัยว่าลูกค้ากำลังสมรู้ร่วมคิดกับลูกค้ารายอื่นหรือกระทำการฉ้อโกง โปรดติดต่อฝ่ายบริการลูกค้าทันที',
  'お客様はメールでカスタマーサービスにいつでも連絡することができます。':
    'ลูกค้าสามารถติดต่อฝ่ายบริการลูกค้าได้ตลอดเวลาทางอีเมล',
  "いかなる場合でも問題が発生してから3か月以内に、顧客の苦情/申し立てを提出する必要があります。お客様の苦情/申し立てが正確な部署に向けられ、調査されていることを確実にするために、以下の手段を介して書面による連絡をDayLott'sに提出しなければなりません。":
    'ไม่ว่าในกรณีใด ลูกค้าต้องยื่นเรื่องร้องเรียน/ข้อกล่าวหาภายในสามเดือนของปัญหาที่เกิดขึ้น เพื่อให้แน่ใจว่าข้อร้องเรียน/ข้อกล่าวหาของลูกค้าถูกส่งไปยังแผนกที่ถูกต้องและตรวจสอบ การสื่อสารที่เป็นลายลักษณ์อักษรจะต้องส่งไปยัง DayLott โดยใช้วิธีการดังต่อไปนี้',
  電子メール: 'อีเมล',
  "お客様のプライバシーを保護するために、お客様とDayLott's間のすべての電子メールによる通信は、DayLott'sで保持されているお客様の顧客アカウントに登録したメールアドレスを使用して実行する必要があります。そうでない場合、当社の対応が遅れることがあります。DayLott'sとの(苦情を含む) あらゆる書面による連絡には、以下の情報を含める必要があります。":
    'เพื่อปกป้องความเป็นส่วนตัวของคุณ การสื่อสารทางอีเมลทั้งหมดระหว่างคุณกับ DayLott จะต้องดำเนินการโดยใช้ที่อยู่อีเมลที่ลงทะเบียนไว้ในบัญชีลูกค้าของคุณที่ดูแลด้วย DayLott มิฉะนั้น การตอบสนองของเราอาจล่าช้า การสื่อสารที่เป็นลายลักษณ์อักษรกับ DayLott (รวมถึงการร้องเรียน) จะต้องมีข้อมูลต่อไปนี้',
  'お客様のニックネーム/電話番号': 'ชื่อเล่น/หมายเลขโทรศัพท์ของคุณ',
  お客様の顧客アカウントに登録済みの氏名:
    'ชื่อของคุณตามที่ปรากฏในบัญชีลูกค้าของคุณ',
  '苦情/申し立ての詳細': 'รายละเอียดการร้องเรียน/ข้อกล่าวหา',
  '苦情/申し立てに関連する特定の日付と時刻 (該当する場合)':
    'วันที่และเวลาเฉพาะที่เกี่ยวข้องกับการร้องเรียน/ข้อกล่าวหา (ถ้ามี)',
  "DayLott'sでは、お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。":
    'ที่ DayLott เรามุ่งมั่นที่จะมอบประสบการณ์การพนันที่สนุกสนานที่สุดให้กับคุณ แต่เราก็ตระหนักดีว่าการพนันสามารถสร้างปัญหาให้กับบางคนได้ เพื่อให้แน่ใจว่าคุณสามารถเพลิดเพลินกับการเล่นที่ปลอดภัยและจัดการได้ต่อไป เราสนับสนุนการพนันอย่างมีความรับผิดชอบ และได้กำหนดมาตรการหลายอย่างเพื่อช่วยเหลือคุณ',
  'ギャンブルによりお客様自身 (またはその他の人) の人生が支配されている可能性があると懸念する場合、次の質問で判明することがあるかもしれません。':
    'หากคุณกังวลว่าการพนันอาจควบคุมชีวิตของคุณ (หรือของผู้อื่น) คำถามต่อไปนี้อาจช่วยให้คุณทราบได',
  'つまらない日常や不幸な人生を忘れるためにギャンブルをしますか？':
    'คุณเล่นการพนันเพื่อลืมชีวิตที่น่าเบื่อหรือไม่มีความสุขของคุณหรือไม่?',
  'ギャンブルをしていてお金が足りなくなったら、喪失感を味わい絶望を感じ、できるだけ早く再びギャンブルをする必要がありますか？':
    'หากคุณกำลังเล่นการพนันและเงินหมด คุณรู้สึกสูญเสียและสิ้นหวังและจำเป็นต้องเล่นการพนันอีกครั้งโดยเร็วที่สุด?',
  '最後の1円がなくなるまでギャンブルをしますか？それが、たとえば家への交通費や生活費だとしてもですか？':
    'คุณเล่นการพนันจนเพนนีสุดท้ายของคุณหมดหรือไม่? คุณจะเล่นการพนันจนเงินสุดท้ายของคุณหมดลง แม้ว่าจะเป็นเพียงค่าเดินทางบ้านหรือค่าครองชีพหรือไม่?',
  'ギャンブルに費やした金額や時間の嘘をついてごまかしたことがありますか？':
    'คุณเคยโกหกและโกงเกี่ยวกับจำนวนเงินหรือเวลาที่คุณใช้ไปกับการพนันหรือไม่?',
  'ギャンブルが原因で家族、友人、趣味への興味を失ったことがありますか？':
    'คุณเคยหมดความสนใจในครอบครัว เพื่อน หรืองานอดิเรกเพราะการพนันหรือไม่?',
  '負けた後、できるだけ早くもう一度挑戦して損失を勝たなければならないと感じますか？':
    'หลังจากแพ้ คุณรู้สึกอยากที่จะลองอีกครั้งโดยเร็วที่สุดเพื่อเอาชนะการแพ้หรือไม่?',
  '議論、不満、失望によりギャンブルをしたくなりますか？':
    'การโต้เถียง ความผิดหวัง หรือความผิดหวังทำให้คุณต้องการเดิมพันหรือไม่?',
  'ギャンブルをすることが原因で落ち込む、あるいは自分自身に害を及ぼすと感じることがありますか？':
    'คุณรู้สึกหดหู่หรือทำร้ายตัวเองเพราะการพนันหรือไม่?',
  'これらの質問に "はい" という答えが多いほど、深刻なギャンブルの問題を抱えている可能性が高くなります。':
    'ยิ่งคุณตอบคำถามเหล่านี้ "ใช่" มากเท่าใด โอกาสที่คุณจะมีปัญหาการพนันที่ร้ายแรงก็จะยิ่งมากขึ้นเท่านั้น',
  'この問題について相談するには、カスタマーサービスに連絡するか、無料で独立したアドバイスを提供する数々のサポート組織があります。ご相談希望の方は、自国のサポート組織・機関に問い合わせをして下さい。':
    'เพื่อหารือเกี่ยวกับปัญหานี้ คุณสามารถติดต่อฝ่ายบริการลูกค้าหรือองค์กรสนับสนุนที่เสนอคำแนะนำอิสระฟรี โปรดติดต่อองค์กรสนับสนุนในประเทศของคุณหากคุณต้องการปรึกษาปัญหาของคุณ',
  'お客様あるいは知り合いの方がギャンブルの問題を抱えている場合、認定された組織からの支援を検討することをおすすめします。':
    'หากคุณหรือคนที่คุณรู้จักมีปัญหาเรื่องการพนัน คุณอาจต้องการรับความช่วยเหลือจากองค์กรที่ได้รับการรับรอง',
  "DayLott'sでは、お客様にギャンブルアカウントの管理に積極的に関与することを推奨しています。そのために、当社はさまざまな責任のあるゲーミング機能を提供しています。":
    'DayLott สนับสนุนให้ลูกค้าของเรามีบทบาทอย่างแข็งขันในการจัดการบัญชีการพนันของพวกเขา ด้วยเหตุนี้ เราจึงนำเสนอฟังก์ชันการเล่นเกมที่มีความรับผิดชอบมากมาย',
  'もしお客様がギャンブルに対し問題が生じたと思われる場合には、カスタマーサービスへ連絡をし、アカウントへ自己排除期間を適用するように要請してください。':
    'หากคุณเชื่อว่าคุณมีปัญหากับการพนัน โปรดติดต่อฝ่ายบริการลูกค้าและขอให้ใช้ระยะเวลาการยกเว้นตนเองกับบัญชีของคุณ',
  以下の自己排除期間がご利用可能です:
    'มีช่วงเวลายกเว้นตนเองดังต่อไปนี้',
  '1週間': '1 สัปดาห์',
  '2週間': '2 สัปดาห์',
  '1ヶ月': '1 เดือน',
  '3ヶ月': '3 months',
  '6ヶ月': '6 เดือน',
  "できる限り速やかに自己排除を適用するように努めますが、このプロセスにはある程度の作業時間が必要となりますのでご理解いただきますようお願いいたします。自己排除期間は実装されてお客様へ連絡されるまで、開始したものとはみなされませんのでご注意ください。もしその後も引き続きサービスにアクセス可能な場合には、責任をもってすぐ当社までご連絡ください。自己排除に入った時点でのDayLott'sの未確定ベットは、決済されます。お客様のベットが勝ちだった場合、勝ち金は該当する口座へ送金されます。自己排除期間が終了したあと、お客様のアカウントは自動的に再開されますのでご注意下さい。":
    'เราจะพยายามใช้การยกเว้นตนเองโดยเร็วที่สุด แต่โปรดเข้าใจว่ากระบวนการนี้อาจใช้เวลาสักครู่ โปรดทราบว่าระยะเวลาการยกเว้นตนเองจะไม่ถือว่าเริ่มต้นจนกว่าคุณจะได้รับแจ้ง หากคุณยังคงเข้าถึงบริการได้หลังจากเวลานั้น คุณต้องรับผิดชอบในการติดต่อเราทันที การเดิมพันที่ยังไม่ได้ตัดสินของ DayLott ณ เวลาที่แยกตัวเองจะถูกตัดสิน หากเดิมพันของคุณชนะ เงินรางวัลของคุณจะถูกโอนไปยังบัญชีที่เกี่ยวข้อง โปรดทราบว่าบัญชีของคุณจะถูกเปิดใหม่โดยอัตโนมัติหลังจากระยะเวลาการยกเว้นตัวเองหมดลง',
  "DayLott'sは開かれる可能性のある新しい重複アカウントを検出し、閉鎖するためにできる限りのことを常に行っております。自己排除が適用されると、いかなるマーケティング資料も受け取ることはなくなります。":
    'DayLott จะทำทุกวิถีทางเพื่อตรวจหาและปิดบัญชีที่ซ้ำกันใหม่ที่อาจเปิดได้ เมื่อใช้การยกเว้นตนเองแล้ว คุณจะไม่ได้รับเอกสารทางการตลาดใดๆ อีกต่อไป',
  // フォーム
  メールアドレス: 'ที่อยู่อีเมล์',
  ユーザー名: 'ชื่อผู้ใช้งาน',
  パスワード: 'รหัสผ่าน',
  'パスワード(確認用)': 'รหัสผ่าน (สำหรับยืนยัน)',
  新しいパスワード: 'รหัสผ่านใหม่',
  '新しいパスワード(確認用)': 'รหัสผ่านใหม่ (สำหรับยืนยัน)',
  ファイルを選択: 'เลือกไฟล์',
  'ドラッグ＆ドロップするかファイルを選択してください':
    'เลือกไฟล์ที่จะลากแล้ววาง',
  // ボタン
  修正する: 'แก้ไข',
  送信する: 'ส่ง',
  再送する: 'ส่งอีกครั้ง',
  確認する: 'ยืนยัน',
  登録する: 'สมัคร',
  入金する: 'ฝากเงิน',
  出金する: 'ถอนเงิน',
  受け取る: 'รับ',
  アップロード: 'อัปโหลด',
  削除: 'ยกเลิก',
  停止: 'ระงับ',
  選択: 'เลือก',
  送信: 'ส่ง',
  戻る: 'กลับ',
  設定する: 'ตั้งค่า',
  // トッページ
  さらに表示する: 'แสดงรายการต่อไป',
  // 新規会員登録ページ
  '新規会員登録(タイトル)': 'สมัครสมาชิกใหม่',
  // 新規会員登録確認ページ
  '内容に誤りがないか、よくご確認ください。':
    'ตรวจสอบความถูกต้องของข้อมูลที่กรอก',
  // 新規会員登録完了ページ
  メールの送信が完了しました: 'ส่งอีเมล์เรียบร้อยแล้ว',
  '以下のメールアドレスに確認メールを送りました。メール記載のURLから登録を完了してください。':
    'เราได้ส่งอีเมล์ยืนยันไปยังที่อยู่นี้ คลิกที่ URL ในอีเมล์เพื่อดำเนินการสมัครให้เสร็จสมบูรณ์',
  TOPに戻る: 'กลับไปยังหน้าแรก',
  // メール認証
  '認証に成功しました。': 'ยืนยันสำเร็จ',
  ログインページへ: 'ไปที่หน้าลงชื่อเข้าใช้งาน',
  // ログイン
  'Bunny Paradise Worldのアカウントでもログインできますが、初回はパスワードのリセットが必要です。': 'คุณยังสามารถเข้าสู่ระบบด้วยบัญชี Bunny Paradise World ของคุณได้ แต่ต้องรีเซ็ตรหัสผ่านครั้งแรก',
  'ログインができない、パスワードを忘れた方はこちら':
    'ฉันลืมรหัสผ่าน ไม่สามารถลงชื่อเข้าใช้งานได้',
  "DayLott'sのリンクをホーム画面に追加すると次回以降簡単にアクセスができます。": 'เพิ่มลิงก์ของ DayLott ลงในหน้าจอหลักเพื่อให้เข้าถึงได้ง่ายในครั้งต่อไป',
  iPhoneの場合: 'สำหรับ iPhone',
  '1. ブラウザ(Safari)でWEBページを開いた状態で、下部の「共有アイコン」をタップ。': '1. เปิดหน้าเว็บในเบราว์เซอร์ของคุณ (Safari) แล้วแตะ "ไอคอนแชร์" ที่ด้านล่าง',
  '2. 共有メニューに表示される「ホーム画面に追加」をタップ。': '2. แตะ "เพิ่มไปที่หน้าจอหลัก" ในเมนูแชร์',
  '3. 名称などをご確認いただき、画面右上の「追加」をタップ。': '3. แตะ "เพิ่ม" ที่มุมขวาบนของหน้าจอหลังจากยืนยันชื่อ ฯลฯ',
  '4. ホーム画面にアイコンが追加されたら完了です。': '4. เมื่อไอคอนถูกเพิ่มลงในหน้าจอหลัก แสดงว่าคุณทำเสร็จแล้ว',
  確認して閉じる: 'ยืนยันและปิด',
  // パスワードリセット
  '登録済みのメールアドレスにパスワード再設定用のURLを送信します。':
    'เราจะส่ง URL สำหรับรีเซ็ตรหัสผ่านไปยังที่อยู่อีเมล์ที่ใช้ในการสมัคร',
  パスワードのリセットを申請する: 'ขอรีเซ็ตรหัสผ่าน',
  '再送しました。': 'ส่งอีกครั้งเรียบร้อยแล้ว',
  // パスワードの再設定
  新しいパスワードを設定する: 'ตั้งรหัสผ่านใหม่',
  '新しいパスワードを入力した後、「設定する」を押してください。':
    'หลังจากป้อนรหัสผ่านใหม่แล้ว ให้กด "ตั้งค่า"',
  // パスワードの再設定(完了)
  パスワードの変更が完了しました: 'เปลี่ยนรหัสผ่านแล้ว',
  // マイページ
  'ポイント購入履歴はありません。': 'ไม่มีประวัติการซื้อคะแนน',
  初回登録ボーナス: 'โบนัสการลงทะเบียนครั้งแรก',
  入金ボーナス: 'โบนัสเงินฝาก',
  紹介ボーナス: 'โบนัสผู้แนะนำ',
  クレジット購入: 'ซื้อด้วยเครดิต',
  ビットコイン購入: 'ซื้อด้วย Bitcoin',
  振り込み購入: 'ซื้อโดยโอนเงินสด',
  テザー購入: 'ซื้อโดย Tether',
  イーサリアム購入: 'ซื้อโดย Ethereum',
  'ポイント出金・交換履歴はありません。':
    'ไม่มีประวัติการถอนเงิน/แลกคะแนน',
  'べット履歴はありません。': 'ไม่มีประวัติการวางเงินเดิมพัน',
  '当選履歴はありません。': 'ไม่มีประวัติการถูกรางวัล',
  '〇回ゲームをプレイすると出金を行うことができます。': 'คุณสามารถถอนเงินได้เมื่อเล่นเกมครบ ○ ครั้ง',
  所持ポイント: 'คะแนนคงเหลือ',
  生年月日: 'วันเดือนปีเกิด',
  '※ポイントの出金・交換をする際にはKYC認証が必須となります。': '※ในการถอนเงินและแลกคะแนนนั้น คุณจำเป็นต้องทำการยืนยันตัวตนด้วยระบบ KYC ก่อน',
  未設定: 'ยังไม่ได้ตั้งค่า',
  登録情報: 'ข้อมูลการสมัคร',
  KYC提出: 'ขอทำ KYC',
  'ポイントデポジット出金・交換': 'ฝากเงิน/ถอนเงิน/แลกคะแนน',
  プレイ履歴: 'ประวัติ',
  編集: 'แก้ไข',
  ログアウト: 'ออกจากระบบ',
  KYC確認中: 'อยู่ในระหว่างการยืนยัน KYC',
  KYC却下: 'ไม่ผ่านการยืนยัน KYC',
  書類のタイプを選択する: 'เลือกประเภทของเอกสาร',
  パスポート: 'หนังสือเดินทาง',
  免許証: 'ใบขับขี่',
  // アカウントの停止(確認)
  'アカウント情報、所持チケット、所持ポイント等が失われます。確認してから「停止」ボタンを押してください。':
    'ข้อมูลบัญชี ตั๋ว คะแนน ฯลฯ จะถูกลบออก โปรดยืนยันแล้วคลิกปุ่ม "ระงับ"',
  '※この操作は取り消しができません。': '※ การปิดบัญชีนี้ไม่สามารถยกเลิกได้',
  // アカウントの停止(完了)
  アカウント停止完了: 'ระงับชั่วคราว',
  'アカウントの停止が完了しました。ご利用いただきありがとうございました。':
    'เราระงับบัญชีของคุณอย่างถาวร ขอบคุณที่ใช้',
  // メールアドレスの変更
  '入力したメールアドレスにメールアドレス再設定用のURLを送信します。':
    'เราจะส่ง URL สำหรับเปลี่ยนที่อยู่อีเมล์ไปยังที่อยู่ที่คุณกรอก',
  // KYC書類アップロード(フォーム)
  パスポートのアップロード: 'อัปโหลดหนังสือเดินทาง',
  免許証のアップロード: 'อัปโหลดใบขับขี่',
  年: 'ปี',
  月: 'เดือน',
  日: 'วัน',
  パスポートの写真をアップロード: 'อัปโหลดรูปถ่ายหนังสือเดินทาง',
  '免許証 ( 表 ) の写真をアップロード':
    'อัปโหลดรูปถ่ายด้านหน้าใบขับขี่ของคุณ',
  '免許証 ( 裏 ) の写真をアップロード':
    'อัปโหลดรูปถ่ายด้านหลังใบขับขี่ของคุณ',
  '1つ前に戻る': 'กลับไปยังหน้าที่แล้ว',
  '画像をアップロードしてください。': 'อัปโหลดภาพ',
  // KYC書類アップロード(確認)
  提出内容の確認: 'ตรวจสอบข้อมูลคำขอ',
  送信内容の確認: 'ตรวจสอบข้อมูลที่ส่ง',
  '以下の内容で送信します。よろしければ送信を押してください。':
    'คุณกำลังจะส่งข้อมูลดังต่อไปนี้ ตรวจสอบข้อมูลอีกครั้งก่อนกดปุ่มส่ง',
  '免許証 ( 表 )': 'ใบขับขี่ (ด้านหน้า)',
  '免許証 ( 裏 )': 'ใบขับขี่ (ด้านหลัง)',
  // KYC書類アップロード(完了)
  本人確認書類送信完了: 'ส่งเอกสารในการยืนยันตัวตนเรียบร้อยแล้ว',
  '審査結果はメールにてお知らせいたします。今しばらくお待ちください。':
    'เราจะแจ้งผลการตรวจสอบผ่านทางอีเมล์ กรุณารอสักครู่',
  マイページへ戻る: 'กลับไปที่หน้าของฉัน',
  // ポイント購入
  ポイント購入: 'ซื้อคะแนน',
  ポイント: 'คะแนน',
  ドル: 'ดอลลาร์สหรัฐ',
  円: 'เยน',
  レートを確認する: 'ตรวจสอบอัตราแลกเปลี่ยน',
  ボーナスポイント: 'คะแนนโบนัส',
  'ボーナスポイントは購入ポイント獲得時に付与されます。': 'คุณจะได้คะแนนโบนัสเมื่อคุณได้คะแนนการซื้อ',
  購入方法を選択: 'เลือกวิธีการซื้อ',
  仮想通貨: 'สกุลเงินคริปโต',
  クレジット: 'บัตรเครดิต',
  現金振込: 'โอนเงินสด',
  'ページを更新して再度お試しください。':
    'โปรดรีเฟรชหน้าแล้วลองอีกครั้ง',
  'データを取得できませんでした。': 'ไม่สามารถดาวน์โหลดข้อมูลได้',
  '最低購入ポイント数は10ポイントです。':
    'คะแนนในการซื้อขั้นต่ำ 10 คะแนน',
  'お名前(必須)': 'ชื่อของคุณ (จำเป็น)',
  '姓(カナ)': 'นามสกุล',
  '名(カナ)': 'ชื่อจริง',
  '全角カタカナで入力してください。': 'โปรดป้อนคาตาคานะแบบเต็มความกว้าง',
  '口座名義は入力必須です。': 'ต้องระบุเจ้าของบัญชี',
  // ポイント購入（仮想通貨）
  'レート表記はページにアクセスした時点のものです。入金時の数値とは異なる場合がございます。':
    'สัญกรณ์อัตราคือ ณ เวลาที่มีการเข้าถึงเพจ อาจแตกต่างไปจากมูลค่า ณ เวลาที่ฝาก',
  仮想通貨で入金: 'ฝากเงินด้วยสกุลเงินคริปโต',
  送金アドレス: 'ที่อยู่ในการส่งเงิน',
  アドレスを再取得: 'เรียกที่อยู่อีเมลอีกครั้ง',
  'コピーしました。': 'คัดลอกแล้ว',
  'コピー失敗しました。': 'คัดลอกไม่สำเร็จ',
  // ポイント購入（クレジット）
  クレジットカードで入金: 'ฝากเงินด้วยบัตรเครดิต',
  有効期限: 'วันหมดอายุ',
  クレジットカード番号: 'หมายเลขบัตรเครดิต',
  カード名義人: 'ชื่อผู้ถือบัตร',
  支払い回数: 'จำนวนงวดในการชำระเงิน',
  一括払い: 'งวดเดียว',
  '3回払い': '3 งวด',
  '6回払い': '6 งวด',
  セキュリティコード: 'รหัสความปลอดภัย',
  '以上の内容でよろしければ「入金する」ボタンをクリックしてください。':
    'หากคุณพอใจกับเนื้อหาข้างต้น โปรดคลิกปุ่ม "ฝากเงิน"',
  'お支払い手続きが完了いたします。':
    'ขั้นตอนการชำระเงินจะเสร็จสมบูรณ์',
  // ポイント購入（現金）
  現金で入金: 'ฝากเงินด้วยเงินสด',
  '支払額を以下の口座にお振込ください。':
    'กรุณาโอนจำนวนเงินที่ชำระไปยังบัญชีต่อไปนี้',
  依頼人名: 'ชื่อลูกค้า',
  銀行名: 'ชื่อธนาคาร',
  支店名: 'ชื่อสาขา',
  店番号: 'หมายเลขสาขา',
  口座科目: 'ประเภทบัญชี',
  口座番号: 'หมายเลขบัญชีธนาคาร',
  口座名義: 'ชื่อบัญชี',
  // ポイント購入完了（共通）
  入金情報送信完了: 'ส่งข้อมูลการฝากเงินเรียบร้อยแล้ว',
  '確認ができ次第メールにてお知らせいたします。今しばらくお待ちください。':
    'เราจะแจ้งทางอีเมล์ทันทีที่ตรวจสอบเสร็จ กรุณารอสักครู่',
  // ポイント出金・交換(トップ)
  '出金・交換ポイント数': 'คะแนนที่แลก/ถอนเงิน',
  '出金・交換をするポイント数を入力してください。': 'กรอกเลขคะแนนที่จะแลกหรือถอนเงิน',
  ポイント残高: 'คะแนนคงเหลือ',
  '出金・交換方法を選択': 'เลือกวิธีการถอน / แลกเปลี่ยน',
  '半角数字のみ入力してください。': 'โปรดป้อนตัวเลขครึ่งความกว้างเท่านั้น',
  '50ポイントから出金が可能です。': 'ถอนได้ตั้งแต่ 50 คะแนน',
  '入力ポイントが所持ポイントを超えています。': 'จุดอินพุตเกินจุดครอบครอง',
  '送金先アドレスを入力してください。': 'กรุณากรอกที่อยู่สำหรับการโอนเงินของคุณ',
  // ポイント出金・交換(仮想通貨)
  送金手続き: 'ขั้นตอนการส่งเงิน',
  送金内容の確認: 'ตรวจสอบข้อมูลการส่งเงิน',
  送金額: 'จำนวนเงินที่จะส่ง',
  送金先アドレス: 'ที่อยู่สำหรับส่งเงิน',
  '上記内容に間違いがないことを確認して送金をしてください。送金確定後の返金や操作の取り消しはできません。': 'ตรวจสอบความถูกต้องของข้อมูลการส่งเงิน แล้วกด "ถอนเงิน/แลกคะแนน" เมื่อส่งเงินแล้ว คุณจะไม่สามารถคืนเงินหรือยกเลิกการถอนเงิน/แลกคะแนนได้',
  // ポイント出金・交換(受付完了)
  '出金・交換を受け付けました。': 'คุณได้ขอถอนเงิน/แลกคะแนนเรียบร้อยแล้ว',
  '取引に問題があった場合はメールにてご連絡を致します。またご不明な点がありましたらサポートまでお問い合わせください。': 'เราจะติดต่อคุณทางอีเมล์ในกรณีที่มีข้อขัดข้องในการทำรายการ หากมีข้อสงสัย สามารถติดต่อได้ที่ศูนย์ช่วยเหลือ',
  // 履歴
  べット履歴: 'ประวัติการวางเงินเดิมพัน',
  当選履歴: 'ประวัติการถูกรางวัล',
  ポイント購入履歴: 'ประวัติการซื้อคะแนน',
  'ポイント出金・交換履歴': 'ประวัติการถอนเงิน/แลกคะแนน',
  もっと見る: 'ดูต่อ',
  // DayLott'sとは
  "DayLott'sでは、毎日ミリオネアを排出する、世界初のゲームです。少額から大口まで、多彩なテーブルを用意することで、より多くの方にゲームを楽しんでもらうことができます。":
    'DayLott เป็นเกมแรกในโลกที่สร้างเศรษฐีทุกวัน เรามีโต๊ะหลากหลายตั้งแต่ขนาดเล็กไปจนถึงขนาดใหญ่ เพื่อให้ผู้คนสามารถเพลิดเพลินกับเกมได้มากขึ้น',
  '業界最高水準の75%！！世界で１番ユーザーが獲得できるゲームになるようにこだわって作りました。そしてその割合は常に一定です。ゲームにエントリーをした全てのユーザーに平等に獲得するチャンスがあります。':
    'ชั้นนำในอุตสาหกรรม 75%!! เราได้ทำให้ความหลงใหลในการทำให้เป็นเกมที่มีผู้ใช้มากที่สุดในโลก และเปอร์เซ็นต์จะคงที่เสมอ ผู้ใช้ทุกคนที่เข้าสู่เกมมีโอกาสชนะเท่ากัน',
  'ビットコイン、テザー、イーサリアムでゲームを選ぶポイントを購入することができます。':
    'คุณสามารถซื้อคะแนนเพื่อเล่นเกมด้วย Bitcoin, Tether หรือ Ethereum',
  '会員登録からエントリーまでたった3分で完了します。スマートフォンがあればいつでもどこでも気軽にゲームにエントリーをすることができます。':
    'ใช้เวลาเพียง 3 นาทีจากการลงทะเบียนจนถึงรายการ หากคุณมีสมาร์ทโฟน คุณสามารถเข้าสู่เกมได้อย่างง่ายดายทุกที่ทุกเวลา',
  // 仕組み
  '世界初、必ず購入した誰かが高額当選、ミリオネアを誕生させる。':
    ' และเป็นเกมแรกของโลกที่ผู้ที่ซื้อตั๋วจะได้เป็นเศรษฐีจากการถูกรางวัลใหญ่',
  '世界最高水準還元率が驚異の75%を誇るオンラインゲームです。':
    'อัตราผลตอบแทนที่สูงที่สุดในโลกคือ 75% ที่น่าประหลาดใจสำหรับเกมออนไลน์',
  'エントリーするベットポイント、当選ポイントに応じたボックスが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステム。エントリーしたボックスのチケット購入数が満たされると即座に抽選が始まり、当選が確定します。世界一簡単な、世界一高額当選のできる世界一スピード感のあるオンラインゲームです。':
    'เว็บไซต์นี้มีกล่องเกมที่ปรับตามคะแนนเดิมพัน ส่วนกติกาก็ง่ายมาก เพียงแค่ซื้อตั๋วแล้วเข้าร่วมเล่นเกม การจับรางวัลหาผู้โชคดีจะเริ่มทันทีเมื่อมีจำนวนรายการซื้อในกล่องเกมครบจำนวนที่กำหนด ถือเป็นเกมออนไลน์ที่ง่ายที่สุดโลก, มีแจ็กพอตมูลค่ามากที่สุดในโลก และใช้เวลารวดเร็วที่สุดในโลก',
  '対応端末 iOS 14.8以降 Android 12.0以降':
    'อุปกรณ์ที่รองรับ iOS 14.8 หรือใหม่กว่าและ Android 12.0 หรือใหม่กว่า',
  'チケットを購入し、抽選ページにアクセス':
    'ซื้อตั๋ว แล้วไปที่หน้าการจับรางวัล',
  '抽選演出開始され、当選演出時にご自身で登録したユーザー名が枠内に止まりましたら当選確定':
    'เริ่มต้นการจับรางวัล คุณจะได้รับรางวัลเมื่อชื่อผู้ใช้งานของคุณหยุดอยู่ที่ช่อง',
  '当選確定後、当選ポイントが反映され出金が可能':
    'เมื่อคุณได้รับรางวัล จะมีคะแนนที่ถูกรางวัลปรากฏขึ้น ซึ่งคุณสามารถนำคะแนนดังกล่าวนี้ไปถอนเงินได้',
  ご購入された誰かが必ず高額当選:
    'ใครซื้อไปโดนรางวัลใหญ่แน่นอน',
  "ボックス毎に設定された高額当選ポイントが、ご購入された誰かに当選します。Day Lott'sではボックスに設定されている当選ポイントは必ずユーザーに反映されます。":
    'คะแนนการชนะที่มีราคาสูงในแต่ละกล่องจะเป็นผู้ชนะโดยผู้ที่ซื้อกล่อง ใน Day Lott คะแนนที่ชนะในกล่องจะมาจากผู้ใช้ที่ชนะเสมอ',
  '安心・安全・透明性のある当選': 'การเลือกตั้งที่ปลอดภัย มั่นคง และโปร่งใส',
  "Day Lott'sでは、透明性のある運営を心がけています。全てのユーザーが当選したユーザーを確認することができます。また、個人情報の保護、入出金におけるセキュリティの強化をしているため、安全にプレイをして頂くことができます。":
    'ที่ Day Lott เรามุ่งมั่นเพื่อความโปร่งใสเสมอ ผู้ใช้ทุกคนสามารถตรวจสอบผู้ใช้ที่ชนะ นอกจากนี้ เราได้เสริมความแข็งแกร่งในการปกป้องข้อมูลส่วนบุคคลและความปลอดภัยของการฝากและถอนเงิน คุณสามารถเล่นได้อย่างปลอดภัย',
  バリエーションのある当選ポイント設定: 'การกำหนดคะแนนที่ชนะด้วยรูปแบบต่างๆ',
  'ユーザーのニーズを満たしたプレイボックスを多く用意しています。小さなべットエントリーで高額当選ポイントを獲得することも可能です。最低5ポイントからエントリーが可能となっており、最大100ミリオンポイントの当選も実現します。':
    'เรามีกล่องเล่นต่างๆ มากมายที่ตรงตามความต้องการของผู้ใช้ นอกจากนี้ยังเป็นไปได้ที่จะได้รับคะแนนชนะสูงด้วยรายการเดิมพันขนาดเล็ก คุณสามารถป้อนขั้นต่ำ 5 คะแนนและชนะมากถึง 100 ล้านคะแนน',
  // よくある質問
  入金: 'ฝากเงิน',
  出金: 'ถอนเงิน',
  'よくある質問はありません。': 'ไม่มีคำถามที่พบบ่อย',
  // ランキング
  一日: 'รายวัน',
  週間: 'รายสัปดาห์',
  月間: 'รายเดือน',
  全期間: 'ระยะเวลาทั้งหมด',
  'ランキングはありません。': 'ยังไม่มีการจัดอันดับ',
  // サポート
  '当ページのよくある質問をまずはご覧ください。':
    'ดูคำถามที่พบบ่อยในหน้านี้',
  メールでのお問い合わせ: 'ติดต่อสอบถามผ่านทางอีเมล์',
  'までお問い合わせください。': 'ติดต่อสอบถามได้ที่',
  お問合せ時間: 'เวลาติดต่อสอบถาม',
  '毎日対応させて頂いております。': 'เราตอบคำถามผู้ใช้ทุกวัน',
  '※お問い合わせの回答に数日頂く事がございます。3営業日以内には回答させて頂きます。':
    '*※อาจใช้เวลาหลายวันในการตอบคำถามของคุณ เราจะดำเนินการภายใน 3 วันทำการ',
  // PLAYBOX
  テーブル選択: 'การเลือกตาราง',
  予約状況: 'สถานะการจอง',
  口: 'หน่วย',
  ゼウス: 'ซุส',
  キング: 'กษัตริย์',
  ダイヤモンド: 'เพชร',
  ゴールド: 'ทอง',
  シルバー: 'เงิน',
  ブロンズ: 'ทองแดง',
  アンバー: 'อำพล',
  口数選択: 'เลือกจำนวนคน',
  購入する: 'ซื้อ',
  キャンセル: 'ยกเลิก',
  ダイヤモンド獲得確率: 'อัตราการชนะของเพชร',
  ゴールド獲得確率: 'อัตราการชนะทอง',
  シルバー獲得確率: 'อัตราการชนะของเงิน',
  ブロンズ獲得確率: 'อัตราการชนะเหรียญทองแดง',
  獲得確率: 'อัตราการชนะ',
  獲得: 'ชนะ',
  確率: 'ประเมินค่า',
  口購入: 'หน่วยซื้อ',
  '所持ポイントが不足しています。': 'คะแนนไม่เพียงพอ',
  '購入するにはログインが必要です。': 'คุณจำเป็นต้องลงชื่อเข้าใช้งานก่อนการซื้อ',
  '1度に購入できるのは2000口までです。': 'คุณสามารถซื้อได้สูงสุด 4,000 หน่วยต่อครั้ง',
  // チケット購入確認
  購入内容のご確認: 'ตรวจสอบข้อมูลการซื้อ',
  番: 'หมายเลข ',
  '下記の内容で間違いないかご確認ください。':
    'โปรดตรวจสอบสิ่งต่อไปนี้เพื่อให้แน่ใจว่าไม่มีข้อผิดพลาด',
  '「購入する」ボタンをクリックすると購入が確定します。':
    'คลิกปุ่ม "ซื้อ" เพื่อดำเนินการซื้อให้เสร็จสิ้น',
  参加ボックス: 'กล่องเกมที่คุณเข้าร่วม',
  購入ポイント数: 'จำนวนคะแนนที่ซื้อ',
  消費ポイント数: 'คะแนนการบริโภค',
  所持有償ポイント数: 'ครอบครองคะแนนที่จ่าย',
  所持無償ポイント数: 'คะแนนฟรีครอบครอง',
  残り有償ポイント数: 'คะแนนที่เหลือจ่าย',
  残り無償ポイント数: 'คะแนนว่างที่เหลือ',
  // チケット購入完了
  チケット番号: 'หมายเลขตั๋ว',
  '参加中のPLAY BOX': 'PLAY BOX ที่คุณกำลังเข้าร่วม',
  抽選画面へ: 'ไปที่หน้าจอการจับรางวัล',
  // お知らせ一覧
  '購入済みのチケットの抽選が開始されました。矢印をタップすると結果を見ることができます。':
    'การจับตั๋วที่ซื้อแล้วได้เริ่มขึ้นแล้ว กดหรือแตะที่นี่เพื่อดูผลการจับรางวัล',
  'お客様のランクがアップされました。': 'คุณได้รับการเลื่อนอันดับ',
  キャンペーン: 'แคมเปญ.',
  表示を閉じる: 'ยุบรายการ',
  // お知らせ
  'お知らせはありません。': 'ยังไม่มีข่าวสาร',
  抽選の結果を見る: 'ดูผลการจับรางวัล',
  抽選会場へ行く: 'ไปที่ลอตเตอรี',
  マイページで確認: 'ตรวจสอบที่หน้าของฉัน',
  // お問い合わせ
  お問い合わせフォーム: 'แบบฟอร์มติดต่อสอบถาม',
  'お客様からのご質問をお問い合わせフォームにて受け付けております。':
    'เรายอมรับการติดต่อจากลูกค้าโดยใช้แบบฟอร์มการติดต่อ',
  '下記項目をご記入の上、「確認する」を押してください。':
    'กรุณากรอกรายการต่อไปนี้และกด "ยืนยัน"',
  氏名: 'ชื่อเต็ม',
  お問い合わせ内容: 'ข้อมูลติดต่อสอบถาม',
  // お問い合わせ確認
  お問い合わせ内容の確認: 'ตรวจสอบข้อมูลติดต่อสอบถาม',
  // お問い合わせ受付完了
  'お問い合わせを受け付けました。': 'เราได้รับข้อมูลติดต่อสอบถามจากคุณแล้ว',
  'お問い合わせありがとうございます。': 'เราได้รับข้อมูลติดต่อสอบถามจากคุณแล้ว',
  'お問い合わせには3営業日以内に回答させて頂きます。':
    'เราจะให้คำตอบภายใน 3 วันทำการ',
  // 抽選結果
  'このページは動画が自動再生されます。よろしければ"OK"を押してください。動画を再生せずポイントを受け取る場合は"受け取る"を押してください。':
    'วิดีโอจะเล่นโดยอัตโนมัติในหน้านี้ กรุณากด "ตกลง" หากคุณต้องการ หากต้องการรับคะแนนโดยไม่ต้องเล่นวิดีโอ ให้คลิก "รับ"',
  Waiting: 'การรอคอย',
  購入日時: 'วันและเวลาในการซื้อ',
  当選ポイント: 'คะแนนเดิมพัน',
  // ポイント受け取り
  獲得ポイント受け取り: 'รับคะแนนสะสม',
  動画を再生せずに獲得した全てのポイントを受け取る: 'รับคะแนนทั้งหมดที่ได้รับโดยไม่ต้องเล่นวิดีโอ',
  'ポイントを受け取りました。': 'คะแนนที่ได้รับ',
  // 共通エラー
  通信エラー: 'เกิดข้อขัดข้องในเครือข่าย',
  サーバーエラー: 'เกิดข้อขัดข้องในเซิร์ฟเวอร์',
  // バリエーションエラー
  'は入力必須です。': 'คุณจำเป็นต้องกรอก',
  'メールアドレスは正しい形式で入力してください。':
    'กรอกที่อยู่อีเมล์ในรูปแบบที่ถูกต้อง',
  'ユーザー名は半角英数字のみで入力してください。':
    'โปรดป้อนชื่อผู้ใช้โดยใช้ตัวอักษรและตัวเลขแบบไบต์เดี่ยวเท่านั้น',
  'パスワードは大文字小文字を含む半角英数字を組み合わせて入力してください。':
    'โปรดป้อนรหัสผ่านโดยใช้อักขระแบบไบต์เดี่ยวและตัวเลขรวมทั้งตัวพิมพ์ใหญ่และตัวพิมพ์เล็ก',
  'パスワードが一致しません。': 'รหัสผ่านไม่ตรงกัน',
  '画像は.jpgか.pngをアップロードしてください。':
    'อัปโหลดรูปภาพในสกุลไฟล์ .jpg หรือ .png',
  '金額を入れてください。': 'โปรดป้อนจำนวนเงิน',
  '日本円での合計金額(手数料込)': 'ยอดรวมทั้งหมด (รวมค่าธรรมเนียม) ในเงินเยนญี่ปุ่น',
  同意: 'Agree',
};
