import { FC, memo, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import CompletionLayout from 'components/templates/CompletionLayout';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';
import { useAccountDeleteFinish } from 'hooks/pages/guest/useAccountDeleteFinish';

/**
 *
 * Component
 *
 */
const AccountDeleteFinish: FC = memo(() => {
  // Hooks
  const { t } = useTranslation();
  const history = useHistory();
  const { referrer } = useAccountDeleteFinish();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    // 確認ページ以外からのアクセス制御
    if (referrer !== '/act/dle/confirm') {
      history.replace('/');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.accountDeleteCompleted')}</title>
        <meta name="description" content={t('description.accountDeleteCompleted')} />
      </Helmet>
      <CompletionLayout
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title="アカウント停止完了"
        to="/"
        linkText="TOPに戻る"
      >
        <TextS>
          {t('アカウントの停止が完了しました。ご利用いただきありがとうございました。')}
        </TextS>
      </CompletionLayout>
    </>
  );
});
export default AccountDeleteFinish;

/**
 *
 * Styles
 *
 */
const TextS = styled.div`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 5rem;
  text-align: center;

  @media (min-width: 600px) {
    width: 60%;
    font-size: 1.6rem;
  }

  @media (min-width: 1025px) {
    width: 40%;
  }
`;
