import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type Props = {
  color: string;
  justifyContent: string;
  children: ReactNode;
};

/**
 *
 * Component
 *
 */
const ColorFrame: FC<Props> = memo(({ color, justifyContent, children }) => (
  <ColorFrameS color={color} justifyContent={justifyContent}>{children}</ColorFrameS>));

export default ColorFrame;

/**
 *
 * Styles
 *
 */
const ColorFrameS = styled.div<{ color: string, justifyContent: string }>`
  display: inline-flex;
  width: 100%;
  padding: 2rem 1rem;
  font-size: inherit;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid ${({ color }) => color};
  border-radius: 7px;
  justify-content: ${({ justifyContent }) => justifyContent};
`;
