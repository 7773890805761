import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import FormLayout from 'components/templates/FormLayout';
import NormalInput from 'components/atoms/input/NormalInput';
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { usePasswordReset } from 'hooks/pages/common/usePasswordReset';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const PasswordReset: FC = () => {
  // i18next
  const { t } = useTranslation();

  // パスワードリセット申請用カスタムフック
  const {
    errorMessage,
    isLoading,
    emailRef,
    breadCrumbItems,
    postApiForgetPassword,
    cleanUpMethod,
  } = usePasswordReset();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    return () => {
      cleanUpMethod();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.passwordReset')}</title>
        <meta name="description" content={t('description.passwordReset')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={breadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('パスワードのリセット')}
        titleStyle="silver"
      >
        {/* フォーム */}
        <FormS>
          {/* エラーメッセージ */}
          {errorMessage && (
            <ErrorMessageWrapS>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </ErrorMessageWrapS>
          )}

          {/* ローディングアニメーション */}
          {isLoading && <LoaderWindow />}

          <InputWrapS>
            <NormalInput
              ref={emailRef}
              name="email"
              type="email"
              placeholder={t('メールアドレス')}
            />
          </InputWrapS>

          <TextS>
            {t('登録済みのメールアドレスにパスワード再設定用のURLを送信します。')}
          </TextS>

          <RoundButtonWrapS>
            {isLoading ? (
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {t('パスワードのリセットを申請する')}
              </RoundButton>
            ) : (
              <RoundButton
                onClick={postApiForgetPassword}
                background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                borderColor="rgba(219,0,177,1)"
              >
                {t('パスワードのリセットを申請する')}
              </RoundButton>
            )}
          </RoundButtonWrapS>
        </FormS>
      </FormLayout>
    </>
  );
};
export default PasswordReset;

/**
 *
 * Styles
 *
 */
const ErrorMessageWrapS = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const FormS = styled.div`
  width: 100%;
  margin-top: 2rem;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
    margin-top: 2.4rem;
  }

  @media (min-width: 1025px) {
    width: 50%;
    margin-top: 3rem;
  }
`;

const InputWrapS = styled.div`
  width: 100%;
  font-size: 1.4rem;
  margin-bottom: 1.2rem;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
`;

const TextS = styled.p`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 3rem;
`;

const RoundButtonWrapS = styled.div`
  width: 90%;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;
