/**
 * PLAYBOX 種別
 */
export const PLAYBOX_TYPE = {
  BOX_1000: '1000',
  BOX_5000: '5000',
  BOX_10000: '10000',
  BOX_100000: '100000',
  BOX_1000000: '1000000',
} as const;

export const PLAYBOX_ID = {
  ID_INITIAL: '',
  ID_1000: 'dayLotts1000',
  ID_5000: 'dayLotts5000',
  ID_10000: 'dayLotts10000',
  ID_100000: 'dayLotts100000',
  ID_1000000: 'dayLotts1000000',
} as const;

export const MAX_PURCHASE_UNIT_ONCE = 2000 as const;
