// External library
import Resizer from 'react-image-file-resizer';

/**
 *
 * ファイル処理
 *
 */
export const useFile = (): {
  resizeFile: (file: File) => Promise<string>;
  convertFile: (dataUri: string) => Blob;
} => {
  // Fileデータのリサイズ処理
  const resizeFile = (file: File) => new Promise<string>((resolve) => {
    Resizer.imageFileResizer(
      file, // Path of image file
      300, // Max height
      300, // Max width
      'JPEG', // Compress format
      100, // Quality
      0, // Rotation
      (uri) => {
        resolve(uri as string);
      }, // Response uri function
      'base64', // Output type
    );
  });

  // DataURIからBlobへの変換処理
  const convertFile = (dataUri: string) => {
    // base64エンコードされたDataURIをバイナリー文字列にエンコード
    const blob = window.atob(dataUri.replace(/^.*,/, ''));
    // 8bit符号なし整数値の配列生成
    const buffer = new Uint8Array(blob.length);
    // UTF-16文字コード取得
    const utf16Buffer = buffer.map((_, index) => blob.charCodeAt(index));

    return new Blob([utf16Buffer.buffer], { type: 'image/jpeg' });
  };

  return {
    resizeFile,
    convertFile,
  };
};
