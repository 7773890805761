import { zhTwSeo } from 'utils/lang/seo/zhTwSeo';

/**
 * 中文（繁体）
 */
export const zh_tw = {
  // メタ情報
  ...zhTwSeo,
  // ページ
  トップ: '首頁',
  新規会員登録: '新會員註冊',
  入力内容の確認: '輸入內容的確認',
  メール認証: '電子郵件認證',
  ログイン: '登入',
  パスワードのリセット: '重設密碼',
  パスワードの再設定: '密碼重新設定',
  マイページ: '我的頁面',
  アカウントの停止: '帳號暫停',
  メールアドレスの変更: '電子郵件地址的變更',
  'ポイント出金・交換': '點數提領/兌換',
  KYC書類アップロード: 'KYC 文件上傳',
  "Day Lott'sとは": "何謂 Day Lott's",
  仕組み: '系統',
  よくある質問: '常見問題',
  ランキング: '排名',
  サポート: '支援',
  お知らせ: '通知',
  言語: '語',
  'NEWSはありません。': '沒有通知。',
  '高額獲得者はいません。': '沒有大贏家。',
  '業界最高水準である、当選確率1/8も用意されているオンラインゲームです。還元率は75%!! 1ゲーム5Pからゲーム参加可能。5Pから10,000Pの当選も毎日開催されています。':
    '業界最高水準、備有中獎概率達 1/8 的線上遊戲。還元率為 75%!! 1 個遊戲只要 5P 起即可參加。每天舉辦從 5P 至 10,000P 的開獎。',
  "1day,1million,1richをテーマにDay Lott'sは運営をしています。毎日世界中のどこかでミリオネアがDay Lott'sから誕生しています。我々のサービスは、1日1,000,000Pの当選を目指しています。":
    "Day Lott's 的營運以 1day,1million,1rich為主題。世界的某處將每天從 Day Lott's 中誕生百萬富翁。我們的服務以每天中獎 1,000,000P 為目標。",
  "Day Lott'sでは、入金、出金の際に仮想通貨を利用することができます。仮想通貨の利用により、ボーダーレスに、そしてスピードのある入出金が可能となっております。":
    "在 Day Lott's 中，您可以使用虛擬貨幣進行提款與存款。透過使用虛擬貨幣，將可達到無國界且迅速的存提款。",
  'メールアドレスを登録フォームに入力し、会員登録を行います。':
    '將電子郵件地址輸入在註冊表格上，進行會員註冊。',
  会員登録: '會員註冊',
  '入金・チャージ': '存款/儲值',
  '仮想通貨、振込など様々な方法でポイントをチャージすることができます。仮想通貨、振込など様々な方法でポイントをチャージすることができます。':
    '可以使用虛擬貨幣、轉帳等各種方法儲值點數。',
  チケット購入: '購買票券',
  'ゲームに参加するためにチケットを、ポイントで購入します。':
    '用點數購買票券以參加遊戲。',
  当選の確定: '中獎的確定',
  'ゲームにエントリーをし、ゲームが開始されたら、すぐに当選の確定が分かります。当選確定の演出もお楽しみください！':
    '一旦在遊戲中下注且遊戲開始後，馬上可以知道是否確定中獎。並請欣賞中獎確定前的演出！',
  '当選が確定したら、即座にマイページに当選ポイントが反映されます。また、そのポイントを換金して出金することができます。':
    '如果確定中獎，中獎點數會立即反映至「我的頁面」中。另外，可以將該點數兌換成現金並提款。',
  // フッター
  利用規約: '使用條款',
  お問い合わせ: '聯繫我們',
  利用規約に同意し登録する: '同意<0>使用條款</0>並註冊',
  '※ユーザー名は後から変更をすることはできません。': '※用戶名以後不能更改。',
  '※パスワードは大文字、小文字、数字を組み合わせて8文字以内で入力してください。': '※密碼必須是大小寫字母和數字的組合，不超過8個字符。',
  プライバシーポリシー: '隱私政策',
  責任あるギャンブル: '負責任的賭博',
  "DayLott'sとこのウェブサイトは、Seven Eyes Ent. Company（会社番号227777/076/077および登録住所Sheetal Marg, Baluwater Kathmandu, Nepal）によって運営されています。 Seven Eyes Ent. Companyはネパール政府より発行された、Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010に基づき付与されたサブライセンスを通して正式な許可を保持しています。":
    "DayLott's and this website are operated by Seven Eyes Ent. Company. (Company number 227777/076/077 and registered address Sheetal Marg, Baluwater Kathmandu, Nepal) Seven Eyes Ent. Company holds a formal license through a sub-license granted under Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010 issued by the Government of Nepal. Seven Eyes Ent.",
  プレイヤー保護について: 'About Player Protection',
  責任のあるギャンブルポリシー: 'Responsible Gambling Policy',
  '1 .未成年者の保護': '1 .Protection of minors',
  '2 .プレイのルール': '2 .Rules of Play',
  '3 .結託': '3 .Collusion',
  '4 .カスタマーサービスと顧客からの苦情':
    '4 .Customer Service and Customer Complaints',
  '4.1.カスタマーサービス': '4.1.Customer Service',
  '4.2.苦情の提出': '4.2.Submission of Complaints',
  "4.3.DayLott'sとの書面による連絡に含める必要がある情報":
    "4.3.Information You Must Include in Your Written Communication with DayLott's",
  '1 .ギャンブルの自己評価': '1 .Gambling self-assessment',
  '2 .サポート組織': '2 .Support Organizations',
  '3 .コントロールの維持': '3 .Maintaining control',
  '3.1.自己排除': '3.1.Self-Exclusion',
  "インターネットはモバイル機器や世界中の家庭から簡単にアクセスできるため、責任のあるオンラインギャンブルは親の管理と監督に大きく依存しています。インターネット上での子供の安全を確保するため、DayLott'sは未成年者が不適切なオンライン資料にアクセスするのを防止するフィルタリングソフトウェアを使用するようにお客様に推奨します。DayLott'sでは、保護者に対して次のヒントを提供しています。":
    "Because the Internet is easily accessible from mobile devices and homes around the world, responsible online gambling relies heavily on parental control and supervision. To ensure the safety of children on the Internet, DayLott's encourages its customers to use filtering software that prevents minors from accessing inappropriate online material. DayLott's offers the following tips to parents",
  '自分のメールアドレスを安全に保つ。':
    'Keep your email address and password secure.',
  "自分のDayLott'sアカウントにログインしたときにコンピューターを無人で放置しない。":
    "Do not leave your computer unattended when you log in to your DayLott's account.",
  '18歳未満の人がいかなるギャンブル活動への参加も許可しない。':
    'Do not allow anyone under the age of 18 to participate in any gambling activity.',
  '自分のクレジット/デビットカードおよびその他の金融口座の詳細を、子供の手の届かないところに保管する。':
    'Keep your credit/debit card and other financial account details out of the reach of children.',
  'パスワードをコンピューターに保存しない。パスワードを書き留め、未成年者から離れた安全な場所に保管する。':
    'Do not store passwords on your computer. Write down passwords and keep them in a safe place away from minors.',
  "共有コンピューターからDayLott'sにアクセスする場合、特にコンピューターを子供と共有する場合は、「記憶する」機能を使用しない。":
    'When accessing DayLott\'s from a shared computer, especially if the computer is shared with children. Do not use the "remember" function when accessing DayLott\'s from a shared computer, especially if the computer is shared with children.',
  '未成年のギャンブルによって引き起こされる違法性と潜在的な損害について、自分の子供たちに教育する。':
    'Educate your children about the illegality and potential damage caused by underage gambling.',
  '未成年者によるすべての電子機器ならびにモバイルデバイスの使用には特に注意する。':
    'Be especially careful about the use of all electronic and mobile devices by minors.',
  '自分の子供がギャンブルサイトを含む不適切な題材にアクセスするのを防ぐために、フィルタリングソフトウェアを活用する。':
    'Use filtering software to prevent your children from accessing inappropriate material, including gambling sites.',
  "未成年者のギャンブルは違法であるだけでなく、監督されていない子供が負った早期のギャンブル依存と実質的なギャンブルでの借金につながる可能性があります。したがって、自分の携帯電話、タブレット、ラップトップまたはコンピューターを法定年齢に満たない友人または家族と共有してオンラインギャンブルに参加する場合、DayLott'sは以下のいずれかのサービスを使用して、ウェブサイトへのアクセスを制限することを推奨します。":
    "Gambling by minors is not only illegal, but can lead to early gambling addiction and substantial gambling debts incurred by unsupervised children. Therefore, if you share your cell phone, tablet, laptop or computer with a friend or family member who is under the legal age to participate in online gambling, DayLott's recommends that you restrict their access to the website by using one of the following services.",
  'Netnanny.com - 不適切なウェブコンテンツから子供を守るフィルタリングソフトウェア。':
    'Netnanny.com - Filtering software that protects children from inappropriate web content.',
  'Cybersitter.com - 保護者がブロックするサイトを自身で追加することができるフィルタリングソフトウェア。':
    'Cybersitter.com - Filtering software that allows parents to add their own sites to block.',
  'betterinternetforkids.eu - EUが共同出資し、インターネットフィルタソフトウェアに関する情報を提供し、無料のテスト版を提供するウェブサイト。':
    'betterinternetforkids.eu - A website co-sponsored by the EU that provides information about internet filter software and provides free test versions.',
  その他の重要なヒント: 'Other Important Tips',
  'ギャンブルをする決定は自分の個人的な選択であることを確認してください。次の場合は、ギャンブルをすることはおすすめしません。':
    'Make sure that your decision to gamble is your own personal choice. Gambling is not recommended in the following cases.',
  '毎日の責務を妨げる場合。': 'It interferes with your daily responsibilities.',
  '依存症の治療をしている、あるいは回復している場合。':
    'You are in treatment for or recovering from an addiction.',
  'アルコールやその他の物質の影響を受けている場合。':
    'You are under the influence of alcohol or other substances.',
  '以前の損失を取り戻そうとしている場合。あるいは賞金で借金を回収することが主な目的となっている場合。':
    'If you are trying to recover your previous loss. Or when the main goal is to collect the debt with your winnings.',
  "DayLott'sでゲームを始める前に、ゲームのルールを知ることをおすすめします。":
    "Before starting a game in DayLott's, it is recommended to know the rules of the game.",
  '顧客が他の顧客と結託していると思われる場合や、不正行為を行った場合は、直ちにカスタマーサービスにご連絡ください。':
    'If you suspect that a customer is colluding with another customer or has committed any fraudulent act, please contact Customer Service immediately.',
  'お客様はメールでカスタマーサービスにいつでも連絡することができます。':
    'Customers can contact customer service at any time via email.',
  "いかなる場合でも問題が発生してから3か月以内に、顧客の苦情/申し立てを提出する必要があります。お客様の苦情/申し立てが正確な部署に向けられ、調査されていることを確実にするために、以下の手段を介して書面による連絡をDayLott'sに提出しなければなりません。":
    "In any case, customer complaints/allegations must be submitted within three months of the problem occurring. In order to ensure that the customer's complaint/allegation is directed to the correct department and investigated, written communication must be submitted to DayLott's via the following means",
  電子メール: 'Email',
  "お客様のプライバシーを保護するために、お客様とDayLott's間のすべての電子メールによる通信は、DayLott'sで保持されているお客様の顧客アカウントに登録したメールアドレスを使用して実行する必要があります。そうでない場合、当社の対応が遅れることがあります。DayLott'sとの(苦情を含む) あらゆる書面による連絡には、以下の情報を含める必要があります。":
    "In order to protect your privacy, all email communications between you and DayLott's must be executed using the email address registered in your customer account maintained with DayLott's. Otherwise, our response may be delayed. Any written communication with DayLott's (including complaints) must include the following information.",
  'お客様のニックネーム/電話番号': 'Your nickname/phone number',
  お客様の顧客アカウントに登録済みの氏名:
    'Your name as it appears in your customer account',
  '苦情/申し立ての詳細': 'Details of the complaint/allegation',
  '苦情/申し立てに関連する特定の日付と時刻 (該当する場合)':
    'The specific date and time related to the complaint/allegation (if applicable)',
  "DayLott'sでは、お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。":
    "At DayLott's, we are committed to providing you with the most enjoyable gambling experience possible, but we also recognize that gambling can cause problems for some people. To ensure that you can continue to enjoy safe and manageable play, we support responsible gambling and have put in place a number of measures to assist you.",
  'ギャンブルによりお客様自身 (またはその他の人) の人生が支配されている可能性があると懸念する場合、次の質問で判明することがあるかもしれません。':
    "If you are concerned that gambling may be taking control of your (or others') life, the following questions may help you find out.",
  'つまらない日常や不幸な人生を忘れるためにギャンブルをしますか？':
    'Do you gamble to forget about your boring or unhappy life?',
  'ギャンブルをしていてお金が足りなくなったら、喪失感を味わい絶望を感じ、できるだけ早く再びギャンブルをする必要がありますか？':
    'If you are gambling and run out of money, do you feel lost and hopeless and need to gamble again as soon as possible?',
  '最後の1円がなくなるまでギャンブルをしますか？それが、たとえば家への交通費や生活費だとしてもですか？':
    'Do you gamble until your last penny is gone? Would you gamble until your last penny was gone, even if it was for, say, transportation home or living expenses?',
  'ギャンブルに費やした金額や時間の嘘をついてごまかしたことがありますか？':
    'Have you ever lied and cheated about the amount of money or time you spent on gambling?',
  'ギャンブルが原因で家族、友人、趣味への興味を失ったことがありますか？':
    'Have you ever lost interest in your family, friends, or hobbies because of gambling?',
  '負けた後、できるだけ早くもう一度挑戦して損失を勝たなければならないと感じますか？':
    'After losing, do you feel compelled to try again as soon as possible to win your losses?',
  '議論、不満、失望によりギャンブルをしたくなりますか？':
    'Do arguments, frustrations, or disappointments make you want to gamble?',
  'ギャンブルをすることが原因で落ち込む、あるいは自分自身に害を及ぼすと感じることがありますか？':
    'Do you feel depressed or harm yourself because of gambling?',
  'これらの質問に "はい" という答えが多いほど、深刻なギャンブルの問題を抱えている可能性が高くなります。':
    'The more "yes" answers you give to these questions, the more likely you are to have a serious gambling problem.',
  'この問題について相談するには、カスタマーサービスに連絡するか、無料で独立したアドバイスを提供する数々のサポート組織があります。ご相談希望の方は、自国のサポート組織・機関に問い合わせをして下さい。':
    'To discuss this issue, you can contact customer service or one of the many support organizations that offer free, independent advice. Please contact the support organization in your country if you would like to discuss your problem.',
  'お客様あるいは知り合いの方がギャンブルの問題を抱えている場合、認定された組織からの支援を検討することをおすすめします。':
    'If you or someone you know has a gambling problem, you may want to consider getting help from an accredited organization.',
  "DayLott'sでは、お客様にギャンブルアカウントの管理に積極的に関与することを推奨しています。そのために、当社はさまざまな責任のあるゲーミング機能を提供しています。":
    "DayLott's encourages our customers to take an active role in the management of their gambling accounts. To that end, we offer a variety of responsible gaming functions.",
  'もしお客様がギャンブルに対し問題が生じたと思われる場合には、カスタマーサービスへ連絡をし、アカウントへ自己排除期間を適用するように要請してください。':
    'If you believe that you have a problem with gambling, please contact Customer Service and request that a self-exclusion period be applied to your account.',
  以下の自己排除期間がご利用可能です:
    'The following self-exclusion periods are available',
  '1週間': '1 week',
  '2週間': '2 weeks',
  '1ヶ月': '1 month',
  '3ヶ月': '3 months',
  '6ヶ月': '6 months',
  "できる限り速やかに自己排除を適用するように努めますが、このプロセスにはある程度の作業時間が必要となりますのでご理解いただきますようお願いいたします。自己排除期間は実装されてお客様へ連絡されるまで、開始したものとはみなされませんのでご注意ください。もしその後も引き続きサービスにアクセス可能な場合には、責任をもってすぐ当社までご連絡ください。自己排除に入った時点でのDayLott'sの未確定ベットは、決済されます。お客様のベットが勝ちだった場合、勝ち金は該当する口座へ送金されます。自己排除期間が終了したあと、お客様のアカウントは自動的に再開されますのでご注意下さい。":
    "We will try to apply the self-exclusion as soon as possible, but please understand that this process may take some time. Please note that the self-exclusion period will not be considered to have started until you are notified. If you continue to have access to the service after that time, you are responsible for contacting us immediately. Any unsettled bets on DayLott's at the time of self-exclusion will be settled. If your bet is a win, your winnings will be transferred to the relevant account. Please note that your account will be automatically reopened after the self-exclusion period has expired.",
  "DayLott'sは開かれる可能性のある新しい重複アカウントを検出し、閉鎖するためにできる限りのことを常に行っております。自己排除が適用されると、いかなるマーケティング資料も受け取ることはなくなります。":
    "DayLott's will always do everything possible to detect and close any new duplicate accounts that may be opened. Once self-exclusion has been applied, you will no longer receive any marketing materials.",
  // フォーム
  メールアドレス: '電子郵件地址',
  ユーザー名: '用戶名稱',
  パスワード: '密碼',
  'パスワード(確認用)': '密碼（用於確認）',
  新しいパスワード: '新的密碼',
  '新しいパスワード(確認用)': '新的密碼（用於確認）',
  ファイルを選択: '選擇檔案',
  'ドラッグ＆ドロップするかファイルを選択してください': '請拖放或選擇檔案',
  // ボタン
  修正する: '修改',
  送信する: '發送',
  再送する: '再次寄送',
  確認する: '確認',
  登録する: '註冊',
  入金する: '存款',
  出金する: '提款',
  受け取る: '接收',
  アップロード: '上傳',
  削除: '刪除',
  停止: '暫停',
  選択: '選擇',
  送信: '提交',
  戻る: '返回',
  設定する: '設置',
  // トッページ
  さらに表示する: '查看更多',
  // 新規会員登録ページ
  '新規会員登録(タイトル)': '新會員註冊',
  // 新規会員登録確認ページ
  '内容に誤りがないか、よくご確認ください。': '請仔細確認內容是否有誤。',
  // 新規会員登録完了ページ
  メールの送信が完了しました: '郵件寄送已完成',
  '以下のメールアドレスに確認メールを送りました。メール記載のURLから登録を完了してください。':
    '已寄送確認電子郵件至以下的電子郵件地址。請從電子郵件中記載的 URL 完成註冊。',
  TOPに戻る: '返回 TOP',
  // メール認証
  '認証に成功しました。': '認證已成功。',
  ログインページへ: '前往登入頁面',
  // ログイン
  'Bunny Paradise Worldのアカウントでもログインできますが、初回はパスワードのリセットが必要です。': '您也可以使用Bunny Paradise World帳戶登錄，但第一次需要重置密碼。',
  'ログインができない、パスワードを忘れた方はこちら':
    '無法登入、忘記密碼者請按此處',
  "DayLott'sのリンクをホーム画面に追加すると次回以降簡単にアクセスができます。": "將 DayLott's 的鏈接添加到您的主屏幕，以便下次輕鬆訪問。",
  iPhoneの場合: '苹果手机',
  '1. ブラウザ(Safari)でWEBページを開いた状態で、下部の「共有アイコン」をタップ。': '1. 在瀏覽器（Safari）中打開一個網頁，然後點擊底部的“共享圖標”。',
  '2. 共有メニューに表示される「ホーム画面に追加」をタップ。': '2. 點擊共享菜單上的“添加到主屏幕”。',
  '3. 名称などをご確認いただき、画面右上の「追加」をタップ。': '3.確認姓名等後點擊屏幕右上角的“添加”。',
  '4. ホーム画面にアイコンが追加されたら完了です。': '4. 當圖標添加到主屏幕時，您就完成了。',
  確認して閉じる: '確認並關閉',
  // パスワードリセット
  '登録済みのメールアドレスにパスワード再設定用のURLを送信します。':
    '將寄送重新設定密碼用的 URL 至您註冊完成的電子郵件地址',
  パスワードのリセットを申請する: '申請重設密碼',
  '再送しました。': '已再次寄送。',
  // パスワードの再設定
  新しいパスワードを設定する: '設定新的密碼',
  '新しいパスワードを入力した後、「設定する」を押してください。':
    '輸入新密碼後，按“設置”。',
  // パスワードの再設定(完了)
  パスワードの変更が完了しました: '密碼變更已完成。',
  // マイページ
  'ポイント購入履歴はありません。': '沒有點數購買紀錄。',
  初回登録ボーナス: '初始註冊獎金',
  入金ボーナス: '存款紅利',
  紹介ボーナス: '推薦獎金',
  クレジット購入: '賒購',
  ビットコイン購入: '用比特幣購買',
  振り込み購入: '以轉賬方式購買',
  テザー購入: '通過 Tether 購買',
  イーサリアム購入: '以太坊購買',
  'ポイント出金・交換履歴はありません。': '沒有點數提領/兌換紀錄。',
  'べット履歴はありません。': '沒有投注記錄。',
  '当選履歴はありません。': '沒有中獎記錄。',
  所持ポイント: '持有點數',
  生年月日: '出生年月日',
  '※ポイントの出金・交換をする際にはKYC認証が必須となります。': '※當對點數進行出款與交換操作時，需要進行KYC驗證。',
  未設定: '未設定',
  登録情報: '註冊資訊',
  KYC提出: '提交 KYC',
  'ポイントデポジット出金・交換': '點數提領   提款/兌換',
  プレイ履歴: '記錄',
  編集: '編輯',
  ログアウト: '登出',
  KYC確認中: 'KYC 確認中',
  KYC却下: 'KYC 拒絕',
  書類のタイプを選択する: '選擇文件類型',
  パスポート: '護照',
  免許証: '駕駛執照',
  // アカウントの停止(確認)
  'アカウント情報、所持チケット、所持ポイント等が失われます。確認してから「削除」ボタンを押してください。':
    '帳戶資訊、持有票券、持有點數等將全部消失。請確認後按「暫停」按鈕。',
  '※この操作は取り消しができません。': '※此操作無法撤銷。',
  // アカウントの停止(完了)
  アカウント停止完了: '帳戶暫停已完成',
  'アカウントの停止が完了しました。ご利用いただきありがとうございました。':
    '帳戶暫停已完成。感謝您的使用。',
  // メールアドレスの変更
  '入力したメールアドレスにメールアドレス再設定用のURLを送信します。':
    '會將電子郵件重新設定的 URL 寄送至您輸入的電子郵件地址。',
  // KYC書類アップロード(フォーム)
  パスポートのアップロード: '上傳護照',
  免許証のアップロード: '上傳駕駛執照',
  年: '年',
  月: '月',
  日: '日',
  パスポートの写真をアップロード: '上傳護照的照片',
  '免許証 ( 表 ) の写真をアップロード': '上傳駕駛執照（正面）的照片',
  '免許証 ( 裏 ) の写真をアップロード': '上傳駕駛執照（背面）的照片',
  '1つ前に戻る': '返回前一個',
  '画像をアップロードしてください。': '請上傳圖片。',
  // KYC書類アップロード(確認)
  提出内容の確認: '提交內容的確認',
  送信内容の確認: '發送內容的確認',
  '以下の内容で送信します。よろしければ送信を押してください。':
    '將發送以下內容。同意的話，請按「發送」。',
  '免許証 ( 表 )': '駕駛執照（正面）',
  '免許証 ( 裏 )': '駕駛執照（背面）',
  // KYC書類アップロード(完了)
  本人確認書類送信完了: '本人確認文件已發送完成',
  '審査結果はメールにてお知らせいたします。今しばらくお待ちください。':
    '審査結果將以電子郵件通知。敬請稍待。',
  マイページへ戻る: '返回「我的頁面」',
  // ポイント購入
  ポイント購入: '購買點數',
  ポイント: '點',
  ドル: '美元',
  円: '日圓',
  レートを確認する: '確認匯率',
  ボーナスポイント: '獎勵點數',
  'ボーナスポイントは購入ポイント獲得時に付与されます。': '獎勵點數會在使用者取得所購買點數時贈送給使用者。',
  購入方法を選択: '選擇購買方法',
  仮想通貨: '虛擬貨幣',
  クレジット: '信用卡',
  現金振込: '現金轉賬',
  'ページを更新して再度お試しください。': '請刷新頁面並重試。',
  'データを取得できませんでした。': '無法取得資料。',
  '最低購入ポイント数は10ポイントです。': '最低購買點數為 10 點。',
  'お名前(必須)': '您的姓名(必填)',
  '姓(カナ)': '姓氏(假名)',
  '名(カナ)': '名字(假名)',
  '全角カタカナで入力してください。': '請輸入全角片假名。',
  '口座名義は入力必須です。': '必須輸入帳戶持有人。',
  // ポイント購入（仮想通貨）
  'レート表記はページにアクセスした時点のものです。入金時の数値とは異なる場合がございます。':
    '費率表示法是截至訪問頁面的時間。它可能與存款時的價值不同。',
  仮想通貨で入金: '以虛擬貨幣存款',
  送金アドレス: '匯款地址',
  アドレスを再取得: 'アドレスを再生成',
  'コピーしました。': '已複製。',
  'コピー失敗しました。': '複製失敗。',
  // ポイント購入（クレジット）
  クレジットカードで入金: '以信用卡存款',
  有効期限: '到期日期',
  クレジットカード番号: '信用卡號碼',
  カード名義人: '持卡人',
  支払い回数: '付款次數',
  一括払い: '一次付清',
  '3回払い': '分 3 次付款',
  '6回払い': '分 6 次付款',
  セキュリティコード: '安全碼',
  '以上の内容でよろしければ「入金する」ボタンをクリックしてください。':
    '若同意以上內容，請點擊「存款」。',
  'お支払い手続きが完了いたします。': '然後付款完成',
  // ポイント購入（現金）
  現金で入金: '以現金存款',
  '支払額を以下の口座にお振込ください。':
    '請將付款金額轉入以下賬戶。',
  依頼人名: '客戶名稱',
  銀行名: '銀行名稱',
  支店名: '分行名稱',
  店番号: '銀行代碼',
  口座科目: '帳戶分類',
  口座番号: '帳戶編號',
  口座名義: '帳戶名義',
  // ポイント購入完了（共通）
  入金情報送信完了: '存款資訊發送完成',
  '確認ができ次第メールにてお知らせいたします。今しばらくお待ちください。':
    '確認後將立即以電子郵件通知。敬請稍待。',
  // ポイント出金・交換(トップ)
  '出金・交換ポイント数': '提款/兌換積分',
  '出金・交換をするポイント数を入力してください。': '請輸入要提取/兌換的積分數。',
  ポイント残高: '剩餘點數',
  '出金・交換方法を選択': '選擇提款/兌換方式',
  '半角数字のみ入力してください。': '請僅輸入半角數字。',
  '50ポイントから出金が可能です。': '提款可以從 50 點開始。',
  '入力ポイントが所持ポイントを超えています。': '輸入點超過佔有點。',
  '送金先アドレスを入力してください。': '請輸入匯款地址。',
  // ポイント出金・交換(仮想通貨)
  送金手続き: '匯款手續',
  送金内容の確認: '確認匯款內容',
  送金額: '匯款金額',
  送金先アドレス: '匯款目標地址',
  '上記内容に間違いがないことを確認して送金をしてください。送金確定後の返金や操作の取り消しはできません。': '請於確認上述內容正確無誤之後，再進行出款與交換的手續。當匯款程序完成之後，就無法退款或是取消。',
  // ポイント出金・交換(受付完了)
  '出金・交換を受け付けました。': '系統已經收到出款與交換的請求。',
  '取引に問題があった場合はメールにてご連絡を致します。またご不明な点がありましたらサポートまでお問い合わせください。': '如果交易過程有出現任何問題的話，請您與我們聯繫。如果您有任何不明白或不清楚的地方，請與我們的客服人員聯繫詢問。',
  // 履歴
  べット履歴: '投注記錄',
  当選履歴: '中獎記錄',
  ポイント購入履歴: '點數購買紀錄',
  'ポイント出金・交換履歴': '提款/兌換點',
  もっと見る: '查看更多',
  // DayLott'sとは
  "DayLott'sでは、毎日ミリオネアを排出する、世界初のゲームです。少額から大口まで、多彩なテーブルを用意することで、より多くの方にゲームを楽しんでもらうことができます。":
    "在Day lott's，每天都会诞生很多百万富翁，这是世界首款此类型游戏。从小额到高额，通过准备多彩多样的游戏桌，让更多的人享受游戏的乐趣",
  '業界最高水準の75%！！世界で１番ユーザーが獲得できるゲームになるようにこだわって作りました。そしてその割合は常に一定です。ゲームにエントリーをした全てのユーザーに平等に獲得するチャンスがあります。':
    '行業領先75%！！我們一直痴迷於讓它成為世界上擁有最多用戶的遊戲。而且百分比始終不變。每個進入遊戲的用戶都有平等的獲勝機會。',
  'ビットコイン、テザー、イーサリアムでゲームを選ぶポイントを購入することができます。':
    '可以使用比特币、Tether、以太坊购买用于游戏的积分。',
  '会員登録からエントリーまでたった3分で完了します。スマートフォンがあればいつでもどこでも気軽にゲームにエントリーをすることができます。':
    '从注册会员到进入游戏仅需3分钟。只要一台智能手机，无论何时何地都可轻松进入游戏。',
  入金はこちら: '入金はこちら',
  // 仕組み
  '世界初、必ず購入した誰かが高額当選、ミリオネアを誕生させる。':
    '世界上首次，必定讓購買中的某個人中獎高額獎金而誕生百萬富翁。',
  '世界最高水準還元率が驚異の75%を誇るオンラインゲームです。':
    '世界上最高的網絡遊戲回報率是驚人的 75%。',
  'エントリーするベットポイント、当選ポイントに応じたボックスが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステム。エントリーしたボックスのチケット購入数が満たされると即座に抽選が始まり、当選が確定します。世界一簡単な、世界一高額当選のできる世界一スピード感のあるオンラインゲームです。':
    '我們為大家準備了投注點數與重點點數各有不同的投注箱。遊戲規則很簡單，只要購買彩券然後投注就可以了。當投注箱中的彩券數量滿足條件後，就會立刻開始抽獎，並且選出中獎者。這是全世界最簡單、中獎金額最高、且速度感最快的線上遊戲。',
  '対応端末 iOS 14.8以降 Android 12.0以降':
    'Supported devices iOS 14.8 or later and Android 12.0 or later',
  'チケットを購入し、抽選ページにアクセス': '購買彩券，然後前往抽獎頁面',
  '抽選演出開始され、当選演出時にご自身で登録したユーザー名が枠内に止まりましたら当選確定':
    '頁面開始進行抽獎演出，如果頁面中的中獎演出停留在您所註冊的使用者名稱欄位上，就表示您確定中獎了',
  '当選確定後、当選ポイントが反映され出金が可能':
    '在確定中獎後，中獎點數會反應到使用者帳號上，而該使用者也可以提領金額',
  ご購入された誰かが必ず高額当選: '只要購買就一定有某人能獲得高額獎品',
  "ボックス毎に設定された高額当選ポイントが、ご購入された誰かに当選します。Day Lott'sではボックスに設定されている当選ポイントは必ずユーザーに反映されます。":
    "每個投注箱中都會設定高額的中獎點數，而且一定會有某個購買彩券投注的使用者中獎。在Day Lott's的系統之中每個投注箱所設定的中獎點數，一定會反應到使用者帳號上。",
  '安心・安全・透明性のある当選': '安心、安全且透明的抽獎',
  "Day Lott'sでは、透明性のある運営を心がけています。全てのユーザーが当選したユーザーを確認することができます。また、個人情報の保護、入出金におけるセキュリティの強化をしているため、安全にプレイをして頂くことができます。":
    "Day Lott's對於保持經營的透明性非常重視。在我們的系統之中所有的使用者都可以確認是哪一個使用者中獎了。而且對於個人情報的保護以及存入、提領金錢部分都有強化系統安全性，讓每一位使用者都能夠安全地玩遊戲。",
  バリエーションのある当選ポイント設定: '設定的中獎點就變化多',
  'ユーザーのニーズを満たしたプレイボックスを多く用意しています。小さなべットエントリーで高額当選ポイントを獲得することも可能です。最低5ポイントからエントリーが可能となっており、最大100ミリオンポイントの当選も実現します。':
    '我們為了滿足所有使用者的需求，所以設定了許多不同的投注箱。使用者也有可能只投入極小量的彩券，就獲得極高的中獎金額。最低只要5點就可以投注參加抽獎，而且最多甚至有可能中獎獲得1億點。',
  // よくある質問
  出金: '提款',
  'よくある質問はありません。': '沒有常見問題。',
  // ランキング
  一日: '1 天',
  週間: '週',
  月間: '月',
  全期間: '所有期間',
  'ランキングはありません。': '沒有排名。',
  // サポート
  '当ページのよくある質問をまずはご覧ください。': '首先請參閱本頁的常見問題。',
  メールでのお問い合わせ: '使用電子郵件詢問',
  'までお問い合わせください。': '請詢問上述地址。',
  お問合せ時間: '詢問時間',
  '毎日対応させて頂いております。': '我們每天都會回應。',
  '※お問い合わせの回答に数日頂く事がございます。3営業日以内には回答させて頂きます。':
    '※回答您諮詢的問題可能需要花上幾天。我們會在3個工作日以內回覆您。',
  // PLAYBOX
  予約状況: '預約狀況',
  口: '口',
  ゼウス: '宙斯',
  キング: '王',
  ダイヤモンド: '鑽石',
  ゴールド: '金',
  シルバー: '銀',
  ブロンズ: '銅',
  アンバー: '琥珀',
  口数選択: '選擇口數',
  購入する: '購買',
  キャンセル: '取消',
  ダイヤモンド獲得確率: '鑽石獲得概率',
  ゴールド獲得確率: '金獲得概率',
  獲得確率: '取得概率',
  獲得: '取得',
  確率: '概率',
  口購入: '口購買',
  '所持ポイントが不足しています。': '持有點數不夠',
  '購入するにはログインが必要です。': '購買時須要登入',
  '1度に購入できるのは2000口までです。': '一次最多可購買 2000 口。',
  // チケット購入確認
  購入内容のご確認: '購買內容的確認',
  '下記の内容で間違いないかご確認ください。': '請確認以下內容是否有誤。',
  '「購入する」ボタンをクリックすると購入が確定します。':
    '點擊「購買」按鈕後即確定購買。',
  参加ボックス: '參加遊戲箱',
  購入ポイント数: '購買點數',
  消費ポイント数: '消費點',
  所持有償ポイント数: '擁有積分',
  所持無償ポイント数: '擁有免費積分',
  残り有償ポイント数: '剩餘的已付積分',
  残り無償ポイント数: '剩餘免費積分',
  // チケット購入完了
  チケット番号: '票券號碼',
  番: '號',
  '参加中のPLAY BOX': '參加中的 PLAY BOX',
  抽選画面へ: '前往抽獎畫面',
  // お知らせ一覧
  '購入済みのチケットの抽選が開始されました。矢印をタップすると結果を見ることができます。':
    '所購票券的抽獎已開始。可以點擊箭查看結果。',
  'お客様のランクがアップされました。': '客戶的等級已經提升。',
  キャンペーン: 'キャンペーン',
  表示を閉じる: '關閉顯示',
  // お知らせ
  'お知らせはありません。': '沒有通知。',
  抽選の結果を見る: '查看抽獎結果',
  抽選会場へ行く: '進入抽獎頁面',
  マイページで確認: '在我的頁面進行確認',
  // お問い合わせ
  お問い合わせフォーム: '聯繫我們',
  'お客様からのご質問をお問い合わせフォームにて受け付けております。':
    '我們接受客戶利用詢問表格提出問題。',
  '下記項目をご記入の上、「確認する」を押してください。':
    '請確認以下項目後按「確認」。',
  氏名: '全名',
  お問い合わせ内容: '詢問內容',
  // お問い合わせ確認
  お問い合わせ内容の確認: '詢問內容的確認',
  // お問い合わせ受付完了
  'お問い合わせを受け付けました。': '我們已收到您的詢問。',
  'お問い合わせありがとうございます。': '謝謝您的詢問。',
  'お問い合わせには3営業日以内に回答させて頂きます。':
    '我們將在 3 個工作天內回答您的詢問。',
  // 抽選結果
  'このページは動画が自動再生されます。よろしければ"OK"を押してください。動画を再生せずポイントを受け取る場合は"受け取る"を押してください。':
    '此頁面將自動播放視頻。如果您願意，請按 "OK"。要在不播放視頻的情況下獲得積分，請單擊“接收”。',
  Waiting: '等待',
  購入日時: '購入日時',
  当選ポイント: '中獎點數',
  // ポイント受け取り
  獲得ポイント受け取り: '獲得積分',
  動画を再生せずに獲得した全てのポイントを受け取る: '在不播放視頻的情況下獲得所有積分。',
  'ポイントを受け取りました。': '收到的積分。',
  // 共通エラー
  通信エラー: '通訊錯誤',
  サーバーエラー: '伺服器錯誤',
  // バリエーションエラー
  'は入力必須です。': '必須輸入。',
  'メールアドレスは正しい形式で入力してください。':
    '電子郵件地址請用正確形式輸入。',
  'ユーザー名は半角英数字のみで入力してください。':
    '請僅使用單字節字母數字字符輸入用戶名稱。',
  'パスワードは大文字小文字を含む半角英数字を組み合わせて入力してください。':
    '請輸入包含大寫和小寫字母的單字節字母數字字符組合的密碼。',
  'パスワードが一致しません。': '密碼不一致。',
  '画像は.jpgか.pngをアップロードしてください。':
    '圖片請上傳 .jpg 或 .png 檔案。',
  '金額を入れてください。': '請輸入金額。',
  '日本円での合計金額(手数料込)': '總金額（包括手續費）以日元計算',
  同意: '同意',
};
