import { FC, memo, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Components
import BreadCrumbs from 'components/atoms/breadCrumbs/BreadCrumbs';
import NeonBorderTitle from 'components/molecules/title/NeonBorderTitle';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
import RoundButton from 'components/atoms/button/RoundButton';
// Custom hooks
import { useKycConfirm } from 'hooks/pages/private/useKycConfirm';
import { useRedirect } from 'hooks/versatile/useRedirect';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const KycConfirm: FC = memo(() => {
  // Hooks
  const { t } = useTranslation();
  const { redirectToTopPage } = useRedirect();
  const {
    errorMessage,
    isLoading,
    breadCrumbItems,
    referrer,
    birthday,
    uploadFile,
    toBackKycUpload,
    postApiUsersKyc,
    cleanUpMethod,
  } = useKycConfirm();

  useEffect(() => {
    // アップロードページ以外からのアクセス防止
    if (
      referrer !== '/kyc/upload/passport'
      && referrer !== '/kyc/upload/license'
    ) {
      redirectToTopPage();
    }

    return () => {
      cleanUpMethod();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.kycConfirmation')}</title>
        <meta name="description" content={t('description.kycConfirmation')} />
      </Helmet>
      <KycConfirmS>
        {/* ローディングアニメーション */}
        {isLoading && <LoaderWindow />}

        <ContainerS>
          {/* パンくずリスト(PC用) */}
          <BreadCrumbsWrapS>
            <BreadCrumbs crumbItems={breadCrumbItems} />
          </BreadCrumbsWrapS>

          <ContentWrapS>
            <NeonBorderTitle
              title={t('送信内容の確認')}
              description={t('以下の内容で送信します。よろしければ送信を押してください。')}
            />

            <BoxS>
              <ListS>
                <FieldS>{t('生年月日')}</FieldS>
                <BirthDayS>{birthday}</BirthDayS>
              </ListS>
              {uploadFile.map(({ dataUri, name }) => (
                <ListS key={uuidv4()}>
                  <FieldS>
                    {name === 'passport' && t('パスポート')}
                    {name === 'license_frontside' && t('免許証 ( 表 )')}
                    {name === 'license_backside' && t('免許証 ( 裏 )')}
                  </FieldS>
                  <ThumbnailS>
                    <ImageS src={dataUri} alt="" />
                  </ThumbnailS>
                </ListS>
              ))}
            </BoxS>
          </ContentWrapS>

          {/* エラーメッセージ */}
          {errorMessage && (
            <ErrorMessageWrapS>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </ErrorMessageWrapS>
          )}

          <ButtonGroupS>
            <ButtonWrapS>
              {isLoading ? (
                <RoundButton
                  background="#555555"
                  borderColor="rgba(230, 230, 230, 1)"
                >
                  {t('修正する')}
                </RoundButton>
              ) : (
                <RoundButton
                  onClick={toBackKycUpload}
                  background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                  borderColor="rgba(0,135,167,1)"
                >
                  {t('修正する')}
                </RoundButton>
              )}
            </ButtonWrapS>
            <ButtonWrapS>
              {isLoading ? (
                <RoundButton
                  background="#555555"
                  borderColor="rgba(230, 230, 230, 1)"
                >
                  {t('送信する')}
                </RoundButton>
              ) : (
                <RoundButton
                  onClick={postApiUsersKyc}
                  background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                  borderColor="rgba(219,0,177,1)"
                >
                  {t('送信する')}
                </RoundButton>
              )}
            </ButtonWrapS>
          </ButtonGroupS>
        </ContainerS>
      </KycConfirmS>
    </>
  );
});
export default KycConfirm;

/**
 *
 * Styles
 *
 */
const KycConfirmS = styled.main`
  color: #ffffff;
  // background
  background-image: url(${backGroundSp});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y: top;

  @media (min-width: 600px) {
    background-position-y: center;
  }

  @media (min-width: 1025px) {
    background-image: image-set(
      url(${backGroundPc}) 1x,
      url(${backGroundPc2x}) 2x
    );
    background-position-y: top;
  }
`;

const ContainerS = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 7rem;
  padding-bottom: 5rem;

  @media (min-width: 600px) {
    width: 60%;
    padding-top: 10rem;
  }

  @media (min-width: 1025px) {
    width: 100%;
    max-width: 100rem;
    padding-top: 2rem;
    padding-bottom: 10rem;
  }
`;

const BreadCrumbsWrapS = styled.div`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    width: 100%;
    margin-bottom: 6rem;
  }
`;

const ContentWrapS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center center;
  row-gap: 2rem;

  @media (min-width: 1025px) {
    width: 70%;
    margin: 0 auto;
  }
`;

const ErrorMessageWrapS = styled.div`
  width: 100%;
  font-size: 1.4rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }
`;

const BoxS = styled.ul`
  width: 100%;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  // flex
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (min-width: 600px) {
    padding: 3rem;
  }

  @media (min-width: 1025px) {
    padding: 3.4rem 4rem 4rem;
  }
`;

const ListS = styled.li`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FieldS = styled.p`
  font-size: 1.4rem;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }
`;

const BirthDayS = styled.p`
  font-size: 2.4rem;
  letter-spacing: 0.7px;

  @media (min-width: 600px) {
    font-size: 2.6rem;
  }

  @media (min-width: 1025px) {
    font-size: 2.8rem;
  }
`;

const ThumbnailS = styled.div`
  width: 100%;
  // flex
  display: flex;
  justify-content: center;
`;

const ImageS = styled.img`
  width: 70%;

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 1025px) {
    width: 40%;
  }
`;

const ButtonGroupS = styled.div`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  margin-top: 2.6rem;

  @media (min-width: 600px) {
    margin-top: 3rem;
  }
`;

const ButtonWrapS = styled.div`
  font-size: 1.4rem;
  width: 50%;

  @media (min-width: 600px) {
    width: 40%;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
    width: 20%;
  }
`;
