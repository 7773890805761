import { FC, memo } from 'react';
// External library
import { useTranslation } from 'react-i18next';
// Styled components
import styled from 'styled-components';
// Components
import RoundButton from 'components/atoms/button/RoundButton';
import ErrorMessage from 'components/atoms/text/ErrorMessage';

/**
 *
 * Props
 *
 */
type Props = {
  title: string;
  description: string;
  emptyMessage: string;
  errorMessage: string;
  link?: string;
  canWatchLottery: boolean;
  lotteryFlg?: boolean;
  movieStatus?: number | undefined | null;
};

/**
 *
 * Component
 *
 */
const NewsBox: FC<Props> = memo((props) => {
  const {
    title, description, emptyMessage, errorMessage, link, canWatchLottery, lotteryFlg, movieStatus,
  } = props;

  // i18next
  const { t } = useTranslation();

  return (
    <NewsBoxS>
      <NewsWrapS>
        {/* タイトル */}
        <TitleS>{title}</TitleS>

        {/* 内容 */}
        <TextS>{description}</TextS>

        {/* 抽選画面へのリンク */}
        {(link && canWatchLottery) && (
          <RoundButtonWrapS>
            <a href={link}>
              <RoundButton
                background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                borderColor="rgba(219,0,177,1)"
              >
                { (movieStatus === 2) && t('抽選の結果を見る')}
                { (movieStatus !== 2 && link.includes('lottery')) && t('抽選会場へ行く')}
                { (!lotteryFlg && !link.includes('lottery')) && t('マイページで確認')}
              </RoundButton>
            </a>
          </RoundButtonWrapS>
        )}

        {(link && !canWatchLottery) && (
          <RoundButtonWrapS>
            <RoundButton
              background="#555555"
              borderColor="rgba(230, 230, 230, 1)"
            >
              { (movieStatus === 2) && t('抽選の結果を見る')}
              { (movieStatus !== 2 && link.includes('lottery')) && t('抽選会場へ行く')}
              { (!lotteryFlg && !link.includes('lottery')) && t('マイページで確認')}
            </RoundButton>
          </RoundButtonWrapS>
        )}

        {/* お知らせ無し */}
        {emptyMessage && (
          <MessageWrapS>
            <p>{emptyMessage}</p>
          </MessageWrapS>
        )}

        {/* エラーメッセージ */}
        {errorMessage && (
          <MessageWrapS>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </MessageWrapS>
        )}
      </NewsWrapS>
    </NewsBoxS>
  );
});
export default NewsBox;

/**
 *
 * Styles
 *
 */
const NewsBoxS = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

const NewsWrapS = styled.div`
  padding: 4rem 2rem;
  color: #ffffff;

  @media (min-width: 600px) {
    padding: 4rem 6rem;
  }

  @media (min-width: 1025px) {
    padding: 7rem 8rem;
  }
`;

const TitleS = styled.p`
  margin-bottom: 3rem;
  font-size: min(6vw, 1.7rem);
  line-height: 1.5;
  word-break: break-all;
  font-weight: bold;
  display: flex;

  @media (min-width: 600px) {
    font-size: 2rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 2.5rem;
    font-size: 2.8rem;
  }
`;

const TextS = styled.p`
  font-size: min(6vw, 1.4rem);
  word-break: break-all;
  white-space: pre-line;
  line-height: 1.7;
  margin-bottom: 5rem;

  @media (min-width: 600px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 10rem;
    font-size: 1.7rem;
  }
`;

const MessageWrapS = styled.div`
  text-align: center;
  font-size: min(6vw, 1.2rem);

  @media (min-width: 600px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.7rem;
  }
`;

const RoundButtonWrapS = styled.div`
  margin: 0 auto;
  width: min(70%, 175px);
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 35%;
    font-size: 1.7rem;
  }

  @media (min-width: 1025px) {
    width: 33%;
    font-size: 2rem;
  }
`;
