// External library
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
import { useClear } from 'hooks/versatile/useClear';
// Recoil
import { useSetRecoilState } from 'recoil';
import { userState } from 'store/atoms/userState';
import { langState } from 'store/atoms/langState';
import { unlotteryBoxesState } from 'store/atoms/unlotteryBoxesState';
// Utils
import { axiosClient, getAuthorizationHeader } from 'utils/axios';
// Types
import { ApiErrorType, UsersType, LotUnlotteryType } from 'types/api';

/**
 *
 * 認証系ロジック
 *
 */
export const useAuth = (): {
  getApiUsersMe: () => void;
  getApiLotUnlottery: () => void;
} => {
  // Hooks
  const { i18n } = useTranslation();
  const { errorHandler } = useError();
  const { clearUserState } = useClear();

  // Recoil ユーザー情報更新関数
  const setUserData = useSetRecoilState(userState);
  // Recoil 言語設定更新関数
  const setLang = useSetRecoilState(langState);
  // Recoil 参加中BOX更新関数
  const setUnlotteryBoxes = useSetRecoilState(unlotteryBoxesState);

  // ユーザー情報リクエスト
  const getApiUsersMe = () => {
    // axios GET /api/users/me
    axiosClient
      .get<UsersType>('/api/users/me', {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then(({ data }) => {
        setUserData({
          loggedIn: true,
          id: data.id,
          name: data.name,
          email: data.email,
          ethAddress: data.eth_address ?? '',
          point: data.point,
          freePoint: data.free_point,
          birthday: data.birthday,
          unreadNewsCount: data.unread_news_count,
          unreadNewsUsersCount: data.unread_news_users_count,
          unlotteryBetsCount: data.unlottery_bets_count,
          kycFlg: data.kyc_flg,
        });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        i18n.changeLanguage(data.lang);
        setLang(data.lang);
      })
      .catch((error: AxiosError<ApiErrorType>) => {
        if (error.response === undefined) {
          clearUserState();
        }
        errorHandler(error.response);
      });
  };

  // 未抽選BOX情報取得リクエスト
  const getApiLotUnlottery = () => {
    // axios GET /api/lot/unlottery
    axiosClient
      .get<LotUnlotteryType[]>('api/lot/unlottery', {
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then(({ data }) => {
        if (data.length !== 0) {
          setUnlotteryBoxes(data);
        }
      })
      .catch((error: AxiosError<ApiErrorType>) => {
        const result = errorHandler(error.response);

        if (result) {
          setUnlotteryBoxes([]);
        }
      });
  };

  return {
    getApiUsersMe,
    getApiLotUnlottery,
  };
};
