import { FC, memo, ComponentPropsWithoutRef } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type InputProps = ComponentPropsWithoutRef<'input'>;

type CssProps = {
  color: string;
  textAlign: string;
};

type Unit = {
  unit: string | null;
}

type Props = InputProps & CssProps & Unit;

/**
 *
 * Comoponent
 *
 */
const ColorBorderInput: FC<Props> = memo(({
  color, textAlign, unit = null, ...inputProps
}) => (
  <ColorBorderDivS
    color={color}
    textAlign={textAlign}
  >
    {(unit && unit !== '') && (
      <UnitS unit={unit}>{unit}</UnitS>
    )}
    <ColorBorderInputS
      unit={unit}
      {...inputProps}
    />
  </ColorBorderDivS>
));
export default ColorBorderInput;

/**
 *
 * Styles
 *
 */
const ColorBorderDivS = styled.div<CssProps>`
  width: 100%;
  display: inline-flex;
  padding: 2rem 1rem;
  font-size: 1.6rem;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid ${({ color }) => color};
  border-radius: 7px;
  margin: 0;
  text-align: ${({ textAlign }) => textAlign};

  @media (min-width: 1025px) {
    font-size: 1.8rem;
  }
`;

const UnitS = styled.span<Unit>`
  text-align: start;
  display: ${({ unit }) => (unit ? 'inline' : 'none')};
`;

const ColorBorderInputS = styled.input<Unit>`
  margin-left: -1rem;
`;
