import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Components
import VimeoVideo from 'components/organisms/lottery/VimeoVideo';
// Types
import { PlayBox } from 'types/playBoxType';
// Images
import barTopSp from 'assets/images/neonItem/bar-top_sp.png';
import barTopPc from 'assets/images/neonItem/bar-top_pc.png';
import barBottomSp from 'assets/images/neonItem/bar-bottom_sp.png';
import barBottomPc from 'assets/images/neonItem/bar-bottom_pc.png';
// Utils
import { PLAYBOX_TYPE } from 'utils/const/playBox';

/**
 *
 * Types
 *
 */
type Props = {
  playBoxType: PlayBox | null;
};

/**
 *
 * Component
 *
 */
const VideoBox: FC<Props> = memo(({ playBoxType }) => (
  <VideoBoxS>
    <BarS>
      <source srcSet={barTopPc} media="(min-width: 600px)" />
      <ImageS src={barTopSp} alt="" />
    </BarS>

    <VideoAreaS>
      {playBoxType !== null ? (
        <VimeoVideo playboxType={playBoxType} />
      ) : (
        <WaitingDisplayS>
          <WaitingTextS>Waiting for entry</WaitingTextS>
        </WaitingDisplayS>
      )}
    </VideoAreaS>

    <BarS>
      <source srcSet={barBottomPc} media="(min-width: 600px)" />
      <ImageS src={barBottomSp} alt="" />
    </BarS>
  </VideoBoxS>
));
export default VideoBox;

/**
 *
 * Styles
 *
 */
const VideoBoxS = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #000000;
`;

const BarS = styled.picture`
  width: 100%;
  display: flex;
`;

const ImageS = styled.img`
  width: 100%;
`;

const WaitingDisplayS = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WaitingTextS = styled.p`
  font-size: clamp(1.6rem, 8vw, 4rem);
  font-weight: bold;
  word-break: break-all;

  @keyframes blinking {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  animation-name: blinking;
  animation-iteration-count: infinite;
  animation-duration: 1.7s;
  animation-timing-function: linear;
`;

const VideoAreaS = styled.div`
  height: 100%;
  width: 100%;
  background-color: #000000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    display: block;
  }
`;
