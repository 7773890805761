import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import FormLayout from 'components/templates/FormLayout';
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ColorSquare from 'components/atoms/frame/ColorFrame';
import EqualIcon from 'components/atoms/icon/EqualIcon';
import ColorBorderInput from 'components/atoms/input/ColorBorderInput';
// Custom hooks
import { usePointPurchaseCredit } from 'hooks/pages/private/usePointPurchaseCredit';
// Utils
import { pointPurchaseCreditBreadCrumbItems } from 'utils/breadCrumbs';
import { PAYMENT_URL } from 'utils/const/payment';
import { devices } from 'utils/const/devices';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';

const { tabletPortrait, tabletLandscape, pc } = devices;

/**
 *
 * Component
 *
 */
const PointPurchaseCredit: FC = memo(() => {
  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    cid,
    encodedMail,
    prc,
    url1,
    url2,
    user,
    pointInputProps,
    rateJpy,
    goBack,
    onClickPurchase,
    formRef,
  } = usePointPurchaseCredit();

  return (
    <>
      <Helmet>
        <title>{t('title.pointPurchaseCredit')}</title>
        <meta
          name="description"
          content={t('description.pointPurchaseCredit')}
        />
      </Helmet>
      <FormLayout
        breadCrumbItems={pointPurchaseCreditBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('クレジットカードで入金')}
        titleStyle="gold"
      >
        {/* ローディングアニメーション */}
        {isLoading && <LoaderWindow />}

        <FormS
          action={PAYMENT_URL}
          method="post"
          // onSubmit={onSubmit}
          ref={formRef}
        >
          <input type="hidden" name="cid" value={cid} />
          <input type="hidden" name="user" value={user} />
          <input type="hidden" name="prc" value={prc} />
          <input type="hidden" name="mail" value={encodedMail} />
          <input type="hidden" name="url1" value={url1} />
          <input type="hidden" name="url2" value={url2} />

          <BlockS>
            {/* 変換レート表示部分 */}
            <PointRateBlockS>
              <PurchasePointBlockS>
                <PurchaseTextS>
                  {t('金額を入れてください。')}
                </PurchaseTextS>
                <PointBlockS>
                  <InputSquareBlockS>
                    {/* ユーザー入力要素 */}
                    <ColorBorderInput
                      color="#f502fd"
                      textAlign="end"
                      unit="$"
                      {...pointInputProps}
                    />
                  </InputSquareBlockS>
                  <PointUnitBlockS>
                    <UnitS>{t('ポイント')}</UnitS>
                  </PointUnitBlockS>
                </PointBlockS>
                <HiddenPurchaseTextS>
                  {t('金額を入れてください。')}
                </HiddenPurchaseTextS>
              </PurchasePointBlockS>

              <EqualBlockS>
                <EqualIcon />
              </EqualBlockS>

              <ConvertBlockS>
                {/* 円 */}
                <JpyBlockS>
                  <HiddenNoticeTextS>
                    {t('日本円での合計金額(手数料込)')}
                  </HiddenNoticeTextS>
                  <JpyPriceWrapS>
                    <SquareBlockS>
                      <ColorSquare color="#f502fd" justifyContent="end">
                        <JpyRateS>{rateJpy.toLocaleString()}</JpyRateS>
                        <UnitS>{t('円')}</UnitS>
                      </ColorSquare>
                    </SquareBlockS>
                  </JpyPriceWrapS>
                  <NoticeTextS>
                    {t('日本円での合計金額(手数料込)')}
                  </NoticeTextS>
                </JpyBlockS>
              </ConvertBlockS>
            </PointRateBlockS>
          </BlockS>

          <BlockS>
            <AnnounceS>
              <TextS>
                {t(
                  '以上の内容でよろしければ「入金する」ボタンをクリックしてください。',
                )}
              </TextS>
              <TextS>{t('お支払い手続きが完了いたします。')}</TextS>
            </AnnounceS>

            <ButtonS>
              <RoundButtonWrapS>
                {isLoading ? (
                  <RoundButton
                    background="#555555"
                    borderColor="rgba(230, 230, 230, 1)"
                  >
                    {t('入金する')}
                  </RoundButton>
                ) : (
                  <RoundButton
                    background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                    borderColor="rgba(219,0,177,1)"
                    onClick={onClickPurchase}
                  >
                    {t('入金する')}
                  </RoundButton>
                )}
              </RoundButtonWrapS>

              <RoundButtonWrapS>
                {isLoading ? (
                  <RoundButton
                    background="#555555"
                    borderColor="rgba(230, 230, 230, 1)"
                  >
                    {t('1つ前に戻る')}
                  </RoundButton>
                ) : (
                  <RoundButton
                    onClick={goBack}
                    background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                    borderColor="rgba(0,135,167,1)"
                  >
                    {t('1つ前に戻る')}
                  </RoundButton>
                )}
              </RoundButtonWrapS>
            </ButtonS>
          </BlockS>
        </FormS>
      </FormLayout>
    </>
  );
});

export default PointPurchaseCredit;

/**
 *
 * Styles
 *
 */
const FormS = styled.form`
  width: 100%;
  margin-top: 5rem;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1025px) {
    width: 90%;
    margin-top: 7rem;
  }
`;

const BlockS = styled.div`
  width: 100%;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PointRateBlockS = styled.div`
  // grid
  display: grid;
  row-gap: 2rem;

  @media (min-width: 600px) {
    row-gap: 2rem;

    grid-template-columns: 2fr 1fr 2fr;
    row-gap: unset;
  }
`;

const PurchasePointBlockS = styled.div`
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2rem;

  grid-row: 1/2;
  grid-column: 1/6;

  @media (min-width: 600px) {
    grid-row: unset;
    grid-column: unset;
  }
`;

const PurchaseTextS = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ffffff;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const HiddenPurchaseTextS = styled(PurchaseTextS)`
  color: transparent;
  visibility: hidden;
`;

const PointBlockS = styled.div`
  // flex
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

const JpyBlockS = styled(PointBlockS)`
  flex-direction: column;
  row-gap: 2rem;
  align-items: flex-start;
  align-self: start;
`;

const JpyPriceWrapS = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  column-gap: 1.2rem;
`;

const SquareBlockS = styled.div`
  width: 73%;
  font-size: 1.6rem;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 1025px) {
    width: 75%;
    font-size: 1.8rem;
  }
`;

const InputSquareBlockS = styled.div`
  font-size: 1.6rem;

  @media ${tabletPortrait} {
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const RateS = styled.p`
  width: 100%;
  text-align: end;
  color: #ffffff;
  word-break: break-all;
`;

const JpyRateS = styled(RateS)`
  color: #ffeb00;
`;

const NoticeTextS = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ffffff;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const HiddenNoticeTextS = styled(NoticeTextS)`
  color: transparent;
  visibility: hidden;
`;

const PointUnitBlockS = styled.div`
  width: 22%;

  @media (min-width: 600px) {
    width: 33%;
  }

  @media (min-width: 1025px) {
    width: 25%;
  }
`;

const UnitS = styled.p`
  font-size: 1.4rem;
  color: #ffeb00;
  word-break: break-all;
  margin-left: 1rem;
  white-space: no-wrap;
  width: auto;
  flex: none;
  width: max-content;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const EqualBlockS = styled.div`
  // flex
  display: flex;

  grid-row: 2/3;
  grid-column: 1/2;

  @media (min-width: 600px) {
    grid-row: unset;
    grid-column: unset;
  }
`;

const ConvertBlockS = styled.div`
  // grid
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;

  grid-row: 2/3;
  grid-column: 2/6;

  @media (min-width: 600px) {
    grid-row: unset;
    grid-column: unset;
  }
`;

const AnnounceS = styled.div`
  width: 90%;
`;

const TextS = styled.p`
  font-size: 1.4rem;
  word-break: break-all;
  line-height: 1.7;
  color: #ffffff;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const ButtonS = styled.div`
  width: 100%;
  margin-top: 5rem;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const RoundButtonWrapS = styled.div`
  width: 200px;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 220px;
    font-size: 1.6rem;
  }
`;
