const prefix = 'DayLott\'s - ';

export const thSeo = {
  title: {
    // Global pages
    top: `${prefix}สูงสุด`,
    emailSend: `${prefix}ส่งอีเมล์เสร็จเรียบร้อย`,
    emailVerified: `${prefix}การตรวจสอบอีเมล`,
    whats: `${prefix}Day Lott คืออะไร ?`,
    system: `${prefix}ระบบ`,
    faq: `${prefix}คำถามที่พบบ่อย`,
    ranking: `${prefix}อันดับ`,
    support: `${prefix}สนับสนุน`,
    responsibleGambling: `${prefix}นโยบายการพนันอย่างมีความรับผิดชอบ`,
    newsShow: `${prefix}ข่าว`,
    contact: `${prefix}ติดต่อเรา`,
    contactConfirmation: `${prefix}การยืนยันเนื้อหาการติดต่อ`,
    contactCompleted: `${prefix}ติดต่อเสร็จสิ้น`,
    passwordReset: `${prefix}รีเซ็ตรหัสผ่าน`,
    passwordResetForm: `${prefix}แบบฟอร์มรีเซ็ตรหัสผ่าน`,
    passwordResetCompleted: `${prefix}รีเซ็ตรหัสผ่านเรียบร้อยแล้ว`,
    // Guest pages
    register: `${prefix}สร้างบัญชี`,
    registerConfirmation: `${prefix}สร้างบัญชี`,
    login: `${prefix}เข้าสู่ระบบ`,
    accountDeleteCompleted: `${prefix}ระงับบัญชีเสร็จสิ้น`,
    // Private pages
    mypage: `${prefix}หน้าของฉัน`,
    accountDeleteConfirmation: `${prefix}ระงับบัญชี`,
    kycUpload: `${prefix}KYC อัพโหลด`,
    kycConfirmation: `${prefix}การยืนยัน KYC`,
    kycCompleted: `${prefix}KYC เสร็จสมบูรณ์`,
    emailChange: `${prefix}เปลี่ยนที่อยู่อีเมล`,
    pointPurchase: `${prefix}การซื้อคะแนน`,
    pointPurchaseCc: `${prefix}ฝากเงินด้วยสกุลเงินดิจิตอล`,
    pointPurchaseCredit: `${prefix}ฝากเงินด้วยบัตรเครดิต`,
    pointPurchaseCash: `${prefix}ฝากเงินด้วยเงินสด`,
    pointPurchaseCompleted: `${prefix}ส่งข้อมูลการฝากเงินเรียบร้อยแล้ว`,
    userNewsIndex: `${prefix}รายการข่าว`,
    userNewsShow: `${prefix}ข่าวสาร`,
    userNewsSkip: `${prefix}รับคะแนนสะสม`,
    lottery: `${prefix}หวย`,
    ticketPurchaseConfirmation: `${prefix}ตรวจสอบข้อมูลการซื้อ`,
    ticketPurchaseCompleted: `${prefix}การซื้อตั๋วเสร็จสมบูรณ์`,
    withdrawalTop: `${prefix}การฝาก ถอน การแลกเปลี่ยน`,
    withdrawalCc: `${prefix}ขั้นตอนการส่งเงิน`,
    withdrawalFinish: `${prefix}คุณได้ขอถอนเงิน/แลกคะแนนเรียบร้อยแล้ว`,
  },
  description: {
    // Global pages
    top: 'DayLott\'s เป็นเกมลอตเตอรีออนไลน์เกมแรกของโลกที่ปล่อยเศรษฐีทุกวัน',
    emailSend: `${prefix}หน้าเสร็จสิ้นการส่งอีเมล`,
    emailVerified: `${prefix}หน้ายืนยันอีเมล`,
    whats: 'DayLott\'s เป็นเกมลอตเตอรีออนไลน์เกมแรกของโลกที่ปล่อยเศรษฐีทุกวัน',
    system: 'เกมลอตเตอรีออนไลน์ที่มีโอกาสชนะ 75% ที่น่าทึ่ง ตารางจะถูกจัดเตรียมตามจำนวนเงินเดิมพันที่จะป้อนและจำนวนเงินที่ชนะ กฎเกณฑ์เป็นระบบง่ายๆ ที่คุณเพียงแค่ซื้อตั๋วและเข้าชม',
    faq: 'นี่คือหน้าคำถามที่พบบ่อยเกี่ยวกับ DayLott\'s',
    ranking: 'นี่คือหน้าการจัดอันดับผู้ชนะของ DayLott\'s',
    support: 'นี่คือหน้าสนับสนุนสำหรับคำถามที่พบบ่อยเกี่ยวกับ DayLott\'s การสอบถามทางอีเมล เวลาสอบถาม ฯลฯ',
    responsibleGambling: 'เรามุ่งมั่นที่จะมอบประสบการณ์การพนันที่สนุกสนานที่สุดให้กับคุณ แต่เรายังตระหนักดีว่าการพนันอาจทำให้เกิดปัญหากับบางคนได้ เพื่อให้แน่ใจว่าคุณสามารถเพลิดเพลินกับการเล่นที่ปลอดภัยและจัดการได้ต่อไป เราสนับสนุนการพนันอย่างมีความรับผิดชอบ และได้กำหนดมาตรการหลายอย่างเพื่อช่วยเหลือคุณ',
    newsShow: `${prefix}หน้าข่าว.`,
    contact: 'นี่คือหน้าติดต่อของ DayLott\' เรายอมรับการติดต่อจากลูกค้าโดยใช้แบบฟอร์มการติดต่อ',
    contactConfirmation: `${prefix}หน้ายืนยันการติดต่อ`,
    contactCompleted: `${prefix}ติดต่อหน้างาน.`,
    passwordReset: `${prefix}หน้ารีเซ็ตรหัสผ่าน`,
    passwordResetForm: `${prefix}แบบฟอร์มการรีเซ็ตรหัสผ่าน`,
    passwordResetCompleted: `${prefix}หน้ารีเซ็ตรหัสผ่านเสร็จสมบูรณ์`,
    // Guest pages
    register: 'ลงทะเบียนเป็นสมาชิกของ DayLott\'s และสะสมคะแนน! ซื้อลอตเตอรีและเข้าร่วมเกม!',
    registerConfirmation: `${prefix}ยืนยันข้อมูลที่กรอก`,
    login: 'โปรดป้อนที่อยู่อีเมลและรหัสผ่านที่ลงทะเบียนใน DayLott\'s เพื่อเข้าสู่ระบบ หากคุณใช้งานเป็นครั้งแรก โปรดลงทะเบียนเป็นสมาชิก',
    accountDeleteCompleted: `${prefix}หน้าระงับบัญชีเสร็จสมบูรณ์`,
    // Private pages
    mypage: 'นี่คือหน้าของฉันสำหรับผู้ใช้ DayLott\'s',
    accountDeleteConfirmation: `${prefix}หน้าระงับบัญชี`,
    kycUpload: `${prefix}หน้าอัปโหลด KYC`,
    kycConfirmation: `${prefix}หน้ายืนยัน KYC`,
    kycCompleted: `${prefix}KYC หน้าเสร็จสมบูรณ์`,
    emailChange: `${prefix}หน้าเปลี่ยนที่อยู่อีเมล์`,
    pointPurchase: `${prefix}หน้าการซื้อคะแนน`,
    pointPurchaseCc: `${prefix}หน้าสำหรับการฝากเงินด้วยสกุลเงินดิจิตอล`,
    pointPurchaseCredit: `${prefix}หน้าสำหรับการฝากเงินด้วยเครดิต`,
    pointPurchaseCash: `${prefix}หน้าสำหรับการฝากด้วยเงินสด`,
    pointPurchaseCompleted: `${prefix}ส่งข้อมูลการฝากเงินแล้ว`,
    userNewsIndex: `${prefix}หน้าสำหรับรายการข่าว`,
    userNewsShow: `${prefix}หน้าข่าว.`,
    userNewsSkip: `${prefix}รับคะแนนสะสมหน้า`,
    lottery: `${prefix}หน้าหวย.`,
    ticketPurchaseConfirmation: `${prefix}หน้ายืนยันการซื้อตั๋ว`,
    ticketPurchaseCompleted: `${prefix}หน้าซื้อตั๋วเสร็จ`,
    withdrawalTop: `${prefix}จุดฝากถอนหน้าแลกเปลี่ยน`,
    withdrawalCc: `${prefix}หน้าขั้นตอนการโอนเงิน`,
    withdrawalFinish: `${prefix}คุณได้ขอถอนเงิน/แลกคะแนนเรียบร้อยแล้ว`,
  },
};
