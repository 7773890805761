// eslint-disable-next-line
import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Utils
import { devices } from 'utils/const/devices';
// Types
import { PlayBoxType } from 'types/playBoxType';
// Images
import reserveBgSp from 'assets/images/backGround/acquisition-points_sp.jpg';
import reserveBgPc from 'assets/images/top/playBox/reserve_pc.jpg';
import reserveBgPc2x from 'assets/images/top/playBox/reserve_pc@2x.jpg';

const {
  tabletPortrait, tabletLandscape, pc,
} = devices;

/**
 *
 * Component
 *
 */
type Props = {
  playBox: PlayBoxType;
};

// 予約状況用コンポーネント
const TableReserve: FC<Props> = memo((props) => {
  // i18next
  const { t } = useTranslation();

  // playBox
  const { playBox } = props;

  return (
    <ReserveS>
      <TableReserveTextWrapS>
        <TableReserveLabelWrapS>
          <TableReserveLabelS>{t('予約状況')}</TableReserveLabelS>
        </TableReserveLabelWrapS>
        <TableReserveStatusS>
          <NumeratorS>
            {playBox.lot_tables[0]?.lot_bets_count.toLocaleString()}
          </NumeratorS>
          <DenominatorS>
            {`/ ${Number(playBox.recruitment).toLocaleString()}
              ${t('口')}`}
          </DenominatorS>
        </TableReserveStatusS>
      </TableReserveTextWrapS>
    </ReserveS>
  );
});
export default TableReserve;

/**
 *
 * Styles
 *
 */
const ReserveS = styled.div`
  padding: 1rem;
  border: 1px solid #ffffff;
  background-image: url(${reserveBgSp});
  background-size: cover;
  background-position: top;

  @media ${tabletLandscape}, ${pc} {
    display: flex;
    background-image: image-set(
      url(${reserveBgPc}) 1x,
      url(${reserveBgPc2x}) 2x
    );
    background-position: center;
    background-repeat: no-repeat;
    min-width: 210px;
  }
`;

const TextS = styled.p`
  font-size: 1.4rem;
  word-break: break-word;
  line-height: 1.6;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const TableReserveTextWrapS = styled.div`
  width: 100%;
  font-weight: bold;
  display: flex;
  align-items: center;

  @media ${tabletLandscape}, ${pc} {
    justify-content: center;
    flex-direction: column;
  }
`;

const TableReserveLabelWrapS = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableReserveLabelS = styled(TextS)`
  font-size: 1.4rem;

  @media ${tabletPortrait} {
    font-size: 1.8rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2rem;
    text-align: center;
  }
`;

const TableReserveStatusS = styled.div`
  width: 100%;
  color: #fff240;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;

const NumeratorS = styled(TextS)`
  font-size: 1.4rem;

  @media ${tabletPortrait} {
    font-size: 1.8rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2rem;
  }
`;

const DenominatorS = styled(TextS)`
  font-size: 1.4rem;

  @media ${tabletPortrait} {
    font-size: 1.8rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2rem;
  }
`;
