import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type Props = {
  backgroundColor?: string;
  children: ReactNode;
};

/**
 *
 * Component
 *
 */
const CommonModal: FC<Props> = memo((props) => {
  const { backgroundColor = '#000000', children } = props;

  return (
    <CommonModalS backgroundColor={backgroundColor}>{children}</CommonModalS>
  );
});
export default CommonModal;

/**
 *
 * Styles
 *
 */
const CommonModalS = styled.div<{ backgroundColor: string }>`
  width: 100%;
  height: 100vh;
  padding: 2rem;
  overflow-y: scroll;
  overscroll-behavior: contain;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  &::before,
  &::after {
    content: "",
    width: 1px;
    height: calc(100vh + 1px);
    display: flex;
  }
`;
