import { FC, memo } from 'react';
// External library
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Component
import NeonShadowBorder from 'components/atoms/border/NeonShadowBorder';
import RoundButton from 'components/atoms/button/RoundButton';
import ArrowIcon from 'components/atoms/icon/ArrowIcon';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
import LoaderPart from 'components/atoms/loader/LoaderPart';
// Types
import { PaginationType } from 'types/api';
import { HistoryDataType } from 'types/history';
// Utils
import { HISTORY_KIND } from 'utils/const/historyKind';
import { devices } from 'utils/const/devices';
// Images
import historyListBgPc from 'assets/images/mypage/bg-history-list_pc.png';
import historyListBgPc2x from 'assets/images/mypage/bg-history-list_pc@2x.png';

const { DEPOSIT, WITHDRAW, BET } = HISTORY_KIND;
const {
  tabletPortrait,
  tabletLandscape,
  pc,
} = devices;

/**
 *
 * Types
 *
 */
// ペジネーションタイプ
type Pagination = Pick<
  PaginationType,
  'next_page_url' | 'last' | 'per_page' | 'total'
>;
// リストタイプ
type ListType = 'deposit' | 'history';

type HistoryKind = typeof HISTORY_KIND[keyof typeof HISTORY_KIND];

type Props = {
  kind: HistoryKind;
  isLoading: boolean;
  listType: ListType;
  title: string;
  history: HistoryDataType[];
  pagination?: Pagination;
  getApiMethod?: () => void;
  isShowHistory?: boolean;
  onClickShowHistory?: () => void;
  isEmpty: boolean;
  errorMessage: string;
};

/**
 *
 * Component
 *
 */
const History: FC<Props> = memo((props) => {
  // i18next
  const { t } = useTranslation();

  const {
    kind,
    isLoading,
    listType,
    title,
    history,
    pagination,
    getApiMethod,
    isShowHistory,
    onClickShowHistory,
    isEmpty,
    errorMessage,
  } = props;

  return (
    <HistoryS>
      <TitleS type="button" onClick={onClickShowHistory}>
        <BlockS>
          <TitleTextWrapS>
            <TitleTextS>{t(title)}</TitleTextS>
          </TitleTextWrapS>
          <HeaderButtonWrapS listType={listType}>
            <HeaderButtonS>
              <ArrowIcon rotate={isShowHistory ? '225deg' : '45deg'} />
            </HeaderButtonS>
          </HeaderButtonWrapS>
        </BlockS>
        <BorderPcS>
          <NeonShadowBorder />
        </BorderPcS>
        <BorderSpS />
      </TitleS>
      <ContainerS
        className={`${isShowHistory ? '' : ' hide'}`}
        listType={listType}
      >
        <ListWrapS listType={listType}>
          {history[0]?.id !== 0 && (
            <ul>
              {history.map((item) => (
                <ListS key={item.id}>
                  <HistoryBlockS>
                    <HistoryTextWrapS>
                      <DatetimeWrapS>
                        <TextS>{item.datetime}</TextS>
                      </DatetimeWrapS>
                      <CenterTextWrapS>
                        <TextS>{t(item.centerText)}</TextS>
                      </CenterTextWrapS>
                      {kind === WITHDRAW && (
                        <RightTextWrapS>
                          {item.txHash && (
                            <TextS>
                              <LinkS
                                href={`https://etherscan.io/token/${item.txHash}`}
                                target="_blank"
                              >
                                {item.txHash}
                              </LinkS>
                            </TextS>
                          )}
                          {!item.txHash && (
                            <TextS>-</TextS>
                          )}
                        </RightTextWrapS>
                      )}
                    </HistoryTextWrapS>
                    <PointWrapS>
                      <PointS>
                        {Number(item.point).toLocaleString()}
                        <span>&nbsp;P</span>
                      </PointS>
                    </PointWrapS>
                  </HistoryBlockS>
                </ListS>
              ))}
            </ul>
          )}

          {/* ローディングアニメーション */}
          {isLoading && (
            <LoaderWrapS>
              <LoaderPart />
            </LoaderWrapS>
          )}

          {/* 0件取得時メッセージ */}
          {isEmpty && (
            <MessageWrapS>
              {kind === DEPOSIT ? (
                <p>{t('ポイント購入履歴はありません。')}</p>
              ) : kind === WITHDRAW ? (
                <p>{t('ポイント出金・交換履歴はありません。')}</p>
              ) : kind === BET ? (
                <p>{t('べット履歴はありません。')}</p>
              ) : (
                <p>{t('当選履歴はありません。')}</p>
              )}
            </MessageWrapS>
          )}

          {/* エラーメッセージ */}
          {errorMessage && (
            <MessageWrapS>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </MessageWrapS>
          )}
        </ListWrapS>

        {/* もっと見るボタン */}
        {pagination && pagination?.total > pagination?.per_page && (
          <ButtonWrapS>
            {isLoading ? (
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {!pagination.last ? t('もっと見る') : t('表示を閉じる')}
              </RoundButton>
            ) : (
              <RoundButton
                onClick={getApiMethod}
                background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                borderColor="rgba(0,135,167,1)"
              >
                {!pagination.last ? t('もっと見る') : t('表示を閉じる')}
              </RoundButton>
            )}
          </ButtonWrapS>
        )}
      </ContainerS>
    </HistoryS>
  );
});
export default History;

/**
 *
 * Styles
 *
 */
const HistoryS = styled.div`
  width: 100%;
`;

const TitleS = styled.button`
  width: 100%;
  margin-bottom: 1.5rem;

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 3rem;
  }
`;

const BlockS = styled.div`
  margin-bottom: 1rem;
  // flex
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 1.4rem;
  }
`;

const TitleTextWrapS = styled.div`
  width: 80%;

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
  }
`;

const HeaderButtonWrapS = styled.div<{ listType: ListType }>`
  padding-left: 2rem;
  width: 15%;

  // flex
  display: ${({ listType }) => (listType === 'history' ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  @media ${tabletPortrait} {
    width: 10%;
  }

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;

const HeaderButtonS = styled.div`
  width: 1.5rem;
  height: 1.5rem;

  // flex
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleTextS = styled.p`
  font-size: 1.4rem;

  @media ${tabletLandscape}, ${pc} {
    font-size: 2.4rem;
  }
`;

const BorderPcS = styled.div`
  display: none;

  @media ${tabletLandscape}, ${pc} {
    display: block;
    width: 100%;
  }
`;

const BorderSpS = styled.hr`
  display: block;

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;

const ContainerS = styled.div<{ listType: ListType }>`
  padding-bottom: 0;
  width: 100%;

  // flex
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &.hide {
    display: none;
    display: ${({ listType }) => (listType === 'history' ? 'none' : 'flex')};
    transition: all 0.5s ease;

    @media ${tabletLandscape}, ${pc} {
      display: flex;
    }
  }
`;

const ListWrapS = styled.div<{ listType: ListType }>`
  width: 100%;

  @media ${tabletLandscape}, ${pc} {
    background: ${({ listType }) => (listType === 'history'
    ? `image-set(
          url(${historyListBgPc}) 1x,
          url(${historyListBgPc2x}) 2x
         );`
    : 'rgba(0, 0, 0, 0.4)')};
    background-size: cover;
    background-position: center;
  }
`;

const ListS = styled.li`
  margin-bottom: 0.5rem;
  background: rgba(0, 0, 0, 0.4);

  &:last-child {
    margin-bottom: 0;
  }
`;

const HistoryBlockS = styled.div`
  padding: 1rem 1rem;

  // flex
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${tabletLandscape}, ${pc} {
    padding: 1rem 5rem;
  }
`;

const HistoryTextWrapS = styled.div`
  width: 75%;

  // flex
  display: flex;
  flex-direction: column;

  @media ${tabletLandscape}, ${pc} {
    flex-direction: unset;
    justify-content: space-between;
  }
`;

const DatetimeWrapS = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 0;
  }
`;

const CenterTextWrapS = styled.div`
  font-size: 1.4rem;

  @media ${tabletLandscape}, ${pc} {
    flex: 1;
    padding-left: 5rem;
  }
`;

const RightTextWrapS = styled.div`
  font-size: 1.2rem;
  text-align: left;
  max-width: 100%;
  
  @media ${tabletLandscape}, ${pc} {
    text-align: center;
    max-width: 55%;
    padding-left: 5rem;
    flex: 3;
  } 
`;

const PointWrapS = styled.div`
  color: #ffff00;
`;

const TextS = styled.p`
  font-size: inherit;
  word-break: break-word;

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const LinkS = styled.a`
`;

const PointS = styled.span`
  font-size: 1.4rem;

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const LoaderWrapS = styled.div`
  width: 100%;
  height: calc(clamp(30px, 15vw, 60px) + 4rem);
  position: relative;

  @media ${tabletPortrait} {
    height: calc(clamp(30px, 10vw, 60px) + 4rem);
  }

  @media ${tabletLandscape}, ${pc} {
    height: 20rem;
  }
`;

const MessageWrapS = styled.div`
  padding: 5rem;
  color: #ffffff;
  line-height: 1.5;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  font-size: min(6vw, 1.4rem);

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;

    width: 100%;
    height: 100%;
    // flex
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonWrapS = styled.div`
  width: min(50%, 140px);
  margin-top: 1.5rem;
  font-size: 1.4rem;

  @media ${tabletLandscape}, ${pc} {
    width: 20%;
    font-size: 1.6rem;
  }
`;
