import {
  useRef, useState, Dispatch, SetStateAction, RefObject,
} from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
import { useClear } from 'hooks/versatile/useClear';
import { LocationState } from 'hooks/pages/private/useWithdrawalTop';
// Utils
import { axiosClient, axiosCancelToken, getAuthorizationHeader } from 'utils/axios';
import { HISTORY_KIND } from 'utils/const/historyKind';
import { GRANT } from 'utils/const/grant';
// Types
import {
  ApiErrorType,
  PointLogType,
  ExchangeLogType,
  LotBetsType,
  LotWinType,
  PaginationType,
  UsersWithdrawal,
} from 'types/api';
import { HistoryDataType } from 'types/history';
import { GrantType } from 'types/grantType';

/**
 *
 * Types
 *
 */
type Menu = 'user' | 'kyc' | 'point' | 'history';

type MenuList = {
  label: string;
  ref: RefObject<HTMLLIElement>;
  isActive: boolean;
  onClick: () => void;
}[];

type HistoryKind = typeof HISTORY_KIND[keyof typeof HISTORY_KIND];

type DepositList = {
  kind: HistoryKind;
  isLoading: boolean;
  title: string;
  history: HistoryDataType[];
  pagination: Pagination;
  getApiMethod: () => void;
  isEmpty: boolean;
  errorMessage: string;
}[];

type Pagination = Pick<
  PaginationType,
  'next_page_url' | 'last' | 'per_page' | 'total'
>;

type HistoryList = {
  kind: HistoryKind;
  isLoading: boolean;
  title: string;
  history: HistoryDataType[];
  pagination: Pagination;
  getApiMethod: () => void;
  isShowHistory: boolean;
  onClickShowHistory: () => void;
  isEmpty: boolean;
  errorMessage: string;
}[];

type UseMypage = () => {
  errorMessage: string;
  isLoading: boolean;
  // 出金制御
  kycFlag: boolean;
  remainingTickets: number;
  getApiUsersWithdrawal: () => void;
  goToWithdrawalTopPage: () => void;
  // メニュー
  initialMenu: Menu;
  isMenuActive: Menu;
  setIsMenuActive: Dispatch<SetStateAction<Menu>>;
  menuList: MenuList;
  scrollIntoMenuView: (menu: Menu) => void;
  // ポイント・デポジット
  depositList: DepositList;
  // ポイント購入プレイ履歴
  getApiPointLogGet: () => void;
  // 出金履歴
  getApiPointLogExchange: () => void;
  // べット履歴
  getApiLotBets: () => void;
  // 当選履歴
  getApiLotWinHistory: () => void;
  historyList: HistoryList;
  // ログアウト
  getApiLogout: () => void;
  // 退会
  goToDeleteAccountConfirm: () => void;
  // クリーンアップ処理
  cleanup: () => void;
};

const initialPagination: Pagination = {
  next_page_url: '',
  last: false,
  per_page: 0,
  total: 0,
};

/**
 *
 * Hooks
 *
 */
export const useMypage: UseMypage = () => {
  const history = useHistory();

  // メニュー表示状態
  const location = useLocation<{
    initialMenu: Menu;
  }>();
  // 出金制御
  const [kycFlag, setKycFlag] = useState(false);
  const [remainingTickets, setRemainingTickets] = useState(0);
  const usersWithdrawalSource = axiosCancelToken.source();
  const getApiUsersWithdrawal = () => {
    axiosClient
      .get<UsersWithdrawal>('/api/users/withdrawal', {
        cancelToken: usersWithdrawalSource.token,
        headers: { Authorization: getAuthorizationHeader() },
      })
      .then(({ data }) => {
        setKycFlag(data.kycFlg);
        setRemainingTickets(data.remainingTickets);
      })
      .catch((error: AxiosError<ApiErrorType>) => {
        const result = errorHandler(error.response);
        if (result) setErrorMessage(result);
      });
  };
  const locationState: LocationState = {
    referrer: '/mypage',
  };
  const goToWithdrawalTopPage = () => {
    history.push({
      pathname: '/withdrawal',
      state: locationState,
    });
  };

  const { initialMenu } = location.state ?? { initialMenu: 'history' };
  const [isMenuActive, setIsMenuActive] = useState<Menu>(initialMenu);
  // メニューRef
  const userRef = useRef<HTMLLIElement>(null);
  const kycRef = useRef<HTMLLIElement>(null);
  const pointRef = useRef<HTMLLIElement>(null);
  const historyRef = useRef<HTMLLIElement>(null);
  // 初期表示時に対象のメニューへスクロール
  const scrollIntoMenuView = (menu: Menu) => {
    switch (menu) {
      case 'user':
        userRef.current?.scrollIntoView();
        break;
      case 'kyc':
        kycRef.current?.scrollIntoView();
        break;
      case 'point':
        pointRef.current?.scrollIntoView();
        break;
      case 'history':
        historyRef.current?.scrollIntoView();
        break;
      default:
        userRef.current?.scrollIntoView();
    }
  };
  const menuList: MenuList = [
    // {
    //   label: '登録情報',
    //   ref: userRef,
    //   isActive: isMenuActive === 'user',
    //   onClick: () => {
    //     setIsMenuActive('user');
    //   },
    // },
    // {
    //   label: 'KYC提出',
    //   ref: kycRef,
    //   isActive: isMenuActive === 'kyc',
    //   onClick: () => {
    //     setIsMenuActive('kyc');
    //   },
    // },
    // {
    //   label: 'ポイントデポジット出金・交換',
    //   ref: pointRef,
    //   isActive: isMenuActive === 'point',
    //   onClick: () => {
    //     setIsMenuActive('point');
    //   },
    // },
    {
      label: 'プレイ履歴',
      ref: historyRef,
      isActive: isMenuActive === 'history',
      onClick: () => {
        setIsMenuActive('history');
      },
    },
  ];

  // エラー用カスタムフック
  const { errorHandler } = useError();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   *
   * ログアウト
   *
   */
  const { clearUserState } = useClear();
  const logoutSource = axiosCancelToken.source();
  const getApiLogout = () => {
    if (!isLoading) {
      setIsLoading(true);

      // axios POST /api/logout
      axiosClient
        .post<boolean>('/api/logout', {
          cancenToken: logoutSource.token,
        })
        .then(({ data }) => {
          if (data) clearUserState();
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);
          if (result) setErrorMessage(result);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   *
   * 退会
   *
   */
  const goToDeleteAccountConfirm = () => {
    history.push({
      pathname: '/act/dle/confirm',
      state: {
        referrer: '/mypage',
      },
    });
  };

  /**
   *
   * ポイント購入履歴
   *
   */
  const [isPointLogLoading, setIsPointLogLoading] = useState<boolean>(false);
  const [pointLog, setPointLog] = useState<HistoryDataType[]>([]);
  const [pointLogIsEmpty, setPointLogIsEmpty] = useState<boolean>(true);
  const [pointLogErrorMessage, setPointLogErrorMessage] = useState<string>('');
  const [pointLogPagination, setPointLogPagination] = useState<Pagination>(initialPagination);

  const {
    REGISTRATION_BONUS,
    PAYMENT_BONUS,
    INTRODUCTION_BONUS,
    CREDIT_PAYMENT,
    BTC_PAYMENT,
    PAYMENT_TRANSFER,
    USDT_PAYMENT,
    ETH_PAYMENT,
  } = GRANT;

  const getGrantText = (type: GrantType) => {
    switch (type) {
      case REGISTRATION_BONUS:
        return '初回登録ボーナス';
      case PAYMENT_BONUS:
        return '入金ボーナス';
      case INTRODUCTION_BONUS:
        return '紹介ボーナス';
      case CREDIT_PAYMENT:
        return 'クレジット購入';
      case BTC_PAYMENT:
        return 'ビットコイン購入';
      case PAYMENT_TRANSFER:
        return '振り込み購入';
      case USDT_PAYMENT:
        return 'テザー購入';
      case ETH_PAYMENT:
        return 'イーサリアム購入';
      default:
        return '';
    }
  };

  const pointLogGetSource = axiosCancelToken.source();
  const getApiPointLogGet = () => {
    if (!isPointLogLoading) {
      setIsPointLogLoading(true);
      setPointLogIsEmpty(false);
      setPointLogErrorMessage('');

      // axios GET /api/point/log/get
      axiosClient
        .get<{
          log_points: PointLogType[];
          pagination: PaginationType;
        }>(`/api/point/log/get${pointLogPagination.next_page_url}`, {
          cancelToken: pointLogGetSource.token,
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then(({ data }) => {
          const { log_points, pagination } = data;

          if (log_points.length !== 0) {
            const getPointLog = log_points.map((item) => ({
              id: item.id,
              datetime: item.created_at,
              centerText: getGrantText(item.grant_type),
              txHash: null,
              point: item.point + item.free_point,
            }));

            if (pagination.current_page > 1) {
              setPointLog((_pointLog) => [..._pointLog, ...getPointLog]);
            } else {
              setPointLog([...getPointLog]);
            }

            // ページネーション更新
            setPointLogPagination({
              next_page_url: pagination.next_page_url ?? '',
              last: pagination.last,
              per_page: pagination.per_page,
              total: pagination.total,
            });
          } else {
            setPointLogIsEmpty(true);
          }
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            setPointLogErrorMessage(errorHandler(error.response));
          }
        })
        .finally(() => setIsPointLogLoading(false));
    }
  };

  /**
   *
   * ポイント出金・交換履歴
   *
   */
  const [pointWithdraw, setPointWithdraw] = useState<HistoryDataType[]>([]);
  const [
    pointWithdrawIsLoading,
    setPointWithdrawIsLoading,
  ] = useState<boolean>(false);
  const [
    pointWithdrawIsEmpty,
    setPointWithdrawIsEmpty,
  ] = useState<boolean>(false);
  const [
    pointWithdrawErrorMessage,
    setPointWithdrawErrorMessage,
  ] = useState<string>('');
  const [
    pointWithdrawPagination,
    setPointWithdrawPagination,
  ] = useState<Pagination>(initialPagination);

  const pointLogExchangeSource = axiosCancelToken.source();
  const getApiPointLogExchange = () => {
    if (!pointWithdrawIsLoading) {
      setPointWithdrawIsLoading(true);

      axiosClient
        .get<{
          transactions: ExchangeLogType[];
          pagination: PaginationType;
        }>('/api/point/log/exchange', {
          cancelToken: pointLogExchangeSource.token,
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then(({ data }) => {
          const { transactions, pagination } = data;
          if (transactions.length !== 0) {
            const res: HistoryDataType[] = transactions.map((v) => (
              {
                id: v.id,
                datetime: dayjs(v.created_at).format('YYYY/MM/DD mm:ss'),
                centerText: `${parseInt(v.amount_usdt, 10)} USDT`,
                txHash: v.tx_hash,
                point: parseInt(v.amount_usdt, 10),
              }
            ));

            if (pagination.current_page > 1) {
              setPointWithdraw((prevState) => [...prevState, ...res]);
            } else {
              setPointWithdraw([...res]);
            }

            setPointWithdrawPagination({
              next_page_url: pagination.next_page_url ?? '',
              last: pagination.last,
              per_page: pagination.per_page,
              total: pagination.total,
            });
          } else {
            setPointWithdrawIsEmpty(true);
          }
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            setPointLogErrorMessage(errorHandler(error.response));
          }
        })
        .finally(() => setPointWithdrawIsLoading(false));
    }
  };

  const {
    DEPOSIT, WITHDRAW, BET, WINNING,
  } = HISTORY_KIND;

  // デポジットページ履歴リスト
  const depositList: DepositList = [
    {
      kind: DEPOSIT,
      isLoading: isPointLogLoading,
      title: 'ポイント購入履歴',
      history: pointLog,
      pagination: pointLogPagination,
      getApiMethod: getApiPointLogGet,
      isEmpty: pointLogIsEmpty,
      errorMessage: pointLogErrorMessage,
    },
    {
      kind: WITHDRAW,
      isLoading: pointWithdrawIsLoading,
      title: 'ポイント出金・交換履歴',
      history: pointWithdraw,
      pagination: pointWithdrawPagination,
      getApiMethod: getApiPointLogExchange,
      isEmpty: pointWithdrawIsEmpty,
      errorMessage: pointWithdrawErrorMessage,
    },
  ];

  /**
   *
   * ベット履歴
   *
   */
  const [isShowBets, setIsShowBets] = useState<boolean>(false);
  const onClickShowBets = () => {
    setIsShowBets((_isShow) => !_isShow);
  };

  // べット履歴取得リクエスト
  const [isBetsLoading, setIsBetsLoading] = useState<boolean>(false);
  const [lotBetsIsEmpty, setLotBetsIsEmpty] = useState<boolean>(false);
  const [lotBetsErrorMessage, setLotBetsErrorMessage] = useState<string>('');
  const [lotBets, setLotBets] = useState<HistoryDataType[]>([]);
  const [lotBetsPagination, setLotBetsPagination] = useState<Pagination>(initialPagination);

  const lotBetsSource = axiosCancelToken.source();
  const getApiLotBets = () => {
    if (!isBetsLoading) {
      setIsBetsLoading(true);
      setLotBetsIsEmpty(false);
      setLotBetsErrorMessage('');

      // axios GET /api/lot/bets
      axiosClient
        .get<{
          lot_bets: LotBetsType[];
          pagination: PaginationType;
        }>(`/api/lot/bets${lotBetsPagination.next_page_url}`, {
          cancelToken: lotBetsSource.token,
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then(({ data }) => {
          const { lot_bets, pagination } = data;

          if (lot_bets.length !== 0) {
            const getLotBets = lot_bets.map((item) => ({
              id: item.id,
              datetime: item.created_at,
              centerText: item.lot_boxes.title,
              txHash: null,
              point: item.bet_point,
            }));

            if (pagination.current_page > 1) setLotBets((_lotBets) => [..._lotBets, ...getLotBets]);
            else setLotBets([...getLotBets]);

            // ページネーション更新
            setLotBetsPagination({
              next_page_url: pagination.next_page_url ?? '',
              last: pagination.last,
              per_page: pagination.per_page,
              total: pagination.total,
            });
          } else {
            setLotBetsIsEmpty(true);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);
          if (result) setLotBetsErrorMessage(result);
          setLotBets([]);
        })
        .finally(() => {
          setIsBetsLoading(false);
        });
    }
  };

  /**
   *
   * 当選履歴
   *
   */
  const [isShowWin, setIsShowWin] = useState<boolean>(false);
  const onClickShowWin = () => {
    setIsShowWin((_isShow) => !_isShow);
  };

  // 当選履歴取得リクエスト
  const [isWinLoading, setIsWinLoading] = useState<boolean>(false);
  const [lotWinIsEmpty, setLotWinIsEmpty] = useState<boolean>(false);
  const [lotWinErrorMessage, setLotWinErrorMessage] = useState<string>('');
  const [lotWin, setLotWin] = useState<HistoryDataType[]>([]);
  const [lotWinPagination, setLotWinPagination] = useState<Pagination>(initialPagination);

  const lotWinSource = axiosCancelToken.source();
  const getApiLotWinHistory = () => {
    if (!isWinLoading) {
      setIsWinLoading(true);
      setLotWinIsEmpty(false);
      setLotWinErrorMessage('');

      // axios GET /api/lot/win_history
      axiosClient
        .get<{
          lot_win: LotWinType[];
          pagination: PaginationType;
        }>(`/api/lot/win_history${lotWinPagination.next_page_url}`, {
          cancelToken: lotWinSource.token,
          headers: { Authorization: getAuthorizationHeader() },
        })
        .then(({ data }) => {
          const { lot_win, pagination } = data;

          if (lot_win.length !== 0) {
            const getLotWin = lot_win.map((item) => ({
              id: item.id,
              datetime: item.lottery_at,
              centerText: item.lot_boxes.title,
              txHash: null,
              point: item.win_point,
            }));

            if (pagination.current_page > 1) setLotWin((_lotWin) => [..._lotWin, ...getLotWin]);
            else setLotWin([...getLotWin]);

            // ページネーション更新
            setLotWinPagination({
              next_page_url: pagination.next_page_url ?? '',
              last: pagination.last,
              per_page: pagination.per_page,
              total: pagination.total,
            });
          } else {
            setLotWinIsEmpty(true);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);
          if (result) setLotWinErrorMessage(result);
          setLotWin([]);
        })
        .finally(() => {
          setIsWinLoading(false);
        });
    }
  };

  // 履歴リスト
  const historyList: HistoryList = [
    {
      kind: BET,
      isLoading: isBetsLoading,
      title: 'べット履歴',
      history: lotBets,
      pagination: lotBetsPagination,
      getApiMethod: getApiLotBets,
      isShowHistory: isShowBets,
      onClickShowHistory: onClickShowBets,
      isEmpty: lotBetsIsEmpty,
      errorMessage: lotBetsErrorMessage,
    },
    {
      kind: WINNING,
      isLoading: isWinLoading,
      title: '当選履歴',
      history: lotWin,
      pagination: lotWinPagination,
      getApiMethod: getApiLotWinHistory,
      isShowHistory: isShowWin,
      onClickShowHistory: onClickShowWin,
      isEmpty: lotWinIsEmpty,
      errorMessage: lotWinErrorMessage,
    },
  ];

  // クリーンアップ処理
  const cleanup = () => {
    // 出金制御
    setKycFlag(false);
    setRemainingTickets(0);
    usersWithdrawalSource.cancel();
    // ポイント購入履歴
    setPointLog([]);
    setPointLogIsEmpty(false);
    setPointLogErrorMessage('');
    setPointLogPagination(initialPagination);
    pointLogGetSource.cancel();
    // ポイント出金・交換履歴
    setPointWithdraw([]);
    setPointWithdrawIsLoading(false);
    setPointWithdrawIsEmpty(false);
    setPointWithdrawErrorMessage('');
    setPointWithdrawPagination(initialPagination);
    pointLogExchangeSource.cancel();
    // べット履歴
    setIsShowBets(false);
    setLotBets([]);
    setLotBetsPagination(initialPagination);
    lotBetsSource.cancel();
    // 当選履歴
    setIsShowWin(false);
    setLotWin([]);
    setIsWinLoading(false);
    setLotWinPagination(initialPagination);
    lotWinSource.cancel();
    // ログアウト
    logoutSource.cancel();
  };

  return {
    errorMessage,
    isLoading,
    // 出金制御
    kycFlag,
    remainingTickets,
    getApiUsersWithdrawal,
    goToWithdrawalTopPage,
    // メニュー
    initialMenu,
    isMenuActive,
    setIsMenuActive,
    menuList,
    scrollIntoMenuView,
    // ポイント・デポジット
    depositList,
    // ポイント購入履歴
    getApiPointLogGet,
    // 出金履歴
    getApiPointLogExchange,
    // べット履歴
    getApiLotBets,
    // 当選履歴
    getApiLotWinHistory,
    historyList,
    // ログアウト
    getApiLogout,
    // 退会
    goToDeleteAccountConfirm,
    // クリーンアップ処理
    cleanup,
  };
};
