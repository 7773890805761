import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';
// Images
import iconCamera from 'assets/images/kycupload/icon_camera.svg';

/**
 *
 * Types
 *
 */
type Props = {
  children: ReactNode;
  src: string;
  text: string;
};

/**
 *
 * Component
 *
 */
const FileFrameLabel: FC<Props> = memo((props) => {
  const { children, src, text } = props;

  return (
    <FileFrameLabelS>
      {/* <label> */}
      {children}

      {/* サムネイル */}
      {src ? (
        <ThumbnailS>
          <ImageS src={src} alt="" />
        </ThumbnailS>
      ) : (
        <>
          <IconS>
            <ImageS src={iconCamera} alt="" />
          </IconS>
          <TextPcS>{text}</TextPcS>
        </>
      )}
    </FileFrameLabelS>
  );
});
export default FileFrameLabel;

/**
 *
 * Styles
 *
 */
const FileFrameLabelS = styled.div`
  height: 100%;
  width: 100%;
  padding: 3rem;
  border: 1px dashed #ffffff;
  position: relative;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:hover {
    @media (min-width: 1025px) {
      opacity: 0.8;
    }
  }
`;

const ThumbnailS = styled.div`
  width: 40%;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconS = styled.div`
  width: 20%;

  @media (min-width: 600px) {
    width: 15%;
  }

  @media (min-width: 1025px) {
    display: none;
  }
`;

const ImageS = styled.img`
  width: 100%;
`;

const TextPcS = styled.p`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    font-size: 1.6rem;
  }
`;
