import { FC, memo } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type Props = {
  backgroundColor?: string;
};

/**
 *
 * Component
 *
 */
const CrossIcon: FC<Props> = memo(({ backgroundColor = '#ffffff' }) => (
  <CrossIconS backgroundColor={backgroundColor} />
));
export default CrossIcon;

/**
 *
 * Styles
 *
 */
const CrossIconS = styled.div<{ backgroundColor: string }>`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;
