import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
// Components
import FormLayout from 'components/templates/FormLayout';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import RoundButton from 'components/atoms/button/RoundButton';
import ColorSquare from 'components/atoms/frame/ColorFrame';
import EqualIcon from 'components/atoms/icon/EqualIcon';
import ColorBorderInput from 'components/atoms/input/ColorBorderInput';
// Custom hooks
import { usePointPurchaseCc } from 'hooks/pages/private/usePointPurchaseCc';
// Recoil
// Utils
import { pointPurchaseCcBreadCrumbItems as breadCrumbItems } from 'utils/breadCrumbs';
import { devices } from 'utils/const/devices';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';
import copyIcon from 'assets/images/purchaseCc/icon_copy.svg';

const {
  mobileLandscape,
} = devices;
const { tabletPortrait, tabletLandscape, pc } = devices;

/**
 *
 * Component
 *
 */
const PointPurchaseCc: FC = memo(() => {
  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    pointInputProps,
    selectedCurrency,
    displayedCc,
    address,
    copyAddress,
    goBack,
  } = usePointPurchaseCc();

  return (
    <>
      <Helmet>
        <title>{t('title.pointPurchaseCc')}</title>
        <meta name="description" content={t('description.pointPurchaseCc')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={breadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('仮想通貨で入金')}
        titleStyle="gold"
      >
        {/* ローディングアニメーション */}
        {isLoading && <LoaderWindow />}
        <FormS>
          <AttentionTextS>
            {t('レート表記はページにアクセスした時点のものです。入金時の数値とは異なる場合がございます。')}
          </AttentionTextS>
          {/* 変換レート表示部分 */}
          <PointRateBlockS>
            <PurchasePointBlockS>
              <PurchaseTextS>
                {t('金額を入れてください。')}
              </PurchaseTextS>
              <PointBlockS>
                <InputSquareBlockS>
                  {/* ユーザー入力要素 */}
                  <ColorBorderInput
                    color="#f502fd"
                    textAlign="end"
                    unit="$"
                    {...pointInputProps}
                  />
                </InputSquareBlockS>
                <PointUnitBlockS>
                  <UnitS>{t('ポイント')}</UnitS>
                </PointUnitBlockS>
              </PointBlockS>
              <HiddenPurchaseTextS>
                {t('金額を入れてください。')}
              </HiddenPurchaseTextS>
            </PurchasePointBlockS>

            <EqualBlockS>
              <EqualIcon />
            </EqualBlockS>

            <ConvertBlockS>
              {/* 仮想通貨 */}
              <CcBlockS>
                <HiddenNoticeTextS>
                  {t('日本円での合計金額(手数料込)')}
                </HiddenNoticeTextS>
                <JpyPriceWrapS>
                  <SquareBlockS>
                    <ColorSquare color="#f502fd" justifyContent="end">
                      <RateS>{displayedCc}</RateS>
                      <UnitS>{selectedCurrency}</UnitS>
                    </ColorSquare>
                  </SquareBlockS>
                </JpyPriceWrapS>
                <NoticeTextS>
                  {t('日本円での合計金額(手数料込)')}
                </NoticeTextS>
              </CcBlockS>
            </ConvertBlockS>
          </PointRateBlockS>

          <AddressS>
            <QRCodeSetS>
              <QRCodeFrameS>
                <QRCode size={100} value={address} />
              </QRCodeFrameS>
              <CopySetS>
                <LabelS>{t('送金アドレス')}</LabelS>
                <InputSetS>
                  <InputS>{address}</InputS>
                  <CopyButtonS type="button" onClick={copyAddress}>
                    <CopyIconS src={copyIcon} alt="" />
                  </CopyButtonS>
                </InputSetS>
              </CopySetS>
            </QRCodeSetS>
          </AddressS>

          <GoBackButtonWrapS>
            <RoundButton
              onClick={goBack}
              background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
              borderColor="rgba(0,135,167,1)"
            >
              {t('1つ前に戻る')}
            </RoundButton>
          </GoBackButtonWrapS>
        </FormS>
      </FormLayout>
    </>
  );
});

export default PointPurchaseCc;

/**
 *
 * Styles
 *
 */
const FormS = styled.div`
  color: #ffffff;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletLandscape}, ${pc} {
    width: 90%;
  }
`;

const AttentionTextS = styled.p`
  font-size: 1.6rem;
  line-height: 1.5;
  word-break: break-word;
  margin-bottom: 2rem;

  @media ${mobileLandscape} {
    font-size: 1.8rem;
  }

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
    width: 90%;
  }
`;

const PointRateBlockS = styled.div`
  // grid
  display: grid;
  row-gap: 2rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    grid-template-columns: 2fr 1fr 2fr;
    row-gap: unset;
  }
`;

const PurchasePointBlockS = styled.div`
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2rem;

  grid-row: 1/2;
  grid-column: 1/6;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    grid-row: unset;
    grid-column: unset;
  }
`;

const PurchaseTextS = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ffffff;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const HiddenPurchaseTextS = styled(PurchaseTextS)`
  color: transparent;
  visibility: hidden;
`;

const PointBlockS = styled.div`
  // flex
  display: flex;
  align-items: flex-end;
  gap: 1rem;
`;

const CcBlockS = styled(PointBlockS)`
  flex-direction: column;
  row-gap: 2rem;
  align-items: flex-start;
  align-self: start;
`;

const JpyPriceWrapS = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  column-gap: 1.2rem;
`;

const SquareBlockS = styled.div`
  width: 73%;
  font-size: 1.6rem;

  @media ${tabletPortrait} {
    width: 70%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 75%;
    font-size: 1.8rem;
  }
`;

const InputSquareBlockS = styled.div`
  font-size: 1.6rem;

  @media ${tabletPortrait} {
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const RateS = styled.p`
  text-align: end;
  color: #ffffff;
  word-break: break-all;
`;

const NoticeTextS = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ffffff;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const HiddenNoticeTextS = styled(NoticeTextS)`
  color: transparent;
  visibility: hidden;
`;

const PointUnitBlockS = styled.div`
  width: 22%;

  @media ${tabletPortrait} {
    width: 33%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 25%;
  }
`;

const UnitS = styled.p`
  font-size: 1.4rem;
  color: #ffeb00;
  word-break: break-all;
  margin-left: 1rem;
  white-space: no-wrap;
  width: auto;
  flex: none;
  width: max-content;

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const EqualBlockS = styled.div`
  // flex
  display: flex;

  grid-row: 2/3;
  grid-column: 1/2;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    grid-row: unset;
    grid-column: unset;
  }
`;

const ConvertBlockS = styled.div`
  // grid
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;

  grid-row: 2/3;
  grid-column: 2/6;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    grid-row: unset;
    grid-column: unset;
  }
`;

const AddressS = styled.div`
  width: 100%;
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    width: 90%;
  }
`;

const QRCodeSetS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.8rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    flex-direction: row;
    align-items: end;
  }
`;

const QRCodeFrameS = styled.div`
  width: 120px;
  height: 120px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CopySetS = styled.div`
  width: 100%;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    flex: 1;
  }
`;

const LabelS = styled.div`
  font-size: 1.4rem;
  margin-bottom: 1rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const InputSetS = styled.div`
  word-break: break-all;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const InputS = styled.div`
  width: 100%;
  min-height: 3rem;
  padding: 1.4rem;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #f502fd;
  border-radius: 5px;
  font-size: 1.4rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    min-height: 4rem;
    font-size: 1.6rem;
  }
`;

const CopyButtonS = styled.button`
  width: min(12%, 3rem);
`;

const CopyIconS = styled.img`
  width: 100%;
`;

const GoBackButtonWrapS = styled.div`
  width: 200px;
  margin-top: 5rem;
  font-size: 1.4rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    margin-top: 6rem;
    width: 220px;
    font-size: 1.6rem;
  }
`;
