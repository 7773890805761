import {
  useState,
  useEffect,
  useRef,
  RefObject,
} from 'react';
// External library
import { useHistory } from 'react-router-dom';
// Custom hooks
import { useValidate } from 'hooks/versatile/useValidate';
import { useOpenDocument } from 'hooks/versatile/useOpenDocument';

/**
 *
 * 新規会員登録ページ用ロジック
 *
 */
export const useRegister = (): {
  errorMessage: string;
  emailRef: RefObject<HTMLInputElement>;
  nameRef: RefObject<HTMLInputElement>;
  passwordRef: RefObject<HTMLInputElement>;
  confirmPasswordRef: RefObject<HTMLInputElement>;
  isCheckedTos: boolean;
  openTerms: () => void;
  checkTermsOfService: () => void;
  pushToRegisterConfirm: () => void;
} => {
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Eメール
  const emailRef = useRef<HTMLInputElement>(null);
  // ユーザー名
  const nameRef = useRef<HTMLInputElement>(null);
  // パスワード
  const passwordRef = useRef<HTMLInputElement>(null);
  // パスワード(確認用)
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  // 利用規約
  const { openTerms } = useOpenDocument();
  const [isCheckedTos, setIsCheckdTos] = useState(false);
  const checkTermsOfService = () => {
    setIsCheckdTos((_isCheckedTos) => !_isCheckedTos);
  };

  // historyオブジェクト
  const history = useHistory();
  // バリデーション用カスタムフック
  const {
    emailValidate,
    nameValidate,
    passwordValidate,
    confirmPasswordValidate,
  } = useValidate();
  // 確認画面に遷移
  const pushToRegisterConfirm = () => {
    // メールアドレスバリデーション
    let email = '';
    if (emailRef.current !== null) {
      const result = emailValidate(emailRef.current.value);
      if (result) {
        setErrorMessage(result);

        return;
      }

      email = emailRef.current.value;
    }
    // ユーザー名バリデーション
    let name = '';
    if (nameRef.current !== null) {
      const result = nameValidate(nameRef.current.value);
      if (result) {
        setErrorMessage(result);

        return;
      }

      name = nameRef.current.value;
    }
    // パスワードバリデーション
    let password = '';
    if (passwordRef.current !== null) {
      const result = passwordValidate(passwordRef.current.value);
      if (result) {
        setErrorMessage(result);

        return;
      }

      password = passwordRef.current.value;
    }
    // パスワード(確認)バリデーション
    let confirmPassword = '';
    if (passwordRef.current !== null && confirmPasswordRef.current !== null) {
      const result = confirmPasswordValidate(
        passwordRef.current.value,
        confirmPasswordRef.current.value,
      );
      if (result) {
        setErrorMessage(result);

        return;
      }

      confirmPassword = confirmPasswordRef.current.value;
    }

    history.push({
      pathname: '/register/confirm',
      state: {
        referrer: '/register',
        email,
        name,
        password,
        confirmPassword,
      },
    });
  };

  useEffect(() => {
    // スクロール位置リセット
    window.scrollTo({
      top: 0,
    });
  }, []);

  return {
    errorMessage,
    emailRef,
    nameRef,
    passwordRef,
    confirmPasswordRef,
    isCheckedTos,
    openTerms,
    checkTermsOfService,
    pushToRegisterConfirm,
  };
};
