import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
// Components
import BatchIcon from 'components/atoms/icon/BatchIcon';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
// Recoil
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { langState } from 'store/atoms/langState';
// Utils
import { devices } from 'utils/const/devices';
// Types
import { LangType } from 'types/lang';
import { UfcPage } from 'types/UfcPage';
import city from '../../../../assets/images/header/back_city_button_tx.png';

/**
 *
 * Component
 *
 */
type LangOption = {
  label: string;
  value: LangType;
};
type Props = {
  pushToNewsUsers: () => void;
  transitToUfc: (tabMenu: UfcPage | null) => void;
  pushToPlayHistory: () => void;
  isLoading: boolean;
  langOptions: LangOption[];
  changeLangMethod: (arg: LangType) => void;
};
const HeaderMenuSp: FC<Props> = memo((props) => {
  // Recoil ユーザー情報
  const user = useRecoilValue(userState);
  // Recoil 言語設定
  const lang = useRecoilValue(langState);

  // i18-next
  const { t } = useTranslation();

  const {
    pushToNewsUsers,
    transitToUfc,
    pushToPlayHistory,
    isLoading,
    langOptions,
    changeLangMethod,
  } = props;

  return (
    <HeaderMenuSpS>
      {isLoading && <LoaderWindow />}
      <NavS>
        <UListS>
          <ListS>
            <Link className="link-daylotts" to="/whats">
              <button type="button" onClick={pushToNewsUsers}>
                {t("Day Lott'sとは")}
              </button>
            </Link>
          </ListS>
          <ListS>
            <Link className="link-structure" to="/how">
              <button type="button" onClick={pushToNewsUsers}>
                {t('仕組み')}
              </button>
            </Link>
          </ListS>
          <ListS>
            <Link className="link-qa" to="/faq">
              <button type="button" onClick={pushToNewsUsers}>
                {t('よくある質問')}
              </button>
            </Link>
          </ListS>
          <ListS>
            <Link className="link-ranking" to="/ranking">
              <button type="button" onClick={pushToNewsUsers}>
                {t('ランキング')}
              </button>
            </Link>
          </ListS>
          <ListS>
            <Link className="link-support" to="/support">
              <button type="button" onClick={pushToNewsUsers}>
                {t('サポート')}
              </button>
            </Link>
          </ListS>
          <ListS>
            <Link className="link-support" to="/">
              <button type="button" onClick={() => transitToUfc(null)}>
                <CityIconS src={city} alt="" />
              </button>
            </Link>
          </ListS>
        </UListS>
      </NavS>
      {user.loggedIn && (
        <UserS>
          <UserNavS>
            <UserUListS>
              <UserListS>
                <button type="button" onClick={pushToNewsUsers}>
                  <UserNewsS>
                    <p>{t('お知らせ')}</p>
                    {user.unreadNewsUsersCount > 0 && (
                      <UserNewsBatchIconS>
                        <BatchIcon>{user.unreadNewsUsersCount}</BatchIcon>
                      </UserNewsBatchIconS>
                    )}
                  </UserNewsS>
                </button>
              </UserListS>
              <UserListS>
                <button type="button" onClick={() => transitToUfc('myaccount')}>
                  {t('登録情報')}
                </button>
              </UserListS>
              <UserListS>
                <button type="button" onClick={() => transitToUfc('kyc')}>
                  {t('KYC提出')}
                </button>
              </UserListS>
              <UserListS>
                <button type="button" onClick={() => transitToUfc('deposit')}>
                  {t('ポイントデポジット出金・交換')}
                </button>
              </UserListS>
              <UserListS>
                <button type="button" onClick={() => pushToPlayHistory()}>
                  {t('プレイ履歴')}
                </button>
              </UserListS>
            </UserUListS>
          </UserNavS>
        </UserS>
      )}
      {langOptions.length > 0 && (
        <LangS className={user.loggedIn ? '' : 'guest'}>
          <ContentS>
            <LabelS>{t('言語')}</LabelS>
            <LangBoxS>
              <BorderWrapS>
                <PinkBorderS />
              </BorderWrapS>
              <LangContentS>
                {langOptions.map((item) => (
                  <ButtonS
                    key={uuidv4()}
                    onClick={() => changeLangMethod(item.value)}
                  >
                    <UserLangButtonTextS
                      className={lang !== item.value ? 'unselected' : ''}
                    >
                      {item.label}
                    </UserLangButtonTextS>
                  </ButtonS>
                ))}
              </LangContentS>
              <BorderWrapS>
                <PinkBorderS />
              </BorderWrapS>
            </LangBoxS>
          </ContentS>
        </LangS>
      )}
    </HeaderMenuSpS>
  );
});
export default HeaderMenuSp;

/**
 *
 * Styles
 *
 */
const { tabletPortrait, tabletLandscape } = devices;

const HeaderMenuSpS = styled.div`
  width: 100%;
  height: 100%;
`;

const NavS = styled.nav`
  width: 100%;
`;

const UListS = styled.ul`
  width: 100%;
`;

const ListS = styled.li`
  font-size: 2rem;
  font-weight: 600;
  margin-top: 2rem;

  .link-daylotts {
    color: #ff17e4;
  }

  .link-structure {
    color: #d312dc;
  }

  .link-qa {
    color: #b10ed5;
  }

  .link-ranking {
    color: #8c0ace;
  }

  .link-support {
    color: #7f09cd;
  }
`;

const UserS = styled.div`
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserNavS = styled.nav`
  width: 100%;
`;

const UserUListS = styled.ul`
  width: 100%;
`;

const UserListS = styled.li`
  font-size: 1.4rem;
  color: #ffffff;
  margin-top: 1.5rem;

  &:first-child {
    margin-top: 0;
  }
`;

const UserNewsS = styled.div`
  display: flex;
  align-items: center;
`;

const UserNewsBatchIconS = styled.div`
  font-size: 1.2rem;
  color: #ffffff;
  height: 2rem;
  width: 2rem;
  margin-left: 0.8rem;
`;

const LangS = styled.div`
  width: 100%;
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LangBoxS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LangContentS = styled.div`
  width: 100%;
  background: #000000;
  padding: 0.8rem 0rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1rem;
  align-items: center;

  @media ${tabletPortrait}, ${tabletLandscape} {
    padding: 1rem 0;
    display: flex;
    row-gap: unset;
    justify-content: center;
  }
`;

const UserLangButtonTextS = styled.p`
  font-size: 1.4rem;
  color: #ffffff;

  &.unselected {
    color: #777777;
  }
`;

const ButtonS = styled.button`
  display: flex;
  justify-content: center;
  border-right: 1px solid #777777;
  padding: 0.6rem;

  &:last-child {
    border: none;
  }

  @media (max-width: 599px) {
    &:nth-child(3) {
      border: none;
    }
  }

  @media ${tabletPortrait}, ${tabletLandscape} {
    width: 100%;
    padding: 1rem;
  }
`;

const BorderWrapS = styled.div`
  width: 100%;
  position: relative;
`;

const PinkBorderS = styled.div`
  height: 1px;
  width: 100%;
  position: absolute;
  top: -2.5px;
  left: 0;
  background: linear-gradient(45deg, #ff17e4, #7f09cd);
`;

const LabelS = styled.div`
  width: 100%;
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 1.5rem;
`;

const CityIconS = styled.img`
  width: 100%;
`;
