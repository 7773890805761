import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Components
import ArrowIcon from 'components/atoms/icon/ArrowIcon';
// Hooks
import { useQuestion } from 'hooks/organisms/useQuestion';
// Images
import supportBoxPc from 'assets/images/neonItem/box_pc.png';
import supportBoxPc2x from 'assets/images/neonItem/box_pc@2x.png';
// Util
import { LANG } from 'utils/const/lang';
// Types
import { QuestionType } from 'types/faq';

const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN,
  VIETNAM, THAILAND,
} = LANG;

/**
 *
 * Types
 *
 */
type Props = {
  faq: QuestionType;
};

/**
 *
 * Component
 *
 */
const Question: FC<Props> = memo((props) => {
  const { faq } = props;

  // Recoil 言語設定
  const lang = useRecoilValue(langState);

  // Hooks
  const { isShowAnswer, onClickShowAnswer } = useQuestion();

  return (
    <TextBoxBlockS type="button" onClick={onClickShowAnswer}>
      <FaqWrapS>
        <QuestionS>
          <QuestionTextWrapS>
            <TextHeadS>
              Q .
              {lang === JAPANESE
                ? faq.question
                : lang === SIMPLE_CHINESE
                  ? faq.zh_cn_question
                  : lang === TRADITIONAL_CHINESE
                    ? faq.zh_tw_question
                    : lang === KOREAN
                      ? faq.ko_question
                      : lang === VIETNAM
                        ? faq.vi_question
                        : lang === THAILAND
                          ? faq.th_question
                          : faq.en_question}
            </TextHeadS>
          </QuestionTextWrapS>
          <ShowButtonWrapS>
            <ShowButtonS>
              {isShowAnswer ? (
                <ArrowIcon rotate="225deg" />
              ) : (
                <ArrowIcon rotate="45deg" />
              )}
            </ShowButtonS>
          </ShowButtonWrapS>
        </QuestionS>
        <AnswerS className={isShowAnswer ? 'show' : ''}>
          <TextS>
            <span dangerouslySetInnerHTML={{
              __html: lang === JAPANESE
                ? faq.answer
                : lang === SIMPLE_CHINESE
                  ? faq.zh_cn_answer
                  : lang === TRADITIONAL_CHINESE
                    ? faq.zh_tw_answer
                    : lang === KOREAN
                      ? faq.ko_answer
                      : lang === VIETNAM
                        ? faq.vi_answer
                        : lang === THAILAND
                          ? faq.th_answer
                          : faq.en_answer,
            }}
            />
          </TextS>
        </AnswerS>
      </FaqWrapS>
    </TextBoxBlockS>
  );
});
export default Question;

/**
 *
 * Styles
 *
 */
const TextBoxBlockS = styled.button`
  width: 100%;
  padding-top: 5%;
  padding-bottom: 4%;
  display: block;

  background: rgba(0, 0, 0, 0.2);
  border-left: 0.1px solid rgba(220, 220, 220, 0.3);
  border-right: 0.1px solid rgba(220, 220, 220, 0.3);

  @media (min-width: 600px) {
    width: 90%;
  }

  @media (min-width: 1025px) {
    width: 100%;
    background: transparent;
    border-left: none;
    border-right: none;

    border-image: url(${supportBoxPc}) 5 5 5 5 fill stretch;
    @media (min-resolution: 2dppx) {
      border-image: url(${supportBoxPc2x}) 5 5 5 5 fill stretch;
    }
    border-width: 5px;
    border-style: solid;
  }
`;

const FaqWrapS = styled.div`
  padding: 0rem 1.5rem;

  @media (min-width: 600px) {
    padding: 0rem 3rem;
  }

  @media (min-width: 1025px) {
    padding: 0rem 6rem;
  }
`;

const QuestionS = styled.div`
  // flex
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionTextWrapS = styled.div`
  width: 80%;
`;

const ShowButtonWrapS = styled.div`
  width: 15%;

  // flex
  display: flex;
  justify-content: end;
  align-items: center;
`;

const ShowButtonS = styled.div`
  width: 1.5rem;
  height: 1.5rem;

  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    width: 3rem;
    height: 3rem;
  }
`;

const TextHeadS = styled.p`
  font-size: 1.3rem;
  line-height: 1.6;

  @media (min-width: 600px) {
    font-size: 1.8rem;
  }

  @media (min-width: 1025px) {
    font-size: 2rem;
  }
`;

const AnswerS = styled.div`
  transition: all 0.3s ease;
  width: 100%;
  max-height: 0;
  overflow-y: hidden;

  &.show {
    margin-top: 3rem;
    transition: all 0.5s ease;
    max-height: 1000vh;
  }
`;

const TextS = styled.p`
  font-size: 1.4rem;
  font-weight: 200;
  white-space: pre-line;
  line-height: 1.7;

  @media (min-width: 600px) {
    font-size: 1.3rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;
