import { FC, ReactNode, memo } from 'react';
// External library
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type Props = {
  children?: ReactNode;
  backGroundSp: string;
  backGroundPc: string;
  backGroundPc2x: string;
  title: string;
  to: string;
  linkText: string;
};
type BackGround = Pick<
  Props,
  'backGroundSp' | 'backGroundPc' | 'backGroundPc2x'
>;

/**
 *
 * Component
 *
 */
const CompletionLayout: FC<Props> = memo((props) => {
  // i18next
  const { t } = useTranslation();

  const {
    children,
    backGroundSp,
    backGroundPc,
    backGroundPc2x,
    title,
    to,
    linkText,
  } = props;

  return (
    <CompletionLayoutS
      backGroundSp={backGroundSp}
      backGroundPc={backGroundPc}
      backGroundPc2x={backGroundPc2x}
    >
      <ContainerS>
        {/* ページタイトル */}
        <TitleS>
          <TitleTextS>{t(title)}</TitleTextS>
        </TitleS>

        {/* コンテンツ */}
        {children}

        {/* ページリンク */}
        <LinkWrapS>
          <Link className="link" to={to}>
            {t(linkText)}
          </Link>
        </LinkWrapS>
      </ContainerS>
    </CompletionLayoutS>
  );
});
export default CompletionLayout;

/**
 *
 * Styles
 *
 */
const CompletionLayoutS = styled.main<BackGround>`
  /* background */
  background-image: url(${({ backGroundSp }) => backGroundSp});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (min-width: 1025px) {
    background-image: image-set(
      url(${({ backGroundPc }) => backGroundPc}) 1x,
      url(${({ backGroundPc2x }) => backGroundPc2x}) 2x
    );
    background-position: top;
  }
`;

const ContainerS = styled.div`
  width: 90%;
  min-height: 85vh;
  margin: 0 auto;
  padding: 10rem 0;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @media (min-width: 600px) {
    padding: 14rem 0;
  }

  @media (min-width: 1025px) {
    width: 100%;
    max-width: 100rem;
    min-height: 70vh;
  }
`;

const ItemS = styled.div`
  width: 100%;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1025px) {
    width: 50%;
  }
`;

const TitleS = styled(ItemS)`
  margin-bottom: 4rem;
`;

const TitleTextS = styled.p`
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 600;

  @media (min-width: 600px) {
    font-size: 2rem;
  }

  @media (min-width: 600px) {
    font-size: 2.2rem;
  }
`;

const LinkWrapS = styled(ItemS)`
  .link {
    font-size: 1.4rem;
    font-weight: 200;
    color: #fff040;
    text-decoration: underline;

    @media (min-width: 1025px) {
      font-size: 1.6rem;
    }
  }
`;
