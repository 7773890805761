import { FC, memo, useState } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import CommonModal from '../modal/CommonModal';
import CrossIcon from 'components/atoms/icon/CrossIcon';

/**
 *
 * Props
 *
 */
type Props = {
  onAgree: () => void;
};

/**
 *
 * Component
 *
 */
const TermsModal: FC<Props> = memo(({ onAgree }) => {
  const { t } = useTranslation();

  const [isCloseWithoutAgreement, setCloseWithoutAgreement] = useState<boolean>();

  const onClose = () => {
    setCloseWithoutAgreement(true);
  };

  return (
    <CommonModal backgroundColor="rgba(0, 0, 0, 0.9)">
      {!isCloseWithoutAgreement && (
        <ContainerS>
          <HeaderS>
            <CloseButtonS onClick={onClose}>
              <CrossIcon backgroundColor="#ffffff" />
            </CloseButtonS>
          </HeaderS>
          <TitleS>
            {t('利用規約')}
          </TitleS>
          <FlowS>
            <FlowBodyS>
              <FlowContentS>
                <BodyParagraph>1.Introduction</BodyParagraph>
                <BodyParagraph>1.1. By using, visiting, and/or accessing part of the Daylotts.net website and/or any subdomains,</BodyParagraph>
                <BodyParagraph>websites, or mobile applications owned or operated by our company (hereinafter referred to as the</BodyParagraph>
                <BodyParagraph>&quot;Website&quot;), and/or by registering an account on the Website, you agree to the following. You will be</BodyParagraph>
                <BodyParagraph>bound by these Terms of Use, our Privacy Policy, our Cookie Policy, and any other rules applicable to</BodyParagraph>
                <BodyParagraph>our gambling or gaming products available on the Website (collectively referred to as the &quot;Terms of</BodyParagraph>
                <BodyParagraph>Use&quot;), and you will be deemed to have accepted and understood all the Terms of Use.</BodyParagraph>
                <BodyParagraph>1.2. Please read the terms carefully. If you do not agree with the terms or cannot agree to them,</BodyParagraph>
                <BodyParagraph>please do not use, visit, or access the Website.</BodyParagraph>
                <BodyParagraph>1.3. These terms may be changed by our company at any time, regardless of the reason (including</BodyParagraph>
                <BodyParagraph>compliance with applicable laws or regulatory authorities&apos; requirements). The latest version of these</BodyParagraph>
                <BodyParagraph>terms can be obtained from the website. If you continue to use the website after such changes have</BodyParagraph>
                <BodyParagraph>come into effect, you will be considered to have accepted these changes to the terms.</BodyParagraph>
                <BodyParagraph>1.4. daylotts.net is operated by Seven Eyes Ent. Company (Company Registration Number</BodyParagraph>
                <BodyParagraph>227777/076/077 and Registered Address Sheetal Marg, Baluwater, Kathmandu, Nepal).</BodyParagraph>
                <BodyParagraph>1.5. &quot;You,&quot; &quot;your,&quot; &quot;customer,&quot; &quot;user,&quot; or &quot;player&quot; refers to any individual who uses the services</BodyParagraph>
                <BodyParagraph>available on the website or registered customers of the website.</BodyParagraph>
                <BodyParagraph>1.6. &quot;Game&quot; refers to online casino gaming and other games that are periodically made available on</BodyParagraph>
                <BodyParagraph>the website. daylotts.net reserves the right to add and remove games from the website at its sole</BodyParagraph>
                <BodyParagraph>discretion.</BodyParagraph>
                <BodyParagraph>2.Your Account</BodyParagraph>
                <BodyParagraph>2.1. Legal Requirements</BodyParagraph>
                <BodyParagraph>2.1.1. &quot;Account&quot; refers to the account that you have registered on the website, agreeing to these</BodyParagraph>
                <BodyParagraph>terms. By registering an account, you declare that you are 18 years of age or older, or have reached</BodyParagraph>
                <BodyParagraph>the minimum legal age specified in your jurisdiction of residence under the applicable laws. It is your</BodyParagraph>
                <BodyParagraph>sole responsibility to determine whether the services available on this website are legal in your</BodyParagraph>
                <BodyParagraph>country of residence. Individuals under the age of 18 are not allowed to use the website and/or the</BodyParagraph>
                <BodyParagraph>services available on the website.</BodyParagraph>
                <BodyParagraph>2.1.2. If you are a resident of Aruba, Austria, Australia, Bonaire, Curaçao, France, Iran, Iraq, the</BodyParagraph>
                <BodyParagraph>Netherlands, Saba, Spain, St. Martin, Statia, the United States, or a resident of the United Kingdom,</BodyParagraph>
                <BodyParagraph>you are not permitted to register on the website and use our services. The company reserves the</BodyParagraph>
                <BodyParagraph>right, at its sole discretion, to refuse customers from other countries beyond the jurisdictions</BodyParagraph>
                <BodyParagraph>mentioned above.</BodyParagraph>
                <BodyParagraph>2.2. Account Registration</BodyParagraph>
                <BodyParagraph>2.2.1. To place bets, play games, or make deposits, you must register an account on the website.</BodyParagraph>
                <BodyParagraph>2.2.2. To register an account, you must provide complete and up-to-date information, including your</BodyParagraph>
                <BodyParagraph>mobile phone number, email address, username, password, and any other required information on the</BodyParagraph>
                <BodyParagraph>registration form.</BodyParagraph>
                <BodyParagraph>2.2.3. By registering an account on the website, you agree to provide your official name. The</BodyParagraph>
                <BodyParagraph>company may take measures to verify the accuracy of the information you provided. This data cannot</BodyParagraph>
                <BodyParagraph>be changed, but in cases of honest mistakes or other issues, you may request individual data</BodyParagraph>
                <BodyParagraph>changes by contacting the website&apos;s customer support.</BodyParagraph>
                <BodyParagraph>2.2.4. As part of our security procedures, if you choose or are provided with a username, password, or</BodyParagraph>
                <BodyParagraph>other information, you must treat such information as confidential and not disclose it to third parties.</BodyParagraph>
                <BodyParagraph>The company will not be responsible for any misuse or abuse of accounts by third parties, whether</BodyParagraph>
                <BodyParagraph>intentional or accidental, active or passive, resulting from the disclosure of login information to third</BodyParagraph>
                <BodyParagraph>parties. The company will never ask you for your password disclosure, nor will it initiate contact with</BodyParagraph>
                <BodyParagraph>you to recall password-related memories.</BodyParagraph>
                <BodyParagraph>2.2.5. Employees, former employees, and/or associates of the service provider are not allowed to</BodyParagraph>
                <BodyParagraph>register an account on the website and are not permitted to use the services or promotions available</BodyParagraph>
                <BodyParagraph>on the website. The same rule applies to the family members mentioned above. Violating this rule will</BodyParagraph>

                <BodyParagraph>result in permanent account closure, and referred accounts will be considered fraudulent. Winnings</BodyParagraph>
                <BodyParagraph>obtained from such activity will be considered forfeited by the account owner, and only the deposited</BodyParagraph>
                <BodyParagraph>amount will be returned to the account owner.</BodyParagraph>
                <BodyParagraph>2.2.6. Accounts cannot be transferred, sold, or pledged to others. This prohibition includes the transfer</BodyParagraph>
                <BodyParagraph>of all types of valuable assets, including but not limited to accounts, winnings, deposits, bets, rights,</BodyParagraph>
                <BodyParagraph>and/or claims related to legal, commercial, or other assets. Such prohibition extends to assignees or</BodyParagraph>
                <BodyParagraph>other third parties, companies, individuals or legal entities, foundations, and cooperatives, including</BodyParagraph>
                <BodyParagraph>but not limited to debts, liens, transfers, usufructs, transactions, intermediation, assumptions, and</BodyParagraph>
                <BodyParagraph>donations, in any form of association or in any way.</BodyParagraph>
                <BodyParagraph>2.3. Account Issues</BodyParagraph>
                <BodyParagraph>2.3.1. In case you forget your password or suspect that someone may have knowledge of your</BodyParagraph>
                <BodyParagraph>personal data details, or if there is suspicion of other users engaging in fraudulent activities or</BodyParagraph>
                <BodyParagraph>collusion, you must report such suspicions to our company.</BodyParagraph>
                <BodyParagraph>2.3.2. Our company reserves the right, at our sole discretion, to declare bets or transactions partially</BodyParagraph>
                <BodyParagraph>or entirely invalid if it is clear that one of the following situations has occurred:</BodyParagraph>
                <BodyParagraph>(1) Account owners or individuals associated with account owners may directly or indirectly influence</BodyParagraph>
                <BodyParagraph>the outcome of events.</BodyParagraph>
                <BodyParagraph>(2) Account owners or individuals associated with account owners are circumventing the rules of the</BodyParagraph>
                <BodyParagraph>website, directly or indirectly.</BodyParagraph>
                <BodyParagraph>(3) The outcome of events or bets is directly or indirectly influenced by criminal activities.</BodyParagraph>
                <BodyParagraph>(4) Bets were placed during a period when they would not have been accepted by other means but</BodyParagraph>
                <BodyParagraph>were accepted due to technical issues affecting the website.</BodyParagraph>
                <BodyParagraph>(5) Errors, misprints, technical errors, human errors, force majeure, or other errors resulted in bets</BodyParagraph>
                <BodyParagraph>being proposed, placed, or accepted due to this error.</BodyParagraph>
                <BodyParagraph>2.3.3. Regardless of the reason, if our company closes or temporarily suspends an account, we</BodyParagraph>
                <BodyParagraph>reserve the right to close any future accounts that may have been registered by the same individual,</BodyParagraph>
                <BodyParagraph>device, address, or using the same payment wallet or credit card, and invalidate/cancel all bets and</BodyParagraph>
                <BodyParagraph>transactions associated with that account.</BodyParagraph>
                <BodyParagraph>2.3.4. As an additional security measure against unauthorized use of accounts, our company provides</BodyParagraph>
                <BodyParagraph>two-factor authentication (2FA). You are responsible for keeping your login information confidential</BodyParagraph>
                <BodyParagraph>and ensuring that others cannot access it.</BodyParagraph>
                <BodyParagraph>2.4. By registering an account on the website, you declare and warrant the following:</BodyParagraph>
                <BodyParagraph>2.4.1. You are 18 years of age or older, or you have reached the minimum legal age specified by the</BodyParagraph>
                <BodyParagraph>jurisdictional law applicable to you to participate in the games offered on the website.</BodyParagraph>
                <BodyParagraph>2.4.2. You will use this website and your account for the sole purpose of genuine participation in the</BodyParagraph>
                <BodyParagraph>games and not for financial or other business purposes. Participation in the games is strictly for</BodyParagraph>
                <BodyParagraph>recreational and entertainment purposes and is based solely on your non-professional capacity.</BodyParagraph>
                <BodyParagraph>2.4.3. You will participate in the games as a representative of yourself and not on behalf of others.</BodyParagraph>
                <BodyParagraph>2.4.4. You are not a resident of Aruba, France, Iran, Iraq, the Netherlands, North Korea, Singapore,</BodyParagraph>
                <BodyParagraph>Spain, St. Martin, Statia, the United States, U.S. territories, Ukraine, or the United Kingdom.</BodyParagraph>
                <BodyParagraph>2.4.5. All information you provide to daylotts.net is true, complete, and accurate, and you will promptly</BodyParagraph>
                <BodyParagraph>notify our company if there are any changes to such information.</BodyParagraph>
                <BodyParagraph>2.4.6. You are solely responsible for reporting and accounting for any taxes applicable to the winnings</BodyParagraph>
                <BodyParagraph>you receive from daylotts.net in accordance with relevant laws.</BodyParagraph>
                <BodyParagraph>2.4.7. All money that you deposit into your account is not of illegal origin and, in particular, is not</BodyParagraph>
                <BodyParagraph>derived from any illegal activities or funding sources.</BodyParagraph>
                <BodyParagraph>2.4.8. You acknowledge the risk of losing funds deposited into your account by participating in the</BodyParagraph>
                <BodyParagraph>games.</BodyParagraph>
                <BodyParagraph>2.4.9. You shall not engage in fraud, collusion, cheating, or any other illegal activities, nor shall you</BodyParagraph>
                <BodyParagraph>use software aids, techniques, or hardware devices in connection with your or any third party&apos;s</BodyParagraph>
                <BodyParagraph>participation in the games. daylotts.net reserves the right to disable or close your account or invalidate</BodyParagraph>
                <BodyParagraph>your participation in the games if such activities are detected.</BodyParagraph>

                <BodyParagraph>2.4.10. Regarding the deposit and withdrawal of funds to/from your account, you will use only valid</BodyParagraph>
                <BodyParagraph>and legally owned credit cards and other financial instruments belonging to you.</BodyParagraph>
                <BodyParagraph>2.4.11. The computer software provided by our company is owned by daylotts.net or other third</BodyParagraph>
                <BodyParagraph>parties and is protected by copyright and other intellectual property laws. You may use the software</BodyParagraph>
                <BodyParagraph>for personal entertainment purposes only in compliance with all rules, terms, and applicable laws,</BodyParagraph>
                <BodyParagraph>regulations, and provisions.</BodyParagraph>
                <BodyParagraph>2.4.12. Games played on the website must be played in the same manner as games played in other</BodyParagraph>
                <BodyParagraph>settings. You shall treat other players and representatives of daylotts.net with courtesy and avoid</BodyParagraph>
                <BodyParagraph>making disrespectful or obscene comments, including in chat rooms.</BodyParagraph>
                <BodyParagraph>3.Multi-Accounts</BodyParagraph>
                <BodyParagraph>3.1. You are allowed to have only one account that can be used for signing up on the website.</BodyParagraph>
                <BodyParagraph>3.2. Only one account is permitted per household, IP address, computer, or device. If two or more</BodyParagraph>
                <BodyParagraph>users share the same household, IP address, computer, or device, each account owner must be</BodyParagraph>
                <BodyParagraph>notified in advance.</BodyParagraph>
                <BodyParagraph>3.3. If you attempt to sign up or register for multiple accounts, regardless of the reason, our company</BodyParagraph>
                <BodyParagraph>may, at our discretion, block or close part or all of your accounts. Additionally, we may invalidate all</BodyParagraph>
                <BodyParagraph>bets made on duplicate accounts, block bonuses or gifts, and invalidate withdrawal requests.</BodyParagraph>
                <BodyParagraph>Furthermore, any returns, winnings, or bonuses acquired or accumulated during the lifecycle of</BodyParagraph>
                <BodyParagraph>duplicate accounts will be forfeited.</BodyParagraph>
                <BodyParagraph>4.Payment</BodyParagraph>
                <BodyParagraph>4.1. All winnings are available in your account when the outcome of the game you participated in is</BodyParagraph>
                <BodyParagraph>determined or when daylotts.net confirms the results of the related event and settles the market, as</BodyParagraph>
                <BodyParagraph>applicable.</BodyParagraph>
                <BodyParagraph>4.2. If daylotts.net mistakenly deposits winnings not belonging to you into your account, regardless of</BodyParagraph>
                <BodyParagraph>the reason, such as technical errors or human errors, that amount will remain the property of</BodyParagraph>
                <BodyParagraph>daylotts.net, and it will be deducted from your account. If you withdraw funds that do not belong to you</BodyParagraph>
                <BodyParagraph>before daylotts.net notices the error, without affecting any other available legal remedies or actions,</BodyParagraph>
                <BodyParagraph>the amount erroneously paid to you becomes a debt you owe to daylotts. In the event of unauthorized</BodyParagraph>
                <BodyParagraph>credits, you are obliged to immediately notify daylotts.net.</BodyParagraph>
                <BodyParagraph>4.3. daylotts.net reserves the right to perform additional verification and identity confirmation</BodyParagraph>
                <BodyParagraph>procedures when making withdrawals or at any level of withdrawal. To prevent money laundering, all</BodyParagraph>
                <BodyParagraph>transactions will be checked.</BodyParagraph>
                <BodyParagraph>5.Deposits</BodyParagraph>
                <BodyParagraph>5.1. To play with real money bets, you need to deposit funds into your account. You can deposit online</BodyParagraph>
                <BodyParagraph>at any time via debit or credit cards, e-wallets, or bank transfers through all available deposit methods.</BodyParagraph>
                <BodyParagraph>You can check all available deposit methods on the &quot;Deposit&quot; page of the website. Cash or checks are</BodyParagraph>
                <BodyParagraph>not accepted as deposit methods. Please note that some methods may not be available in certain</BodyParagraph>
                <BodyParagraph>countries.</BodyParagraph>
                <BodyParagraph>5.2. We accept payments in various currencies. Payments received by daylotts.net in a currency other</BodyParagraph>
                <BodyParagraph>than the account&apos;s currency will be converted into the account&apos;s currency at the prevailing exchange</BodyParagraph>
                <BodyParagraph>rate. Please be aware that exchange premiums are at your expense.</BodyParagraph>
                <BodyParagraph>5.3. daylotts.net reserves the right to perform Know Your Customer (KYC) verification using additional</BodyParagraph>
                <BodyParagraph>procedures and means when making deposits to the account and reserves the right to close the</BodyParagraph>
                <BodyParagraph>account if these documents cannot be submitted to daylotts.net.</BodyParagraph>
                <BodyParagraph>5.4. daylotts.net does not grant any credit for the use of its services.</BodyParagraph>
                <BodyParagraph>5.5. By depositing, you confirm that all deposits are approved, and you agree not to take actions such</BodyParagraph>
                <BodyParagraph>as refusing deposits or having payments canceled by third parties to avoid legitimate responsibility.</BodyParagraph>

                <BodyParagraph>5.6. To prevent money laundering, you must wager your deposit at least once before requesting a</BodyParagraph>
                <BodyParagraph>withdrawal. Please be aware of any wagering requirements that may apply before requesting a</BodyParagraph>
                <BodyParagraph>withdrawal.</BodyParagraph>
                <BodyParagraph>5.7. Third-party deposits (by friends, relatives, partners, spouses, etc.) are not allowed at daylotts.net.</BodyParagraph>
                <BodyParagraph>All payments must be made from the account/system or credit card registered in the account owner&apos;s</BodyParagraph>
                <BodyParagraph>name. If third-party deposits are discovered, all winnings will be confiscated and transferred to</BodyParagraph>
                <BodyParagraph>daylotts.net, and the deposit will be refunded to the legitimate owner of the account/credit card. If</BodyParagraph>
                <BodyParagraph>bank transfer fees are required when returning money to the legitimate owner, the recipient will cover</BodyParagraph>
                <BodyParagraph>those fees.</BodyParagraph>
                <BodyParagraph>6.Withdrawals</BodyParagraph>
                <BodyParagraph>6.1. You must provide KYC documents, a photo of your credit card (both sides), where the card</BodyParagraph>
                <BodyParagraph>number should be clearly readable with the first 6 digits and last 4 digits visible, while the remaining</BodyParagraph>
                <BodyParagraph>digits of the card number and CVV/CVC code should not be displayed when applying for the first</BodyParagraph>
                <BodyParagraph>withdrawal, bank statements, copies of identification documents, address verification, and proof of</BodyParagraph>
                <BodyParagraph>ownership of other used payment methods, with additional requirements applicable depending on the</BodyParagraph>
                <BodyParagraph>payment channel.</BodyParagraph>
                <BodyParagraph>6.2. daylotts.net reserves the right to change the maximum allowable amount for each payment</BodyParagraph>
                <BodyParagraph>system per transaction at any time without prior notice.</BodyParagraph>
                <BodyParagraph>6.3. Withdrawal requests will not be processed until all wagering requirements are met.</BodyParagraph>
                <BodyParagraph>6.4. Funds marked as &quot;Bonuses&quot; and funds stuck in interrupted games cannot be withdrawn.</BodyParagraph>
                <BodyParagraph>6.5. daylotts.net reserves the right to refuse a withdrawal if the total amount of bets is less than the</BodyParagraph>
                <BodyParagraph>last deposit amount. You must first wager your initial deposit at least once before making a</BodyParagraph>
                <BodyParagraph>withdrawal.</BodyParagraph>
                <BodyParagraph>6.6. All withdrawal requests will be processed within 2 business days, but this timing may be extended</BodyParagraph>
                <BodyParagraph>further depending on the payment channel, additional account verification, and holidays.</BodyParagraph>
                <BodyParagraph>6.7. Users cannot withdraw funds exceeding their account balance.</BodyParagraph>
                <BodyParagraph>6.8. Withdrawals will be made to a bank account or other available withdrawal methods in the</BodyParagraph>
                <BodyParagraph>&quot;Cashier&quot; that the user used to deposit funds into their account. Withdrawals will be processed using</BodyParagraph>
                <BodyParagraph>the method that the user used to deposit funds into their account.</BodyParagraph>
                <BodyParagraph>6.9. In the event of a withdrawal cancellation, the funds will be returned to the account, and users can</BodyParagraph>
                <BodyParagraph>use those funds on their account as appropriate. daylotts.net assumes no responsibility for funds lost</BodyParagraph>
                <BodyParagraph>during gameplay after a withdrawal has been canceled by the user or the company.</BodyParagraph>
                <BodyParagraph>6.10. Please note that our products are instantly consumed during gameplay. Therefore, refunds,</BodyParagraph>
                <BodyParagraph>reimbursements, or cancellations of services requested during play cannot be accommodated. When</BodyParagraph>
                <BodyParagraph>playing with real money, that money will be withdrawn from your account immediately.</BodyParagraph>
                <BodyParagraph>6.11. Unless otherwise agreed upon for larger amounts, you can withdraw a maximum of $10,000 (or</BodyParagraph>
                <BodyParagraph>the equivalent in the account currency) within 24 hours and a maximum of $300,000 (or the equivalent</BodyParagraph>
                <BodyParagraph>in the account currency) within 30 days. ※ High winners can extend withdrawal limits as an</BodyParagraph>
                <BodyParagraph>exception. Please contact Customer Support.</BodyParagraph>
                <BodyParagraph>6.12. Before accepting a withdrawal, we may request the submission of legal identification</BodyParagraph>
                <BodyParagraph>documents, such as a passport, ID card, or similar documents, depending on the situation.</BodyParagraph>
                <BodyParagraph>Additionally, we may conduct phone verification, facial recognition, or other authentication procedures</BodyParagraph>
                <BodyParagraph>to verify your identity.</BodyParagraph>
                <BodyParagraph>6.13. Pending withdrawals (i.e., those requested but not processed) are limited to one per payment</BodyParagraph>
                <BodyParagraph>method at a time. Additionally, depending on the method used, you can make a withdrawal request</BodyParagraph>
                <BodyParagraph>only once every 24 hours.</BodyParagraph>
                <BodyParagraph>6.14. The maximum daily prize for a single customer cannot exceed $100,000 or the equivalent in the</BodyParagraph>
                <BodyParagraph>account currency. &quot;Day&quot; refers to the period from GMT 00:00 to GMT 23:59.</BodyParagraph>
                <BodyParagraph>7.Account Closure</BodyParagraph>

                <BodyParagraph>7.1. You may request the closure of your account at any time, with the condition that any related</BodyParagraph>
                <BodyParagraph>withdrawal fees will be deducted from your account balance. To close your account, you must first</BodyParagraph>
                <BodyParagraph>cancel any open bets if applicable and contact the website&apos;s customer support. The closure of an</BodyParagraph>
                <BodyParagraph>account is equivalent to terminating daylotts.net. If the reason for closing the account is related to</BodyParagraph>
                <BodyParagraph>concerns about potential gambling addiction, you must notify daylotts.net.</BodyParagraph>
                <BodyParagraph>7.2. The method of reimbursement will be determined at our discretion.</BodyParagraph>
                <BodyParagraph>7.3. daylotts.net reserves the right to close your account without the obligation to state or provide</BodyParagraph>
                <BodyParagraph>reasons, deducting any associated withdrawal fees and refunding the &quot;withdrawable&quot; balance to you,</BodyParagraph>
                <BodyParagraph>at daylotts.net&apos;s absolute discretion, without prior notice.</BodyParagraph>
                <BodyParagraph>7.4. daylotts.net reserves the right to cancel and remove any granted bonus amounts if they have not</BodyParagraph>
                <BodyParagraph>been used within 1 month from the date of granting.</BodyParagraph>
                <BodyParagraph>7.5. daylotts.net reserves the right to refuse withdrawal requests in cases of fraud. In such cases, the</BodyParagraph>
                <BodyParagraph>account will be suspended, and payments will not be processed.</BodyParagraph>
                <BodyParagraph>7.6. daylotts.net will verify and classify all player accounts at its own discretion. If a player is classified</BodyParagraph>
                <BodyParagraph>as a &quot;bonus hunter&quot; or &quot;bonus abuser,&quot; all winnings and bonuses will become void, the account will be</BodyParagraph>
                <BodyParagraph>temporarily suspended, and payments will not be processed.</BodyParagraph>
                <BodyParagraph>8.Personal Information</BodyParagraph>
                <BodyParagraph>8.1. We will comply with data protection laws applicable to the personal information you provide to us.</BodyParagraph>
                <BodyParagraph>Your personal information will be processed in accordance with our Privacy Policy. A copy of the</BodyParagraph>
                <BodyParagraph>Privacy Policy can be obtained by clicking here.</BodyParagraph>
                <BodyParagraph>9.Crashed and Aborted Games</BodyParagraph>
                <BodyParagraph>9.1. Criminal Activities</BodyParagraph>
                <BodyParagraph>In the event of any fraudulent activities (including attempted money laundering or suspected fraud),</BodyParagraph>
                <BodyParagraph>daylotts.net reserves the right to close accounts and report to relevant authorities through one or more</BodyParagraph>
                <BodyParagraph>accounts regarding criminal activities or other suspicious activities conducted via the website. All</BodyParagraph>
                <BodyParagraph>violators&apos; account balances will be blocked, withdrawals invalidated, and deposits and winnings</BodyParagraph>
                <BodyParagraph>confiscated.</BodyParagraph>
                <BodyParagraph>9.2. Collusion and Fraud</BodyParagraph>
                <BodyParagraph>daylotts.net may disable a user&apos;s account and confiscate the account balance (including deposits and</BodyParagraph>
                <BodyParagraph>winnings) if it is discovered that the user has engaged in activities aimed at profiting from transaction</BodyParagraph>
                <BodyParagraph>information on cards or colluding with other users to commit fraud or gain an unfair advantage. These</BodyParagraph>
                <BodyParagraph>advantages may include chip dumping and transferring, discussing hands during play, multi-account</BodyParagraph>
                <BodyParagraph>use, soft play, and more. daylotts.net offers strict scrutiny of play through both manual and automated</BodyParagraph>
                <BodyParagraph>methods and investigates all relevant user complaints. Additionally, daylotts.net offers proactive and</BodyParagraph>
                <BodyParagraph>random checks of gameplay and accounts.</BodyParagraph>
                <BodyParagraph>9.3. Fraudulent Activities</BodyParagraph>
                <BodyParagraph>If Daylotts.net detects fraudulent, illegal, deceptive, or inappropriate activities (including but not limited</BodyParagraph>
                <BodyParagraph>to masking or manipulating the actual location identification using VPNs, proxies, or similar services</BodyParagraph>
                <BodyParagraph>on third-party websites, betting, gambling, or poker play through third parties or agents), Daylotts.net</BodyParagraph>
                <BodyParagraph>reserves the right to block the user&apos;s account without prior notice and confiscate all account balances.</BodyParagraph>
                <BodyParagraph>In such cases, Daylotts.net reserves the right to report fraudulent activities to existing users. This may</BodyParagraph>
                <BodyParagraph>include but is not limited to, banks, credit card companies, individuals, or organizations with legal</BodyParagraph>
                <BodyParagraph>rights to such information and take legal actions against such users through regulatory and law</BodyParagraph>
                <BodyParagraph>enforcement agencies.</BodyParagraph>
                <BodyParagraph>10.Live Chat</BodyParagraph>
                <BodyParagraph>10.1. As part of website usage, daylotts.net may provide a chat feature, which is managed and</BodyParagraph>
                <BodyParagraph>controlled by us. We reserve the right to monitor the chat and record all statements made in such</BodyParagraph>
                <BodyParagraph>facilities. The use of the chat feature is intended for recreation and socialization, subject to the</BodyParagraph>
                <BodyParagraph>following rules.</BodyParagraph>

                <BodyParagraph>10.2. You must not engage in sexually explicit, overtly offensive, or significantly aggressive</BodyParagraph>
                <BodyParagraph>statements, including expressions of bias, racial discrimination, hatred, or blasphemy.</BodyParagraph>
                <BodyParagraph>10.3. You must not make abusive, defamatory, harassing, or insulting statements about the website or</BodyParagraph>
                <BodyParagraph>daylotts.net.</BodyParagraph>
                <BodyParagraph>10.4. You must not make statements that promote or otherwise associate with other online entities by</BodyParagraph>
                <BodyParagraph>advertising or other means.</BodyParagraph>
                <BodyParagraph>10.5. You must not make false, malicious, or statements that may harm daylotts.net, this website, or</BodyParagraph>
                <BodyParagraph>other internet sites connected to daylotts.net.</BodyParagraph>
                <BodyParagraph>10.6. You must not engage in collusion through chat rooms or individual chats. Suspicious chat</BodyParagraph>
                <BodyParagraph>activities will be reported to relevant regulatory authorities or law enforcement agencies.</BodyParagraph>
                <BodyParagraph>10.7. If you violate any of the above provisions regarding the chat feature, daylotts.net reserves the</BodyParagraph>
                <BodyParagraph>right to either remove the chat room or immediately terminate your account. Upon such termination,</BodyParagraph>
                <BodyParagraph>daylotts.net will refund you any funds that may exist in your account beyond any amounts due to us at</BodyParagraph>
                <BodyParagraph>that time, if applicable.</BodyParagraph>
                <BodyParagraph>11. Crashed and Aborted Games</BodyParagraph>
                <BodyParagraph>11.1. daylotts.net shall not be held responsible for downtime, server interruptions, delays, or technical</BodyParagraph>
                <BodyParagraph>or political disruptions affecting gameplay. Refunds may only be granted at the discretion of</BodyParagraph>
                <BodyParagraph>daylotts.net.</BodyParagraph>
                <BodyParagraph>11.2. daylotts.net shall not be liable for any damages or losses claimed or deemed to have arisen</BodyParagraph>
                <BodyParagraph>from or related to the website or its content (including but not limited to delays or interruptions in</BodyParagraph>
                <BodyParagraph>operation or transmission, data loss or corruption, and communication issues) or arising from or</BodyParagraph>
                <BodyParagraph>related to misuse of the website or its content by anyone, or errors or omissions in the content.</BodyParagraph>
                <BodyParagraph>11.3. In case of a malfunction in the casino system, all bets will be void.</BodyParagraph>
                <BodyParagraph>11.4. If a game has commenced but is then aborted due to a system malfunction, daylotts.net will</BodyParagraph>
                <BodyParagraph>refund the amount wagered in the game by crediting it to your account or, if your account no longer</BodyParagraph>
                <BodyParagraph>exists, by paying it to you. If there is a monetary value associated with any credits accrued when the</BodyParagraph>
                <BodyParagraph>game failed, daylotts.net will credit that value to your account or, if your account no longer exists, will</BodyParagraph>
                <BodyParagraph>pay it to you through the approved method.</BodyParagraph>
                <BodyParagraph>12.Obligations</BodyParagraph>
                <BodyParagraph>12.1. daylotts.net reserves the right to assign or legally transfer the rights and obligations under these</BodyParagraph>
                <BodyParagraph>Terms and Conditions. You shall not assign or transfer your rights and obligations under these Terms</BodyParagraph>
                <BodyParagraph>and Conditions.</BodyParagraph>
                <BodyParagraph>13.Complaints</BodyParagraph>
                <BodyParagraph>13.1. If you have a complaint, please send an email to the website&apos;s customer support at</BodyParagraph>
                <BodyParagraph>support@daylotts.net.</BodyParagraph>
                <BodyParagraph>13.2. daylotts.net will make its best efforts to promptly address reported issues.</BodyParagraph>
                <BodyParagraph>13.3. If you have questions regarding transactions, please contact daylotts.net (support@daylotts.net)</BodyParagraph>
                <BodyParagraph>with details of your inquiry. We will consider questions or objections related to transactions. Our</BodyParagraph>
                <BodyParagraph>judgment shall be final.</BodyParagraph>
                <BodyParagraph>14.Consent and Acceptance</BodyParagraph>
                <BodyParagraph>14.1. These terms, the Privacy Policy, Cookie Policy, and any documents explicitly referred to therein,</BodyParagraph>
                <BodyParagraph>along with guidelines or rules posted on the website, constitute the complete agreement and</BodyParagraph>
                <BodyParagraph>understanding between you and daylotts.net regarding this website. They take precedence over all</BodyParagraph>
                <BodyParagraph>prior or contemporaneous communications and proposals, whether electronic, oral, or written,</BodyParagraph>
                <BodyParagraph>between you and daylotts.net concerning fraud.</BodyParagraph>
                <BodyParagraph>14.2. A printed version of these terms and electronic notifications given shall be admissible in judicial</BodyParagraph>
                <BodyParagraph>or administrative proceedings based upon or relating to these terms, to the same extent and subject</BodyParagraph>

                <BodyParagraph>to the same conditions as other business documents and records originally generated and maintained</BodyParagraph>
                <BodyParagraph>in printed form.</BodyParagraph>
                <BodyParagraph>14.3. If any provision of these terms is deemed unlawful or unenforceable, that provision shall be</BodyParagraph>
                <BodyParagraph>severed from these terms, and all other provisions shall remain valid and enforceable.</BodyParagraph>
                <BodyParagraph>14.4. In case of inconsistencies in the text content between language versions, the English version of</BodyParagraph>
                <BodyParagraph>the website shall prevail.</BodyParagraph>
                <BodyParagraph>14.5. These terms are governed by Nepalese law, and both parties agree to be bound by the</BodyParagraph>
                <BodyParagraph>arbitration rules based on the jurisdiction and applicable law of Nepalese courts.</BodyParagraph>
                <BodyParagraph>15.Copyright</BodyParagraph>
                <BodyParagraph>15.1. We are the sole owners of the daylotts.net trademark and the daylotts.net logo. Unauthorized</BodyParagraph>
                <BodyParagraph>use of the daylotts.net trademark and daylotts.net logo may result in prosecution.</BodyParagraph>
                <BodyParagraph>15.2. https://daylotts.net is the Uniform Resource Locator (URL) of the website operated by</BodyParagraph>
                <BodyParagraph>daylotts.net, and this URL may not be used improperly on another website or digital platform without</BodyParagraph>
                <BodyParagraph>prior written consent.</BodyParagraph>
                <BodyParagraph>15.3. daylotts.net is the owner or rightful licensee of the rights to the technology, software, and</BodyParagraph>
                <BodyParagraph>business systems used within this website.</BodyParagraph>
                <BodyParagraph>15.4. The content and structure of daylotts.net website pages belong to daylotts.net, and all rights are</BodyParagraph>
                <BodyParagraph>reserved. Copyrights for this website, including all text, graphics, code, files, and links, belong to</BodyParagraph>
                <BodyParagraph>daylotts.net, and unless authorized in writing by us, the whole or any part of the site may not be</BodyParagraph>
                <BodyParagraph>reproduced, transmitted, or saved. Therefore, your registration and use of our system do not grant</BodyParagraph>
                <BodyParagraph>you any rights to the intellectual property contained in our system.</BodyParagraph>
                <BodyParagraph>15.5. Links to this website and its pages may not be included on other websites without prior written</BodyParagraph>
                <BodyParagraph>consent from daylotts.net.</BodyParagraph>
                <BodyParagraph>15.6. You agree not to use automated or manual devices to monitor the pages or content of the</BodyParagraph>
                <BodyParagraph>website.</BodyParagraph>
                <BodyParagraph>15.7. Unauthorized use or replication may lead to prosecution.</BodyParagraph>
                <BodyParagraph>16.Promotions: Terms of Use</BodyParagraph>
                <BodyParagraph>16.1. Each individual promotion is subject to its own specific set of Promotion Terms of Use</BodyParagraph>
                <BodyParagraph>(&quot;Promotion Terms&quot;) in addition to these Terms and Conditions. Therefore, please read these Terms</BodyParagraph>
                <BodyParagraph>and Conditions in conjunction with the Promotion Terms that apply to the contest, bonus, or promotion</BodyParagraph>
                <BodyParagraph>you wish to participate in.</BodyParagraph>
                <BodyParagraph>16.2. By participating in a promotion, you agree to be bound by both these Terms and Conditions and</BodyParagraph>
                <BodyParagraph>the Promotion Terms.</BodyParagraph>
                <BodyParagraph>16.3. If, for any reason, a promotion does not have Promotion Terms applied to it, these Terms and</BodyParagraph>
                <BodyParagraph>Conditions will apply by default to that promotion.</BodyParagraph>
                <BodyParagraph>16.4. The Promotion Terms governing the conduct of a promotion are independent of the remaining</BodyParagraph>
                <BodyParagraph>terms. Even if any provision is found to be inappropriate or inaccurate, the remaining Promotion</BodyParagraph>
                <BodyParagraph>Terms will continue to be valid.</BodyParagraph>
                <BodyParagraph>16.5. Participation in a promotion is deemed as your full and unconditional agreement to the terms,</BodyParagraph>
                <BodyParagraph>including these Promotion Terms and any applicable Promotion Terms, and that our decisions are final</BodyParagraph>
                <BodyParagraph>and binding in all respects.</BodyParagraph>
                <BodyParagraph>16.6. daylotts.net reserves the right to terminate or cancel the current promotion without prior notice.</BodyParagraph>
                <BodyParagraph>Users who violate the Promotion Terms may be disqualified from receiving rewards from that</BodyParagraph>
                <BodyParagraph>promotion.</BodyParagraph>
                <BodyParagraph>16.7. daylotts.net reserves the right, at its sole discretion, to withhold credits, bonuses, coupons,</BodyParagraph>
                <BodyParagraph>loyalty points, or prizes awarded as part of a promotion.</BodyParagraph>
                <BodyParagraph>16.8. daylotts.net has the right to notify users of current promotions and updates via email, SMS, chat,</BodyParagraph>
                <BodyParagraph>WhatsApp, social media, mobile phones, internet browser notifications, or mobile applications.</BodyParagraph>
                <BodyParagraph>16.9. daylotts.net is not responsible for inaccurate information, whether caused by human error,</BodyParagraph>
                <BodyParagraph>technical errors related to users&apos; devices, or submission errors, regardless of whether they are related</BodyParagraph>
                <BodyParagraph>to the website, promotions, or entries.</BodyParagraph>

                <BodyParagraph>16.10. Bonuses and Bonus Money</BodyParagraph>
                <BodyParagraph>16.10.1. At daylotts.net, we regularly offer various bonuses and perks that are credited to your</BodyParagraph>
                <BodyParagraph>account. The term &quot;bonuses&quot; at daylotts.net refers to cashbacks, additional money for direct play, and</BodyParagraph>
                <BodyParagraph>additional money received after a deposit. Typically, bonuses are subject to wagering requirements</BodyParagraph>
                <BodyParagraph>that must be met before funds can be withdrawn. In such cases, we recommend that you carefully</BodyParagraph>
                <BodyParagraph>read the bonus terms before using them.</BodyParagraph>
                <BodyParagraph>16.10.2. Bonus Rules</BodyParagraph>
                <BodyParagraph>16.10.2.1. Bonuses are credited to the bonus balance.</BodyParagraph>
                <BodyParagraph>16.10.2.2. Once the wagering requirements for a bonus are met, the bonus amount is transferred to</BodyParagraph>
                <BodyParagraph>the main balance and can be withdrawn at any time.</BodyParagraph>
                <BodyParagraph>16.10.2.3. daylotts.net reserves the right to take punitive measures against users who abuse</BodyParagraph>
                <BodyParagraph>bonuses, which means that the current bonus and bonus winnings will be deleted and denied to the</BodyParagraph>
                <BodyParagraph>user. The user&apos;s account will be immediately blocked. In such cases, daylotts.net will not be</BodyParagraph>
                <BodyParagraph>responsible for withdrawing or compensating any funds in the account before being blocked.</BodyParagraph>
                <BodyParagraph>16.10.2.4. Using a bonus to play games with accumulated features during gameplay (e.g., unlocking</BodyParagraph>
                <BodyParagraph>additional game features after several plays) and then returning to the game after zeroing out the</BodyParagraph>
                <BodyParagraph>wagering requirements to trigger or unlock the features. Recovering the accumulated features or</BodyParagraph>
                <BodyParagraph>associated winnings. In this case, all winnings and bonuses will be invalidated.</BodyParagraph>
                <BodyParagraph>16.10.2.5. Funds won using a bonus cannot be withdrawn until all bonuses have been wagered.</BodyParagraph>
                <BodyParagraph>16.10.2.6. In the event of a malfunction, all payments and gameplay will be invalidated. daylotts.net</BodyParagraph>
                <BodyParagraph>reserves the right to withdraw any winnings resulting from the latest deposit.</BodyParagraph>
                <BodyParagraph>16.10.2.7. Users must read and understand the applicable bonus policy to receive bonuses and</BodyParagraph>
                <BodyParagraph>comply with its terms.</BodyParagraph>
                <BodyParagraph>16.10.2.8. Wagering requirements for bonuses refer to the multiplier representing the number of times</BodyParagraph>
                <BodyParagraph>a bonus must be played before winnings can be withdrawn. Once the wagering requirements are met,</BodyParagraph>
                <BodyParagraph>the bonus or winnings earned will be transferred to the main balance. Wagering contributions for</BodyParagraph>
                <BodyParagraph>specific games may not count toward all wagering requirements. As these percentages may change</BodyParagraph>
                <BodyParagraph>from time to time, please contact the website&apos;s customer support for confirmation of the wagering</BodyParagraph>
                <BodyParagraph>contributions for specific games.</BodyParagraph>
                <BodyParagraph>16.11. Wagering Requirements</BodyParagraph>
                <BodyParagraph>16.11.1. After acquiring a bonus, it is transferred to the bonus balance until the wagering requirements</BodyParagraph>
                <BodyParagraph>are met.</BodyParagraph>
                <BodyParagraph>16.11.2. Wagering requirements represent the multiplier indicating the number of times a bonus must</BodyParagraph>
                <BodyParagraph>be played before it can be withdrawn. Once the wagering requirements are met, the bonus or</BodyParagraph>
                <BodyParagraph>winnings earned will be transferred to the main balance.</BodyParagraph>
                <BodyParagraph>16.11.3. If the balance is less than $0.10 (or the equivalent amount in the account&apos;s currency), the</BodyParagraph>
                <BodyParagraph>wagering requirements for the bonus will become invalid.</BodyParagraph>
                <BodyParagraph>16.11.4. Funds won using a bonus cannot be withdrawn until all bonuses have been wagered.</BodyParagraph>
                <BodyParagraph>16.11.5. You can withdraw before meeting the wagering requirements, but doing so will result in the</BodyParagraph>
                <BodyParagraph>loss of the bonus. If you wish to make such a withdrawal, you should contact the website&apos;s customer</BodyParagraph>
                <BodyParagraph>support team.</BodyParagraph>
                <BodyParagraph>16.11.6. All users have the right to forfeit a bonus by making a request to the website&apos;s customer</BodyParagraph>
                <BodyParagraph>support to zero out their bonus balance.</BodyParagraph>
              </FlowContentS>
            </FlowBodyS>
          </FlowS>

          <ConfirmButtonS onClick={onAgree}>
            {t('同意')}
          </ConfirmButtonS>
        </ContainerS>
      )}

    </CommonModal>
  );
});

export default TermsModal;

const ContainerS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  padding: 2rem 2rem 3rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  color: #ffffff;
`;

const HeaderS = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButtonS = styled.button`
  width: 2.4rem;
  height: 2.4rem;
`;

const TitleS = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.3;
`;

const FlowS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.4rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #aaaaaa;
  padding: 2rem;
`;

const FlowBodyS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
`;

const FlowContentS = styled.p`
  font-size: 1.2rem;
  line-height: 1.4;
  max-height: 50vh;
  overflow-y: scroll;
  overscroll-behavior-y: contain;
`;

const ConfirmButtonS = styled.button`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.4rem;
  color: #000000;
  background-color: #fff040;
  border-radius: 6px;
  width: 80%;

  @media (min-width: 600px) {
    width: 200px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const BodyParagraph = styled.p`
  text-align: left;
`;
