import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import { ja } from 'utils/lang/ja';
import { en } from 'utils/lang/en';
import { zh_cn } from 'utils/lang/zh_cn';
import { zh_tw } from 'utils/lang/zh_tw';
import { ko } from 'utils/lang/ko';
import { vi } from 'utils/lang/vi';
import { th } from 'utils/lang/th';
import { LANG } from 'utils/const/lang';
import { LangType } from 'types/lang';

const {
  ENGLISH, JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

// 言語ファイル
const resources = {
  ja: {
    translation: ja,
  },
  en: {
    translation: en,
  },
  'zh-CN': {
    translation: zh_cn,
  },
  'zh-TW': {
    translation: zh_tw,
  },
  ko: {
    translation: ko,
  },
  vi: {
    translation: vi,
  },
  th: {
    translation: th,
  },
};

// ブラウザ言語設定取得
export const fetchBrowserLang = (): LangType => {
  // ブラウザ言語設定
  const browserLang = (window.navigator.languages && window.navigator.languages[0])
    || window.navigator.language;

  // 日本語か英語のみ(それ以外は英語に設定)
  let switchLang: LangType;
  switch (browserLang) {
    case JAPANESE:
      switchLang = JAPANESE;
      break;
    case SIMPLE_CHINESE:
      switchLang = SIMPLE_CHINESE;
      break;
    case TRADITIONAL_CHINESE:
      switchLang = TRADITIONAL_CHINESE;
      break;
    case KOREAN:
      switchLang = KOREAN;
      break;
    case VIETNAM:
      switchLang = VIETNAM;
      break;
    case THAILAND:
      switchLang = THAILAND;
      break;
    default:
      switchLang = ENGLISH;
  }

  return switchLang;
};

export default i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: ENGLISH,
    interpolation: { escapeValue: false },
    resources,
  });
