// External library
import { useLocation } from 'react-router-dom';

/**
 *
 * Types
 *
 */
type UseKycFinish = () => {
  referrer: string;
};

export const useKycFinish: UseKycFinish = () => {
  // locationオブジェクト
  const location = useLocation<{ referrer: string }>();
  const { referrer } = location.state ?? { referrer: '/' };

  return {
    referrer,
  };
};
