import { useState } from 'react';
// External library
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType, NewsType } from 'types/api';

/**
 *
 * お知らせ詳細ページ用ロジック
 *
 */
type News = Omit<
  NewsType,
  'start_date' | 'end_date' | 'created_at' | 'updated_at' | 'deleted_at'
>;

export const useNewsShow = (): {
  isLoading: boolean;
  news: News;
  canWatchLottery: boolean,
  getApiNewsFind: () => void;
  emptyMessage: string;
  errorMessage: string;
  // クリーンアップ関数
  cleanUpMethod: () => void;
} => {
  // i18-next
  const { t } = useTranslation();

  // お知らせが無い時のメッセージ
  const [emptyMessage, setEmptyMessage] = useState<string>('');

  // エラー用カスタムフック
  const { errorHandler } = useError();

  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // ローディング状態
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // お知らせ
  const initialNews = {
    id: 0,
    title: '',
    lot_tables: null,
    description: '',
    en_title: '',
    en_description: '',
    ko_title: '',
    ko_description: '',
    zh_cn_title: '',
    zh_cn_description: '',
    zh_tw_title: '',
    zh_tw_description: '',
    vi_title: '',
    vi_description: '',
    th_title: '',
    th_description: '',
  };
  const [news, setNews] = useState<News>({ ...initialNews });

  const [canWatchLottery, setCanWatchLottery] = useState<boolean>(false);

  // お知らせID
  const { id } = useParams<{ id: string }>();
  // お知らせ情報取得リクエスト
  const axiosNewsSource = axiosCancelToken.source();
  const getApiNewsFind = () => {
    if (!isLoading) {
      setIsLoading(true);
      // axios GET /api/news/find?id=number
      axiosClient
        .get<NewsType>(`/api/news/find?id=${id}`, {
          cancelToken: axiosNewsSource.token,
        })
        .then(({ data }) => {
          // お知らせ詳細取得
          if (Object.keys(data).length !== 0) {
            setNews({
              id: data.id,
              title: data.title,
              description: data.description,
              lot_tables: data.lot_tables,
              en_title: data.en_title,
              en_description: data.en_description,
              ko_title: data.ko_title,
              ko_description: data.ko_description,
              zh_cn_title: data.zh_cn_title,
              zh_cn_description: data.zh_cn_description,
              zh_tw_title: data.zh_tw_title,
              zh_tw_description: data.zh_tw_description,
              vi_title: data.vi_title,
              vi_description: data.vi_description,
              th_title: data.th_title,
              th_description: data.th_description,
            });
            setCanWatchLottery(false);
          } else {
            // お知らせが無い場合
            setEmptyMessage(t('お知らせはありません。'));
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    // APIリクエストキャンセル
    axiosNewsSource.cancel();
    // データ
    setNews({ ...initialNews });
  };

  return {
    isLoading,
    news,
    canWatchLottery,
    getApiNewsFind,
    emptyMessage,
    errorMessage,
    // クリーンアップ関数
    cleanUpMethod,
  };
};
