import { FC } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
// Components
import FormLayout from 'components/templates/FormLayout';
import NormalInput from 'components/atoms/input/NormalInput';
import RoundButton from 'components/atoms/button/RoundButton';
import GoogleRecaptcha from 'components/atoms/text/GoogleRecaptcha';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { useRegister } from 'hooks/pages/guest/useRegister';
// Utils
import { registerBreadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const Register: FC = () => {
  // i18next
  const { t } = useTranslation();

  // 新規会員登録用カスタムフック
  const {
    errorMessage,
    emailRef,
    nameRef,
    passwordRef,
    confirmPasswordRef,
    isCheckedTos,
    openTerms,
    checkTermsOfService,
    pushToRegisterConfirm,
  } = useRegister();

  return (
    <>
      <Helmet>
        <title>{t('title.register')}</title>
        <meta name="description" content={t('description.register')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={registerBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('新規会員登録(タイトル)')}
        titleStyle="gold"
      >
        {/* 登録フォーム */}
        <FormS>
          {/* エラーメッセージ */}
          {errorMessage && (
            <ErrorMessageWrapS>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </ErrorMessageWrapS>
          )}

          <InputWrapS>
            <NormalInput
              ref={emailRef}
              name="email"
              type="text"
              placeholder={t('メールアドレス')}
            />
          </InputWrapS>
          <InputWrapS>
            <NormalInput
              ref={nameRef}
              name="name"
              type="text"
              placeholder={t('ユーザー名')}
            />
            <NoteS>{t('※ユーザー名は後から変更をすることはできません。')}</NoteS>
          </InputWrapS>
          <InputWrapS>
            <NormalInput
              ref={passwordRef}
              name="password"
              type="password"
              placeholder={t('パスワード')}
            />
          </InputWrapS>
          <InputWrapS>
            <NormalInput
              ref={confirmPasswordRef}
              name="confirm_password"
              type="password"
              placeholder={t('パスワード(確認用)')}
            />
          </InputWrapS>

          <OptionS>
            <GoogleRecaptchaWrapS>
              <GoogleRecaptcha />
            </GoogleRecaptchaWrapS>

            <TermsOfServiceS>
              <CheckBoxS
                name="terms_of_service"
                type="checkbox"
                onChange={checkTermsOfService}
              />
              <TermsOfServiceTextS>
                <Trans
                  i18nKey="利用規約に同意し登録する"
                  components={[
                    <TermsButtonS type="button" onClick={openTerms} />,
                  ]}
                />
              </TermsOfServiceTextS>
            </TermsOfServiceS>
          </OptionS>

          {isCheckedTos ? (
            <RoundButtonWrapS>
              <RoundButton
                onClick={pushToRegisterConfirm}
                background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                borderColor="rgba(0,135,167,1)"
              >
                {t('新規会員登録')}
              </RoundButton>
            </RoundButtonWrapS>
          ) : (
            <RoundButtonWrapS>
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {t('新規会員登録')}
              </RoundButton>
            </RoundButtonWrapS>
          )}
        </FormS>
      </FormLayout>
    </>
  );
};
export default Register;

/**
 *
 * Styles
 *
 */
const FormS = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;

  @media (min-width: 600px) {
    width: 60%;
    margin-top: 2.4rem;
  }

  @media (min-width: 1025px) {
    width: 50%;
    margin-top: 3rem;
  }
`;

const ErrorMessageWrapS = styled.div`
  width: 100%;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
  }
`;

const InputWrapS = styled.div`
  width: 100%;
  font-size: 1.4rem;
  margin-bottom: 1.8rem;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
    margin-bottom: 2.4rem;
  }
`;

const NoteS = styled.span`
  font-size: 1.2rem;
  color: #ff0000;
`;

const OptionS = styled.div`
  margin-bottom: 2rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 600px) {
    flex-direction: row;
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 4rem;
  }
`;

const GoogleRecaptchaWrapS = styled.div`
  width: 100%;
  margin-bottom: 1.8rem;

  @media (min-width: 600px) {
    width: 47%;
    margin-bottom: 0;
  }
`;

const TermsOfServiceS = styled.div`
  width: 100%;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 50%;
  }
`;

const TermsOfServiceTextS = styled.p`
  font-size: 1.2rem;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const TermsButtonS = styled.button`
  font-size: 1.2rem;
  color: #fff040;
  text-decoration: underline;
  padding-bottom: 0.5rem;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const CheckBoxS = styled.input.attrs({ type: 'checkbox' })`
  height: 15px;
  width: 15px;
  margin-right: 0.6rem;

  @media (min-width: 600px) {
    height: 25px;
    width: 25px;
    margin-right: 0.8rem;
  }

  @media (min-width: 1025px) {
    height: 20px;
    width: 20px;
  }
`;

const RoundButtonWrapS = styled.div`
  width: 65%;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;
