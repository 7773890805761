// External library
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useClear } from 'hooks/versatile/useClear';
// Utils
import { STATUS_CODE } from 'utils/const/statusCode';
// Types
import { ApiErrorType } from 'types/api';

/**
 *
 * Types
 *
 */
type ErrorHandler = (response: AxiosResponse<ApiErrorType> | undefined) => string;

type DisplayErrorMessageInToast = (error: unknown) => void;

type UseError = () => {
  errorHandler: ErrorHandler;
  displayErrorMessageInToast: DisplayErrorMessageInToast;
};

/**
 *
 * Hooks
 *
 */
export const useError: UseError = () => {
  // Hooks
  const history = useHistory();
  const { t } = useTranslation();
  const { clearUserState } = useClear();

  // ステータスコードに応じて処理を実行
  const errorHandler: ErrorHandler = (response) => {
    const {
      LOGIN_CHECK_ERROR, // 未ログイン
      VALIDATION_ERROR, // バリデーションエラー
      NOT_FOUND, // Not found
      SERVER_ERROR, // サーバーエラー
    } = STATUS_CODE;

    let result = '';
    if (response === undefined) {
      result = t('通信エラー');
    } else {
      const {
        status,
        data: { message },
      } = response;

      switch (status) {
        case LOGIN_CHECK_ERROR:
          clearUserState();
          break;
        case NOT_FOUND:
          history.replace('/404');
          break;
        case SERVER_ERROR:
          result = t('サーバーエラー');
          break;
        case VALIDATION_ERROR:
        default:
          result = message || '';
      }
    }

    return result;
  };

  // React-Toastifyでエラー内容表示
  const displayErrorMessageInToast: DisplayErrorMessageInToast = (error) => {
    // Axios error
    if (axios.isAxiosError(error)) {
      const result = errorHandler(error.response);
      if (result) toast.error(result);
    // Common error
    } else if (error instanceof Error) {
      toast.error(error.message);
    }
  };

  return {
    errorHandler,
    displayErrorMessageInToast,
  };
};
