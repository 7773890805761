import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';
// Utils
import { devices } from 'utils/const/devices';
// Images
import neonBarTopSp from 'assets/images/neonItem/bar-tiplist-top_sp.png';
import neonBarBottomSp from 'assets/images/neonItem/bar-tiplist-bottom_sp.png';

/**
 * Types
 */
type Props = {
  silverText: string;
  children: ReactNode;
};

/**
 *
 * Component
 *
 */
// 仕組みページ エントリーの流れ用コンポーネント
const StepBox: FC<Props> = memo((props) => {
  const { silverText, children } = props;

  return (
    <StepBoxS>
      <NeonBarS>
        <NeonBarImageS src={neonBarTopSp} alt="" loading="lazy" />
      </NeonBarS>
      <BoxS>
        <BoxContentS>
          <SilverTextWrapS>
            <SilverTextS>{silverText}</SilverTextS>
          </SilverTextWrapS>
          {children}
        </BoxContentS>
      </BoxS>
      <NeonBarS>
        <NeonBarImageS src={neonBarBottomSp} alt="" loading="lazy" />
      </NeonBarS>
    </StepBoxS>
  );
});
export default StepBox;

/**
 *
 * Styles
 *
 */
const { tabletPortrait, tabletLandscape, pc } = devices;

const StepBoxS = styled.div`
  width: 100%;
`;

const NeonBarS = styled.div`
  width: 100%;
`;

const NeonBarImageS = styled.img`
  width: 100%;
`;

const BoxS = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  padding: 2rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    border-left: 0.1px solid rgba(220, 220, 220, 0.3);
    border-right: 0.1px solid rgba(220, 220, 220, 0.3);
  }
`;

const BoxContentS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    flex-direction: unset;
  }
`;

const SilverTextWrapS = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    width: 20%;
    margin-bottom: 0;
  }
`;

const SilverTextS = styled.p`
  font-size: 2.6rem;
  font-weight: bold;
  word-break: break-all;
  color: transparent;
  background: linear-gradient(
    0deg,
    #cfd4de,
    #8390aa,
    #fcfcfd,
    #e0e3e9,
    #ccd1dc
  );
  background-clip: text;
  -webkit-background-clip: text;

  @media ${tabletLandscape}, ${pc} {
    font-size: 3rem;
  }
`;
