import { selector } from 'recoil';
import { userState } from 'store/atoms/userState';
import { SELECTOR_KEYS } from 'utils/const/recoilKeys';

export const userBetsCountDisplayState = selector<number>({
  key: SELECTOR_KEYS.USER_BETS_COUNT_DISPLAY_STATE,
  get: ({ get }) => {
    const { unlotteryBetsCount } = get(userState);

    return unlotteryBetsCount < 100 ? unlotteryBetsCount : 99;
  },
});
