import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { ATOM_KEYS } from 'utils/const/recoilKeys';
import { TOP_VIDEO } from 'utils/const/video';
import { TopVideoUrlType } from 'types/video';

// 永続化用Recoilプラグイン
const { persistAtom } = recoilPersist();

export const topVideoState = atom<TopVideoUrlType>({
  key: ATOM_KEYS.TOP_VIDEO_STATE,
  default: TOP_VIDEO.VIDEO_EN,
  effects_UNSTABLE: [persistAtom],
});
