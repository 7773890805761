import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import CompletionLayout from 'components/templates/CompletionLayout';
import SuccessMessage from 'components/atoms/text/SuccessMessage';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
// Custom hooks
import { useEmailVerified } from 'hooks/pages/guest/useEmailVerified';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const EmailVerified: FC = () => {
  // i18-next
  const { t } = useTranslation();
  // メール認証用カスタムフック
  const {
    isLoading, successMessage, errorMessage, postApiVerified,
  } = useEmailVerified();

  useEffect(() => {
    // メール認証リクエスト
    postApiVerified();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.emailVerified')}</title>
        <meta name="description" content={t('description.emailVerified')} />
      </Helmet>
      <CompletionLayout
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title="メール認証"
        to="/login"
        linkText="ログインページへ"
      >
        {/* サクセスメッセージ */}
        {successMessage && (
          <MessageS>
            <SuccessMessage>{successMessage}</SuccessMessage>
          </MessageS>
        )}

        {/* エラーメッセージ */}
        {errorMessage && (
          <MessageS>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </MessageS>
        )}

        {/* ローディングアニメーション */}
        {isLoading && <LoaderWindow />}
      </CompletionLayout>
    </>
  );
};
export default EmailVerified;

/**
 *
 * Styles
 *
 */
const MessageS = styled.div`
  width: 100%;
  margin-bottom: 4rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  word-break: break-word;

  @media (min-width: 600px) {
    font-size: 2rem;
    width: 60%;
  }

  @media (min-width: 1025px) {
    width: 50%;
  }
`;
