import { FC, memo, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import FormLayout from 'components/templates/FormLayout';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import RoundButton from 'components/atoms/button/RoundButton';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { useAccountDeleteConfirm } from 'hooks/pages/private/useAccountDeleteConfirm';
// Utils
import { actDleConfirmBreadCrumbItems as breadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const AccountDeleteConfirm: FC = memo(() => {
  // hooks
  const { t } = useTranslation();
  const {
    isLoading, errorMessage, cleanUpMethod, postApiUsersDelete,
  } = useAccountDeleteConfirm();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    return cleanUpMethod;
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.accountDeleteConfirmation')}</title>
        <meta name="description" content={t('description.accountDeleteConfirmation')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={breadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('アカウントの停止')}
        titleStyle="silver"
      >
        {
          // ローディング
          isLoading && <LoaderWindow />
        }

        <TextWrapS>
          <TextS>
            {t(
              'アカウント情報、所持チケット、所持ポイント等が失われます。確認してから「停止」ボタンを押してください。',
            )}
          </TextS>
        </TextWrapS>

        {errorMessage && (
          // エラーメッセージ
          <ErrorMessageWrapS>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </ErrorMessageWrapS>
        )}

        <RoundButtonWrapS className="button-confirm">
          {isLoading ? (
            <RoundButton
              background="#555555"
              borderColor="rgba(230, 230, 230, 1)"
            >
              {t('停止')}
            </RoundButton>
          ) : (
            <RoundButton
              onClick={postApiUsersDelete}
              background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
              borderColor="rgba(219,0,177,1)"
            >
              {t('停止')}
            </RoundButton>
          )}
        </RoundButtonWrapS>
        <NoticeTextWrapS>
          <TextS>{t('※この操作は取り消しができません。')}</TextS>
        </NoticeTextWrapS>
      </FormLayout>
    </>
  );
});
export default AccountDeleteConfirm;

/**
 *
 * Styles
 *
 */
const TextWrapS = styled.div`
  width: min(100%, 300px);
  margin-top: 3rem;
  word-break: break-word;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: min(60%, 420px);
    margin-top: 2.4rem;
  }

  @media (min-width: 1025px) {
    margin-top: 3rem;
    width: 50%;
  }
`;

const NoticeTextWrapS = styled(TextWrapS)`
  margin-top: 1.6rem;
`;

const TextS = styled.p`
  color: #ffffff;
  font-size: 1.4rem;
  line-height: 1.5;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }
`;

const RoundButtonWrapS = styled.div`
  font-size: 1.4rem;
  width: min(70%, 200px);
  margin-top: 3rem;

  @media (min-width: 600px) {
    width: min(30%, 200px);
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
    margin-top: 5rem;
  }
`;

const ErrorMessageWrapS = styled.div`
  font-size: 1.6rem;
  line-height: 1.5;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    width: 50%;
    margin-top: 4rem;
  }
`;
