import {
  useState,
  useEffect, ChangeEvent, useRef, RefObject,
} from 'react';
// External library
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Types
import {
  ApiErrorType, CreditPayment, PostPaymentType, RateSaveType, RateType,
} from 'types/api';
// Utils
import { axiosCancelToken, axiosClient } from 'utils/axios';
import { CRYPTCURRENCY, MAXIMUM_PURCHASE_CREDIT, MINIMUM_PURCHASE_POINT } from 'utils/const/payment';

/**
 *
 * Types
 *
 */
type UsePointPurchaseCredit = () => {
  isLoading: boolean;
  displayedCommission: string;
  pointInputProps: InputProps;
  point: string;
  needUsd: number;
  cid: string;
  encodedMail: string;
  prc: string;
  url1: string;
  url2: string;
  user: string;
  rateCc: number;
  rateJpy: number;
  accountHolderErrorMessage: string;
  lessPointErrorMessage: string;
  goBack: () => void;
  onClickPurchase: () => void;
  formRef: RefObject<HTMLFormElement>;
}

// 仮想通貨種別
type CryptocurrencyType = typeof CRYPTCURRENCY[number];
type CurrencyRates = {[key in CryptocurrencyType]: number}

// 購入方法
type PurchaseType = 'btc' | 'usdt' | 'eth' | 'credit' | 'jpy';

type OnChangeEvent = (event: ChangeEvent<HTMLInputElement>) => void;

type InputProps = {
  type: string;
  value: string;
  onChange: OnChangeEvent;
  onBlur?: () => void;
};

/**
 *
 * ポイント購入（クレジット）ページ用カスタムフック
 *
 */
export const usePointPurchaseCredit: UsePointPurchaseCredit = () => {
  const formRef = useRef<HTMLFormElement>(null);

  // ステート一覧
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<CryptocurrencyType>('BTC');
  const [rateCc, setRateCc] = useState<number>(0);
  const [rateJpy, setRateJpy] = useState<number>(0);
  const [rateUsd, setRateUsd] = useState<number>(0);
  const [point, setPoint] = useState<string>(MINIMUM_PURCHASE_POINT);
  const [purchase, setPurchase] = useState<PurchaseType>('jpy');
  const [displayedCommission, setDisplayedCommission] = useState('');

  const [needUsd, setNeedUsd] = useState(0);
  const [cid, setCid] = useState('');
  const [encodedMail, setEncodedMail] = useState('');
  const [prc, setPrc] = useState('');
  const [url1, setUrl1] = useState('');
  const [url2, setUrl2] = useState('');
  const [user, setUser] = useState('');

  const history = useHistory();
  const goBack = () => history.push('/point_purchase');
  const goToPointPurchaseTopPage = () => history.push('/point_purchase');

  // エラー用カスタムフック
  const { displayErrorMessageInToast } = useError();

  // 購入ポイントが最低購入ポイント数未満かどうかのチェック関数
  const checkIsLessPoint = () => Number(point) < Number(MINIMUM_PURCHASE_POINT);
  const checkOverMaxCredit = () => purchase === 'credit' && Number(rateUsd) >= Number(MAXIMUM_PURCHASE_CREDIT);

  const ccRateValuesRef = useRef<CurrencyRates>({
    BTC: 0,
    USDT: 0,
    ETH: 0,
  });

  // レートデータリクエスト
  const rateSource = axiosCancelToken.source();
  const postApiRate = () => {
    if (checkIsLessPoint()) {
      return;
    }

    if (!isLoading) {
      setIsLoading(true);

      // axios POST /api/rate
      axiosClient
        .post<RateType>('/api/rate', {
          point,
          cancelToken: rateSource.token,
        })
        .then(({
          data: {
            BTC, USDT, ETH, USD, jpy, commission,
          },
        }) => {
          // 選択した仮想通貨によってstateの値切り替え
          ccRateValuesRef.current = { BTC, USDT, ETH };
          switch (selectedCurrency) {
            case 'BTC':
              setRateCc(ccRateValuesRef.current.BTC);
              break;
            case 'USDT':
              setRateCc(ccRateValuesRef.current.USDT);
              break;
            case 'ETH':
              setRateCc(ccRateValuesRef.current.ETH);
              break;
            default:
          }
          setRateUsd(USD);
          setRateJpy(jpy);
          setDisplayedCommission(commission);
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          displayErrorMessageInToast(error);
          setPoint('0');
          setRateCc(0);
          setRateJpy(0);
          setRateUsd(0);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const checkInputPoint: OnChangeEvent = (event) => {
    const { value } = event.currentTarget;
    // 半角数字以外の場合は空文字に
    if (!/^\d+$/.exec(value)) {
      setPoint('0');
    } else {
      setPoint(value);
    }
  };

  const pointInputProps: InputProps = {
    type: 'text',
    value: point,
    onChange: checkInputPoint,
    onBlur: postApiRate,
  };

  const [
    accountHolderErrorMessage,
    setAccountHolderErrorMessage,
  ] = useState('');

  // API レート保存
  const rateSaveSource = axiosCancelToken.source();
  const postApiRateSave = (payment_type: PostPaymentType) => (
    axiosClient
      .post<RateSaveType>('/api/rate/save', {
        point,
        payment_type,
        cancelToken: rateSaveSource.token,
      })
  );

  // API クレジットカード決済パラメーター取得
  const creditPaymentSource = axiosCancelToken.source();
  const getApiCreditPayment = (transactionId: string, usd: number) => (
    axiosClient
      .get<CreditPayment>(
        `/api/credit/payment?need_usd=${usd}&transaction_id=${transactionId}`,
        { cancelToken: creditPaymentSource.token },
      )
  );

  const [
    lessPointErrorMessage,
    setLessPointErrorMessage,
  ] = useState('');

  // 購入ボタン押下
  const onClickPurchase = async () => {
    setAccountHolderErrorMessage('');
    setLessPointErrorMessage('');

    if (checkIsLessPoint()) {
      setLessPointErrorMessage('ポイントが不足しています');

      return;
    }
    if (checkOverMaxCredit()) {
      setLessPointErrorMessage('クレジットカード利用可能上限を超えています');

      return;
    }

    try {
      setIsLoading(true);

      const { data: rateSave } = await postApiRateSave('USD');
      const { data: creditPayment } = (
        await getApiCreditPayment(rateSave.transaction_id, rateSave.need_usd)
      );
      setNeedUsd(rateSave.need_usd);
      setCid(creditPayment.cid);
      const encoded = encodeURIComponent(creditPayment.mail).replace('%40', '@');
      setEncodedMail(encoded);
      setPrc(creditPayment.prc);
      setUrl1(creditPayment.url1);
      setUrl2(creditPayment.url2);
      setUser(creditPayment.user);

      formRef?.current?.submit();
    } catch (e) {
      displayErrorMessageInToast(e);
      setIsLoading(false);
    }
  };

  const cleanup = () => {
    setIsLoading(false);
    setPoint('0');
    setSelectedCurrency('BTC');
    setRateCc(0);
    setRateJpy(0);
    setRateUsd(0);
    setPurchase('jpy');
    rateSource.cancel();
    rateSaveSource.cancel();
    window.removeEventListener('popstate', goToPointPurchaseTopPage, false);
  };
  useEffect(() => {
    window.addEventListener('popstate', goToPointPurchaseTopPage, false);

    window.scrollTo({
      top: 0,
    });

    postApiRate();

    return cleanup;
  }, []);

  return {
    isLoading,
    displayedCommission,
    pointInputProps,
    point,
    needUsd,
    cid,
    encodedMail,
    prc,
    url1,
    url2,
    user,
    rateCc,
    rateJpy,
    accountHolderErrorMessage,
    lessPointErrorMessage,
    goBack,
    onClickPurchase,
    formRef,
  };
};
