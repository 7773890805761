import { zhCnSeo } from 'utils/lang/seo/zhCnSeo';

/**
 * 中文（簡体）
 */
export const zh_cn = {
  // メタ情報
  ...zhCnSeo,
  // ページ
  トップ: '首页',
  新規会員登録: '新会员注册',
  入力内容の確認: '确认填写的内容',
  メール認証: '邮件认证',
  ログイン: '登录',
  パスワードのリセット: '重置密码',
  パスワードの再設定: '重置密码',
  マイページ: '我的页面',
  アカウントの停止: '帐号暂停',
  メールアドレスの変更: '更改电子邮箱',
  'ポイント出金・交換': '点数提现、兑换',
  KYC書類アップロード: 'KYC 文件上传',
  "Day Lott'sとは": "什么是Day Lott's",
  仕組み: '系统',
  よくある質問: '常见问题',
  ランキング: '排行',
  サポート: '支持',
  お知らせ: '通知',
  言語: '语',
  'NEWSはありません。': '没有通知。',
  '高額獲得者はいません。': '没有大赢家。',
  '業界最高水準である、当選確率1/8も用意されているオンラインゲームです。還元率は75%!! 1ゲーム5Pからゲーム参加可能。5Pから10,000Pの当選も毎日開催されています。':
    '业界最高标准，中奖率1/8的线上游戏。回报率高达75％！！最低仅需5P即可参加游戏。每天的奖金从5P到10,000P不等。',
  "1day,1million,1richをテーマにDay Lott'sは運営をしています。毎日世界中のどこかでミリオネアがDay Lott'sから誕生しています。我々のサービスは、1日1,000,000Pの当選を目指しています。":
    "Day Lott's的经营主旨是1day，1million，1rich。在世界的某个地方每天都有百万富翁从Day Lott's诞生。我们的服务目标是每天赢得1,000,000P.",
  "Day Lott'sでは、入金、出金の際に仮想通貨を利用することができます。仮想通貨の利用により、ボーダーレスに、そしてスピードのある入出金が可能となっております。":
    "在Day Lott's，您可以使用虚拟货币进行充值和提现。使用虚拟货币可以实现无国界和快速充值提现。",
  'メールアドレスを登録フォームに入力し、会員登録を行います。':
    '在注册表中输入您的电子邮箱，即可注册成为会员。',
  会員登録: '会员注册',
  '入金・チャージ': '充钱、充值',
  '仮想通貨、振込など様々な方法でポイントをチャージすることができます。仮想通貨、振込など様々な方法でポイントをチャージすることができます。':
    '您可以通过、虚拟货币和银行转账等多种方式充值点数。',
  チケット購入: '购票',
  'ゲームに参加するためにチケットを、ポイントで購入します。':
    '您可以用点数购票参与游戏。',
  当選の確定: '确认中奖',
  'ゲームにエントリーをし、ゲームが開始されたら、すぐに当選の確定が分かります。当選確定の演出もお楽しみください！':
    '只要进入并开始游戏，您立刻就能知道自己是否中奖。确认中奖后将会有表演出现。',
  '当選が確定したら、即座にマイページに当選ポイントが反映されます。また、そのポイントを換金して出金することができます。':
    '确认中奖后，中奖点数会立即显示在MY PAGE上。您还可以把点数换成钱进行提现。',
  // フッター
  利用規約: '使用条款',
  お問い合わせ: '联系我们',
  利用規約に同意し登録する: '同意<0>使用条款</0>并开始注册',
  '※ユーザー名は後から変更をすることはできません。': '※用户名称以后不能更改。',
  '※パスワードは大文字、小文字、数字を組み合わせて8文字以内で入力してください。': '※密码必须是大、小写字母和数字的组合，不超过8个字符。',
  プライバシーポリシー: '隐私政策',
  責任あるギャンブル: '有责任的赌博',
  "DayLott'sとこのウェブサイトは、Seven Eyes Ent. Company（会社番号227777/076/077および登録住所Sheetal Marg, Baluwater Kathmandu, Nepal）によって運営されています。 Seven Eyes Ent. Companyはネパール政府より発行された、Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010に基づき付与されたサブライセンスを通して正式な許可を保持しています。":
    "DayLott's and this website are operated by Seven Eyes Ent. Company. (Company number 227777/076/077 and registered address Sheetal Marg, Baluwater Kathmandu, Nepal) Seven Eyes Ent. Company holds a formal license through a sub-license granted under Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010 issued by the Government of Nepal. Seven Eyes Ent.",
  プレイヤー保護について: 'About Player Protection',
  責任のあるギャンブルポリシー: 'Responsible Gambling Policy',
  '1 .未成年者の保護': '1 .Protection of minors',
  '2 .プレイのルール': '2 .Rules of Play',
  '3 .結託': '3 .Collusion',
  '4 .カスタマーサービスと顧客からの苦情':
    '4 .Customer Service and Customer Complaints',
  '4.1.カスタマーサービス': '4.1.Customer Service',
  '4.2.苦情の提出': '4.2.Submission of Complaints',
  "4.3.DayLott'sとの書面による連絡に含める必要がある情報":
    "4.3.Information You Must Include in Your Written Communication with DayLott's",
  '1 .ギャンブルの自己評価': '1 .Gambling self-assessment',
  '2 .サポート組織': '2 .Support Organizations',
  '3 .コントロールの維持': '3 .Maintaining control',
  '3.1.自己排除': '3.1.Self-Exclusion',
  "インターネットはモバイル機器や世界中の家庭から簡単にアクセスできるため、責任のあるオンラインギャンブルは親の管理と監督に大きく依存しています。インターネット上での子供の安全を確保するため、DayLott'sは未成年者が不適切なオンライン資料にアクセスするのを防止するフィルタリングソフトウェアを使用するようにお客様に推奨します。DayLott'sでは、保護者に対して次のヒントを提供しています。":
    "Because the Internet is easily accessible from mobile devices and homes around the world, responsible online gambling relies heavily on parental control and supervision. To ensure the safety of children on the Internet, DayLott's encourages its customers to use filtering software that prevents minors from accessing inappropriate online material. DayLott's offers the following tips to parents",
  '自分のメールアドレスを安全に保つ。':
    'Keep your email address and password secure.',
  "自分のDayLott'sアカウントにログインしたときにコンピューターを無人で放置しない。":
    "Do not leave your computer unattended when you log in to your DayLott's account.",
  '18歳未満の人がいかなるギャンブル活動への参加も許可しない。':
    'Do not allow anyone under the age of 18 to participate in any gambling activity.',
  '自分のクレジット/デビットカードおよびその他の金融口座の詳細を、子供の手の届かないところに保管する。':
    'Keep your credit/debit card and other financial account details out of the reach of children.',
  'パスワードをコンピューターに保存しない。パスワードを書き留め、未成年者から離れた安全な場所に保管する。':
    'Do not store passwords on your computer. Write down passwords and keep them in a safe place away from minors.',
  "共有コンピューターからDayLott'sにアクセスする場合、特にコンピューターを子供と共有する場合は、「記憶する」機能を使用しない。":
    'When accessing DayLott\'s from a shared computer, especially if the computer is shared with children. Do not use the "remember" function when accessing DayLott\'s from a shared computer, especially if the computer is shared with children.',
  '未成年のギャンブルによって引き起こされる違法性と潜在的な損害について、自分の子供たちに教育する。':
    'Educate your children about the illegality and potential damage caused by underage gambling.',
  '未成年者によるすべての電子機器ならびにモバイルデバイスの使用には特に注意する。':
    'Be especially careful about the use of all electronic and mobile devices by minors.',
  '自分の子供がギャンブルサイトを含む不適切な題材にアクセスするのを防ぐために、フィルタリングソフトウェアを活用する。':
    'Use filtering software to prevent your children from accessing inappropriate material, including gambling sites.',
  "未成年者のギャンブルは違法であるだけでなく、監督されていない子供が負った早期のギャンブル依存と実質的なギャンブルでの借金につながる可能性があります。したがって、自分の携帯電話、タブレット、ラップトップまたはコンピューターを法定年齢に満たない友人または家族と共有してオンラインギャンブルに参加する場合、DayLott'sは以下のいずれかのサービスを使用して、ウェブサイトへのアクセスを制限することを推奨します。":
    "Gambling by minors is not only illegal, but can lead to early gambling addiction and substantial gambling debts incurred by unsupervised children. Therefore, if you share your cell phone, tablet, laptop or computer with a friend or family member who is under the legal age to participate in online gambling, DayLott's recommends that you restrict their access to the website by using one of the following services.",
  'Netnanny.com - 不適切なウェブコンテンツから子供を守るフィルタリングソフトウェア。':
    'Netnanny.com - Filtering software that protects children from inappropriate web content.',
  'Cybersitter.com - 保護者がブロックするサイトを自身で追加することができるフィルタリングソフトウェア。':
    'Cybersitter.com - Filtering software that allows parents to add their own sites to block.',
  'betterinternetforkids.eu - EUが共同出資し、インターネットフィルタソフトウェアに関する情報を提供し、無料のテスト版を提供するウェブサイト。':
    'betterinternetforkids.eu - A website co-sponsored by the EU that provides information about internet filter software and provides free test versions.',
  その他の重要なヒント: 'Other Important Tips',
  'ギャンブルをする決定は自分の個人的な選択であることを確認してください。次の場合は、ギャンブルをすることはおすすめしません。':
    'Make sure that your decision to gamble is your own personal choice. Gambling is not recommended in the following cases.',
  '毎日の責務を妨げる場合。': 'It interferes with your daily responsibilities.',
  '依存症の治療をしている、あるいは回復している場合。':
    'You are in treatment for or recovering from an addiction.',
  'アルコールやその他の物質の影響を受けている場合。':
    'You are under the influence of alcohol or other substances.',
  '以前の損失を取り戻そうとしている場合。あるいは賞金で借金を回収することが主な目的となっている場合。':
    'If you are trying to recover your previous loss. Or when the main goal is to collect the debt with your winnings.',
  "DayLott'sでゲームを始める前に、ゲームのルールを知ることをおすすめします。":
    "Before starting a game in DayLott's, it is recommended to know the rules of the game.",
  '顧客が他の顧客と結託していると思われる場合や、不正行為を行った場合は、直ちにカスタマーサービスにご連絡ください。':
    'If you suspect that a customer is colluding with another customer or has committed any fraudulent act, please contact Customer Service immediately.',
  'お客様はメールでカスタマーサービスにいつでも連絡することができます。':
    'Customers can contact customer service at any time via email.',
  "いかなる場合でも問題が発生してから3か月以内に、顧客の苦情/申し立てを提出する必要があります。お客様の苦情/申し立てが正確な部署に向けられ、調査されていることを確実にするために、以下の手段を介して書面による連絡をDayLott'sに提出しなければなりません。":
    "In any case, customer complaints/allegations must be submitted within three months of the problem occurring. In order to ensure that the customer's complaint/allegation is directed to the correct department and investigated, written communication must be submitted to DayLott's via the following means",
  電子メール: 'Email',
  "お客様のプライバシーを保護するために、お客様とDayLott's間のすべての電子メールによる通信は、DayLott'sで保持されているお客様の顧客アカウントに登録したメールアドレスを使用して実行する必要があります。そうでない場合、当社の対応が遅れることがあります。DayLott'sとの(苦情を含む) あらゆる書面による連絡には、以下の情報を含める必要があります。":
    "In order to protect your privacy, all email communications between you and DayLott's must be executed using the email address registered in your customer account maintained with DayLott's. Otherwise, our response may be delayed. Any written communication with DayLott's (including complaints) must include the following information.",
  'お客様のニックネーム/電話番号': 'Your nickname/phone number',
  お客様の顧客アカウントに登録済みの氏名:
    'Your name as it appears in your customer account',
  '苦情/申し立ての詳細': 'Details of the complaint/allegation',
  '苦情/申し立てに関連する特定の日付と時刻 (該当する場合)':
    'The specific date and time related to the complaint/allegation (if applicable)',
  "DayLott'sでは、お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。":
    "At DayLott's, we are committed to providing you with the most enjoyable gambling experience possible, but we also recognize that gambling can cause problems for some people. To ensure that you can continue to enjoy safe and manageable play, we support responsible gambling and have put in place a number of measures to assist you.",
  'ギャンブルによりお客様自身 (またはその他の人) の人生が支配されている可能性があると懸念する場合、次の質問で判明することがあるかもしれません。':
    "If you are concerned that gambling may be taking control of your (or others') life, the following questions may help you find out.",
  'つまらない日常や不幸な人生を忘れるためにギャンブルをしますか？':
    'Do you gamble to forget about your boring or unhappy life?',
  'ギャンブルをしていてお金が足りなくなったら、喪失感を味わい絶望を感じ、できるだけ早く再びギャンブルをする必要がありますか？':
    'If you are gambling and run out of money, do you feel lost and hopeless and need to gamble again as soon as possible?',
  '最後の1円がなくなるまでギャンブルをしますか？それが、たとえば家への交通費や生活費だとしてもですか？':
    'Do you gamble until your last penny is gone? Would you gamble until your last penny was gone, even if it was for, say, transportation home or living expenses?',
  'ギャンブルに費やした金額や時間の嘘をついてごまかしたことがありますか？':
    'Have you ever lied and cheated about the amount of money or time you spent on gambling?',
  'ギャンブルが原因で家族、友人、趣味への興味を失ったことがありますか？':
    'Have you ever lost interest in your family, friends, or hobbies because of gambling?',
  '負けた後、できるだけ早くもう一度挑戦して損失を勝たなければならないと感じますか？':
    'After losing, do you feel compelled to try again as soon as possible to win your losses?',
  '議論、不満、失望によりギャンブルをしたくなりますか？':
    'Do arguments, frustrations, or disappointments make you want to gamble?',
  'ギャンブルをすることが原因で落ち込む、あるいは自分自身に害を及ぼすと感じることがありますか？':
    'Do you feel depressed or harm yourself because of gambling?',
  'これらの質問に "はい" という答えが多いほど、深刻なギャンブルの問題を抱えている可能性が高くなります。':
    'The more "yes" answers you give to these questions, the more likely you are to have a serious gambling problem.',
  'この問題について相談するには、カスタマーサービスに連絡するか、無料で独立したアドバイスを提供する数々のサポート組織があります。ご相談希望の方は、自国のサポート組織・機関に問い合わせをして下さい。':
    'To discuss this issue, you can contact customer service or one of the many support organizations that offer free, independent advice. Please contact the support organization in your country if you would like to discuss your problem.',
  'お客様あるいは知り合いの方がギャンブルの問題を抱えている場合、認定された組織からの支援を検討することをおすすめします。':
    'If you or someone you know has a gambling problem, you may want to consider getting help from an accredited organization.',
  "DayLott'sでは、お客様にギャンブルアカウントの管理に積極的に関与することを推奨しています。そのために、当社はさまざまな責任のあるゲーミング機能を提供しています。":
    "DayLott's encourages our customers to take an active role in the management of their gambling accounts. To that end, we offer a variety of responsible gaming functions.",
  'もしお客様がギャンブルに対し問題が生じたと思われる場合には、カスタマーサービスへ連絡をし、アカウントへ自己排除期間を適用するように要請してください。':
    'If you believe that you have a problem with gambling, please contact Customer Service and request that a self-exclusion period be applied to your account.',
  以下の自己排除期間がご利用可能です:
    'The following self-exclusion periods are available',
  '1週間': '1 week',
  '2週間': '2 weeks',
  '1ヶ月': '1 month',
  '3ヶ月': '3 months',
  '6ヶ月': '6 months',
  "できる限り速やかに自己排除を適用するように努めますが、このプロセスにはある程度の作業時間が必要となりますのでご理解いただきますようお願いいたします。自己排除期間は実装されてお客様へ連絡されるまで、開始したものとはみなされませんのでご注意ください。もしその後も引き続きサービスにアクセス可能な場合には、責任をもってすぐ当社までご連絡ください。自己排除に入った時点でのDayLott'sの未確定ベットは、決済されます。お客様のベットが勝ちだった場合、勝ち金は該当する口座へ送金されます。自己排除期間が終了したあと、お客様のアカウントは自動的に再開されますのでご注意下さい。":
    "We will try to apply the self-exclusion as soon as possible, but please understand that this process may take some time. Please note that the self-exclusion period will not be considered to have started until you are notified. If you continue to have access to the service after that time, you are responsible for contacting us immediately. Any unsettled bets on DayLott's at the time of self-exclusion will be settled. If your bet is a win, your winnings will be transferred to the relevant account. Please note that your account will be automatically reopened after the self-exclusion period has expired.",
  "DayLott'sは開かれる可能性のある新しい重複アカウントを検出し、閉鎖するためにできる限りのことを常に行っております。自己排除が適用されると、いかなるマーケティング資料も受け取ることはなくなります。":
    "DayLott's will always do everything possible to detect and close any new duplicate accounts that may be opened. Once self-exclusion has been applied, you will no longer receive any marketing materials.",
  // フォーム
  メールアドレス: '电子邮箱',
  ユーザー名: '用户名',
  パスワード: '密码',
  'パスワード(確認用)': '密码（确认密码)',
  新しいパスワード: '新密码',
  '新しいパスワード(確認用)': '新密码（确认新密码）',
  ファイルを選択: '选择文件',
  'ドラッグ＆ドロップするかファイルを選択してください': '请拖放或选择一个文件',
  // ボタン
  修正する: '修改',
  送信する: '发送',
  再送する: '重新发送',
  確認する: '确认',
  登録する: '注册',
  入金する: '充值',
  出金する: '提现',
  受け取る: '接收',
  アップロード: '上传',
  削除: '削除',
  停止: '暂停',
  選択: '选择',
  送信: '提交',
  戻る: '返回',
  設定する: '设置',
  // トッページ
  さらに表示する: '查看更多',
  // 新規会員登録ページ
  '新規会員登録(タイトル)': '新会员注册',
  // 新規会員登録確認ページ
  '内容に誤りがないか、よくご確認ください。': '请确认填写的内容正确无误',
  // 新規会員登録完了ページ
  メールの送信が完了しました: '电子邮件已发送',
  '以下のメールアドレスに確認メールを送りました。メール記載のURLから登録を完了してください。':
    '我们已向以下电子邮箱发送了一封确认邮件。 请使用邮件中的链接来完成注册。',
  TOPに戻る: '返回TOP',
  // メール認証
  '認証に成功しました。': '认证成功。',
  ログインページへ: '转至登录页面',
  // ログイン
  'Bunny Paradise Worldのアカウントでもログインできますが、初回はパスワードのリセットが必要です。': '您也可以使用Bunny Paradise World帐户登录，但第一次需要重置密码。',
  'ログインができない、パスワードを忘れた方はこちら': '无法登录或忘记密码？',
  "DayLott'sのリンクをホーム画面に追加すると次回以降簡単にアクセスができます。": "将 DayLott's 的链接添加到您的主屏幕，以便下次轻松访问。",
  iPhoneの場合: '蘋果手機',
  '1. ブラウザ(Safari)でWEBページを開いた状態で、下部の「共有アイコン」をタップ。': '1. 在浏览器（Safari）中打开一个网页，然后点击底部的“共享图标”。',
  '2. 共有メニューに表示される「ホーム画面に追加」をタップ。': '2. 点击共享菜单上的“添加到主屏幕”。',
  '3. 名称などをご確認いただき、画面右上の「追加」をタップ。': '3.确认姓名等后点击屏幕右上角的“添加”。',
  '4. ホーム画面にアイコンが追加されたら完了です。': '4. 当图标添加到主屏幕时，您就完成了。',
  確認して閉じる: '确认并关闭',
  // パスワードリセット
  '登録済みのメールアドレスにパスワード再設定用のURLを送信します。':
    '我们将向您注册的电子邮箱发送一个重置密码的链接。',
  パスワードのリセットを申請する: '要求重置密码',
  '再送しました。': '已重新发送',
  // パスワードの再設定
  新しいパスワードを設定する: '设置新密码',
  '新しいパスワードを入力した後、「設定する」を押してください。':
    '输入新密码后，按“设置”。',
  // パスワードの再設定(完了)
  パスワードの変更が完了しました: '密码已更改。',
  // マイページ
  'ポイント購入履歴はありません。': '没有点数购买历史。',
  初回登録ボーナス: '初始注册奖金',
  入金ボーナス: '存款红利',
  紹介ボーナス: '推荐奖金',
  クレジット購入: '赊购',
  ビットコイン購入: '用比特币购买',
  振り込み購入: '以转账方式购买',
  テザー購入: '通过 Tether 购买',
  イーサリアム購入: '以太坊购买',
  'ポイント出金・交換履歴はありません。': '没有点数提现、购买历史。',
  'べット履歴はありません。': '没有投注历史。',
  '当選履歴はありません。': '没有中奖历史。',
  所持ポイント: '所持点数',
  生年月日: '出生日期',
  '※ポイントの出金・交換をする際にはKYC認証が必須となります。': '※提取或兑换积分时需要KYC认证。',
  未設定: '未设置',
  登録情報: '注册信息',
  KYC提出: '提交KYC',
  'ポイントデポジット出金・交換': '现有点数 提现、兑换',
  プレイ履歴: '历史',
  編集: '编辑',
  ログアウト: '登出',
  KYC確認中: 'KYC确认中',
  KYC却下: 'KYC被拒',
  書類のタイプを選択する: '选择您的证件类型',
  パスポート: '护照',
  免許証: '驾驶执照',
  // アカウントの停止(確認)
  'アカウント情報、所持チケット、所持ポイント等が失われます。確認してから「停止」ボタンを押してください。':
    '您将j会失去账户信息、所持点数和票。请在确认后点击“暂停”按钮。',
  '※この操作は取り消しができません。': '※该操作不能撤销',
  // アカウントの停止(完了)
  アカウント停止完了: '账户已暂停',
  'アカウントの停止が完了しました。ご利用いただきありがとうございました。':
    '帐户暂停已完成。感谢您使用它。',
  // メールアドレスの変更
  '入力したメールアドレスにメールアドレス再設定用のURLを送信します。':
    '我们将向您输入的邮箱地址发送更改电子邮箱的链接。',
  // KYC書類アップロード(フォーム)
  パスポートのアップロード: '上传护照',
  免許証のアップロード: '上传驾驶执照',
  年: '年',
  月: '月',
  日: '日',
  パスポートの写真をアップロード: '上传护照照片',
  '免許証 ( 表 ) の写真をアップロード': '上传驾驶执照照片（正面）',
  '免許証 ( 裏 ) の写真をアップロード': '上传驾驶执照照片（背面）',
  '1つ前に戻る': '返回上一级',
  '画像をアップロードしてください。': '请上传图像。',
  // KYC書類アップロード(確認)
  提出内容の確認: '确认提交内容',
  送信内容の確認: '确认发送内容',
  '以下の内容で送信します。よろしければ送信を押してください。':
    '将发送以下内容。请在确认内容正确无误后点击发送。',
  '免許証 ( 表 )': '驾驶执照（正面）',
  '免許証 ( 裏 )': '驾驶执照（背面）',
  // KYC書類アップロード(完了)
  本人確認書類送信完了: '身份验证文件已发送',
  '審査結果はメールにてお知らせいたします。今しばらくお待ちください。':
    '我们将通过电子邮件通知您审查结果。请耐心等待。',
  マイページへ戻る: '返回MY PAGE',
  // ポイント購入
  ポイント購入: '購買點數',
  ポイント: '点数',
  ドル: '美元',
  円: '日元',
  レートを確認する: '确认汇率',
  ボーナスポイント: '奖励点数',
  'ボーナスポイントは購入ポイント獲得時に付与されます。': '奖励积分将与购物积分一并发放。',
  購入方法を選択: '选择购买方式',
  仮想通貨: '虚拟货币',
  クレジット: '信用卡',
  現金振込: '现金转账',
  'ページを更新して再度お試しください。': '请刷新页面并重试。',
  'データを取得できませんでした。': '无法获取数据。',
  '最低購入ポイント数は10ポイントです。': '最低购买点数为10点。',
  'お名前(必須)': '您的姓名(必填)',
  '姓(カナ)': '姓氏(假名)',
  '名(カナ)': '名字(假名)',
  '全角カタカナで入力してください。': '请输入全角片假名。',
  '口座名義は入力必須です。': '必须输入帐户持有人。',
  // ポイント購入（仮想通貨）
  'レート表記はページにアクセスした時点のものです。入金時の数値とは異なる場合がございます。':
    '费率表示法是截至访问页面的时间。它可能与存款时的价值不同。',
  仮想通貨で入金: '用虚拟货币充值',
  送金アドレス: '汇款地址',
  アドレスを再取得: 'アドレスを再生成',
  'コピーしました。': '已复制。',
  'コピー失敗しました。': '复制失败。',
  // ポイント購入（クレジット）
  クレジットカードで入金: '用信用卡充值',
  有効期限: '有效期限',
  クレジットカード番号: '信用卡号码',
  カード名義人: '持卡人',
  支払い回数: '付款次数',
  一括払い: '一次付清',
  '3回払い': '分3次付清',
  '6回払い': '分6次付清',
  セキュリティコード: '安全码',
  '以上の内容でよろしければ「入金する」ボタンをクリックしてください。':
    '请确认以上信息无误后点击“充值”。',
  'お支払い手続きが完了いたします。': '这样就完成了支付过程。',
  // ポイント購入（現金）
  現金で入金: '现金充值',
  '支払額を以下の口座にお振込ください。':
    '请将付款金额转入以下账户。',
  依頼人名: '客户名称',
  銀行名: '银行名',
  支店名: '支店名',
  店番号: '店号',
  口座科目: '账户类型',
  口座番号: '账号',
  口座名義: '账户名',
  // ポイント購入完了（共通）
  入金情報送信完了: '已发送充值信息',
  '確認ができ次第メールにてお知らせいたします。今しばらくお待ちください。':
    '我们在确认后会通过邮件通知您。请耐心等待。',
  // ポイント出金・交換(トップ)
  '出金・交換ポイント数': '提款/兑换积分',
  '出金・交換をするポイント数を入力してください。': '请输入要提取/兑换的积分数。',
  ポイント残高: '剩余积分',
  '出金・交換方法を選択': '选择提款/兑换方式',
  '半角数字のみ入力してください。': '请仅输入半角数字。',
  '50ポイントから出金が可能です。': '提款可以从 50 点开始。',
  '入力ポイントが所持ポイントを超えています。': '输入点超过占有点。',
  '送金先アドレスを入力してください。': '请输入汇款地址。',
  // ポイント出金・交換(仮想通貨)
  送金手続き: '汇款手续',
  送金内容の確認: '确认汇款内容',
  送金額: '汇款金额',
  送金先アドレス: '汇款地址',
  '上記内容に間違いがないことを確認して送金をしてください。送金確定後の返金や操作の取り消しはできません。': '确认上述内容无误后进行提取或兑换。确认汇款后无法退款或取消操作。',
  // ポイント出金・交換(受付完了)
  '出金・交換を受け付けました。': '提取或兑换已被受理。',
  '取引に問題があった場合はメールにてご連絡を致します。またご不明な点がありましたらサポートまでお問い合わせください。': '交易出现问题时我们会通过邮件联系您。有任何疑问请联系客服。',
  // 履歴
  べット履歴: '投注历史',
  当選履歴: '中奖历史',
  ポイント購入履歴: '点数购买历史',
  'ポイント出金・交換履歴': '提款/兑换点',
  もっと見る: '查看更多',
  // DayLott'sとは
  "DayLott'sでは、毎日ミリオネアを排出する、世界初のゲームです。少額から大口まで、多彩なテーブルを用意することで、より多くの方にゲームを楽しんでもらうことができます。":
    '在Day lott’s之中每天都有許多百萬富翁誕生，可以說是世界首見的遊戲。我們準備了多樣化的遊戲平台，下注金額從小額到鉅款皆可，讓更多的人能夠享受遊戲的樂趣',
  '業界最高水準の75%！！世界で１番ユーザーが獲得できるゲームになるようにこだわって作りました。そしてその割合は常に一定です。ゲームにエントリーをした全てのユーザーに平等に獲得するチャンスがあります。':
    '行业领先75%！！我们一直痴迷于让它成为世界上拥有最多用户的游戏。而且百分比始终不变。每个进入游戏的用户都有平等的获胜机会。',
  'ビットコイン、テザー、イーサリアムでゲームを選ぶポイントを購入することができます。':
    '玩家可以透過比特幣、泰達幣、以太幣等購買可在遊戲內使用的點數。',
  '会員登録からエントリーまでたった3分で完了します。スマートフォンがあればいつでもどこでも気軽にゲームにエントリーをすることができます。':
    '從註冊會員到開始遊戲只要3分鐘。只要有智慧型手機不管在何處，都能夠輕鬆地進行遊戲。',
  入金はこちら: '入金はこちら',
  // 仕組み
  '世界初、必ず購入した誰かが高額当選、ミリオネアを誕生させる。':
    '世界上首个买入者中一定会有人高额中奖，成为百万富翁的游戏。',
  '世界最高水準還元率が驚異の75%を誇るオンラインゲームです。':
    '世界上最高的网络游戏回报率是惊人的 75%。',
  'エントリーするベットポイント、当選ポイントに応じたボックスが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステム。エントリーしたボックスのチケット購入数が満たされると即座に抽選が始まり、当選が確定します。世界一簡単な、世界一高額当選のできる世界一スピード感のあるオンラインゲームです。':
    '根据参与投注点数及中奖点数有会相应的包厢。系统规则简单，购票即可参与。进入的包厢的购票数量满足一定条件后立即开始抽奖，确定中奖用户。这是全球玩法最简单、中奖金额最高、速度最快的在线游戏。',
  '対応端末 iOS 14.8以降 Android 12.0以降':
    'Supported devices iOS 14.8 or later and Android 12.0 or later',
  'チケットを購入し、抽選ページにアクセス': '购买门票，进入抽奖页面',
  '抽選演出開始され、当選演出時にご自身で登録したユーザー名が枠内に止まりましたら当選確定':
    '抽奖表演开始，抽奖过程中当您登录的用户名停留在抽奖框内时，即为中奖',
  '当選確定後、当選ポイントが反映され出金が可能':
    '确定中奖后，显示中奖点数，并可提现',
  ご購入された誰かが必ず高額当選: '保证参与的客户中必定有人中得高额奖金',
  "ボックス毎に設定された高額当選ポイントが、ご購入された誰かに当選します。Day Lott'sではボックスに設定されている当選ポイントは必ずユーザーに反映されます。":
    '每个包厢设定的高额中奖点数，保证会有用户中得。在Day Lott\'s，包厢中设定的中奖点数保证会返奖给用户。',
  '安心・安全・透明性のある当選': '中奖的可靠、安全和透明性',
  "Day Lott'sでは、透明性のある運営を心がけています。全てのユーザーが当選したユーザーを確認することができます。また、個人情報の保護、入出金におけるセキュリティの強化をしているため、安全にプレイをして頂くことができます。":
    "Day Lott's一直致力于打造透明的运营环境。所有用户均可以查看中奖用户。此外，因为加强了个人信息的保护、存取款的安全性，所以您可以安全地参与游戏。",
  バリエーションのある当選ポイント設定: '多种多样的中奖积分设定',
  'ユーザーのニーズを満たしたプレイボックスを多く用意しています。小さなべットエントリーで高額当選ポイントを獲得することも可能です。最低5ポイントからエントリーが可能となっており、最大100ミリオンポイントの当選も実現します。':
    '为了满足用户的不同需求，我们准备了多种包厢。小额投注也可能获得高额中奖积分。最低5点即可参与，最高可获得1亿积分的中奖机会。',
  // よくある質問
  出金: '提现',
  'よくある質問はありません。': '没有常见问题。',
  // ランキング
  一日: '一天',
  週間: '一周',
  月間: '一个月',
  全期間: '所有时期',
  'ランキングはありません。': '没有排行',
  // サポート
  '当ページのよくある質問をまずはご覧ください。': '请先查看本页的常见问题。',
  メールでのお問い合わせ: '通过电子邮件联系我们',
  'までお問い合わせください。': '请将邮件发送至。',
  お問合せ時間: '咨询时间',
  '毎日対応させて頂いております。': '关于咨询时间，我们每天都向客户提供服务。',
  '※お問い合わせの回答に数日頂く事がございます。3営業日以内には回答させて頂きます。':
    '※回复您的咨询可能需要几天时间。我们将在3个工作日内回复。',
  // PLAYBOX
  予約状況: '预约情况',
  口: '份',
  ゼウス: '宙斯',
  キング: '王',
  ダイヤモンド: '钻石',
  ゴールド: '金',
  シルバー: '银',
  ブロンズ: '铜',
  アンバー: '琥珀',
  口数選択: '份数选择',
  購入する: '购买',
  キャンセル: '取消',
  ダイヤモンド獲得確率: '钻石获得概率',
  ゴールド獲得確率: '金获得概率',
  獲得確率: '中奖概率',
  獲得: '中奖',
  確率: '概率',
  口購入: '份买入',
  '所持ポイントが不足しています。': '账户中没有足够的点数。',
  '購入するにはログインが必要です。': '您必须登录后才能购买。',
  '1度に購入できるのは2000口までです。': '一次最多可购买 2000 份。',
  // チケット購入確認
  購入内容のご確認: '确认购买信息',
  '下記の内容で間違いないかご確認ください。': '请确认以下信息是否正确无误。',
  '「購入する」ボタンをクリックすると購入が確定します。':
    '点击”购买“确认购买。',
  参加ボックス: '参加Box',
  購入ポイント数: '购买点数',
  消費ポイント数: '消费点',
  所持有償ポイント数: '占有率付费积分',
  所持無償ポイント数: '占有率自由分',
  残り有償ポイント数: '剩余的付费点数',
  残り無償ポイント数: '剩余的免费积分',
  // チケット購入完了
  チケット番号: '票号',
  番: '号',
  '参加中のPLAY BOX': '正在参加的PLAY BOX',
  抽選画面へ: '转至抽奖画面',
  // お知らせ一覧
  '購入済みのチケットの抽選が開始されました。矢印をタップすると結果を見ることができます。':
    '您购买的票已经开始抽奖了。点击箭头查看结果。',
  'お客様のランクがアップされました。': '您的排名已升级。',
  キャンペーン: 'キャンペーン',
  表示を閉じる: '关闭显示',
  // お知らせ
  'お知らせはありません。': '没有通知。',
  抽選の結果を見る: '查看抽奖结果',
  抽選会場へ行く: '转到抽奖页面',
  マイページで確認: '在我的页面进行确认',
  // お問い合わせ
  お問い合わせフォーム: '联系我们',
  'お客様からのご質問をお問い合わせフォームにて受け付けております。':
    '您可以通过咨询表格向我们提问。',
  '下記項目をご記入の上、「確認する」を押してください。':
    '请检查以下各项后点击”确认“。',
  氏名: '全名',
  お問い合わせ内容: '咨询内容',
  // お問い合わせ確認
  お問い合わせ内容の確認: '确认咨询内容',
  // お問い合わせ受付完了
  'お問い合わせを受け付けました。': '我们已收到您的咨询。',
  'お問い合わせありがとうございます。': '感谢您的咨询。',
  'お問い合わせには3営業日以内に回答させて頂きます。':
    '我们将在3个工作日内回复您。',
  // 抽選結果
  'このページは動画が自動再生されます。よろしければ"OK"を押してください。動画を再生せずポイントを受け取る場合は"受け取る"を押してください。':
    '此页面将自动播放视频。如果您愿意，请按 "OK"。要在不播放视频的情况下获得积分，请单击“接收”。',
  Waiting: '等待',
  購入日時: '購買日期時間',
  当選ポイント: '中獎點數',
  // ポイント受け取り
  獲得ポイント受け取り: '获得积分',
  動画を再生せずに獲得した全てのポイントを受け取る: '在不播放视频的情况下获得所有积分。',
  'ポイントを受け取りました。': '收到的积分。',
  // 共通エラー
  通信エラー: '通信错误',
  サーバーエラー: '服务器错误',
  // バリエーションエラー
  'は入力必須です。': '为必填项。',
  'メールアドレスは正しい形式で入力してください。': '请输入正确的邮箱格式。',
  'ユーザー名は半角英数字のみで入力してください。':
    '请仅使用单字节字母数字字符输入用户名。',
  'パスワードは大文字小文字を含む半角英数字を組み合わせて入力してください。':
    '请输入包含大写和小写字母的单字节字母数字字符组合的密码。',
  'パスワードが一致しません。': '密码不一致。',
  '画像は.jpgか.pngをアップロードしてください。': '请上传.jpg或.png的图片。',
  '金額を入れてください。': '请输入金额。',
  '日本円での合計金額(手数料込)': '总金额（包括手续费）以日元计算',
  同意: '同意',
};
