// eslint-disable-next-line
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
// External library
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosCancelToken, axiosClient } from 'utils/axios';
// Types
import type { CachLocationState } from 'types/paymentType';
import {
  ApiErrorType,
  CachPayment,
  PointCampaignSelectType,
  PostPaymentType,
  RateSaveType,
  RateType,
} from 'types/api';
import { AxiosError } from 'axios';
import { CRYPTCURRENCY, MAXIMUM_PURCHASE_CREDIT, MINIMUM_PURCHASE_POINT } from 'utils/const/payment';

/**
 *
 * Types
 *
 */
type UsePointPurchaseCash = () => {
  point: string;
  goBack: () => void;
  isLoading: boolean;
  pointInputProps: InputProps;
  firstNameInputProps: InputProps;
  lastNameInputProps: InputProps;
  rateCc: number;
  rateJpy: number;
  onClickPurchase: () => void;
  accountHolderErrorMessage: string;
  lessPointErrorMessage: string;
  campaigns: PointCampaignSelectType[];
};

// 仮想通貨種別
type CryptocurrencyType = typeof CRYPTCURRENCY[number];
type CurrencyRates = {[key in CryptocurrencyType]: number}

// 購入方法
type PurchaseType = 'btc' | 'usdt' | 'eth' | 'credit' | 'jpy';

type OnChangeEvent = (event: ChangeEvent<HTMLInputElement>) => void;

type InputProps = {
  type: string;
  value: string;
  onChange: OnChangeEvent;
  onBlur?: () => void;
};

/**
 *
 * ポイント購入（現金）ページ用カスタムフック
 *
 */
export const usePointPurchaseCash: UsePointPurchaseCash = () => {
  // ステート一覧
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCurrency, setSelectedCurrency] = useState<CryptocurrencyType>('BTC');
  const [rateCc, setRateCc] = useState<number>(0);
  const [rateJpy, setRateJpy] = useState<number>(0);
  const [rateUsd, setRateUsd] = useState<number>(0);
  const [point, setPoint] = useState<string>(MINIMUM_PURCHASE_POINT);
  const [purchase, setPurchase] = useState<PurchaseType>('jpy');
  const [displayedCommission, setDisplayedCommission] = useState('');

  // ポイント購入ページへ遷移
  const history = useHistory();

  // API レート保存
  const rateSaveSource = axiosCancelToken.source();
  const postApiRateSave = (payment_type: PostPaymentType) => (
    axiosClient
      .post<RateSaveType>('/api/rate/save', {
        point,
        payment_type,
        cancelToken: rateSaveSource.token,
      })
  );

  // API 現金振込情報取得
  const cachPaymentSource = axiosCancelToken.source();
  const postApiPaymentRequest = (jpy: number, transactionId: string) => (
    axiosClient
      .post<CachPayment>(
        '/api/payment/request',
        {
          point,
          amount: jpy,
          transaction_id: transactionId,
          kana_last_name: lastName,
          kana_first_name: firstName,
          cancelToken: cachPaymentSource.token,
        },
      )
  );

  // 口座名義
  const [firstName, setFirstName] = useState('');
  const onChangeFirstName: OnChangeEvent = (event) => {
    setFirstName(event.currentTarget.value);
  };
  const firstNameInputProps: InputProps = {
    type: 'text',
    value: firstName,
    onChange: onChangeFirstName,
  };
  const [lastName, setLastName] = useState('');
  const onChangeLastName: OnChangeEvent = (event) => {
    setLastName(event.currentTarget.value);
  };
  const lastNameInputProps: InputProps = {
    type: 'text',
    value: lastName,
    onChange: onChangeLastName,
  };
  const [
    accountHolderErrorMessage,
    setAccountHolderErrorMessage,
  ] = useState('');

  const [
    lessPointErrorMessage,
    setLessPointErrorMessage,
  ] = useState('');

  const goBack = useCallback(() => {
    history.push('/point_purchase');
  }, []);
  // i18next
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  // エラー用カスタムフック
  const { displayErrorMessageInToast } = useError();

  // 購入ポイントが最低購入ポイント数未満かどうかのチェック関数
  const checkIsLessPoint = () => Number(point) < Number(MINIMUM_PURCHASE_POINT);
  const checkOverMaxCredit = () => purchase === 'credit' && Number(rateUsd) >= Number(MAXIMUM_PURCHASE_CREDIT);

  const ccRateValuesRef = useRef<CurrencyRates>({
    BTC: 0,
    USDT: 0,
    ETH: 0,
  });
  // レートデータリクエスト
  const rateSource = axiosCancelToken.source();
  const postApiRate = () => {
    if (checkIsLessPoint()) {
      return;
    }

    if (!isLoading) {
      setIsLoading(true);

      // axios POST /api/rate
      axiosClient
        .post<RateType>('/api/rate', {
          point,
          cancelToken: rateSource.token,
        })
        .then(({
          data: {
            BTC, USDT, ETH, USD, jpy, commission,
          },
        }) => {
          // 選択した仮想通貨によってstateの値切り替え
          ccRateValuesRef.current = { BTC, USDT, ETH };
          switch (selectedCurrency) {
            case 'BTC':
              setRateCc(ccRateValuesRef.current.BTC);
              break;
            case 'USDT':
              setRateCc(ccRateValuesRef.current.USDT);
              break;
            case 'ETH':
              setRateCc(ccRateValuesRef.current.ETH);
              break;
            default:
          }
          setRateUsd(USD);
          setRateJpy(jpy);
          setDisplayedCommission(commission);
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          displayErrorMessageInToast(error);
          setPoint('0');
          setRateCc(0);
          setRateJpy(0);
          setRateUsd(0);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const checkInputPoint: OnChangeEvent = (event) => {
    const { value } = event.currentTarget;
    // 半角数字以外の場合は空文字に
    if (!/^\d+$/.exec(value)) {
      setPoint('0');
    } else {
      setPoint(value);
    }
  };

  const pointInputProps: InputProps = {
    type: 'text',
    value: point,
    onChange: checkInputPoint,
    onBlur: postApiRate,
  };

  // 入金ボーナスポイントリクエスト
  const [campaigns, setCampaigns] = useState<PointCampaignSelectType[]>([]);
  const pointCampaignSelectSource = axiosCancelToken.source();
  const getApiPointCampaignSelect = () => {
    // axios GET /api/point
    axiosClient
      .get<PointCampaignSelectType[]>('/api/point_campaign/select', {
        cancelToken: pointCampaignSelectSource.token,
      })
      .then(({ data }) => {
        if (data.length > 0) {
          setCampaigns([...data]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // 購入ボタン押下
  const onClickPurchase = async () => {
    setAccountHolderErrorMessage('');
    setLessPointErrorMessage('');

    if (checkIsLessPoint()) {
      setLessPointErrorMessage('ポイントが不足しています');

      return;
    }
    if (checkOverMaxCredit()) {
      setLessPointErrorMessage('クレジットカード利用可能上限を超えています');

      return;
    }
    const result = checkAccountHolderInput();
    if (result) {
      setAccountHolderErrorMessage(result);

      return;
    }

    try {
      setIsLoading(true);

      const { data: rateSave } = await postApiRateSave('jpy');
      const { data: paymentRequest } = (
        await postApiPaymentRequest(rateSave.need_jpy, rateSave.transaction_id)
      );

      if (paymentRequest.success) {
        const { data } = paymentRequest;
        const state: CachLocationState = {
          referrer: '/point_purchase/cash',
          point,
          needJpy: rateSave.need_jpy,
          needUsd: rateSave.need_usd,
          commission: displayedCommission,
          referenceNumber: data.reference_number,
          lastName,
          firstName,
          bankName: data.bank.bank_name,
          branchName: data.bank.branch_name,
          branchCode: data.bank.branch_code,
          accountType: data.bank.account_type,
          accountNumber: data.bank.account_number,
          accountName: data.bank.account_name,
        };
        history.push({
          pathname: '/point_purchase/cash/account',
          state,
        });
      } else {
        throw new Error(paymentRequest.message);
      }
    } catch (e) {
      displayErrorMessageInToast(e);
      setIsLoading(false);
    }
  };

  // 全角カナチェック
  const checkAccountHolderInput = () => {
    const regex = /[^ァ-ヶー]+$/;
    const noMatchmessage = '全角カタカナで入力してください。';
    if (regex.exec(firstName)) return noMatchmessage;
    if (regex.exec(lastName)) return noMatchmessage;
    if (firstName === '' || lastName === '') return '口座名義は入力必須です。';

    return '';
  };

  // クリーンアップ処理
  const cleanup = () => {
    setIsLoading(false);
    setPoint('0');
    setSelectedCurrency('BTC');
    setRateCc(0);
    setRateJpy(0);
    setRateUsd(0);
    setPurchase('jpy');
    setCampaigns([]);
    rateSource.cancel();
    pointCampaignSelectSource.cancel();
    rateSaveSource.cancel();
  };

  useEffect(() => {
    // スクロール位置リセット
    window.scrollTo({
      top: 0,
    });

    // レート値取得API実行
    postApiRate();
    // 入金ボーナスポイント取得API実行
    getApiPointCampaignSelect();

    return cleanup;
  }, []);

  return {
    point,
    lastName,
    firstName,
    goBack,
    isLoading,
    pointInputProps,
    firstNameInputProps,
    lastNameInputProps,
    rateCc,
    rateJpy,
    onClickPurchase,
    accountHolderErrorMessage,
    lessPointErrorMessage,
    campaigns,
  };
};
