import { FC, memo } from 'react';
// External library
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Components
import LoaderPart from 'components/atoms/loader/LoaderPart';
import NeonShadowBorder from 'components/atoms/border/NeonShadowBorder';
// Custom hooks
import { useEntryUserBox } from 'hooks/organisms/lottery/useEntryUserBox';
// Recoil
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { langState } from 'store/atoms/langState';
import { earnedPointShowFlagsState } from 'store/atoms/lottery/earnedPointShowFlagsState';
// Utils
import { LANG } from 'utils/const/lang';
import { devices } from 'utils/const/devices';
import { SPECIAL_FLAG } from 'utils/const/lottery/entryUsers';
// Images
import barNeonBottomPc from 'assets/images/neonItem/bar-bottom_pc.png';
import titleJaSp from 'assets/images/lottery/entry/title_ja_sp.png';
import titleJaPc from 'assets/images/lottery/entry/title_ja_pc.png';
import titleJaPc2x from 'assets/images/lottery/entry/title_ja_pc@2x.png';
import titleEnSp from 'assets/images/lottery/entry/title_en_sp.png';
import titleEnPc from 'assets/images/lottery/entry/title_en_pc.png';
import titleEnPc2x from 'assets/images/lottery/entry/title_en_pc@2x.png';
import titleZhCnSp from 'assets/images/lottery/entry/title_zh_cn_sp.png';
import titleZhCnPc from 'assets/images/lottery/entry/title_zh_cn_pc.png';
import titleZhCnPc2x from 'assets/images/lottery/entry/title_zh_cn_pc@2x.png';
import titleZhTwSp from 'assets/images/lottery/entry/title_zh_tw_sp.png';
import titleZhTwPc from 'assets/images/lottery/entry/title_zh_tw_pc.png';
import titleZhTwPc2x from 'assets/images/lottery/entry/title_zh_tw_pc@2x.png';
import titleKoSp from 'assets/images/lottery/entry/title_ko_sp.png';
import titleKoPc from 'assets/images/lottery/entry/title_ko_pc.png';
import titleKoPc2x from 'assets/images/lottery/entry/title_ko_pc@2x.png';
import titleViSp from 'assets/images/lottery/entry/title_vi_sp.png';
import titleViPc from 'assets/images/lottery/entry/title_vi_pc.png';
import titleViPc2x from 'assets/images/lottery/entry/title_vi_pc@2x.png';
import titleThSp from 'assets/images/lottery/entry/title_th_sp.png';
import titleThPc from 'assets/images/lottery/entry/title_th_pc.png';
import titleThPc2x from 'assets/images/lottery/entry/title_th_pc@2x.png';
import { NEWS } from '../../../utils/const/news';

const {
  mobileLandscape,
  tabletPortrait,
  tabletLandscape,
  pc,
} = devices;

const {
  JAPANESE,
  SIMPLE_CHINESE,
  TRADITIONAL_CHINESE,
  KOREAN,
  VIETNAM,
  THAILAND,
} = LANG;
const { LIST_VIEW_LIMIT } = NEWS;

/**
 *
 * Types
 *
 */
type Props = {
  scrollFirstFlag: boolean;
  scrollLastFlag: boolean;
  isLoading: boolean;
  getApiEntryUsers: (isNext: boolean) => void;
};

/**
 *
 * Component
 *
 */
const EntryUserBox: FC<Props> = memo((props) => {
  const {
    scrollFirstFlag,
    scrollLastFlag,
    isLoading,
    getApiEntryUsers,
  } = props;

  // Hooks
  const { t } = useTranslation();
  const {
    loginUserRef,
    specialLoginUserRef,
    entryUsersBoxRef,
    entryUserReadMoreRef,
    entryUserListRef,
    entryUsers,
  } = useEntryUserBox();
  // Recoil
  const loginUser = useRecoilValue(userState);
  const lang = useRecoilValue(langState);
  const earnedPointShowFlags = useRecoilValue(earnedPointShowFlagsState);

  const clickPrev = () => {
    getApiEntryUsers(false);
  };
  const clickNext = () => {
    getApiEntryUsers(true);
  };

  return (
    <EntryS>
      <TitleS>
        <source
          srcSet={
            (lang === JAPANESE)
              ? `${titleJaPc} 1x, ${titleJaPc2x} 2x`
              : (lang === SIMPLE_CHINESE)
                ? `${titleZhCnPc} 1x, ${titleZhCnPc2x} 2x`
                : (lang === TRADITIONAL_CHINESE)
                  ? `${titleZhTwPc} 1x, ${titleZhTwPc2x} 2x`
                  : (lang === KOREAN)
                    ? `${titleKoPc} 1x, ${titleKoPc2x} 2x`
                    : (lang === VIETNAM)
                      ? `${titleViPc} 1x, ${titleViPc2x} 2x`
                      : (lang === THAILAND)
                        ? `${titleThPc} 1x, ${titleThPc2x} 2x`
                        : `${titleEnPc} 1x, ${titleEnPc2x} 2x`
          }
          media="(min-width: 600px)"
        />
        <ImageS
          src={
            (lang === JAPANESE)
              ? titleJaSp
              : (lang === SIMPLE_CHINESE)
                ? titleZhCnSp
                : (lang === TRADITIONAL_CHINESE)
                  ? titleZhTwSp
                  : (lang === KOREAN)
                    ? titleKoSp
                    : (lang === VIETNAM)
                      ? titleViSp
                      : (lang === THAILAND)
                        ? titleThSp
                        : titleEnSp
          }
          alt=""
        />
      </TitleS>

      <EntryTableS>
        <EntryHeadS>
          <EntryHeadTitleS>{t('No.')}</EntryHeadTitleS>
          <EntryHeadTitleS>{t('ユーザー名')}</EntryHeadTitleS>
          <EntryHeadTitleS>{t('購入日時')}</EntryHeadTitleS>
          <EntryHeadTitleS>{t('当選ポイント')}</EntryHeadTitleS>
        </EntryHeadS>

        <NeonShadowBorderWrapS>
          <NeonShadowBorder />
        </NeonShadowBorderWrapS>

        {isLoading && <LoaderPart zIndex={2} />}

        {entryUsers.length > 0 && (
          <EntryUListS scrollLastFlag={scrollLastFlag} ref={entryUsersBoxRef}>
            {!scrollFirstFlag && (
              <ReadMoreButtonWrapS ref={entryUserReadMoreRef}>
                <ReadMoreButtonS type="button" onClick={clickPrev}>
                  {t('さらに表示する')}
                </ReadMoreButtonS>
              </ReadMoreButtonWrapS>
            )}
            {entryUsers.map((user) => (
              <EntryListS
                key={uuidv4()}
                ref={
                  (user.userId !== loginUser.id)
                    ? entryUserListRef
                    : (user.specialFlg === SPECIAL_FLAG.TRUE)
                      ? specialLoginUserRef
                      : loginUserRef
                }
              >
                <EntryListBoxS
                  winningFlag={
                    earnedPointShowFlags.includes(user.betId) && user.point > 0
                  }
                >
                  <EntryIds>{user.ticketNumber}</EntryIds>
                  <EntryUserNameS>{user.name}</EntryUserNameS>
                  <EntryDateS>{user.date}</EntryDateS>
                  <EarnedPointS>
                    {(earnedPointShowFlags.includes(user.betId) && user.point) || 0 }
                  </EarnedPointS>
                </EntryListBoxS>
              </EntryListS>
            ))}
            {!scrollLastFlag && (
              <ReadMoreButtonWrapS>
                <ReadMoreButtonS type="button" onClick={clickNext}>
                  {t('さらに表示する')}
                </ReadMoreButtonS>
              </ReadMoreButtonWrapS>
            )}
          </EntryUListS>
        )}
      </EntryTableS>

      <NeonBarPcS>
        <ImageS src={barNeonBottomPc} alt="" />
      </NeonBarPcS>
    </EntryS>
  );
});

export default EntryUserBox;

/**
 *
 * Styles
 *
 */
const EntryS = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  padding-bottom: 12vw;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media ${mobileLandscape} {
    padding-bottom: 0;
  }

  @media ${tabletLandscape}, ${pc} {
    padding-bottom: 0;
    font-size: 3rem;
  }
`;

const EntryTableS = styled.div`
  height: 100%;
  width: 100%;

  @media ${tabletLandscape}, ${pc} {
    margin-top: -10%;
  }
`;

const EntryHeadS = styled.div`
  width: 100%;
  // grid
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const EntryHeadTitleS = styled.div`
  padding: 0 1rem 1.4rem;
  font-size: 1.4rem;
  word-break: break-all;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    padding: 5rem 1rem 1.4rem;
  }
`;

const EntryUListS = styled.ul<{ scrollLastFlag: boolean }>`
  height: 350px;
  overflow: auto;
  // grid
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  // grid-auto-rows: 1fr;
  align-content: start;
  border-bottom: ${({ scrollLastFlag }) => (scrollLastFlag ? '1px solid #707070' : 0)};


  @media ${mobileLandscape} {
    height: 300px;
  }

  @media ${tabletLandscape}, ${pc} {
    height: 278px;
  }
`;

const EntryListS = styled.li`
  border-bottom: 1px solid #ffffff;

  &:last-of-type {
    border-bottom: none;
  }
`;

const EntryListBoxS = styled.div<{ winningFlag: boolean }>`
  // grid
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // animation
  @keyframes high-light {
    0% { opacity: 1; }
    50% {
      background-color: rgba(0,0,0,1);
      opacity: 0.2;
    }
    100% { opacity: 1; }
  }
  animation-name: ${({ winningFlag }) => (winningFlag ? 'high-light' : '')};
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-timing-function: linear;
`;

const EntryListItemS = styled.div`
  width: 100%;
  word-break: break-all;
  padding: 1rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${tabletPortrait} {
    padding: 1.2rem;
  }
`;

const EntryIds = styled(EntryListItemS)`
  font-size: 1.2rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const EntryUserNameS = styled(EntryListItemS)`
  font-size: 1.4rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const EntryDateS = styled(EntryListItemS)`
  font-size: 1.2rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const EarnedPointS = styled(EntryListItemS)`
  font-size: 1.4rem;
  color: #ffd400;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;

const NeonShadowBorderWrapS = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  @media ${tabletPortrait} {
    margin-bottom: 1.4rem;
  }
`;

const TitleS = styled.picture`
  width: 100%;
  margin-bottom: 1rem;
  display: inline-block;
  margin-top: calc(-27vw / 2);
  z-index: 3;

  @media ${mobileLandscape} {
    margin-top: calc(-12vw / 2);
  }

  @media ${tabletPortrait} {
    margin-top: calc(-22vw / 2);
  }

  @media ${tabletLandscape}, ${pc} {
    margin-top: -10%;
  }
`;

const NeonBarPcS = styled.div`
  display: none;

  @media ${mobileLandscape}, ${tabletLandscape}, ${pc} {
    width: 100%;
    display: flex;
  }
`;

const ImageS = styled.img`
  width: 100%;
`;

const ReadMoreButtonWrapS = styled.div`
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  word-break: break-all;
  border-top: 1px solid #707070;

  @media (min-width: 1025px) {
    border-top: 0;
  }
`;

const ReadMoreButtonS = styled.button`
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
