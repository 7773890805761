import { FC } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import FormLayout from 'components/templates/FormLayout';
import NormalInput from 'components/atoms/input/NormalInput';
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Custom hooks
import { usePasswordResetForm } from 'hooks/pages/common/usePasswordResetForm';
// Utils
import { passwordResetFormBreadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const PasswordResetForm: FC = () => {
  // i18next
  const { t } = useTranslation();

  // パスワードリセットフォーム用カスタムフック
  const {
    isLoading,
    errorMessage,
    passwordRef,
    confirmPasswordRef,
    postApiResetPassword,
  } = usePasswordResetForm();

  return (
    <>
      <Helmet>
        <title>{t('title.passwordResetForm')}</title>
        <meta name="description" content={t('description.passwordResetForm')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={passwordResetFormBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('パスワードの再設定')}
        titleStyle="silver"
      >
        <TextWrapS>
          <TextS>
            {t('新しいパスワードを入力した後、「設定する」を押してください。')}
          </TextS>
        </TextWrapS>

        {/* パスワードフォーム */}
        <FormS>
          {/* エラーメッセージ */}
          {errorMessage && (
            <ErrorMessageWrapS>
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </ErrorMessageWrapS>
          )}

          <InputWrapS>
            <NormalInput
              ref={passwordRef}
              name="password"
              type="password"
              placeholder={t('新しいパスワード')}
            />
          </InputWrapS>
          <InputWrapS>
            <NormalInput
              ref={confirmPasswordRef}
              name="confirm_password"
              type="password"
              placeholder={t('新しいパスワード(確認用)')}
            />
          </InputWrapS>

          {/* ローディングアニメーション */}
          {isLoading && <LoaderWindow />}

          {isLoading ? (
            <RoundButtonWrapS>
              <RoundButton
                background="#555555"
                borderColor="rgba(230, 230, 230, 1)"
              >
                {t('設定する')}
              </RoundButton>
            </RoundButtonWrapS>
          ) : (
            <RoundButtonWrapS>
              <RoundButton
                onClick={postApiResetPassword}
                background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                borderColor="rgba(219,0,177,1)"
              >
                {t('設定する')}
              </RoundButton>
            </RoundButtonWrapS>
          )}
        </FormS>
      </FormLayout>
    </>
  );
};
export default PasswordResetForm;

/**
 *
 * Styles
 *
 */
const TextWrapS = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.8rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
    margin-top: 2.4rem;
  }

  @media (min-width: 1025px) {
    width: 50%;
    margin-top: 3rem;
  }
`;

const TextS = styled.p`
  font-size: 1.2rem;
  word-break: break-word;
  line-height: 1.5;
  color: #ffffff;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const FormS = styled.div`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1025px) {
    width: 50%;
  }
`;

const ErrorMessageWrapS = styled.div`
  width: 100%;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
  }
`;

const InputWrapS = styled.div`
  width: 100%;
  margin-bottom: 1.8rem;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
  }
`;

const RoundButtonWrapS = styled.div`
  width: 80%;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 60%;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;
