import axios, { AxiosError } from 'axios';

type RefreshResponse = {
  access_token: string;
  refresh_token?: string;
  token_type: string;
  expires_in: string;
}

declare module 'axios' {
  export interface AxiosRequestConfig {
    isRetry?: boolean;
  }
}

export const getToken = (): string => {
  const token = localStorage?.getItem('access_token');

  return token || '';
};

export const getRefreshToken = (): string => {
  const token = localStorage?.getItem('refresh_token');

  return token || '';
};

export const getExpiresIn = (): Date | null => {
  const expiresIn = localStorage?.getItem('expires_in');

  return expiresIn ? new Date(expiresIn) : null;
};

export const getAuthorizationHeader = ():string => `Bearer ${getToken()}`;
export const getRefreshHeader = ():string => `Bearer ${getRefreshToken()}`;

// axios設定
export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': process.env.REACT_WEB_API_URL,
    Authorization: getAuthorizationHeader(),
  },
  responseType: 'json',
  withCredentials: true,
});

export const axiosFormDataClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': process.env.REACT_WEB_API_URL,
    Authorization: getAuthorizationHeader(),
  },
  responseType: 'json',
  withCredentials: true,
});

export const axiosCancelToken = axios.CancelToken;

// Refresh
const refresh = async () => {
  console.log('Access token will be refreshed');

  await axiosClient.post<RefreshResponse>('/api/refresh', {
    headers: {
      Authorization: getRefreshHeader(),
    },
  })
    .then(({ data }) => {
      if (data.access_token) {
        localStorage.setItem('access_token', data.access_token);
      }
      if (data.refresh_token) {
        localStorage.setItem('refresh_token', data.refresh_token);
      }
      if (data.expires_in) {
        localStorage.setItem('expires_in', data.expires_in.toString());
      }
    })
    .catch((err) => {
      void Promise.reject(err);
    });
};

axiosClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error?.response?.status === 401) {
      await refresh();

      return axios.request(error.config);
    }

    return Promise.reject(error);
  },
);

axiosFormDataClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error?.response?.status === 401) {
      await refresh();

      return axios.request(error.config);
    }

    return Promise.reject(error);
  },
);
