import { useState, useEffect } from 'react';

/**
 * Types
 */
type WindowDimensions = {
  width: number;
  height: number;
};

// ウインドウサイズ取得用カスタムフック
export const useWindowDimensions = (): {
  windowDimensions: WindowDimensions;
} => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;

    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(
    getWindowDimensions(),
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return {
    windowDimensions,
  };
};
