import { useRef, useState, RefObject } from 'react';
// External library
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
import { useValidate } from 'hooks/versatile/useValidate';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType } from 'types/api';

/**
 *
 * メール認証用ロジック
 *
 */
export const useEmailChange = (): {
  emailRef: RefObject<HTMLInputElement>;
  isLoading: boolean;
  errorMessage: string;
  postApiUsersChangeEmail: () => void;
  cleanUpMethod: () => void;
} => {
  // Eメール
  const emailRef = useRef<HTMLInputElement>(null);

  // ローディング状態
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState('');
  // エラー用カスタムフック
  const { errorHandler } = useError();
  // バリデーション用カスタムフック
  const { emailValidate } = useValidate();

  // historyオブジェクト
  const history = useHistory();
  // APIキャンセルトークン
  const axiosSource = axiosCancelToken.source();
  // Eメールアドレス変更リクエスト
  const postApiUsersChangeEmail = () => {
    if (!isLoading) {
      setIsLoading(true);

      // メールアドレスバリデーション
      let email = '';
      if (emailRef.current !== null) {
        const result = emailValidate(emailRef.current.value);
        if (result) {
          setErrorMessage(result);
          setIsLoading(false);

          return;
        }
        email = emailRef.current.value;
      }

      // axios POST /api/users/change_email
      axiosClient
        .post<boolean>('/api/users/change_email', {
          email,
          cancelToken: axiosSource.token,
        })
        .then(({ data }) => {
          if (data) {
            history.push({
              pathname: '/email_send',
              state: {
                referrer: '/email_change',
                email,
              },
            });
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    setIsLoading(false);
    setErrorMessage('');
    axiosSource.cancel();
  };

  return {
    emailRef,
    isLoading,
    errorMessage,
    postApiUsersChangeEmail,
    cleanUpMethod,
  };
};
