import { RefObject } from 'react';
// Recooil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { playBoxShowState } from 'store/atoms/playBoxShowState';

/**
 * PLAYBOX5000用カスタムフック
 */
export const usePlayBox5000 = (): {
  scrollToBox: (ref: RefObject<HTMLInputElement>) => void;
  onClickShow: (ref: RefObject<HTMLInputElement>, isScroll: boolean) => void;
} => {
  // Recoil PLAYBOX開閉状態
  const playBoxShow = useRecoilValue(playBoxShowState);
  // このボックスの情報
  const { isShow5000 } = playBoxShow;
  // Recoil PLAYBOX開閉情報更新関数
  const setPlayBoxShow = useSetRecoilState(playBoxShowState);

  // ボックス選択時のスクロール;
  /**
   * ref: RefObject
   */
  const scrollToBox = (ref: RefObject<HTMLInputElement>) => {
    if (ref?.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // BOXの開閉 関数
  const onClickShow = (ref: RefObject<HTMLInputElement>, isScroll: boolean) => {
    if (isShow5000) {
      setPlayBoxShow({
        initialBoxId: playBoxShow.initialBoxId,
        isShow1000: false,
        isShow5000: false,
        isShow10000: false,
        isShow100000: false,
        isShow1000000: false,
      });
    } else {
      setPlayBoxShow({
        initialBoxId: playBoxShow.initialBoxId,
        isShow1000: false,
        isShow5000: true,
        isShow10000: false,
        isShow100000: false,
        isShow1000000: false,
      });

      if (isScroll) {
        window.setTimeout(() => {
          // スクロール
          scrollToBox(ref);
        }, 500);
      }
    }
  };

  return {
    scrollToBox,
    onClickShow,
  };
};
