import { memo } from 'react';
// External library
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import DepositButton from 'components/atoms/button/DepositButton';
import WithdrawalOnButton from 'components/atoms/button/WithdrawalOnButton';
import WithdrawalOffButton from 'components/atoms/button/WithdrawalOffButton';
// Recoil
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { langState } from 'store/atoms/langState';
import { userPointDisplayState } from 'store/selectors/userPointDisplayState';
// Utils
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';
// Images
import backGroundSP from 'assets/images/backGround/mypage_sp.jpg';

const { tabletPortrait, tabletLandscape, pc } = devices;
const {
  JAPANESE,
  ENGLISH,
  KOREAN,
  SIMPLE_CHINESE,
  TRADITIONAL_CHINESE,
  VIETNAM,
  THAILAND,
} = LANG;

/**
 *
 * Types
 *
 */
type Props = {
  kycFlag: boolean;
  ticket: number;
  goToPage: () => void;
};

/**
 *
 * Component
 *
 */
const UserInfomationSp = memo(({ kycFlag, ticket, goToPage }: Props) => {
  // Hooks
  const { t } = useTranslation();
  // Recoil
  const lang = useRecoilValue(langState);
  const { name } = useRecoilValue(userState);
  const point = useRecoilValue(userPointDisplayState);

  return (
    <UserInfomationSpS>
      <ContainerS>
        <UserS>
          <NameS>{name}</NameS>
        </UserS>

        <BorderS />

        <PointS>
          <TextWrapS>
            <TextS>{t('所持ポイント')}</TextS>
          </TextWrapS>
          <PointTextWrapS>
            <PointTextS>
              {point}
              <PointUnitS>&nbsp;P</PointUnitS>
            </PointTextS>
          </PointTextWrapS>
          {ticket > 0 && (
            <NoticeTextS>
              {lang === JAPANESE && (
                `あと${ticket}枚チケットを購入すると出金を行うことができます。`
              )}
              {lang === ENGLISH && (
                `${ticket} more tickets can be purchased to make withdrawals.`
              )}
              {lang === KOREAN && (
                `${ticket}장의 티켓을 추가로 구매하여 출금할 수 있습니다.`
              )}
              {lang === SIMPLE_CHINESE && (
                `可以再购买${ticket}张门票进行提款。`
              )}
              {lang === TRADITIONAL_CHINESE && (
                `可以再購買${ticket}張門票進行提款。`
              )}
              {lang === VIETNAM && (
                `Có thể mua thêm ${ticket} vé để rút tiền.`
              )}
              {lang === THAILAND && (
                `สามารถซื้อบัตรได้อีก ${ticket} ใบสำหรับการถอน`
              )}
            </NoticeTextS>
          )}
          <ButtonGroupS>
            <Link className="link" to="/point_purchase">
              <DepositButton />
            </Link>
            {kycFlag && ticket === 0 ? (
              <ButtonWrapS onClick={goToPage}>
                <WithdrawalOnButton />
              </ButtonWrapS>
            ) : (
              <ButtonWrapS>
                <WithdrawalOffButton />
              </ButtonWrapS>
            )}
          </ButtonGroupS>
        </PointS>
      </ContainerS>
    </UserInfomationSpS>
  );
});
export default UserInfomationSp;

/**
 *
 * Styles
 *
 */
const UserInfomationSpS = styled.div`
  width: 100%;
  padding: 6rem 0 3rem;
  // background
  background-image: url(${backGroundSP});
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;

  @media ${tabletPortrait} {
    padding: 8rem 0 4rem;
  }

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;

const ContainerS = styled.div`
  width: 80%;
  margin: 0 auto;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletPortrait} {
    width: 60%;
  }
`;

const UserS = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameS = styled.p`
  font-size: 2rem;
  word-break: break-all;

  @media ${tabletPortrait} {
    font-size: 2.4rem;
  }
`;

const BorderS = styled.hr`
  width: 100%;
  height: 2px;
  margin-bottom: 1.6rem;
  background: linear-gradient(45deg, #ff17e4, #5e05c6);
  color: transparent;
`;

const PointS = styled.div`
  width: 100%;
  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextWrapS = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextS = styled.p`
  font-size: 1.2rem;
  word-break: break-all;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }
`;

const PointTextWrapS = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PointTextS = styled.p`
  font-size: 3.4rem;
  word-break: break-all;
`;

const PointUnitS = styled.span`
  font-size: 2.4rem;
`;

const NoticeTextS = styled.p`
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.5;
  width: 100%;
  margin-bottom: 1rem;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
`;

const ButtonGroupS = styled.div`
  width: min(80%, 25rem);
  // flex
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${tabletPortrait} {
    width: 100%;
    flex-direction: row;
  }

  .link {
    width: 100%;
    margin-bottom: 1.6rem;

    @media ${tabletPortrait} {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ButtonWrapS = styled.div`
  width: 100%;
`;
