// eslint-disable-next-line
import { FC, memo, RefObject } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Hooks
import { useUfcTransition } from 'hooks/organisms/useUfcTransition';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utill
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';
// Images
import titleJaSp from 'assets/images/top/flows/ja/title_sp.png';
import titleJaPc from 'assets/images/top/flows/ja/title_pc.png';
import titleJaPc2x from 'assets/images/top/flows/ja/title_pc@2x.png';
import titleEnSp from 'assets/images/top/flows/en/title_sp.png';
import titleEnPc from 'assets/images/top/flows/en/title_pc.png';
import titleEnPc2x from 'assets/images/top/flows/en/title_pc@2x.png';
import titleZhCnSp from 'assets/images/top/flows/zhCn/title_sp.png';
import titleZhCnPc from 'assets/images/top/flows/zhCn/title_pc.png';
import titleZhCnPc2x from 'assets/images/top/flows/zhCn/title_pc@2x.png';
import titleZhTwSp from 'assets/images/top/flows/zhTw/title_sp.png';
import titleZhTwPc from 'assets/images/top/flows/zhTw/title_pc.png';
import titleZhTwPc2x from 'assets/images/top/flows/zhTw/title_pc@2x.png';
import titleKoSp from 'assets/images/top/flows/ko/title_sp.png';
import titleKoPc from 'assets/images/top/flows/ko/title_pc.png';
import titleKoPc2x from 'assets/images/top/flows/ko/title_pc@2x.png';
import titleviSp from 'assets/images/top/flows/vi/title_sp.png';
import titleviPc from 'assets/images/top/flows/vi/title_pc.png';
import titleviPc2x from 'assets/images/top/flows/vi/title_pc@2x.png';
import titlethSp from 'assets/images/top/flows/th/title_sp.png';
import titlethPc from 'assets/images/top/flows/th/title_pc.png';
import titlethPc2x from 'assets/images/top/flows/th/title_pc@2x.png';
import iconRegisterSp from 'assets/images/top/flows/flow_icon1_sp.jpg';
import iconRegisterPc from 'assets/images/top/flows/flow_icon1_pc.jpg';
import iconRegisterPc2x from 'assets/images/top/flows/flow_icon1_pc@2x.jpg';
import iconDepositSp from 'assets/images/top/flows/flow_icon2_sp.jpg';
import iconDepositPc from 'assets/images/top/flows/flow_icon2_pc.jpg';
import iconDepositPc2x from 'assets/images/top/flows/flow_icon2_pc@2x.jpg';
import iconPurchaseSp from 'assets/images/top/flows/flow_icon3_sp.jpg';
import iconPurchasePc from 'assets/images/top/flows/flow_icon3_pc.jpg';
import iconPurchasePc2x from 'assets/images/top/flows/flow_icon3_pc@2x.jpg';
import iconLotSp from 'assets/images/top/flows/flow_icon4_sp.jpg';
import iconLotPc from 'assets/images/top/flows/flow_icon4_pc.jpg';
import iconLotPc2x from 'assets/images/top/flows/flow_icon4_pc@2x.jpg';
import iconWithdrawSp from 'assets/images/top/flows/flow_icon5_sp.jpg';
import iconWithdrawPc from 'assets/images/top/flows/flow_icon5_pc.jpg';
import iconWithdrawPc2x from 'assets/images/top/flows/flow_icon5_pc@2x.jpg';

const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

/**
 *
 * Types
 *
 */
type Props = {
  _ref: RefObject<HTMLInputElement>;
  scrollToBox: (ref: RefObject<HTMLInputElement>, isScroll: boolean) => void;
};

/**
 *
 * Component
 *
 */
// 登録の流れ用コンポーネント
const Flow: FC<Props> = memo((props) => {
  // i18next
  const { t } = useTranslation();
  // Recoil 言語設定
  const lang = useRecoilValue(langState);

  const { _ref, scrollToBox } = props;

  const {
    transitToUfc,
  } = useUfcTransition();

  return (
    <FlowS>
      <HeadPictureS>
        <source
          srcSet={
            lang === JAPANESE
              ? `${titleJaPc} 1x,${titleJaPc2x} 2x`
              : lang === SIMPLE_CHINESE
                ? `${titleZhCnPc} 1x,${titleZhCnPc2x} 2x`
                : lang === TRADITIONAL_CHINESE
                  ? `${titleZhTwPc} 1x,${titleZhTwPc2x} 2x`
                  : lang === KOREAN
                    ? `${titleKoPc} 1x,${titleKoPc2x} 2x`
                    : lang === VIETNAM
                      ? `${titleviPc} 1x,${titleviPc2x} 2x`
                      : lang === THAILAND
                        ? `${titlethPc} 1x,${titlethPc2x} 2x`
                        : `${titleEnPc} 1x,${titleEnPc2x} 2x`
          }
          media="(min-width: 600px)"
        />
        <HeadImageS
          src={
            lang === JAPANESE
              ? titleJaSp
              : lang === SIMPLE_CHINESE
                ? titleZhCnSp
                : lang === TRADITIONAL_CHINESE
                  ? titleZhTwSp
                  : lang === KOREAN
                    ? titleKoSp
                    : lang === VIETNAM
                      ? titleviSp
                      : lang === THAILAND
                        ? titlethSp
                        : titleEnSp
          }
          alt=""
          loading="lazy"
        />
      </HeadPictureS>
      <ContainerS>
        {/* 会員登録 */}
        <BoxS>
          <ContentS>
            <IconAndHeadWrapS>
              <HeadWrapS>
                <HeadTextS>{t('会員登録')}</HeadTextS>
              </HeadWrapS>

              <FlowIconPictureS>
                <source
                  srcSet={`${iconRegisterPc} 1x,${iconRegisterPc2x} 2x`}
                  media="(min-width: 600px)"
                />
                <FlowIconImageS src={iconRegisterSp} alt="" loading="lazy" />
              </FlowIconPictureS>
            </IconAndHeadWrapS>

            <DetailWrapS>
              <TextS>
                {t(
                  'メールアドレスを登録フォームに入力し、会員登録を行います。',
                )}
              </TextS>
            </DetailWrapS>

            <ButtonWrapS className="link" onClick={() => transitToUfc('register')}>
              <RoundButtonS type="button">{t('登録する')}</RoundButtonS>
            </ButtonWrapS>
          </ContentS>
        </BoxS>

        {/* 入金・チャージ */}
        <BoxS>
          <ContentS>
            <IconAndHeadWrapS>
              <HeadWrapS>
                <HeadTextS>{t('入金・チャージ')}</HeadTextS>
              </HeadWrapS>

              <FlowIconPictureS>
                <source
                  srcSet={`${iconDepositPc} 1x,${iconDepositPc2x} 2x`}
                  media="(min-width: 600px)"
                />
                <FlowIconImageS src={iconDepositSp} alt="" loading="lazy" />
              </FlowIconPictureS>
            </IconAndHeadWrapS>

            <DetailWrapS>
              <TextS>
                {t(
                  '仮想通貨、振込など様々な方法でポイントをチャージすることができます。仮想通貨、振込など様々な方法でポイントをチャージすることができます。',
                )}
              </TextS>
            </DetailWrapS>

            <ButtonWrapS className="link" onClick={() => transitToUfc('deposit')}>
              <RoundButtonS type="button">{t('入金する')}</RoundButtonS>
            </ButtonWrapS>
          </ContentS>
        </BoxS>

        {/* チケット購入 */}
        <BoxS>
          <ContentS>
            <IconAndHeadWrapS>
              <HeadWrapS>
                <HeadTextS>{t('チケット購入')}</HeadTextS>
              </HeadWrapS>

              <FlowIconPictureS>
                <source
                  srcSet={`${iconPurchasePc} 1x,${iconPurchasePc2x} 2x`}
                  media="(min-width: 600px)"
                />
                <FlowIconImageS src={iconPurchaseSp} alt="" loading="lazy" />
              </FlowIconPictureS>
            </IconAndHeadWrapS>

            <DetailWrapS>
              <TextS>
                {t('ゲームに参加するためにチケットを、ポイントで購入します。')}
              </TextS>
            </DetailWrapS>

            <ButtonWrapS>
              <RoundButtonS
                type="button"
                onClick={() => scrollToBox(_ref, true)}
              >
                {t('購入する')}
              </RoundButtonS>
            </ButtonWrapS>
          </ContentS>
        </BoxS>

        {/* 当選の確定 */}
        <BoxS>
          <ContentS>
            <IconAndHeadWrapS>
              <HeadWrapS>
                <HeadTextS>{t('当選の確定')}</HeadTextS>
              </HeadWrapS>

              <FlowIconPictureS>
                <source
                  srcSet={`${iconLotPc} 1x,${iconLotPc2x} 2x`}
                  media="(min-width: 600px)"
                />
                <FlowIconImageS src={iconLotSp} alt="" loading="lazy" />
              </FlowIconPictureS>
            </IconAndHeadWrapS>

            <DetailWrapS>
              <TextS>
                {t(
                  'ゲームにエントリーをし、ゲームが開始されたら、すぐに当選の確定が分かります。当選確定の演出もお楽しみください！',
                )}
              </TextS>
            </DetailWrapS>
          </ContentS>
        </BoxS>

        {/* 出金 */}
        <BoxS>
          <ContentS>
            <IconAndHeadWrapS>
              <HeadWrapS>
                <HeadTextS>{t('出金')}</HeadTextS>
              </HeadWrapS>

              <FlowIconPictureS>
                <source
                  srcSet={`${iconWithdrawPc} 1x,${iconWithdrawPc2x} 2x`}
                  media="(min-width: 600px)"
                />
                <FlowIconImageS src={iconWithdrawSp} alt="" loading="lazy" />
              </FlowIconPictureS>
            </IconAndHeadWrapS>

            <DetailWrapS>
              <TextS>
                {t(
                  '当選が確定したら、即座にマイページに当選ポイントが反映されます。また、そのポイントを換金して出金することができます。',
                )}
              </TextS>
            </DetailWrapS>

            <ButtonWrapS className="link" onClick={() => transitToUfc('withdrawal')}>
              <RoundButtonS type="button">{t('出金する')}</RoundButtonS>
            </ButtonWrapS>
          </ContentS>
        </BoxS>
      </ContainerS>
    </FlowS>
  );
});
export default Flow;

/**
 *
 * Styles
 *
 */
const {
  mobileLandscape, tabletPortrait, tabletLandscape, pc,
} = devices;

const FlowS = styled.div`
  width: 95%;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    max-width: 100rem;
  }
`;

const HeadPictureS = styled.picture`
  width: 50%;
  margin-bottom: 2rem;

  @media (min-width: 600px) {
    width: 30%;
    min-width: 180px;
    margin-bottom: 3rem;
  }

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 5rem;
  }
`;

const HeadImageS = styled.img`
  width: 100%;
`;

const ContainerS = styled.div`
  width: 100%;

  // flex
  display: flex;
  flex-direction: column;

  border: 1px solid #ffffff;
  border-radius: 10px;

  @media (min-width: 600px) {
    flex-direction: unset;
  }
`;

const BoxS = styled.div`
  width: 100%;
  border-bottom: 1px solid #ffffff;
  min-height: 340px;

  // flex
  display: flex;

  &:last-child {
    border-bottom: unset;
  }

  @media (min-width: 600px) {
    width: calc(100% / 5);
    min-height: none;
    border-bottom: unset;
    border-right: 1px solid #ffffff;

    &:last-child {
      border-right: unset;
    }
  }
`;

const ContentS = styled.div`
  width: 100%;
  padding: 2rem;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mobileLandscape}, ${tabletPortrait} {
    padding: 1rem;
  }
`;

const HeadWrapS = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    min-height: 45px;

    // flex
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${tabletLandscape}, ${pc} {
    min-height: 60px;
  }
`;

const DetailWrapS = styled.div`
  margin: 1rem 0;

  @media ${mobileLandscape}, ${tabletPortrait} {
    margin: 2rem 0;
  }

  @media ${tabletLandscape}, ${pc} {
    margin: 1rem 0 2rem;
  }
`;

const TextS = styled.p`
  color: #ffffff;
  font-size: 1.4rem;
  word-break: break-word;
  line-height: 1.6;

  @media ${mobileLandscape}, ${tabletPortrait} {
    font-size: 1.2rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.4rem;
  }
`;

const HeadTextS = styled(TextS)`
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
  word-break: break-word;

  @media ${mobileLandscape}, ${tabletPortrait} {
    font-size: 1.4rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const FlowIconPictureS = styled.picture`
  width: 40%;

  @media ${mobileLandscape}, ${tabletPortrait} {
    width: 70%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 80%;
  }
`;

const FlowIconImageS = styled.img`
  width: 100%;
`;

const IconAndHeadWrapS = styled.div`
  width: 100%;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    flex-direction: column-reverse;
  }
`;

const ButtonWrapS = styled.div`
  width: 200px;
  font-size: 1.4rem;
  margin-top: auto;
  word-break: break-word;

  @media (min-width: 600px) {
    width: 100%;
    font-size: min(1.9vw, 16px);
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const RoundButtonS = styled.button`
  width: 100%;
  position: relative;
  padding: 0.8rem 2rem;
  border-radius: 9999px;
  background: linear-gradient(rgba(0, 5, 65, 1), 85%, rgba(219, 0, 177, 0.9));
  border-color: rgba(219, 0, 177, 1);
  border-width: 2px;
  border-style: solid;
  color: #ffffff;
  text-align: center;

  @media ${mobileLandscape}, ${tabletPortrait} {
    padding: 0.8rem 1rem;
  }
`;
