import { FC } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Components
import SupportLayout from 'components/templates/SupportLayout';
import GoldArrowIcon from 'components/atoms/icon/GoldArrowIcon';
import StepBox from 'components/organisms/how/StepBox';
import PointBox from 'components/organisms/how/PointBox';
// Utils
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';
// Images
import entryFlowTitleJaSp from 'assets/images/how/headJa/midasi_entry_ja_sp.png';
import entryFlowTitleJaPc from 'assets/images/how/headJa/midasi_entry_ja_pc.png';
import entryFlowTitleJaPc2x from 'assets/images/how/headJa/midasi_entry_ja_pc@2x.png';
import entryFlowTitleEnSp from 'assets/images/how/headEn/midasi_entry_en_sp.png';
import entryFlowTitleEnPc from 'assets/images/how/headEn/midasi_entry_en_pc.png';
import entryFlowTitleEnPc2x from 'assets/images/how/headEn/midasi_entry_en_pc@2x.png';
import entryFlowTitleZhCnSp from 'assets/images/how/headZhCn/midasi_entry_zh_cn_sp.png';
import entryFlowTitleZhCnPc from 'assets/images/how/headZhCn/midasi_entry_zh_cn_pc.png';
import entryFlowTitleZhCnPc2x from 'assets/images/how/headZhCn/midasi_entry_zh_cn_pc@2x.png';
import entryFlowTitleZhTwSp from 'assets/images/how/headZhTw/midasi_entry_zh_tw_sp.png';
import entryFlowTitleZhTwPc from 'assets/images/how/headZhTw/midasi_entry_zh_tw_pc.png';
import entryFlowTitleZhTwPc2x from 'assets/images/how/headZhTw/midasi_entry_zh_tw_pc@2x.png';
import entryFlowTitleKoSp from 'assets/images/how/headKo/midasi_entry_ko_sp.png';
import entryFlowTitleKoPc from 'assets/images/how/headKo/midasi_entry_ko_pc.png';
import entryFlowTitleKoPc2x from 'assets/images/how/headKo/midasi_entry_ko_pc@2x.png';
import entryFlowTitleViSp from 'assets/images/how/headVi/midasi_entry_vi_sp.png';
import entryFlowTitleViPc from 'assets/images/how/headVi/midasi_entry_vi_pc.png';
import entryFlowTitleViPc2x from 'assets/images/how/headVi/midasi_entry_vi_pc@2x.png';
import entryFlowTitleThSp from 'assets/images/how/headTh/midasi_entry_th_sp.png';
import entryFlowTitleThPc from 'assets/images/how/headTh/midasi_entry_th_pc.png';
import entryFlowTitleThPc2x from 'assets/images/how/headTh/midasi_entry_th_pc@2x.png';
import neonBarSp from 'assets/images/neonItem/bar-main_sp.png';
import neonBarPc from 'assets/images/neonItem/bar-main_pc.png';
import neonBarPc2x from 'assets/images/neonItem/bar-main_pc@2x.png';
import pointTitleJaSp from 'assets/images/how/headJa/midasi_point_ja_sp.png';
import pointTitleJaPc from 'assets/images/how/headJa/midasi_point_ja_pc.png';
import pointTitleJaPc2x from 'assets/images/how/headJa/midasi_point_ja_pc@2x.png';
import pointTitleEnSp from 'assets/images/how/headEn/midasi_point_en_sp.png';
import pointTitleEnPc from 'assets/images/how/headEn/midasi_point_en_pc.png';
import pointTitleEnPc2x from 'assets/images/how/headEn/midasi_point_en_pc@2x.png';
import pointTitleZhCnSp from 'assets/images/how/headZhCn/midasi_point_zh_cn_sp.png';
import pointTitleZhCnPc from 'assets/images/how/headZhCn/midasi_point_zh_cn_pc.png';
import pointTitleZhCnPc2x from 'assets/images/how/headZhCn/midasi_point_zh_cn_pc@2x.png';
import pointTitleZhTwSp from 'assets/images/how/headZhTw/midasi_point_zh_tw_sp.png';
import pointTitleZhTwPc from 'assets/images/how/headZhTw/midasi_point_zh_tw_pc.png';
import pointTitleZhTwPc2x from 'assets/images/how/headZhTw/midasi_point_zh_tw_pc@2x.png';
import pointTitleKoSp from 'assets/images/how/headKo/midasi_point_ko_sp.png';
import pointTitleKoPc from 'assets/images/how/headKo/midasi_point_ko_pc.png';
import pointTitleKoPc2x from 'assets/images/how/headKo/midasi_point_ko_pc@2x.png';
import pointTitleViSp from 'assets/images/how/headVi/midasi_point_vi_sp.png';
import pointTitleViPc from 'assets/images/how/headVi/midasi_point_vi_pc.png';
import pointTitleViPc2x from 'assets/images/how/headVi/midasi_point_vi_pc@2x.png';
import pointTitleThSp from 'assets/images/how/headTh/midasi_point_th_sp.png';
import pointTitleThPc from 'assets/images/how/headTh/midasi_point_th_pc.png';
import pointTitleThPc2x from 'assets/images/how/headTh/midasi_point_th_pc@2x.png';

const { tabletPortrait, tabletLandscape, pc } = devices;

/**
 *
 * Component
 *
 */
const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

const How: FC = () => {
  // i18next
  const { t } = useTranslation();
  // Recoil言語設定
  const lang = useRecoilValue(langState);
  // current page path
  const path = useLocation().pathname.slice(1);

  return (
    <>
      <Helmet>
        <title>{t('title.system')}</title>
        <meta name="description" content={t('description.system')} />
      </Helmet>
      <SupportLayout path={path}>
        <ContainerS>
          <HeadS>
            <HeadMainCatchphraseS>
              {t(
                '世界初、必ず購入した誰かが高額当選、ミリオネアを誕生させる。',
              )}
            </HeadMainCatchphraseS>

            <HeadSubCatchphraseS>
              {t('世界最高水準還元率が驚異の75%を誇るオンラインゲームです。')}
            </HeadSubCatchphraseS>

            <TextS>
              {t(
                'エントリーするベットポイント、当選ポイントに応じたボックスが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステム。エントリーしたボックスのチケット購入数が満たされると即座に抽選が始まり、当選が確定します。世界一簡単な、世界一高額当選のできる世界一スピード感のあるオンラインゲームです。',
              )}
            </TextS>
            <DeviceTextS>
              {t('対応端末 iOS 14.8以降 Android 12.0以降')}
            </DeviceTextS>
          </HeadS>

          <EntryStepS>
            <TitlePictureS>
              <source
                srcSet={
                  lang === JAPANESE
                    ? `${entryFlowTitleJaPc} 1x,${entryFlowTitleJaPc2x} 2x`
                    : lang === SIMPLE_CHINESE
                      ? `${entryFlowTitleZhCnPc} 1x,${entryFlowTitleZhCnPc2x} 2x`
                      : lang === TRADITIONAL_CHINESE
                        ? `${entryFlowTitleZhTwPc} 1x,${entryFlowTitleZhTwPc2x} 2x`
                        : lang === KOREAN
                          ? `${entryFlowTitleKoPc} 1x,${entryFlowTitleKoPc2x} 2x`
                          : lang === VIETNAM
                            ? `${entryFlowTitleViPc} 1x,${entryFlowTitleViPc2x} 2x`
                            : lang === THAILAND
                              ? `${entryFlowTitleThPc} 1x,${entryFlowTitleThPc2x} 2x`
                              : `${entryFlowTitleEnPc} 1x,${entryFlowTitleEnPc2x} 2x`
                }
                media="(min-width: 1025px)"
              />
              <ImageS
                src={
                  lang === JAPANESE
                    ? entryFlowTitleJaSp
                    : lang === SIMPLE_CHINESE
                      ? entryFlowTitleZhCnSp
                      : lang === TRADITIONAL_CHINESE
                        ? entryFlowTitleZhTwSp
                        : lang === KOREAN
                          ? entryFlowTitleKoSp
                          : lang === VIETNAM
                            ? entryFlowTitleViSp
                            : lang === THAILAND
                              ? entryFlowTitleThSp
                              : entryFlowTitleEnSp
                }
                className={lang}
                alt=""
                loading="lazy"
              />
            </TitlePictureS>
            <StepBlockS>
              {/* STEP1 */}
              <StepBox silverText="STEP1">
                <StepTextWrapS>
                  <StepTextS>
                    {t('チケットを購入し、抽選ページにアクセス')}
                  </StepTextS>
                </StepTextWrapS>
              </StepBox>

              <ArrowWrapS>
                <GoldArrowIcon />
              </ArrowWrapS>

              {/* STEP2 */}
              <StepBox silverText="STEP2">
                <StepTextWrapS>
                  <StepTextS>
                    {t(
                      '抽選演出開始され、当選演出時にご自身で登録したユーザー名が枠内に止まりましたら当選確定',
                    )}
                  </StepTextS>
                </StepTextWrapS>
              </StepBox>

              <ArrowWrapS>
                <GoldArrowIcon />
              </ArrowWrapS>

              {/* STEP3 */}
              <StepBox silverText="STEP3">
                <StepTextWrapS>
                  <StepTextS>
                    {t('当選確定後、当選ポイントが反映され出金が可能')}
                  </StepTextS>
                </StepTextWrapS>
              </StepBox>
            </StepBlockS>
          </EntryStepS>
        </ContainerS>

        <MainNeonBarPictureS>
          <source
            srcSet={`${neonBarPc} 1x,${neonBarPc2x} 2x`}
            media="(min-width: 1025px)"
          />
          <MainNeonBarImageS src={neonBarSp} alt="" loading="lazy" />
        </MainNeonBarPictureS>

        <PointContainerS>
          <PointS>
            <PointTitlePictureS>
              <source
                srcSet={
                  lang === JAPANESE
                    ? `${pointTitleJaPc} 1x,${pointTitleJaPc2x} 2x`
                    : lang === SIMPLE_CHINESE
                      ? `${pointTitleZhCnPc} 1x,${pointTitleZhCnPc2x} 2x`
                      : lang === TRADITIONAL_CHINESE
                        ? `${pointTitleZhTwPc} 1x,${pointTitleZhTwPc2x} 2x`
                        : lang === KOREAN
                          ? `${pointTitleKoPc} 1x,${pointTitleKoPc2x} 2x`
                          : lang === VIETNAM
                            ? `${pointTitleViPc} 1x,${pointTitleViPc2x} 2x`
                            : lang === THAILAND
                              ? `${pointTitleThPc} 1x,${pointTitleThPc2x} 2x`
                              : `${pointTitleEnPc} 1x,${pointTitleEnPc2x} 2x`
                }
                media="(min-width: 1025px)"
              />
              <ImageS
                src={
                  lang === JAPANESE
                    ? pointTitleJaSp
                    : lang === SIMPLE_CHINESE
                      ? pointTitleZhCnSp
                      : lang === TRADITIONAL_CHINESE
                        ? pointTitleZhTwSp
                        : lang === KOREAN
                          ? pointTitleKoSp
                          : lang === VIETNAM
                            ? pointTitleViSp
                            : lang === THAILAND
                              ? pointTitleThSp
                              : pointTitleEnSp
                }
                alt=""
                loading="lazy"
              />
            </PointTitlePictureS>
            <PointBlockS>
              {/* POINT1 */}
              <PointBox
                pinkText="Point1"
                headText={t('ご購入された誰かが必ず高額当選')}
              >
                <PointTextWrapS>
                  <PointTextS>
                    {t(
                      "ボックス毎に設定された高額当選ポイントが、ご購入された誰かに当選します。Day Lott'sではボックスに設定されている当選ポイントは必ずユーザーに反映されます。",
                    )}
                  </PointTextS>
                </PointTextWrapS>
              </PointBox>

              {/* POINT2 */}
              <PointBox
                pinkText="Point2"
                headText={t('安心・安全・透明性のある当選')}
              >
                <PointTextWrapS>
                  <PointTextS>
                    {t(
                      "Day Lott'sでは、透明性のある運営を心がけています。全てのユーザーが当選したユーザーを確認することができます。また、個人情報の保護、入出金におけるセキュリティの強化をしているため、安全にプレイをして頂くことができます。",
                    )}
                  </PointTextS>
                </PointTextWrapS>
              </PointBox>

              {/* POINT3 */}
              <PointBox
                pinkText="Point3"
                headText={t('バリエーションのある当選ポイント設定')}
              >
                <PointTextWrapS>
                  <PointTextS>
                    {t(
                      'ユーザーのニーズを満たしたプレイボックスを多く用意しています。小さなべットエントリーで高額当選ポイントを獲得することも可能です。最低5ポイントからエントリーが可能となっており、最大100ミリオンポイントの当選も実現します。',
                    )}
                  </PointTextS>
                </PointTextWrapS>
              </PointBox>
            </PointBlockS>
          </PointS>
        </PointContainerS>
      </SupportLayout>
    </>
  );
};

export default How;

/**
 *
 * Styles
 *
 */
const ContainerS = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${tabletLandscape}, ${pc} {
    max-width: 100rem;
    margin-top: 7rem;
  }
`;

const PointContainerS = styled(ContainerS)`
  margin-top: 0rem;
  margin-bottom: 0;
  background: #000000;

  @media ${tabletLandscape}, ${pc} {
    background: none;
  }
`;

const TextS = styled.p`
  font-size: 1.4rem;
  color: #ffffff;
  line-height: 1.6;
  word-break: break-word;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const DeviceTextS = styled.p`
  font-size: 1.0rem;
  color: #ffffff;
  line-height: 1.6;
  word-break: break-word;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const ImageS = styled.img`
  width: 100%;

  &.en,
  &.zh-CN,
  &.zh-TW,
  &.ko,
  &.vi,
  &.th {
    width: 75%;

    @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
      width: 100%;
    }
  }
`;

const HeadS = styled.div`
  width: 90%;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 1rem;

  @media ${tabletPortrait} {
    row-gap: 1.4rem;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    margin-bottom: 10rem;
    row-gap: 1.8rem;
  }
`;

const HeadMainCatchphraseS = styled(TextS)`
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.5;

  @media ${tabletPortrait} {
    font-size: 2.4rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 3.3rem;
  }
`;

const HeadSubCatchphraseS = styled(TextS)`
  width: 100%;
  font-size: 1.6rem;
  color: #ffff00;
  font-weight: bold;

  @media ${tabletPortrait} {
    font-size: 2rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2.6rem;
  }
`;

const EntryStepS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitlePictureS = styled.picture`
  width: min(80vw, 330px);
  display: flex;
  justify-content: center;

  @media ${tabletPortrait} {
    width: min(50vw, 350px);
  }

  @media ${tabletLandscape}, ${pc} {
    width: 35%;
  }
`;

const PointTitlePictureS = styled(TitlePictureS)`
  width: min(60vw, 230px);
  margin-top: 5rem;

  @media ${tabletPortrait} {
    width: 250px;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 40%;
    margin: 5rem 0;
  }
`;

const StepBlockS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletPortrait} {
    width: 90%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
  }
`;

const StepTextWrapS = styled.div`
  width: 100%;
`;

const StepTextS = styled(TextS)`
  font-weight: bold;
`;

const ArrowWrapS = styled.div`
  width: 5%;

  @media ${tabletLandscape}, ${pc} {
    width: 3%;
  }
`;

const MainNeonBarPictureS = styled.picture`
  width: 100%;

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;

const MainNeonBarImageS = styled(ImageS)`
  height: 20px;
`;

const PointS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 15rem;
  }
`;

const PointBlockS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletPortrait} {
    width: 90%;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    row-gap: 5rem;
  }
`;

const PointTextWrapS = styled.div`
  width: 100%;
  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const PointTextS = styled(TextS)`
  @media ${tabletLandscape}, ${pc} {
    font-size: 1.6rem;
  }
`;
