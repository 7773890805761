import { useCallback } from 'react';
// Types
import { UfcPage } from 'types/UfcPage';

export const useUfcTransition = (): {
  transitToUfc: (page: UfcPage | null) => void;
} => {
  const transitToUfc = useCallback((page: UfcPage | null) => {
    if (page == null) {
      const url = process.env.REACT_APP_UNFOLDCITY_APP_WEB_URL
        ? `${process.env.REACT_APP_UNFOLDCITY_APP_WEB_URL}`
        : 'https://unfoldcity.net';
      window.location.replace(
        url,
      );
    } else {
      const url = process.env.REACT_APP_UNFOLDCITY_APP_WEB_URL
        ? `${process.env.REACT_APP_UNFOLDCITY_APP_WEB_URL}?page=${page}`
        : `https://unfoldcity.net?page=${page}`;
      window.location.replace(
        url,
      );
    }
  }, []);

  return {
    transitToUfc,
  };
};

// マイページ遷移
