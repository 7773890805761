import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
// Components
import CompletionLayout from 'components/templates/CompletionLayout';
// Custom hooks
import { usePasswordResetFinish } from 'hooks/pages/common/usePasswordResetFinish';
import { useRedirect } from 'hooks/versatile/useRedirect';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const PasswordResetFinish: FC = () => {
  // Hooks
  const { redirectToTopPage } = useRedirect();
  const { referrer } = usePasswordResetFinish();
  const { t } = useTranslation();

  useEffect(() => {
    // フォーム以外からアクセスした場合TOPへリダイレクト
    if (referrer !== '/password_reset/form') {
      redirectToTopPage();
    }

    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', null);
    // ブラウザバックor進むをしたらトップページにリダイレクト
    window.addEventListener('popstate', redirectToTopPage, false);

    return () => {
      window.removeEventListener('popstate', redirectToTopPage, false);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.passwordResetCompleted')}</title>
        <meta name="description" content={t('description.passwordResetCompleted')} />
      </Helmet>
      <CompletionLayout
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title="パスワードの変更が完了しました"
        to="/login"
        linkText="ログインページへ"
      />
    </>
  );
};

export default PasswordResetFinish;
