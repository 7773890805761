import { FC, useEffect, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { InView } from 'react-intersection-observer';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useTop } from 'hooks/pages/common/useTop';
// Components
import NewsScrollBox from 'components/organisms/top/NewsScrollBox';
import PlayBox1000 from 'components/organisms/top/playBox/PlayBox1000';
import PlayBox5000 from 'components/organisms/top/playBox/PlayBox5000';
import PlayBox10000 from 'components/organisms/top/playBox/PlayBox10000';
import PlayBox100000 from 'components/organisms/top/playBox/PlayBox100000';
import PlayBox1000000 from 'components/organisms/top/playBox/PlayBox1000000';
import Whats from 'components/organisms/top/Whats';
import Flow from 'components/organisms/top/Flow';
import LoaderPart from 'components/atoms/loader/LoaderPart';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Recoil
import { useRecoilValue } from 'recoil';
import { topVideoState } from 'store/atoms/topVideoState';
// Images
import newsBgSp from 'assets/images/backGround/pers_sp.jpg';
import newsBgPc from 'assets/images/backGround/pers_pc.jpg';
import newsBgPc2x from 'assets/images/backGround/pers_pc@2x.jpg';
import playBoxBgSp from 'assets/images/backGround/main_sp.jpg';
import playBoxBgPc from 'assets/images/backGround/main_pc.jpg';
import playBoxBgPc2x from 'assets/images/backGround/main_pc@2x.jpg';
import playBoxTitlePc from 'assets/images/top/playBox/title_pc.png';
import playBoxTitlePc2x from 'assets/images/top/playBox/title_pc@2x.png';
import playBoxTitleSp from 'assets/images/top/playBox/title_sp.png';
import mainNeonBar from 'assets/images/neonItem/bar-main_pc.png';
import mainNeonBar2x from 'assets/images/neonItem/bar-main_pc@2x.png';
import whatsBgPc from 'assets/images/backGround/whats_pc.jpg';
import whatsBgPc2x from 'assets/images/backGround/whats_pc@2x.jpg';

/**
 *
 * Types
 *
 */
type ContainerSProps = {
  backgroundImageSp: string;
  backgroundImagePc: string;
  backgroundImagePc2x: string;
};

/**
 *
 * Component
 *
 */
const Top: FC = memo(() => {
  // Recoil TOP動画
  const topVideo = useRecoilValue(topVideoState);

  // Hooks
  const { t } = useTranslation();
  const {
    // メインバナー
    playingVideo,
    onPlayingVideo,
    // NEWS
    newsService,
    newsPagination,
    newsIsLoading,
    isEmptyNews,
    newsErrorMessage,
    getApiNewsSelect,
    // 高額獲得者
    newsBigWinners,
    newsBigWinnersPagination,
    newsBigWinnersIsLoading,
    isEmptyWinnersNews,
    newsBigWinnersErrorMessage,
    getApiNewsBigWinnersSelect,
    // PLAYBOX
    boxRef,
    isBoxLoading,
    getApiLotSelect,
    boxEmptyMessage,
    boxErrorMessage,
    scrollToBox,
    // クリーンアップ関数
    cleanUpMethod,
  } = useTop();

  useEffect(() => {
    onPlayingVideo();

    return () => {
      cleanUpMethod();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.top')}</title>
        <meta name="description" content={t('description.top')} />
      </Helmet>
      <TopS>
        {/* メインバナー */}
        <MainBannerS>
          <ReactPlayer
            url={topVideo}
            playsinline
            playing={playingVideo}
            volume={0}
            muted
            loop
            width="100%"
            height="100%"
          />
        </MainBannerS>

        {/* NEWS・高額獲得者 */}
        <ContainerS
          className="news"
          backgroundImageSp={newsBgSp}
          backgroundImagePc={newsBgPc}
          backgroundImagePc2x={newsBgPc2x}
        >
          <NewsS>
            <InView
              as="div"
              className="news-scrollbox-wrap"
              onChange={(inView) => inView && getApiNewsSelect()}
              triggerOnce
            >
              <NewsScrollBox
                isEmpty={isEmptyNews}
                isLoading={newsIsLoading}
                scrollLastFlag={newsPagination.last}
                getApiMethod={getApiNewsSelect}
                news={newsService}
                errorMessage={newsErrorMessage}
              />
            </InView>
            <InView
              as="div"
              className="news-scrollbox-wrap"
              onChange={(inView) => inView && getApiNewsBigWinnersSelect()}
              triggerOnce
            >
              <NewsScrollBox
                isEmpty={isEmptyWinnersNews}
                isLoading={newsBigWinnersIsLoading}
                scrollLastFlag={newsBigWinnersPagination.last}
                getApiMethod={getApiNewsBigWinnersSelect}
                news={newsBigWinners}
                errorMessage={newsBigWinnersErrorMessage}
              />
            </InView>
          </NewsS>
        </ContainerS>

        {/* PLAYBOX */}
        <ContainerS
          className="playbox"
          backgroundImageSp={playBoxBgSp}
          backgroundImagePc={playBoxBgPc}
          backgroundImagePc2x={playBoxBgPc2x}
        >
          <ContainerBarS>
            <PlayBoxBarImageS
              src={mainNeonBar}
              srcSet={`${mainNeonBar2x} 2x`}
              alt=""
              loading="lazy"
            />
            <ContainerBarTitleS ref={boxRef}>
              <ContainerBarTitleImageS
                src={playBoxTitlePc}
                srcSet={`${playBoxTitlePc2x} 2x`}
                alt=""
                loading="lazy"
              />
              <ContainerBarTitleImageSpS
                src={playBoxTitleSp}
                alt=""
                loading="lazy"
              />
            </ContainerBarTitleS>
          </ContainerBarS>

          {/* ローディング */}
          {isBoxLoading && <LoaderPart />}

          <PlayBoxS>
            <InView
              as="div"
              className="playbox-wrap"
              onChange={(inView) => inView && getApiLotSelect()}
              triggerOnce
            >
              <PlayBoxWrapS>
                <PlayBox1000 />
              </PlayBoxWrapS>

              <PlayBoxWrapS>
                <PlayBox5000 />
              </PlayBoxWrapS>

              <PlayBoxWrapS>
                <PlayBox10000 />
              </PlayBoxWrapS>

              <PlayBoxWrapS>
                <PlayBox100000 />
              </PlayBoxWrapS>

              <PlayBoxWrapS>
                <PlayBox1000000 />
              </PlayBoxWrapS>
            </InView>
          </PlayBoxS>

          {/* 0件取得時メッセージ */}
          {boxEmptyMessage && (
            <MessageWrapS>
              <p>{boxEmptyMessage}</p>
            </MessageWrapS>
          )}

          {/* エラーメッセージ */}
          {boxErrorMessage && (
            <MessageWrapS>
              <ErrorMessage>{boxErrorMessage}</ErrorMessage>
            </MessageWrapS>
          )}
        </ContainerS>

        {/* サービス概要 */}
        <ContainerS
          className="what"
          backgroundImageSp=""
          backgroundImagePc={whatsBgPc}
          backgroundImagePc2x={whatsBgPc2x}
        >
          <ContainerBarS>
            <ContainerBarImageS
              src={mainNeonBar}
              srcSet={`${mainNeonBar2x} 2x`}
              alt=""
              loading="lazy"
            />
          </ContainerBarS>
          <Whats />
        </ContainerS>

        {/* 登録の流れ */}
        <ContainerS
          className="flow"
          backgroundImageSp=""
          backgroundImagePc=""
          backgroundImagePc2x=""
        >
          <Flow _ref={boxRef} scrollToBox={scrollToBox} />
        </ContainerS>
      </TopS>
    </>
  );
});
export default Top;

/**
 *
 * Styles
 *
 */
const TopS = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainBannerS = styled.div`
  width: 100%;
  min-height: 60vh;
  background-color: #000000;

  @media (min-width: 1025px) {
    min-height: 800px;
  }

  iframe {
    display: block;
  }
`;

const ContainerS = styled.div<ContainerSProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: url(${({ backgroundImageSp }) => backgroundImageSp});
  background-attachment: fixed;
  background-size: cover;

  @media (min-width: 600px) {
    background: image-set(
      url(${({ backgroundImagePc }) => backgroundImagePc}) 1x,
      url(${({ backgroundImagePc2x }) => backgroundImagePc2x}) 2x
    );
  }

  &.news {
    background-position: top;
    background-size: cover;
    position: relative;

    @media (min-width: 1025px) {
      background-position: center;
    }

    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      background-color: rgba(0, 0, 0, 0.2);

      @media (min-width: 1025px) {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &.playbox {
    position: relative;
    min-height: 500px;
    background-position: top;
    background-size: cover;
  }

  &.what {
    background-size: cover;
    background-color: #000000;
  }

  &.flow {
    background-size: cover;
    background-color: #000000;
    padding: 2rem 0;

    @media (min-width: 600px) {
      padding: 3rem 0 5rem;
    }

    @media (min-width: 1025px) {
      padding: 5rem 0 8rem;
    }
  }
`;

const ContainerBarS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ContainerBarImageS = styled.img`
  height: 10px;
  width: 100%;
  z-index: 2;

  @media (min-width: 1025px) {
    height: 15px;
  }
`;

const PlayBoxBarImageS = styled(ContainerBarImageS)`
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }
`;

const ContainerBarTitleS = styled.div`
  width: 100%;
  margin-top: max(-13%, -60px);
  z-index: 2;

  @media (min-width: 600px) {
    width: min(40%, 280px);
    margin-top: max(-7%, -50px);
  }

  @media (min-width: 1025px) {
    width: min(25%, 340px);
    margin-top: max(-5%, -65px);
  }
`;

const ContainerBarTitleImageS = styled.img`
  width: 100%;
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }
`;

const ContainerBarTitleImageSpS = styled.img`
  width: 100%;

  @media (min-width: 600px) {
    display: none;
  }
`;

const NewsS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  margin: 4rem 0 7rem;

  @media (min-width: 600px) {
    width: 95%;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  @media (min-width: 1025px) {
    max-width: 100rem;
    margin: 6rem 0 10rem;
  }

  .news-scrollbox-wrap {
    width: 100%;

    @media (min-width: 600px) {
      width: 47.5%;
    }

    @media (min-width: 1025px) {
      justify-content: center;
      align-items: center;
    }

    &:first-child {
      margin-bottom: 3rem;

      @media (min-width: 600px) {
        margin-bottom: 0;
      }
    }
  }
`;

const PlayBoxS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: 1rem 0 2rem;

  @media (min-width: 600px) {
    margin: 2rem 0 3rem;
  }

  @media (min-width: 1025px) {
    width: 100%;
    max-width: 100rem;
    margin: 5rem 0;
  }
`;

const PlayBoxWrapS = styled.div`
  margin-bottom: 1rem;

  &:last-child,
  &:only-child {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    margin-bottom: 2.4rem;
  }

  @media (min-width: 1025px) {
    margin-bottom: 5rem;
  }
`;

const MessageWrapS = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-size: min(6vw, 1.2rem);

  @media (min-width: 600px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.7rem;
  }
`;
