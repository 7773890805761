import { useRef, useEffect, useState } from 'react';
// Recoil
import { useRecoilValue } from 'recoil';
import { entryUsersState } from 'store/atoms/lottery/entryUsersState';
// Types
import { EntryUsers } from 'types/lottery/entryUsers';
import { LIMIT_PER_PAGE } from '../../../utils/const/lottery/entryUsers';

/**
 *
 * Types
 *
 */
type UseEntryUserBox = () => {
  loginUserRef: React.RefObject<HTMLLIElement>;
  specialLoginUserRef: React.RefObject<HTMLLIElement>;
  entryUsersBoxRef: React.RefObject<HTMLUListElement>;
  entryUserReadMoreRef: React.RefObject<HTMLDivElement>;
  entryUserListRef: React.RefObject<HTMLLIElement>
  entryUsers: EntryUsers[];
};

export const useEntryUserBox: UseEntryUserBox = () => {
  const entryUsers = useRecoilValue(entryUsersState);
  const [scrollTop, setScrollTop] = useState<number>(0);

  const loginUserRef = useRef<HTMLLIElement | null>(null);
  const specialLoginUserRef = useRef<HTMLLIElement | null>(null);
  const entryUsersBoxRef = useRef<HTMLUListElement | null>(null);
  const entryUserReadMoreRef = useRef<HTMLDivElement | null>(null);
  const entryUserListRef = useRef<HTMLLIElement | null>(null);

  // ログインユーザーの位置までスクロール(特別枠優先)
  const scrollToLoginUser = () => {
    if (specialLoginUserRef.current !== null) {
      specialLoginUserRef.current.scrollIntoView({
        block: 'start',
      });
    } else if (loginUserRef.current !== null) {
      loginUserRef.current.scrollIntoView({
        block: 'start',
      });
    }
    window.scroll({
      top: 0,
    });
  };

  const rememberScroll = () => {
    if (entryUsersBoxRef.current == null) {
      return;
    }
    const topPosition = entryUsersBoxRef.current?.scrollTop;
    setScrollTop(topPosition);
  };

  const scrollToPrevPosition = () => {
    if (entryUserReadMoreRef.current == null) {
      return;
    }
    const readMoreHeight = entryUserReadMoreRef.current?.offsetHeight;
    const entryUserListHeight = loginUserRef.current?.offsetHeight
      || specialLoginUserRef.current?.offsetHeight
      || entryUserListRef?.current?.offsetHeight
      || 38.4;
    if (scrollTop > readMoreHeight) {
      entryUsersBoxRef.current?.scrollTo(0, scrollTop);
    } else {
      entryUsersBoxRef.current?.scrollTo(0,
        scrollTop + entryUserListHeight * (LIMIT_PER_PAGE - 1) + readMoreHeight);
    }
  };

  useEffect(() => {
    scrollToLoginUser();
  }, []);

  useEffect(() => {
    scrollToPrevPosition();
    entryUsersBoxRef.current?.addEventListener('scroll', rememberScroll);
  }, [entryUsers]);

  return {
    loginUserRef,
    specialLoginUserRef,
    entryUsersBoxRef,
    entryUserReadMoreRef,
    entryUserListRef,
    entryUsers,
  };
};
