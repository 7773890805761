export const ATOM_KEYS = {
  USER_STATE: 'userState',
  LANG_STATE: 'langState',
  PLAYBOX_LIST_STATE: 'playBoxListState',
  PLAYBOX_SHOW_STATE: 'playBoxShowState',
  PRODUCTION_END_FLAG_STATE: 'productionEndFlagState',
  LOTTERY_SKIP_FLAG_STATE: 'lotterySkipFlagState',
  EARNED_POINT_SHOW_FLAGS_STATE: 'earnedPointShowFlagsState',
  EARNEDPOINTS_GROUPS_STATE: 'earnedPointsGroupsState',
  EARNEDPOINTS_STATE: 'earnedPointsState',
  ENTRY_USERS_STATE: 'entryUsersState',
  RANKING_STATE: 'rankingState',
  WINNERS_STATE: 'winnersState',
  SPECIAL_POINTS_STATE: 'specialPointsState',
  FIRST_WINNER_STATE: 'firstWinnerState',
  SECOND_WINNERS_STATE: 'secondWinnersState',
  THIRD_WINNERS_STATE: 'thirdWinnersState',
  FOURTH_WINNERS_STATE: 'fourthWinnersState',
  FIFTH_WINNERS_STATE: 'fifthWinnersState',
  IS_CHANGE_LANG_STATE: 'isChangeLangState',
  TOP_VIDEO_STATE: 'topVideoState',
  UNLOTTERY_BOXES_STATE: 'unlotteryBoxesState',
  MOVIE_LIST: 'movieList',
  CURRENT_RANK_RESULT: 'currentRankResult',
} as const;

export const SELECTOR_KEYS = {
  USER_BETS_COUNT_DISPLAY_STATE: 'userBetsCountDisplayState',
  USER_POINT_DISPLAY_STATE: 'userPointDisplayState',
} as const;
