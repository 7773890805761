import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import NewsLayout from 'components/templates/NewsLayout';
import RoundButton from 'components/atoms/button/RoundButton';
// Utils
import { newsUsersSkipBreadCrumpItems } from 'utils/breadCrumbs';
import { useNewsUsersSkip } from 'hooks/pages/private/useNewsUsersSkip';

/**
 *
 * Component
 *
 */
const NewsUsersSkip: FC = memo(() => {
  // Hooks
  const { t } = useTranslation();
  const { isLoading, goBack, onLotCheck } = useNewsUsersSkip();

  return (
    <>
      <Helmet>
        <title>{t('title.userNewsSkip')}</title>
        <meta name="description" content={t('description.userNewsSkip')} />
      </Helmet>
      <NewsLayout
        breadCrumbItems={[
          ...newsUsersSkipBreadCrumpItems,
        ]}
      >
        {/* コンテンツ */}
        <ContainerS>
          <HeadingS>{t('動画を再生せずに獲得した全てのポイントを受け取る')}</HeadingS>
          <ButtonGroupS>
            <ButtonWrapS>
              {isLoading ? (
                <RoundButton
                  background="#555555"
                  borderColor="rgba(230, 230, 230, 1)"
                >
                  {t('受け取る')}
                </RoundButton>
              ) : (
                <RoundButton
                  onClick={onLotCheck}
                  background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                  borderColor="rgba(219,0,177,1)"
                >
                  {t('受け取る')}
                </RoundButton>
              )}
            </ButtonWrapS>

            <ButtonWrapS>
              <RoundButton
                onClick={goBack}
                background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                borderColor="rgba(0,135,167,1)"
              >
                {t('戻る')}
              </RoundButton>
            </ButtonWrapS>
          </ButtonGroupS>
        </ContainerS>
      </NewsLayout>
    </>
  );
});

export default NewsUsersSkip;

/**
 *
 * Styles
 *
 */
const ContainerS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: relative;
  color: #ffffff;
  padding: 4rem 2rem;

  @media (min-width: 600px) {
    padding: 4rem 6rem;
  }

  @media (min-width: 1025px) {
    padding: 6rem 8rem 7rem;
  }
`;

const HeadingS = styled.h1`
  font-size: clamp(1rem, 3.6vw, 1.8rem);
  line-height: 1.5;
  word-break: break-all;
  font-weight: bold;
  margin-bottom: 3rem;

  @media (min-width: 600px) {
    font-size: 1.8rem;
  }

  @media (min-width: 1025px) {
    font-size: 2rem;
    margin-bottom: 4rem;
  }
`;

const ButtonGroupS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.8rem;

  @media (min-width: 1025px) {
    row-gap: 2.2rem;
  }
`;

const ButtonWrapS = styled.div`
  font-size: 1.4rem;
  width: 200px;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }
`;
