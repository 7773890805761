import { FC, memo } from 'react';
// External library
import styled from 'styled-components';

/**
 *
 * Component
 *
 */
const NeonShadowBorder: FC = memo(() => (
  <NeonShadowBorderS>
    <ShadowS />
    <BorderS />
  </NeonShadowBorderS>
));
export default NeonShadowBorder;

/**
 *
 * Styles
 *
 */
const NeonShadowBorderS = styled.div`
  width: 100%;
  position: relative;
`;

const ShadowS = styled.div`
  height: 10px;
  width: 100%;
  position: absolute;
  top: -2.5px;
  left: 0;
  z-index: 1;
  background: linear-gradient(45deg, #004eff, #f500ff);
  border-radius: 9999px;
  filter: blur(3px);
`;

const BorderS = styled.div`
  width: 99%;
  position: absolute;
  top: 1px;
  left: 0.5%;
  z-index: 2;
  background-color: #ffffff;
  border: 1.5px solid #ffffff;
  border-radius: 9999px;
`;
