import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Image
import iconUpload from 'assets/images/kycupload/icon_upload.svg';

/**
 *
 * Types
 *
 */
type Props = {
  name: string;
  htmlFor: string;
};

/**
 *
 * Component
 *
 */
const FileTextLabel: FC<Props> = memo((props) => {
  const { name, htmlFor } = props;

  return (
    <FileTextLabelS htmlFor={htmlFor}>
      <IconS>
        <IconImageS src={iconUpload} />
      </IconS>
      <NameS>{name}</NameS>
    </FileTextLabelS>
  );
});
export default FileTextLabel;

/**
 *
 * Styles
 *
 */
const FileTextLabelS = styled.label`
  font-size: inherit;
  width: 100%;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  justify-content: flex-end;

  &:hover {
    @media (min-width: 1025px) {
      opacity: 0.9;
    }
  }
`;

const IconS = styled.div`
  width: calc(1em * 1.1);
`;

const IconImageS = styled.img`
  width: 100%;
`;

const NameS = styled.p`
  font-size: inherit;
  color: #fff040;
`;
