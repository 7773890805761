import { atom } from 'recoil';
import { ATOM_KEYS } from 'utils/const/recoilKeys';
import { LotResultPointType } from 'types/api';

export const earnedPointsGroupsState = atom<LotResultPointType[]>({
  key: ATOM_KEYS.EARNEDPOINTS_GROUPS_STATE,
  default: [],
});

export const earnedPointsState = atom<number>({
  key: ATOM_KEYS.EARNEDPOINTS_STATE,
  default: 0,
});
