import React, {
  useState, useCallback, useEffect, ChangeEvent, useRef,
} from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
// Hooks
import { useRedirect } from 'hooks/versatile/useRedirect';
// Custom Hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosCancelToken, axiosClient } from 'utils/axios';
import { CRYPTCURRENCY, MAXIMUM_PURCHASE_CREDIT, MINIMUM_PURCHASE_POINT } from 'utils/const/payment';
// Types
import type { GetAddress } from 'types/api';
import type { CcLocationState } from 'types/paymentType';
import {
  ApiErrorType, PostPaymentType, RateSaveType, RateType,
} from 'types/api';
import { AxiosError } from 'axios';

/**
 *
 * Types
 *
 */
type UsePointPurchaseCc = () => {
  isLoading: boolean;
  displayedCommission: string;
  pointInputProps: InputProps;
  referrer: string;
  point: string;
  selectedCurrency: CryptocurrencyType;
  rateCc: number;
  rateJpy: number;
  displayedCc: number;
  getApiAddress: () => void;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  copyAddress: () => void;
  goBack: () => void;
  cleanUpMethod: () => void;
  lessPointErrorMessage: string;
};

// 仮想通貨種別
type CryptocurrencyType = typeof CRYPTCURRENCY[number];
type CurrencyRates = {[key in CryptocurrencyType]: number}

// 購入方法
type OnChangeEvent = (event: ChangeEvent<HTMLInputElement>) => void;

type InputProps = {
  type: string;
  value: string;
  onChange: OnChangeEvent;
  onBlur?: () => void;
};

/**
 *
 * Hooks
 *
 */
export const usePointPurchaseCc: UsePointPurchaseCc = () => {
  // ステート一覧
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rateCc, setRateCc] = useState<number>(0);
  const [rateJpy, setRateJpy] = useState<number>(0);
  const [point, setPoint] = useState<string>(MINIMUM_PURCHASE_POINT);
  const [displayedCommission, setDisplayedCommission] = useState('');

  // ポイント購入ページからの取得内容
  const location = useLocation<CcLocationState | undefined>();
  const alternativeLocationState: CcLocationState = {
    referrer: '',
    point: '',
    selectedCurrency: 'BTC',
    needBtc: 0,
    needEth: 0,
    needUsdt: 0,
  };
  const {
    selectedCurrency,
    referrer,
    needBtc,
    needEth,
    needUsdt,
  } = location.state ?? alternativeLocationState;

  // 送金アドレス取得API
  const [address, setAddress] = useState<string>('');
  const getAddressSource = axiosCancelToken.source();
  const getApiAddress = () => {
    setIsLoading(true);

    axiosClient
      .get<GetAddress>(
        '/api/users/getaddress',
        {
          cancelToken: getAddressSource.token,
        },
      )
      .then(({ data }) => {
        const { btc_address, eth_address } = data;
        switch (selectedCurrency) {
          case 'BTC':
            setAddress(btc_address ?? '');
            break;
          case 'USDT':
          case 'ETH':
            setAddress(eth_address ?? '');
            break;
          default:
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const { t } = useTranslation();
  const copyAddress = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        toast.success(t('コピーしました。'));
      })
      .catch(() => {
        toast.error(t('コピー失敗しました。'));
      });
  };

  const history = useHistory();
  // ポイント購入ページへ遷移
  const goBack = useCallback(() => {
    history.push('/point_purchase');
  }, []);

  // エラー用カスタムフック
  const { displayErrorMessageInToast } = useError();

  // 購入ポイントが最低購入ポイント数未満かどうかのチェック関数
  const checkIsLessPoint = () => Number(point) < Number(MINIMUM_PURCHASE_POINT);

  const ccRateValuesRef = useRef<CurrencyRates>({
    BTC: 0,
    USDT: 0,
    ETH: 0,
  });

  // レートデータリクエスト
  const rateSource = axiosCancelToken.source();
  const postApiRate = () => {
    if (checkIsLessPoint()) {
      return;
    }

    if (!isLoading) {
      setIsLoading(true);

      // axios POST /api/rate
      axiosClient
        .post<RateType>('/api/rate', {
          point,
          cancelToken: rateSource.token,
        })
        .then(({
          data: {
            BTC, USDT, ETH, jpy, commission,
          },
        }) => {
          // 選択した仮想通貨によってstateの値切り替え
          ccRateValuesRef.current = { BTC, USDT, ETH };
          switch (selectedCurrency) {
            case 'BTC':
              setRateCc(ccRateValuesRef.current.BTC);
              setDisplayedCc(BTC);
              break;
            case 'USDT':
              setRateCc(ccRateValuesRef.current.USDT);
              setDisplayedCc(USDT);
              break;
            case 'ETH':
              setRateCc(ccRateValuesRef.current.ETH);
              setDisplayedCc(ETH);
              break;
            default:
          }
          setRateJpy(jpy);
          setDisplayedCommission(commission);
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          displayErrorMessageInToast(error);
          setPoint('0');
          setRateCc(0);
          setRateJpy(0);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const checkInputPoint: OnChangeEvent = (event) => {
    const { value } = event.currentTarget;
    // 半角数字以外の場合は空文字に
    if (!/^\d+$/.exec(value)) {
      setPoint('0');
    } else {
      setPoint(value);
    }
  };

  const pointInputProps: InputProps = {
    type: 'text',
    value: point,
    onChange: checkInputPoint,
    onBlur: postApiRate,
  };

  // API レート保存
  const rateSaveSource = axiosCancelToken.source();
  const postApiRateSave = (payment_type: PostPaymentType) => (
    axiosClient
      .post<RateSaveType>('/api/rate/save', {
        point,
        payment_type,
        cancelToken: rateSaveSource.token,
      })
  );

  const [
    lessPointErrorMessage,
    setLessPointErrorMessage,
  ] = useState('');

  // 購入ボタン押下
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClickPurchase = async () => {
    setLessPointErrorMessage('');

    if (checkIsLessPoint()) {
      setLessPointErrorMessage('ポイントが不足しています');

      return;
    }

    try {
      setIsLoading(true);

      const { data } = await postApiRateSave(selectedCurrency);
      const state: CcLocationState = {
        referrer: '/point_purchase',
        point,
        needBtc: data.need_btc,
        needEth: data.need_eth,
        needUsdt: data.need_usdt,
        selectedCurrency,
      };
      history.push({
        pathname: '/point_purchase/cc',
        state,
      });
    } catch (e) {
      displayErrorMessageInToast(e);
      setIsLoading(false);
    }
  };

  // クリーンアップ関数
  const cleanUpMethod = () => {
    setIsLoading(false);
    setPoint('0');
    setRateCc(0);
    setRateJpy(0);
    rateSource.cancel();
    getAddressSource.cancel();
    rateSource.cancel();
    rateSaveSource.cancel();
  };

  const [displayedCc, setDisplayedCc] = useState(0);
  const { redirectToTopPage } = useRedirect();
  useEffect(() => {
    if (referrer !== '/point_purchase') {
      redirectToTopPage();
    }

    window.scrollTo({
      top: 0,
    });
    switch (selectedCurrency) {
      case 'BTC':
        setDisplayedCc(needBtc);
        break;
      case 'USDT':
        setDisplayedCc(needUsdt);
        break;
      case 'ETH':
        setDisplayedCc(needEth);
        break;
      default:
    }

    getApiAddress();
    postApiRate();

    return cleanUpMethod;
  }, []);

  return {
    isLoading,
    displayedCommission,
    pointInputProps,
    referrer,
    point,
    selectedCurrency,
    rateCc,
    rateJpy,
    displayedCc,
    getApiAddress,
    address,
    setAddress,
    copyAddress,
    goBack,
    cleanUpMethod,
    lessPointErrorMessage,
  };
};
