import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import CompletionLayout from 'components/templates/CompletionLayout';
// Hooks
import { usePointPurchaseFinish } from 'hooks/pages/private/usePointPurchaseFinish';
// Images
import backGroundSp from 'assets/images/backGround/pers_sp.jpg';
import backGroundPc from 'assets/images/backGround/pers_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/pers_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const PointPurchaseFinish: FC = memo(() => {
  // hooks
  const { t } = useTranslation();
  usePointPurchaseFinish();

  return (
    <>
      <Helmet>
        <title>{t('title.pointPurchaseCompleted')}</title>
        <meta
          name="description"
          content={t('description.pointPurchaseCompleted')}
        />
      </Helmet>
      <CompletionLayout
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title="入金情報送信完了"
        to="/mypage"
        linkText="マイページへ戻る"
      >
        <TextS>
          {t(
            '確認ができ次第メールにてお知らせいたします。今しばらくお待ちください。',
          )}
        </TextS>
      </CompletionLayout>
    </>
  );
});
export default PointPurchaseFinish;

/**
 *
 * Styles
 *
 */
const TextS = styled.p`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 5rem;
  text-align: center;

  @media (min-width: 600px) {
    width: 60%;
    font-size: 1.6rem;
  }

  @media (min-width: 1025px) {
    width: 40%;
  }
`;
