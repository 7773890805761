export const devices = {
  mobileLandscape:
    '(min-width: 568px) and (max-width: 926px) and (orientation: landscape)', // スマホ横向き
  tabletPortrait:
    '(min-width: 600px) and (max-width: 1024px) and (orientation: portrait)', // タブレット縦向き
  tabletLandscape: '(min-width: 1024px) and (orientation: landscape)', // タブレット横向き
  pc: '(min-width: 1025px)', // PC
  tabletLandscapeAndPc:
    '(min-width: 1024px) and (orientation: landscape), (min-width: 1025px)',
};

export const ORIENTATION = {
  PORTRAIT: 0,
};
