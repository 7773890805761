import {
  useState, useEffect, useCallback, useRef,
} from 'react';
// External libarary
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// Custom hooks
import { useApiLotCheck } from 'hooks/versatile/api/useApiLotCheck';
// Recoil
import { entryUsersState } from 'store/atoms/lottery/entryUsersState';
import {
  specialPointsState,
  winnersState,
} from 'store/atoms/lottery/winnersState';
import { firstWinnerState } from 'store/atoms/lottery/firstWinnerState';
import { secondWinnersState } from 'store/atoms/lottery/secondWinnersState';
import { thirdWinnersState } from 'store/atoms/lottery/thirdWinnersState';
import { fourthWinnersState } from 'store/atoms/lottery/fourthWinnersState';
import { earnedPointShowFlagsState } from 'store/atoms/lottery/earnedPointShowFlagsState';
import { earnedPointsGroupsState, earnedPointsState } from 'store/atoms/lottery/earnedPointsState';
import { rankingState } from 'store/atoms/lottery/rankingState';
import { userState } from 'store/atoms/userState';
import { productionEndFlagState } from 'store/atoms/lottery/productionEndFlag';
import { currentRankResultState, movieList } from 'store/atoms/lottery/movieList';
// Utils
import { PLAYBOX_TYPE } from 'utils/const/playBox';
import { WINNERS_RANK_UP_TO_FIFTH, WINNERS_RANK_UP_TO_FOURTH } from 'utils/const/lottery/winners';
import {
  PRIZE_100000POINT_EVENT_VIDEOS,
} from 'utils/const/video';
import { EVENT_ENABLED, EVENT_NEWS } from 'utils/const/event';
// Types
import { PRODUCTION_END_FLAG } from 'utils/const/lottery/lotBox';
import { fifthWinnersState } from '../../../store/atoms/lottery/fifthWinnersState';

const {
  BOX_5000,
  BOX_10000,
  BOX_100000,
  BOX_1000000,
} = PLAYBOX_TYPE;

const {
  FIRST, SECOND, THIRD, FOURTH, FIFTH,
} = WINNERS_RANK_UP_TO_FIFTH;

const PRIZE = {
  ZEUS: 'zeus',
  KING: 'king',
  DIAMOND: 'diamond',
  GOLD_DOUBLE: 'gold_double',
  GOLD_SINGLE: 'gold_single',
  SILVER: 'silver',
  BRONZE: 'bronze',
  OTHER: 'other',
} as const;

const SELECTED_PEOPLE_NUMBER = 37;

/**
 *
 * Types
 *
 */
export type PlayBoxType = typeof PLAYBOX_TYPE[keyof typeof PLAYBOX_TYPE];

type Prize = typeof PRIZE[keyof typeof PRIZE];

type UsePrize1000or5000WebGl = (playboxType: PlayBoxType) => {
  movieUrl: string;
  isPlaying: boolean;
  isShowAudioButton: boolean;
  mute: boolean;
  onMuted: () => void;
  onUnMuted: () => void;
  onClickSkip: () => void;
  onMovieReady: () => void;
  onMovieEnded: () => void;
  isShowBlackOut: boolean;
};

/**
 *
 * Hooks
 *
 */
export const useVimeoVideo: UsePrize1000or5000WebGl = (playboxType) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [movieUrl, setMovieUrl] = useState<string>('');
  // mute
  const [isShowAudioButton, setIsShowAudioButton] = useState<boolean>(false);
  const [mute, setMute] = useState<boolean>(true);
  const onMuted = useCallback(() => {
    setMute(true);
  }, []);
  const onUnMuted = useCallback(() => {
    setMute(false);
  }, []);
  const onMovieReady = useCallback(() => {
    setIsPlaying(true);
  }, []);
  const onMovieEnded = useCallback(() => {
    nextMovie();
  }, []);

  // user_names取得カスタムフック
  const entryUsers = useRecoilValue(entryUsersState);
  const specialPoints = useRecoilValue(specialPointsState);
  const fifthWinners = useRecoilValue(fifthWinnersState);
  const fourthWinners = useRecoilValue(fourthWinnersState);
  const thirdWinners = useRecoilValue(thirdWinnersState);
  const secondWinners = useRecoilValue(secondWinnersState);
  const firstWinner = useRecoilValue(firstWinnerState);
  const setWinners = useSetRecoilState(winnersState);
  const setRanking = useSetRecoilState(rankingState);
  const setMovieList = useRecoilValue(movieList);

  const setCurrentResultRank = useSetRecoilState(currentRankResultState);

  // ログインユーザーの獲得ポイント更新
  const loginUser = useRecoilValue(userState);
  const earnedPointsGroups = useRecoilValue(earnedPointsGroupsState);
  const [earnedPoints, setEarnedPoints] = useRecoilState(earnedPointsState);
  const updateEarnedPoint = (rank :string) => {
    const newEarnedPoints = earnedPointsGroups.find((ep) => ep.rank === Number(rank))?.point ?? 0;
    setEarnedPoints((prev) => prev + newEarnedPoints);
  };

  // lot_table_id
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const lotTableId = query.get('id') ?? '';

  // 獲得ポイント反映API
  const { lotCheckSource, postApiLotCheck } = useApiLotCheck();
  const cleanup = useCallback(() => {
    lotCheckSource.cancel();
  }, []);
  useEffect(() => cleanup, [earnedPoints]);

  const setEarnedPointShowFlags = useSetRecoilState(earnedPointShowFlagsState);

  // 各当選者情報更新
  const updateFifthWinners = () => {
    setCurrentResultRank(FIFTH);
    setWinners((prevState) => (
      prevState.map((v) => (
        {
          ...v,
          isShow: v.rank === FIFTH,
        }
      ))
    ));
    updateEarnedPoint(FIFTH);
    setEarnedPointShowFlags((_flags) => (
      [..._flags, ...fifthWinners.map((user) => user.betId)]
    ));
  };
  const updateFourthWinners = () => {
    setCurrentResultRank(FOURTH);
    setWinners((prevState) => (
      prevState.map((v) => (
        {
          ...v,
          isShow: v.rank === FOURTH || v.rank === FIFTH,
        }
      ))
    ));
    updateEarnedPoint(FOURTH);
    setEarnedPointShowFlags((_flags) => (
      [..._flags, ...fourthWinners.map((user) => user.betId)]
    ));
  };
  const updateThirdWinners = () => {
    setCurrentResultRank(THIRD);
    setWinners((prevState) => (
      prevState.map((v) => (
        {
          ...v,
          isShow: v.rank === THIRD || v.rank === FOURTH || v.rank === FIFTH,
        }
      ))
    ));
    updateEarnedPoint(THIRD);
    setEarnedPointShowFlags((_flags) => (
      [..._flags, ...thirdWinners.map((user) => user.betId)]
    ));
  };
  const updateSecondWinners = () => {
    setCurrentResultRank(SECOND);
    setWinners((prevState) => (
      prevState.map((v) => (
        { ...v, isShow: v.rank !== FIRST }
      ))
    ));
    updateEarnedPoint(SECOND);
    setEarnedPointShowFlags((_flags) => (
      [..._flags, ...secondWinners.map((user) => user.betId)]
    ));
  };
  const updateFirstWinner = () => {
    setCurrentResultRank(FIRST);
    setWinners((prevState) => (
      prevState.map((v) => (
        { ...v, isShow: true }
      ))
    ));
    updateEarnedPoint(FIRST);
    setEarnedPointShowFlags(entryUsers.map((v) => v.betId));
  };

  const getVideoUrl = (argPrize: Prize) => {
    switch (argPrize) {
      // TODO:
      case PRIZE.ZEUS:
      case PRIZE.KING:
      {
        return setMovieList.KING_MOVIE_URL;
      }
      case PRIZE.DIAMOND: {
        return setMovieList.DIAMOND_MOVIE_URL;
      }
      case PRIZE.GOLD_DOUBLE: {
        return setMovieList.GOLD_MOVIE_URL;
      }
      case PRIZE.GOLD_SINGLE: {
        return setMovieList.GOLD_MOVIE_URL;
      }
      case PRIZE.SILVER: {
        return setMovieList.SILVER_MOVIE_URL;
      }
      case PRIZE.BRONZE: {
        return setMovieList.BRONZE_MOVIE_URL;
      }
      case PRIZE.OTHER: {
        return setMovieList.OTHER_MOVIE_URL;
      }
      default: {
        return setMovieList.BRONZE_MOVIE_URL;
      }
    }
  };

  const setProductionEndFlag = useSetRecoilState(productionEndFlagState);

  const prepareMovie = useCallback((argPrize: Prize) => {
    setTimeout(() => {
      setIsShowAudioButton(true);
      setMovieUrl(getVideoUrl(argPrize));
      setIsPlaying(true);
    }, 100);
  }, []);

  const prize = useRef<Prize>(
    playboxType === BOX_100000
      ? PRIZE.OTHER
      : PRIZE.BRONZE,
  );
  if (EVENT_ENABLED && Number(lotTableId) === EVENT_NEWS.ID) {
    prize.current = PRIZE.KING;
  }
  prepareMovie(prize.current);

  const nextMovie = () => {
    setIsShowAudioButton(false);

    switch (prize.current) {
      case PRIZE.OTHER: {
        if (playboxType === BOX_100000) {
          updateFifthWinners();
          prepareMovie(PRIZE.BRONZE);
          prize.current = PRIZE.BRONZE;
        }
        break;
      }
      case PRIZE.BRONZE: {
        if (playboxType === BOX_10000 || playboxType === BOX_100000) {
          updateFourthWinners();
        } else {
          updateThirdWinners();
        }

        prepareMovie(PRIZE.SILVER);
        prize.current = PRIZE.SILVER;
        break;
      }
      case PRIZE.SILVER: {
        if (playboxType === BOX_10000 || playboxType === BOX_100000) {
          updateThirdWinners();
          prepareMovie(PRIZE.GOLD_DOUBLE);
          prize.current = PRIZE.GOLD_DOUBLE;
        } else {
          updateSecondWinners();
          prepareMovie(PRIZE.GOLD_SINGLE);
          prize.current = PRIZE.GOLD_SINGLE;
        }
        break;
      }
      case PRIZE.GOLD_DOUBLE: {
        updateSecondWinners();
        if (playboxType === BOX_100000) {
          prepareMovie(PRIZE.KING);
          prize.current = PRIZE.KING;
        } else {
          prepareMovie(PRIZE.DIAMOND);
          prize.current = PRIZE.DIAMOND;
        }
        break;
      }
      case PRIZE.GOLD_SINGLE:
      case PRIZE.DIAMOND:
      case PRIZE.KING:
      case PRIZE.ZEUS:
      {
        setIsShowBlackOut(true);
        postApiLotCheck(lotTableId);
        updateFirstWinner();
        setRanking((prevState) => prevState.map((v) => ({ ...v, isShow: true })));
        setProductionEndFlag(PRODUCTION_END_FLAG.FALSE);
        goToHistory();
        break;
      }
      default: {
        const strangeCase: never = prize.current;
        /* eslint-disable @typescript-eslint/restrict-template-expressions */
        throw new Error(`${strangeCase} is not case.`);
        /* eslint-enable @typescript-eslint/restrict-template-expressions */
      }
    }
  };

  // 抽選演出スキップ
  const [isShowBlackOut, setIsShowBlackOut] = useState(false);
  const onClickSkip = () => {
    setMovieUrl('');
    setIsShowBlackOut(true);
    setIsShowAudioButton(false);
    onMuted();
    let points = 0;
    const winners = [
      firstWinner,
      ...secondWinners,
      ...thirdWinners,
      ...fourthWinners,
      ...fifthWinners,
    ];
    winners.forEach((user) => {
      if (user?.userId === loginUser.id) points += user?.point;
    });
    points += specialPoints;
    if (points > 0) setEarnedPoints(points);
    const betIds = entryUsers.map((user) => user.betId);
    setEarnedPointShowFlags([...betIds]);
    setWinners((prevState) => (
      prevState.map((v) => (
        { ...v, isShow: true }
      ))
    ));
    setRanking((prevState) => prevState.map((v) => ({ ...v, isShow: true })));
    setProductionEndFlag(PRODUCTION_END_FLAG.FALSE);
    postApiLotCheck(lotTableId);
    goToHistory();
  };
  const goToHistory = () => {
    setTimeout(() => {
      history.push({
        pathname: '/mypage',
        state: {
          initialMenu: 'history',
        },
      });
    }, 5000);
  };

  const history = useHistory();

  return {
    isPlaying,
    movieUrl,
    isShowAudioButton,
    mute,
    onMuted,
    onUnMuted,
    onClickSkip,
    onMovieReady,
    onMovieEnded,
    isShowBlackOut,
  };
};
