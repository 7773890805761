import { useState } from 'react';

/**
 * よくある質問用カスタムフック
 */
export const useQuestion = (): {
  isShowAnswer: boolean;
  onClickShowAnswer: () => void;
  // クリーンアップ関数
  cleanUpMethod: () => void;
} => {
  // 質問の答えの開閉ステート
  const [isShowAnswer, setIsShowAnswer] = useState<boolean>(false);

  // 質問の答え開閉ステート更新
  const onClickShowAnswer = () => {
    setIsShowAnswer((_isShowAnswer) => !_isShowAnswer);
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    setIsShowAnswer(false);
  };

  return {
    isShowAnswer,
    onClickShowAnswer,
    // クリーンアップ関数
    cleanUpMethod,
  };
};
