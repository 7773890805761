import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import SupportList from 'components/organisms/supports/SupportList';
import SupportLayout from 'components/templates/SupportLayout';

/**
 *
 * Component
 *
 */
const Support: FC = memo(() => {
  // Hooks
  const { t } = useTranslation();
  const path = useLocation().pathname.slice(1);

  return (
    <>
      <Helmet>
        <title>{t('title.support')}</title>
        <meta name="description" content={t('description.support')} />
      </Helmet>
      <SupportLayout path={path}>
        <SupportList />
      </SupportLayout>
    </>
  );
});
export default Support;
