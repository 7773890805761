import { FC } from 'react';
// External library
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components
import { useOpenDocument } from 'hooks/versatile/useOpenDocument';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';
// Images
import backGroundSp from 'assets/images/backGround/footer_sp.jpg';
import backGroundPc from 'assets/images/backGround/footer_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/footer_pc@2x.jpg';
import neonBarSp from 'assets/images/neonItem/footer_sp.png';
import neonBarPc from 'assets/images/neonItem/footer_pc.png';
import neonBarPc2x from 'assets/images/neonItem/footer_pc@2x.png';

/**
 *
 * Component
 *
 */
const {
  ENGLISH, JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN,
  VIETNAM, THAILAND,
} = LANG;

const Footer: FC = () => {
  // i18next
  const { t } = useTranslation();
  // Recoil言語状態
  const lang = useRecoilValue(langState);

  // pdf表示用カスタムフック
  const {
    openTerms,
    openPrivacyPolicy,
    openResponsibleGambling,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    openKycPolicy,
  } = useOpenDocument();

  return (
    <FooterS>
      <NeonBarS>
        <source
          srcSet={`${neonBarPc} 1x, ${neonBarPc2x} 2x`}
          media="(min-width: 1025px)"
        />
        <NeonBarImageS src={neonBarSp} alt="" />
      </NeonBarS>
      <NavS>
        <UListS>
          <ListS>
            <button type="button" className="link" onClick={openTerms}>
              {t('利用規約')}
            </button>
          </ListS>
          <ListS>
            <button type="button" className="link" onClick={openPrivacyPolicy}>
              {t('プライバシーポリシー')}
            </button>
          </ListS>
          <ListS>
            <Link className="link" to="/whats">
              {t("Day Lott'sとは")}
            </Link>
          </ListS>
          <ListS>
            <Link className="link" to="/support">
              {t('サポート')}
            </Link>
          </ListS>
          <ListS>
            <Link className="link" to="/inquiry">
              {t('お問い合わせ')}
            </Link>
          </ListS>
        </UListS>
        <LicenseS>
          <LicenseTopS>
            {lang === ENGLISH && (
              <LicenseTopTextS>
                {`When you access, use, or navigate through this site, you agree
                to the `}
                <LicenseButtonS type="button" onClick={openTerms}>
                  {t('利用規約')}
                </LicenseButtonS>
                {` of use, and we use certain browser cookies to ensure a smooth and pleasant experience
              for you. We do not use cookies to violate your privacy. Please refer to our `}
                <LicenseButtonS type="button" onClick={openPrivacyPolicy}>
                  {t('プライバシーポリシー')}
                </LicenseButtonS>
                {` for information on how we use cookies and how to disable or
                manage their use. If you have any problems while using our site,
                please see our`}
                <Link className="link" to="/faq">
                  {' Frequently Asked Questions'}
                </Link>
                {`. Please note that gambling can be addictive and DayLott's does not solve
              financial problems. Please refer to `}
                <Link className="link" to="/responsible">
                  {t('責任あるギャンブル')}
                </Link>
                for more information.
              </LicenseTopTextS>
            )}

            {lang === JAPANESE && (
              <LicenseTopTextS>
                当サイトにアクセスやご利用、また当サイトを通してのナビゲーションの際、
                <LicenseButtonS type="button" onClick={openTerms}>
                  {t('利用規約')}
                </LicenseButtonS>
                {`に記載されている内容に同意し、お客様に円滑かつ快適にご利用いただくために、特定のブラウザ・クッキーを利用しております。
                  DayLott'sでは、クッキーを利用してお客様のプライバシーを侵害することは決してございません。
                  クッキーの利用方法、およびクッキーの使用を無効または管理方法につきましては、`}
                <LicenseButtonS type="button" onClick={openPrivacyPolicy}>
                  {t('プライバシーポリシー')}
                </LicenseButtonS>
                をご参照ください。当サイトを利用中にお困りの場合は、
                <Link className="link" to="/faq">
                  {t('よくある質問')}
                </Link>
                {`をご覧ください。また、ギャンブルは中毒性がございます。
                  DayLott'sは財政問題を解決いたしません。詳しくは`}
                <LicenseButtonS type="button" onClick={openResponsibleGambling}>
                  {t('責任あるギャンブル')}
                </LicenseButtonS>
                に記載がございますのでご参照ください。
              </LicenseTopTextS>
            )}

            {lang === SIMPLE_CHINESE && (
              <LicenseTopTextS>
                在访问和利用本网站，以及通过本网站进行导航时，为了令用户同意
                <LicenseButtonS type="button" onClick={openTerms}>
                  {t('利用規約')}
                </LicenseButtonS>
                {`中所记述的内容，并顺畅畅快地使用本网站，我们使用了特定的浏览器和cookie。
                DayLott's绝不会使用cookie来侵害用户的隐私。Cookie的使用方法及使用失效和管理方法请参照`}
                <LicenseButtonS type="button" onClick={openPrivacyPolicy}>
                  {t('プライバシーポリシー')}
                </LicenseButtonS>
                。在使用本网站中遇到疑问问题时，请查阅
                <Link className="link" to="/faq">
                  {t('よくある質問')}
                </Link>
                {`。此外，博彩具有上瘾性。
                DayLott's不会解决您的财政问题。详情记载于`}
                <LicenseButtonS type="button" onClick={openResponsibleGambling}>
                  {t('責任あるギャンブル')}
                </LicenseButtonS>
                内容中，敬请参照。
              </LicenseTopTextS>
            )}

            {lang === TRADITIONAL_CHINESE && (
              <LicenseTopTextS>
                當您訪問或使用本網站，或經由本網站瀏覽時，即表明您同意
                <LicenseButtonS type="button" onClick={openTerms}>
                  {t('利用規約')}
                </LicenseButtonS>
                {`所述之內容，為使客戶擁有舒適無礙的使用體驗，我們將透過特定的瀏覽器使用您的 cookie。
                在 DayLott's，我們絕不會使用 cookie 來侵犯客戶的隱私。請參閱我們的`}
                <LicenseButtonS type="button" onClick={openPrivacyPolicy}>
                  {t('プライバシーポリシー')}
                </LicenseButtonS>
                ，了解我們如何使用 cookie 以及我們如何作廢或管理 cookie 的使用。若您在使用本網站時遇到任何問題，請參閱
                <Link className="link" to="/faq">
                  {t('よくある質問')}
                </Link>
                {`。此外，博弈具有成癮性。
                DayLott's 不會解決財務問題。詳情將記載於`}
                <LicenseButtonS type="button" onClick={openResponsibleGambling}>
                  {t('責任あるギャンブル')}
                </LicenseButtonS>
                ，敬請參閱。
              </LicenseTopTextS>
            )}

            {lang === KOREAN && (
              <LicenseTopTextS>
                {`본 사이트에 접속 및 이용 또한 본
                사이트를 통해 네비게이션 활동시 `}
                <LicenseButtonS type="button" onClick={openTerms}>
                  {t('利用規約')}
                </LicenseButtonS>
                {`에 기재된 내용에 동의하여 고객께서 원활하고 쾌적하게 이용할 수 있도록 특정 브라우저 쿠키를 이용하고 있습니다.
                 DayLott's에서는 쿠키를 이용하여 고객님의 프라이버시를 침해하는 것은 절대 하지 않습니다.
                 쿠키의 이용방법 및 쿠키사용의 무효 또는 관리 방법에 대해서는 `}
                <LicenseButtonS type="button" onClick={openPrivacyPolicy}>
                  {t('プライバシーポリシー')}
                </LicenseButtonS>
                {`을 참조 해 주세요.
                 본 사이트 이용에 어려움이 있을경우 `}
                <Link className="link" to="/faq">
                  {t('よくある質問')}
                </Link>
                {`을 확인해주세요. 또한 도박은 중독성이 있습니다.
                 DayLott's는 재정문제를 해결하지 않습니다. 상세한 내용은 `}
                <LicenseButtonS type="button" onClick={openResponsibleGambling}>
                  {t('責任あるギャンブル')}
                </LicenseButtonS>
                에 기재하고 있으므로 확인해 주세요.
              </LicenseTopTextS>
            )}

            {lang === VIETNAM && (
              <LicenseTopTextS>
                {'Khi bạn truy cập, sử dụng hoặc điều hướng qua trang web này, bạn đồng ý '}
                <LicenseButtonS type="button" onClick={openTerms}>
                  {t('利用規約')}
                </LicenseButtonS>
                {` sử dụng và chúng tôi sử dụng một số cookie trình duyệt nhất định để đảm bảo trải nghiệm mượt mà và dễ chịu cho bạn.
                Chúng tôi không sử dụng cookie để vi phạm quyền riêng tư của bạn. Hãy tham khảo của chúng tôi `}
                <LicenseButtonS type="button" onClick={openPrivacyPolicy}>
                  {t('プライバシーポリシー')}
                </LicenseButtonS>
                {` để biết thông tin về cách chúng tôi sử dụng cookie và cách tắt hoặc quản lý việc sử dụng chúng.
                Nếu bạn gặp bất kỳ vấn đề nào khi sử dụng trang web của chúng tôi,
                vui lòng xem`}
                <Link className="link" to="/faq">
                  {t('よくある質問')}
                </Link>
                {` Xin lưu ý rằng cờ bạc có thể gây nghiện và DayLott's không giải quyết được
                 các vấn đề tài chính. Vui lòng tham khảo trước `}
                <LicenseButtonS type="button" onClick={openResponsibleGambling}>
                  {t('責任あるギャンブル')}
                </LicenseButtonS>
                for more information.
              </LicenseTopTextS>
            )}

            {lang === THAILAND && (
              <LicenseTopTextS>
                {'เมื่อคุณเข้าถึง ใช้ หรือนำทางผ่านไซต์นี้ คุณยอมรับ '}
                <LicenseButtonS type="button" onClick={openTerms}>
                  {t('利用規約')}
                </LicenseButtonS>
                {` ของการใช้งาน และเราใช้คุกกี้เบราว์เซอร์บางอย่างเพื่อให้แน่ใจว่าคุณจะได้รับประสบการณ์ที่ราบรื่นและน่าพึงพอใจ
                  เราไม่ใช้คุกกี้เพื่อละเมิดความเป็นส่วนตัวของคุณ โปรดดูที่ .ของเรา `}
                <LicenseButtonS type="button" onClick={openPrivacyPolicy}>
                  {t('プライバシーポリシー')}
                </LicenseButtonS>
                {` สำหรับข้อมูลเกี่ยวกับวิธีที่เราใช้คุกกี้และวิธีปิดการใช้งานหรือจัดการการใช้งาน
                  หากคุณมีปัญหาใด ๆ ขณะใช้งานเว็บไซต์ของเรา
                  โปรดดูของเรา`}
                <Link className="link" to="/faq">
                  {t('よくある質問')}
                </Link>
                {" โปรดทราบว่าการพนันอาจทำให้ติดได้ และ DayLott's ไม่ได้แก้ปัญหาทางการเงิน โปรดดูที่ "}
                <LicenseButtonS type="button" onClick={openResponsibleGambling}>
                  {t('責任あるギャンブル')}
                </LicenseButtonS>
                for more information.
              </LicenseTopTextS>
            )}

          </LicenseTopS>
          <LicenseBelowS>
            {t(
              "DayLott'sとこのウェブサイトは、Seven Eyes Ent. Company（会社番号227777/076/077および登録住所Sheetal Marg, Baluwater Kathmandu, Nepal）によって運営されています。 Seven Eyes Ent. Companyはネパール政府より発行された、Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010に基づき付与されたサブライセンスを通して正式な許可を保持しています。",
            )}
          </LicenseBelowS>
          <LicenseBelowS>
            <LicenseButtonS type="button" onClick={openKycPolicy}>
              {t('AML Policy of Curacao Co N.V. ')}
            </LicenseButtonS>
          </LicenseBelowS>
        </LicenseS>
      </NavS>
      <CopyRightS>
        <CopyRightTextS>&copy;2022&nbsp;DayLotts</CopyRightTextS>
      </CopyRightS>
    </FooterS>
  );
};
export default Footer;

/**
 *
 * Styles
 *
 */
const { tabletPortrait, tabletLandscape, pc } = devices;

const FooterS = styled.div`
  width: 100%;
  background-image: url(${backGroundSp});
  background-size: cover;
  color: #ffffff;
  padding-bottom: 13rem;

  @media ${tabletLandscape}, ${pc} {
    background-image: image-set(
      url(${backGroundPc}) 1x,
      url(${backGroundPc2x}) 2x
    );
    padding-bottom: 4rem;
  }
`;

const NeonBarS = styled.picture`
  width: 100%;
`;

const NeonBarImageS = styled.img`
  width: 100%;
  height: 10px;

  @media ${tabletLandscape}, ${pc} {
    height: 15px;
  }
`;

const NavS = styled.nav`
  width: 90%;
  margin: 3rem auto;

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    max-width: 100rem;
    margin: 5rem auto 15rem;
  }
`;

const UListS = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.5rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    width: 350px;
    row-gap: 2rem;
  }
`;

const ListS = styled.li`
  font-size: 1.2rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.4rem;
  }

  .link {
    &:hover {
      opacity: 0.7;
    }
  }
`;

const CopyRightS = styled.div`
  width: 100%;
`;

const CopyRightTextS = styled.p`
  text-align: center;

  @media ${tabletPortrait} {
    font-size: 1.2rem;
  }
`;

const LicenseS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
`;

const LicenseTopS = styled.div`
  width: 100%;
  margin-bottom: 3rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.4rem;
  }
`;

const LicenseTopTextS = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  word-break: break-word;
  color: #c7c7c7;

  .link {
    color: #ffffff;
    font-weight: bold;

    &:hover {
      opacity: 0.7;
    }
  }

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.4rem;
  }
`;

const LicenseButtonS = styled.button`
  display: contents;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #ffffff;
  font-weight: bold;

  &:hover {
    opacity: 0.7;
  }

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.4rem;
  }
`;

const LicenseBelowS = styled.div`
  margin-bottom: 2rem;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.5;
  word-break: break-word;
  color: #c7c7c7;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 1.4rem;
  }
`;
