import { useState, ChangeEvent } from 'react';
// External library
import { useHistory } from 'react-router-dom';
// Recooil
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { playBoxListState } from 'store/atoms/playBoxListState';
// Types
import { LotTable, PlayBox } from 'types/playBoxType';
import { MAX_PURCHASE_UNIT_ONCE } from 'utils/const/playBox';

/**
 * PLAYBOX用カスタムフック
 */
export const usePlayBoxWrap = (
  type: PlayBox,
): {
  units: string;
  winRate: number;
  isPurchase: boolean;
  onChangeInput: (event: ChangeEvent<HTMLInputElement>) => void;
  increment: () => void;
  decrement: () => void;
  bulkIncrement: (num: number) => void;
  unitReset: () => void;
  pushToConfirm: () => void;
  // クリーンアップ関数
  cleanUpMethod: (
) => void;
} => {
  // 所持ポイント
  const { point, freePoint } = useRecoilValue(userState);
  // Recoil playbox
  const playBoxList = useRecoilValue(playBoxListState);
  // PLAYBOX
  const playbox = playBoxList[type];
  // 購入口数ステート
  const [units, setUnits] = useState<string>('0');
  // 当選確率ステート
  const [winRate, setWinRate] = useState<number>(0);
  // 購入可否フラグ
  const [isPurchase, setIsPurchase] = useState<boolean>(false);
  // テーブル初期値
  const initialLotTable: LotTable = {
    id: 0,
    title: '',
    en_title: '',
    lot_box_id: 0,
    winning_point_1st: 0,
    number_of_winners_1st: 0,
    winning_point_2nd: 0,
    number_of_winners_2nd: 0,
    winning_point_3rd: 0,
    number_of_winners_3rd: 0,
    winning_point_4th: 0,
    number_of_winners_4th: 0,
    winning_point_5th: 0,
    number_of_winners_5th: 0,
    public_flg: 0,
    lottery_flg: 0,
    lottery_at: '',
    production_end_flg: 0,
    movie_status: 0,
    special_point: 0,
    special_number: 0,
    created_at: '',
    updated_at: '',
    deleted_at: '',
    lot_bets_count: 0,
  };

  // テーブル
  const lotTable = playbox && playbox.lot_tables[0] ? playbox.lot_tables[0] : initialLotTable;

  // 予約枠残数
  const remainingUnits = playbox.recruitment - lotTable.lot_bets_count;

  // 当選確率更新関数
  const onChangeWinRate = (bet: string) => {
    // テーブルの当たり総数
    const numberOfWinningTotal = lotTable.number_of_winners_1st;

    // 総べット額(購入口数 * 最低べットポイント)
    const betAmount = Number(bet) * playbox.minimum_bet;

    // 当たり総数 / ( ボックスの募集総額 / 総べット額 )
    const rate = numberOfWinningTotal / (playbox.total_offer / betAmount);

    // 当選確率(%)
    const ratePercentage = rate > 1 ? 100 : rate * 100;

    // パーセンテージをSet
    setWinRate(ratePercentage);

    // 購入可否チェック
    if (betAmount > 0 && point + freePoint >= betAmount && remainingUnits > 0) {
      setIsPurchase(true);
    } else {
      setIsPurchase(false);
    }
  };

  // 選択口数・当選確率ステートをまとめて更新
  const setUnitsAndRate = (value: string) => {
    setUnits(value);
    onChangeWinRate(value);
  };

  // 購入口数チェック
  const valueCheck = (value: string) => {
    let giveValue = value;

    if (!/^\d+$/.exec(value)) {
      // 半角数字以外の場合
      setUnits('');
      onChangeWinRate('0');
    } else {
      if (Number(value) > remainingUnits) {
        // 上限に揃える
        giveValue = String(remainingUnits);
      }
      if (Number(value) > MAX_PURCHASE_UNIT_ONCE) {
        // 負荷対策のためMAX_PURCHASE_UNIT_ONCE件以上は購入不可
        giveValue = String(MAX_PURCHASE_UNIT_ONCE);
      }
      setUnitsAndRate(giveValue);
    }
  };

  // ユーザー入力値受け取り
  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    valueCheck(value);
  };

  // 選択口数 増加
  const increment = () => {
    if (Number(units) < remainingUnits && Number(units) < MAX_PURCHASE_UNIT_ONCE) {
      const incrementNumber = (Number(units) + 1).toString();
      setUnitsAndRate(incrementNumber);
    }
  };
  // 選択口数 減少
  const decrement = () => {
    if (Number(units) > 0) {
      const decrementNumber = (Number(units) - 1).toString();
      setUnitsAndRate(decrementNumber);
    }
  };
  // 複数口購入時加算
  const bulkIncrement = (num: number) => {
    const resultNumber = Number(units) + num;

    if (resultNumber < remainingUnits && Number(units) < MAX_PURCHASE_UNIT_ONCE) {
      setUnitsAndRate(resultNumber.toString());
    } else if (remainingUnits < MAX_PURCHASE_UNIT_ONCE) {
      setUnitsAndRate(remainingUnits.toString());
    } else {
      setUnitsAndRate(MAX_PURCHASE_UNIT_ONCE.toString());
    }
  };

  // リセットボタン用関数
  const unitReset = () => {
    setUnitsAndRate('0');
  };

  // historyオブジェクト
  const history = useHistory();
  // 購入確認画面に遷移
  const pushToConfirm = () => {
    history.push({
      pathname: '/ticket_purchase/confirm',
      state: {
        referrer: '/',
        boxTitle: playbox.title,
        minimumBet: playbox.minimum_bet,
        tableId: lotTable.id,
        units,
      },
    });
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    setUnits('');
    setWinRate(0);
    setIsPurchase(false);
  };

  return {
    units,
    winRate,
    isPurchase,
    unitReset,
    onChangeInput,
    increment,
    decrement,
    bulkIncrement,
    pushToConfirm,
    // クリーンアップ関数
    cleanUpMethod,
  };
};
