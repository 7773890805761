import { FC, memo } from 'react';
// External library
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// Custom hooks
import { useTranslation } from 'react-i18next';
// types
import { BreadCrumbItems } from 'utils/breadCrumbs';

/**
 *
 * Types
 *
 */
type Props = {
  crumbItems: BreadCrumbItems[];
};

/**
 *
 * Component
 *
 */
const BreadCrumbs: FC<Props> = memo((props) => {
  // i18next
  const { t } = useTranslation();
  const { crumbItems } = props;

  return (
    <BreadCrumbsS>
      <UListS>
        {crumbItems.map((crumb, index) => (
          <ListS key={uuidv4()}>
            {/* 最後の要素以外は矢印を表示 */}
            {crumbItems.length === index + 1 ? (
              <>{t(crumb.name)}</>
            ) : (
              <>
                <Link to={crumb.path}>{t(crumb.name)}</Link>
                <ArrowS>&gt;</ArrowS>
              </>
            )}
          </ListS>
        ))}
      </UListS>
    </BreadCrumbsS>
  );
});
export default BreadCrumbs;

/**
 *
 * Styles
 *
 */
const BreadCrumbsS = styled.div`
  width: 100%;
`;

const UListS = styled.ul`
  display: flex;
  align-items: center;
`;

const ListS = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  color: #ffffff;
`;

const ArrowS = styled.p`
  color: #dddddd;
  margin: 0 1rem;
`;
