import { FC, useEffect, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// Components
import NewsLayout from 'components/templates/NewsLayout';
import NewsBox from 'components/organisms/news/NewsBox';
import LoaderPart from 'components/atoms/loader/LoaderPart';
// Custom hooks
import { useNewsUsersShow } from 'hooks/pages/private/useNewsUsersShow';
// Recoil
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { langState } from 'store/atoms/langState';
// Utils
import { newsUsersIndexBreadCrumbItems } from 'utils/breadCrumbs';
import { LANG } from 'utils/const/lang';

const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN,
  VIETNAM, THAILAND,
} = LANG;

/**
 *
 * Component
 *
 */
const NewsUsersShow: FC = memo(() => {
  const user = useRecoilValue(userState);
  const lang = useRecoilValue(langState);

  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    newsUsers,
    canWatchLottery,
    lotteryFlg,
    movieStatus,
    getApiNewsUsersFind,
    emptyMessage,
    errorMessage,
    cleanUpMethod,
  } = useNewsUsersShow();

  const title = lang === JAPANESE
    ? newsUsers.title
    : lang === SIMPLE_CHINESE
      ? newsUsers.zh_cn_title
      : lang === TRADITIONAL_CHINESE
        ? newsUsers.zh_tw_title
        : lang === KOREAN
          ? newsUsers.ko_title
          : lang === VIETNAM
            ? newsUsers.vi_title
            : lang === THAILAND
              ? newsUsers.th_title
              : newsUsers.en_title;

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    if (user.loggedIn) {
      // ユーザーお知らせ情報取得
      getApiNewsUsersFind();
    }

    return () => {
      cleanUpMethod();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{newsUsers.title ? `DayLott's - ${newsUsers.title}` : t('title.userNewsShow')}</title>
        <meta name="description" content={newsUsers.description ? newsUsers.description : t('description.userNewsShow')} />
      </Helmet>
      <NewsLayout
        breadCrumbItems={[
          ...newsUsersIndexBreadCrumbItems,
          {
            path: `/news_users/show/${newsUsers.id}`,
            name: newsUsers.id !== 0 ? title : emptyMessage,
          },
        ]}
      >
        {/* コンテンツ */}
        <ContainerS>
          {isLoading ? (
            <LoaderPart />
          ) : (
            <NewsBox
              title={title}
              description={
                lang === JAPANESE
                  ? newsUsers.description
                  : lang === SIMPLE_CHINESE
                    ? newsUsers.zh_cn_description
                    : lang === TRADITIONAL_CHINESE
                      ? newsUsers.zh_tw_description
                      : lang === KOREAN
                        ? newsUsers.ko_description
                        : lang === VIETNAM
                          ? newsUsers.vi_description
                          : lang === THAILAND
                            ? newsUsers.th_description
                            : newsUsers.en_description
              }
              emptyMessage={emptyMessage}
              errorMessage={errorMessage}
              link={newsUsers.link}
              canWatchLottery={canWatchLottery}
              lotteryFlg={lotteryFlg}
              movieStatus={movieStatus}
            />
          )}
        </ContainerS>
      </NewsLayout>
    </>
  );
});

export default NewsUsersShow;

/**
 *
 * Styles
 *
 */
const ContainerS = styled.div`
  width: 100%;
  min-height: 300px;
  position: relative;

  @media (min-width: 1025px) {
    min-height: 400px;
  }
`;
