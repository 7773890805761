import { FC, memo, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
// Components
import RoundButton from 'components/atoms/button/RoundButton';
// Custom hooks
import { useResponsible } from 'hooks/pages/common/useResponsible';
// Utils
import { SUPPORT } from 'utils/const/support';
import { devices } from 'utils/const/devices';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';

/**
 *
 * Component
 *
 */
// 「責任のあるギャンブル」画面用コンポーネント
const Responsible: FC = memo(() => {
  // i18next
  const { t } = useTranslation();
  // hooks
  const {
    protectionTexts,
    serviceTexts,
    hintTexts,
    infoTexts,
    questionTexts,
    periodTexts,
    goBack,
  } = useResponsible();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.responsibleGambling')}</title>
        <meta name="description" content={t('description.responsibleGambling')} />
      </Helmet>
      <ResponsibleS>
        {/* プレイヤー保護について */}
        <ContainerS>
          <TitleS>
            <SquareS />
            <TitleTextS>{t('プレイヤー保護について')}</TitleTextS>
            <SquareS />
          </TitleS>

          {/* コンテンツ */}
          <ContentS>
            <ItemS>
              <HeadTextS>{t('1 .未成年者の保護')}</HeadTextS>
              <BlockS>
                <TextS>
                  {t(
                    "インターネットはモバイル機器や世界中の家庭から簡単にアクセスできるため、責任のあるオンラインギャンブルは親の管理と監督に大きく依存しています。インターネット上での子供の安全を確保するため、DayLott'sは未成年者が不適切なオンライン資料にアクセスするのを防止するフィルタリングソフトウェアを使用するようにお客様に推奨します。DayLott'sでは、保護者に対して次のヒントを提供しています。",
                  )}
                </TextS>
                <UlistS>
                  {protectionTexts.map((text) => (
                    <ListS key={uuidv4()}>{text}</ListS>
                  ))}
                </UlistS>
              </BlockS>

              <BlockS>
                <TextS>
                  {t(
                    "未成年者のギャンブルは違法であるだけでなく、監督されていない子供が負った早期のギャンブル依存と実質的なギャンブルでの借金につながる可能性があります。したがって、自分の携帯電話、タブレット、ラップトップまたはコンピューターを法定年齢に満たない友人または家族と共有してオンラインギャンブルに参加する場合、DayLott'sは以下のいずれかのサービスを使用して、ウェブサイトへのアクセスを制限することを推奨します。",
                  )}
                </TextS>
                <UlistS className="service">
                  {serviceTexts.map((text) => (
                    <ListS key={uuidv4()}>{text}</ListS>
                  ))}
                </UlistS>
              </BlockS>

              <BlockS>
                <LabelS>
                  {t('その他の重要なヒント')}
                  :
                </LabelS>
                <TextS>
                  {t(
                    'ギャンブルをする決定は自分の個人的な選択であることを確認してください。次の場合は、ギャンブルをすることはおすすめしません。',
                  )}
                </TextS>
                <UlistS>
                  {hintTexts.map((text) => (
                    <ListS key={uuidv4()}>{text}</ListS>
                  ))}
                </UlistS>
              </BlockS>
            </ItemS>

            <ItemS>
              <HeadTextS>{t('2 .プレイのルール')}</HeadTextS>
              <TextS>
                {t(
                  "DayLott'sでゲームを始める前に、ゲームのルールを知ることをおすすめします。",
                )}
              </TextS>
            </ItemS>

            <ItemS>
              <HeadTextS>{t('3 .結託')}</HeadTextS>
              <TextS>
                {t(
                  '顧客が他の顧客と結託していると思われる場合や、不正行為を行った場合は、直ちにカスタマーサービスにご連絡ください。',
                )}
              </TextS>
            </ItemS>

            <ItemS>
              <HeadTextS>{t('4 .カスタマーサービスと顧客からの苦情')}</HeadTextS>
              <CustomerS>
                <CustomerItemS>
                  <HeadTextChildS>{t('4.1.カスタマーサービス')}</HeadTextChildS>
                  <MailTextWrapS>
                    <TextS>
                      {t(
                        'お客様はメールでカスタマーサービスにいつでも連絡することができます。',
                      )}
                    </TextS>
                    <TextS>{`${t('電子メール')}: ${SUPPORT.MAIL}`}</TextS>
                  </MailTextWrapS>
                </CustomerItemS>

                <CustomerItemS>
                  <HeadTextChildS>{t('4.2.苦情の提出')}</HeadTextChildS>
                  <MailTextWrapS>
                    <TextS>
                      {t(
                        "いかなる場合でも問題が発生してから3か月以内に、顧客の苦情/申し立てを提出する必要があります。お客様の苦情/申し立てが正確な部署に向けられ、調査されていることを確実にするために、以下の手段を介して書面による連絡をDayLott'sに提出しなければなりません。",
                      )}
                    </TextS>
                    <TextS>{`${t('電子メール')}: ${SUPPORT.MAIL}`}</TextS>
                  </MailTextWrapS>
                </CustomerItemS>

                <CustomerItemS>
                  <HeadTextChildS>
                    {t("4.3.DayLott'sとの書面による連絡に含める必要がある情報")}
                  </HeadTextChildS>
                  <TextS>
                    {t(
                      "お客様のプライバシーを保護するために、お客様とDayLott's間のすべての電子メールによる通信は、DayLott'sで保持されているお客様の顧客アカウントに登録したメールアドレスを使用して実行する必要があります。そうでない場合、当社の対応が遅れることがあります。DayLott'sとの(苦情を含む) あらゆる書面による連絡には、以下の情報を含める必要があります。",
                    )}
                  </TextS>
                  <UlistS>
                    {infoTexts.map((text) => (
                      <ListS key={uuidv4()}>{text}</ListS>
                    ))}
                  </UlistS>
                </CustomerItemS>
              </CustomerS>
            </ItemS>
          </ContentS>
        </ContainerS>

        {/* 責任のあるギャンブルポリシー */}
        <ContainerS>
          <TitleS>
            <SquareS />
            <TitleTextS>{t('責任のあるギャンブルポリシー')}</TitleTextS>
            <SquareS />
          </TitleS>

          {/* コンテンツ */}
          <ContentS className="policy">
            <TextS>
              {t(
                "DayLott'sでは、お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。",
              )}
            </TextS>

            <PolicyS>
              <ItemS>
                <HeadTextS>{t('1 .ギャンブルの自己評価')}</HeadTextS>
                <ItemContentS>
                  <TextS>
                    {t(
                      'ギャンブルによりお客様自身 (またはその他の人) の人生が支配されている可能性があると懸念する場合、次の質問で判明することがあるかもしれません。',
                    )}
                  </TextS>
                  <QuestionUlistS>
                    {questionTexts.map((text) => (
                      <ListS key={uuidv4()}>{text}</ListS>
                    ))}
                  </QuestionUlistS>
                  <TextS>
                    {t(
                      'これらの質問に "はい" という答えが多いほど、深刻なギャンブルの問題を抱えている可能性が高くなります。',
                    )}
                  </TextS>
                  <TextS>
                    {t(
                      'この問題について相談するには、カスタマーサービスに連絡するか、無料で独立したアドバイスを提供する数々のサポート組織があります。ご相談希望の方は、自国のサポート組織・機関に問い合わせをして下さい。',
                    )}
                  </TextS>
                </ItemContentS>
              </ItemS>

              <ItemS>
                <HeadTextS>{t('2 .サポート組織')}</HeadTextS>
                <TextS>
                  {t(
                    'お客様あるいは知り合いの方がギャンブルの問題を抱えている場合、認定された組織からの支援を検討することをおすすめします。',
                  )}
                </TextS>
              </ItemS>

              <ItemS>
                <HeadTextS>{t('3 .コントロールの維持')}</HeadTextS>
                <TextS>
                  {t(
                    "DayLott'sでは、お客様にギャンブルアカウントの管理に積極的に関与することを推奨しています。そのために、当社はさまざまな責任のあるゲーミング機能を提供しています。",
                  )}
                </TextS>
                <ControlWrapS>
                  <HeadTextChildS>
                    {t('3.1.自己排除')}
                    :
                  </HeadTextChildS>
                  <ItemContentS>
                    <TextS>
                      {t(
                        'もしお客様がギャンブルに対し問題が生じたと思われる場合には、カスタマーサービスへ連絡をし、アカウントへ自己排除期間を適用するように要請してください。',
                      )}
                    </TextS>
                    <TextS>{t('以下の自己排除期間がご利用可能です')}</TextS>
                    <UlistS>
                      {periodTexts.map((text) => (
                        <ListS key={uuidv4()}>{text}</ListS>
                      ))}
                    </UlistS>
                    <TextS>
                      {t(
                        "できる限り速やかに自己排除を適用するように努めますが、このプロセスにはある程度の作業時間が必要となりますのでご理解いただきますようお願いいたします。自己排除期間は実装されてお客様へ連絡されるまで、開始したものとはみなされませんのでご注意ください。もしその後も引き続きサービスにアクセス可能な場合には、責任をもってすぐ当社までご連絡ください。自己排除に入った時点でのDayLott'sの未確定ベットは、決済されます。お客様のベットが勝ちだった場合、勝ち金は該当する口座へ送金されます。自己排除期間が終了したあと、お客様のアカウントは自動的に再開されますのでご注意下さい。",
                      )}
                    </TextS>
                    <TextS>
                      {t(
                        "DayLott'sは開かれる可能性のある新しい重複アカウントを検出し、閉鎖するためにできる限りのことを常に行っております。自己排除が適用されると、いかなるマーケティング資料も受け取ることはなくなります。",
                      )}
                    </TextS>
                  </ItemContentS>
                </ControlWrapS>
              </ItemS>
            </PolicyS>
          </ContentS>

          <RoundButtonWrapS>
            <RoundButton
              onClick={goBack}
              background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
              borderColor="rgba(0,135,167,1)"
            >
              {t('戻る')}
            </RoundButton>
          </RoundButtonWrapS>
        </ContainerS>
      </ResponsibleS>
    </>
  );
});
export default Responsible;

/**
 *
 * Styles
 *
 */
const { tabletPortrait, tabletLandscape, pc } = devices;

const ResponsibleS = styled.div`
  background-image: url(${backGroundSp});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media ${tabletLandscape}, ${pc} {
    background-image: image-set(
      url(${backGroundPc}) 1x,
      url(${backGroundPc2x}) 2x
    );
    background-position: top;
  }
`;

const ContainerS = styled.div`
  width: 90%;
  min-height: 90vh;
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 8rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  &:first-child {
    padding-bottom: 0;
  }

  @media ${tabletPortrait} {
    padding-top: 12rem;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 100%;
    max-width: 100rem;
    padding-top: 8rem;
    padding-bottom: 10rem;
  }
`;

const TitleS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SquareS = styled.div`
  width: min(5vw, 2.4rem);
  height: min(5.4vw, 2.4rem);
  transform: skew(-40deg, 0deg);
  background: linear-gradient(
    0deg,
    #ffe99b,
    #a8861f,
    #ffe99b,
    #ffffff,
    #ffe99b
  );

  @media ${tabletPortrait} {
    width: 1.8rem;
    height: 1.8rem;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

const TitleTextS = styled.p`
  margin: 0 1.2rem;
  font-size: min(5vw, 2.4rem);
  font-weight: bold;
  word-break: break-all;
  color: transparent;
  background: linear-gradient(
    0deg,
    #ffe99b,
    #a8861f,
    #ffe99b,
    #ffffff,
    #ffe99b
  );
  background-clip: text;
  -webkit-background-clip: text;

  @media ${tabletPortrait} {
    margin: 0 1.4rem;
    font-size: 2.4rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2.6rem;
    margin: 0 1.6rem;
  }
`;

const ContentS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
`;

const ItemS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    margin-bottom: 6rem;
  }
`;

const BlockS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    margin-bottom: 5rem;
  }
`;

const PolicyS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
`;

const TextS = styled.p`
  font-size: 1.4rem;
  color: #ffffff;
  word-break: break-word;
  line-height: 1.7;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const HeadTextS = styled(TextS)`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 2rem;

  @media ${tabletPortrait} {
    font-size: 1.8rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2rem;
  }
`;

const HeadTextChildS = styled(TextS)`
  font-size: 1.4rem;
  font-weight: bold;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const UlistS = styled.ul`
  width: 100%;
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 2rem;

  &.service {
    list-style-type: none;
    list-style-position: unset;
    padding-left: 0;
  }
`;

const QuestionUlistS = styled(UlistS)`
  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    margin: 1rem 0;
  }
`;

const ListS = styled.li`
  font-size: 1.4rem;
  color: #ffffff;
  word-break: break-word;
  line-height: 1.5;

  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const LabelS = styled.p`
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: bold;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;

const ItemContentS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const CustomerS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    row-gap: 4rem;
  }
`;

const CustomerItemS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
`;

const MailTextWrapS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const ControlWrapS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

const RoundButtonWrapS = styled.div`
  width: 200px;
  font-size: 1.4rem;
  margin-top: 5rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    width: 220px;
    font-size: 1.6rem;
    margin-top: 7rem;
  }
`;
