import { jaSeo } from 'utils/lang/seo/jaSeo';

export const ja = {
  // メタ情報
  ...jaSeo,
  // ページ
  トップ: 'トップ',
  新規会員登録: '新規会員登録',
  入力内容の確認: '入力内容の確認',
  メール送信完了: 'メール送信完了',
  メール認証: 'メール認証',
  ログイン: 'ログイン',
  パスワードのリセット: 'パスワードのリセット',
  パスワードの再設定: 'パスワードの再設定',
  マイページ: 'マイページ',
  アカウントの停止: 'アカウントの停止',
  メールアドレスの変更: 'メールアドレスの変更',
  'ポイント出金・交換': 'ポイント出金・交換',
  KYC書類アップロード: 'KYC書類アップロード',
  "Day Lott'sとは": "Day Lott'sとは",
  仕組み: '仕組み',
  よくある質問: 'よくある質問',
  ランキング: 'ランキング',
  サポート: 'サポート',
  お知らせ: 'お知らせ',
  言語: '言語',
  'NEWSはありません。': 'NEWSはありません。',
  '高額獲得者はいません。': '高額獲得者はいません。',
  '業界最高水準である、当選確率1/8も用意されているオンラインゲームです。還元率は75%!! 1ゲーム5Pからゲーム参加可能。5Pから10,000Pの当選も毎日開催されています。':
    '業界最高水準である、当選確率1/8も用意されているオンラインゲームです。還元率は75%!! 1ゲーム5Pからゲーム参加可能。5Pから10,000Pの当選も毎日開催されています。',
  "1day,1million,1richをテーマにDay Lott'sは運営をしています。毎日世界中のどこかでミリオネアがDay Lott'sから誕生しています。我々のサービスは、1日1,000,000Pの当選を目指しています。":
    "1day,1million,1richをテーマにDay Lott'sは運営をしています。毎日世界中のどこかでミリオネアがDay Lott'sから誕生しています。我々のサービスは、1日1,000,000Pの当選を目指しています。",
  "Day Lott'sでは、入金、出金の際に仮想通貨を利用することができます。仮想通貨の利用により、ボーダーレスに、そしてスピードのある入出金が可能となっております。":
    "Day Lott'sでは、入金、出金の際に仮想通貨を利用することができます。仮想通貨の利用により、ボーダーレスに、そしてスピードのある入出金が可能となっております。",
  'メールアドレスを登録フォームに入力し、会員登録を行います。':
    'メールアドレスを登録フォームに入力し、会員登録を行います。',
  会員登録: '会員登録',
  '入金・チャージ': '入金・チャージ',
  '仮想通貨、振込など様々な方法でポイントをチャージすることができます。仮想通貨、振込など様々な方法でポイントをチャージすることができます。':
    '仮想通貨、振込など様々な方法でポイントをチャージすることができます。仮想通貨、振込など様々な方法でポイントをチャージすることができます。',
  チケット購入: 'チケット購入',
  'ゲームに参加するためにチケットを、ポイントで購入します。':
    'ゲームに参加するためにチケットを、ポイントで購入します。',
  当選の確定: '当選の確定',
  'ゲームにエントリーをし、ゲームが開始されたら、すぐに当選の確定が分かります。当選確定の演出もお楽しみください！':
    'ゲームにエントリーをし、ゲームが開始されたら、すぐに当選の確定が分かります。当選確定の演出もお楽しみください！',
  '当選が確定したら、即座にマイページに当選ポイントが反映されます。また、そのポイントを換金して出金することができます。':
    '当選が確定したら、即座にマイページに当選ポイントが反映されます。また、そのポイントを換金して出金することができます。',
  // フッター
  利用規約: '利用規約',
  お問い合わせ: 'お問い合わせ',
  利用規約に同意し登録する: '<0>利用規約</0>に同意し登録する',
  '※ユーザー名は後から変更をすることはできません。': '※ユーザー名は後から変更をすることはできません。',
  '※パスワードは大文字、小文字、数字を組み合わせて8文字以内で入力してください。': '※パスワードは大文字、小文字、数字を組み合わせて8文字以内で入力してください。',
  プライバシーポリシー: 'プライバシーポリシー',
  責任あるギャンブル: '責任あるギャンブル',
  "DayLott'sとこのウェブサイトは、Seven Eyes Ent. Company（会社番号227777/076/077および登録住所Sheetal Marg, Baluwater Kathmandu, Nepal）によって運営されています。 Seven Eyes Ent. Companyはネパール政府より発行された、Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010に基づき付与されたサブライセンスを通して正式な許可を保持しています。":
    "DayLott'sとこのウェブサイトは、Seven Eyes Ent. Company（会社番号227777/076/077および登録住所Sheetal Marg, Baluwater Kathmandu, Nepal）によって運営されています。 Seven Eyes Ent. Companyはネパール政府より発行された、Sports betting, poker, bingo, lotto online gaming, License No,109 dated on July 29, 2010に基づき付与されたサブライセンスを通して正式な許可を保持しています。",
  プレイヤー保護について: 'プレイヤー保護について',
  責任のあるギャンブルポリシー: '責任のあるギャンブルポリシー',
  '1 .未成年者の保護': '1 .未成年者の保護',
  '2 .プレイのルール': '2 .プレイのルール',
  '3 .結託': '3 .結託',
  '4 .カスタマーサービスと顧客からの苦情':
    '4 .カスタマーサービスと顧客からの苦情',
  '4.1.カスタマーサービス': '4.1.カスタマーサービス',
  '4.2.苦情の提出': '4.2.苦情の提出',
  "4.3.DayLott'sとの書面による連絡に含める必要がある情報":
    "4.3.DayLott'sとの書面による連絡に含める必要がある情報",
  '1 .ギャンブルの自己評価': '1 .ギャンブルの自己評価',
  '2 .サポート組織': '2 .サポート組織',
  '3 .コントロールの維持': '3 .コントロールの維持',
  '3.1.自己排除': '3.1.自己排除',
  "インターネットはモバイル機器や世界中の家庭から簡単にアクセスできるため、責任のあるオンラインギャンブルは親の管理と監督に大きく依存しています。インターネット上での子供の安全を確保するため、DayLott'sは未成年者が不適切なオンライン資料にアクセスするのを防止するフィルタリングソフトウェアを使用するようにお客様に推奨します。DayLott'sでは、保護者に対して次のヒントを提供しています。":
    "インターネットはモバイル機器や世界中の家庭から簡単にアクセスできるため、責任のあるオンラインギャンブルは親の管理と監督に大きく依存しています。インターネット上での子供の安全を確保するため、DayLott'sは未成年者が不適切なオンライン資料にアクセスするのを防止するフィルタリングソフトウェアを使用するようにお客様に推奨します。DayLott'sでは、保護者に対して次のヒントを提供しています。",
  '自分のメールアドレスを安全に保つ。': '自分のメールアドレスを安全に保つ。',
  "自分のDayLott'sアカウントにログインしたときにコンピューターを無人で放置しない。":
    "自分のDayLott'sアカウントにログインしたときにコンピューターを無人で放置しない。",
  '18歳未満の人がいかなるギャンブル活動への参加も許可しない。':
    '18歳未満の人がいかなるギャンブル活動への参加も許可しない。',
  '自分のクレジット/デビットカードおよびその他の金融口座の詳細を、子供の手の届かないところに保管する。':
    '自分のクレジット/デビットカードおよびその他の金融口座の詳細を、子供の手の届かないところに保管する。',
  'パスワードをコンピューターに保存しない。パスワードを書き留め、未成年者から離れた安全な場所に保管する。':
    'パスワードをコンピューターに保存しない。パスワードを書き留め、未成年者から離れた安全な場所に保管する。',
  "共有コンピューターからDayLott'sにアクセスする場合、特にコンピューターを子供と共有する場合は、「記憶する」機能を使用しない。":
    "共有コンピューターからDayLott'sにアクセスする場合、特にコンピューターを子供と共有する場合は、「記憶する」機能を使用しない。",
  '未成年のギャンブルによって引き起こされる違法性と潜在的な損害について、自分の子供たちに教育する。':
    '未成年のギャンブルによって引き起こされる違法性と潜在的な損害について、自分の子供たちに教育する。',
  '未成年者によるすべての電子機器ならびにモバイルデバイスの使用には特に注意する。':
    '未成年者によるすべての電子機器ならびにモバイルデバイスの使用には特に注意する。',
  '自分の子供がギャンブルサイトを含む不適切な題材にアクセスするのを防ぐために、フィルタリングソフトウェアを活用する。':
    '自分の子供がギャンブルサイトを含む不適切な題材にアクセスするのを防ぐために、フィルタリングソフトウェアを活用する。',
  "未成年者のギャンブルは違法であるだけでなく、監督されていない子供が負った早期のギャンブル依存と実質的なギャンブルでの借金につながる可能性があります。したがって、自分の携帯電話、タブレット、ラップトップまたはコンピューターを法定年齢に満たない友人または家族と共有してオンラインギャンブルに参加する場合、DayLott'sは以下のいずれかのサービスを使用して、ウェブサイトへのアクセスを制限することを推奨します。":
    "未成年者のギャンブルは違法であるだけでなく、監督されていない子供が負った早期のギャンブル依存と実質的なギャンブルでの借金につながる可能性があります。したがって、自分の携帯電話、タブレット、ラップトップまたはコンピューターを法定年齢に満たない友人または家族と共有してオンラインギャンブルに参加する場合、DayLott'sは以下のいずれかのサービスを使用して、ウェブサイトへのアクセスを制限することを推奨します。",
  'Netnanny.com - 不適切なウェブコンテンツから子供を守るフィルタリングソフトウェア。':
    'Netnanny.com - 不適切なウェブコンテンツから子供を守るフィルタリングソフトウェア。',
  'Cybersitter.com - 保護者がブロックするサイトを自身で追加することができるフィルタリングソフトウェア。':
    'Cybersitter.com - 保護者がブロックするサイトを自身で追加することができるフィルタリングソフトウェア。',
  'betterinternetforkids.eu - EUが共同出資し、インターネットフィルタソフトウェアに関する情報を提供し、無料のテスト版を提供するウェブサイト。':
    'betterinternetforkids.eu - EUが共同出資し、インターネットフィルタソフトウェアに関する情報を提供し、無料のテスト版を提供するウェブサイト。',
  その他の重要なヒント: 'その他の重要なヒント',
  'ギャンブルをする決定は自分の個人的な選択であることを確認してください。次の場合は、ギャンブルをすることはおすすめしません。':
    'ギャンブルをする決定は自分の個人的な選択であることを確認してください。次の場合は、ギャンブルをすることはおすすめしません。',
  '毎日の責務を妨げる場合。': '毎日の責務を妨げる場合。',
  '依存症の治療をしている、あるいは回復している場合。':
    '依存症の治療をしている、あるいは回復している場合。',
  'アルコールやその他の物質の影響を受けている場合。':
    'アルコールやその他の物質の影響を受けている場合。',
  '以前の損失を取り戻そうとしている場合。あるいは賞金で借金を回収することが主な目的となっている場合。':
    '以前の損失を取り戻そうとしている場合。あるいは賞金で借金を回収することが主な目的となっている場合。',
  "DayLott'sでゲームを始める前に、ゲームのルールを知ることをおすすめします。":
    "DayLott'sでゲームを始める前に、ゲームのルールを知ることをおすすめします。",
  '顧客が他の顧客と結託していると思われる場合や、不正行為を行った場合は、直ちにカスタマーサービスにご連絡ください。':
    '顧客が他の顧客と結託していると思われる場合や、不正行為を行った場合は、直ちにカスタマーサービスにご連絡ください。',
  'お客様はメールでカスタマーサービスにいつでも連絡することができます。':
    'お客様はメールでカスタマーサービスにいつでも連絡することができます。',
  "いかなる場合でも問題が発生してから3か月以内に、顧客の苦情/申し立てを提出する必要があります。お客様の苦情/申し立てが正確な部署に向けられ、調査されていることを確実にするために、以下の手段を介して書面による連絡をDayLott'sに提出しなければなりません。":
    "いかなる場合でも問題が発生してから3か月以内に、顧客の苦情/申し立てを提出する必要があります。お客様の苦情/申し立てが正確な部署に向けられ、調査されていることを確実にするために、以下の手段を介して書面による連絡をDayLott'sに提出しなければなりません。",
  電子メール: '電子メール',
  "お客様のプライバシーを保護するために、お客様とDayLott's間のすべての電子メールによる通信は、DayLott'sで保持されているお客様の顧客アカウントに登録したメールアドレスを使用して実行する必要があります。そうでない場合、当社の対応が遅れることがあります。DayLott'sとの(苦情を含む) あらゆる書面による連絡には、以下の情報を含める必要があります。":
    "お客様のプライバシーを保護するために、お客様とDayLott's間のすべての電子メールによる通信は、DayLott'sで保持されているお客様の顧客アカウントに登録したメールアドレスを使用して実行する必要があります。そうでない場合、当社の対応が遅れることがあります。DayLott'sとの(苦情を含む) あらゆる書面による連絡には、以下の情報を含める必要があります。",
  'お客様のニックネーム/電話番号': 'お客様のニックネーム/電話番号',
  お客様の顧客アカウントに登録済みの氏名:
    'お客様の顧客アカウントに登録済みの氏名',
  '苦情/申し立ての詳細': '苦情/申し立ての詳細',
  '苦情/申し立てに関連する特定の日付と時刻 (該当する場合)':
    '苦情/申し立てに関連する特定の日付と時刻 (該当する場合)',
  "DayLott'sでは、お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。":
    "DayLott'sでは、お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。",
  'ギャンブルによりお客様自身 (またはその他の人) の人生が支配されている可能性があると懸念する場合、次の質問で判明することがあるかもしれません。':
    'ギャンブルによりお客様自身 (またはその他の人) の人生が支配されている可能性があると懸念する場合、次の質問で判明することがあるかもしれません。',
  'つまらない日常や不幸な人生を忘れるためにギャンブルをしますか？':
    'つまらない日常や不幸な人生を忘れるためにギャンブルをしますか？',
  'ギャンブルをしていてお金が足りなくなったら、喪失感を味わい絶望を感じ、できるだけ早く再びギャンブルをする必要がありますか？':
    'ギャンブルをしていてお金が足りなくなったら、喪失感を味わい絶望を感じ、できるだけ早く再びギャンブルをする必要がありますか？',
  '最後の1円がなくなるまでギャンブルをしますか？それが、たとえば家への交通費や生活費だとしてもですか？':
    '最後の1円がなくなるまでギャンブルをしますか？それが、たとえば家への交通費や生活費だとしてもですか？',
  'ギャンブルに費やした金額や時間の嘘をついてごまかしたことがありますか？':
    'ギャンブルに費やした金額や時間の嘘をついてごまかしたことがありますか？',
  'ギャンブルが原因で家族、友人、趣味への興味を失ったことがありますか？':
    'ギャンブルが原因で家族、友人、趣味への興味を失ったことがありますか？',
  '負けた後、できるだけ早くもう一度挑戦して損失を勝たなければならないと感じますか？':
    '負けた後、できるだけ早くもう一度挑戦して損失を勝たなければならないと感じますか？',
  '議論、不満、失望によりギャンブルをしたくなりますか？':
    '議論、不満、失望によりギャンブルをしたくなりますか？',
  'ギャンブルをすることが原因で落ち込む、あるいは自分自身に害を及ぼすと感じることがありますか？':
    'ギャンブルをすることが原因で落ち込む、あるいは自分自身に害を及ぼすと感じることがありますか？',
  'これらの質問に "はい" という答えが多いほど、深刻なギャンブルの問題を抱えている可能性が高くなります。':
    'これらの質問に "はい" という答えが多いほど、深刻なギャンブルの問題を抱えている可能性が高くなります。',
  'この問題について相談するには、カスタマーサービスに連絡するか、無料で独立したアドバイスを提供する数々のサポート組織があります。ご相談希望の方は、自国のサポート組織・機関に問い合わせをして下さい。':
    'この問題について相談するには、カスタマーサービスに連絡するか、無料で独立したアドバイスを提供する数々のサポート組織があります。ご相談希望の方は、自国のサポート組織・機関に問い合わせをして下さい。',
  'お客様あるいは知り合いの方がギャンブルの問題を抱えている場合、認定された組織からの支援を検討することをおすすめします。':
    'お客様あるいは知り合いの方がギャンブルの問題を抱えている場合、認定された組織からの支援を検討することをおすすめします。',
  "DayLott'sでは、お客様にギャンブルアカウントの管理に積極的に関与することを推奨しています。そのために、当社はさまざまな責任のあるゲーミング機能を提供しています。":
    "DayLott'sでは、お客様にギャンブルアカウントの管理に積極的に関与することを推奨しています。そのために、当社はさまざまな責任のあるゲーミング機能を提供しています。",
  'もしお客様がギャンブルに対し問題が生じたと思われる場合には、カスタマーサービスへ連絡をし、アカウントへ自己排除期間を適用するように要請してください。':
    'もしお客様がギャンブルに対し問題が生じたと思われる場合には、カスタマーサービスへ連絡をし、アカウントへ自己排除期間を適用するように要請してください。',
  以下の自己排除期間がご利用可能です: '以下の自己排除期間がご利用可能です',
  '1週間': '1週間',
  '2週間': '2週間',
  '1ヶ月': '1ヶ月',
  '3ヶ月': '3ヶ月',
  '6ヶ月': '6ヶ月',
  "できる限り速やかに自己排除を適用するように努めますが、このプロセスにはある程度の作業時間が必要となりますのでご理解いただきますようお願いいたします。自己排除期間は実装されてお客様へ連絡されるまで、開始したものとはみなされませんのでご注意ください。もしその後も引き続きサービスにアクセス可能な場合には、責任をもってすぐ当社までご連絡ください。自己排除に入った時点でのDayLott'sの未確定ベットは、決済されます。お客様のベットが勝ちだった場合、勝ち金は該当する口座へ送金されます。自己排除期間が終了したあと、お客様のアカウントは自動的に再開されますのでご注意下さい。":
    "できる限り速やかに自己排除を適用するように努めますが、このプロセスにはある程度の作業時間が必要となりますのでご理解いただきますようお願いいたします。自己排除期間は実装されてお客様へ連絡されるまで、開始したものとはみなされませんのでご注意ください。もしその後も引き続きサービスにアクセス可能な場合には、責任をもってすぐ当社までご連絡ください。自己排除に入った時点でのDayLott'sの未確定ベットは、決済されます。お客様のベットが勝ちだった場合、勝ち金は該当する口座へ送金されます。自己排除期間が終了したあと、お客様のアカウントは自動的に再開されますのでご注意下さい。",
  "DayLott'sは開かれる可能性のある新しい重複アカウントを検出し、閉鎖するためにできる限りのことを常に行っております。自己排除が適用されると、いかなるマーケティング資料も受け取ることはなくなります。":
    "DayLott'sは開かれる可能性のある新しい重複アカウントを検出し、閉鎖するためにできる限りのことを常に行っております。自己排除が適用されると、いかなるマーケティング資料も受け取ることはなくなります。",
  // フォーム
  メールアドレス: 'メールアドレス',
  ユーザー名: 'ユーザー名',
  パスワード: 'パスワード',
  'パスワード(確認用)': 'パスワード(確認用)',
  新しいパスワード: '新しいパスワード',
  '新しいパスワード(確認用)': '新しいパスワード(確認用)',
  ファイルを選択: 'ファイルを選択',
  'ドラッグ＆ドロップするかファイルを選択してください':
    'ドラッグ＆ドロップするかファイルを選択してください',
  // ボタン
  修正する: '修正する',
  送信する: '送信する',
  再送する: '再送する',
  確認する: '確認する',
  登録する: '登録する',
  入金する: '入金する',
  出金する: '出金する',
  受け取る: '受け取る',
  アップロード: 'アップロード',
  削除: '削除',
  停止: '停止',
  選択: '選択',
  送信: '送信',
  戻る: '戻る',
  設定する: '設定する',
  // トッページ
  さらに表示する: 'さらに表示する',
  // 新規会員登録ページ
  '新規会員登録(タイトル)': '新規会員登録',
  // 新規会員登録確認ページ
  '内容に誤りがないか、よくご確認ください。':
    '内容に誤りがないか、よくご確認ください。',
  // 新規会員登録完了ページ
  メールの送信が完了しました: 'メールの送信が完了しました。',
  '以下のメールアドレスに確認メールを送りました。メール記載のURLから登録を完了してください。':
    '以下のメールアドレスに確認メールを送りました。メール記載のURLから登録を完了してください。',
  TOPに戻る: 'TOPに戻る',
  // メール認証
  '認証に成功しました。': '認証に成功しました。',
  ログインページへ: 'ログインページへ',
  // ログイン
  'Bunny Paradise Worldのアカウントでもログインできますが、初回はパスワードのリセットが必要です。': 'Bunny Paradise Worldのアカウントでもログインできますが、初回はパスワードのリセットが必要です。',
  'ログインができない、パスワードを忘れた方はこちら':
    'ログインができない、パスワードを忘れた方はこちら',
  "DayLott'sのリンクをホーム画面に追加すると次回以降簡単にアクセスができます。": "DayLott'sのリンクをホーム画面に追加すると次回以降簡単にアクセスができます。",
  iPhoneの場合: 'iPhoneの場合',
  '1. ブラウザ(Safari)でWEBページを開いた状態で、下部の「共有アイコン」をタップ。': '1. ブラウザ(Safari)でWEBページを開いた状態で、下部の「共有アイコン」をタップ。',
  '2. 共有メニューに表示される「ホーム画面に追加」をタップ。': '2. 共有メニューに表示される「ホーム画面に追加」をタップ。',
  '3. 名称などをご確認いただき、画面右上の「追加」をタップ。': '3. 名称などをご確認いただき、画面右上の「追加」をタップ。',
  '4. ホーム画面にアイコンが追加されたら完了です。': '4. ホーム画面にアイコンが追加されたら完了です。',
  確認して閉じる: '確認して閉じる',
  // パスワードリセット
  '登録済みのメールアドレスにパスワード再設定用のURLを送信します。':
    '登録済みのメールアドレスにパスワード再設定用のURLを送信します。',
  パスワードのリセットを申請する: 'パスワードのリセットを申請する',
  '再送しました。': '再送しました。',
  // パスワードの再設定
  '新しいパスワードを入力した後、「設定する」を押してください。':
    '新しいパスワードを入力した後、「設定する」を押してください。',
  // パスワードの再設定(完了)
  パスワードの変更が完了しました: 'パスワードの変更が完了しました。',
  // マイページ
  'ポイント購入履歴はありません。': 'ポイント購入履歴はありません。',
  初回登録ボーナス: '初回登録ボーナス',
  入金ボーナス: '入金ボーナス',
  紹介ボーナス: '紹介ボーナス',
  クレジット購入: 'クレジット購入',
  ビットコイン購入: 'ビットコイン購入',
  振り込み購入: '振り込み購入',
  テザー購入: 'テザー購入',
  イーサリアム購入: 'イーサリアム購入',
  'ポイント出金・交換履歴はありません。':
    'ポイント出金・交換履歴はありません。',
  'べット履歴はありません。': 'べット履歴はありません。',
  '当選履歴はありません。': '当選履歴はありません。',
  所持ポイント: '所持ポイント',
  生年月日: '生年月日',
  '※ポイントの出金・交換をする際にはKYC認証が必須となります。': '※ポイントの出金・交換をする際にはKYC認証が必須となります。',
  未設定: '未設定',
  登録情報: '登録情報',
  KYC提出: 'KYC提出',
  'ポイントデポジット出金・交換': 'ポイントデポジット\n出金・交換',
  プレイ履歴: 'プレイ履歴',
  編集: '編集',
  ログアウト: 'ログアウト',
  KYC確認中: 'KYC確認中',
  KYC却下: 'KYC却下',
  書類のタイプを選択する: '書類のタイプを選択する',
  パスポート: 'パスポート',
  免許証: '免許証',
  // アカウントの停止(確認)
  'アカウント情報、所持チケット、所持ポイント等が失われます。確認してから「停止」ボタンを押してください。':
    'アカウント情報、所持チケット、所持ポイント等が失われます。確認してから「停止」ボタンを押してください。',
  '※この操作は取り消しができません。': '※この操作は取り消しができません。',
  // アカウントの停止(完了)
  アカウント停止完了: 'アカウント停止完了',
  'アカウントの停止が完了しました。ご利用いただきありがとうございました。':
    'アカウントの停止が完了しました。ご利用いただきありがとうございました。',
  // メールアドレスの変更
  '入力したメールアドレスにメールアドレス再設定用のURLを送信します。':
    '入力したメールアドレスにメールアドレス再設定用のURLを送信します。',
  // KYC書類アップロード(フォーム)
  パスポートのアップロード: 'パスポートのアップロード',
  免許証のアップロード: '免許証のアップロード',
  年: '年',
  月: '月',
  日: '日',
  パスポートの写真をアップロード: 'パスポートの写真をアップロード',
  '免許証 ( 表 ) の写真をアップロード': '免許証 ( 表 ) の写真をアップロード',
  '免許証 ( 裏 ) の写真をアップロード': '免許証 ( 裏 ) の写真をアップロード',
  '1つ前に戻る': '1つ前に戻る',
  '画像をアップロードしてください。': '画像をアップロードしてください。',
  // KYC書類アップロード(確認)
  提出内容の確認: '提出内容の確認',
  送信内容の確認: '送信内容の確認',
  '以下の内容で送信します。よろしければ送信を押してください。':
    '以下の内容で送信します。よろしければ送信を押してください。',
  '免許証 ( 表 )': '免許証 ( 表 )',
  '免許証 ( 裏 )': '免許証 ( 裏 )',
  // KYC書類アップロード(完了)
  本人確認書類送信完了: '本人確認書類送信完了',
  '審査結果はメールにてお知らせいたします。今しばらくお待ちください。':
    '審査結果はメールにてお知らせいたします。今しばらくお待ちください。',
  マイページへ戻る: 'マイページへ戻る',
  // ポイント購入
  ポイント購入: 'ポイント購入',
  ポイント: 'ポイント',
  ドル: 'ドル',
  円: '円',
  レートを確認する: 'レートを確認する',
  ボーナスポイント: 'ボーナスポイント',
  'ボーナスポイントは購入ポイント獲得時に付与されます。': 'ボーナスポイントは購入ポイント獲得時に付与されます。',
  購入方法を選択: '購入方法を選択',
  仮想通貨: '仮想通貨',
  クレジット: 'クレジット',
  現金振込: '現金振込',
  'ページを更新して再度お試しください。':
    'ページを更新して再度お試しください。',
  'データを取得できませんでした。': 'データを取得できませんでした。',
  '最低購入ポイント数は10ポイントです。':
    '最低購入ポイント数は10ポイントです。',
  'お名前(必須)': 'お名前(必須)',
  '姓(カナ)': '姓(カナ)',
  '名(カナ)': '名(カナ)',
  '全角カタカナで入力してください。': '全角カタカナで入力してください。',
  '口座名義は入力必須です。': '口座名義は入力必須です。',
  // ポイント購入（仮想通貨）
  'レート表記はページにアクセスした時点のものです。入金時の数値とは異なる場合がございます。':
    'レート表記はページにアクセスした時点のものです。入金時の数値とは異なる場合がございます。',
  仮想通貨で入金: '仮想通貨で入金',
  送金アドレス: '送金アドレス',
  アドレスを再取得: 'アドレスを再生成',
  'コピーしました。': 'コピーしました。',
  'コピー失敗しました。': 'コピー失敗しました。',
  // ポイント購入（クレジット）
  クレジットカードで入金: 'クレジットカードで入金',
  有効期限: '有効期限',
  クレジットカード番号: 'クレジットカード番号',
  カード名義人: 'カード名義人',
  支払い回数: '支払い回数',
  一括払い: '一括払い',
  '3回払い': '3回払い',
  '6回払い': '6回払い',
  セキュリティコード: 'セキュリティコード',
  '以上の内容でよろしければ「入金する」ボタンをクリックしてください。':
    '以上の内容でよろしければ「入金する」ボタンをクリックしてください。',
  'お支払い手続きが完了いたします。': 'お支払い手続きが完了いたします。',
  // ポイント購入（現金）
  現金で入金: '現金で入金',
  '支払額を以下の口座にお振込ください。':
    '支払額を以下の口座お振込ください。',
  依頼人名: '依頼人名',
  銀行名: '銀行名',
  支店名: '支店名',
  店番号: '店番号',
  口座科目: '口座科目',
  口座番号: '口座番号',
  口座名義: '口座名義',
  // ポイント購入完了（共通）
  入金情報送信完了: '入金情報送信完了',
  '確認ができ次第メールにてお知らせいたします。今しばらくお待ちください。':
    '確認ができ次第メールにてお知らせいたします。今しばらくお待ちください。',
  // ポイント出金・交換(トップ)
  '出金・交換ポイント数': '出金・交換ポイント数',
  '出金・交換をするポイント数を入力してください。': '出金・交換をするポイント数を入力してください。',
  ポイント残高: 'ポイント残高',
  '出金・交換方法を選択': '出金・交換方法を選択',
  '半角数字のみ入力してください。': '半角数字のみ入力してください。',
  '50ポイントから出金が可能です。': '50ポイントから出金が可能です。',
  '入力ポイントが所持ポイントを超えています。': '入力ポイントが所持ポイントを超えています。',
  '送金先アドレスを入力してください。': '送金先アドレスを入力してください。',
  // ポイント出金・交換(仮想通貨)
  送金手続き: '送金手続き',
  送金内容の確認: '送金内容の確認',
  送金額: '送金額',
  送金先アドレス: '送金先アドレス',
  '上記内容に間違いがないことを確認して送金をしてください。送金確定後の返金や操作の取り消しはできません。': '上記内容に間違いがないことを確認して送金をしてください。送金確定後の返金や操作の取り消しはできません。',
  // ポイント出金・交換(受付完了)
  '出金・交換を受け付けました。': '出金・交換を受け付けました。',
  '取引に問題があった場合はメールにてご連絡を致します。またご不明な点がありましたらサポートまでお問い合わせください。': '取引に問題があった場合はメールにてご連絡を致します。またご不明な点がありましたらサポートまでお問い合わせください。',
  // 履歴
  べット履歴: 'べット履歴',
  当選履歴: '当選履歴',
  ポイント購入履歴: 'ポイント購入履歴',
  'ポイント出金・交換履歴': 'ポイント出金・交換履歴',
  もっと見る: 'もっと見る',
  // DayLott'sとは
  "DayLott'sでは、毎日ミリオネアを排出する、世界初のゲームです。少額から大口まで、多彩なテーブルを用意することで、より多くの方にゲームを楽しんでもらうことができます。":
    "DayLott'sでは、毎日ミリオネアを排出する、世界初のゲームです。少額から大口まで、多彩なテーブルを用意することで、より多くの方にゲームを楽しんでもらうことができます。",
  '業界最高水準の75%！！世界で１番ユーザーが獲得できるゲームになるようにこだわって作りました。そしてその割合は常に一定です。ゲームにエントリーをした全てのユーザーに平等に獲得するチャンスがあります。':
    '業界最高水準の75%！！世界で１番ユーザーが獲得できるゲームになるようにこだわって作りました。そしてその割合は常に一定です。ゲームにエントリーをした全てのユーザーに平等に獲得するチャンスがあります。',
  'ビットコイン、テザー、イーサリアムでゲームを選ぶポイントを購入することができます。':
    'ビットコイン、テザー、イーサリアムでゲームを選ぶポイントを購入することができます。',
  '会員登録からエントリーまでたった3分で完了します。スマートフォンがあればいつでもどこでも気軽にゲームにエントリーをすることができます。':
    '会員登録からエントリーまでたった3分で完了します。スマートフォンがあればいつでもどこでも気軽にゲームにエントリーをすることができます。',
  入金はこちら: '入金はこちら',
  // 仕組み
  '世界初、必ず購入した誰かが高額当選、ミリオネアを誕生させる。':
    '世界初、必ず購入した誰かが高額当選、ミリオネアを誕生させる。',
  '世界最高水準還元率が驚異の75%を誇るオンラインゲームです。':
    '世界最高水準還元率が驚異の75%を誇るオンラインゲームです。',
  'エントリーするベットポイント、当選ポイントに応じたボックスが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステム。エントリーしたボックスのチケット購入数が満たされると即座に抽選が始まり、当選が確定します。世界一簡単な、世界一高額当選のできる世界一スピード感のあるオンラインゲームです。':
    'エントリーするベットポイント、当選ポイントに応じたボックスが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステム。エントリーしたボックスのチケット購入数が満たされると即座に抽選が始まり、当選が確定します。世界一簡単な、世界一高額当選のできる世界一スピード感のあるオンラインゲームです。',
  '対応端末 iOS 14.8以降 Android 12.0以降':
    '対応端末 iOS 14.8以降 Android 12.0以降',
  'チケットを購入し、抽選ページにアクセス':
    'チケットを購入し、抽選ページにアクセス',
  '抽選演出開始され、当選演出時にご自身で登録したユーザー名が枠内に止まりましたら当選確定':
    '抽選演出開始され、当選演出時にご自身で登録したユーザー名が枠内に止まりましたら当選確定',
  '当選確定後、当選ポイントが反映され出金が可能':
    '当選確定後、当選ポイントが反映され出金が可能',
  ご購入された誰かが必ず高額当選: 'ご購入された誰かが必ず高額当選',
  "ボックス毎に設定された高額当選ポイントが、ご購入された誰かに当選します。Day Lott'sではボックスに設定されている当選ポイントは必ずユーザーに反映されます。":
    "ボックス毎に設定された高額当選ポイントが、ご購入された誰かに当選します。Day Lott'sではボックスに設定されている当選ポイントは必ずユーザーに反映されます。",
  '安心・安全・透明性のある当選': '安心・安全・透明性のある当選',
  "Day Lott'sでは、透明性のある運営を心がけています。全てのユーザーが当選したユーザーを確認することができます。また、個人情報の保護、入出金におけるセキュリティの強化をしているため、安全にプレイをして頂くことができます。":
    "Day Lott'sでは、透明性のある運営を心がけています。全てのユーザーが当選したユーザーを確認することができます。また、個人情報の保護、入出金におけるセキュリティの強化をしているため、安全にプレイをして頂くことができます。",
  バリエーションのある当選ポイント設定: 'バリエーションのある当選ポイント設定',
  'ユーザーのニーズを満たしたプレイボックスを多く用意しています。小さなべットエントリーで高額当選ポイントを獲得することも可能です。最低5ポイントからエントリーが可能となっており、最大100ミリオンポイントの当選も実現します。':
    'ユーザーのニーズを満たしたプレイボックスを多く用意しています。小さなべットエントリーで高額当選ポイントを獲得することも可能です。最低5ポイントからエントリーが可能となっており、最大100ミリオンポイントの当選も実現します。',
  // よくある質問
  入金: '入金',
  出金: '出金',
  'よくある質問はありません。': 'よくある質問はありません。',
  // ランキング
  一日: '1日',
  週間: '週間',
  月間: '月間',
  全期間: '全期間',
  'ランキングはありません。': 'ランキングはありません。',
  // サポート
  '当ページのよくある質問をまずはご覧ください。':
    '当ページのよくある質問をまずはご覧ください。',
  メールでのお問い合わせ: 'メールでのお問い合わせ',
  'までお問い合わせください。': 'までお問い合わせください。',
  お問合せ時間: 'お問合せ時間',
  '毎日対応させて頂いております。': '毎日対応させて頂いております。',
  '※お問い合わせの回答に数日頂く事がございます。3営業日以内には回答させて頂きます。':
    '※お問い合わせの回答に数日頂く事がございます。3営業日以内には回答させて頂きます。',
  // PLAYBOX
  テーブル選択: 'テーブル選択',
  予約状況: '予約状況',
  口: '口',
  ゼウス: 'ゼウス',
  キング: 'キング',
  ダイヤモンド: 'ダイヤモンド',
  ゴールド: 'ゴールド',
  シルバー: 'シルバー',
  ブロンズ: 'ブロンズ',
  アンバー: 'アンバー',
  口数選択: '口数選択',
  購入する: '購入する',
  キャンセル: 'キャンセル',
  ダイヤモンド獲得確率: 'ダイヤモンド獲得確率',
  ゴールド獲得確率: 'ゴールド獲得確率',
  獲得確率: '獲得確率',
  獲得: '獲得',
  確率: '確率',
  口購入: '口購入',
  '所持ポイントが不足しています。': '所持ポイントが不足しています。',
  '購入するにはログインが必要です。': '購入にはログインが必要です。',
  '1度に購入できるのは2000口までです。': '1度に購入できるのは2000口までです。',
  // チケット購入確認
  購入内容のご確認: '購入内容のご確認',
  '下記の内容で間違いないかご確認ください。':
    '下記の内容で間違いないかご確認ください。',
  '「購入する」ボタンをクリックすると購入が確定します。':
    '「購入する」ボタンをクリックすると購入が確定します。',
  参加ボックス: '参加ボックス',
  購入ポイント数: '購入ポイント数',
  消費ポイント数: '消費ポイント数',
  所持有償ポイント数: '所持有償ポイント数',
  所持無償ポイント数: '所持無償ポイント数',
  残り有償ポイント数: '残り有償ポイント数',
  残り無償ポイント数: '残り無償ポイント数',
  // チケット購入完了
  チケット番号: 'チケット番号',
  番: '番',
  '参加中のPLAY BOX': '参加中のPLAY BOX',
  抽選画面へ: '抽選画面へ',
  // お知らせ一覧
  '購入済みのチケットの抽選が開始されました。矢印をタップすると結果を見ることができます。':
    '購入済みのチケットの抽選が開始されました。矢印をタップすると結果を見ることができます。',
  'お客様のランクがアップされました。': 'お客様のランクがアップされました。',
  キャンペーン: 'キャンペーン',
  表示を閉じる: '表示を閉じる',
  // お知らせ
  'お知らせはありません。': 'お知らせはありません。',
  抽選の結果を見る: '抽選の結果を見る',
  抽選会場へ行く: '抽選会場へ行く',
  マイページで確認: 'マイページで確認',
  // お問い合わせ
  お問い合わせフォーム: 'お問い合わせフォーム',
  'お客様からのご質問をお問い合わせフォームにて受け付けております。':
    'お客様からのご質問をお問い合わせフォームにて受け付けております。',
  '下記項目をご記入の上、「確認する」を押してください。':
    '下記項目をご記入の上、「確認する」を押してください。',
  氏名: '氏名',
  お問い合わせ内容: 'お問い合わせ内容',
  // お問い合わせ確認
  お問い合わせ内容の確認: 'お問い合わせ内容の確認',
  // お問い合わせ受付完了
  'お問い合わせを受け付けました。': 'お問い合わせを受け付けました。',
  'お問い合わせありがとうございます。': 'お問い合わせありがとうございます。',
  'お問い合わせには3営業日以内に回答させて頂きます。':
    'お問い合わせには3営業日以内に回答させて頂きます。',
  // 抽選結果
  'このページは動画が自動再生されます。よろしければ"OK"を押してください。動画を再生せずポイントを受け取る場合は"受け取る"を押してください。':
    'このページは動画が自動再生されます。よろしければ"OK"を押してください。動画を再生せずポイントを受け取る場合は"受け取る"を押してください。',
  Waiting: 'Waiting',
  購入日時: '購入日時',
  当選ポイント: '当選ポイント',
  // ポイント受け取り
  獲得ポイント受け取り: '獲得ポイント受け取り',
  動画を再生せずに獲得した全てのポイントを受け取る: '動画を再生せずに獲得した全てのポイントを受け取る',
  'ポイントを受け取りました。': 'ポイントを受け取りました。',
  // 共通エラー
  通信エラー: '通信エラー',
  サーバーエラー: 'サーバーエラー',
  // バリエーションエラー
  'は入力必須です。': 'は入力必須です。',
  'メールアドレスは正しい形式で入力してください。':
    'メールアドレスは正しい形式で入力してください。',
  'ユーザー名は半角英数字のみで入力してください。':
    'ユーザー名は半角英数字のみで入力してください。',
  'パスワードは大文字小文字を含む半角英数字を組み合わせて入力してください。':
    'パスワードは大文字小文字を含む半角英数字を組み合わせて入力してください。',
  'パスワードが一致しません。': 'パスワードが一致しません。',
  '画像は.jpgか.pngをアップロードしてください。':
    '画像は.jpgか.pngをアップロードしてください。',
  '金額を入れてください。': '金額を入れてください。',
  '日本円での合計金額(手数料込)': '日本円での合計金額(手数料込)',
  同意: '同意',
};
