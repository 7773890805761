// External library
import { atom } from 'recoil';
import { ATOM_KEYS } from 'utils/const/recoilKeys';
// Types
import { LotSelectType } from 'types/api';
import { PlayBoxType } from 'types/playBoxType';

const initialPlayBox: PlayBoxType = {
  id: 0,
  title: '',
  total_offer: 0,
  minimum_bet: 0,
  dividend_amount: 0,
  recruitment: 0,
  implementation_flg: 1,
  type: null,
  public_flg: true,

  lot_tables: [
    {
      id: 0,
      title: '',
      en_title: '',
      lot_box_id: 0,
      winning_point_1st: 0,
      number_of_winners_1st: 0,
      winning_point_2nd: 0,
      number_of_winners_2nd: 0,
      winning_point_3rd: 0,
      number_of_winners_3rd: 0,
      winning_point_4th: 0,
      number_of_winners_4th: 0,
      winning_point_5th: 0,
      number_of_winners_5th: 0,
      public_flg: 0,
      lottery_flg: 0,
      lottery_at: '',
      movie_status: 0,
      production_end_flg: 0,
      special_point: 0,
      special_number: 0,
      created_at: '',
      updated_at: '',
      deleted_at: '',
      lot_bets_count: 0,
    },
  ],
};

export const playBoxListState = atom<LotSelectType>({
  key: ATOM_KEYS.PLAYBOX_LIST_STATE,
  default: {
    1000: initialPlayBox,
    5000: initialPlayBox,
    10000: initialPlayBox,
    100000: initialPlayBox,
    1000000: initialPlayBox,
  },
});
