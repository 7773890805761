import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import CommonModal from '../modal/CommonModal';
import CrossIcon from 'components/atoms/icon/CrossIcon';

/**
 *
 * Props
 *
 */
type Props = {
  onClose: () => void;
};

/**
 *
 * Component
 *
 */
const HomeModal: FC<Props> = memo(({ onClose }) => {
  const { t } = useTranslation();

  return (
    <CommonModal backgroundColor="rgba(0, 0, 0, 0.9)">
      <ContainerS>
        <HeaderS>
          <CloseButtonS onClick={onClose}>
            <CrossIcon backgroundColor="#ffffff" />
          </CloseButtonS>
        </HeaderS>
        <TitleS>
          {t("DayLott'sのリンクをホーム画面に追加すると次回以降簡単にアクセスができます。")}
        </TitleS>
        <FlowS>
          <FlowHeadS>{t('iPhoneの場合')}</FlowHeadS>
          <FlowBodyS>
            <FlowContentS>
              {t('1. ブラウザ(Safari)でWEBページを開いた状態で、下部の「共有アイコン」をタップ。')}
            </FlowContentS>
            <FlowArrowS>↓</FlowArrowS>
            <FlowContentS>
              {t('2. 共有メニューに表示される「ホーム画面に追加」をタップ。')}
            </FlowContentS>
            <FlowArrowS>↓</FlowArrowS>
            <FlowContentS>
              {t('3. 名称などをご確認いただき、画面右上の「追加」をタップ。')}
            </FlowContentS>
            <FlowArrowS>↓</FlowArrowS>
            <FlowContentS>
              {t('4. ホーム画面にアイコンが追加されたら完了です。')}
            </FlowContentS>
          </FlowBodyS>
        </FlowS>

        <ConfirmButtonS onClick={onClose}>
          {t('確認して閉じる')}
        </ConfirmButtonS>
      </ContainerS>
    </CommonModal>
  );
});

export default HomeModal;

const ContainerS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  padding: 2rem 2rem 3rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  color: #ffffff;
`;

const HeaderS = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CloseButtonS = styled.button`
  width: 2.4rem;
  height: 2.4rem;
`;

const TitleS = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.3;
`;

const FlowS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.4rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #aaaaaa;
  padding: 2rem;
`;

const FlowHeadS = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
`;

const FlowBodyS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
`;

const FlowContentS = styled.p`
  font-size: 1.2rem;
  line-height: 1.4;
`;

const FlowArrowS = styled.span`
  font-weight: bold;
`;

const ConfirmButtonS = styled.button`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.4rem;
  color: #000000;
  background-color: #fff040;
  border-radius: 6px;
  width: 80%;

  @media (min-width: 600px) {
    width: 200px;
  }

  &:hover {
    opacity: 0.8;
  }
`;
