import { useRef, useState, RefObject } from 'react';
// External library
import { useParams, useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
// Custom hooks
import { useValidate } from 'hooks/versatile/useValidate';
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType } from 'types/api';

/**
 *
 * パスワードリセットフォーム用ロジック
 *
 */
export const usePasswordResetForm = (): {
  isLoading: boolean;
  errorMessage: string;
  passwordRef: RefObject<HTMLInputElement>;
  confirmPasswordRef: RefObject<HTMLInputElement>;
  postApiResetPassword: () => void;
  cleanUpMethod: () => void;
} => {
  // paramsオブジェクト
  const params = useParams<{ token: string }>();
  const { token } = params;

  // ローディング状態
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');
  // エラー用カスタムフック
  const { errorHandler } = useError();
  // バリデーション用カスタムフック
  const { passwordValidate, confirmPasswordValidate } = useValidate();

  // パスワード
  const passwordRef = useRef<HTMLInputElement>(null);
  // パスワード(確認用)
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  // historyオブジェクト
  const history = useHistory();
  // axiosキャンセルトークン
  const axiosSource = axiosCancelToken.source();
  // パスワードリセットリクエスト
  const postApiResetPassword = () => {
    if (!isLoading) {
      setIsLoading(true);

      // パスワードバリデーション
      let password = '';
      if (passwordRef.current !== null) {
        const result = passwordValidate(passwordRef.current.value);
        if (result) {
          setErrorMessage(result);
          setIsLoading(false);

          return;
        }

        password = passwordRef.current.value;
      }
      // パスワード(確認)バリデーション
      let confirmPassword = '';
      if (passwordRef.current !== null && confirmPasswordRef.current !== null) {
        const result = confirmPasswordValidate(
          passwordRef.current.value,
          confirmPasswordRef.current.value,
        );
        if (result) {
          setErrorMessage(result);
          setIsLoading(false);

          return;
        }

        confirmPassword = confirmPasswordRef.current.value;
      }

      // axios POST /api/reset_password
      axiosClient
        .post<boolean>('/api/reset_password', {
          password,
          password_confirmation: confirmPassword,
          token,
          cancelToken: axiosSource.token,
        })
        .then(({ data }) => {
          if (data) {
            history.push({
              pathname: '/password_reset/finish',
              state: {
                referrer: '/password_reset/form',
              },
            });
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    setIsLoading(false);
    setErrorMessage('');
    axiosSource.cancel();
  };

  return {
    isLoading,
    errorMessage,
    passwordRef,
    confirmPasswordRef,
    postApiResetPassword,
    cleanUpMethod,
  };
};
