import {
  useState,
  useCallback,
  useRef,
  RefObject,
  useEffect,
} from 'react';
// External library
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Hooks
import { useAuth } from 'hooks/versatile/useAuth';
import { useError } from 'hooks/versatile/useError';
// Recoil
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { productionEndFlagState } from 'store/atoms/lottery/productionEndFlag';
import { earnedPointsGroupsState, earnedPointsState } from 'store/atoms/lottery/earnedPointsState';
import { earnedPointShowFlagsState } from 'store/atoms/lottery/earnedPointShowFlagsState';
import { entryUsersState } from 'store/atoms/lottery/entryUsersState';
import { firstWinnerState, initialFirstWinner } from 'store/atoms/lottery/firstWinnerState';
import { secondWinnersState } from 'store/atoms/lottery/secondWinnersState';
import { thirdWinnersState } from 'store/atoms/lottery/thirdWinnersState';
import { fourthWinnersState } from 'store/atoms/lottery/fourthWinnersState';
import { fifthWinnersState } from 'store/atoms/lottery/fifthWinnersState';
import { specialPointsState, winnersState } from 'store/atoms/lottery/winnersState';
import { rankingState } from 'store/atoms/lottery/rankingState';
import { currentRankResultState, initialMovieList, movieList } from 'store/atoms/lottery/movieList';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
import { PLAYBOX_TYPE } from 'utils/const/playBox';
import { WINNERS_RANK_UP_TO_FIFTH } from 'utils/const/lottery/winners';
import { LIMIT_PER_PAGE, SPECIAL_FLAG } from 'utils/const/lottery/entryUsers';
import { PRODUCTION_END_FLAG } from 'utils/const/lottery/lotBox';
import {
  EVENT_ENABLED,
  EVENT_NEWS,
  EVENT_USERS,
} from 'utils/const/event';
// Types
import {
  LotResultCheckType,
  LotResultRankingType,
  LotResultEntryWithPaginationType,
  LotResultWinnersWithPaginationType, LotResultPoints,
} from 'types/api';
import { PlayBox } from 'types/playBoxType';
import { MovieList } from 'types/lottery/movieList';

/**
 *
 * Types
 *
 */
type Pagination = {
  prevPageUrl: string;
  prevPage: number | null
  first: boolean;
  nextPageUrl: string;
  nextPage: number | null
  last: boolean;
};

type UseLottery = () => {
  isLoading: boolean;
  entryUsersScrollPagination: Pagination;
  winnersScrollPagination: Pagination;
  playBoxType: PlayBox | null;
  isShowModal: boolean;
  canWatchMovie: boolean;
  canReceivePoints: boolean;
  getApiEntryUsers: () => void;
  getApiWinners: () => void;
  onClickLotteryCheck: () => void;
  onReceive: () => void;
  goBack: () => void;
  audioRef: RefObject<HTMLAudioElement>;
  lotTableId: string;
};

/**
 *
 * 抽選結果ページ用ロジック
 *
 */
export const useLottery: UseLottery = () => {
  const { BOX_100000 } = PLAYBOX_TYPE;

  // クエリパラメーター取得(LotテーブルID)
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const lotTableId = query.get('id') ?? '';

  // エラー用カスタムフック
  const { displayErrorMessageInToast } = useError();

  // 抽選演出種別ステート
  const [playBoxType, setPlayBoxType] = useState<PlayBox | null>(null);
  const isPlayBoxType = (arg: PlayBox | null): arg is PlayBox => arg !== null;

  // 抽選演出スキップフラグ
  const setProductionEndFlag = useSetRecoilState(productionEndFlagState);

  // Recoil ログインユーザー
  const loginUser = useRecoilValue(userState);
  // Recoil エントリーユーザー用Setter
  const setEntryUsers = useSetRecoilState(entryUsersState);
  let entryUsersCount = 0;

  // Recoil 獲得ポイント
  const setEarnedPointsGroups = useSetRecoilState(earnedPointsGroupsState);
  const setEarnedPoints = useSetRecoilState(earnedPointsState);
  const setEarnedPointShowFlags = useSetRecoilState(earnedPointShowFlagsState);
  // Recoil 各当選者情報用Setter
  const setWinners = useSetRecoilState(winnersState);
  const setSpecialPoints = useSetRecoilState(specialPointsState);
  const setFirstWinner = useSetRecoilState(firstWinnerState);
  const setSecondWinners = useSetRecoilState(secondWinnersState);
  const setThirdWinners = useSetRecoilState(thirdWinnersState);
  const setFourthWinners = useSetRecoilState(fourthWinnersState);
  const setFifthWinners = useSetRecoilState(fifthWinnersState);

  // Recoil 動画
  const currentRankResult = useRecoilValue(currentRankResultState);

  // Recoil ランキング
  const setRanking = useSetRecoilState(rankingState);
  // movie list
  const setMovieList = useSetRecoilState(movieList);

  // API 抽選開始フラグ
  const lotResultCheckSource = axiosCancelToken.source();
  const getApiLotResultCheck = () => (
    axiosClient.get<LotResultCheckType>(
      `/api/lot/result/check?lot_table_id=${lotTableId}`,
      { cancelToken: lotResultCheckSource.token },
    )
  );

  // ページネーション
  const initialPagination = {
    prevPageUrl: '',
    prevPage: null,
    first: false,
    nextPageUrl: '',
    nextPage: null,
    last: false,
  };
  const [entryUsersScrollPagination, setEntryUsersScrollPagination] = useState<Pagination>({
    ...initialPagination,
  });
  const [winnersScrollPagination, setWinnersScrollPagination] = useState<Pagination>({
    ...initialPagination,
  });
  const limitPerPage = LIMIT_PER_PAGE;
  // API エントリーユーザー
  const lotResultEntrySource = axiosCancelToken.source();
  const getApiLotResultEntry = (page: number|null = null) => (
    axiosClient.get<LotResultEntryWithPaginationType>(
      page
        ? `/api/lot/result/entry?page=${page}&lot_table_id=${lotTableId}&limit=${limitPerPage}`
        : `/api/lot/result/entry?lot_table_id=${lotTableId}&limit=${limitPerPage}`,
      { cancelToken: lotResultEntrySource.token },
    )
  );

  // API 獲得ポイント
  const lotResultPointsSource = axiosCancelToken.source();
  const getApiLotResultPoints = () => (
    axiosClient.get<LotResultPoints>(
      `/api/lot/result/points?lot_table_id=${lotTableId}`,
      { cancelToken: lotResultPointsSource.token },
    )
  );

  // API 当選者
  const lotResultWinnersSource = axiosCancelToken.source();
  const getApiLotResultWinners = (page: number|null = null) => (
    axiosClient.get<LotResultWinnersWithPaginationType>(
      page
        ? `/api/lot/result/winners?page=${page}&lot_table_id=${lotTableId}`
        : `/api/lot/result/winners?lot_table_id=${lotTableId}`,
      { cancelToken: lotResultWinnersSource.token },
    )
  );

  // API ランキング
  const lotResultRankingSource = axiosCancelToken.source();
  const getApiLotResultRanking = () => (
    axiosClient
      .get<LotResultRankingType[]>(
        `/api/lot/result/ranking?lot_table_id=${lotTableId}`,
        { cancelToken: lotResultRankingSource.token },
      )
  );

  // Movie取得
  const movieListSource = axiosCancelToken.source();
  const getMovieList = () => (
    axiosClient.get<MovieList>(
      `/api/movie/list?lot_table_id=${lotTableId}`,
      { cancelToken: movieListSource.token },
    )
  );

  // 定期リクエスト用Ref
  const intervalRef = useRef<null | NodeJS.Timer>(null);
  const stopLotResultCheck = () => {
    if (intervalRef.current === null) {
      return;
    }

    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  // 抽選開始状態定期リクエスト用プロセス
  const [isLoading, setIsLoading] = useState(false);
  const requestedRef = useRef<boolean>(false);
  const lotteryCheckProcess = async () => {
    try {
      requestedRef.current = true;

      /**
       *
       * API 抽選開始フラグ
       * API エントリーユーザー
       *
       */
      const [lotResultCheck, lotResultEntry] = await Promise.all([
        getApiLotResultCheck(),
        getApiLotResultEntry(),
      ]);

      // エントリーユーザー
      if (entryUsersCount < LIMIT_PER_PAGE) {
        const users = lotResultEntry.data.entry.map((user) => ({
          betId: user.id,
          userId: user.user_id,
          ticketNumber: user.ticket_number,
          name: user.user_name,
          date: user.ymd,
          point: user.win_point,
          specialFlg: user.special_flg,
        }));

        if (EVENT_ENABLED && Number(lotTableId) === EVENT_NEWS.ID) {
          users.splice(0);

          // TODO:
          for (let i = 0; i < EVENT_USERS.length; i++) {
            users.push({
              betId: 9501,
              userId: i + 1,
              ticketNumber: i + 1,
              name: EVENT_USERS[i],
              date: (new Date()).toLocaleDateString().replaceAll('/', '-'),
              point: 0,
              specialFlg: 0,
            });
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setEntryUsers((prev) => users);
        entryUsersCount = users.length;

        const { pagination } = lotResultEntry.data;
        setEntryUsersScrollPagination({
          prevPageUrl: pagination.prev_page_url,
          prevPage: pagination.current_page === 1 ? null : pagination.current_page - 1,
          first: pagination.current_page === 1,
          nextPageUrl: pagination.next_page_url,
          nextPage: pagination.last ? null : pagination.current_page + 1,
          last: pagination.last,
        });
      }

      /**
       *
       * API ランキング
       *
       */
      const lotResultRanking = await getApiLotResultRanking();
      // ランキング
      setRanking(lotResultRanking.data.map((v) => (
        {
          userId: v.user_id,
          name: v.name,
          rank: v.rank,
          isShow: true,
        }
      )));

      // 待機状態であれば定期リクエスト再開
      const { result } = lotResultCheck.data;
      let { lot_boxes } = lotResultCheck.data;
      if (!result && !EVENT_ENABLED) {
        requestedRef.current = false;
        setIsLoading(false);

        return;
      }

      if (EVENT_ENABLED && Number(lotTableId) === EVENT_NEWS.ID) {
        lot_boxes = {
          id: 3,
          title: 'Day Lott\'s 100000',
          total_offer: 20000,
          minimum_bet: 5,
          dividend_amount: 15000,
          recruitment: 4000,
          implementation_flg: 1,
          public_flg: 1,
          type: BOX_100000,
          created_at: '2022-10-10T13:17:53.000000Z',
          updated_at: '2022-10-10T13:17:53.000000Z',
          deleted_at: '',
          production_end_flg: 1,
        };
      }

      // PLYABOX情報が取得できない場合のエラー
      if (lot_boxes === null || lot_boxes === undefined) throw new Error('Server error.');

      // PLAYBOX種別のチェック
      const { type } = lot_boxes;
      if (!isPlayBoxType(type)) throw new Error('Server error.');
      // 抽選演出スキップフラグ(2日経過していたら)
      const { production_end_flg } = lot_boxes;
      setProductionEndFlag(production_end_flg);

      /**
       *
       * API 当選者
       * API 動画リスト
       *
       */
      const [
        lotResultPoints,
        lotResultWinners,
        lotMovieList,
      ] = await Promise.all([
        getApiLotResultPoints(),
        getApiLotResultWinners(),
        getMovieList(),
      ]);

      // 獲得ポイント
      setEarnedPointsGroups(lotResultPoints.data);

      // 特別枠当選ポイントセット
      let specialPoints = 0;
      let index = 0;
      const specialPointBetIds: number[] = [];

      const specialPointWinners = lotResultWinners.data.winners
        .filter((winner) => winner.special_flg === SPECIAL_FLAG.TRUE);
      specialPointWinners.forEach((user) => {
        if (user.user_id === loginUser.id && user.special_flg === SPECIAL_FLAG.TRUE) {
          specialPoints += user.win_point;
        }

        if (user.special_flg === SPECIAL_FLAG.TRUE) {
          specialPointBetIds[index] = user.id;
          index++;
        }
      });
      if (specialPoints > 0) setEarnedPoints(specialPoints);
      setEarnedPointShowFlags([...specialPointBetIds]);
      setSpecialPoints(specialPoints);

      // 当選者
      const winners = EVENT_ENABLED && Number(lotTableId) === EVENT_NEWS.ID
        ? []
        : lotResultWinners.data
          .winners
          .filter((winner) => winner.special_flg === SPECIAL_FLAG.FALSE)
          .map((winner) => (
            {
              betId: winner.id,
              userId: winner.user_id,
              name: winner.user_name,
              rank: winner.rank,
              point: winner.win_point,
              isShow: false,
              specialFlg: winner.special_flg,
            }
          ));
      setWinners(winners);

      const { pagination } = lotResultWinners.data;
      setWinnersScrollPagination({
        prevPageUrl: pagination.prev_page_url,
        prevPage: pagination.current_page === 1 ? null : pagination.current_page - 1,
        first: pagination.current_page === 1,
        nextPageUrl: pagination.next_page_url,
        nextPage: pagination.last ? null : pagination.current_page + 1,
        last: pagination.last,
      });

      if (!EVENT_ENABLED || Number(lotTableId) !== EVENT_NEWS.ID) {
        const {
          FIRST, SECOND, THIRD, FOURTH, FIFTH,
        } = WINNERS_RANK_UP_TO_FIFTH;
        // 1等当選者情報セット
        const firstWinner = winners.find((winner) => winner.rank === FIRST) ?? null;
        setFirstWinner(firstWinner);

        // 2等当選者情報セット
        const secondWinners = winners.filter((winner) => winner.rank === SECOND);
        setSecondWinners(secondWinners);

        // 3等当選者情報セット
        const thirdWinners = winners.filter((winner) => winner.rank === THIRD);
        setThirdWinners(thirdWinners);

        // PLAYBOX10000は4等当選者情報セット
        if (type === PLAYBOX_TYPE.BOX_10000) {
          const fourthWinners = winners.filter((winner) => winner.rank === FOURTH);
          setFourthWinners(fourthWinners);
        }

        // PLAYBOX100000は5等当選者情報セット
        if (type === PLAYBOX_TYPE.BOX_100000) {
          const fifthWinners = winners.filter((winner) => winner.rank === FIFTH);
          setFifthWinners(fifthWinners);
        }
      }

      // 動画リスト
      setMovieList(lotMovieList.data);

      // 抽選演出用動画コンポーネントレンダリング
      setPlayBoxType(type);

      // 定期リクエスト終了
      stopLotResultCheck();

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      displayErrorMessageInToast(error);
      setIsLoading(false);
    }
  };

  const getApiEntryUsers = async (isNext = true) => {
    setIsLoading(true);
    const lotResultEntry = await getApiLotResultEntry(isNext
      ? entryUsersScrollPagination.nextPage
      : entryUsersScrollPagination.prevPage);
    setIsLoading(false);
    // エントリーユーザー
    const users = lotResultEntry.data.entry.map((user) => ({
      betId: user.id,
      userId: user.user_id,
      ticketNumber: user.ticket_number,
      name: user.user_name,
      date: user.ymd,
      point: user.win_point,
      specialFlg: user.special_flg,
    }));
    setEntryUsers((prev) => (isNext ? prev.concat(users) : users.concat(prev)));
    entryUsersCount += users.length;

    const { pagination } = lotResultEntry.data;
    setEntryUsersScrollPagination({
      prevPageUrl: !isNext
        ? pagination.prev_page_url
        : entryUsersScrollPagination.prevPageUrl,
      prevPage: !isNext
        ? (pagination.current_page === 1 ? null : pagination.current_page - 1)
        : entryUsersScrollPagination.prevPage,
      first: !isNext
        ? pagination.current_page === 1
        : entryUsersScrollPagination.first,
      nextPageUrl: isNext
        ? pagination.next_page_url
        : entryUsersScrollPagination.nextPageUrl,
      nextPage: isNext
        ? (pagination.last ? null : pagination.current_page + 1)
        : entryUsersScrollPagination.nextPage,
      last: isNext
        ? pagination.last
        : entryUsersScrollPagination.last,
    });
  };

  const getApiWinners = async () => {
    setIsLoading(true);
    console.log('getApiWinners');
    const lotResultWinners = await getApiLotResultWinners(winnersScrollPagination.nextPage);
    setIsLoading(false);

    const winners = lotResultWinners.data
      .winners
      .filter((winner) => winner.special_flg === SPECIAL_FLAG.FALSE)
      .map((winner) => (
        {
          betId: winner.id,
          userId: winner.user_id,
          name: winner.user_name,
          rank: winner.rank,
          point: winner.win_point,
          isShow: Number(winner.rank) >= Number(currentRankResult),
          specialFlg: winner.special_flg,
        }
      ));
    setWinners((prev) => {
      const hoge = prev.concat(winners);
      console.log(hoge.length);

      return hoge;
    });

    const { pagination } = lotResultWinners.data;
    setWinnersScrollPagination({
      prevPageUrl: winnersScrollPagination.prevPageUrl,
      prevPage: winnersScrollPagination.prevPage,
      first: winnersScrollPagination.first,
      nextPageUrl: pagination.next_page_url,
      nextPage: (pagination.last ? null : pagination.current_page + 1),
      last: pagination.last,
    });

    if (!EVENT_ENABLED || Number(lotTableId) !== EVENT_NEWS.ID) {
      const {
        FIRST, SECOND, THIRD, FOURTH, FIFTH,
      } = WINNERS_RANK_UP_TO_FIFTH;
      // 1等当選者情報セット
      const firstWinner = winners.find((winner) => winner.rank === FIRST) ?? null;
      setFirstWinner(firstWinner);

      // 2等当選者情報セット
      const secondWinners = winners.filter((winner) => winner.rank === SECOND);
      setSecondWinners((prev) => prev.concat(secondWinners));

      // 3等当選者情報セット
      const thirdWinners = winners.filter((winner) => winner.rank === THIRD);
      setThirdWinners((prev) => prev.concat(thirdWinners));

      // PLAYBOX10000は4等当選者情報セット
      if (playBoxType === PLAYBOX_TYPE.BOX_10000) {
        const fourthWinners = winners.filter((winner) => winner.rank === FOURTH);
        setFourthWinners((prev) => prev.concat(fourthWinners));
      }

      // PLAYBOX100000は5等当選者情報セット
      if (playBoxType === PLAYBOX_TYPE.BOX_100000) {
        const fifthWinners = winners.filter((winner) => winner.rank === FIFTH);
        setFifthWinners((prev) => prev.concat(fifthWinners));
      }
    }
  };

  // 抽選開始状態定期リクエスト
  const periodicLotResultCheck = () => {
    if (intervalRef.current !== null) return;

    setIsLoading(true);
    intervalRef.current = setInterval(() => {
      setIsLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      if (!requestedRef.current) lotteryCheckProcess();
    }, 30 * 1000);
  };

  // Safariで音声再生の許可用
  const audioRef = useRef<HTMLAudioElement>(null);

  // モーダル用ステート
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  // モーダル72時間以内の演出再生可能
  const [canWatchMovie, setCanWatchMovie] = useState<boolean>(true);
  const [canReceivePoints, setCanReceivePoints] = useState<boolean>(true);

  // モーダルOKクリック
  const onClickLotteryCheck = useCallback(() => {
    // Safari対策用音声再生許可
    if (audioRef.current !== null) {
      audioRef.current.play().catch((e) => { console.log(e); });
      audioRef.current.pause();
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    lotteryCheckProcess();
    // 抽選開始フラグ定期リクエスト開始
    periodicLotResultCheck();

    // モーダルを閉じる
    setIsShowModal(false);
  }, [setIsShowModal]);

  // 受け取りボタンをクリック
  const { getApiUsersMe } = useAuth();
  const { t } = useTranslation();
  const lotCheckSource = axiosCancelToken.source();
  const onReceive = () => {
    setIsLoading(true);
    axiosClient
      .post<boolean>(
        '/api/lot/check',
        {
          lot_table_id: lotTableId,
          cancelToken: lotCheckSource.token,
        },
      )
      .then(({ data }) => {
        if (data) {
          getApiUsersMe();
          toast.success(t('ポイントを受け取りました。'));

          setTimeout(() => {
            history.push({
              pathname: '/mypage',
              state: {
                initialMenu: 'history',
              },
            });
          }, 500);
        }
      })
      .catch(displayErrorMessageInToast)
      .finally(() => { setIsLoading(false); });
  };

  // 戻るボタンクリック
  const history = useHistory();
  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  // アンマウント時に全処理をクリア
  const cleanup = () => {
    // APIキャンセル
    lotResultCheckSource.cancel();
    lotResultEntrySource.cancel();
    lotResultWinnersSource.cancel();
    lotResultRankingSource.cancel();
    lotCheckSource.cancel();
    // 開始状態定期チェック停止
    stopLotResultCheck();
    // ローディング状態OFF
    setIsLoading(false);
    // 各Reacoilステート クリア
    setEntryUsers([]);
    setEarnedPoints(0);
    setEarnedPointShowFlags([]);
    setWinners([]);
    setFirstWinner(initialFirstWinner);
    setSecondWinners([]);
    setThirdWinners([]);
    setFourthWinners([]);
    setProductionEndFlag(PRODUCTION_END_FLAG.FALSE);
    setPlayBoxType(null);
    setMovieList(initialMovieList);
  };

  useEffect(() => {
    void (async () => {
      const lotResultCheck = await getApiLotResultCheck();
      setCanWatchMovie(true);
      // setCanWatchMovie(!lotResultCheck.data.video_end_flg);
      setCanReceivePoints(lotResultCheck.data.result && lotResultCheck.data.lot_boxes != null);
      setIsShowModal(true);
    })();

    return cleanup;
  }, [location]);

  return {
    isLoading,
    entryUsersScrollPagination,
    winnersScrollPagination,
    isShowModal,
    canWatchMovie,
    canReceivePoints,
    playBoxType,
    getApiEntryUsers,
    getApiWinners,
    onClickLotteryCheck,
    onReceive,
    goBack,
    audioRef,
    lotTableId,
  };
};
