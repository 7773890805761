// eslint-disable-next-line
import { FC, memo, ChangeEvent } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Component
import ArrowIcon from 'components/atoms/icon/ArrowIcon';
// Utils
import { devices } from 'utils/const/devices';
import { PLAYBOX_TYPE } from 'utils/const/playBox';
import { LANG } from 'utils/const/lang';
// Types
import { PlayBoxType } from 'types/playBoxType';

/**
 *
 * Types
 *
 */
type Props = {
  playBox: PlayBoxType;
  units: string;
  winRate: number;
  onChangeMethod: (event: ChangeEvent<HTMLInputElement>) => void;
  increment: () => void;
  decrement: () => void;
};

const { ENGLISH } = LANG;
const { BOX_100000, BOX_10000 } = PLAYBOX_TYPE;

/**
 *
 * Component
 *
 */
const UnitAndRate: FC<Props> = memo((props) => {
  const {
    playBox, units, winRate, onChangeMethod, increment, decrement,
  } = props;

  // i18next
  const { t } = useTranslation();
  // Recoil 言語状態
  const lang = useRecoilValue(langState);

  return (
    <UnitAndRateS>
      <SelectUnitS>
        <SpinnerLabelWrapS>
          <SpinnerLabelS>{t('口数選択')}</SpinnerLabelS>
        </SpinnerLabelWrapS>

        <SpinnerWrapS>
          <SpinnerInputS
            type="text"
            value={units}
            onChange={onChangeMethod}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          />
          <SpinnerButtonWrapS>
            <SpinnerButtonS type="button" onClick={increment}>
              <SpinnerArrowIconWrapS>
                <ArrowIcon rotate="-135deg" />
              </SpinnerArrowIconWrapS>
            </SpinnerButtonS>
            <SpinnerButtonS type="button" onClick={decrement}>
              <SpinnerArrowIconWrapS>
                <ArrowIcon rotate="45deg" />
              </SpinnerArrowIconWrapS>
            </SpinnerButtonS>
          </SpinnerButtonWrapS>
        </SpinnerWrapS>
      </SelectUnitS>

      {/* 確率表示部分 */}
      {lang === ENGLISH ? (
        <WinRateWrapEnS>
          <RateTextWrapS>
            {playBox.type === BOX_10000 ? (
              <RateTextS>Winning rate of diamond</RateTextS>
            ) : (
              <RateTextS>Winning rate of gold</RateTextS>
            )}
          </RateTextWrapS>
          <WinRateS>
            <WinRateTextS>
              {`${
                winRate > 0 && winRate < 100 ? winRate.toFixed(1) : winRate
              }%`}
            </WinRateTextS>
          </WinRateS>
        </WinRateWrapEnS>
      ) : (
        <WinRateWrapS>
          <RateTextWrapS>
            {playBox.type === BOX_100000 ? (
              <RateTextS>{t('キング')}</RateTextS>
            ) : playBox.type === BOX_10000 ? (
              <RateTextS>{t('ダイヤモンド')}</RateTextS>
            ) : (
              <RateTextS>{t('ゴールド')}</RateTextS>
            )}
            <RateTextS>{t('獲得確率')}</RateTextS>
          </RateTextWrapS>
          <WinRateS>
            <WinRateTextS>
              {`${
                winRate > 0 && winRate < 100 ? winRate.toFixed(1) : winRate
              }%`}
            </WinRateTextS>
          </WinRateS>
        </WinRateWrapS>
      )}
    </UnitAndRateS>
  );
});
export default UnitAndRate;

/**
 *
 * Styles
 *
 */
const UnitAndRateS = styled.div`
  margin-bottom: 3rem;
  display: flex;
  column-gap: 2rem;

  @media ${devices.tabletLandscapeAndPc} {
    margin-bottom: 2rem;
    column-gap: 2rem;
  }
`;

const TextS = styled.p`
  font-size: 1.4rem;
  word-break: break-word;
  line-height: 1.6;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }

  @media ${devices.tabletLandscapeAndPc} {
    font-size: 1.8rem;
  }
`;

const SelectUnitS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 0.4rem;

  @media ${devices.tabletLandscapeAndPc} {
    width: 50%;
    align-items: center;
  }
`;

const SpinnerLabelWrapS = styled.div`
  width: 100%;
`;

const SpinnerLabelS = styled(TextS)`
  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }
`;

const SpinnerWrapS = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  @media ${devices.tabletLandscapeAndPc} {
    justify-content: flex-end;
  }
`;

const SpinnerInputS = styled.input`
  width: 100%;
  height: 100%;
  background-color: #222222;
  border: 1px solid #ff00e2;
  border-radius: 4px;
  padding: 1rem;
  font-size: 2.4rem;
  text-align: end;
  flex: 1;

  @media (min-width: 600px) {
    font-size: 2rem;
  }

  @media (min-width: 1025px) {
    padding: 2rem 1rem;
    font-size: 2rem;
  }
`;

const SpinnerButtonWrapS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1rem;
  row-gap: 1rem;
`;

const SpinnerButtonS = styled.button`
  height: min(12vw, 42px);
  width: min(12vw, 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222222;
  border: 1px solid #888888;
  border-radius: 3px;

  @media (min-width: 600px) {
    height: 40px;
    width: 40px;
  }

  @media ${devices.tabletLandscapeAndPc} {
    height: 30px;
    width: 30px;
  }
`;

const SpinnerArrowIconWrapS = styled.div`
  height: 1.2rem;
  width: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WinRateWrapS = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.tabletLandscapeAndPc} {
    width: 50%;
    margin-top: 0;
    flex-direction: unset;
  }
`;

const WinRateWrapEnS = styled.div`
  width: 35%;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    width: 30%;
  }

  @media ${devices.tabletLandscapeAndPc} {
    width: 45%;
    margin-top: 0;
    flex-direction: unset;
    column-gap: 1rem;
  }
`;

const RateTextWrapS = styled.div`
  width: 100%;
  text-align: end;
`;

const RateTextS = styled(TextS)`
  @media (min-width: 600px) {
    font-size: 1.4rem;
  }
`;

const WinRateS = styled.div`
  width: 100%;
  text-align: end;
`;

const WinRateTextS = styled(TextS)`
  font-size: 3rem;
  color: #fff240;
  font-weight: bold;
`;
