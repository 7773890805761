import { FC, memo, ReactNode } from 'react';
// External library
import styled from 'styled-components';
// Utils
import { devices } from 'utils/const/devices';
// Images
import neonBarTopSp from 'assets/images/neonItem/bar-tiplist-top_sp.png';
import neonBarBottomSp from 'assets/images/neonItem/bar-tiplist-bottom_sp.png';
import neonBoxPc from 'assets/images/neonItem/box_pc.png';
import neonBoxPc2x from 'assets/images/neonItem/box_pc@2x.png';

/**
 * Types
 */
type Props = {
  pinkText: string;
  headText: string;
  children: ReactNode;
};

/**
 *
 * Component
 *
 */
// 仕組みページ ポイント用コンポーネント
const PointBox: FC<Props> = memo((props) => {
  const { pinkText, headText, children } = props;

  return (
    <PointBoxS>
      <NeonBarS>
        <NeonBarImageS src={neonBarTopSp} alt="" loading="lazy" />
      </NeonBarS>
      <BoxS>
        <BoxContentS>
          <PinkTextS>{pinkText}</PinkTextS>
          <HeadTextS>{headText}</HeadTextS>
          {children}
        </BoxContentS>
      </BoxS>
      <NeonBarS>
        <NeonBarImageS src={neonBarBottomSp} alt="" loading="lazy" />
      </NeonBarS>
    </PointBoxS>
  );
});
export default PointBox;

/**
 *
 * Styles
 *
 */
const { tabletPortrait, tabletLandscape, pc } = devices;

const PointBoxS = styled.div`
  width: 100%;
  color: #ffffff;

  @media ${tabletLandscape}, ${pc} {
    border-width: 5px;
    border-style: solid;
    border-image: url(${neonBoxPc}) 5 5 5 5 fill stretch;

    @media (min-resolution: 2dppx) {
      border-image: url(${neonBoxPc2x}) 5 5 5 5 fill stretch;
    }
  }
`;

const NeonBarS = styled.div`
  width: 100%;
  display: block;

  @media ${tabletLandscape}, ${pc} {
    display: none;
  }
`;

const NeonBarImageS = styled.img`
  width: 100%;
`;

const BoxS = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding: 2rem;

  @media ${tabletPortrait} {
    min-height: 235px;
    border-left: 0.1px solid rgba(220, 220, 220, 0.3);
    border-right: 0.1px solid rgba(220, 220, 220, 0.3);
    padding: 2rem 5rem;
  }

  @media ${tabletLandscape}, ${pc} {
    background: none;
    border-left: none;
    border-right: none;
    padding: 5rem 7rem;
  }
`;

const BoxContentS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    row-gap: 3rem;
  }
`;

const PinkTextS = styled.p`
  font-size: 2.2rem;
  color: #ff00e2;
  font-weight: bold;
  transform: skewX(-15deg);
  word-break: break-word;
  line-height: 1.6rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    font-size: 2.8rem;
  }
`;

const HeadTextS = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  word-break: break-word;
  line-height: 1.6rem;

  @media ${tabletLandscape}, ${pc} {
    font-size: 1.8rem;
  }
`;
