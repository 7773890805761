// eslint-disable-next-line
import { FC, memo, RefObject, useEffect } from 'react';
// External library
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Hooks
import { usePlayBoxWrap } from 'hooks/organisms/playBox/usePlayBoxWrap';
import { useWindowDimensions } from 'hooks/versatile/useWindowDimensions';
// Components
import ArrowIcon from 'components/atoms/icon/ArrowIcon';
import CrossIcon from 'components/atoms/icon/CrossIcon';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
import TableReserve from 'components/organisms/top/playBox/TableReserve';
import UnitAndRate from 'components/organisms/top/playBox/UnitAndRate';
import TablePrize from 'components/organisms/top/playBox/TablePrize';
import MultiplePurchaseButton from 'components/organisms/top/playBox/MultiplePurchaseButton';
// Recoil
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { playBoxListState } from 'store/atoms/playBoxListState';
// Images
import headerBgSp from 'assets/images/top/playBox/header_sp.png';
import headerBgPc from 'assets/images/top/playBox/bg-header_pc.jpg';
import headerBgPc2x from 'assets/images/top/playBox/bg-header_pc@2x.jpg';
import neonBarTopPc from 'assets/images/neonItem/bar-top_pc.png';
import neonBarBottomPc from 'assets/images/neonItem/bar-bottom_pc.png';
// Utils
import { devices } from 'utils/const/devices';
// Types
import { PlayBoxId, PlayBox } from 'types/playBoxType';
import { MAX_PURCHASE_UNIT_ONCE } from '../../../../utils/const/playBox';

/**
 *
 * Types
 *
 */
type Props = {
  type: PlayBox;
  id: PlayBoxId;
  isShow: boolean;
  icon: string;
  _ref: RefObject<HTMLInputElement>;
  onClickMethod: (ref: RefObject<HTMLInputElement>, isScroll: boolean) => void;
};

/**
 *
 * Component
 *
 */
const PlayBoxWrap: FC<Props> = memo((props) => {
  // hooks
  const { t } = useTranslation();
  const { windowDimensions } = useWindowDimensions();
  // Recoil
  // ユーザー
  const user = useRecoilValue(userState);
  // playboxlist
  const playBoxList = useRecoilValue(playBoxListState);

  const {
    type, id, isShow, icon, _ref, onClickMethod,
  } = props;

  // RecoilからplayBox抽出
  const playBox = playBoxList[type];

  // PLAYBOX用カスタムフック
  const {
    units,
    winRate,
    isPurchase,
    onChangeInput,
    increment,
    decrement,
    bulkIncrement,
    pushToConfirm,
    unitReset,
    cleanUpMethod,
  } = usePlayBoxWrap(type);

  useEffect(() => cleanUpMethod, []);

  return (
    <PlayBoxS id={id} ref={_ref}>

      { playBox.public_flg && (
        <FlashNeonBarWrapS isTableShow={isShow}>
          <NeonBarImageS src={neonBarTopPc} alt="" />
        </FlashNeonBarWrapS>
      )}

      { !playBox.public_flg && (
        <NeonBarWrapS>
          <NeonBarImageS src={neonBarTopPc} alt="" />
        </NeonBarWrapS>
      )}

      { !playBox.public_flg && (
        <ComingSoonS>
          <ComingSoonTextS>Coming Soon.</ComingSoonTextS>
        </ComingSoonS>
      )}

      {/* ヘッダー */}
      {/* タブレット以下のウィンドウサイズでのみスクロール */}
      <AccordionButtonS
        type="button"
        onClick={() => {
          if (playBox.public_flg) {
            onClickMethod(_ref, windowDimensions.width < 1025);
          }
        }}
        enabled={playBox.public_flg}
      >
        <HeaderS>
          <HeaderLogoWrapS>
            <HeaderLogoS src={icon} alt="" />
          </HeaderLogoWrapS>
          <HeaderButtonWrapS>
            <HeaderButtonS>
              {isShow ? <CrossIcon /> : <ArrowIcon rotate="45deg" />}
            </HeaderButtonS>
          </HeaderButtonWrapS>
        </HeaderS>
      </AccordionButtonS>

      {/* 展開後表示コンテンツ */}
      <BodyS className={`${isShow ? ' show' : ''}`}>
        <ContentS>
          {/* 予約状況 */}
          <TableReserve playBox={playBox} />

          <PurchaseS>
            <UserPurchaseS>
              <InputBlockS>
                {Number(units) > 0 && !isPurchase && (
                  <MessageWrapS>
                    <ErrorMessage>
                      {!user.loggedIn
                        ? t('購入するにはログインが必要です。')
                        : t('所持ポイントが不足しています。')}
                    </ErrorMessage>
                  </MessageWrapS>
                )}

                {Number(units) >= MAX_PURCHASE_UNIT_ONCE && (
                  <MessageWrapS>
                    <ErrorMessage>
                      {t('1度に購入できるのは2000口までです')}
                    </ErrorMessage>
                  </MessageWrapS>
                )}

                {/* 購入口数選択 */}
                <UnitAndRate
                  playBox={playBox}
                  units={units}
                  winRate={winRate}
                  onChangeMethod={onChangeInput}
                  increment={increment}
                  decrement={decrement}
                />

                {/* ボタングループ */}
                <ButtonGroupS>
                  <BuyButtonS
                    type="button"
                    onClick={pushToConfirm}
                    isPurchase={isPurchase}
                    disabled={!isPurchase}
                  >
                    {t('購入する')}
                  </BuyButtonS>
                  <CancelButtonS type="button" onClick={unitReset}>
                    {t('キャンセル')}
                  </CancelButtonS>
                </ButtonGroupS>
              </InputBlockS>

              {/* 獲得ポイント数 */}
              <TablePrizeWrapS>
                <TablePrize playBox={playBox} />
              </TablePrizeWrapS>
            </UserPurchaseS>

            {/* 複数口数購入ボタン */}
            <MultiplePurchaseButton
              playBox={playBox}
              bulkIncrement={bulkIncrement}
            />
          </PurchaseS>
        </ContentS>
      </BodyS>

      { playBox.public_flg && (
        <FlashNeonBarWrapS isTableShow={isShow}>
          <NeonBarImageS src={neonBarBottomPc} alt="" />
        </FlashNeonBarWrapS>
      )}

      { !playBox.public_flg && (
        <NeonBarWrapS>
          <NeonBarImageS src={neonBarBottomPc} alt="" />
        </NeonBarWrapS>
      )}

    </PlayBoxS>
  );
});
export default PlayBoxWrap;

/**
 *
 * Styles
 *
 */
const {
  tabletPortrait, tabletLandscape, pc,
} = devices;

const PlayBoxS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FlashNeonBarWrapS = styled.picture<{ isTableShow: boolean }>`
  width: 100%;

  @keyframes fadeIn {
    0%,
    100% {
      opacity: 1;
    }
    60% {
      opacity: 0.1;
    }
  }

  animation: ${({ isTableShow }) => (isTableShow ? 'none' : 'fadeIn 2s cubic-bezier(0.3, 0, 0.1, 1) infinite')};
`;

const NeonBarImageS = styled.img`
  height: 10px;
  width: 100%;

  @media ${tabletLandscape}, ${pc} {
    height: 15px;
  }
`;

const AccordionButtonS = styled.button<{ enabled: boolean }>`
  width: 100%;
  opacity: ${({ enabled }) => `${enabled ? 1.0 : 0.5}`};
`;

const HeaderS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 9rem;
  background-image: url(${headerBgSp});
  background-position: center;
  border: 1px solid #707070;
  border-bottom: none;
  position: relative;

  @media (min-width: 600px) {
    min-height: 9rem;
    background-image: image-set(url(${headerBgPc}) 1x, url(${headerBgPc2x}) 2x);
    border: 1px solid #707070;
    border-radius: 8px;
  }

  @media ${tabletLandscape}, ${pc} {
    min-height: 11rem;
  }
`;

const HeaderLogoWrapS = styled.div`
  width: min(30%, 95px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @media (min-width: 600px) {
    width: 20%;
    display: block;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 16%;
  }
`;

const HeaderLogoS = styled.img`
  width: 100%;

  @media ${tabletPortrait} {
    width: 100%;
    margin-top: -7%;
  }

  @media ${tabletLandscape}, ${pc} {
    margin-top: -16%;
  }
`;

const HeaderButtonWrapS = styled.div`
  position: absolute;
  right: 0;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 10%;
  }
`;

const HeaderButtonS = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${tabletLandscape}, ${pc} {
    width: 3rem;
    height: 3rem;
  }
`;

const BodyS = styled.div`
  transition: all 0.3s ease;
  width: 100%;
  max-height: 0;
  color: #ffffff;
  overflow-y: hidden;
  margin-top: 0.3rem;

  &.show {
    transition: all 0.5s ease;
    max-height: 1000vh;
  }
`;

const ContentS = styled.div`
  display: flex;
  flex-direction: column;

  @media ${tabletLandscape}, ${pc} {
    flex-direction: unset;
  }
`;

const PurchaseS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const UserPurchaseS = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid #ffffff;
  border-top: none;
  border-bottom: none;

  @media ${tabletPortrait} {
    padding: 2rem 1rem 2rem 2rem;
    flex-direction: row;
    column-gap: 1rem;
  }

  @media ${tabletLandscape}, ${pc} {
    padding: 2rem 1rem 2rem 2rem;
    border-top: 1px solid #ffffff;
    flex-direction: row;
    column-gap: 1.4rem;
  }
`;

const InputBlockS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  @media ${tabletPortrait} {
    width: 50%;
    padding: 0;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 50%;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`;

const MessageWrapS = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1.6rem;

  @media (min-width: 600px) {
    font-size: 1.4rem;
  }

  @media ${tabletLandscape}, ${pc} {
    margin-bottom: 2rem;
  }
`;

const ButtonGroupS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;

  @media ${tabletLandscape}, ${pc} {
    flex-direction: unset;
    justify-content: space-between;
  }
`;

const ButtonS = styled.button`
  width: 100%;
  border: 2px solid #ffffff;
  border-radius: 10px;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  text-align: center;

  @media (min-width: 600px) {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    padding: 1rem;
  }
`;

const BuyButtonS = styled(ButtonS)<{ isPurchase: boolean }>`
  background-color: #aa0463;
  opacity: ${({ isPurchase }) => (isPurchase ? 1 : 0.5)};

  @media ${tabletLandscape}, ${pc} {
    width: 55%;
  }
`;

const CancelButtonS = styled(ButtonS)`
  background-color: #300b8e;

  @media ${tabletLandscape}, ${pc} {
    width: 40%;
  }
`;

const TablePrizeWrapS = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media ${tabletPortrait}, ${tabletLandscape}, ${pc} {
    width: 50%;
    margin-bottom: 0;
  }
`;

const NeonBarWrapS = styled.picture`
  width: 100%;
`;

const ComingSoonS = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1;
`;

const ComingSoonTextS = styled.p`
  color: #ffffff;
  font-size: 3rem;
  font-style: italic;

  @media (min-width: 600px) {
    font-size: 5rem;
  }
`;
