import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Utils
import { LANG } from 'utils/const/lang';
// Images
import depositButtonJa from 'assets/images/mypage/ja/button_deposit.svg';
import depositButtonEn from 'assets/images/mypage/en/button_deposit.svg';
import depositButtonZhCn from 'assets/images/mypage/zhCn/button_deposit.svg';
import depositButtonZhTw from 'assets/images/mypage/zhTw/button_deposit.svg';
import depositButtonKo from 'assets/images/mypage/ko/button_deposit.svg';
import depositButtonVi from 'assets/images/mypage/vi/button_deposit.svg';
import depositButtonTh from 'assets/images/mypage/th/button_deposit.svg';

/**
 *
 * component
 *
 */
const {
  JAPANESE, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN, VIETNAM, THAILAND,
} = LANG;

const DepositButton: FC = memo(() => {
  const lang = useRecoilValue(langState);

  return (
    <DepositButtonS
      src={
        lang === JAPANESE
          ? depositButtonJa
          : lang === SIMPLE_CHINESE
            ? depositButtonZhCn
            : lang === TRADITIONAL_CHINESE
              ? depositButtonZhTw
              : lang === KOREAN
                ? depositButtonKo
                : lang === VIETNAM
                  ? depositButtonVi
                  : lang === THAILAND
                    ? depositButtonTh
                    : depositButtonEn
      }
      alt=""
    />
  );
});
export default DepositButton;

/**
 *
 * Styles
 *
 */
const DepositButtonS = styled.img`
  width: 100%;
`;
