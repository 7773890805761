import { FC, useEffect, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Components
import BreadCrumbs from 'components/atoms/breadCrumbs/BreadCrumbs';
import UserInformationSp from 'components/organisms/mypage/UserInformationSp';
import RegisterInformation from 'components/organisms/mypage/RegisterInformation';
import Kyc from 'components/organisms/mypage/Kyc';
import Deposit from 'components/organisms/mypage/Deposit';
import History from 'components/organisms/mypage/History';
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import NeonShadowBorder from 'components/atoms/border/NeonShadowBorder';
// Custom hooks
import { useMypage } from 'hooks/pages/private/useMypage';
// Utils
import { myPageBreadCrumbItems } from 'utils/breadCrumbs';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/mypage_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/mypage_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const MyPage: FC = memo(() => {
  // i18next
  const { t } = useTranslation();
  // Recoil言語
  const lang = useRecoilValue(langState);

  // マイページ用カスタムフック
  const {
    isLoading,
    // 出金制御
    kycFlag,
    remainingTickets,
    getApiUsersWithdrawal,
    goToWithdrawalTopPage,
    // メニュー
    initialMenu,
    isMenuActive,
    setIsMenuActive,
    menuList,
    scrollIntoMenuView,
    // ポイント・デポジット
    depositList,
    // ポイント購入履歴
    getApiPointLogGet,
    // 出金履歴
    getApiPointLogExchange,
    // べット履歴
    getApiLotBets,
    // 当選履歴
    getApiLotWinHistory,
    historyList,
    // ログアウト
    getApiLogout,
    // 退会
    goToDeleteAccountConfirm,
    // クリーンアップ処理
    cleanup,
  } = useMypage();

  useEffect(() => {
    getApiUsersWithdrawal();
    setIsMenuActive(initialMenu);
    scrollIntoMenuView(initialMenu);

    // 履歴取得
    getApiPointLogGet();
    getApiPointLogExchange();
    getApiLotBets();
    getApiLotWinHistory();

    window.scrollTo({
      top: 0,
    });

    return cleanup;
  }, [initialMenu, lang]);

  return (
    <>
      <Helmet>
        <title>{t('title.mypage')}</title>
        <meta name="description" content={t('description.mypage')} />
      </Helmet>
      <MypageS>
        {/* ユーザー情報(SP用) */}
        {false && (
          <UserInformationSp
            kycFlag={kycFlag}
            ticket={remainingTickets}
            goToPage={goToWithdrawalTopPage}
          />
        )}

        <ContainerS>
          {/* パンくずリスト(PC用) */}
          <BreadCrumbsWrapS>
            <BreadCrumbs crumbItems={myPageBreadCrumbItems} />
          </BreadCrumbsWrapS>

          {/* メニュー */}
          <MenuS>
            {/* ローディングアニメーション */}
            {isLoading && <LoaderWindow />}
            {/* メニューヘッダー */}
            {false && (
              <HeaderS>
                {menuList.map((menu) => (
                  <HeaderListS
                    key={uuidv4()}
                    ref={menu.ref}
                    isActive={menu.isActive}
                    onClick={menu.onClick}
                  >
                    {t(menu.label)}
                  </HeaderListS>
                ))}
              </HeaderS>
            )}

            {/* メニューコンテンツ */}
            <ContentS isHistory={isMenuActive === 'history'}>
              {/* 登録情報 */}
              <ContentListS isActive={isMenuActive === 'user'}>
                <RegisterInformation
                  kycFlag={kycFlag}
                  ticket={remainingTickets}
                  goToPage={goToWithdrawalTopPage}
                />
              </ContentListS>

              {/* KYC提出 */}
              <ContentListS isActive={isMenuActive === 'kyc'}>
                <Kyc />
              </ContentListS>

              {/* ポイントデポジット 出金・交換 */}
              <ContentListS isActive={isMenuActive === 'point'}>
                <Deposit
                  kycFlag={kycFlag}
                  ticket={remainingTickets}
                  goToPage={goToWithdrawalTopPage}
                >
                  {depositList.map((item) => (
                    <HistoryListS key={uuidv4()}>
                      <History
                        kind={item.kind}
                        isLoading={item.isLoading}
                        listType="deposit"
                        title={item.title}
                        history={item.history}
                        pagination={item.pagination}
                        getApiMethod={item.getApiMethod}
                        isEmpty={item.isEmpty}
                        errorMessage={item.errorMessage}
                      />
                    </HistoryListS>
                  ))}
                </Deposit>
              </ContentListS>

              {/* 履歴 */}
              <ContentListS isActive={isMenuActive === 'history'}>
                {/* 履歴リスト */}
                {historyList.map((item) => (
                  <HistoryListS key={uuidv4()}>
                    <History
                      kind={item.kind}
                      isLoading={item.isLoading}
                      listType="history"
                      title={item.title}
                      history={item.history}
                      pagination={item.pagination}
                      getApiMethod={item.getApiMethod}
                      isShowHistory={item.isShowHistory}
                      onClickShowHistory={item.onClickShowHistory}
                      isEmpty={item.isEmpty}
                      errorMessage={item.errorMessage}
                    />
                  </HistoryListS>
                ))}
              </ContentListS>
            </ContentS>
          </MenuS>

          {false && (
            <AccountS>
              {/* ログアウト */}
              <BlockS>
                <TitleS>{t('ログアウト')}</TitleS>
                <BorderWrapS>
                  <NeonShadowBorder />
                </BorderWrapS>
                <RoundButtonWrapS>
                  {isLoading ? (
                    <RoundButton
                      background="#555555"
                      borderColor="rgba(230, 230, 230, 1)"
                    >
                      {t('ログアウト')}
                    </RoundButton>
                  ) : (
                    <RoundButton
                      onClick={getApiLogout}
                      background="linear-gradient(rgba(0,71,106,1), 90%, rgba(0,150,110,0.9))"
                      borderColor="rgba(0,150,110,1)"
                    >
                      {t('ログアウト')}
                    </RoundButton>
                  )}
                </RoundButtonWrapS>
              </BlockS>

              {/* アカウントの停止 */}
              <BlockS>
                <TitleS>{t('アカウントの停止')}</TitleS>
                <BorderWrapS>
                  <NeonShadowBorder />
                </BorderWrapS>
                <RoundButtonWrapS>
                  <RoundButton
                    onClick={goToDeleteAccountConfirm}
                    background="linear-gradient(rgba(0,71,106,1), 90%, rgba(0,150,110,0.9))"
                    borderColor="rgba(0,150,110,1)"
                  >
                    {t('停止')}
                  </RoundButton>
                </RoundButtonWrapS>
              </BlockS>
            </AccountS>
          )}
        </ContainerS>
      </MypageS>
    </>
  );
});
export default MyPage;

/**
 *
 * Styles
 *
 */
const MypageS = styled.main`
  width: 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const ContainerS = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 6rem;
  background-image: url(${backGroundSp});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-top: 6rem;

  @media (min-width: 600px) {
    padding-top: 6rem;
  }

  @media (min-width: 1025px) {
    min-height: 740px;
    padding-top: 2rem;
    // background
    background-image: image-set(
      url(${backGroundPc}) 1x,
      url(${backGroundPc2x}) 2x
    );
    background-position-y: top;
    background-position-x: center;
    background-size: auto;
  }
`;

const BreadCrumbsWrapS = styled.div`
  display: none;

  @media (min-width: 1025px) {
    display: block;
    width: 100%;
    max-width: 100rem;
    margin: 0 auto 3rem;
  }
`;

const MenuS = styled.div`
  width: 95%;
  margin: 0 auto 3rem;

  @media (min-width: 600px) {
    width: 65%;
  }

  @media (min-width: 1025px) {
    width: 100%;
    margin-bottom: 5rem;
  }
`;

const HeaderS = styled.ul`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  justify-content: space-between;

  // Firefox
  scrollbar-width: none;
  // IE, Edge
  -ms-overflow-style: none;
  // Chrome Safari
  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1025px) {
    max-width: 100rem;
    margin: 0 auto;
    overflow: unset;
  }
`;

const HeaderListS = styled.li<{ isActive: boolean }>`
  width: calc(100% / 4);
  padding: 1.4rem 1rem 1rem;
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;
  // active
  color: ${({ isActive }) => (isActive ? '#ff66f1' : '#ffffff')};
  border-bottom: 3px solid
    ${({ isActive }) => (isActive ? '#ff66f1' : 'transparent')};

  @media (min-width: 600px) {
    padding: 1rem;
    font-size: 1.4rem;
  }

  @media (min-width: 1025px) {
    font-size: 1.6rem;
    white-space: pre;
    padding: 1rem;
    border-bottom: 5px solid
      ${({ isActive }) => (isActive ? '#ff66f1' : 'transparent')};
  }

  &:hover {
    @media (min-width: 1025px) {
      opacity: 0.7;
    }
  }
`;

const ContentS = styled.ul<{ isHistory: boolean }>`
  width: 100%;
  margin: 0 auto;
  background-color: rgba(80, 100, 200, 0.3);

  @media (min-width: 1025px) {
    max-width: 100rem;
    background-color: ${({ isHistory }) => (isHistory ? 'rgba(80, 80, 80, 0.2)' : 'rgba(80, 80, 80, 0.4)')};
  }
`;

const ContentListS = styled.li<{ isActive: boolean }>`
  width: 100%;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  padding: 3rem 1rem;

  @media (min-width: 600px) {
    padding: 2rem 3rem 3rem;
  }

  @media (min-width: 1025px) {
    padding: 5rem;
  }
`;

const HistoryListS = styled.div`
  margin-bottom: 5rem;

  @media (min-width: 1025px) {
    margin-bottom: 7rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const AccountS = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 6rem;

  @media (min-width: 600px) {
    width: 65%;
    padding: 3rem;
    background-color: rgba(80, 80, 80, 0.4);
    flex-direction: row;
    gap: 5rem;
  }

  @media (min-width: 1025px) {
    max-width: 100rem;
    width: 100%;
    padding: 5rem;
  }
`;

const BlockS = styled.div`
  width: 100%;
`;

const TitleS = styled.div`
  font-size: 1.6rem;
  padding-left: 0.5rem;
  margin-bottom: 1rem;

  @media (min-width: 1025px) {
    font-size: 1.8rem;
  }
`;

const BorderWrapS = styled.div`
  margin-bottom: 4rem;

  @media (min-width: 1025px) {
    margin-bottom: 4rem;
  }
`;

const RoundButtonWrapS = styled.div`
  width: 50%;
  margin: 0 auto;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 70%;
  }

  @media (min-width: 1025px) {
    width: 45%;
  }
`;
