import { atom } from 'recoil';
import { ATOM_KEYS } from 'utils/const/recoilKeys';
// Types
import { MovieList } from 'types/lottery/movieList';
import { WINNERS_RANK_UP_TO_FIFTH } from '../../../utils/const/lottery/winners';

export const initialMovieList = {
  ZEUS_MOVIE_URL: '',
  KING_MOVIE_URL: '',
  DIAMOND_MOVIE_URL: '',
  GOLD_MOVIE_URL: '',
  SILVER_MOVIE_URL: '',
  BRONZE_MOVIE_URL: '',
  OTHER_MOVIE_URL: '',
};

export const initialRankResult = WINNERS_RANK_UP_TO_FIFTH.FIFTH;

export const movieList = atom<MovieList>({
  key: ATOM_KEYS.MOVIE_LIST,
  default: initialMovieList,
});

export const currentRankResultState = atom<string>({
  key: ATOM_KEYS.CURRENT_RANK_RESULT,
  default: initialRankResult,
});
