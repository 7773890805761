import { atom } from 'recoil';
import { ATOM_KEYS } from 'utils/const/recoilKeys';
// Types
import { Winner } from 'types/lottery/winner';

export const initialFirstWinner = {
  betId: 0,
  userId: 0,
  name: '',
  rank: '',
  point: 0,
  isShow: false,
  specialFlg: 0,
};

export const firstWinnerState = atom<Winner|null>({
  key: ATOM_KEYS.FIRST_WINNER_STATE,
  default: initialFirstWinner,
});
