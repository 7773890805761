import { useState } from 'react';
// External library
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
// Hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType, LotUnlotteryType } from 'types/api';

/**
 *
 * Types
 *
 */
type LocationState = {
  referrer: string;
  boxTitle: string;
  ticketNumbers: number[];
};

/**
 *
 * 購入完了ページ用ロジック
 *
 */
export const useTicketPurchaseFinish = (): {
  referrer: string;
  boxTitle: string;
  ticketNumbers: number[];
  unlotteryBoxes: LotUnlotteryType[];
  isLoading: boolean;
  errorMessage: string;
  getApiLotUnlottery: () => void;
  // クリーンアップ関数
  cleanUpMethod: () => void;
} => {
  // 購入確認画面から受け取ったステート
  const location = useLocation<LocationState | undefined>();
  const alternativeLocationState: LocationState = {
    referrer: '',
    boxTitle: '',
    ticketNumbers: [0],
  };
  const { referrer, boxTitle, ticketNumbers } = location.state ?? alternativeLocationState;

  // エラー用カスタムフック
  const { errorHandler } = useError();
  // ステート
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [unlotteryBoxes, setUnlotteryBoxes] = useState<LotUnlotteryType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // APIキャンセルトークン
  const axiosLotUnlotterySource = axiosCancelToken.source();
  // 未抽選BOX情報取得リクエスト
  const getApiLotUnlottery = () => {
    if (!isLoading) {
      setIsLoading(true);

      // axios GET /api/lot/unlottery
      axiosClient
        .get<LotUnlotteryType[]>('api/lot/unlottery', {
          cancelToken: axiosLotUnlotterySource.token,
        })
        .then(({ data }) => {
          if (data.length !== 0) {
            setUnlotteryBoxes(data);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const cleanUpMethod = () => {
    // APIリクエストキャンセル
    axiosLotUnlotterySource.cancel();
    // ステート
    setUnlotteryBoxes([]);
    setErrorMessage('');
    setIsLoading(false);
  };

  return {
    referrer,
    boxTitle,
    ticketNumbers,
    unlotteryBoxes,
    isLoading,
    errorMessage,
    getApiLotUnlottery,
    cleanUpMethod,
  };
};
