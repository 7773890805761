// External library
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// 責任のあるギャンブルページ用ロジック
export const useResponsible = (): {
  protectionTexts: string[];
  serviceTexts: string[];
  hintTexts: string[];
  infoTexts: string[];
  questionTexts: string[];
  periodTexts: string[];
  goBack: () => void;
} => {
  // i18next
  const { t } = useTranslation();

  const protectionTexts = [
    t('自分のメールアドレスを安全に保つ。'),
    t(
      "自分のDayLott'sアカウントにログインしたときにコンピューターを無人で放置しない。",
    ),
    t('18歳未満の人がいかなるギャンブル活動への参加も許可しない。'),
    t(
      '自分のクレジット/デビットカードおよびその他の金融口座の詳細を、子供の手の届かないところに保管する。',
    ),
    t(
      'パスワードをコンピューターに保存しない。パスワードを書き留め、未成年者から離れた安全な場所に保管する。',
    ),
    t(
      "共有コンピューターからDayLott'sにアクセスする場合、特にコンピューターを子供と共有する場合は、「記憶する」機能を使用しない。",
    ),
    t(
      '未成年のギャンブルによって引き起こされる違法性と潜在的な損害について、自分の子供たちに教育する。',
    ),
    t(
      '未成年者によるすべての電子機器ならびにモバイルデバイスの使用には特に注意する。',
    ),
    t(
      '自分の子供がギャンブルサイトを含む不適切な題材にアクセスするのを防ぐために、フィルタリングソフトウェアを活用する。',
    ),
  ];

  const serviceTexts = [
    t(
      'Netnanny.com - 不適切なウェブコンテンツから子供を守るフィルタリングソフトウェア。',
    ),
    t(
      'Cybersitter.com - 保護者がブロックするサイトを自身で追加することができるフィルタリングソフトウェア。',
    ),
    t(
      'betterinternetforkids.eu - EUが共同出資し、インターネットフィルタソフトウェアに関する情報を提供し、無料のテスト版を提供するウェブサイト。',
    ),
  ];

  const hintTexts = [
    t('毎日の責務を妨げる場合。'),
    t('依存症の治療をしている、あるいは回復している場合。'),
    t('アルコールやその他の物質の影響を受けている場合。'),
    t(
      '以前の損失を取り戻そうとしている場合。あるいは賞金で借金を回収することが主な目的となっている場合。',
    ),
  ];

  const infoTexts = [
    t('お客様のニックネーム/電話番号'),
    t('お客様の顧客アカウントに登録済みの氏名'),
    t('苦情/申し立ての詳細'),
    t('苦情/申し立てに関連する特定の日付と時刻 (該当する場合)'),
  ];

  const questionTexts = [
    t('つまらない日常や不幸な人生を忘れるためにギャンブルをしますか？'),
    t(
      'ギャンブルをしていてお金が足りなくなったら、喪失感を味わい絶望を感じ、できるだけ早く再びギャンブルをする必要がありますか？',
    ),
    t(
      '最後の1円がなくなるまでギャンブルをしますか？それが、たとえば家への交通費や生活費だとしてもですか？',
    ),
    t('ギャンブルに費やした金額や時間の嘘をついてごまかしたことがありますか？'),
    t('ギャンブルが原因で家族、友人、趣味への興味を失ったことがありますか？'),
    t(
      '負けた後、できるだけ早くもう一度挑戦して損失を勝たなければならないと感じますか？',
    ),
    t('議論、不満、失望によりギャンブルをしたくなりますか？'),
    t(
      'ギャンブルをすることが原因で落ち込む、あるいは自分自身に害を及ぼすと感じることがありますか？',
    ),
  ];

  const periodTexts = [
    t('1週間'),
    t('2週間'),
    t('1ヶ月'),
    t('3ヶ月'),
    t('6ヶ月'),
  ];

  // historyオブジェクト
  const history = useHistory();
  // history.back
  const goBack = () => {
    history.goBack();
  };

  return {
    protectionTexts,
    serviceTexts,
    hintTexts,
    infoTexts,
    questionTexts,
    periodTexts,
    goBack,
  };
};
