import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Images
import arrowSp from 'assets/images/how/arow_sp.png';
import arrowPc from 'assets/images/how/arow_pc.png';
import arrowPc2x from 'assets/images/how/arow_pc@2x.png';

/**
 *
 * Component
 *
 */
const GoldArrowIcon: FC = memo(() => (
  <PictureS>
    <source
      srcSet={`${arrowPc} 1x,${arrowPc2x} 2x`}
      media="(min-width: 1025px)"
    />
    <ImageS src={arrowSp} alt="" loading="lazy" />
  </PictureS>
));
export default GoldArrowIcon;

/**
 *
 * Styles
 *
 */
const PictureS = styled.picture`
  width: 100%;
`;

const ImageS = styled.img`
  width: 100%;
`;
