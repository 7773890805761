import { useState } from 'react';
// External library
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
import { EVENT_ENABLED, EVENT_NEWS } from 'utils/const/event';
// Types
import { ApiErrorType, NewsUsersType } from 'types/api';

/**
 *
 * ユーザーお知らせ詳細ページ用ロジック
 *
 */
type NewsUsers = Omit<
  NewsUsersType,
  | 'kind'
  | 'user_id'
  | 'lot_box_id'
  | 'lot_table_id'
  | 'lottery_flg'
  | 'status'
  | 'created_at'
  | 'updated_at'
  | 'deleted_at'
>;

export const useNewsUsersShow = (): {
  isLoading: boolean;
  newsUsers: NewsUsers;
  canWatchLottery: boolean;
  lotteryFlg: boolean;
  movieStatus: number | undefined | null;
  getApiNewsUsersFind: () => void;
  emptyMessage: string;
  errorMessage: string;
  // クリーンアップ関数
  cleanUpMethod: () => void;
} => {
  // お知らせが無い時のメッセージ
  const [emptyMessage, setEmptyMessage] = useState<string>('');

  // エラー用カスタムフック
  const { errorHandler } = useError();

  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');

  // ローディング状態
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // i18-next
  const { t } = useTranslation();

  // 動画演出ページ閲覧不可能
  const [canWatchLottery, setCanWatchLottery] = useState<boolean>(false);

  // 抽選フラグ
  const [lotteryFlg, setLotteryFlg] = useState<boolean>(false);

  // 動画作成ステータス
  const [movieStatus, setMovieStatus] = useState<number|undefined|null>(null);

  // ユーザーお知らせ詳細
  const initialNewsUsers = {
    id: 0,
    title: '',
    description: '',
    en_title: '',
    en_description: '',
    ko_title: '',
    ko_description: '',
    zh_cn_title: '',
    zh_cn_description: '',
    zh_tw_title: '',
    zh_tw_description: '',
    vi_title: '',
    vi_description: '',
    th_title: '',
    th_description: '',
    link: '',
    lot_tables: null,
  };
  const [newsUsers, setNewsUsers] = useState<NewsUsers>({
    ...initialNewsUsers,
  });

  // ユーザーお知らせID
  const { id } = useParams<{ id: string }>();
  // ユーザーお知らせ取得リクエスト
  const axiosNewsUsersSource = axiosCancelToken.source();
  const getApiNewsUsersFind = () => {
    if (!isLoading) {
      setIsLoading(true);
      // axios GET /api/news_users/find?id=number
      const tempId = id;
      axiosClient
        .get<NewsUsersType>(`/api/news_users/find?id=${id}`, {
          cancelToken: axiosNewsUsersSource.token,
        })
        .then(({ data }) => {
          if (Object.keys(data).length !== 0) {
            setNewsUsers({
              id: data.id,
              title: data.title,
              description: data.description,
              en_title: data.en_title,
              en_description: data.en_description,
              ko_title: data.ko_title,
              ko_description: data.ko_description,
              zh_cn_title: data.zh_cn_title,
              zh_cn_description: data.zh_cn_description,
              zh_tw_title: data.zh_tw_title,
              zh_tw_description: data.zh_tw_description,
              link: data.link,
              lot_tables: data.lot_tables,
              vi_title: data.vi_title,
              vi_description: data.vi_description,
              th_title: data.th_title,
              th_description: data.th_description,
            });
            setCanWatchLottery(true);
            setLotteryFlg(data.lottery_flg === 1);
            setMovieStatus(data?.lot_tables?.movie_status);
          } else if (EVENT_ENABLED && Number(tempId) === EVENT_NEWS.ID) {
            setNewsUsers({
              id: EVENT_NEWS.ID,
              title: EVENT_NEWS.TITLE,
              description: EVENT_NEWS.DESCRIPTION,
              en_title: EVENT_NEWS.EN_TITLE,
              en_description: EVENT_NEWS.EN_DESCRIPTION,
              ko_title: EVENT_NEWS.KO_TITLE,
              ko_description: EVENT_NEWS.KO_DESCRIPTION,
              zh_cn_title: EVENT_NEWS.ZH_CN_TITLE,
              zh_cn_description: EVENT_NEWS.ZH_CN_DESCRIPTION,
              zh_tw_title: EVENT_NEWS.ZH_TW_TITLE,
              zh_tw_description: EVENT_NEWS.ZH_TW_DESCRIPTION,
              link: EVENT_NEWS.LINK,
              lot_tables: null,
              vi_title: EVENT_NEWS.VI_TITLE,
              vi_description: EVENT_NEWS.VI_DESCRIPTION,
              th_title: EVENT_NEWS.TH_TITLE,
              th_description: EVENT_NEWS.TH_DESCRIPTION,
            });
            setCanWatchLottery(true);
          } else {
            setEmptyMessage(t('お知らせはありません。'));
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // クリーンアップ処理
  const cleanUpMethod = () => {
    // APIリクエストキャンセル
    axiosNewsUsersSource.cancel();
    // データ
    setNewsUsers({ ...initialNewsUsers });
  };

  return {
    canWatchLottery,
    lotteryFlg,
    movieStatus,
    isLoading,
    newsUsers,
    getApiNewsUsersFind,
    emptyMessage,
    errorMessage,
    // クリーンアップ関数
    cleanUpMethod,
  };
};
