const prefix = 'DayLott\'s - ';

export const enSeo = {
  title: {
    // Global pages
    top: `${prefix}Top`,
    emailSend: `${prefix}Email transmission completed`,
    emailVerified: `${prefix}Email authentication`,
    whats: `${prefix}What is Day Lott's ?`,
    system: `${prefix}System`,
    faq: `${prefix}FAQ`,
    ranking: `${prefix}Ranking`,
    support: `${prefix}Support`,
    responsibleGambling: `${prefix}Responsible Gambling Polic`,
    newsShow: `${prefix}News`,
    contact: `${prefix}Contact Us`,
    contactConfirmation: `${prefix}Confirmation of contact contents`,
    contactCompleted: `${prefix}Contact completed`,
    passwordReset: `${prefix}Password reset`,
    passwordResetForm: `${prefix}Password reset form`,
    passwordResetCompleted: `${prefix}Password reset completed.`,
    // Guest pages
    register: `${prefix}Create account`,
    registerConfirmation: `${prefix}Create account`,
    login: `${prefix}Sign in`,
    accountDeleteCompleted: `${prefix}Account suspend completed`,
    // Private pages
    mypage: `${prefix}My page`,
    accountDeleteConfirmation: `${prefix}Account suspend`,
    kycUpload: `${prefix}KYC Upload`,
    kycConfirmation: `${prefix}KYC Confirmation`,
    kycCompleted: `${prefix}KYC Completed`,
    emailChange: `${prefix}Email address change`,
    pointPurchase: `${prefix}Point purchase`,
    pointPurchaseCc: `${prefix}Deposit with cryptocurrency`,
    pointPurchaseCredit: `${prefix}Deposit with credit card`,
    pointPurchaseCash: `${prefix}Deposit with cash`,
    pointPurchaseCompleted: `${prefix}Payment information transmission completed`,
    userNewsIndex: `${prefix}News list`,
    userNewsShow: `${prefix}News`,
    userNewsSkip: `${prefix}Receive earned points`,
    lottery: `${prefix}Lottery`,
    ticketPurchaseConfirmation: `${prefix}Confirmation of purchase details`,
    ticketPurchaseCompleted: `${prefix}Ticket purchase completed`,
    withdrawalTop: `${prefix}Point deposit withdraw exchang`,
    withdrawalCc: `${prefix}Remittance procedures`,
    withdrawalFinish: `${prefix}The withdrawal/exchange has been accepted.`,
  },
  description: {
    // Global pages
    top: 'DayLott\'s is the world\'s first online lottery game that emits millionaires every day.',
    emailSend: `${prefix}Email transmission completion page.`,
    emailVerified: `${prefix}Email verification page.`,
    whats: 'DayLott\'s is the world\'s first online lottery game that emits millionaires every day.',
    system: 'An online lottery game with an amazing 75% chance of winning. Tables are prepared according to the bet amount to be entered and the winning amount. The rules are a simple system where you just buy a ticket and enter.',
    faq: 'This is the FAQ page about DayLott\'s.',
    ranking: 'This is the DayLott\'s winner ranking page.',
    support: 'This is a support page for frequently asked questions about DayLott\'s, email inquiries, inquiry times, etc.',
    responsibleGambling: 'We are committed to providing you with the most enjoyable gambling experience possible, but we also recognize that gambling can cause problems for some people. To ensure that you can continue to enjoy safe and manageable play, we support responsible gambling and have put in place a number of measures to assist you.',
    newsShow: `${prefix}News page.`,
    contact: 'This is the contact page for DayLott\'s. We accept contact from customers using the contact form.',
    contactConfirmation: `${prefix}Contact confirmation page.`,
    contactCompleted: `${prefix}Contact completion page.`,
    passwordReset: `${prefix}Password reset page.`,
    passwordResetForm: `${prefix}Password reset form.`,
    passwordResetCompleted: `${prefix}Password reset completed page.`,
    // Guest pages
    register: 'Register as a member of DayLott\'s and charge points! Buy lottery tickets and join the game!',
    registerConfirmation: `${prefix}Confirmation of input contents.`,
    login: 'Please enter the email address and password registered in DayLott \'s to log in. If you are using for the first time, please register as a member.',
    accountDeleteCompleted: `${prefix}Account suspend completed page.`,
    // Private pages
    mypage: 'This is my page for DayLott\'s users.',
    accountDeleteConfirmation: `${prefix}Account suspend page.`,
    kycUpload: `${prefix}KYC upload page.`,
    kycConfirmation: `${prefix}KYC Confirmation page.`,
    kycCompleted: `${prefix}KYC Completed page.`,
    emailChange: `${prefix}Email address change page.`,
    pointPurchase: `${prefix}Point purchase page.`,
    pointPurchaseCc: `${prefix}Page for depositing with cryptocurrency.`,
    pointPurchaseCredit: `${prefix}Page for depositing with credit.`,
    pointPurchaseCash: `${prefix}Page for depositing with cash.`,
    pointPurchaseCompleted: `${prefix}The deposit information has been sent.`,
    userNewsIndex: `${prefix}Page for news list.`,
    userNewsShow: `${prefix}News page.`,
    userNewsSkip: `${prefix}Receive earned points page`,
    lottery: `${prefix}Page for lottery.`,
    ticketPurchaseConfirmation: `${prefix}Ticket purchase confirmation page.`,
    ticketPurchaseCompleted: `${prefix}Ticket purchase completed page.`,
    withdrawalTop: `${prefix}Point deposit withdraw exchang page.`,
    withdrawalCc: `${prefix}Remittance procedures page.`,
    withdrawalFinish: `${prefix}The withdrawal/exchange has been accepted.`,
  },
};
