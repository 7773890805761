import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { playBoxShowState } from 'store/atoms/playBoxShowState';
// Types
import { PlayBoxId } from 'types/playBoxType';
/**
 *
 * フッターメニュー用カスタムフック
 *
 */
export const useFooterNavSp = (): {
  isMenuShow: boolean;
  toggleMenuShow: (state: boolean) => void;
  pushToBox: (playBoxId: PlayBoxId) => void;
} => {
  // モーダル表示ステート
  const [isMenuShow, setIsMenuShow] = useState<boolean>(false);
  // モーダル表示非表示切り替え
  const toggleMenuShow = useCallback((state: boolean) => {
    setIsMenuShow(state);
  }, []);

  // Recoil
  // PLAYBOX開閉状態
  const playBoxShow = useRecoilValue(playBoxShowState);
  // 状態更新関数
  const setPlayBoxShow = useSetRecoilState(playBoxShowState);

  // history object
  const history = useHistory();

  // ボックス選択
  const pushToBox = useCallback((playBoxId: PlayBoxId) => {
    setPlayBoxShow({
      initialBoxId: playBoxId,
      isShow1000: playBoxShow.isShow1000,
      isShow5000: playBoxShow.isShow5000,
      isShow10000: playBoxShow.isShow10000,
      isShow100000: playBoxShow.isShow100000,
      isShow1000000: playBoxShow.isShow1000000,
    });

    // 選択したボックスに遷移
    history.push({
      pathname: '/',
    });

    // modal close
    setIsMenuShow(false);
  }, []);

  return {
    isMenuShow,
    toggleMenuShow,
    pushToBox,
  };
};
