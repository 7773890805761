import { FC, memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Components
import RoundButton from 'components/atoms/button/RoundButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Recoil
import { useRecoilValue } from 'recoil';
import { langState } from 'store/atoms/langState';
// Hooks
import { useTicketPurchaseConfirm } from 'hooks/pages/private/useTicketPurchaseConfirm';
// Utils
import { LANG } from 'utils/const/lang';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';

/**
 *
 * Component
 *
 */
const TicketPurchaseConfirm: FC = memo(() => {
  // Recoil
  const lang = useRecoilValue(langState);

  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    boxTitle,
    betPoint,
    point,
    freePoint,
    remainingFreePoints,
    remainingPaidPoints,
    errorMessage,
    goToTopPage,
    postApiLotBuy,
  } = useTicketPurchaseConfirm();

  return (
    <>
      <Helmet>
        <title>{t('title.ticketPurchaseConfirmation')}</title>
        <meta
          name="description"
          content={t('description.ticketPurchaseConfirmation')}
        />
      </Helmet>

      {isLoading && <LoaderWindow />}

      <ConfirmS>
        <ContainerS>

          <TitleS>
            <SquareS />
            {lang === LANG.ENGLISH ? (
              <TitleTextWrapS>
                <TitleTextS>Confirmation of</TitleTextS>
                <TitleTextS>purchase details</TitleTextS>
              </TitleTextWrapS>
            ) : (
              <TitleTextWrapS>
                <TitleTextS>{t('購入内容のご確認')}</TitleTextS>
              </TitleTextWrapS>
            )}
            <SquareS />
          </TitleS>

          <PurchaseS>
            <PurchaseWrapS>
              <PurchaseTextS>
                <TextS>
                  {t('下記の内容で間違いないかご確認ください。')}
                  <br />
                  {t('「購入する」ボタンをクリックすると購入が確定します。')}
                </TextS>
              </PurchaseTextS>
              <PurchaseInfoS>
                <PlayBoxInfoS>
                  <BoxTitleWrapS>
                    <BoxTitleHeadS>
                      <TextS>{t('参加ボックス')}</TextS>
                    </BoxTitleHeadS>
                    <BoxTitleS>
                      <TextS>{boxTitle}</TextS>
                    </BoxTitleS>
                  </BoxTitleWrapS>
                </PlayBoxInfoS>
                <PointInfoS>
                  <PointWrapS>
                    <PointRowS>
                      <div>
                        <TextS>{t('消費ポイント数')}</TextS>
                      </div>
                      <div>
                        <TextS>
                          {betPoint.toLocaleString()}
                          {' '}
                          P
                        </TextS>
                      </div>
                    </PointRowS>
                    <PointRowS>
                      <div>
                        <TextS>{t('所持有償ポイント数')}</TextS>
                      </div>
                      <div>
                        <TextS>
                          {point.toLocaleString()}
                          {' '}
                          P
                        </TextS>
                      </div>
                    </PointRowS>
                    <PointRowS>
                      <div>
                        <TextS>{t('所持無償ポイント数')}</TextS>
                      </div>
                      <div>
                        <TextS>
                          {freePoint.toLocaleString()}
                          {' '}
                          P
                        </TextS>
                      </div>
                    </PointRowS>
                    <BorderS />
                    <PointRowS>
                      <div>
                        <TextS>{t('残り有償ポイント数')}</TextS>
                      </div>
                      <div>
                        <TextS>
                          {remainingPaidPoints.toLocaleString()}
                          {' '}
                          P
                        </TextS>
                      </div>
                    </PointRowS>
                    <PointRowS>
                      <div>
                        <TextS>{t('残り無償ポイント数')}</TextS>
                      </div>
                      <div>
                        <TextS>
                          {remainingFreePoints.toLocaleString()}
                          {' '}
                          P
                        </TextS>
                      </div>
                    </PointRowS>
                  </PointWrapS>
                </PointInfoS>
              </PurchaseInfoS>

              {/* エラーメッセージ */}
              {errorMessage && (
                <MessageWrapS>
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                </MessageWrapS>
              )}

              <ButtonGroupS>
                <RoundButtonWrapS className="button-fix">
                  <RoundButton
                    onClick={goToTopPage}
                    background="linear-gradient(rgba(0,5,65,1), 90%, rgba(0,135,167,0.9))"
                    borderColor="rgba(0,135,167,1)"
                  >
                    {t('修正する')}
                  </RoundButton>
                </RoundButtonWrapS>
                <RoundButtonWrapS className="button-register-confirm">
                  {isLoading ? (
                    <RoundButton
                      background="#555555"
                      borderColor="rgba(230, 230, 230, 1)"
                    >
                      {t('購入する')}
                    </RoundButton>
                  ) : (
                    <RoundButton
                      onClick={postApiLotBuy}
                      background="linear-gradient(rgba(0,5,65,1), 85%, rgba(219,0,177,0.9))"
                      borderColor="rgba(219,0,177,1)"
                    >
                      {t('購入する')}
                    </RoundButton>
                  )}
                </RoundButtonWrapS>
              </ButtonGroupS>
            </PurchaseWrapS>
          </PurchaseS>
        </ContainerS>
      </ConfirmS>
    </>
  );
});
export default TicketPurchaseConfirm;

/**
 *
 * Styles
 *
 */
const ConfirmS = styled.main`
  // background
  background: url(${backGroundSp});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (min-width: 1025px) {
    background: image-set(url(${backGroundPc}) 1x, url(${backGroundPc2x}) 2x);
    background-position: top;
  }
`;

const ContainerS = styled.div`
  width: 90%;
  min-height: 90vh;
  margin: 0 auto;
  padding-top: 6.5rem;
  padding-bottom: 5rem;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  @media (min-width: 600px) {
    padding-top: 12rem;
  }

  @media (min-width: 1025px) {
    width: 100%;
    max-width: 100rem;
    padding-top: 2rem;
    padding-bottom: 10rem;
  }
`;
const TitleS = styled.div`
  position: relative;
  margin-bottom: 3rem;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    margin-bottom: 0;
    margin: 7rem 0;
  }
`;

const SquareS = styled.div`
  margin-top: 0.5rem;
  width: 3.5rem;
  height: 2.5rem;
  transform: skew(-40deg, 0deg);
  background: linear-gradient(
    0deg,
    #ffe99b,
    #a8861f,
    #ffe99b,
    #ffffff,
    #ffe99b
  );

  @media (min-width: 600px) {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

const TitleTextWrapS = styled.div`
  margin: 0 1.2rem;
  width: 100%;

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleTextS = styled.p`
  color: transparent;
  font-size: min(6vw, 3rem);
  font-weight: bold;
  word-break: break-word;

  background: linear-gradient(
    0deg,
    #ffe99b,
    #a8861f,
    #ffe99b,
    #ffffff,
    #ffe99b
  );
  background-clip: text;
  -webkit-background-clip: text;
`;

const PurchaseS = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
`;

const PurchaseWrapS = styled.div`
  padding: 2rem 1rem;

  @media (min-width: 600px) {
    padding: 2rem;
  }

  @media (min-width: 1025px) {
    padding: 5rem;
  }
`;

const PurchaseTextS = styled.div`
  font-size: 1.2rem;

  @media (min-width: 1025px) {
    font-size: 1.4rem;
  }
`;

const TextS = styled.p`
  color: #ffffff;
  font-size: inherit;
  line-height: 1.7;
  word-break: break-word;
`;

const PurchaseInfoS = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    justify-content: space-between;
    flex-direction: unset;
  }
`;

const PlayBoxInfoS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    width: 47%;
  }
`;

const BoxTitleWrapS = styled.div`
  display: flex;
  padding-bottom: 1rem;
`;

const BoxTitleHeadS = styled.div`
  width: 45%;
  padding: 1rem;
  font-size: 1.4rem;
  text-align: center;
  background: rgba(80, 80, 80, 0.3);

  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    width: 30%;
    padding: 1rem 0;
    font-size: 1.6rem;
  }
`;

const BoxTitleS = styled.div`
  width: 70%;
  padding-left: 2rem;
  font-size: 1.6rem;
  background: rgba(80, 80, 80, 0.2);

  // flex
  display: flex;
  align-items: center;

  @media (min-width: 1025px) {
    font-size: 1.9rem;
  }
`;

const PointInfoS = styled.div`
  width: 100%;
  background: rgba(80, 80, 80, 0.3);

  @media (min-width: 600px) {
    width: 47%;
  }
`;

const PointWrapS = styled.div`
  padding: 2rem 1rem;

  @media (min-width: 1025px) {
    padding: 3rem;
  }
`;

const PointRowS = styled.div`
  padding-bottom: 1.5rem;
  font-size: 1.4rem;

  // flex
  display: flex;
  justify-content: space-between;

  @media (min-width: 1025px) {
    padding-bottom: 1rem;
    font-size: 1.6rem;
  }
`;

const BorderS = styled.hr`
  color: #ffffff;
  margin-bottom: 1.5rem;

  @media (min-width: 1025px) {
    margin-bottom: 1rem;
  }
`;

const ButtonGroupS = styled.div`
  width: 80%;
  padding: 3rem 0;
  margin: 0 auto;
  // flex
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 50%;
    flex-direction: row;
  }

  @media (min-width: 1025px) {
    width: 45%;
  }
`;

const RoundButtonWrapS = styled.div`
  font-size: 1.4rem;

  @media (min-width: 1025px) {
    font-size: 1.6rem;
  }

  &.button-fix {
    width: 50%;
    margin-bottom: 2rem;

    @media (min-width: 600px) {
      width: 40%;
      margin-bottom: 0;
      margin-right: 2rem;
    }

    @media (min-width: 1025px) {
      width: 30%;
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }

  &.button-register-confirm {
    width: 65%;

    @media (min-width: 600px) {
      width: 50%;
    }

    @media (min-width: 1025px) {
      width: 45%;
    }
  }
`;

const MessageWrapS = styled.div`
  width: 100%;
  margin-top: 5rem;
  font-size: 1.6rem;
  text-align: center;
`;
