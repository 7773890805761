import { memo } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
// Components
import FormLayout from 'components/templates/FormLayout';
import NeonBorderTitle from 'components/molecules/title/NeonBorderTitle';
import RoundButton from 'components/atoms/button/RoundButton';
import RadioButton from 'components/atoms/radio/RadioButton';
import LoaderWindow from 'components/atoms/loader/LoaderWindow';
// Custom hooks
import { usePointPurchase } from 'hooks/pages/private/usePointPurchase';
// Recoil
import { langState } from 'store/atoms/langState';
// Utils
import { pointPurchaseBreadCrumbItems } from 'utils/breadCrumbs';
import { devices } from 'utils/const/devices';
import { LANG } from 'utils/const/lang';
// Images
import backGroundSp from 'assets/images/backGround/main_sp.jpg';
import backGroundPc from 'assets/images/backGround/main_pc.jpg';
import backGroundPc2x from 'assets/images/backGround/main_pc@2x.jpg';

const {
  JAPANESE, ENGLISH, SIMPLE_CHINESE, TRADITIONAL_CHINESE, KOREAN,
  VIETNAM, THAILAND,
} = LANG;
const { tabletPortrait, tabletLandscape, pc } = devices;

/**
 *
 * Component
 *
 */
const PointPurchase = memo((): JSX.Element => {
  // Hooks
  const { t } = useTranslation();
  const {
    isLoading,
    purchase,
    purchaseRadioList,
    onClickPurchase,
    campaigns,
  } = usePointPurchase();

  const lang = useRecoilValue(langState);

  return (
    <>
      <Helmet>
        <title>{t('title.pointPurchase')}</title>
        <meta name="description" content={t('description.pointPurchase')} />
      </Helmet>
      <FormLayout
        breadCrumbItems={pointPurchaseBreadCrumbItems}
        backGroundSp={backGroundSp}
        backGroundPc={backGroundPc}
        backGroundPc2x={backGroundPc2x}
        title={t('ポイント購入')}
        titleStyle="gold"
      >
        {/* ローディングアニメーション */}
        {isLoading && <LoaderWindow />}
        <FormS>
          {/* ボーナスポイント */}
          {campaigns.length > 0 && (
            <BlockS>
              <NeonBorderTitle
                title={t('ボーナスポイント')}
                description={t('ボーナスポイントは購入ポイント獲得時に付与されます。')}
              />
              <BonusS>
                <BonusUlistS>
                  {campaigns.map((campaign) => (
                    <BonusListS key={uuidv4()}>
                      {lang === JAPANESE && (
                        <BonusNameS>{`${campaign.min_point}P以上の購入で`}</BonusNameS>
                      )}
                      {lang === ENGLISH && (
                        <BonusNameS>{`You purchases of ${campaign.min_point}point or more`}</BonusNameS>
                      )}
                      {lang === KOREAN && (
                        <BonusNameS>{`${campaign.min_point}포인트 이상 구매`}</BonusNameS>
                      )}
                      {lang === SIMPLE_CHINESE && (
                        <BonusNameS>{`您购买了${campaign.min_point}P或更多`}</BonusNameS>
                      )}
                      {lang === TRADITIONAL_CHINESE && (
                        <BonusNameS>{`您購買了${campaign.min_point}P或更多`}</BonusNameS>
                      )}
                      {lang === VIETNAM && (
                        <BonusNameS>{`Bạn mua điểm ${campaign.min_point}trở lên`}</BonusNameS>
                      )}
                      {lang === THAILAND && (
                        <BonusNameS>{`คุณซื้อจุด ${campaign.min_point}หรือมากกว่า`}</BonusNameS>
                      )}
                      <BonusPointS>
                        <BonusPointPlusS>+</BonusPointPlusS>
                        {campaign.point}
                        <BonusPointUnitS>P</BonusPointUnitS>
                      </BonusPointS>
                    </BonusListS>
                  ))}
                </BonusUlistS>
              </BonusS>
            </BlockS>
          )}

          <BlockS>
            <NeonBorderTitle
              title={t('購入方法を選択')}
            />

            <PurchaseTypeS>
              {purchaseRadioList.map((item) => (
                <ListS key={uuidv4()} checked={purchase === item.value}>
                  <PurchaseTypeImageS src={item.image} alt="" />
                  <RadioButton
                    radioText={item.radioText}
                    onClick={item.changePurchase}
                    width="5%"
                  >
                    <LabelS
                      htmlFor={item.value}
                      checked={purchase === item.value}
                    />
                  </RadioButton>
                </ListS>
              ))}
            </PurchaseTypeS>

            <PurchaseButtonS>
              {isLoading ? (
                <RoundButton
                  background="#555555"
                  borderColor="rgba(230, 230, 230, 1)"
                >
                  {t('購入する')}
                </RoundButton>
              ) : (
                <RoundButton
                  onClick={onClickPurchase}
                  background="linear-gradient(rgba(0,71,106,1), 90%, rgba(0,150,110,0.9))"
                  borderColor="rgba(0,150,110,1)"
                >
                  {t('購入する')}
                </RoundButton>
              )}
            </PurchaseButtonS>
          </BlockS>
        </FormS>
      </FormLayout>
    </>
  );
});
export default PointPurchase;

/**
 *
 * Styles
 *
 */
const FormS = styled.div`
  width: 100%;
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tabletPortrait} {
    margin-top: 7.4rem;
  }

  @media ${tabletLandscape}, ${pc} {
    width: 90%;
    margin-top: 8rem;
  }
`;

const BlockS = styled.div`
  width: 100%;
  margin-bottom: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const BonusS = styled.div`
  width: 100%;
  color: #ffeb00;
  margin-top: 2rem;

  @media (min-width: 600px) {
    margin-top: 2.4rem;
  }
`;

const BonusUlistS = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5rem;
  margin-top: 3rem;

  @media ${tabletPortrait} {
    margin-top: 6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    row-gap: 7rem;
    margin-top: 7rem;
  }
`;

const BonusListS = styled.li`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const BonusNameS = styled.div`
  font-size: 1.4rem;

  @media ${tabletPortrait} {
    font-size: 1.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 2rem;
  }
`;

const BonusPointS = styled.div`
  font-size: 3rem;
  font-weight: bold;
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: end;
  column-gap: 0.3rem;

  @media (min-width: 600px) {
    font-size: 4rem;
  }
`;

const BonusPointPlusS = styled.span`
  font-size: 2rem;
  font-weight: normal;

  @media ${tabletPortrait} {
    font-size: 3rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 3.4rem;
  }
`;

const BonusPointUnitS = styled.span`
  font-size: 1.6rem;

  @media ${tabletPortrait} {
    font-size: 2.6rem;
  }

  @media ${tabletLandscape}, ${pc} {
    font-size: 3rem;
  }
`;

const PurchaseTypeS = styled.ul`
  margin-top: 3rem;
  margin-bottom: 2rem;
  width: 100%;
  display: block;

  @media (min-width: 600px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  @media ${tabletLandscape}, ${pc} {
    margin: 5rem 0;
  }
`;

const ListS = styled.li<{ checked: boolean }>`
  padding: 1.5rem 0;

  // flex
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ checked }) => (checked ? 'rgba(255, 255, 255, 0.3)' : 'transparent')};
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  margin-bottom: 1rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media ${tabletLandscape}, ${pc} {
    padding: 2.5rem 0;
  }
`;

const LabelS = styled.label<{ checked: boolean }>`
  cursor: pointer;
  padding-left: 0rem;
  position: relative;
`;

const PurchaseTypeImageS = styled.img`
  height: 20px;
  margin-left:10px;

  @media ${tabletLandscape}, ${pc} {
  
  }
`;

const PurchaseButtonS = styled.div`
  width: 130px;
  font-size: 1.4rem;

  @media (min-width: 600px) {
    width: 150px;
    font-size: 1.6rem;
  }
`;
