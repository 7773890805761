import { useState } from 'react';
// External library
import { CancelTokenSource } from 'axios';
import { toast } from 'react-toastify';
// Custom hooks
import { useAuth } from 'hooks/versatile/useAuth';
import { useError } from 'hooks/versatile/useError';
// Utils
import { axiosCancelToken, axiosClient } from 'utils/axios';
// Types
import { ApiErrorType } from 'types/api';
import { useTranslation } from 'react-i18next';

/**
 *
 * Types
 *
 */
type PostApiLotCheck = (lotTableId: string) => void;

type UseApiLotCheck = () => {
  isLoading: boolean;
  lotCheckSource: CancelTokenSource;
  postApiLotCheck: PostApiLotCheck;
};

export const useApiLotCheck: UseApiLotCheck = () => {
  // Hooks
  const { getApiUsersMe } = useAuth();
  const { displayErrorMessageInToast } = useError();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   * API 抽選獲得ポイントチェック
   * これを実行しないと獲得ポイントが付与されない
   *
   */
  const lotCheckSource = axiosCancelToken.source();
  const postApiLotCheck: PostApiLotCheck = (lotTableId) => {
    setIsLoading(true);
    axiosClient
      .post<true | ApiErrorType>(
        '/api/lot/check',
        {
          lot_table_id: lotTableId,
          cancelToken: lotCheckSource.token,
        },
      )
      .then(({ data }) => {
        if (data === true) {
          getApiUsersMe();
          toast.success(t('ポイントを受け取りました。'));
        } else {
          toast.error(data.message);
        }
      })
      .catch(displayErrorMessageInToast)
      .finally(() => { setIsLoading(false); });
  };

  return {
    isLoading,
    lotCheckSource,
    postApiLotCheck,
  };
};
