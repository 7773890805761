import { useState, useEffect } from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
// Recooil
import { userState } from 'store/atoms/userState';
import { useRecoilValue } from 'recoil';
// Hooks
import { useError } from 'hooks/versatile/useError';
import { useRedirect } from 'hooks/versatile/useRedirect';
// Utils
import { axiosClient, axiosCancelToken } from 'utils/axios';
// Types
import { ApiErrorType } from 'types/api';
import { LotBuy } from 'types/playBoxType';

/**
 *
 * Types
 *
 */
type LocationState = {
  referrer: string;
  boxTitle: string;
  minimumBet: number;
  tableId: number;
  units: string;
};

/**
 *
 * 購入確認ページ用ロジック
 *
 */
export const useTicketPurchaseConfirm = (): {
  isLoading: boolean;
  boxTitle: string;
  betPoint: number;
  point: number;
  freePoint: number;
  remainingFreePoints: number;
  remainingPaidPoints: number;
  errorMessage: string;
  goToTopPage: () => void;
  postApiLotBuy: () => void;
} => {
  const history = useHistory();
  const goToTopPage = () => {
    history.push('/');
  };

  // TOPからの入力内容
  const alternativeLocationState: LocationState = {
    referrer: '',
    boxTitle: '',
    minimumBet: 0,
    tableId: 0,
    units: '',
  };
  const {
    referrer, boxTitle, minimumBet, tableId, units,
  } = useLocation<LocationState | undefined>().state ?? alternativeLocationState;

  // エラー用カスタムフック
  const { errorHandler } = useError();
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');
  // ローディング状態
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 購入ポイント数
  const betPoint = minimumBet * Number(units);
  // 所持有償/無償ポイント
  const { point, freePoint } = useRecoilValue(userState);
  const [remainingFreePoints, setRemainingFreePoints] = useState(freePoint);
  const [remainingPaidPoints, setRemainingPaidPoints] = useState(point);
  useEffect(() => {
    if (betPoint <= freePoint) {
      setRemainingFreePoints(freePoint - betPoint);
    } else {
      setRemainingFreePoints(0);
      setRemainingPaidPoints(point - (betPoint - freePoint));
    }
  }, []);

  // 購入チケット番号ステート
  const [ticketNumbers, setTicketNumbers] = useState<number[]>([0]);

  // 購入完了画面に遷移
  const pushToFinish = () => {
    history.push({
      pathname: '/ticket_purchase/finish',
      state: {
        referrer: '/ticket_purchase/confirm',
        boxTitle,
        ticketNumbers,
      },
    });
  };

  // APIキャンセルトークン
  const axiosLotBuySource = axiosCancelToken.source();
  // ロト購入リクエスト
  const postApiLotBuy = () => {
    if (!isLoading) {
      setIsLoading(true);

      // axios POST /api/lot/buy
      axiosClient
        .post<LotBuy[]>('api/lot/buy', {
          lot_table_id: tableId,
          amount: units,
          cancelToken: axiosLotBuySource.token,
        })
        .then(({ data }) => {
          if (data.length) {
            // レスポンスデータからチケット番号を抽出
            const getTicketNumbers = data.map((item) => item.ticket_number);

            setTicketNumbers([...getTicketNumbers]);
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (ticketNumbers[0] !== 0) {
      // 完了画面に遷移
      pushToFinish();
    }
  }, [ticketNumbers]);

  // クリーンアップ処理
  const cleanup = () => {
    axiosLotBuySource.cancel();
    setTicketNumbers([0]);
  };
  const { redirectToTopPage } = useRedirect();
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    // TOP以外からのアクセスor購入情報がない=>TOPへリダイレクト
    if (referrer !== '/' || betPoint === 0) {
      redirectToTopPage();
    }

    return cleanup;
  }, []);

  return {
    isLoading,
    boxTitle,
    betPoint,
    point,
    freePoint,
    remainingFreePoints,
    remainingPaidPoints,
    errorMessage,
    goToTopPage,
    postApiLotBuy,
  };
};
