const prefix = 'DayLott\'s - ';

export const zhCnSeo = {
  title: {
    // Global pages
    top: `${prefix}首页`,
    emailSend: `${prefix}电子邮件传输完成`,
    emailVerified: `${prefix}电子邮件认证`,
    whats: `${prefix}什么是Day Lott's`,
    system: `${prefix}系统`,
    faq: `${prefix}常见问题`,
    ranking: `${prefix}排行`,
    support: `${prefix}支持`,
    responsibleGambling: `${prefix}Responsible Gambling Polic`,
    newsShow: `${prefix}注意`,
    contact: `${prefix}联系我们`,
    contactConfirmation: `${prefix}确认联系内容`,
    contactCompleted: `${prefix}查询完成`,
    passwordReset: `${prefix}重设密码`,
    passwordResetForm: `${prefix}密码重置表格`,
    passwordResetCompleted: `${prefix}密码重置完成`,
    // Guest pages
    register: `${prefix}新会员注册`,
    registerConfirmation: `${prefix}新会员注册`,
    login: `${prefix}登录`,
    accountDeleteCompleted: `${prefix}账户已暂停`,
    // Private pages
    mypage: `${prefix}我的页面`,
    accountDeleteConfirmation: `${prefix}暂停账户`,
    kycUpload: `${prefix}KYC 上传`,
    kycConfirmation: `${prefix}KYC 确认`,
    kycCompleted: `${prefix}KYC 完成`,
    emailChange: `${prefix}更改电子邮箱`,
    pointPurchase: `${prefix}購買點數`,
    pointPurchaseCc: `${prefix}用虚拟货币充值`,
    pointPurchaseCredit: `${prefix}用信用卡充值`,
    pointPurchaseCash: `${prefix}现金充值`,
    pointPurchaseCompleted: `${prefix}已发送充值信息`,
    userNewsIndex: `${prefix}通知清单`,
    userNewsShow: `${prefix}注意`,
    userNewsSkip: `${prefix}获得积分`,
    lottery: `${prefix}彩票`,
    ticketPurchaseConfirmation: `${prefix}确认购买信息`,
    ticketPurchaseCompleted: `${prefix}购票完成`,
    withdrawalTop: `${prefix}现有点数 提现、兑换`,
    withdrawalCc: `${prefix}匯款手續`,
    withdrawalFinish: `${prefix}提取或兑换已被受理。`,
  },
  description: {
    // Global pages
    top: 'DayLott\'s 是世界上第一个每天都会产生百万富翁的在线彩票游戏。',
    emailSend: `${prefix}的邮件发送完成页面。`,
    emailVerified: `${prefix}的邮箱验证页面。`,
    whats: 'DayLott\'s 是世界上第一个每天都会产生百万富翁的在线彩票游戏。',
    system: '一款在线彩票游戏，中奖几率高达 75%。根据要输入的赌注金额和获胜金额准备表格。规则是一个简单的系统，您只需购买门票并进入。',
    faq: '这是一个总结有关 DayLott\'s 的常见问题的页面。',
    ranking: '这是DayLott\'s 的获胜者排名页面。',
    support: '这是有关 DayLott\'s 的常见问题、电子邮件查询、查询时间等的支持页面。',
    responsibleGambling: 'We are committed to providing you with the most enjoyable gambling experience possible, but we also recognize that gambling can cause problems for some people. To ensure that you can continue to enjoy safe and manageable play, we support responsible gambling and have put in place a number of measures to assist you.',
    newsShow: `${prefix}通知页面。`,
    contact: '这是DayLott\'s的查询页面。使用我们的联系表格与我们的客户取得联系。',
    contactConfirmation: `${prefix}的查询确认页面。`,
    contactCompleted: `${prefix}查询完成页面。`,
    passwordReset: `${prefix}密码重置页面。`,
    passwordResetForm: `${prefix}密码重置表格。`,
    passwordResetCompleted: `${prefix}密码重置完成页面。`,
    // Guest pages
    register: '注册成为 DayLott\'s 会员并充值！购买彩票并加入游戏！',
    registerConfirmation: `${prefix}输入内容的确认。`,
    login: '请输入在 DayLott\'s 中注册的邮箱地址和密码登录。如果您是第一次使用，请注册成为会员。',
    accountDeleteCompleted: `${prefix}帐户暂停完成页面。`,
    // Private pages
    mypage: '这是我的DayLott\'s用户页面。',
    accountDeleteConfirmation: `${prefix}帐户暂停页面。`,
    kycUpload: `${prefix}KYC 上传页面。`,
    kycConfirmation: `${prefix}KYC 确认页面。`,
    kycCompleted: `${prefix}KYC 完成页面。`,
    emailChange: `${prefix}电子邮件地址更改页面。`,
    pointPurchase: `${prefix}积分购买页面。`,
    pointPurchaseCc: `${prefix}使用加密货币存款的页面。`,
    pointPurchaseCredit: `${prefix}用信用卡存款的页面。`,
    pointPurchaseCash: `${prefix}用现金存款的页面。`,
    pointPurchaseCompleted: `${prefix}存款信息已发送。`,
    userNewsIndex: `${prefix}通知列表页面。`,
    userNewsShow: `${prefix}通知页面。`,
    userNewsSkip: `${prefix}获得积分页面。`,
    lottery: `${prefix}彩票页面。`,
    ticketPurchaseConfirmation: `${prefix}购票确认页面。`,
    ticketPurchaseCompleted: `${prefix}购票完成页面。`,
    withdrawalTop: `${prefix}现有点数 提现、兑页面。`,
    withdrawalCc: `${prefix}匯款手續页面。`,
    withdrawalFinish: `${prefix}提取或兑换已被受理。`,
  },
};
