export const WINNERS_RANK_UP_TO_THIRD = {
  FIRST: '1',
  SECOND: '2',
  THIRD: '3',
} as const;

export const WINNERS_RANK_UP_TO_FOURTH = {
  FIRST: '1',
  SECOND: '2',
  THIRD: '3',
  FOURTH: '4',
} as const;

export const WINNERS_RANK_UP_TO_FIFTH = {
  FIRST: '1',
  SECOND: '2',
  THIRD: '3',
  FOURTH: '4',
  FIFTH: '5',
} as const;
