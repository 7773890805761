import { FC, useEffect } from 'react';
// External library
import { Helmet } from 'react-helmet-async';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// Custom hooks
import { useRanking } from 'hooks/pages/common/useRanking';
// Components
import LoaderPart from 'components/atoms/loader/LoaderPart';
import ErrorMessage from 'components/atoms/text/ErrorMessage';
// Images
import rankingBgPc from 'assets/images/backGround/pers2_pc.jpg';
import rankingBgPc2x from 'assets/images/backGround/pers2_pc@2x.jpg';
import rankingBgSp from 'assets/images/backGround/pers2_sp.jpg';
import rankingHeadPc from 'assets/images/ranking/title_pc.png';
import rankingHeadPc2x from 'assets/images/ranking/title_pc@2x.png';
import rankingHeadSp from 'assets/images/ranking/title_sp.png';
import rankingEmblem1stPc from 'assets/images/ranking/1st_pc.png';
import rankingEmblem1stPc2x from 'assets/images/ranking/1st_pc@2x.png';
import rankingEmblem1stSp from 'assets/images/ranking/1st_sp.png';
import rankingEmblem2ndPc from 'assets/images/ranking/2nd_pc.png';
import rankingEmblem2ndPc2x from 'assets/images/ranking/2nd_pc@2x.png';
import rankingEmblem2ndSp from 'assets/images/ranking/2nd_sp.png';
import rankingEmblem3rdPc from 'assets/images/ranking/3rd_pc.png';
import rankingEmblem3rdPc2x from 'assets/images/ranking/3rd_pc@2x.png';
import rankingEmblem3rdSp from 'assets/images/ranking/3rd_sp.png';
// Utils
import { RANK_EMBLEM } from 'utils/const/ranking';
import { devices } from 'utils/const/devices';

const { FIRST, SECOND, THIRD } = RANK_EMBLEM;

/**
 *
 * Component
 *
 */
const Ranking: FC = () => {
  // i18next
  const { t } = useTranslation();

  // ランキングページ用カスタムフック
  const {
    isLoading,
    ranking,
    errorMessage,
    isEmpty,
    // タブメニュー
    isTabActive,
    getApiRanking,
    // クリーンアップ関数
    cleanUpMethod,
  } = useRanking();

  useEffect(() => {
    // デイリーランキング情報取得
    getApiRanking('daily');

    return () => {
      // クリーンアップ関数
      cleanUpMethod();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('title.ranking')}</title>
        <meta name="description" content={t('description.ranking')} />
      </Helmet>
      <ContainerS>
        {/* SP版タイトルイメージ */}
        <RankingTitleImageBlockSpS>
          <RankingTitleImageSpWrapS>
            <RankingTitleImageSpS src={rankingHeadSp} alt="" loading="lazy" />
          </RankingTitleImageSpWrapS>
        </RankingTitleImageBlockSpS>
        {/* PC版タイトルイメージ */}
        <RankingTitleImageS
          src={rankingHeadPc}
          srcSet={`${rankingHeadPc2x} 2x`}
          alt=""
          loading="lazy"
        />
        <RankingWrapS>
          {/* ランキング要素 */}
          <RankingContainerS>
            {/* SP版タブメニュー */}
            <TabMenuSpS>
              <TabMenuListS
                className={isTabActive === 'daily' ? 'tabActive' : 'tabInactive'}
                preLine="pre-line"
                onClick={() => getApiRanking('daily')}
              >
                {t('一日')}
              </TabMenuListS>
              <TabMenuListS
                className={isTabActive === 'weekly' ? 'tabActive' : 'tabInactive'}
                preLine="pre-line"
                onClick={() => getApiRanking('weekly')}
              >
                {t('週間')}
              </TabMenuListS>
              <TabMenuListS
                className={
                  isTabActive === 'monthly' ? 'tabActive' : 'tabInactive'
                }
                preLine="pre-line"
                onClick={() => getApiRanking('monthly')}
              >
                {t('月間')}
              </TabMenuListS>
              <TabMenuListS
                className={isTabActive === 'all' ? 'tabActive' : 'tabInactive'}
                preLine="pre-line"
                onClick={() => getApiRanking('all')}
              >
                {t('全期間')}
              </TabMenuListS>
            </TabMenuSpS>

            <TabS>
              {/* PC版タブメニュー */}
              <TabMenuPcWrapS>
                <TabMenuPcS>
                  <TabMenuListS
                    className={
                      isTabActive === 'daily' ? 'tabActive' : 'tabInactive'
                    }
                    preLine="pre-line"
                    onClick={() => getApiRanking('daily')}
                  >
                    {t('一日')}
                  </TabMenuListS>
                  <TabMenuListS
                    className={
                      isTabActive === 'weekly' ? 'tabActive' : 'tabInactive'
                    }
                    preLine="pre-line"
                    onClick={() => getApiRanking('weekly')}
                  >
                    {t('週間')}
                  </TabMenuListS>
                  <TabMenuListS
                    className={
                      isTabActive === 'monthly' ? 'tabActive' : 'tabInactive'
                    }
                    preLine="pre-line"
                    onClick={() => getApiRanking('monthly')}
                  >
                    {t('月間')}
                  </TabMenuListS>
                  <TabMenuListS
                    className={
                      isTabActive === 'all' ? 'tabActive' : 'tabInactive'
                    }
                    preLine="pre-line"
                    onClick={() => getApiRanking('all')}
                  >
                    {t('全期間')}
                  </TabMenuListS>
                </TabMenuPcS>
              </TabMenuPcWrapS>
              <UlistS>
                {/* ローディングアニメーション */}
                {isLoading && <LoaderPart />}
                {ranking?.[0].rank !== 0
                  && ranking.map((item) => (
                    <ListS key={uuidv4()}>
                      <RowS>
                        {/* Rank */}
                        {/* 1位 */}
                        {item.rank === FIRST && (
                          <RankEmblemPictureS>
                            <RankEmblemSourceS
                              srcSet={`${rankingEmblem1stPc} 1x,${rankingEmblem1stPc2x} 2x`}
                              media="(min-width: 1025px)"
                            />
                            <RankEmblemImageS
                              src={rankingEmblem1stSp}
                              alt=""
                              loading="lazy"
                            />
                          </RankEmblemPictureS>
                        )}

                        {/* ２位 */}
                        {item.rank === SECOND && (
                          <RankEmblemPictureS>
                            <RankEmblemSourceS
                              srcSet={`${rankingEmblem2ndPc} 1x,${rankingEmblem2ndPc2x} 2x`}
                              media="(min-width: 1025px)"
                            />
                            <RankEmblemImageS
                              src={rankingEmblem2ndSp}
                              alt=""
                              loading="lazy"
                            />
                          </RankEmblemPictureS>
                        )}

                        {/* 3位 */}
                        {item.rank === THIRD && (
                          <RankEmblemPictureS>
                            <RankEmblemSourceS
                              srcSet={`${rankingEmblem3rdPc} 1x,${rankingEmblem3rdPc2x} 2x`}
                              media="(min-width: 1025px)"
                            />
                            <RankEmblemImageS
                              src={rankingEmblem3rdSp}
                              alt=""
                              loading="lazy"
                            />
                          </RankEmblemPictureS>
                        )}

                        {/* 3位以下 */}
                        {item.rank > THIRD && <RankS>{item.rank}</RankS>}

                        {/* UserName */}
                        {/* 3位以上 */}
                        {item.rank <= THIRD && (
                          <UserNameS addFontSize={0.3}>{item.name}</UserNameS>
                        )}

                        {/* 4位以下 */}
                        {item.rank > THIRD && (
                          <UserNameS addFontSize={0}>{item.name}</UserNameS>
                        )}

                        {/* Point */}
                        {/* 3位以上 */}
                        {item.rank > 0 && item.rank <= THIRD && (
                          <PointS addFontSize={0.3}>
                            {Number(item.point).toLocaleString()}
                            P
                          </PointS>
                        )}

                        {/* 4位以下 */}
                        {item.rank > THIRD && (
                          <PointS addFontSize={0}>
                            {Number(item.point).toLocaleString()}
                            P
                          </PointS>
                        )}
                      </RowS>
                    </ListS>
                  ))}
                {/* 0件取得時メッセージ */}
                {isEmpty && <MessageS>{t('ランキングはありません。')}</MessageS>}
                {/* エラーメッセージ */}
                {errorMessage && (
                  <MessageS>
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                  </MessageS>
                )}
              </UlistS>
            </TabS>
          </RankingContainerS>
          <RankingContainerS />
        </RankingWrapS>
      </ContainerS>
    </>
  );
};
export default Ranking;

/**
 *
 * Styles
 *
 *
 * */
const {
  pc,
} = devices;

const ContainerS = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: url(${rankingBgSp});
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  padding-top: 6.5rem;
  padding-bottom: 5rem;
  position: relative;

  @media (min-width: 600px) {
    padding-top: 9rem;
  }

  @media (min-width: 1025px) {
    padding-top: 2rem;
    padding-bottom: 10rem;

    background: image-set(url(${rankingBgPc}) 1x, url(${rankingBgPc2x}) 2x);
    background-position: -50px 0px;
    background-attachment: fixed;
    background-size: cover;
  }
`;

const RankingTitleImageBlockSpS = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  @media (min-width: 568px) {
    display: none;
  }
`;

const RankingTitleImageSpWrapS = styled.div`
  width: 100%;
  position: absolute;
  margin-top: -5%;

  @media (min-width: 600px) {
    width: 35%;
    margin-top: -3%;
  }
`;

const RankingTitleImageSpS = styled.img`
  width: 100%;
`;

const RankingWrapS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    135deg,
    rgba(149, 11, 123, 0.5),
    rgba(39, 68, 182, 0.7)
  );
  margin-top: 7%;

  @media (min-width: 568px) {
    background: linear-gradient(
      135deg,
      rgba(149, 11, 123, 0.5),
      rgba(39, 68, 182, 0.7)
    );
    margin-top: 10%;
  }

  @media (min-width: 1025px) {
    background: transparent;
    margin-top: 1%;
  }
`;

const RankingTitleImageS = styled.img`
  width: 60%;
  position: absolute;
  display: none;

  @media (min-width: 568px) {
    display: block;
    width: 100%;
  }

  @media ${pc} {
    width: 60%;
  }
`;

const RankingContainerS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 600px) {
    width: 70%;
    margin-top: 5%;
  }

  @media (min-width: 1025px) {
    width: 60%;
    margin-top: 5%;
  }

  &:last-child {
    margin-top: 10%;
  }
`;

const TabS = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.3);

  // flex
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1025px) {
    background: linear-gradient(135deg, rgba(149, 11, 123, 0.7) 1%, #2744b6);
    border: 1px solid rgba(220, 220, 220, 0.3);
  }
`;

const TabMenuPcWrapS = styled.div`
  width: 100%;
  border-bottom: 0.1px solid rgba(220, 220, 220, 0.3);
  display: none;

  @media (min-width: 1025px) {
    display: flex;
    justify-content: center;
  }
`;

const TabMenuPcS = styled.ul`
  display: flex;
  margin-top: 8%;
  text-align: center;
  width: 80%;
  justify-content: space-between;
  .tabActive {
    border-bottom: 5px solid #ffffff;
  }
  .tabInactive {
    border-bottom: 5px solid transparent;
  }
`;

const TabMenuSpS = styled.ul`
  width: 100%;
  margin-top: 15%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1px solid rgba(220, 220, 220, 0.3);
  .tabActive {
    border-bottom: 5px solid #ffffff;
  }
  .tabInactive {
    border-bottom: 5px solid transparent;
  }

  @media (min-width: 600px) {
    margin-top: 6%;
  }
  @media (min-width: 1025px) {
    display: none;
  }
`;

const TabMenuListS = styled.li<{ preLine: string }>`
  color: #ffffff;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  text-align: center;
  white-space: ${({ preLine }) => preLine};

  @media (min-width: 600px) {
    height: 5rem;
    font-size: 1.8rem;
  }

  @media (min-width: 1025px) {
    height: 7rem;
    font-size: 2rem;
  }
`;
const UlistS = styled.ul`
  position: relative;
  width: 100%;
  min-height: 300px;
  border: 0.1px solid rgba(220, 220, 220, 0.3);
  border-top: none;

  @media (min-width: 1025px) {
    border: none;
  }

  .tabActive {
    display: block;
  }
  .tabInactive {
    display: none;
  }
`;

const ListS = styled.li`
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-top: 1px solid hsla(0, 0%, 86.27450980392157%, 0.3);
`;

const RowS = styled.div`
  display: flex;
  align-items: center;
`;

const RankEmblemPictureS = styled.picture`
  flex: 0 0 20%;
  text-align: center;

  @media (min-width: 600px) {
    flex: 0 0 25%;
  }

  @media (min-width: 1025px) {
    flex: 0 0 18%;
  }
`;

const RankEmblemSourceS = styled.source``;

const RankEmblemImageS = styled.img`
  width: 35%;

  @media (min-width: 600px) {
    width: 30%;
  }
  @media (min-width: 1025px) {
    width: 20%;
    min-width: 40px;
  }
`;

const RankS = styled.span`
  color: #ffffff;
  text-align: center;
  flex: 0 0 20%;
  font-size: 1.3rem;
  @media (min-width: 600px) {
    flex: 0 0 25%;
    font-size: 2.5rem;
  }

  @media (min-width: 1025px) {
    flex: 0 0 18%;
    font-size: 2rem;
  }
`;

const UserNameS = styled.span<{ addFontSize: number }>`
  color: #ffffff;
  flex: 0 0 30%;
  margin: 3% 15px;
  font-size: ${({ addFontSize }) => `${1.3 + addFontSize}rem`};

  @media (min-width: 600px) {
    flex: 0 0 35%;
    margin: 3% 6%;
    font-size: ${({ addFontSize }) => `${2.2 + addFontSize}rem`};
  }

  @media (min-width: 1025px) {
    flex: 0 0 50%;
    margin: 2% 5%;
    font-size: 2rem;
  }
`;

const PointS = styled.span<{ addFontSize: number }>`
  color: #ffff00;
  flex: 0 0 35%;
  text-align: right;
  font-size: ${({ addFontSize }) => `${1.3 + addFontSize}rem`};

  @media (min-width: 600px) {
    flex: 0 0 22%;
    font-size: ${({ addFontSize }) => `${2.2 + addFontSize}rem`};
  }

  @media (min-width: 1025px) {
    flex: 0 0 17%;
    margin: 0% 0%;
    font-size: 2rem;
  }
`;

const MessageS = styled.p`
  width: 100%;
  height: 100%;
  position: absolute;
  // flex
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-size: 1.3rem;

  @media (min-width: 600px) {
    font-size: 1.8rem;
  }

  @media (min-width: 1025px) {
    font-size: 2rem;
  }
`;
