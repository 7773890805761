import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
// Images
import supportBoxPc from 'assets/images/neonItem/box_pc.png';
import supportBoxPc2x from 'assets/images/neonItem/box_pc@2x.png';
import supportBarTopSp from 'assets/images/neonItem/bar-tiplist-top_sp.png';
import supportBarBottomSp from 'assets/images/neonItem/bar-tiplist-bottom_sp.png';
// Utils
import { SUPPORT } from 'utils/const/support';

/**
 *
 * Types
 *
 */
 type TextList = {
  textKind: 'faq' | 'mail' | 'time';
  textHead: string;
  text: string;
  textBold: string;
  textLink: string;
  fontSize: number;
  marginTop: string;
  marginBottom: string;
}[];

type TextBoldSCssProps = {
  fontSize: number;
  marginTop: string;
  marginBottom: string;
};

const { FAQ_URL, MAIL } = SUPPORT;
const textList: TextList = [
  {
    /* よくある質問 */
    textKind: 'faq',
    textHead: 'よくある質問',
    text: '当ページのよくある質問をまずはご覧ください。',
    textBold: 'よくある質問',
    textLink: FAQ_URL,
    fontSize: 2.0,
    marginTop: '3%',
    marginBottom: '1%',
  },
  {
    /* メールでのお問い合わせ */
    textKind: 'mail',
    textHead: 'メールでのお問い合わせ',
    text: 'までお問い合わせください。',
    textBold: MAIL,
    textLink: '',
    fontSize: 2.5,
    marginTop: '0%',
    marginBottom: '2%',
  },
  {
    /* お問い合わせ時間 */
    textKind: 'time',
    textHead: 'お問合せ時間',
    text: '※お問い合わせの回答に数日頂く事がございます。3営業日以内には回答させて頂きます。',
    textBold: '毎日対応させて頂いております。',
    textLink: '',
    fontSize: 2.3,
    marginTop: '0%',
    marginBottom: '2%',
  },
];

/**
 *
 * Component
 *
 */
const SupportList: FC = memo(() => {
  // i18next
  const { t } = useTranslation();

  return (
    <ContentS>
      <UlistS>
        {textList.map((item) => (
          <ListS key={uuidv4()}>
            <SupportBarS>
              <SupportBarImageS src={supportBarTopSp} alt="" />
            </SupportBarS>

            {item.textKind === 'faq' ? (
              <TextBoxBlockS>
                <TextHeadWrapS>
                  <SquareS />
                  <TextHeadS>{t(item.textHead)}</TextHeadS>
                  <SquareS />
                </TextHeadWrapS>
                <TextS>{t(item.text)}</TextS>
                <TextBoldS
                  fontSize={item.fontSize}
                  marginTop={item.marginTop}
                  marginBottom={item.marginBottom}
                >
                  {t(item.textBold)}
                </TextBoldS>
                {item.textLink && (
                  <LinkWrapS>
                    <TextLinkS href="./faq">{item.textLink}</TextLinkS>
                  </LinkWrapS>
                )}
              </TextBoxBlockS>
            ) : (
              <TextBoxBlockS>
                <TextHeadWrapS>
                  <SquareS />
                  <TextHeadS>{t(item.textHead)}</TextHeadS>
                  <SquareS />
                </TextHeadWrapS>
                <TextBoldS
                  fontSize={item.fontSize}
                  marginTop={item.marginTop}
                  marginBottom={item.marginBottom}
                >
                  {t(item.textBold)}
                </TextBoldS>
                <TextS>{t(item.text)}</TextS>
              </TextBoxBlockS>
            )}

            <SupportBarS>
              <SupportBarImageS src={supportBarBottomSp} alt="" />
            </SupportBarS>
          </ListS>
        ))}
      </UlistS>
    </ContentS>
  );
});
export default SupportList;

/**
 *
 * Styles
 *
 */
const ContentS = styled.div`
  width: 100%;
  margin: 1rem 0 5rem;

  @media (min-width: 600px) {
    margin: 2rem 0 9rem;
  }

  @media (min-width: 1025px) {
    max-width: 100rem;
    margin: 7rem 0 9rem;
  }
`;

const UlistS = styled.ul``;

const ListS = styled.li`
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1025px) {
    padding-bottom: 4%;
  }
`;

const SupportBarS = styled.div`
  width: 90%;
  display: block;

  @media (min-width: 1025px) {
    display: none;
  }
`;

const SupportBarImageS = styled.img`
  width: 100%;
`;

const TextBoxBlockS = styled.div`
  width: 90%;
  padding: 7% 3%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(0, 0, 0, 0.2);
  border-left: 0.1px solid rgba(220, 220, 220, 0.3);
  border-right: 0.1px solid rgba(220, 220, 220, 0.3);

  @media (min-width: 1025px) {
    width: 100%;
    background: transparent;
    border-left: none;
    border-right: none;

    border-image: url(${supportBoxPc}) 5 5 5 5 fill stretch;
    @media (min-resolution: 2dppx) {
      border-image: url(${supportBoxPc2x}) 5 5 5 5 fill stretch;
    }
    border-width: 5px;
    border-style: solid;
  }
`;

const TextHeadWrapS = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4%;
`;

const TextHeadS = styled.p`
  margin: 0 2rem;
  font-size: 1.5rem;

  @media (min-width: 600px) {
    margin: 0 3rem;
    font-size: 2.4rem;
  }

  @media (min-width: 1025px) {
    margin: 0 5rem;
    font-size: 3rem;
  }
`;

const SquareS = styled.div`
  width: 18px;
  height: 18px;
  background: #ffffff 0% 0% no-repeat padding-box;
  transform: skew(-40deg, 0deg);

  @media (min-width: 600px) {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 1025px) {
    width: 35px;
    height: 35px;
  }
`;

const TextS = styled.div`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;

  @media (min-width: 600px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1025px) {
    font-size: 2rem;
  }
`;

const TextBoldS = styled.div<TextBoldSCssProps>`
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  font-size: ${({ fontSize }) => `${fontSize - 1.0}rem`};

  @media (min-width: 600px) {
    font-size: ${({ fontSize }) => `${fontSize - 0.5}rem`};
  }

  @media (min-width: 1025px) {
    font-size: ${({ fontSize }) => `${fontSize}rem`};
  }
`;

const LinkWrapS = styled.div``;

const TextLinkS = styled.a`
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: underline;
  :hover {
    color: #87cefa;
  }

  @media (min-width: 600px) {
    font-size: 2rem;
  }

  @media (min-width: 1025px) {
    font-size: 2.5rem;
  }
`;
