// Documents
import terms from 'assets/documents/daylotts_terms_of_service.pdf';
import privacyPolicy from 'assets/documents/daylotts_privacy_policy.pdf';
import responsibleGambling from 'assets/documents/daylotts_responsible_gambling.pdf';
import kycPolocy from 'assets/documents/daylotts_kyc_policy.pdf';

// 利用規約表示用カスタムフック
export const useOpenDocument = (): {
  openTerms: () => void;
  openPrivacyPolicy: () => void;
  openResponsibleGambling: () => void;
  openKycPolicy: () => void;
} => {
  // 利用規約
  const openTerms = () => window.open(terms);

  // プライバシーポリシー
  const openPrivacyPolicy = () => window.open(privacyPolicy);

  // レスポンシブル
  const openResponsibleGambling = () => window.open(responsibleGambling);

  // KYC
  const openKycPolicy = () => window.open(kycPolocy);

  return {
    openTerms,
    openPrivacyPolicy,
    openResponsibleGambling,
    openKycPolicy,
  };
};
