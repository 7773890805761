import { FC, ReactNode, memo } from 'react';
import styled from 'styled-components';

/**
 *
 * Types
 *
 */
type Props = {
  children: ReactNode;
};

/**
 *
 * Component
 *
 */
const SuccessMessage: FC<Props> = memo(({ children }) => (
  <SuccessMessageS>{children}</SuccessMessageS>
));
export default SuccessMessage;

/**
 *
 * Styles
 *
 */
const SuccessMessageS = styled.p`
  font-size: inherit;
  color: #32cd32;
  word-break: breack-all;
`;
