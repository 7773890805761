import {
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
} from 'react';
// External library
import { useLocation, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
// Recoil
import { userState } from 'store/atoms/userState';
// Hooks
import { useRedirect } from 'hooks/versatile/useRedirect';
import { useError } from 'hooks/versatile/useError';
import { LocationState as CcLocationState } from 'hooks/pages/private/useWithdrawalCc';
// Utils
import { axiosCancelToken, axiosClient } from 'utils/axios';
// Types
import type { RateWithdraw } from 'types/api';

/**
 *
 * Types
 *
 */
export type LocationState = {
  referrer: string;
};

type InputFunction = (event: ChangeEvent<HTMLInputElement>) => void

type UseWithdrawalTop = () => {
  isLoading: boolean;
  pointErrorMessage: string;
  exitPoints: number;
  changeExitPoints: InputFunction
  postApiRateWithdraw: () => void;
  balancePoints: number;
  rateJpy: number;
  address: string;
  changeAddress: InputFunction;
  blurAddress: InputFunction;
  addressErrorMessage: string;
  goToWithdrawalPage: () => void;
  goToMypage: () => void;
};

/**
 *
 * Hooks
 *
 */
export const useWithdrawalTop: UseWithdrawalTop = () => {
  // マイページ以外からのアクセスはトップページへリダイレクト
  const { redirectToTopPage } = useRedirect();
  const { referrer } = useLocation<LocationState | undefined>().state ?? { referrer: '' };
  useEffect(() => {
    if (referrer !== '/mypage' && referrer !== '/withdrawal/cc') redirectToTopPage();
    window.scrollTo({ top: 0 });
  }, []);

  const [pointErrorMessage, setPointErrorMessage] = useState('');
  const { errorHandler } = useError();

  // 出金・交換ポイント数
  const me = useRecoilValue(userState);
  const [exitPoints, setExitPoints] = useState(me.point >= 50 ? 50 : 0);
  const [balancePoints, setBalancePoints] = useState(
    me.point >= 50 ? me.point - 50 : me.point,
  );
  const [rateJpy, setRateJpy] = useState(0);

  const changeExitPoints = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPointErrorMessage('');
    if (!/^\d+$/.exec(event.currentTarget.value)) {
      setPointErrorMessage('半角数字のみ入力してください。');

      return;
    }

    const inputPoints = Number(event.currentTarget.value);
    if (inputPoints < 50) {
      setPointErrorMessage('50ポイントから出金が可能です。');

      return;
    }
    if (me.point < inputPoints) {
      setPointErrorMessage('入力ポイントが所持ポイントを超えています。');

      return;
    }

    setExitPoints(inputPoints);
    setBalancePoints(me.point - inputPoints);
    setRateJpy(0);
  }, [me]);

  // ポインレート計算
  const [isLoading, setIsLoading] = useState(false);
  const rateWithdrawSource = axiosCancelToken.source();
  const postApiRateWithdraw = useCallback(() => {
    setIsLoading(true);

    axiosClient
      .post<RateWithdraw>('/api/rate/withdraw', {
        point: exitPoints,
        cancelToken: rateWithdrawSource.token,
      })
      .then(({ data: { jpy } }) => {
        setPointErrorMessage('');
        setRateJpy(jpy);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) setPointErrorMessage(errorHandler(error.response));
      })
      .finally(() => setIsLoading(false));
  }, [exitPoints]);
  const cleanup = () => {
    rateWithdrawSource.cancel();
  };
  useEffect(() => {
    postApiRateWithdraw();

    return cleanup;
  }, []);

  const [address, setAddress] = useState('');
  const [addressErrorMessage, setAddressErrorMessage] = useState('');
  const changeAddress = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  }, []);
  const blurAddress = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAddressErrorMessage(event.target.value === '' ? '送金先アドレスを入力してください。' : '');
  }, []);

  // 各出金ページへ遷移
  const history = useHistory();
  const goToWithdrawalCcPage = useCallback(() => {
    const state: CcLocationState = {
      referrer: '/withdrawal',
      exitPoints,
      balancePoints,
      usdt: exitPoints,
      address,
    };
    history.push({
      pathname: '/withdrawal/cc',
      state,
    });
  }, [exitPoints, balancePoints, address]);
  const goToWithdrawalPage = useCallback(() => {
    if (exitPoints < 50) {
      setPointErrorMessage('50ポイントから出金が可能です。');

      return;
    }
    if (address === '') {
      setAddressErrorMessage('送金先アドレスを入力してください。');

      return;
    }

    goToWithdrawalCcPage();
  }, [exitPoints, balancePoints, address]);
  const goToMypage = useCallback(() => {
    history.push('/mypage');
  }, []);

  return {
    pointErrorMessage,
    isLoading,
    exitPoints,
    changeExitPoints,
    postApiRateWithdraw,
    balancePoints,
    rateJpy,
    address,
    changeAddress,
    blurAddress,
    addressErrorMessage,
    goToWithdrawalPage,
    goToMypage,
  };
};
