import { useState } from 'react';
// External library
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
// Custom hooks
import { useError } from 'hooks/versatile/useError';
import { useFile } from 'hooks/versatile/useFile';
// Utils
import { axiosFormDataClient, axiosCancelToken } from 'utils/axios';
// Types
import { PageType, UploadFile } from 'types/kyc';
import { ApiErrorType } from 'types/api';
import { BreadCrumbItems } from 'utils/breadCrumbs';

/**
 *
 * Types
 *
 */
type LocationState = {
  pageType: PageType;
  referrer: string;
  birthday: string;
  uploadFile: UploadFile;
  uploadBreadCrumbItems: BreadCrumbItems[];
};

type UseKycUpload = {
  errorMessage: string;
  isLoading: boolean;
  breadCrumbItems: BreadCrumbItems[];
  referrer: string;
  birthday: string;
  uploadFile: UploadFile;
  toBackKycUpload: () => void;
  postApiUsersKyc: () => void;
  cleanUpMethod: () => void;
};

/**
 *
 * KYC提出確認ページ用ロジック
 *
 */
export const useKycConfirm = (): UseKycUpload => {
  // エラー用カスタムフック
  const { errorHandler } = useError();
  // エラーメッセージ
  const [errorMessage, setErrorMessage] = useState<string>('');
  // ローディングフラグ
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // locationオブジェクト
  const location = useLocation<LocationState>();
  const alternativeLocationState: LocationState = {
    pageType: 'license',
    referrer: '',
    birthday: '',
    uploadFile: [],
    uploadBreadCrumbItems: [{ path: '', name: '' }],
  };

  // アップロードページからの入力内容
  const {
    referrer, birthday, uploadFile, uploadBreadCrumbItems,
  } = location.state ?? alternativeLocationState;

  // パンくずリスト
  const breadCrumbItems: BreadCrumbItems[] = [
    ...uploadBreadCrumbItems,
    { path: '/kyc/confirm', name: '送信内容の確認' },
  ];

  // historyオブジェクト
  const history = useHistory();
  // アップロードページへ戻る
  const toBackKycUpload = () => {
    if (
      referrer === '/kyc/upload/passport'
      || referrer === '/kyc/upload/license'
    ) {
      history.push({
        pathname: referrer,
      });
    }
  };
  // KYC書類アップロード完了ページへ遷移
  const goToKycFinish = () => {
    history.push({
      pathname: '/kyc/finish',
      state: {
        referrer: '/kyc/confirm',
      },
    });
  };

  // ファイル用カスタムフック
  const { convertFile } = useFile();

  // KYCアップロードリクエスト
  const source = axiosCancelToken.source();
  const postApiUsersKyc = () => {
    if (!isLoading) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('birthday', birthday);

      uploadFile.forEach(({ dataUri, name }) => {
        const file = convertFile(dataUri);
        formData.append(name, file);
      });

      axiosFormDataClient
        .post<boolean>('/api/users/kyc', formData, {
          cancelToken: source.token,
        })
        .then(({ data }) => {
          if (data) {
            goToKycFinish();
          }
        })
        .catch((error: AxiosError<ApiErrorType>) => {
          const result = errorHandler(error.response);

          if (result) {
            setErrorMessage(result);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // useEffect用クリーンアップ関数
  const cleanUpMethod = () => {
    source.cancel();
    setErrorMessage('');
    setIsLoading(false);
  };

  return {
    errorMessage,
    isLoading,
    breadCrumbItems,
    referrer,
    birthday,
    uploadFile,
    toBackKycUpload,
    postApiUsersKyc,
    cleanUpMethod,
  };
};
