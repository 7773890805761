// 最低購入ポイント
export const MINIMUM_PURCHASE_POINT = '10';
export const MAXIMUM_PURCHASE_CREDIT = '500';

// 仮想通貨
export const CRYPTCURRENCY = ['BTC', 'USDT', 'ETH'] as const;

export const CREDIT_CARD = {
  number: 'number',
  name: 'name',
  expiry: 'expiry',
  cvc: 'cvc',
} as const;

export const CARD_NUMBER_LENGTH = {
  AMEX: 15,
  DINERS: 14,
  DEFAULT: 16,
};

// 決済システムAPI用パラメーター
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL ?? '';
