// eslint-disable-next-line
import { FC, memo } from 'react';
// External library
import styled from 'styled-components';
// Components
import ArrowIcon from 'components/atoms/icon/ArrowIcon';
// Images
import headerBgSp from 'assets/images/top/playBox/header_sp.png';
import headerBgPc from 'assets/images/top/playBox/bg-header_pc.jpg';
import headerBgPc2x from 'assets/images/top/playBox/bg-header_pc@2x.jpg';
import neonBarTopPc from 'assets/images/neonItem/bar-top_pc.png';
import neonBarBottomPc from 'assets/images/neonItem/bar-bottom_pc.png';
import dayLottsIcon from 'assets/images/top/playBox/daylotts1000000_icon.png';

/**
 *
 * Component
 *
 */
const PlayBox1000000: FC = memo(() => (
  <PlayBoxS>
    <NeonBarWrapS>
      <NeonBarImageS src={neonBarTopPc} alt="" />
    </NeonBarWrapS>

    <ComingSoonS>
      <ComingSoonTextS>Coming Soon.</ComingSoonTextS>
    </ComingSoonS>

    {/* ヘッダー */}
    <AccordionButtonS type="button">
      <HeaderS>
        <HeaderLogoWrapS>
          <HeaderLogoS src={dayLottsIcon} alt="" />
        </HeaderLogoWrapS>
        <HeaderButtonWrapS>
          <HeaderButtonS>
            <ArrowIcon rotate="45deg" />
          </HeaderButtonS>
        </HeaderButtonWrapS>
      </HeaderS>
    </AccordionButtonS>

    <NeonBarWrapS>
      <NeonBarImageS src={neonBarBottomPc} alt="" />
    </NeonBarWrapS>
  </PlayBoxS>
));
export default PlayBox1000000;

/**
 *
 * Styles
 *
 */
const PlayBoxS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const NeonBarWrapS = styled.picture`
  width: 100%;
`;

const NeonBarImageS = styled.img`
  height: 10px;
  width: 100%;

  @media (min-width: 1025px) {
    height: 15px;
  }
`;

const AccordionButtonS = styled.button`
  width: 100%;
  opacity: 0.5;
`;

const HeaderS = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 9rem;
  background-image: url(${headerBgSp});
  background-position: center;
  border: 1px solid #707070;
  border-bottom: none;
  position: relative;

  @media (min-width: 600px) {
    min-height: 9rem;
    background-image: image-set(url(${headerBgPc}) 1x, url(${headerBgPc2x}) 2x);
    border: 1px solid #707070;
    border-radius: 8px;
  }

  @media (min-width: 1025px) {
    min-height: 11rem;
  }
`;

const HeaderLogoWrapS = styled.div`
  width: min(30%, 95px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  @media (min-width: 600px) {
    width: 20%;
    display: block;
  }

  @media (min-width: 1025px) {
    width: 16%;
  }
`;

const HeaderLogoS = styled.img`
  width: 100%;

  @media (min-width: 600px) {
    width: 100%;
    margin-top: -7%;
  }

  @media (min-width: 1025px) {
    margin-top: -16%;
  }
`;

const HeaderButtonWrapS = styled.div`
  position: absolute;
  right: 0;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 600px) {
    width: 10%;
  }
`;

const HeaderButtonS = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1025px) {
    width: 3rem;
    height: 3rem;
  }
`;

const ComingSoonS = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1;
`;

const ComingSoonTextS = styled.p`
  color: #ffffff;
  font-size: 3rem;
  font-style: italic;

  @media (min-width: 600px) {
    font-size: 5rem;
  }
`;
