const prefix = 'DayLott\'s - ';

export const jaSeo = {
  title: {
    // Global pages
    top: `${prefix}トップページ`,
    emailSend: `${prefix}メール送信完了`,
    emailVerified: `${prefix}メール認証`,
    whats: `${prefix}DayLott'sとは`,
    system: `${prefix}仕組み`,
    faq: `${prefix}よくある質問`,
    ranking: `${prefix}ランキング`,
    support: `${prefix}サポート`,
    responsibleGambling: `${prefix}責任のあるギャンブルポリシー`,
    newsShow: `${prefix}お知らせ`,
    contact: `${prefix}お問い合わせ`,
    contactConfirmation: `${prefix}お問い合わせ内容の確認`,
    contactCompleted: `${prefix}お問い合わせ完了`,
    passwordReset: `${prefix}パスワードリセット`,
    passwordResetForm: `${prefix}パスワードリセットフォーム`,
    passwordResetCompleted: `${prefix}パスワードリセット完了`,
    // Guest pages
    register: `${prefix}新規会員登録`,
    registerConfirmation: `${prefix}新規会員登録`,
    login: `${prefix}ログイン`,
    accountDeleteCompleted: `${prefix}アカウント停止完了`,
    // Private pages
    mypage: `${prefix}マイページ`,
    accountDeleteConfirmation: `${prefix}アカウントの停止`,
    kyc: `${prefix}KYC アップロード`,
    kycConfirmation: `${prefix}KYC 確認`,
    kycCompleted: `${prefix}KYC 完了`,
    emailChange: `${prefix}メールアドレスの変更`,
    pointPurchase: `${prefix}ポイント購入`,
    pointPurchaseCc: `${prefix}仮想通貨で入金`,
    pointPurchaseCredit: `${prefix}クレジットカードで入金`,
    pointPurchaseCash: `${prefix}現金で入金`,
    pointPurchaseCompleted: `${prefix}入金情報送信完了`,
    userNewsIndex: `${prefix}お知らせ一覧`,
    userNewsShow: `${prefix}お知らせ`,
    userNewsSkip: `${prefix}獲得ポイント受け取り`,
    lottery: `${prefix}抽選`,
    ticketPurchaseConfirmation: `${prefix}購入内容のご確認`,
    ticketPurchaseCompleted: `${prefix}チケット購入完了`,
    withdrawalTop: `${prefix}ポイント出金・交換`,
    withdrawalCc: `${prefix}送金手続き`,
    withdrawalFinish: `${prefix}出金・交換を受け付けました。`,
  },
  description: {
    // Global pages
    top: 'DayLott\'sは毎日ミリオネアを排出する、世界初のオンライン宝くじゲームです。',
    emailSend: `${prefix}メール送信完了ページ。`,
    emailVerified: `${prefix}メール認証ページ。`,
    whats: 'DayLott\'sとは毎日ミリオネアを排出する、世界初のオンライン宝くじゲームです。',
    system: '当選確率が驚異の75%を誇るオンライン宝くじゲームです。エントリーするベット金額、当選金額に応じたテーブルが用意されています。ルールは、チケットを購入してエントリーするだけの簡単なシステムです。',
    faq: 'こちらはDayLott\'sに関するよくある質問をまとめたページです。',
    ranking: 'こちらはDayLott\'sの当選者ランキングページです。',
    support: 'こちらはDayLott\'sに関するよくある質問、メールでのお問い合わせ、お問い合わせ時間などのサポートページです。',
    responsibleGambling: 'お客様にできる限り最も楽しいギャンブル体験を提供することをお約束していますが、ギャンブルは一部の人々にとって問題を引き起こす可能性があることも認識しています。あなたが安全で管理可能なプレーを楽しむことを続けられるよう、当社は責任のあるギャンブルをサポートし、お客様を支援するためにさまざまな対策を講じています。',
    newsShow: `${prefix}お知らせページ。`,
    contact: 'こちらはDayLott\'sのお問い合わせページです。お客様からのご質問をお問い合わせフォームにて受け付けております。',
    contactConfirmation: `${prefix}お問い合わせ内容確認ページ。`,
    contactCompleted: `${prefix}お問い合わせ完了ページ。`,
    passwordReset: `${prefix}パスワードリセットページ。`,
    passwordResetForm: `${prefix}パスワードリセットフォーム。`,
    passwordResetCompleted: `${prefix}パスワードリセット完了ページ。`,
    // Guest pages
    register: 'DayLott\'sに会員登録してポイントをチャージ！宝くじのチケットを購入しゲームに参加しましょう！',
    registerConfirmation: `${prefix}入力内容の確認。`,
    login: 'DayLott\'sにご登録のメールアドレスとパスワードを入力しログインしてください。初めてご利用の場合は会員登録を行ってください。',
    accountDeleteCompleted: `${prefix}アカウント停止完了ページ。`,
    // Private pages
    mypage: 'DayLott\'sご利用者のマイページです。',
    accountDeleteConfirmation: `${prefix}アカウント停止ページ。`,
    kycUpload: `${prefix}KYC アップロードページ。`,
    kycConfirmation: `${prefix}KYC 確認ページ。`,
    kycCompleted: `${prefix}KYC 完了ページ。`,
    emailChange: `${prefix}メールアドレス変更ページ。`,
    pointPurchase: `${prefix}ポイント購入ページ。`,
    pointPurchaseCc: `${prefix}仮想通貨入金ページ。`,
    pointPurchaseCredit: `${prefix}クレジットカード入金ページ。`,
    pointPurchaseCash: `${prefix}現金入金ページ。`,
    pointPurchaseCompleted: `${prefix}入金情報を送信しました。`,
    userNewsIndex: `${prefix}お知らせ一覧ページ。`,
    userNewsShow: `${prefix}お知らせページ。`,
    userNewsSkip: `${prefix}獲得ポイント受け取りのページ。`,
    lottery: `${prefix}抽選ページ。`,
    ticketPurchaseConfirmation: `${prefix}チケット購入確認ページ。`,
    ticketPurchaseCompleted: `${prefix}チケット購入完了ページ。`,
    withdrawalTop: `${prefix}ポイント出金・交換ページ。`,
    withdrawalCc: `${prefix}送金手続きページ。`,
    withdrawalFinish: `${prefix}出金・交換を受け付けました。`,
  },
};
