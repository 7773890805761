import { FC } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'store/atoms/userState';
import { getToken } from '../utils/axios';

const GuestRoute: FC<RouteProps> = ({ path, component }) => {
  const user = useRecoilValue(userState);
  const token = getToken();

  return user.loggedIn && token !== '' && token !== null ? (
    <Redirect to="/mypage" />
  ) : (
    <Route path={path} component={component} />
  );
};

export default GuestRoute;
